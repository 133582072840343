import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { Router, RouterLink } from '@angular/router';
import { AnimalImagesComponent } from '@animal/components/animal-images/animal-images.component';
import { CreateTaskDialogComponent } from '@care/components/create-task-dialog/create-task-dialog.component';
import { MoveOrCopyTaskDialogComponent } from '@care/components/move-or-copy-task-dialog/move-or-copy-task-dialog.component';
import { ShowTaskDialogComponentComponent } from '@care/components/show-task-dialog-component/show-task-dialog-component.component';
import { StatusFilter, TaskFilter } from '@care/components/task-filter/model/task-filter';
import { TaskFilterComponent } from '@care/components/task-filter/task-filter.component';
import {
  BaseTaskDto,
  TaskCategoryGroup,
  TaskDto,
  TaskEntityType,
  TaskStatus,
  isAnimalTask,
  isBoxTask,
  isStationTask,
} from '@care/dtos/task.dto';
import { RepetitionDisplayPipe } from '@care/pipes/repetition-display.pipe';
import { TaskService } from '@care/services/task.service';
import { BaseDataDto, StationDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { BreadcrumbsComponent } from '@core/components/breadcrumbs/breadcrumbs.component';
import { ChipInputComponent } from '@core/components/chip-input/chip-input.component';
import { ImageDisplayComponent } from '@core/components/image-display/image-display.component';
import { FilterOption, OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { SlideToggleComponent } from '@core/components/slide-toggle/slide-toggle.component';
import { StepperComponent } from '@core/components/stepper/stepper.component';
import { routes_config } from '@core/constants';
import { CoreModule } from '@core/core.module';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { TypeSafeMatCellDef } from '@core/directives/mat-table.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AnimalType, UserRole } from '@core/models/general';
import { AndRoleRestrictionPipe, RESTRICT_CASE_WRITE } from '@core/models/role';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { StripRichTextPipe } from '@core/pipes/strip-rich-text.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TestingPlaceholderComponent } from '@core/testing-placeholder/testing-placeholder.component';
import { SWISS_LOCAL_DATE_FORMAT } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { notNullish } from '@core/utils/rxjs';
import { FileListComponent } from '@file/component/file-list/file-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserDto } from '@user/dto/user.dto';
import { CurrentUserService } from '@user/service/current-user.service';
import moment from 'moment/moment';
import { debounceTime, filter, skip, take } from 'rxjs';

import { SimpleStatsDisplayCardComponent } from '../../../statistics/components/simple-stats-display-card/simple-stats-display-card.component';
import { DashboardStatisticsDto } from '../../../statistics/dto/statistics.dto';
import { DashboardStatsChangePipe } from '../../../statistics/pipes/dashboard-stats-change.pipe';
import { StatisticsService } from '../../../statistics/services/statistics.service';

interface TableConfig {
  query: string;
  pageIndex: number;
  pageSize: number;
  filter: TaskFilter;
  stationFilter: StationDto | null;
  taskEntityTypeFilter: TaskEntityType[] | null;
  taskStatusFilter: TaskStatus | null;
}

enum AdminDashboardFilter {
  Open = 'Open',
  All = 'All',
}

@Component({
  selector: 'app-dashboard-page',
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    MatCheckboxModule,
    MatRadioModule,
    SlideToggleComponent,
    MatTabsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    AvatarImageComponent,
    StepperComponent,
    BreadcrumbsComponent,
    ChipInputComponent,
    PaginatorComponent,
    CdkStepperModule,
    MatAutocompleteModule,
    MatButtonModule,
    ImageDisplayComponent,
    AnimalImagesComponent,
    FileListComponent,
    TestingPlaceholderComponent,
    ConfirmationDialogDirective,
    EnumDisplayPipe,
    FullNamePipe,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    OptionButtonComponent,
    RepetitionDisplayPipe,
    TaskFilterComponent,
    TypeSafeMatCellDef,
    FormsModule,
    TypesafeMatTableModule,
    ScrollableTableComponent,
    StripRichTextPipe,
    SingleLineTextComponent,
    RouterLink,
    ContextActionsDirective,
    RoleRestrictionDirective,
    AndRoleRestrictionPipe,
    SimpleStatsDisplayCardComponent,
    DashboardStatsChangePipe,
  ],
  templateUrl: './dashboard-page.component.html',
  styleUrl: './dashboard-page.component.scss',
})
export class DashboardPageComponent implements OnInit {
  datasource?: PagedTigonDatasource<TaskDto, TableConfig>;
  query = signal('');
  pageIndex = 0;
  pageSize = 10;
  totalPages = 1;
  totalItems = 0;
  // filter by default today's tasks
  filter!: TaskFilter;

  taskCategoryGroup = TaskCategoryGroup.Care;

  activeTaskStationFilter = signal<StationDto | null>(null);

  adminFilter = signal<AdminDashboardFilter>(AdminDashboardFilter.Open);

  taskStationFilterOptions: FilterOption<StationDto | null>[] = [];

  adminDashboardOptions: FilterOption<AdminDashboardFilter>[] = createEnumChoices<AdminDashboardFilter>(
    AdminDashboardFilter,
    'GENERAL.DOMAIN.AdminDashboardFilter.',
  ).map(choice => ({
    label: choice.label,
    value: choice.object,
  }));

  dashboardStatistics?: DashboardStatisticsDto;
  firstDayOfYear = moment().startOf('year').format(SWISS_LOCAL_DATE_FORMAT);

  protected readonly appRoutes = routes_config;

  protected readonly columns = ['title', 'category', 'description', 'animal', 'box', 'station', 'terminationDate', 'actions'];
  protected readonly isAnimalTask = isAnimalTask;
  protected readonly isBoxTask = isBoxTask;
  protected readonly isStationTask = isStationTask;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly TaskCategoryGroup = TaskCategoryGroup;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RESTRICT_CASE_WRITE = RESTRICT_CASE_WRITE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private taskService: TaskService,
    private snackbar: SnackbarService,
    private modalService: ModalService,
    private router: Router,
    private baseDataService: BaseDataService,
    private currentUserService: CurrentUserService,
    private statisticsService: StatisticsService,
  ) {
    const today = moment({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    this.filter = TaskFilter.empty()
      .setDateFilter({
        from: today.format('yyyy-MM-DD'),
        to: today.format('yyyy-MM-DD'),
        id: 'dateFilter',
      })
      .setStatusFilter({
        id: 'statusFilter',
        values: [TaskStatus.Open],
      });

    toObservable(this.query)
      .pipe(skip(1), debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe(query => {
        this.datasource?.update({ query });
      });
  }

  ngOnInit() {
    this.statisticsService
      .getDashboardStatistics()
      .pipe(take(1))
      .subscribe((statistics: DashboardStatisticsDto) => {
        this.dashboardStatistics = statistics;
      });

    this.currentUserService.currentUser$.subscribe((currentUser: UserDto) => {
      if (currentUser.roles.some(it => it === UserRole.Veterinarian)) {
        this.router.navigate(this.appRoutes.ANIMALS.url());
      }

      if (currentUser.usedStation) {
        this.activeTaskStationFilter.set(currentUser.usedStation);
      }

      let taskEntityTypeFilter: TaskEntityType[] = [];

      if (currentUser.usedRole === UserRole.Office) {
        taskEntityTypeFilter = [TaskEntityType.Case];
        this.taskCategoryGroup = TaskCategoryGroup.Admin;
      } else {
        taskEntityTypeFilter = [TaskEntityType.Animal, TaskEntityType.Box, TaskEntityType.Station];
        this.taskCategoryGroup = TaskCategoryGroup.Care;
      }

      if (this.taskCategoryGroup === TaskCategoryGroup.Admin) {
        if (this.adminFilter() === AdminDashboardFilter.Open) {
          const statusFilter: StatusFilter = {
            id: 'statusFilter',
            values: [TaskStatus.Open],
          };
          this.filter = this.filter.setStatusFilter(statusFilter);
        }
      }

      this.datasource = new PagedTigonDatasource<TaskDto, TableConfig>(
        {
          query: '',
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
          filter: this.filter,
          stationFilter: this.activeTaskStationFilter(),
          taskEntityTypeFilter: taskEntityTypeFilter,
          // show only open tasks here
          taskStatusFilter: TaskStatus.Open,
        },
        params => {
          return this.taskService.getTasksPaginated(
            {
              query: params.query,
              pageIndex: params.pageIndex,
              pageSize: params.pageSize,
              stationFilter: this.activeTaskStationFilter(),
              filter: this.filter,
              sort: [],
            },
            taskEntityTypeFilter,
          );
        },
        this.destroyRef,
      );

      this.currentUserService.usedRole$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(role => {
        let newTaskEntityTypeFilter: TaskEntityType[];

        if (role === UserRole.Office) {
          newTaskEntityTypeFilter = [TaskEntityType.Case];
          this.taskCategoryGroup = TaskCategoryGroup.Admin;
        } else {
          this.taskCategoryGroup = TaskCategoryGroup.Care;
          newTaskEntityTypeFilter = [TaskEntityType.Animal, TaskEntityType.Box, TaskEntityType.Station];
        }

        this.datasource!.update({
          taskEntityTypeFilter: newTaskEntityTypeFilter,
        });
      });

      this.datasource.page$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(page => {
        this.totalPages = page.totalPages;
        this.totalItems = page.totalItems;
        this.pageSize = page.pageSize;
        this.pageIndex = page.pageIndex;
      });

      this.currentUserService.usedStation$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(station => {
        this.activeTaskStationFilter.set(station);
        this.datasource!.update({
          stationFilter: station,
        });
      });
    });

    this.baseDataService
      .getBaseData()
      .pipe(take(1))
      .subscribe((baseData: BaseDataDto) => {
        const filters: FilterOption<StationDto | null>[] = baseData.stations.map(station => ({
          label: station.name,
          value: station,
        }));

        this.taskStationFilterOptions = [
          {
            label: 'GENERAL.DOMAIN.TaskStationFilter.All',
            value: null,
          },
          ...filters,
        ];
      });
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.datasource!.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }

  deleteTask(task: BaseTaskDto) {
    this.taskService.delete(task.id).subscribe(() => {
      this.datasource!.update({});
      this.snackbar.showSuccessMessage('GENERAL.INFO.TASK_DELETED');
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openTask(task: TaskDto) {
    if (isAnimalTask(task)) {
      this.router.navigate(this.appRoutes.CARE_ANIMAL.url(task.animal.id));
      return;
    }
    this.modalService
      .open(
        ShowTaskDialogComponentComponent,
        {
          task: task,
          taskCategoryGroups: [this.taskCategoryGroup],
        },
        { width: ModalWidth.ExtraLarge },
      )
      .afterClosed()
      .subscribe(() => {
        this.datasource?.update({});
      });
  }

  changeStationFilter(newFilter: StationDto | null) {
    this.activeTaskStationFilter.set(newFilter);
    this.datasource?.update({ stationFilter: newFilter, pageIndex: 0 });
  }

  changeGeneralFilter(newFilter: AdminDashboardFilter) {
    this.adminFilter.set(newFilter);
    this.datasource?.update({ pageIndex: 0 });
  }

  moveTask(task: TaskDto) {
    this.modalService
      .open(MoveOrCopyTaskDialogComponent, { task: task, mode: 'move' }, { width: ModalWidth.Large })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.snackbar.showSuccessMessage('PAGE.CARE_TASK.FEEDBACK.TASK_MOVED');
        }
        this.datasource?.update({});
      });
  }

  copyTask(task: TaskDto) {
    this.modalService
      .open(MoveOrCopyTaskDialogComponent, { task: task, mode: 'copy' }, { width: ModalWidth.Large })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.snackbar.showSuccessMessage('PAGE.CARE_TASK.FEEDBACK.TASK_COPIED');
        }
        this.datasource?.update({});
      });
  }

  filterChanged(newFilter: TaskFilter) {
    this.filter = newFilter;
    this.datasource?.update({ filter: newFilter, pageIndex: 0 });
  }

  createNewTask() {
    this.modalService
      .open(
        CreateTaskDialogComponent,
        {
          entity: null,
          entityType: null,
          taskCategoryGroups: [this.taskCategoryGroup],
        },
        { width: ModalWidth.ExtraLarge },
      )
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe(() => {
        this.datasource?.update({});
      });
  }
}
