import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { ButtonUploadComponent } from '@core/components/button-upload/button-upload.component';
import { ImageDisplayComponent } from '@core/components/image-display/image-display.component';
import { InputUploadComponent } from '@core/components/input-upload/input-upload.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { PageResult } from '@core/data/tigon-datasource';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { FileTagType } from '@file/dto/file-tag-type';
import { FileBaseDto, FileDto, ImageDto } from '@file/dto/file.dto';
import { FileService, UploadResult } from '@file/service/file.service';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { AnimalDto } from '../../dtos/animal.dto';

@Component({
  selector: 'app-animal-images',
  standalone: true,
  imports: [
    ImageDisplayComponent,
    AsyncPipe,
    PaginatorComponent,
    MatButtonModule,
    TranslateModule,
    RouterLink,
    InputUploadComponent,
    ButtonUploadComponent,
    ContextActionsDirective,
    RoleRestrictionDirective,
  ],
  templateUrl: './animal-images.component.html',
  styleUrl: './animal-images.component.scss',
})
export class AnimalImagesComponent implements OnInit {
  @Input({ required: true }) animal!: AnimalDto;
  @Input() isReadOnly = false;

  images$: BehaviorSubject<ImageDto[]> = new BehaviorSubject<ImageDto[]>([]);

  pagination = {
    pageIndex: 0,
    pageSize: 6,

    totalPages: 1,
    totalItems: 0,
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly FileTagType = FileTagType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(private fileService: FileService) {}

  ngOnInit(): void {
    this.fetchImages();
  }

  pageChanged(pageEvent: PageEvent) {
    this.pagination.pageSize = pageEvent.pageSize;
    this.pagination.pageIndex = pageEvent.pageIndex;
    this.pagination.totalPages = pageEvent.maxPages;
    this.fetchImages();
  }

  onUploadCompleted(uploadResults: UploadResult<FileBaseDto>[]) {
    const uploadedImages: ImageDto[] = uploadResults.filter(it => it.success && it.file !== undefined).map(it => it.file) as ImageDto[];
    if (this.isLastPage()) {
      const images: ImageDto[] = [...this.images$.getValue(), ...uploadedImages];
      this.images$.next(images);
    } else {
      this.fetchImages();
    }
  }

  imageDeleted() {
    if (this.images$.value.length == 0) {
      this.pagination.pageIndex = Math.max(this.pagination.pageIndex - 1, 0);
    }

    this.fetchImages();
  }

  private fetchImages() {
    this.fileService
      .getFilesPaginated(this.animal.fileCollection, [FileTagType.AnimalImage], this.pagination)
      .subscribe((result: PageResult<FileDto>) => {
        this.pagination.totalItems = result.totalItems;
        this.pagination.totalPages = result.totalPages;
        this.images$.next(result.items as ImageDto[]);
      });
  }

  private isLastPage() {
    return this.pagination.pageIndex == this.pagination.totalPages - 1;
  }
}
