<h1 class="title" mat-dialog-title>{{ 'PAGE.ANIMALS.AnimalExitDialog.TITLE' | translate }}</h1>

<div mat-dialog-content>
  <div class="form-grid grid-cols-6 column-gap-large">
    <div class="row">
      <div class="item col-span-3">
        <tgn-form-element
          [control]="exitForm.controls.animalState"
          [id]="'animalState'"
          [label]="'ENTITY.Animal.state' | translate"
          class="row-span-3"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-select [choices]="animalStateChoices" [control]="control" [id]="id" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    @if (data.animal.type === AnimalType.DomesticAnimal) {
      <div class="row">
        <div class="item col-span-3">
          <tgn-form-element
            [control]="exitForm.controls.exitState"
            [hideError]="true"
            [id]="'exitState'"
            [label]="'ENTITY.Placement.exitState' | translate"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select
                [choices]="exitStateChoices$ | async | addIfMissing: exitForm.controls.exitState.value | toRadioChoice: 'name'"
                [compareIds]="true"
                [control]="control"
                [id]="id"
              />
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3">
          <tgn-form-element
            [control]="exitForm.controls.exitDate"
            [hideError]="true"
            [id]="'exitDate'"
            [label]="'ENTITY.Placement.exitDate' | translate"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    } @else {
      <div class="row auto-rows">
        <div class="item col-span-3">
          <tgn-form-element
            [control]="exitForm.controls.exitDate"
            [hideError]="true"
            [id]="'exitDate'"
            [label]="'ENTITY.Placement.exitDate' | translate"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
        @if (wildAnimalStatisticsForm) {
          <tgn-wild-animal-statistics-form [inline]="true" [form]="wildAnimalStatisticsForm" />
        }
      </div>
    }
    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="exitForm.controls.exitNote"
          [id]="'exitNote'"
          [label]="'ENTITY.Placement.exitNote' | translate"
          class="row-span-3"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <textarea
                [formControl]="control"
                [id]="id"
                cdkAutosizeMaxRows="10"
                cdkAutosizeMinRows="5"
                cdkTextareaAutosize
                matInput
              ></textarea>
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>
  <button (click)="save()" [disabled]="exitForm.invalid" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.BUTTON.FINISH' | translate }}
  </button>
</div>
