<ng-container *ngIf="animal$ | async as animal">
  <tgn-animal-header
    [animal]="animal"
    [baseData]="baseData$ | async"
    [context]="{ type: AnimalViewContext.AnimalDetail, case: latestCase$ | async }"
  />
</ng-container>

<tgn-nav-tabs [(activeTab)]="selectedTab" [tabs]="tabs"></tgn-nav-tabs>

<div class="content">
  <router-outlet></router-outlet>
</div>
