<div *tgnContextActions class="tgn-context-actions">
  @if (case$ | async; as caseDto) {
    @if (caseDto.state !== CaseState.Completed) {
      <button
        [tgnContextItem]="{ object: case$ }"
        [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE | andRoleRestriction: RESTRICT_CASE_WRITE"
        (click)="openCloseCaseDialog(caseDto)"
        class="tgn-button"
        mat-flat-button
      >
        {{ 'GENERAL.BUTTON.COMPLETE' | translate }}
      </button>
    }

    @if (caseDto.archived) {
      <button
        (agreeEvent)="unarchive(caseDto)"
        [dialogTitle]="'Archivierung aufheben'"
        [dialogDescription]="'Soll dieser Fall wieder aktiv geschalten werden?'"
        appConfirmationDialog
        class="tgn-button"
        mat-flat-button
      >
        <span>{{ 'GENERAL.BUTTON.UNARCHIVE' | translate }}</span>
      </button>
    }
  }
</div>

<tgn-narrow-page-container>
  @if (form) {
    <form class="form-grid grid-cols-6 mt-large">
      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.title" [id]="'title'" [label]="'ENTITY.Case.title' | translate" class="row-span-3">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.Case.title' | translate"
                  cdkFocusInitial
                  type="text"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-3">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.animalType" [id]="'animalType'" [label]="'ENTITY.Case.animalType' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select [choices]="animalTypeChoices" [control]="control" />
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3 col-span-sm-6">
          @if (form.controls.animalType.value === AnimalType.DomesticAnimal) {
            <tgn-form-element
              [control]="form.controls.entryType"
              [hideError]="true"
              [id]="'caseEntryType'"
              [label]="'GENERAL.FORM.LABEL.ENTRY_AS'"
            >
              <ng-template formElement let-control="control" let-id="id">
                <tgn-select [choices]="caseEntryTypeChoices" [control]="control" />
              </ng-template>
            </tgn-form-element>
          }
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="form.controls.submitReason" [id]="'lastName'" [label]="'ENTITY.Case.submitReason' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select
                [compareIds]="true"
                [choices]="submitReasons$ | async | addIfMissing: form.controls.submitReason.value | toRadioChoice: 'name'"
                [control]="control"
                class="col-span-1"
              />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.noteSubmitReason"
            [id]="'noteSubmitReason'"
            [label]="'ENTITY.Case.noteSubmitReason' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [formControl]="control"
                  [id]="id"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-3">
        <div
          [class]="'item col-span-sm-6'"
          [ngClass]="{ 'col-span-2': (isRescue$ | async) === true, 'col-span-3': (isRescue$ | async) === false }"
        >
          <tgn-form-element
            [control]="form.controls.entryViaType"
            [hideError]="true"
            [id]="'entryViaType'"
            [label]="'ENTITY.Case.entryViaType'"
            class="row-span-3 mt-medium"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select [choices]="entryViaTypeChoices" [control]="control" />
            </ng-template>
          </tgn-form-element>
        </div>

        <div
          [class]="'item col-span-sm-6'"
          [ngClass]="{ 'col-span-2': (isRescue$ | async) === true, 'col-span-3': (isRescue$ | async) === false }"
        >
          <tgn-form-element
            [control]="form.controls.entryDate"
            [hideError]="true"
            [id]="'entryDate'"
            [label]="'ENTITY.Case.entryDate'"
            class="row-span-3 mt-medium"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" />
            </ng-template>
          </tgn-form-element>
        </div>

        @if (form.controls.entryViaType.value === EntryViaType.Rescue) {
          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element
              [control]="form.controls.rescueNumber"
              [id]="'rescueNumber'"
              [label]="'ENTITY.Case.rescueNumber'"
              class="row-span-3 mt-medium"
            >
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [placeholder]="'ENTITY.Case.rescueNumber' | translate" [id]="id" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        }
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="form.controls.caseCreationDate"
            [id]="'caseCreationDate'"
            [label]="'ENTITY.Case.caseCreationDate'"
            class="row-span-3 mt-medium"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" />
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="form.controls.completeDate"
            [id]="'completeDate'"
            [label]="'ENTITY.Case.completeDate'"
            class="row-span-3 mt-medium"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      @if (case$ | async; as caseDto) {
        @if (caseDto.entryType === CaseEntryType.Findeltier) {
          <div class="row">
            <div class="item">
              <tgn-form-element
                [control]="form.controls.foundLocation"
                [id]="'foundLocation'"
                [label]="'ENTITY.Case.foundLocation'"
                class="row-span-3 mt-medium"
              >
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <input matInput [formControl]="control" [id]="id" [placeholder]="'ENTITY.Case.foundLocation' | translate" type="text" />
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        }
      }
    </form>
    <tgn-case-general-animals [case$]="case$" />

    @if (case$ | async; as caseDto) {
      @if (caseDto.state !== CaseState.Completed) {
        <button
          [tgnContextItem]="{ object: case$ }"
          [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE | andRoleRestriction: RESTRICT_CASE_WRITE"
          (click)="openCloseCaseDialog(caseDto)"
          class="tgn-button mt-medium"
          mat-flat-button
        >
          <span>{{ 'GENERAL.BUTTON.COMPLETE' | translate }}</span>
        </button>
      }
    }
  }
</tgn-narrow-page-container>
