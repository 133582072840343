<div class="content">
  <h1>Postfach</h1>

  <div class="action-container">
    <tgn-option-button (selectionChange)="changeFilteredRole($event)" [options]="options" [selectedValue]="activeFilter()" />

    <tgn-notification-filter (onFilterChange)="filterChange($event)" [filter]="filter" />

    <div class="select-filter-container">
      <div>
        <tgn-select [choices]="notificationTimePeriodChoices" [control]="timePeriodControl" [id]="'timePeriod'" />
      </div>
    </div>

    <div>
      <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{}" [matMenuTriggerFor]="menu" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <ng-template matMenuContent>
          <button
            (agreeEvent)="markAllAsRead()"
            [dialogDescription]="'Wollen Sie wirklich alle Benachrichtigungen als gelesen markieren?'"
            [dialogTitle]="'Alle als gelesen markieren?'"
            appConfirmationDialog
            mat-menu-item
          >
            <span>Alle als gelesen markieren</span>
          </button>
        </ng-template>
      </mat-menu>
    </div>
  </div>
  @if (notifications$) {
    @if (notifications$ | async; as notifications) {
      @for (notification of notifications; track notification.id) {
        <tgn-notification [notification]="notification" (onMarkAsRead)="refreshDatasource()" />
      }
      @if (notifications.length === 0) {
        <div class="no-notifications">Keine Benachrichtigungen vorhanden für diese Auswahl.</div>
      }
    }
  }

  @if (pagination.totalPages > 1) {
    <tgn-paginator
      (onPageEvent)="pageChanged($event)"
      [maxItemCount]="pagination.totalItems"
      [maxPages]="pagination.totalPages"
      [pageIndex]="pagination.pageIndex"
      [pageSize]="pagination.pageSize"
      class="paginator"
    ></tgn-paginator>
  }
</div>
