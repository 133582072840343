import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

interface TEnum {
  [key: string]: string | number;
}

export function enumValidator<T extends TEnum>(e: T): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = Object.values(e).includes(control.value);
    return isValid ? null : errorResponse();
  };
}

export function nullableEnumValidator<T extends TEnum>(e: T): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null) {
      return null;
    }
    return enumValidator(e)(control);
  };
}

export function enumArrayValidator<T extends TEnum>(e: T): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const values = (control.value ?? []) as T[];
    for (const value of values) {
      const validator = enumValidator(e);
      if (validator(<AbstractControl>{ value }) !== null) {
        return errorResponse();
      }
    }
    return null;
  };
}

function errorResponse() {
  return { enum: true };
}
