import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { NgIcon } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';

import { NavigationItem } from '../../models/navigation-item';

@Component({
  selector: 'tgn-nav-item',
  standalone: true,
  imports: [NgIcon, NgIf, RouterLink, MatButtonModule, CoreModule, TranslateModule, RouterLinkActive, RoleRestrictionDirective],
  templateUrl: './nav-item.component.html',
  styleUrl: './nav-item.component.scss',
})
export class NavItemComponent {
  @Input() navItem!: NavigationItem;
}
