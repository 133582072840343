import { AsyncPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { RelationKindDto, RelationKindOwner, isOwner } from '@baseData/dtos/relation-kind.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { CreateContactDto } from '@contact/dto/create-contact.dto';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { ModalComponent } from '@core/services/modal.service';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService } from '@user/service/access.service';
import { Observable, map } from 'rxjs';

import { ContactStatus, Salutation, SalutationEnum } from '../../dto/contact.dto';
import { ContactDialogForm, DEFAULT_COUNTRY } from '../contact-dialog/contact-dialog.component';

type LocalDateString = string;

export interface RelatedContact extends CreateContactDto {
  kind: RelationKindDto;
  fromDate: LocalDateString | null;
  toDate: LocalDateString | null;
}

interface DialogResult {
  dto: RelatedContact;
}

interface DialogData {
  contact?: RelatedContact;
  preselectedKind?: RelationKindDto;
  disabled?: boolean;
}

interface RelatedContactForm extends ContactDialogForm {
  kind: FormControl<RelationKindDto>;
  fromDate: FormControl<string | null>;
  toDate: FormControl<string | null>;
}

@Component({
  selector: 'app-related-contact-dialog',
  standalone: true,
  imports: [
    FormElementComponent,
    MatButtonModule,
    MatRadioModule,
    MatDialogModule,
    RadioGroupComponent,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormElementDirective,
    SelectComponent,
    DateInputComponent,
    AsyncPipe,
    FieldErrorTranslationPipe,
    MatInputModule,
    ToRadioChoicePipe,
    AddIfMissingPipe,
    RoleRestrictionDirective,
  ],
  templateUrl: './related-contact-dialog.component.html',
  styleUrl: './related-contact-dialog.component.scss',
})
export class RelatedContactDialogComponent extends ModalComponent<DialogData, DialogResult> {
  form?: FormGroup<RelatedContactForm>;

  salutationChoices: RadioChoice<Salutation>[] = createEnumChoices(SalutationEnum, 'GENERAL.DOMAIN.Salutation.');

  relationKinds$: Observable<RelationKindDto[]>;

  statusChoices: RadioChoice<ContactStatus>[] = createEnumChoices(ContactStatus, 'GENERAL.DOMAIN.ContactStatus.');

  data: DialogData;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    fb: FormBuilder,
    dialogRef: MatDialogRef<RelatedContactDialogComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private baseDataService: BaseDataService,
    private accessService: AccessService,
  ) {
    super(dialogRef);
    this.data = data;
    this.relationKinds$ = this.baseDataService.getBaseData().pipe(map(baseData => baseData.relationKinds));

    this.relationKinds$.subscribe((relationKinds: RelationKindDto[]) => {
      const fallbackKind = relationKinds.find(isOwner)!;
      this.form = createContactForm(fb, fallbackKind, data.contact, data.preselectedKind);

      if (this.data.disabled) {
        this.form.disable();
      }
      this.accessService.disableBasedOnRoleRestriction(this.form, GENERAL_WRITE_EXCLUDE);
    });
  }

  close() {
    const value = this.form!.getRawValue();

    this.closeWithResult({ dto: value });
  }
}

export function createContactForm(
  fb: FormBuilder,
  fallBackOwnerKind: RelationKindOwner,
  contact?: RelatedContact,
  preselectedKind?: RelationKindDto,
): FormGroup<RelatedContactForm> {
  const kind = preselectedKind ?? contact?.kind ?? fallBackOwnerKind;

  const isKindDisabled = !!preselectedKind;

  return fb.group<RelatedContactForm>(
    {
      salutation: fb.control<Salutation | null>(contact?.salutation ?? null),
      firstName: fb.control<string | null>(contact?.firstName ?? null),
      lastName: fb.control<string | null>(contact?.lastName ?? null, [Validators.required]),
      company: fb.control<string | null>(contact?.company ?? null),
      companyAddendum: fb.control<string | null>(contact?.companyAddendum ?? null),
      address: fb.control<string | null>(contact?.address ?? null, [Validators.required]),
      addressAddendum: fb.control<string | null>(contact?.addressAddendum ?? null),
      poBox: fb.control<string | null>(contact?.poBox ?? null),
      kind: fb.nonNullable.control<RelationKindDto>({ value: kind, disabled: isKindDisabled }),
      zip: fb.control<string | null>(contact?.zip ?? null, [Validators.required]),
      city: fb.control<string | null>(contact?.city ?? null, [Validators.required]),
      country: fb.control<string | null>(contact ? contact.country : DEFAULT_COUNTRY, [Validators.required]),
      phone: fb.control<string | null>(contact?.phone ?? null),
      phoneWork: fb.control<string | null>(contact?.phoneWork ?? null),
      mobile: fb.control<string | null>(contact?.mobile ?? null),
      email: fb.control<string | null>(contact?.email ?? null, [Validators.email]),
      possibleDonor: fb.nonNullable.control<boolean>(contact?.possibleDonor ?? false),
      noMailings: fb.nonNullable.control<boolean>(contact?.noMailings ?? false),
      isDead: fb.nonNullable.control<boolean>(contact?.isDead ?? false),
      status: fb.nonNullable.control<ContactStatus>(contact?.status ?? ContactStatus.New),
      legalRepresentationId: fb.control<string | null>(contact?.legalRepresentationId ?? null),
      fromDate: fb.control<string | null>(contact?.fromDate ?? null),
      toDate: fb.control<string | null>(contact?.toDate ?? null),
      note: fb.control<string | null>(contact?.note ?? null),
    },
    {},
  );
}
