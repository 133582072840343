interface Person {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
}

export function fullName(person: Person): string | undefined {
  if (!person.firstName && !person.lastName) {
    return undefined;
  }

  if (!person.firstName) {
    return person.lastName ?? undefined;
  }

  if (!person.lastName) {
    return person.firstName ?? undefined;
  }

  return `${person.firstName} ${person.lastName}`;
}
