import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base-service';
import { IsoLocalDateString } from '@core/utils/date';
import { Observable } from 'rxjs';

import { CreateVatTaxDto, VatTaxDto, VatTaxGroup } from '../dtos/vat-tax.dto';

@Injectable({
  providedIn: 'root',
})
export class VatTaxService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getAll(): Observable<VatTaxDto[]> {
    return this.http.get<VatTaxDto[]>(`${this.apiUrl}/master/vatTax`);
  }

  getCurrent(): Observable<VatTaxGroup> {
    return this.http.get<VatTaxGroup>(`${this.apiUrl}/master/vatTax/current`);
  }

  getAtDate(date: IsoLocalDateString): Observable<VatTaxGroup> {
    return this.http.get<VatTaxGroup>(`${this.apiUrl}/master/vatTax/date/${date}`);
  }

  create(dto: CreateVatTaxDto): Observable<VatTaxDto> {
    return this.http.post<VatTaxDto>(`${this.apiUrl}/master/vatTax`, dto);
  }
}
