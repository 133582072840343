<div class="files">
  @for (file of files; track file.filename) {
    <div class="file">
      @if (
        file.contentType === 'image/jpeg' ||
        file.contentType === 'image/jpg' ||
        file.contentType === 'image/png' ||
        file.contentType === 'image/webp'
      ) {
        <ng-container>
          <div class="preview">
            <img [src]="file | file | async" alt="" />
          </div>
          <div class="title">{{ file.filename }}</div>
        </ng-container>
      } @else {
        <ng-container>
          <a class="title link-primary" href="#" fileDownload [downloadFile]="true" [file]="file">
            {{ file.filename }}
          </a>
        </ng-container>
      }
      <div class="actions">
        <button
          [tgnRoleRestriction]="roleRestriction"
          (click)="onFileDeleted.emit(file)"
          mat-flat-button
          class="img-delete-button"
          type="button"
        >
          <div class="actions">
            <tgn-icon [iconName]="'radixCross1'" />
            <span class="ml-md">{{ 'GENERAL.BUTTON.DELETE' | translate }}</span>
          </div>
        </button>
      </div>
    </div>
  }
</div>
