import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { routes_config } from '@core/constants';
import { CoreModule } from '@core/core.module';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { UserService } from '@user/service/user.service';

import { environment } from '../../../../environments/environment';

interface LoginForm {
  email: FormControl<string>;
  password: FormControl<string>;
}

export enum PageMode {
  Login = 'Login',
  PasswordReset = 'PasswordReset',
}

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    FormElementComponent,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule,
    FormElementDirective,
    IconComponent,
  ],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent {
  form: FormGroup<LoginForm>;
  mode: PageMode = PageMode.Login;
  protected readonly environment = environment;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly PageMode = PageMode;

  constructor(
    fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private snackbar: SnackbarService,
    private router: Router,
  ) {
    this.form = fb.group({
      email: fb.nonNullable.control('', [Validators.required, Validators.email]),
      password: fb.nonNullable.control('', [Validators.required]),
    });
  }

  async loginWithPassword() {
    if (this.form.invalid) {
      return;
    }

    const { email, password } = this.form.getRawValue();
    await this.authService.loginWithPassword(email.trim(), password);

    await this.router.navigate(routes_config.CHOOSE_TEAM_AND_STATION.url());
  }

  async loginWithGoogle() {
    await this.authService.loginWithGoogle();
  }

  async loginWithMicrosoft() {
    await this.authService.loginWithMicrosoftAzure();
  }

  showResetPassword() {
    this.mode = PageMode.PasswordReset;
  }

  sendResetPasswordEmail() {
    const { email } = this.form.getRawValue();
    this.userService.sendForgotPasswordLink(email).subscribe({
      next: () => {
        this.snackbar.showSuccessMessage('PAGE.LOGIN.FEEDBACK.SENT_PASSWORD_RESET_EMAIL');
        this.mode = PageMode.Login;
      },
      error: (error: unknown) => {
        console.error('could not send password reset email: ', error);
        this.snackbar.showErrorMessage('PAGE.LOGIN.FEEDBACK.EMAIL_SEND_ERROR');
      },
    });
  }

  switchToLoginMode() {
    this.mode = PageMode.Login;
  }
}
