import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { AnimalTitlePipe } from '@animal/pipes/animal-title.pipe';
import { ExitState } from '@baseData/dtos/exit-state.dto';
import { BaseDataDto } from '@case/dtos/base-data.dto';
import { CompactCaseAnimalDto } from '@case/dtos/case-animal.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { DomesticCaseAnimalDto } from '@case/dtos/domestic-case-animal.dto';
import { CaseGeneralPlacementDto, PlacementDetailDto } from '@case/dtos/placement.dto';
import { UpdateStmzDto } from '@case/dtos/update-stmz.dto';
import { CreateStmzLinkPipe } from '@case/pipes/create-stmz-link.pipe';
import { BaseDataService } from '@case/services/base-data-service';
import { CaseAnimalService } from '@case/services/case-animal.service';
import { ContactDto } from '@contact/dto/contact.dto';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { CheckboxGroupComponent } from '@core/components/checkbox-group/checkbox-group.component';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { routes_config } from '@core/constants';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AnimalType, PlacementState, PlacementType } from '@core/models/general';
import { AndRoleRestrictionPipe, RESTRICT_CASE_WRITE } from '@core/models/role';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { ContactDisplayPipe } from '@core/pipes/contact-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { ListIncludesPipe } from '@core/pipes/list-includes.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { defaultDebounce } from '@core/services/base-service';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, filter, map, take } from 'rxjs';

import { OwnerTakeOverDialogResult, OwnerTakeoverDialogComponent } from '../../owner-takeover-dialog/owner-takeover-dialog.component';

interface PlacementForm {
  isPublishDisallowed: FormControl<boolean | null>;
  placementType: FormControl<PlacementType | null>;
  placementState: FormControl<PlacementState | null>;
  placementNote: FormControl<string | null>;
  exitState: FormControl<ExitState | null>;
  exitDate: FormControl<IsoLocalDateString | null>;
  exitNote: FormControl<string | null>;
  previousTakeoverContact: FormControl<ContactDto | null>;
  takeoverContact: FormControl<ContactDto | null>;
}

interface StmzForm {
  stmzNumber: FormControl<string | null>;
  stmzDate: FormControl<IsoLocalDateString | null>;
  stmzDeadline1: FormControl<IsoLocalDateString | null>;
  stmzDeadline2: FormControl<IsoLocalDateString | null>;
}

@Component({
  selector: 'tgn-single-animal-placement',
  standalone: true,
  imports: [
    CollapsibleComponent,
    AnimalTitlePipe,
    TranslateModule,
    FormElementComponent,
    FormElementDirective,
    ReactiveFormsModule,
    CheckboxGroupComponent,
    ListIncludesPipe,
    MatCheckboxModule,
    MatInputModule,
    SelectComponent,
    MatButtonModule,
    DateInputComponent,
    RouterLink,
    AutocompleteComponent,
    ContactDisplayPipe,
    IconComponent,
    DatePipe,
    FullNamePipe,
    AsyncPipe,
    SingleLineTextComponent,
    ToRadioChoicePipe,
    AddIfMissingPipe,
    RoleRestrictionDirective,
    AndRoleRestrictionPipe,
    CreateStmzLinkPipe,
  ],
  templateUrl: './single-animal-placement.component.html',
  styleUrl: './single-animal-placement.component.scss',
})
export class SingleAnimalPlacementComponent implements OnInit {
  @Input({ required: true }) animal!: AnimalDto;
  @Input({ required: true }) case$!: Observable<CaseDetailDto>;
  case: CaseDetailDto | null = null;
  @Output() onOwnerChanged = new EventEmitter<void>();

  placement?: PlacementDetailDto;
  generalPlacement?: CaseGeneralPlacementDto;

  placementForm?: FormGroup<PlacementForm>;
  stmzForm?: FormGroup<StmzForm>;
  placementTypeChoices: RadioChoice<PlacementType>[] = createEnumChoices(PlacementType, 'GENERAL.DOMAIN.PlacementType.');
  placementStateChoices: RadioChoice<PlacementState>[] = createEnumChoices(PlacementState, 'GENERAL.DOMAIN.PlacementState.');
  exitStates$!: Observable<ExitState[]>;
  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RESTRICT_CASE_WRITE = RESTRICT_CASE_WRITE;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private caseAnimalService: CaseAnimalService,
    private router: Router,
    private baseDataService: BaseDataService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.exitStates$ = this.baseDataService.getBaseData().pipe(map((baseData: BaseDataDto) => baseData.exitStates));

    this.case$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((caseDto: CaseDetailDto) => {
      this.case = caseDto;
      this.caseAnimalService
        .getGeneralCasePlacement(this.case.id, this.animal.id)
        .pipe(take(1))
        .subscribe((placement: CaseGeneralPlacementDto) => {
          this.generalPlacement = placement;
          this.createPlacementForm(placement);
          this.autoSavePlacement();
        });

      if (this.case.animalType === AnimalType.DomesticAnimal) {
        this.caseAnimalService
          .getDomestic(this.case.id, this.animal.id)
          .pipe(take(1))
          .subscribe((domesticCaseAnimal: DomesticCaseAnimalDto) => {
            this.createStmzForm(domesticCaseAnimal);
            this.autoSaveStmz();
          });
      }
    });
  }

  updateIsPublishDisallowed($event: MatCheckboxChange) {
    this.placementForm!.controls.isPublishDisallowed.setValue($event.checked);
  }

  openNewPlacementModal() {
    this.modalService
      .open(
        OwnerTakeoverDialogComponent,
        {
          animal: this.animal,
          case: this.case!,
          placement: this.generalPlacement!,
        },
        { width: ModalWidth.Medium },
      )
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe((result: OwnerTakeOverDialogResult) => {
        const newPlacement = result.newPlacement;
        this.generalPlacement = result.newPlacement;
        this.placementForm?.patchValue({
          previousTakeoverContact: newPlacement.previousTakeoverContact,
          takeoverContact: newPlacement.takeoverContact,
        });
        if (result.withNewCase) {
          this.snackbar.showSuccessMessage('PAGE.PLACEMENT.FEEDBACK.TAKEOVER_NEW_CASE');
        } else {
          this.snackbar.showSuccessMessage('PAGE.PLACEMENT.FEEDBACK.TAKEOVER_SUCCESSFUL');
        }
      });
  }

  routeToNewCase() {
    this.router.navigate(this.appRoutes.CASE_DETAIL_GENERAL.url(this.generalPlacement!.takeoverCase!.id), {
      onSameUrlNavigation: 'reload',
    });
  }

  private autoSavePlacement() {
    this.placementForm!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
      const formValues = this.placementForm!.getRawValue();
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.caseAnimalService.updateGeneralPlacement(this.case!.id, this.animal.id, formValues).subscribe(() => {});
    });
  }

  private createPlacementForm(placement: CaseGeneralPlacementDto) {
    const fb = this.formBuilder;

    const formValues = {
      isPublishDisallowed: fb.control<boolean | null>(placement.isPublishDisallowed),
      exitState: fb.control<ExitState | null>(placement.exitState),
      exitDate: fb.control<IsoLocalDateString | null>(placement.exitDate),
      exitNote: fb.control<string | null>(placement.exitNote),
      placementType: fb.control<PlacementType | null>(placement.placementType),
      placementState: fb.control<PlacementState | null>(placement.placementState),
      placementNote: fb.control<string | null>(placement.placementNote),
      previousTakeoverContact: fb.control<ContactDto | null>({
        value: placement.previousTakeoverContact,
        disabled: true,
      }),
      takeoverContact: fb.control<ContactDto | null>({ value: placement.takeoverContact, disabled: true }),
    };
    this.placementForm = fb.group<PlacementForm>(formValues);

    if (this.case?.archived || this.animal.archived) {
      this.placementForm.disable();
    }
    this.accessService.disableBasedOnRole(this.placementForm, RestrictedSection.Case);
  }

  private createStmzForm(domesticCaseAnimal: DomesticCaseAnimalDto) {
    const fb = this.formBuilder;
    const formValues = {
      stmzNumber: fb.control<string | null>(domesticCaseAnimal.stmzNumber),
      stmzDate: fb.control<IsoLocalDateString | null>(domesticCaseAnimal.stmzDate),
      stmzDeadline1: fb.control<IsoLocalDateString | null>(domesticCaseAnimal.stmzDeadline1),
      stmzDeadline2: fb.control<IsoLocalDateString | null>(domesticCaseAnimal.stmzDeadline2),
    };
    this.stmzForm = fb.group<StmzForm>(formValues);

    if (this.case?.archived || this.animal.archived) {
      this.stmzForm.disable();
    }
    this.accessService.disableBasedOnRole(this.stmzForm, RestrictedSection.Case);
  }

  private autoSaveStmz() {
    if (!this.stmzForm) {
      return;
    }
    this.stmzForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
      const dto: UpdateStmzDto = this.stmzForm!.getRawValue();
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.caseAnimalService.updateStmz(this.case!.id, this.animal.id, dto).subscribe((compactCaseAnimalDto: CompactCaseAnimalDto) => {
        this.stmzForm!.patchValue(
          {
            stmzNumber: compactCaseAnimalDto.stmzNumber,
            stmzDate: compactCaseAnimalDto.stmzDate,
            stmzDeadline1: compactCaseAnimalDto.stmzDeadline1,
            stmzDeadline2: compactCaseAnimalDto.stmzDeadline2,
          },
          { emitEvent: false },
        );
      });
    });
  }
}
