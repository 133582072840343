import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { AnimalId } from '@animal/dtos/animal.dto';
import { BaseDataPositionDto } from '@baseData/dtos/base-data-position.dto';
import { VatTaxGroup } from '@baseData/dtos/vat-tax.dto';
import { VatTaxDisplayPipe } from '@baseData/pipes/vat-tax-display.pipe';
import { BaseDataPositionService } from '@baseData/services/base-data-position.service';
import { BillDto, BillStatus } from '@bill/dto/bill.dto';
import { BillingPeriod } from '@bill/dto/billing-period.dto';
import { CreatePositionDto, PositionDto, UpdatePositionDto } from '@bill/dto/position.dto';
import { ResultTotalCalculationsDto } from '@bill/dto/total-calculations-result.dto';
import { PositionTotalPipe, PositionValues, calculatePositionTotal } from '@bill/pipes/position-total.pipe';
import { BillService } from '@bill/services/bill.service';
import { PositionService } from '@bill/services/position.service';
import { CaseId } from '@case/dtos/case.dto';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SelectComponent } from '@core/components/select/select.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AndRoleRestrictionPipe } from '@core/models/role';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FvsCurrencyPipe } from '@core/pipes/currency.pipe';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { IncludeItemPipe } from '@core/pipes/include.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { defaultDebounce } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TigonValidators } from '@core/utils/validators';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, combineLatest, map, of, startWith, switchMap, take } from 'rxjs';

export type PositionForm = FormGroup<{
  baseDataPosition: FormControl<BaseDataPositionDto | null>;
  description: FormControl<string | null>;
  price: FormControl<number | null>;
  amount: FormControl<number | null>;
  discountPercentage: FormControl<number | null>;
}>;

export type NewPositionForm = FormGroup<{
  baseDataPosition: FormControl<BaseDataPositionDto | null>;
  description: FormControl<string | null>;
  price: FormControl<number | null>;
  amount: FormControl<number | null>;
  discountPercentage: FormControl<number | null>;
}>;

type PositionId = string;

export enum BillMode {
  CreateBill = 'CreateBill',
  EditBill = 'EditBill',
  ViewBill = 'ViewBill',
}

export enum BillServicesPositionContext {
  Case = 'Case',
  CaseAnimal = 'CaseAnimal',
}

@Component({
  selector: 'app-bill-services-position-table',
  standalone: true,
  imports: [
    AsyncPipe,
    ConfirmationDialogDirective,
    EnumDisplayPipe,
    FormElementComponent,
    ScrollableTableComponent,
    MatTableModule,
    TypesafeMatTableModule,
    TranslateModule,
    ToRadioChoicePipe,
    SelectComponent,
    MatTooltip,
    IconComponent,
    FvsCurrencyPipe,
    MatFormField,
    ReactiveFormsModule,
    VatTaxDisplayPipe,
    PositionTotalPipe,
    MatMenuModule,
    MatButtonModule,
    MatIcon,
    RouterLink,
    MatCheckbox,
    MatInput,
    FormElementDirective,
    IncludeItemPipe,
    SingleLineTextComponent,
    RoleRestrictionDirective,
    AndRoleRestrictionPipe,
  ],
  templateUrl: './bill-services-position-table.component.html',
  styleUrl: './bill-services-position-table.component.scss',
})
export class BillServicesPositionTableComponent implements OnInit {
  @Input({ required: true }) datasource!: TigonDatasource<PositionDto, { removedPositions: string[] }>;
  @Input({ required: true }) billingPeriod$!: Observable<BillingPeriod>;
  @Input({ required: true }) positionContext!: BillServicesPositionContext;

  @Input({ required: true }) animalId!: AnimalId | null;
  @Input({ required: true }) caseId!: CaseId;

  @Input({ required: true }) bill: BillDto | null = null;
  @Input({ required: true }) usedVatTax!: VatTaxGroup;
  @Output() onAddPosition: EventEmitter<CreatePositionDto> = new EventEmitter<CreatePositionDto>();
  @Output() onUpdatePositions: EventEmitter<void> = new EventEmitter<void>();

  billingPeriod: BillingPeriod | null = null;

  mode!: BillMode;

  total$?: Observable<ResultTotalCalculationsDto>;

  totalPages = 1;
  totalItems = 0;

  newPositionForm!: NewPositionForm;
  currentSelectedBasePosition: BaseDataPositionDto | null = null;
  baseDataPositions$!: Observable<BaseDataPositionDto[]>;
  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  columns = ['baseDataPosition', 'description', 'amount', 'type', 'price', 'discountPercentage', 'vatTaxType', 'total', 'actions'];
  removedPositions: string[] = [];
  appRoutes = routes_config;
  positionFormGroups: Map<PositionId, PositionForm> = new Map<PositionId, PositionForm>();
  newPositionTotal$: Observable<number | null> = of(null);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly BillMode = BillMode;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly BillStatus = BillStatus;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private positionService: PositionService,
    private snackbar: SnackbarService,
    private baseDataPositionService: BaseDataPositionService,
    private billService: BillService,
    private fb: FormBuilder,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    if (!this.bill) {
      this.mode = BillMode.CreateBill;
    } else {
      if (this.bill.status === BillStatus.Open) {
        this.mode = BillMode.EditBill;
      } else {
        this.mode = BillMode.ViewBill;
      }
    }

    this.billingPeriod$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((billingPeriod: BillingPeriod) => {
      this.billingPeriod = billingPeriod;
    });

    this.datasource.getData().subscribe(data => {
      this.positionFormGroups = new Map();
      data
        .filter((position: PositionDto) => {
          // billed positions are not updateable!
          return position.bill === null || position.bill.status === BillStatus.Open;
        })
        .forEach((position: PositionDto) => {
          const newFormGroup = this.fb.group({
            baseDataPosition: this.fb.control(position.baseDataPosition, [Validators.required]),
            description: this.fb.control(position.description),
            price: this.fb.control(position.price, [Validators.required]),
            amount: this.fb.control(position.amount, [Validators.required]),
            discountPercentage: this.fb.control(position.discountPercentage, [TigonValidators.discountPercentageValidator]),
          });

          this.accessService.disableBasedOnRole(newFormGroup, RestrictedSection.Case);

          this.positionFormGroups.set(position.id, newFormGroup);
          this.autoUpdateRows(newFormGroup, position);
        });
    });

    const isExcludePension = this.positionContext === BillServicesPositionContext.Case;
    this.baseDataPositions$ = this.baseDataPositionService.getValidForSelection(isExcludePension).pipe(take(1));

    this.createNewPositionForm();

    this.accessService.disableBasedOnRole(this.newPositionForm, RestrictedSection.Case);

    this.autoCalculateAmount();

    this.newPositionForm.controls.baseDataPosition.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.currentSelectedBasePosition = this.newPositionForm.controls.baseDataPosition.value;
      if (this.currentSelectedBasePosition) {
        this.newPositionForm.controls.description.setValue(this.currentSelectedBasePosition.description ?? null, { onlySelf: true });
        this.newPositionForm.controls.price.setValue(this.currentSelectedBasePosition.price ?? null, { onlySelf: true });
      }
    });

    this.total$ = combineLatest([this.datasource.getData(), this.onUpdatePositions.pipe(startWith(null))]).pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap(([positions]) => {
        // @TODO:BILLING -> passs already paid amount properly!, also check used vat tax
        return this.billService.calculateBillTotal(
          this.bill?.status == BillStatus.NewStorno,
          positions,
          this.usedVatTax,
          this.bill?.alreadyPaidAmount ?? 0,
        );
      }),
    );
  }

  deletePosition(position: PositionDto) {
    this.positionService.delete(position.id).subscribe(() => {
      this.snackbar.showSuccessMessage('Position wurde gelöscht');
      this.datasource?.update({});
    });
  }

  removePositionFromBill(position: PositionDto) {
    this.removedPositions.push(position.id);
    this.datasource.update({ removedPositions: this.removedPositions });
  }

  addEntry() {
    const form = this.newPositionForm.getRawValue();
    if (!form.baseDataPosition || !form.amount || !form.price) {
      return;
    }

    const createPositionDto: CreatePositionDto = {
      baseDataPositionId: form.baseDataPosition.id,
      description: form.description,
      price: form.price,
      amount: form.amount,
      discountPercentage: form.discountPercentage,
    };

    let createObservable: Observable<PositionDto>;
    if (this.positionContext === BillServicesPositionContext.CaseAnimal) {
      createObservable = this.positionService.createAnimalPosition(
        {
          caseId: this.caseId,
          animalId: this.animalId!,
        },
        createPositionDto,
      );
    } else {
      createObservable = this.positionService.createCasePosition(this.caseId, createPositionDto);
    }
    createObservable.pipe(take(1)).subscribe({
      next: () => {
        this.onAddPosition.emit(createPositionDto);
      },
      error: err => {
        console.error('error', err);
      },
      complete: () => {
        this.newPositionForm.setValue(
          {
            baseDataPosition: null,
            price: null,
            description: null,
            amount: null,
            discountPercentage: null,
          },
          { emitEvent: false },
        );
        this.newPositionForm.markAsUntouched();
        this.newPositionForm.markAsPristine();
      },
    });
  }

  removeAllPositionsFromBill() {
    this.datasource
      .getData()
      .pipe(take(1))
      .subscribe((positions: PositionDto[]) => {
        this.removedPositions = [...this.removedPositions, ...positions.map(it => it.id)];
        this.datasource.update({ removedPositions: this.removedPositions });
      });
  }

  private createNewPositionForm() {
    this.newPositionForm = this.fb.group({
      baseDataPosition: this.fb.control<BaseDataPositionDto | null>(null, [Validators.required]),
      price: this.fb.control<number | null>(null, [Validators.required]),
      description: this.fb.control<string | null>(null),
      amount: this.fb.control<number | null>(null, [Validators.required]),
      discountPercentage: this.fb.control<number | null>(null, [TigonValidators.discountPercentageValidator]),
    });
  }

  private autoUpdateRows(formGroup: PositionForm, position: PositionDto) {
    formGroup.valueChanges.pipe(defaultDebounce(), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        if (formGroup.invalid) {
          return;
        }

        const formValues = formGroup.getRawValue();
        if (formValues.amount == null || formValues.baseDataPosition == null || formValues.price == null) {
          return;
        }
        const dto: UpdatePositionDto = {
          baseDataPositionId: formValues.baseDataPosition.id,
          description: formValues.description,
          price: formValues.price,
          amount: formValues.amount,
          discountPercentage: formValues.discountPercentage,
        };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.positionService.update(position.id, dto).subscribe((result: PositionDto) => {
          position.amount = result.amount;
          position.price = result.price;
          position.description = result.description;
          position.discountPercentage = result.discountPercentage;
          position.baseDataPosition = result.baseDataPosition;
          this.onUpdatePositions.emit();
        });
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('PAGE.ADD_POSITIONS.ERRORS.COULD_NOT_UPDATE_POSITION');
      },
    });
  }

  private autoCalculateAmount() {
    this.newPositionTotal$ = this.newPositionForm.valueChanges.pipe(
      defaultDebounce(),
      takeUntilDestroyed(this.destroyRef),
      map(() => {
        const formValues = this.newPositionForm.getRawValue();
        if (formValues.baseDataPosition == null || formValues.amount == null || formValues.price == null) {
          return null;
        }
        const positionValues: PositionValues = {
          amount: formValues.amount,
          price: formValues.price,
          discountPercentage: formValues.discountPercentage,
          vatTaxType: formValues.baseDataPosition.vatTaxType,
          positionVatTax: null,
        };
        return calculatePositionTotal(this.bill?.status == BillStatus.NewStorno, positionValues, this.usedVatTax).absoluteTotal;
      }),
    );
  }
}
