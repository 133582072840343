import { GroupDto, SpeciesDto, SubGroupDto } from '@case/dtos/base-data.dto';
import { CompactAnimalDto } from '@case/dtos/case-animal.dto';

import { AnimalDto } from '../dtos/animal.dto';

export function displayAnimalAutocomplete(animalDto: AnimalDto | null): string {
  if (animalDto == null) {
    return '';
  }

  const values: string[] = [animalDto.chipId, animalDto.name, animalDto.group?.name].filter((value: string | null | undefined) => {
    return value !== undefined && value !== null && value.length > 0;
  }) as string[];

  return values.join(' - ');
}

export interface AnimalTaxonomyBaseData {
  groups: GroupDto[];
  subGroups: SubGroupDto[];
  species: SpeciesDto[];
}

export interface AnimalTaxonomy {
  group: GroupDto | null;
  subGroup: SubGroupDto | null;
  species: SpeciesDto | null;
}

/**
 *  If group,subGroup and species selection change then automatically update the other fields
 *  based on previous and current values. These are hierarchical (group, subGroup, species) so
 *  if the hierarchy above changes we have to set the values below to null. However, it is possible
 *  to directly choose a species if no group or subGroup is set. In this case automatically set the
 *  hierarchies above.
 */
export function getNextGroupSubgroupSpecies(
  previous: AnimalTaxonomy,
  current: AnimalTaxonomy,
  baseData: AnimalTaxonomyBaseData,
): AnimalTaxonomy {
  let { group, subGroup, species } = current;
  // if a species is selected, automatically set parent and grandparent
  if (species && !previous.species) {
    if (subGroup?.id !== species.subGroupId) {
      subGroup = baseData.subGroups.find(sg => sg.id === species!.subGroupId) ?? null;
    }
    if (group?.id !== subGroup!.groupId) {
      group = baseData.groups.find(g => g.id === subGroup!.groupId) ?? null;
    }
  } else if (subGroup && !previous.subGroup) {
    if (group?.id !== subGroup.groupId) {
      group = baseData.groups.find(g => g.id === subGroup!.groupId) ?? null;
    }
  }

  // if group or subgroup is removed then also remove lower hierarchy elements
  if (previous.group && !group) {
    subGroup = null;
    species = null;
  } else if (previous.subGroup && !subGroup) {
    species = null;
  }

  // if a different group is selected, reset subGroup and species
  if (previous.group?.id != current.group?.id) {
    subGroup = null;
    species = null;
  } else if (previous.subGroup?.id != current.subGroup?.id && current.species?.subGroupId !== current.subGroup?.id) {
    species = null;
  }

  return { group, subGroup, species };
}

export function getAnimalName(animal: AnimalDto | CompactAnimalDto): string {
  if (animal.name != null && animal.name.trim().length > 0) {
    return animal.name;
  }
  return `Tier: ${animal.trdId}`;
}
