import { CdkStepperModule } from '@angular/cdk/stepper';
import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnimalTaxonomy, AnimalTaxonomyBaseData, getNextGroupSubgroupSpecies } from '@animal/model/animal';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { StepperComponent } from '@core/components/stepper/stepper.component';
import { enumArrayValidator, enumValidator, nullableEnumValidator } from '@core/models/form/enum.validator';
import {
  AffectedGeneralRegion,
  AffectedLimb,
  AnimalSex,
  ExtendedAffectedRegion,
  FurLength,
  GeneralFieldChoice,
  OpenBooleanChoice,
  Parasite,
  RoughAge,
  UnitsLength,
  UnitsWeight,
  UnknownBooleanChoice,
} from '@core/models/general';
import { defaultDebounce } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TigonValidators } from '@core/utils/validators';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, ReplaySubject, combineLatest, map, take } from 'rxjs';

import { BaseDataDto, BoxDto, GroupDto, GroupTag, SpeciesDto, SubGroupDto } from '../../dtos/base-data.dto';
import { CompactCaseAnimalDto } from '../../dtos/case-animal.dto';
import { DomesticCaseAnimalDto } from '../../dtos/domestic-case-animal.dto';
import { UpdateDomesticCaseAnimalDto } from '../../dtos/update-domestic-case-animal.dto';
import { CurrentAnimalContext } from '../../pages/entry-check-page/entry-check-page.component';
import { BaseDataService } from '../../services/base-data-service';
import { CaseAnimalService } from '../../services/case-animal.service';
import { AnimalEntryFormComponent, AnimalForm } from '../animal-entry-form/animal-entry-form.component';
import { HealthFormComponent } from '../health-form/health-form.component';
import { EntryCheckForm } from '../health-form/models/form';
import { ParticularitiesForm, ParticularitiesFormComponent } from '../particularities-form/particularities-form.component';

interface CaseAnimalForm {
  animalForm: FormGroup<AnimalForm>;
  entryCheckForm: FormGroup<EntryCheckForm>;
  particularitiesForm: FormGroup<ParticularitiesForm>;
}

@Component({
  selector: 'app-entry-check',
  standalone: true,
  imports: [
    AnimalEntryFormComponent,
    AsyncPipe,
    CdkStepperModule,
    HealthFormComponent,
    ParticularitiesFormComponent,
    StepperComponent,
    TranslateModule,
    NarrowPageContainerComponent,
  ],
  templateUrl: './entry-check.component.html',
  styleUrl: './entry-check.component.scss',
})
export class EntryCheckComponent implements OnInit {
  @Input({ required: true }) currentAnimalContext$!: ReplaySubject<CurrentAnimalContext>;
  @Input() resetStepperIndex$: Observable<void> = new ReplaySubject<void>(1);

  @Input({ required: true }) showPreviousInFirstStep = false;
  @Input() firstPreviousLabel = 'GENERAL.BUTTON.CANCEL';
  @Input({ required: true }) lastActionLabel = 'GENERAL.BUTTON.FINISH';

  @Output() onFirstPreviousAction = new EventEmitter<CurrentAnimalContext>();
  @Output() onLastAction = new EventEmitter<CurrentAnimalContext>();

  boxes$!: Observable<BoxDto[]>;

  stepperContinueDisabled = false;
  animalForm!: FormGroup<AnimalForm>;
  entryCheckForm!: FormGroup<EntryCheckForm>;

  form!: FormGroup<CaseAnimalForm>;

  particularitiesForm!: FormGroup<ParticularitiesForm>;

  lastTaxonomy: AnimalTaxonomy = {
    group: null,
    subGroup: null,
    species: null,
  };

  @ViewChild('stepper') stepper?: StepperComponent;
  taxonomyBaseData: AnimalTaxonomyBaseData = {
    groups: [],
    subGroups: [],
    species: [],
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private caseAnimalService: CaseAnimalService,
    private baseDataService: BaseDataService,
    private snackbar: SnackbarService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.currentAnimalContext$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((context: CurrentAnimalContext) => {
      this.lastTaxonomy = {
        group: context.caseAnimalDto.caseAnimalData.group,
        subGroup: context.caseAnimalDto.caseAnimalData.subGroup,
        species: context.caseAnimalDto.caseAnimalData.species,
      };

      this.baseDataService
        .getTaxonomy(context.caseAnimalDto.animal.type)
        .pipe(take(1))
        .subscribe(taxonomyBaseData => {
          this.taxonomyBaseData = taxonomyBaseData;
        });

      this.createForms();

      this.setFormValuesWithoutEvent(context.caseAnimalDto);

      this.autoUpdateForms(context);

      const caseAnimalData = context.caseAnimalDto.caseAnimalData;
      this.preselectWeightUnit(caseAnimalData.group, caseAnimalData.subGroup);

      this.updateStepperContinue();

      this.animalForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.updateStepperContinue();
      });

      combineLatest([this.animalForm.controls.group.valueChanges, this.animalForm.controls.subGroup.valueChanges])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(([groupDto, subGroupDto]) => {
          if (groupDto == null) {
            return;
          }

          this.preselectWeightUnit(groupDto, subGroupDto);
        });
    });

    this.resetStepperIndex$.subscribe(() => {
      if (!this.stepper) {
        return;
      }
      this.stepper.selectedIndex = 0;
    });

    this.boxes$ = this.baseDataService.getBaseData().pipe(map((baseData: BaseDataDto) => baseData.boxes));
  }

  updateCaseAnimal(context: CurrentAnimalContext, newCaseAnimal: CompactCaseAnimalDto) {
    this.caseAnimalService
      .getDomestic(context.caseDto.id, newCaseAnimal.animal.id)
      .pipe(take(1))
      .subscribe((newCaseAnimalDto: DomesticCaseAnimalDto) => {
        context.caseDto.caseAnimals[context.animalIndex] = newCaseAnimal;

        const newContext: CurrentAnimalContext = {
          ...context,
          caseAnimalDto: newCaseAnimalDto,
        };

        this.currentAnimalContext$.next(newContext);
      });
  }

  stepChanged() {
    this.updateStepperContinue();
  }

  private autoUpdateForms(context: CurrentAnimalContext) {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
      const currentAnimalForm = this.animalForm.getRawValue();

      const currentTaxonomy = {
        group: currentAnimalForm.group,
        subGroup: currentAnimalForm.subGroup,
        species: currentAnimalForm.species,
      };

      const nextTaxonomy = getNextGroupSubgroupSpecies(
        {
          group: this.lastTaxonomy.group,
          subGroup: this.lastTaxonomy.subGroup,
          species: this.lastTaxonomy.species,
        },
        {
          group: currentTaxonomy.group,
          subGroup: currentTaxonomy.subGroup,
          species: currentTaxonomy.species,
        },
        this.taxonomyBaseData,
      );

      this.lastTaxonomy = nextTaxonomy;

      this.animalForm.patchValue(
        {
          group: nextTaxonomy.group,
          subGroup: nextTaxonomy.subGroup,
          species: nextTaxonomy.species,
        },
        { onlySelf: true },
      );

      const dto: UpdateDomesticCaseAnimalDto = {
        caseAnimalData: this.animalForm.getRawValue(),
        entryCheck: { ...this.particularitiesForm.getRawValue(), ...this.entryCheckForm.getRawValue() },
      };

      const isChipSet: boolean =
        (dto.caseAnimalData.chipId !== null && dto.caseAnimalData.chipId.length > 0) ||
        (dto.caseAnimalData.ringId !== null && dto.caseAnimalData.ringId.length > 0);

      if (isChipSet && !dto.caseAnimalData.hasChip) {
        this.animalForm.controls.hasChip.setValue(true, { onlySelf: true, emitEvent: false });

        dto.caseAnimalData.hasChip = true;
      }

      const caseAnimals: CompactCaseAnimalDto = context.caseDto.caseAnimals[context.animalIndex];
      this.caseAnimalService.updateDomestic(context.caseDto.id, caseAnimals.animal.id, dto).subscribe({
        error: (err: unknown) => {
          console.error(`Error updating caseAnimal, caseId: ${context.caseDto.id}, animalIndex: ${context.animalIndex}, err: `, err);
          //this.snackbar.showErrorMessage('PAGE.ENTRY_CHECK.ERROR.AUTO_UPDATE_ERROR');
        },
      });

      this.updateStepperContinue();
    });
  }

  private createForms() {
    const fb = this.fb;
    this.animalForm = fb.group({
      hasChip: fb.control<boolean | null>(null, [Validators.required]),
      chipId: fb.control<string | null>(null, []),
      ringId: fb.control<string | null>(null, []),
      name: fb.control<string | null>(null, [Validators.required, Validators.minLength(1)]),
      group: fb.control<GroupDto | null>(null, [Validators.required]),
      subGroup: fb.control<SubGroupDto | null>(null, [Validators.required]),
      species: fb.control<SpeciesDto | null>(null, [Validators.required]),
      race: fb.control<string | null>(null, []),
      raceNote: fb.control<string | null>(null, []),
      coloring: fb.control<string | null>(null, []),
      furLength: fb.control<FurLength | null>(null, [nullableEnumValidator(FurLength)]),
      roughAge: fb.control<RoughAge | null>(null, [Validators.required, nullableEnumValidator(RoughAge)]),
      guessedAge: fb.control<string | null>(null, []),
    });

    this.entryCheckForm = fb.group({
      generalCondition: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [
        nullableEnumValidator(GeneralFieldChoice),
      ]),
      generalConditionNote: fb.control<string | null>(null, []),
      constitution: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [nullableEnumValidator(GeneralFieldChoice)]),
      constitutionNote: fb.control<string | null>(null, []),
      nutritionalCondition: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [
        nullableEnumValidator(GeneralFieldChoice),
      ]),
      nutritionalConditionNote: fb.control<string | null>(null, []),
      eyes: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [nullableEnumValidator(GeneralFieldChoice)]),
      eyesAffected: fb.control<AffectedGeneralRegion | null>(null, [nullableEnumValidator(AffectedGeneralRegion)]),
      eyesNote: fb.control<string | null>(null, []),
      ears: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [nullableEnumValidator(GeneralFieldChoice)]),
      earsAffected: fb.control<AffectedGeneralRegion | null>(null, [nullableEnumValidator(AffectedGeneralRegion)]),
      earsNote: fb.control<string | null>(null, []),
      nose: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [nullableEnumValidator(GeneralFieldChoice)]),
      noseAffected: fb.control<AffectedGeneralRegion | null>(null, [nullableEnumValidator(AffectedGeneralRegion)]),
      noseNote: fb.control<string | null>(null, []),
      mouthOrTeeth: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [nullableEnumValidator(GeneralFieldChoice)]),
      mouthOrTeethAffected: fb.nonNullable.control<ExtendedAffectedRegion[]>([], []),
      mouthOrTeethNote: fb.control<string | null>(null, []),
      skin: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [nullableEnumValidator(GeneralFieldChoice)]),
      skinNote: fb.control<string | null>(null, []),
      coatOrFeathers: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [nullableEnumValidator(GeneralFieldChoice)]),
      coatOrFeathersNote: fb.control<string | null>(null, []),
      clawsOrFeet: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [nullableEnumValidator(GeneralFieldChoice)]),
      affectedLimbs: fb.nonNullable.control<AffectedLimb[]>([], [enumArrayValidator(AffectedLimb)]),
      clawsOrFeetNote: fb.control<string | null>(null, []),

      anus: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [nullableEnumValidator(GeneralFieldChoice)]),
      anusNote: fb.control<string | null>(null, []),

      parasites: fb.nonNullable.control<Parasite[]>([], [enumArrayValidator(Parasite)]),
      hasParasites: fb.control<OpenBooleanChoice | null>(null, [Validators.required, enumValidator(OpenBooleanChoice)]),
      parasitesNote: fb.control<string | null>(null, []),
      sex: fb.control<AnimalSex | null>(null, [Validators.required, nullableEnumValidator(AnimalSex)]),
      castrated: fb.control<UnknownBooleanChoice | null>(null, [Validators.required, nullableEnumValidator(UnknownBooleanChoice)]),

      // only birds
      flightBehaviorOrSittingPosition: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, [
        nullableEnumValidator(GeneralFieldChoice),
      ]),
      flightBehaviorOrSittingPositionNote: fb.control<string | null>(null, []),

      // only turtles
      turtleShellLengthUnit: fb.nonNullable.control<UnitsLength>(UnitsLength.Centimeter, [nullableEnumValidator(UnitsLength)]),
      turtleShellLength: fb.control<number | null>(null, []),

      weightUnit: fb.control<UnitsWeight | null>(null, [nullableEnumValidator(UnitsWeight)]),
      weight: fb.control<number | null>(null, [TigonValidators.numberInputValidator(false, 0, Number.MAX_SAFE_INTEGER, true)]),
    });

    this.particularitiesForm = this.fb.group({
      behavior: fb.control<string | null>(null, []),
      particularities: fb.control<string | null>(null, []),
      medications: fb.control<string | null>(null, []),
      accessories: fb.nonNullable.control<string[]>([], []),
      box: fb.control<BoxDto | null>(null, [Validators.required]),
    });

    this.form = this.fb.group({
      animalForm: this.animalForm,
      entryCheckForm: this.entryCheckForm,
      particularitiesForm: this.particularitiesForm,
    });

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Animal);
  }

  private preselectWeightUnit(group: GroupDto | null, subGroup: SubGroupDto | null) {
    const weightUnitControl = this.form.controls.entryCheckForm.controls.weightUnit;

    if (weightUnitControl.value !== null) {
      return;
    }
    let unit: UnitsWeight = UnitsWeight.Gram;
    if (group?.tag === GroupTag.Dog || subGroup?.tag === GroupTag.Dog) {
      unit = UnitsWeight.Kilogram;
    }
    weightUnitControl.setValue(unit, { onlySelf: true });
  }

  private setFormValuesWithoutEvent(caseAnimalDto: DomesticCaseAnimalDto) {
    const animal = caseAnimalDto.animal;
    const options = { onlySelf: true, emitEvent: false };
    this.entryCheckForm.patchValue({ ...caseAnimalDto.entryCheck }, options);
    this.animalForm.patchValue({ ...caseAnimalDto.caseAnimalData, ...animal }, options);
    this.particularitiesForm.patchValue(caseAnimalDto.entryCheck, options);
  }

  private updateStepperContinue() {
    const index = this.stepper?.selectedIndex ?? 0;
    if (index === 0 && this.animalForm.invalid) {
      this.stepperContinueDisabled = true;
      return;
    }

    if (index === 1 && this.entryCheckForm.invalid) {
      this.stepperContinueDisabled = true;
      return;
    }

    if (index === 2 && this.particularitiesForm.invalid) {
      this.stepperContinueDisabled = true;
      return;
    }
    this.stepperContinueDisabled = false;
  }
}
