<mat-form-field appearance="outline" class="textarea-container">
  <textarea
    #autosize="cdkTextareaAutosize"
    #textarea
    [cdkAutosizeMaxRows]="maxRowsAutosize"
    [cdkAutosizeMinRows]="minRowsAutosize"
    [cdkTextareaAutosize]="true"
    [formControl]="control"
    [id]="id"
    [placeholder]="placeholder ?? ''"
    cdkFocusInitial
    matInput
  ></textarea>
</mat-form-field>
