import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, HostBinding, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { ContextActionsPortalService } from '../services/context-actions-portal.service';

@Directive({
  selector: '[tgnContextActions]',
  standalone: true,
})
export class ContextActionsDirective implements OnInit, OnDestroy {
  @HostBinding('class.tgn-context-actions') fixedClass = true;

  constructor(
    private readonly contextActionsPortal: ContextActionsPortalService,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    const portal = new TemplatePortal(this.templateRef, this.viewContainer);
    this.contextActionsPortal.pushPortal(portal);
  }

  ngOnDestroy() {
    this.contextActionsPortal.popPortal();
  }
}
