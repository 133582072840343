import { NgForOf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { CheckboxGroupComponent } from '@core/components/checkbox-group/checkbox-group.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import {
  affectedGeneralRegionChoices,
  affectedLimbChoices,
  extendedAffectedRegionChoices,
  generalFieldChoices,
  lengthUnitChoices,
  openBooleanChoices,
  parasiteChoices,
  sexChoices,
  unknownBooleanChoices,
  weightUnitChoices,
} from '@core/models/form/form-helper';
import {
  AffectedLimb,
  AnimalSex,
  GeneralFieldChoice,
  OpenBooleanChoice,
  Parasite,
  UnitsLength,
  UnitsWeight,
  UnknownBooleanChoice,
} from '@core/models/general';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { GroupDto, GroupTag, SubGroupDto } from '../../dtos/base-data.dto';
import { EntryCheckForm } from './models/form';

@Component({
  selector: 'app-health-form',
  standalone: true,
  imports: [
    TranslateModule,
    FormElementComponent,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    RadioGroupComponent,
    RadioGroupComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    SelectComponent,
    MatCheckboxModule,
    FormElementDirective,
    MatInput,
    CheckboxGroupComponent,
  ],
  templateUrl: './health-form.component.html',
  styleUrl: './health-form.component.scss',
})
export class HealthFormComponent {
  @Input({ required: true }) form!: FormGroup<EntryCheckForm>;

  @Input({ required: true }) group: GroupDto | null = null;
  @Input({ required: true }) subGroup: SubGroupDto | null = null;

  readonly generalFieldChoices: RadioChoice<GeneralFieldChoice>[] = generalFieldChoices;
  readonly sexChoices: RadioChoice<AnimalSex>[] = sexChoices;
  readonly lengthUnitChoices: RadioChoice<UnitsLength>[] = lengthUnitChoices;
  readonly weightUnitChoices: RadioChoice<UnitsWeight>[] = weightUnitChoices;
  readonly parasiteChoices: RadioChoice<Parasite>[] = parasiteChoices;
  readonly affectedLimbChoices: RadioChoice<AffectedLimb>[] = affectedLimbChoices;
  readonly birdAffectedLimbChoices: RadioChoice<AffectedLimb>[] = [
    {
      label: this.translate.instant(`GENERAL.DOMAIN.AffectedLimb.${AffectedLimb.LowerLeft}`),
      object: AffectedLimb.LowerLeft,
    },
    {
      label: this.translate.instant(`GENERAL.DOMAIN.AffectedLimb.${AffectedLimb.LowerRight}`),
      object: AffectedLimb.LowerRight,
    },
  ];

  readonly unknownBooleanChoices: RadioChoice<UnknownBooleanChoice>[] = unknownBooleanChoices;
  readonly openBooleanChoices: RadioChoice<OpenBooleanChoice>[] = openBooleanChoices;
  readonly affectedGeneralRegionChoices = affectedGeneralRegionChoices;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly GroupTag = GroupTag;
  protected readonly extendedAffectedRegionChoices = extendedAffectedRegionChoices;

  constructor(private translate: TranslateService) {}

  updateParasites(event: MatCheckboxChange, parasiteChoice: RadioChoice<Parasite>) {
    let parasites = this.form.controls.parasites.value;
    if (event.checked) {
      parasites.push(parasiteChoice.object);
    } else {
      parasites = parasites.filter((current: Parasite) => {
        return current !== parasiteChoice.object;
      });
    }
    this.form.controls.parasites.setValue(parasites);
  }

  updateAffectedLimbs(event: MatCheckboxChange, affectedLimbChoice: RadioChoice<AffectedLimb>) {
    let affectedLimbs = this.form.controls.affectedLimbs.value;
    if (event.checked) {
      affectedLimbs.push(affectedLimbChoice.object);
    } else {
      affectedLimbs = affectedLimbs.filter((current: AffectedLimb) => {
        return current !== affectedLimbChoice.object;
      });
    }
    this.form.controls.affectedLimbs.setValue(affectedLimbs);
  }
}
