import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnimalRelatedContactDto, CreateRelatedContactDto, UpdateRelatedContactDto } from '@contact/dto/related-contact.dto';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

import { AnimalId } from '../dtos/animal.dto';

@Injectable({
  providedIn: 'root',
})
export class AnimalContactsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getAnimalContacts(id: AnimalId): Observable<AnimalRelatedContactDto[]> {
    return this.http.get<AnimalRelatedContactDto[]>(`${this.apiUrl}/animals/${id}/contacts`);
  }

  relateContact(id: AnimalId, dto: CreateRelatedContactDto): Observable<AnimalRelatedContactDto> {
    return this.http.post<AnimalRelatedContactDto>(`${this.apiUrl}/animals/${id}/contacts`, dto);
  }

  updateRelation(id: AnimalId, contactId: string, dto: UpdateRelatedContactDto): Observable<AnimalRelatedContactDto> {
    return this.http.put<AnimalRelatedContactDto>(`${this.apiUrl}/animals/${id}/contacts/${contactId}`, dto);
  }
}
