import { Pipe, PipeTransform } from '@angular/core';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { getAnimalName } from '@animal/model/animal';
import { CompactAnimalDto } from '@case/dtos/case-animal.dto';

@Pipe({
  name: 'animalName',
  standalone: true,
})
export class AnimalNamePipe implements PipeTransform {
  transform(animal: AnimalDto | CompactAnimalDto | null): string {
    if (animal === null) {
      return '';
    }

    return getAnimalName(animal);
  }
}
