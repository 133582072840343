import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ConfirmationDialogComponent } from '@core/components/confirmation-dialog/confirmation-dialog.component';
import { ModalService } from '@core/services/modal.service';

@Directive({
  selector: '[appConfirmationDialog]',
  standalone: true,
})
export class ConfirmationDialogDirective {
  @Output() agreeEvent = new EventEmitter<never>();
  @Output() disagreeEvent = new EventEmitter<never>();
  @Input() dialogTitle?: string;
  @Input() dialogDescription?: string;
  @Input() stopPropagation = true;
  @Input() objectName?: string;
  @Input() noButtonLabel?: string;
  @Input() yesButtonLabel?: string;

  private defaultTitle = 'GENERAL.CONFIRMATION.DELETE';
  private defaultTitleWithObject = 'GENERAL.CONFIRMATION.DELETE_SPECIFIC';

  constructor(private modalService: ModalService) {}

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    if (this.stopPropagation) {
      event.stopPropagation();
    }

    const dialogRef = this.modalService.open(
      ConfirmationDialogComponent,
      {
        title: this.dialogTitle || (this.objectName ? this.defaultTitleWithObject : this.defaultTitle),
        description: this.dialogDescription,
        objectName: this.objectName,
        noButtonLabel: this.noButtonLabel,
        yesButtonLabel: this.yesButtonLabel,
      },
      {
        maxWidth: '30rem',
        panelClass: 'confirmation-dialog',
      },
    );
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      if (result.ok) {
        this.agreeEvent.emit();
      } else {
        this.disagreeEvent.emit();
      }
    });
  }
}
