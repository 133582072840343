import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BoxDto, BoxId, CreateBoxDto, StationId, UpdateBoxDto } from '@case/dtos/base-data.dto';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BoxService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Inactive boxes are included here
   */
  getAll(): Observable<BoxDto[]> {
    return this.http.get<BoxDto[]>(`${this.apiUrl}/master/boxes`);
  }

  getAllInStation(stationId: StationId): Observable<BoxDto[]> {
    return this.http.get<BoxDto[]>(`${this.apiUrl}/master/boxes/station/${stationId}`);
  }

  getAllActive(): Observable<BoxDto[]> {
    return this.http.get<BoxDto[]>(`${this.apiUrl}/master/boxes/active`);
  }

  getById(id: BoxId): Observable<BoxDto> {
    return this.http.get<BoxDto>(`${this.apiUrl}/master/boxes/${id}`);
  }

  create(dto: CreateBoxDto): Observable<BoxDto> {
    return this.http.post<BoxDto>(`${this.apiUrl}/master/boxes`, dto);
  }

  update(id: BoxId, dto: UpdateBoxDto): Observable<BoxDto> {
    return this.http.put<BoxDto>(`${this.apiUrl}/master/boxes/${id}`, dto);
  }
}
