<form class="form-grid grid-cols-6">
  <div class="row">
    <div class="item col-span-6">
      <h2>
        {{ 'PAGE.ENTRY_CHECK.FORM.SECTION_AT_ANIMAL' | translate }}
      </h2>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.generalCondition"
        [emphasizedLabel]="true"
        [id]="'generalCondition'"
        [label]="'ENTITY.EntryCheck.generalCondition'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group
            [choices]="generalFieldChoices"
            [class]="'col-span-4 col-span-sm-1'"
            [control]="control"
            [id]="id"
            cdkFocusInitial
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.generalConditionNote"
        [id]="'generalConditionNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="true"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.constitution"
        [emphasizedLabel]="true"
        [id]="'constitution'"
        [label]="'ENTITY.EntryCheck.constitution'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group [choices]="generalFieldChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" [id]="id" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.constitutionNote"
        [id]="'constitutionNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="true"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.nutritionalCondition"
        [emphasizedLabel]="true"
        [id]="'nutritionalCondition'"
        [label]="'ENTITY.EntryCheck.nutritionalCondition'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group [choices]="generalFieldChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" [id]="id" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.nutritionalConditionNote"
        [id]="'nutritionalConditionNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.eyes"
        [emphasizedLabel]="true"
        [id]="'eyes'"
        [label]="'ENTITY.EntryCheck.eyes'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group [choices]="generalFieldChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" [id]="id" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.eyesAffected"
        [id]="'eyesAffected'"
        [label]="'ENTITY.EntryCheck.eyesAffected'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group
            [allowDeselect]="true"
            [choices]="affectedGeneralRegionChoices"
            [class]="'col-span-3 col-span-sm-1'"
            [control]="control"
            [id]="id"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <tgn-form-element [control]="form.controls.eyesNote" [id]="'eyesNote'" [label]="'GENERAL.FORM.LABEL.NOTE'" [optional]="false">
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  @if (group?.tag !== GroupTag.Bird && subGroup?.tag !== GroupTag.Bird) {
    <div class="row">
      <div class="item">
        <tgn-form-element
          [control]="form.controls.ears"
          [emphasizedLabel]="true"
          [id]="'ears'"
          [label]="'ENTITY.EntryCheck.ears'"
          [optional]="false"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-radio-group [choices]="generalFieldChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" [id]="id" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item">
        <tgn-form-element
          [control]="form.controls.earsAffected"
          [id]="'eyesAffected'"
          [label]="'ENTITY.EntryCheck.earsAffected'"
          [optional]="false"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-radio-group
              [allowDeselect]="true"
              [choices]="affectedGeneralRegionChoices"
              [class]="'col-span-3 col-span-sm-1'"
              [control]="control"
              [id]="id"
            />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item">
        <tgn-form-element [control]="form.controls.earsNote" [id]="'earsNote'" [label]="'GENERAL.FORM.LABEL.NOTE'" [optional]="false">
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input
                matInput
                [formControl]="control"
                [id]="id"
                [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
                type="text"
              />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
  }

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.nose"
        [emphasizedLabel]="true"
        [id]="'nose'"
        [label]="'ENTITY.EntryCheck.nose'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group [choices]="generalFieldChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" [id]="id" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.noseAffected"
        [id]="'eyesAffected'"
        [label]="'ENTITY.EntryCheck.noseAffected'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group
            [allowDeselect]="true"
            [choices]="affectedGeneralRegionChoices"
            [class]="'col-span-3 col-span-sm-1'"
            [control]="control"
            [id]="id"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element [control]="form.controls.noseNote" [id]="'noseNote'" [label]="'GENERAL.FORM.LABEL.NOTE'" [optional]="false">
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.mouthOrTeeth"
        [emphasizedLabel]="true"
        [id]="'mouthOrTeeth'"
        [label]="'ENTITY.EntryCheck.mouthOrTeeth'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group [choices]="generalFieldChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" [id]="id" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.mouthOrTeethAffected"
        [id]="'eyesAffected'"
        [label]="'ENTITY.EntryCheck.mouthOrTeethAffected'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-checkbox-group
            [choices]="extendedAffectedRegionChoices"
            [class]="'col-span-3 col-span-sm-1'"
            [compareIds]="false"
            [control]="form.controls.mouthOrTeethAffected"
            [id]="id"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.mouthOrTeethNote"
        [id]="'mouthOrTeethNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.skin"
        [emphasizedLabel]="true"
        [id]="'skin'"
        [label]="'ENTITY.EntryCheck.skin'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group [choices]="generalFieldChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" [id]="id" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <tgn-form-element [control]="form.controls.skinNote" [id]="'skinNote'" [label]="'GENERAL.FORM.LABEL.NOTE'" [optional]="false">
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.clawsOrFeet"
        [emphasizedLabel]="true"
        [id]="'clawsOrFeet'"
        [label]="'ENTITY.EntryCheck.clawsOrFeet'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group [choices]="generalFieldChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" [id]="id" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element [control]="form.controls.affectedLimbs" [id]="'affectedLimbs'" [label]="'ENTITY.EntryCheck.affectedLimbs'">
        <ng-template formElement>
          <div class="checkbox-container">
            @if (group?.tag === GroupTag.Bird || subGroup?.tag === GroupTag.Bird) {
              @for (affectedLimbChoice of birdAffectedLimbChoices; track affectedLimbChoice) {
                <mat-checkbox
                  [checked]="form.controls.affectedLimbs.value.includes(affectedLimbChoice.object)"
                  [id]="affectedLimbChoice.object.toString()"
                  (change)="updateAffectedLimbs($event, affectedLimbChoice)"
                >
                  {{ affectedLimbChoice.label | translate }}
                </mat-checkbox>
              }
            } @else {
              @for (affectedLimbChoice of affectedLimbChoices; track affectedLimbChoice) {
                <mat-checkbox
                  [checked]="form.controls.affectedLimbs.value.includes(affectedLimbChoice.object)"
                  [id]="affectedLimbChoice.object.toString()"
                  (change)="updateAffectedLimbs($event, affectedLimbChoice)"
                >
                  {{ affectedLimbChoice.label | translate }}
                </mat-checkbox>
              }
            }
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.clawsOrFeetNote"
        [id]="'clawsOrFeetNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.coatOrFeathers"
        [emphasizedLabel]="true"
        [id]="'coatOrFeathers'"
        [label]="'ENTITY.EntryCheck.coatOrFeathers'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group [choices]="generalFieldChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" [id]="id" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.coatOrFeathersNote"
        [id]="'coatOrFeathersNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.anus"
        [emphasizedLabel]="true"
        [id]="'anus'"
        [label]="'ENTITY.EntryCheck.anus'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group [choices]="generalFieldChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" [id]="id" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element [control]="form.controls.anusNote" [id]="'anusNote'" [label]="'GENERAL.FORM.LABEL.NOTE'" [optional]="false">
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.hasParasites"
        [emphasizedLabel]="true"
        [hideError]="true"
        [id]="'hasParasites'"
        [label]="'ENTITY.EntryCheck.hasParasites'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group
            [allowDeselect]="true"
            [choices]="openBooleanChoices"
            [class]="'col-span-3 col-span-sm-1'"
            [control]="control"
            [id]="id"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.parasites"
        [id]="'parasites'"
        [label]="'ENTITY.EntryCheck.parasites'"
        [optional]="false"
        class="mt-small"
      >
        <ng-template formElement>
          <div class="  ">
            <tgn-checkbox-group [choices]="parasiteChoices" [compareIds]="false" [control]="form.controls.parasites" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.parasitesNote"
        [id]="'parasitesNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  @if (group?.tag === GroupTag.Bird || subGroup?.tag === GroupTag.Bird) {
    <div class="row">
      <div class="item">
        <tgn-form-element
          [control]="form.controls.flightBehaviorOrSittingPosition"
          [id]="'flightBehaviorOrSittingPosition'"
          [label]="'ENTITY.EntryCheck.flightBehaviorOrSittingPosition'"
          [optional]="false"
          [emphasizedLabel]="true"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-radio-group [id]="id" [choices]="generalFieldChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item">
        <tgn-form-element
          [control]="form.controls.flightBehaviorOrSittingPositionNote"
          [id]="'flightBehaviorOrSittingPositionNote'"
          [label]="'GENERAL.FORM.LABEL.NOTE'"
          [optional]="false"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input
                matInput
                [formControl]="control"
                [id]="id"
                [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
                type="text"
              />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
  }

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.sex"
        [emphasizedLabel]="true"
        [hideError]="true"
        [id]="'sex'"
        [label]="'ENTITY.EntryCheck.sex'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-select [choices]="sexChoices" [class]="'col-span-4 col-span-sm-1'" [control]="control" [id]="id" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.castrated"
        [emphasizedLabel]="true"
        [hideError]="true"
        [id]="'castrated'"
        [label]="'ENTITY.EntryCheck.castrated'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group [choices]="unknownBooleanChoices" [class]="'col-span-3 col-span-sm-1'" [control]="control" [id]="id" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item"></div>
  </div>

  @if (group?.tag === GroupTag.Turtle || subGroup?.tag === GroupTag.Turtle) {
    <div class="row">
      <div class="item">
        <tgn-form-element
          [control]="form.controls.turtleShellLength"
          [id]="'turtleShellLength'"
          [label]="'ENTITY.EntryCheck.turtleShellLength'"
          [optional]="false"
          [emphasizedLabel]="true"
        >
          <ng-template formElement let-control="control" let-id="id">
            <div class="input-with-unit">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'PAGE.ENTRY_CHECK.FORM.PLACEHOLDER.TURTLE_SHELL_LENGTH' | translate"
                  class="grow-input"
                  type="text"
                />
              </mat-form-field>

              <div class="unit-select">
                <tgn-select [choices]="lengthUnitChoices" [control]="form.controls.turtleShellLengthUnit" />
              </div>
            </div>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
  }

  <div class="row">
    <div class="item">
      <tgn-form-element
        [control]="form.controls.weight"
        [emphasizedLabel]="true"
        [id]="'weight'"
        [label]="'ENTITY.EntryCheck.weight'"
        [optional]="false"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="input-with-unit">
            <mat-form-field appearance="outline" class="grow-input">
              <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.EntryCheck.weight' | translate" matInput type="text" />
            </mat-form-field>

            <div class="unit-select">
              <tgn-select [choices]="weightUnitChoices" [control]="form.controls.weightUnit" />
            </div>
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
</form>
