import { AsyncPipe, NgClass } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCell, MatTableModule } from '@angular/material/table';
import { CreateExitState, ExitState, ExitStateId, UpdateExitState } from '@baseData/dtos/exit-state.dto';
import { ExitStateService } from '@baseData/services/exit-state.service';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SelectComponent } from '@core/components/select/select.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { defaultDebounce } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { uniqueNameValidator } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { shareReplay } from 'rxjs';

interface TableConfig {}

export type UpdateExitStateForm = FormGroup<{
  active: FormControl<boolean>;
}>;

export type NewExitStateForm = FormGroup<{
  name: FormControl<string | null>;
}>;

@Component({
  selector: 'tgn-base-data-exit-state',
  standalone: true,
  imports: [
    AsyncPipe,
    FieldErrorTranslationPipe,
    FormElementComponent,
    MatButton,
    MatCell,
    ScrollableTableComponent,
    MatTableModule,
    TypesafeMatTableModule,
    TranslateModule,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    MatError,
    NgClass,
    SelectComponent,
  ],
  templateUrl: './base-data-exit-state.component.html',
  styleUrl: './base-data-exit-state.component.scss',
})
export class BaseDataExitStateComponent implements OnInit {
  columns = ['name', 'active'];
  appRoutes = routes_config;

  newExitStateForm!: NewExitStateForm;
  editExitStateFormGroups: Map<ExitStateId, UpdateExitStateForm> = new Map<ExitStateId, UpdateExitStateForm>();

  dataSource!: TigonDatasource<ExitState, TableConfig>;
  activeInactiveChoice: RadioChoice<Boolean>[] = [
    {
      label: 'GENERAL.FORM.LABEL.Active',
      object: true,
    },
    {
      label: 'GENERAL.FORM.LABEL.Inactive',
      object: false,
    },
  ];

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private exitStateService: ExitStateService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.dataSource = new TigonDatasource<ExitState, TableConfig>(
      {},
      () => {
        return this.exitStateService.getAll();
      },
      this.destroyRef,
    );

    this.dataSource.getData().subscribe(data => {
      this.editExitStateFormGroups = new Map();
      data.forEach((exitState: ExitState) => {
        const exitStateFormGroup: UpdateExitStateForm = this.fb.group({
          active: this.fb.nonNullable.control<boolean>(exitState.active, [Validators.required]),
        });
        this.editExitStateFormGroups.set(exitState.id, exitStateFormGroup);
        this.autoUpdateRows(exitStateFormGroup, exitState);
      });
    });

    this.createNewExitStateForm();
  }

  addEntry() {
    const form = this.newExitStateForm.getRawValue();
    if (!form.name) {
      return;
    }

    const createExitStateDto: CreateExitState = {
      name: form.name,
    };

    this.exitStateService.create(createExitStateDto).subscribe({
      next: () => {
        this.newExitStateForm.setValue({
          name: null,
        });
        this.newExitStateForm.markAsUntouched();
        this.newExitStateForm.markAsPristine();
        this.snackbar.showSuccessMessage(`Der Austrittsgrund: '${createExitStateDto.name}' wurde erfolgreich erstellt.`);
        this.dataSource.refresh();
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Der Austrittsgrund konnte nicht erstellt werden.');
      },
    });
  }

  private createNewExitStateForm() {
    this.newExitStateForm = this.fb.group({
      name: this.fb.control<string | null>(
        null,

        {
          validators: [Validators.required, Validators.minLength(1)],

          asyncValidators: [uniqueNameValidator(this.dataSource.getData().pipe(shareReplay(1)))],
          updateOn: 'change',
        },
      ),
    });

    this.accessService.disableBasedOnRole(this.newExitStateForm, RestrictedSection.Backoffice);
  }

  private autoUpdateRows(formGroup: UpdateExitStateForm, exitState: ExitState) {
    formGroup.valueChanges.pipe(defaultDebounce(), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        if (formGroup.invalid) {
          return;
        }

        const isActive = formGroup.getRawValue().active;

        const dto: UpdateExitState = {
          active: isActive,
        };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.exitStateService.update(exitState.id, dto).subscribe(() => {
          this.dataSource.refresh();
        });
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Der Austrittsgrund konnte nicht aktualisiert werden.');
      },
    });
  }
}
