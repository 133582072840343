import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class TigonValidators {
  /**
   * This validator returns a valid state if:
   * a) The form control is undefined
   * b) The value is not required and no value has yet been entered (pristine)
   * c) None of the following occur:
   *
   * This validator returns error states if:
   * a) The value is required and does not exist ('numberRequired')
   * b) The value is non-numeric ('nan')
   * c) The value is zero when not allowed ('noZero')
   * d) The value is < min ('numberTooSmall')
   * e) The value is > max ('numberTooBig')
   *
   * @param required Whether a value is required.
   * @param min The minimum value for which an error will NOT be reported.
   * @param max The maximum value for which an error will NOT be reported.
   * @param zeroAllowed Whether a value of zero is allowed.
   */
  static numberInputValidator(required: boolean, min: number, max: number, zeroAllowed: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control === undefined || (!required && (control.pristine || control.value === undefined || control.value === null))) {
        return null; // null => Valid
      }

      // Use Number instead of parseInt or parseFloat as it handles both
      const value = Number(control.value);

      // If required, check the original value as it is of type string (per the FormControl type)
      if (required && (control.value === undefined || control.value === null || control.value.length === 0)) {
        return { numberRequired: true };
      } else if (isNaN(value)) {
        return { nan: true };
      } else if (!zeroAllowed && value === 0 && control.value.length !== 0) {
        // Empty strings parse as zero
        return { noZero: true };
      } else if (value < min && control.value.length !== 0) {
        // Empty strings parse as zero
        return { numberTooSmall: true };
      } else if (value > max && control.value.length !== 0) {
        // Empty strings parse as zero
        return { numberTooBig: true };
      }
      return null;
    };
  }

  static isValidTime(control: AbstractControl): ValidationErrors | null {
    const time = control.value;
    if (time === null || time === undefined || time.length === 0) {
      return null;
    }
    const [hours, minutes] = time.split(':');
    if (hours === undefined || minutes === undefined) {
      return { invalidTime: true };
    }
    const hoursNumber = Number(hours);
    const minutesNumber = Number(minutes);
    if (isNaN(hoursNumber) || isNaN(minutesNumber)) {
      return { invalidTime: true };
    }
    if (hoursNumber < 0 || hoursNumber > 23 || minutesNumber < 0 || minutesNumber > 59) {
      return { invalidTime: true };
    }
    return null;
  }

  static discountPercentageValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === undefined || value.length === 0) {
      return null;
    }
    const numberValue = Number(value);
    if (isNaN(numberValue)) {
      return { invalidDiscountPercentage: true };
    } else if (numberValue < 0 || numberValue > 100) {
      return { invalidDiscountPercentage: true };
    }
    return null;
  }
}
