import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ButtonUploadComponent } from '@core/components/button-upload/button-upload.component';
import { SingleImageDisplayComponent } from '@core/components/image-display/components/single-image-display/single-image-display.component';
import { ImageDisplayComponent } from '@core/components/image-display/image-display.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { DEFAULT_PAGE_SIZE } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AndRoleRestrictionPipe, RoleRestriction } from '@core/models/role';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { ContentTypeDisplayPipe } from '@core/pipes/content-type-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { ModalService } from '@core/services/modal.service';
import { IsImagePipe } from '@file/pipes/is-image.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';

import { FileCollectionDto } from '../../dto/file-collection.dto';
import { FileTagType } from '../../dto/file-tag-type';
import { FileDto } from '../../dto/file.dto';
import { FilePipe } from '../../pipes/file.pipe';
import { FileService } from '../../service/file.service';
import { RenameFileModalComponent } from '../rename-file-modal/rename-file-modal.component';

interface TableConfig {
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-file-list',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    ButtonUploadComponent,
    TranslateModule,
    DatePipe,
    ConfirmationDialogDirective,
    AsyncPipe,
    PaginatorComponent,
    ContextActionsDirective,
    FilePipe,
    TypesafeMatTableModule,
    FullNamePipe,
    ScrollableTableComponent,
    ContentTypeDisplayPipe,
    RoleRestrictionDirective,
    AndRoleRestrictionPipe,
    IsImagePipe,
    ImageDisplayComponent,
    SingleImageDisplayComponent,
  ],
  templateUrl: './file-list.component.html',
  styleUrl: './file-list.component.scss',
})
export class FileListComponent implements OnInit {
  @Input({ required: true }) fileCollection!: FileCollectionDto;
  @Input({ required: true }) tags!: FileTagType[];
  @Input() withContextAction = true;
  @Input() triggerRefreshFiles?: Observable<void>;
  @Input() roleRestriction?: RoleRestriction;
  @Input() showImagesInline = false;

  datasource!: PagedTigonDatasource<FileDto, TableConfig>;

  query = signal('');
  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;

  totalPages = 1;
  totalItems = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly FileTagType = FileTagType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private fileService: FileService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.datasource = new PagedTigonDatasource<FileDto, TableConfig>(
      {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      },
      params => {
        return this.fileService.getFilesPaginated(this.fileCollection, this.tags, {
          pageIndex: params.pageIndex,
          pageSize: params.pageSize,
        });
      },
      this.destroyRef,
    );

    this.datasource.page$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(page => {
      this.totalPages = page.totalPages;
      this.totalItems = page.totalItems;
      this.pageSize = page.pageSize;
      this.pageIndex = page.pageIndex;
    });

    if (this.triggerRefreshFiles) {
      this.triggerRefreshFiles.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.datasource.update({});
      });
    }
  }

  reload() {
    this.datasource.update();
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.datasource.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }

  deleteFile(file: FileDto) {
    from(this.fileService.deleteFile(file)).subscribe(() => {
      this.reload();
    });
  }

  openRenameFileDialog(file: FileDto) {
    this.modalService.open(
      RenameFileModalComponent,
      {
        file: file,
      },
      {
        minWidth: '30rem',
      },
    );
  }
}
