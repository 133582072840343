<div *tgnContextActions class="tgn-context-actions">
  @if (latestCase$ | async; as latestCase) {
    <a
      [tgnContextItem]="{ object: animalDetailStore.animal$ }"
      [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
      [routerLink]="appRoutes.CASE_DETAIL_PLACEMENT.url(latestCase.id)"
      class="tgn-button"
      mat-flat-button
    >
      <span>{{ 'PAGE.ANIMALS.ACTIONS.START_PLACEMENT' | translate }}</span>
    </a>
  }
</div>

<tgn-narrow-page-container>
  <tgn-collapsible [expanded]="false" [title]="'PAGE.PLACEMENT.FORM.LABEL.ANIMAL_DATA' | translate" class="mb-medium">
    @if (animalForm) {
      <div class="form-grid grid-cols-6">
        <div class="row row">
          <div class="item col-span-3 col-span-sm-6">
            <tgn-form-element [control]="animalForm.controls.name" id="name" [label]="'ENTITY.Animal.name' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
        <div class="row row-span-sm-2">
          @if (animalForm.controls.type.value === AnimalType.DomesticAnimal) {
            <div class="item col-span-3 col-span-sm-6">
              <tgn-form-element [control]="animalForm.controls.chipId" id="chipId" [label]="'ENTITY.Animal.chipId' | translate">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <input matInput [formControl]="control" [id]="id" type="text" />
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
            <div class="item col-span-3 col-span-sm-6">
              <tgn-form-element [control]="animalForm.controls.ringId" id="ringId" [label]="'ENTITY.Animal.ringId' | translate">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <input matInput [formControl]="control" [id]="id" type="text" />
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
          }
        </div>

        <div class="row row-span-sm-3">
          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="animalForm.controls.type" id="mainGroup" [label]="'ENTITY.Animal.type' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-select [choices]="mainTypeChoices" [control]="control" [id]="id"></tgn-select>
              </ng-template>
            </tgn-form-element>
          </div>
          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="animalForm.controls.group" id="group" [label]="'ENTITY.Animal.group' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-select
                  [choices]="taxonomyBaseData.groups | toRadioChoice: 'name'"
                  [compareIds]="true"
                  [control]="control"
                  [id]="id"
                  [includeEmptyChoice]="true"
                  [searchable]="true"
                ></tgn-select>
              </ng-template>
            </tgn-form-element>
          </div>
          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="animalForm.controls.subGroup" id="subGroup" [label]="'ENTITY.Animal.subGroup' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-select
                  [choices]="taxonomyBaseData.subGroups | toRadioChoice: 'name'"
                  [compareIds]="true"
                  [control]="control"
                  [id]="id"
                  [includeEmptyChoice]="true"
                ></tgn-select>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-3">
          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="animalForm.controls.species" id="species" [label]="'ENTITY.Animal.species' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-select
                  [choices]="taxonomyBaseData.species | addIfMissing: animalForm.controls.species.value | toRadioChoice: 'name'"
                  [compareIds]="true"
                  [control]="control"
                  [id]="id"
                  [includeEmptyChoice]="true"
                />
              </ng-template>
            </tgn-form-element>
          </div>

          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="animalForm.controls.race" id="race" [label]="'ENTITY.Animal.race' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput type="text" [formControl]="control" [id]="id" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="animalForm.controls.raceNote" id="raceNote" [label]="'ENTITY.Animal.raceNote' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
        @if (animalForm.controls.type.value === AnimalType.DomesticAnimal) {
          <div class="row row-span-sm-3">
            <div class="item col-span-2 col-span-sm-6">
              <tgn-form-element [control]="animalForm.controls.coloring" id="colouring" [label]="'ENTITY.Animal.coloring' | translate">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <input matInput [formControl]="control" [id]="id" type="text" />
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
            <div class="item col-span-2 col-span-sm-6">
              <tgn-form-element [control]="animalForm.controls.furLength" id="furLength" [label]="'ENTITY.Animal.furLength' | translate">
                <ng-template formElement let-control="control" let-id="id">
                  <tgn-select [choices]="furLengthChoices" [control]="control" [id]="id" [includeEmptyChoice]="true" />
                </ng-template>
              </tgn-form-element>
            </div>
          </div>

          <div class="row row-span-sm-2">
            <div class="item col-span-3 col-span-sm-6">
              <tgn-form-element [control]="animalForm.controls.sex" id="sex" [label]="'ENTITY.Animal.sex' | translate">
                <ng-template formElement let-control="control" let-id="id">
                  <tgn-select [choices]="sexChoices" [control]="control" [id]="id" [searchable]="true" />
                </ng-template>
              </tgn-form-element>
            </div>
            <div class="item col-span-3 col-span-sm-6">
              <tgn-form-element [control]="animalForm.controls.birthdate" id="birthdate" [label]="'ENTITY.Animal.birthdate' | translate">
                <ng-template formElement let-control="control" let-id="id">
                  <tgn-date-input [control]="control" [id]="id"></tgn-date-input>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>

          @if (animalForm.controls.group.value?.tag === GroupTag.Turtle || animalForm.controls.subGroup.value?.tag === GroupTag.Turtle) {
            <div class="row">
              <div class="item col-span-6">
                <tgn-form-element
                  [control]="animalForm.controls.turtleShellLength"
                  id="turtleShellLength"
                  [label]="'ENTITY.Animal.turtleShellLength' | translate"
                >
                  <ng-template formElement let-control="control" let-id="id">
                    <div class="input-with-unit">
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          [formControl]="control"
                          [id]="id"
                          [placeholder]="'PAGE.ENTRY_CHECK.FORM.PLACEHOLDER.TURTLE_SHELL_LENGTH' | translate"
                          class="grow-input"
                          type="text"
                        />
                      </mat-form-field>

                      <div class="unit-select">
                        <tgn-select
                          [choices]="lengthUnitChoices"
                          [control]="animalForm.controls.turtleShellLengthUnit"
                          [id]="id"
                          [searchable]="true"
                        />
                      </div>
                    </div>
                  </ng-template>
                </tgn-form-element>
              </div>
            </div>
          }
        }

        @if (animalForm.controls.group.value?.tag === GroupTag.Dog || animalForm.controls.subGroup.value?.tag === GroupTag.Dog) {
          <div class="row">
            <div class="item">
              <tgn-form-element
                [control]="animalForm.controls.behaviour"
                id="behaviorReadonly"
                [label]="'ENTITY.Animal.behavior' | translate"
              >
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <textarea
                      matInput
                      [formControl]="control"
                      [id]="id"
                      cdkAutosizeMinRows="5"
                      cdkTextareaAutosize
                      cdkAutosizeMaxRows="10"
                    ></textarea>
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        }
      </div>
    }
  </tgn-collapsible>

  <tgn-collapsible [expanded]="false" [title]="'PAGE.PLACEMENT.FORM.LABEL.CASE_DATA' | translate" class="mb-medium mt-medium">
    @if (latestCase$ | async; as latestCase) {
      @if (caseAnimal$ | async; as caseAnimal) {
        <tgn-case-read-only-info [caseAnimal]="caseAnimal" [case]="latestCase" />
      }
    }
  </tgn-collapsible>

  @if (placementForm) {
    <h3 class="subsection">{{ 'PAGE.PLACEMENT.FORM.LABEL.PLACEMENT' | translate }}</h3>
    <div class="form-grid grid-cols-6">
      @if (placement?.billingNumber) {
        <div class="row">
          <div class="item col-span-6">
            <tgn-form-element [control]="undefined" id="billingNumber" [label]="'ENTITY.Placement.billingNumber' | translate">
              <ng-template formElement let-control="control" let-id="id">
                {{ placement?.billingNumber ?? '' }}
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
      }
      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.placementType"
            id="placementType"
            [label]="'ENTITY.Placement.placementType' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select [choices]="placementTypeChoices" [control]="control" [id]="id"></tgn-select>
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.placementState"
            id="placementState"
            [label]="'ENTITY.Placement.placementState' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select [choices]="placementStateChoices" [control]="control" [id]="id"></tgn-select>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-6 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.placementNote"
            id="placementNote"
            [label]="'ENTITY.Placement.placementNote' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'GENERAL.FORM.PLACEHOLDER.HINTS' | translate"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <h3 class="subsection">{{ 'PAGE.PLACEMENT.FORM.LABEL.PLACEMENT_MEDIATION' | translate }}</h3>

      <tgn-placement-mediation [placementForm]="placementForm" [placement]="placement! | cast" />
    </div>

    <h3 class="subsection">{{ 'PAGE.PLACEMENT.FORM.LABEL.CONTRACT_PREPARATION' | translate }}</h3>
    <div class="form-grid grid-cols-6">
      <div class="row row-span-sm-2">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="placementForm.controls.castrationType"
            id="castrationType"
            [label]="'ENTITY.Placement.castrationType' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select [choices]="castrationTypeChoices" [control]="control" [id]="id"></tgn-select>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
      <div class="row row-span-sm-2">
        <div class="item col-span-6">
          <tgn-form-element [control]="placementForm.controls.isCastrationCoveredByTrd" id="isCastrationCoveredByTrd">
            <ng-template formElement let-control="control" let-id="id">
              <mat-checkbox
                [disabled]="placementForm.controls.isCastrationCoveredByTrd.disabled"
                [checked]="placementForm.controls.isCastrationCoveredByTrd.value"
                [id]="'isCastrationCoveredByTrd'"
                (change)="updateIsCastrationCoveredByTrd($event)"
              >
                {{ 'ENTITY.Placement.isCastrationCoveredByTrd' | translate }}
              </mat-checkbox>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    </div>

    <h3 class="subsection">{{ 'PAGE.PLACEMENT.FORM.LABEL.VACCINATIONS' | translate }}</h3>
    <div class="form-grid grid-cols-6">
      <div class="row row-span-sm-2">
        <div class="item col-span-6">
          <tgn-form-element [control]="placementForm.controls.isVaccinatedAccordingToPassport" id="isVaccinatedAccordingToPassport">
            <ng-template formElement let-control="control" let-id="id">
              <mat-checkbox [formControl]="control" [id]="id" [disabled]="control.disabled">
                {{ 'ENTITY.Placement.isVaccinatedAccordingToPassport' | translate }}
              </mat-checkbox>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.followUpVaccinationSecondIklsDate"
            id="followUpVaccinationSecondIklsDate"
            [label]="'ENTITY.Placement.followUpVaccinationSecondIklsDate' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id"></tgn-date-input>
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.followUpVaccinationThirdIklsDate"
            id="followUpVaccinationThirdIklsDate"
            [label]="'ENTITY.Placement.followUpVaccinationThirdIklsDate' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id"></tgn-date-input>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    </div>

    <h3 class="subsection">{{ 'PAGE.PLACEMENT.FORM.LABEL.PARASITES' | translate }}</h3>
    <div class="form-grid grid-cols-6">
      <div class="row row-span-sm-2">
        @if (animalForm?.controls?.group?.value?.tag === GroupTag.Cat || animalForm?.controls?.subGroup?.value?.tag === GroupTag.Cat) {
          <div class="item col-span-3 col-span-sm-6">
            <tgn-form-element
              [control]="placementForm.controls.leukosisAndFivNegativeDate"
              id="leukosisAndFivNegativeDate"
              [label]="'ENTITY.Placement.leukosisAndFivNegativeDate' | translate"
            >
              <ng-template formElement let-control="control" let-id="id">
                <tgn-date-input [control]="control" [id]="id"></tgn-date-input>
              </ng-template>
            </tgn-form-element>
          </div>
        }
        <div class="item col-span-3 col-span-sm-6 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.parasiteCareDate"
            id="parasideCareDate"
            [label]="'ENTITY.Placement.parasiteCareDate' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id"></tgn-date-input>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    </div>

    <h3 class="subsection">{{ 'PAGE.PLACEMENT.FORM.LABEL.VETERINARY_CARE' | translate }}</h3>
    <div class="form-grid grid-cols-6">
      <div class="row row-span-sm-2">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="placementForm.controls.veterinaryExaminationCondition"
            id="veterinaryExaminationCondition"
            [label]="'ENTITY.Placement.veterinaryExaminationCondition' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <div class="form-grid grid-cols-2">
                <tgn-radio-group
                  class="full-width"
                  [choices]="veterinaryExaminationConditionChoices"
                  [control]="control"
                  [id]="id"
                ></tgn-radio-group>
              </div>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="placementForm.controls.illnessKinds"
            id="illnessKinds"
            [label]="'ENTITY.Placement.illnessKinds' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-checkbox-group [choices]="illnessKindChoices" [control]="placementForm.controls.illnessKinds" [compareIds]="false" />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="placementForm.controls.illnessNote"
            id="illnessNote"
            [label]="'ENTITY.Placement.illnessNote' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [formControl]="control"
                  [id]="id"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="placementForm.controls.additionalNote"
            id="additionalNote"
            [label]="'ENTITY.Placement.additionalNote' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [formControl]="control"
                  [id]="id"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      @if (animal$ | async; as animal) {
        <div class="row">
          <div class="item col-span-6">
            <h3 class="subsection">{{ 'PAGE.PLACEMENT.FORM.LABEL.specials' | translate }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="item col-span-6">
            <p>{{ animal.specials ?? 'PAGE.PLACEMENT.PLACEHOLDER.NONE' | translate }}</p>
          </div>
        </div>

        @if (animal.group?.tag === GroupTag.Dog || animal.subGroup?.tag === GroupTag.Dog) {
          <div class="row">
            <div class="item col-span-6">
              <tgn-form-element [control]="placementForm.controls.behavior" id="behavior" [label]="'ENTITY.Placement.behavior' | translate">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <textarea
                      matInput
                      [formControl]="control"
                      [id]="id"
                      cdkAutosizeMinRows="5"
                      cdkTextareaAutosize
                      cdkAutosizeMaxRows="10"
                    ></textarea>
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        }
      }

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="placementForm.controls.transferMaterials"
            id="transferMaterials"
            [label]="'ENTITY.Placement.transferMaterials' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-checkbox-group
                [choices]="transferMaterialsChoices"
                [control]="placementForm.controls.transferMaterials"
                [compareIds]="false"
              />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <tgn-form-element
          [control]="placementForm.controls.vaccinationPassHandedOver"
          id="vaccinationPassHandedOver"
          [label]="'ENTITY.Placement.vaccinationPassHandedOver' | translate"
        >
          <div class="item col-span-6 form-grid grid-cols-2">
            <tgn-radio-group
              [class]="'full-width'"
              [choices]="handedInChoices"
              [control]="placementForm.controls.vaccinationPassHandedOver"
            />
          </div>
        </tgn-form-element>
      </div>

      <div class="row">
        <tgn-form-element
          [control]="placementForm.controls.fosterAnimalPassportHandedOver"
          id="foserAnimalPassportHandedOver"
          [label]="'ENTITY.Placement.fosterAnimalPassportHandedOver' | translate"
        >
          <div class="col-span-6 form-grid grid-cols-2">
            <tgn-radio-group
              [class]="'full-width'"
              [choices]="handedInChoices"
              [control]="placementForm.controls.fosterAnimalPassportHandedOver"
            />
          </div>
        </tgn-form-element>
      </div>

      @if (lastEditUser) {
        <div class="row">
          <tgn-form-element [control]="undefined" id="lasteEditedBy" [label]="'ENTITY.Placement.lastEditedBy' | translate">
            <div class="col-span-6">
              <div class="emphasized">{{ lastEditUser | fullName }}</div>
            </div>
          </tgn-form-element>
        </div>
      }
    </div>
  }
</tgn-narrow-page-container>
