<h1 class="mt-large">Archivierte Kontakte</h1>

<div class="action-container">
  <tgn-option-button (selectionChange)="changeFilter($event)" [options]="filterOptions" [selectedValue]="activeFilter()" />
  <mat-form-field appearance="outline" class="search-input">
    <input [(ngModel)]="query" matInput placeholder="Suchen" type="text" />
  </mat-form-field>
</div>

@if (datasource) {
  <tgn-scrollable-table>
    <table #table="matTable" [dataSource]="datasource" mat-table>
      <tr *matHeaderRowDef="columns" mat-header-row></tr>
      <tr
        *matRowDef="let contact; table: table; columns: columns"
        [routerLink]="appRoutes.CONTACT_DETAIL_GENERAL.url(contact.id)"
        class="table-row-link"
        mat-row
      ></tr>

      <ng-container matColumnDef="personNumber">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.personNumber' | translate }}</th>
        <td *matCellDef="let contact; table: table" mat-cell>{{ contact.personNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.lastName' | translate }}</th>
        <td *matCellDef="let contact; table: table" mat-cell>{{ contact.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.firstName' | translate }}</th>
        <td *matCellDef="let contact; table: table" mat-cell>{{ contact.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.type' | translate }}</th>
        <td *matCellDef="let contact; table: table" mat-cell>
          {{ contact.kinds | listDisplayWithKey: 'name' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.status' | translate }}</th>
        <td *matCellDef="let contact; table: table" mat-cell>{{ contact.status | enumDisplay: 'GENERAL.DOMAIN.ContactStatus' }}</td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.company' | translate }}</th>
        <td *matCellDef="let contact; table: table" mat-cell>{{ contact.company }}</td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.address' | translate }}</th>
        <td *matCellDef="let contact; table: table" mat-cell>{{ contact | fullAddress }}</td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.note' | translate }}</th>
        <td *matCellDef="let contact; table: table" mat-cell>
          <div class="max-width-column">
            <tgn-single-line-text [text]="contact.note ?? ''" />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.BUTTON.MENU_ACTIONS' | translate }}</th>
        <td *matCellDef="let contact; table: table" mat-cell>
          <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ contact: contact }" [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <ng-template let-contact="contact" matMenuContent>
              <button
                (agreeEvent)="unarchiveContact(contact)"
                [dialogDescription]="'Willst du diesen Kontakt wirklich wieder aktiv schalten?'"
                [dialogTitle]="'Archivierung aufheben'"
                appConfirmationDialog
                mat-menu-item
              >
                <span>{{ 'GENERAL.BUTTON.UNARCHIVE' | translate }}</span>
              </button>
            </ng-template>
          </mat-menu>
        </td>
      </ng-container>
    </table>
    <tgn-paginator
      (onPageEvent)="pageChanged($event)"
      [maxItemCount]="totalItems"
      [maxPages]="totalPages"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      class="paginator"
      paginator
    ></tgn-paginator>
  </tgn-scrollable-table>
}
