import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { StationDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { routes_config } from '@core/constants';
import { UserRole } from '@core/models/general';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserDto } from '@user/dto/user.dto';
import { CurrentUserService } from '@user/service/current-user.service';
import { Observable, map, take } from 'rxjs';

type TeamForm = FormGroup<{
  station: FormControl<StationDto | null>;
  team: FormControl<UserRole | null>;
}>;

@Component({
  selector: 'app-choose-team-page',
  standalone: true,
  imports: [
    FormElementComponent,
    TranslateModule,
    ReactiveFormsModule,
    FormElementDirective,
    SelectComponent,
    MatButton,
    AsyncPipe,
    ToRadioChoicePipe,
  ],
  templateUrl: './choose-team-page.component.html',
  styleUrl: './choose-team-page.component.scss',
})
export class ChooseTeamPageComponent implements OnInit {
  form?: TeamForm;

  teamChoices!: RadioChoice<UserRole>[];

  stationChoices$!: Observable<StationDto[]>;

  initialized = false;

  constructor(
    private fb: FormBuilder,
    private currentUserService: CurrentUserService,
    private router: Router,
    private baseDataService: BaseDataService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.stationChoices$ = this.baseDataService.getBaseData().pipe(
      take(1),
      map(it => it.stations),
    );

    const fb = this.fb;
    this.currentUserService.currentUser$.subscribe((currentUser: UserDto) => {
      this.teamChoices = currentUser.roles
        .filter(it => it !== UserRole.Superuser && it !== UserRole.Deactivated)
        .map(role => ({
          object: role,
          label: this.translate.instant(`GENERAL.DOMAIN.UserRole.${role}`),
        }));

      this.form = fb.group({
        station: fb.nonNullable.control<StationDto | null>(currentUser.defaultStation, []),
        team: fb.nonNullable.control<UserRole | null>(currentUser.usedRole, [Validators.required]),
      });

      this.initialized = true;
    });
  }

  continue() {
    if (this?.form?.invalid) {
      return;
    }

    const values = this.form!.getRawValue();

    if (!values.team) {
      console.error('Team is required');
      return;
    }

    this.currentUserService.updateCurrentUserAndRole(values.station?.id ?? null, values.team).subscribe(() => {
      this.router.navigate(routes_config.ROOT.url());
    });
  }
}
