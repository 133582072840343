<div *tgnContextActions class="tgn-context-actions">
  <a [routerLink]="appRoutes.CASE.url()" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.ACTION.NEW_ENTRY' | translate }}
  </a>
</div>

<div class="content">
  <h1>{{ 'GENERAL.DOMAIN.CASE_P' | translate }}</h1>

  <div class="action-container">
    <tgn-option-button
      (selectionChange)="changeGeneralStateFilter($event)"
      [options]="caseButtonFilterChoices"
      [selectedValue]="generalStateFilter()"
    />
    <mat-form-field appearance="outline" class="search-input">
      <input [(ngModel)]="query" matInput placeholder="Suchen" type="text" />
    </mat-form-field>
  </div>

  <div class="filter-container">
    <tgn-case-filter (onFilterChange)="this.updateCaseFilter($event)" [filter]="filter" />
  </div>

  <tgn-scrollable-table>
    <table #table="matTable" (matSortChange)="sortChanged($event)" [dataSource]="datasource" mat-table matSort>
      <tr *matHeaderRowDef="columns" mat-header-row></tr>
      <tr
        *matRowDef="let caseListViewDto; table: table; columns: columns"
        [routerLink]="appRoutes.CASE_DETAIL_GENERAL.url(caseListViewDto.id)"
        class="table-row-link"
        mat-row
      ></tr>

      <ng-container matColumnDef="caseNumber">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'ENTITY.CaseListViewDto.caseNumber' | translate }}</th>
        <td *matCellDef="let caseListViewDto; table: table" mat-cell>
          <span class="icon-text">
            {{ caseListViewDto.caseNumber }}
            @if (caseListViewDto.animalType === AnimalType.WildAnimal) {
              <tgn-wild-animal-icon class="ml-md" />
            }
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'ENTITY.CaseListViewDto.title' | translate }}</th>
        <td *matCellDef="let caseListViewDto; table: table" mat-cell>
          <span class="icon-text max-width-column">
            <tgn-single-line-text [text]="caseListViewDto.title ?? ''" />
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="animalNames">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'ENTITY.CaseListViewDto.animalNames' | translate }}</th>
        <td *matCellDef="let caseListViewDto; table: table" mat-cell>
          <div class="animal-name-container">
            @for (animal of caseListViewDto.animals; track animal) {
              <a class="animal-link" [routerLink]="appRoutes.ANIMAL_DETAIL.url(animal.id)">
                <tgn-single-line-text [text]="animal.name | withDefaultAnimalName" />
              </a>
            }
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="box">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'ENTITY.CaseListViewDto.box' | translate }}</th>
        <td *matCellDef="let caseListViewDto; table: table" mat-cell>
          @for (animal of caseListViewDto.animals; track animal) {
            <span>{{ animal.box?.name ?? ('PAGE.CASE.LABELS.NO_BOX' | translate) }}</span>
            @if ($index < caseListViewDto.animals.length - 1) {
              <span class="animal-box-separator">,</span>
            }
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="entryType">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'ENTITY.CaseListViewDto.entryType' | translate }}</th>
        <td *matCellDef="let caseListViewDto; table: table" mat-cell>
          {{ 'GENERAL.DOMAIN.CaseEntryTypeShort.' + caseListViewDto.entryType | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="owner">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'ENTITY.CaseListViewDto.owner' | translate }}</th>
        <td *matCellDef="let caseListViewDto; table: table" mat-cell>
          {{ caseListViewDto.owner | fullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="caseCreationDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'ENTITY.CaseListViewDto.caseCreationDate' | translate }}</th>
        <td *matCellDef="let caseListViewDto; table: table" mat-cell>{{ caseListViewDto.caseCreationDate | date }}</td>
      </ng-container>

      <ng-container matColumnDef="completeDate">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.completeDate' | translate }}</th>
        <td *matCellDef="let caseListViewDto; table: table" mat-cell>{{ caseListViewDto.completeDate | date }}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'ENTITY.CaseListViewDto.state' | translate }}</th>
        <td *matCellDef="let caseListViewDto; table: table" mat-cell>
          {{ 'GENERAL.DOMAIN.CaseState.' + caseListViewDto.state | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.BUTTON.MENU_ACTIONS' | translate }}</th>
        <td *matCellDef="let caseListViewDto; table: table" mat-cell>
          <button
            (click)="$event.stopPropagation()"
            [matMenuTriggerData]="{ case: caseListViewDto }"
            [matMenuTriggerFor]="menu"
            mat-icon-button
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <ng-template let-caseDto="case" matMenuContent>
              <a [routerLink]="appRoutes.CASE_DETAIL_GENERAL.url(caseDto.id)" class="tigon-button" mat-menu-item>
                {{ 'PAGE.CASE.ACTIONS.DETAIL' | translate }}
              </a>
              <a [routerLink]="appRoutes.CASE_DETAIL_TASKS.url(caseDto.id)" class="tigon-button" mat-menu-item>
                {{ 'PAGE.CASE.ACTIONS.TASKS' | translate }}
              </a>
              @if (caseListViewDto.animalType === AnimalType.DomesticAnimal) {
                <a [routerLink]="appRoutes.CASE_DETAIL_PLACEMENT.url(caseDto.id)" class="tigon-button" mat-menu-item>
                  {{ 'PAGE.CASE.ACTIONS.PLACEMENT' | translate }}
                </a>
              }

              <button
                (agreeEvent)="archiveCase(caseDto)"
                [dialogDescription]="'PAGE.CASES.CONFIRMATION.ARCHIVE_QUESTION' | translate"
                [dialogTitle]="'PAGE.CASES.CONFIRMATION.ARCHIVE_CONFIRMATION_TITLE' | translate"
                appConfirmationDialog
                mat-menu-item
              >
                <span>{{ 'GENERAL.BUTTON.ARCHIVE' | translate }}</span>
              </button>
            </ng-template>
          </mat-menu>
        </td>
      </ng-container>
    </table>
    <tgn-paginator
      (onPageEvent)="pageChanged($event)"
      [maxItemCount]="totalItems"
      [maxPages]="totalPages"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      class="paginator"
      paginator
    ></tgn-paginator>
  </tgn-scrollable-table>
</div>
