import { DialogModule } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { StationDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { SelectComponent } from '@core/components/select/select.component';
import { UserRole } from '@core/models/general';
import { ListIncludesAnyPipe, ListIncludesPipe } from '@core/pipes/list-includes.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { ModalComponent } from '@core/services/modal.service';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { UpdateUserDto, UserDto } from '@user/dto/user.dto';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { UserService } from '@user/service/user.service';
import { Observable, map, take } from 'rxjs';

interface DialogResult {}

interface DialogData {
  user: UserDto;
}

interface UserInviteForm {
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string>;
  roles: FormControl<UserRole[]>;
  isSuperuser: FormControl<boolean | null>;
  defaultStation: FormControl<StationDto | null>;
}

@Component({
  selector: 'app-edit-user-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    FormElementComponent,
    FormElementDirective,
    FormsModule,
    MatButton,
    DialogModule,
    TranslateModule,
    MatFormField,
    ReactiveFormsModule,
    MatDialogModule,
    MatInput,
    SelectComponent,
    ToRadioChoicePipe,
    MatCheckbox,
    ListIncludesPipe,
    ListIncludesAnyPipe,
  ],
  templateUrl: './edit-user-dialog.component.html',
  styleUrl: './edit-user-dialog.component.scss',
})
export class EditUserDialogComponent extends ModalComponent<{}, DialogResult> {
  form: FormGroup<UserInviteForm>;
  roles = createEnumChoices(UserRole, 'GENERAL.DOMAIN.UserRole.').filter(
    it => it.object !== UserRole.Superuser && it.object !== UserRole.Deactivated,
  );

  stations$!: Observable<StationDto[]>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;

  constructor(
    private formBuilder: FormBuilder,
    dialogRef: MatDialogRef<EditUserDialogComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private baseDataService: BaseDataService,
    private userService: UserService,
    private accessService: AccessService,
  ) {
    super(dialogRef);

    this.stations$ = this.baseDataService.getBaseData().pipe(
      take(1),
      map(result => result.stations),
    );

    const fb = this.formBuilder;
    this.form = fb.group({
      firstName: fb.control<string | null>(this.data.user.firstName),
      lastName: fb.control<string | null>(this.data.user.lastName),
      email: fb.nonNullable.control<string>({ value: this.data.user.email, disabled: true }),
      roles: fb.nonNullable.control<UserRole[]>(
        this.data.user.roles.filter(it => it != UserRole.Superuser),
        [Validators.required],
      ),
      isSuperuser: fb.control<boolean | null>(this.data.user.roles.includes(UserRole.Superuser)),
      defaultStation: fb.control<StationDto | null>(this.data.user.defaultStation),
    });

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Case);
  }

  saveAndClose() {
    if (!this.form.valid) {
      return;
    }

    const values = this.form.getRawValue();

    let roles = values.roles;

    if (values.isSuperuser) {
      roles = [...roles, UserRole.Superuser];
    }

    const editUserDto: UpdateUserDto = {
      ...values,
      defaultStation: values.defaultStation?.id ?? null,
      roles: roles,
    };

    this.userService
      .updateUser(this.data.user.id, editUserDto)
      .pipe(take(1))
      .subscribe(() => {
        this.closeWithResult({});
      });
  }
}
