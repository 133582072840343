<div class="task-breadcrumb mdc-dialog__content">
  @if (taskTitleBreadcrumb) {
    <span>{{ taskTitleBreadcrumb }}</span>
  }
</div>

<div mat-dialog-content>
  <tgn-form-element [control]="form.controls.title" [hideError]="true" [id]="'title'" class="">
    <ng-template formElement let-control="control" let-id="id">
      <input [formControl]="control" [id]="id" [placeholder]="'PAGE.CARE.NEW_TASK.PLACEHOLDER.TITLE' | translate" matInput />
    </ng-template>
  </tgn-form-element>
  <tgn-task-form
    [existingTask]="task"
    [fileCollection]="task.fileCollection"
    [form]="form"
    [isCreate]="false"
    [lastHistoryTaskReportControl]="lastHistoryTaskReportControl"
    [taskCategoryGroups]="this.taskData.taskCategoryGroups"
    [taskHistory]="taskHistory"
    [taskTypeEntity]="task.entityType"
    [triggerRefreshFiles]="refreshFiles"
  />
</div>

<div mat-dialog-actions>
  <div class="modal-footer-left">
    @if (task.status === TaskStatus.Open) {
      <tgn-button-upload
        (onUploadCompleted)="refreshFiles.next()"
        [fileCollection]="task.fileCollection"
        [showFileList]="false"
        [useIcon]="true"
        [uploadEntity]="uploadEntity"
        [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
      />
    }
  </div>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CLOSE' | translate }}
  </button>
</div>
