import { Component, Input } from '@angular/core';

import { StatisticsCardComponent } from '../statistics-card/statistics-card.component';

@Component({
  selector: 'tgn-simple-stats-display-card',
  standalone: true,
  imports: [StatisticsCardComponent],
  templateUrl: './simple-stats-display-card.component.html',
  styleUrl: './simple-stats-display-card.component.scss',
})
export class SimpleStatsDisplayCardComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) value?: number | string;
  @Input() smallText?: string;
}
