import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { isDefined } from '@core/utils/helplers';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'fieldErrorTranslation',
  standalone: true,
})
export class FieldErrorTranslationPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(errors: ValidationErrors | null | undefined): Observable<string> {
    if (!isDefined(errors) || Object.entries(errors).length === 0) {
      return of('');
    }

    const hasError = (name: string): boolean => isDefined(errors[name]);

    if (hasError('minLength')) {
      return this.translateService.get('GENERAL.ERRORS.MIN_LENGTH');
    }
    if (hasError('maxlength')) {
      return this.translateService.get('GENERAL.ERRORS.MAX_LENGTH');
    }
    if (hasError('pattern')) {
      return this.translateService.get('GENERAL.ERRORS.PATTERN');
    }
    if (hasError('email')) {
      return this.translateService.get('GENERAL.ERRORS.EMAIL');
    }
    if (hasError('passwordValidator')) {
      return this.translateService.get('GENERAL.ERRORS.PASSWORD_PATTERN');
    }
    if (hasError('required')) {
      return this.translateService.get('GENERAL.ERRORS.FIELD_IS_REQUIRED');
    }
    if (hasError('phoneNumberValidator')) {
      return this.translateService.get('GENERAL.ERRORS.PHONE_NUMBER');
    }
    if (hasError('dateRange')) {
      return this.translateService.get('GENERAL.ERRORS.DATE_RANGE');
    }
    if (hasError('timeRange')) {
      return this.translateService.get('GENERAL.ERRORS.TIME_RANGE');
    }
    if (hasError('startDateError') || hasError('endDateError')) {
      return this.translateService.get('GENERAL.ERRORS.DATE');
    }
    if (hasError('vatTaxDateNotLargerThanOthers')) {
      return this.translateService.get('GENERAL.ERRORS.VAT_TAX_DATE_NOT_LARGER_THAN_OTHERS');
    }
    if (hasError('invalidDiscountPercentage')) {
      return this.translateService.get('GENERAL.ERRORS.INVALID_DISCOUNT_PERCENTAGE');
    }
    if (hasError('uniqueName')) {
      return this.translateService.get('GENERAL.ERRORS.UNIQUE_NAME');
    }
    if (hasError('password')) {
      return this.translateService.get(errors['password']);
    }
    if (hasError('inviteName')) {
      return of(errors['inviteName']);
    }
    if (hasError('emailExists')) {
      return of(errors['emailExists']);
    }

    if (hasError('lastNameOrCompanyRequired')) {
      return of(errors['lastNameOrCompanyRequired']);
    }

    return this.translateService.get('GENERAL.ERRORS.INVALID_FORM');
  }
}
