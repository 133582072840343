import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { AnimalCaseIndexDto } from '@animal/dtos/animal-case-index.dto';
import { AnimalService } from '@animal/services/animal.service';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { routes_config } from '@core/constants';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { TypeSafeMatCellDef, TypeSafeMatRowDef } from '@core/directives/mat-table.directive';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, shareReplay, switchMap } from 'rxjs';

import { AnimalDetailStore } from '../animal-detail-layout/animal-detail-layout.component';

@Component({
  selector: 'app-animal-cases',
  standalone: true,
  templateUrl: './animal-cases.component.html',
  styleUrl: './animal-cases.component.scss',
  imports: [
    CommonModule,
    ConfirmationDialogDirective,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    TranslateModule,
    TypeSafeMatCellDef,
    TypeSafeMatRowDef,
    RouterLink,
    SingleLineTextComponent,
  ],
})
export class AnimalCasesComponent {
  cases$: Observable<AnimalCaseIndexDto[]>;
  readonly columns = ['caseNumber', 'title', 'entryDate', 'exitDate', 'entryType', 'entryVia', 'status'];
  protected readonly appRoutes = routes_config;

  constructor(animalDetailStore: AnimalDetailStore, animalService: AnimalService) {
    this.cases$ = animalDetailStore.animal$.pipe(
      takeUntilDestroyed(),
      switchMap(animal => animalService.getCases(animal.id)),
      shareReplay(1),
    );
  }
}
