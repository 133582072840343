import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatCell, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { ContactListViewDto } from '@contact/dto/contact-list-view.dto';
import { ContactFilter, ContactService } from '@contact/service/contact-service';
import { FilterOption, OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { GENERAL_WRITE_EXCLUDE } from '@core/directives/role-restriction.directive';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FullAddressPipe } from '@core/pipes/address.pipe';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { ListDisplayWithKey } from '@core/pipes/list-display.pipe';
import { ModalService } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, skip } from 'rxjs';

interface TableConfig {
  query: string;
  pageIndex: number;
  pageSize: number;
  filter: ContactFilter;
}

@Component({
  selector: 'tgn-archive-contacts',
  standalone: true,
  imports: [
    EnumDisplayPipe,
    FullAddressPipe,
    ListDisplayWithKey,
    MatCell,
    OptionButtonComponent,
    MatFormField,
    FormsModule,
    MatTableModule,
    TypesafeMatTableModule,
    ScrollableTableComponent,
    PaginatorComponent,
    RouterLink,
    TranslateModule,
    SingleLineTextComponent,
    MatInput,
    ConfirmationDialogDirective,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuModule,
  ],
  templateUrl: './archive-contacts.component.html',
  styleUrl: './archive-contacts.component.scss',
})
export class ArchiveContactsComponent implements OnInit {
  columns = ['personNumber', 'lastName', 'firstName', 'type', 'status', 'company', 'address', 'note', 'actions'];
  datasource?: PagedTigonDatasource<ContactListViewDto, TableConfig>;
  query = signal('');
  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  totalPages = 1;
  totalItems = 0;
  activeFilter = signal(ContactFilter.All);
  filterOptions: FilterOption<ContactFilter>[] = Object.values(ContactFilter).map((filter: ContactFilter) => {
    return {
      label: 'GENERAL.DOMAIN.ContactFilter.' + filter,
      value: filter,
    };
  });

  appRoutes = routes_config;

  destroyRef = inject(DestroyRef);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    private modal: ModalService,
    private snackbar: SnackbarService,
    private contactService: ContactService,
  ) {
    this.datasource = new PagedTigonDatasource<ContactListViewDto, TableConfig>(
      {
        query: '',
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        filter: ContactFilter.All,
      },
      params => {
        return this.contactService.getArchivedContactsPaginated({
          query: params.query,
          pageIndex: params.pageIndex,
          pageSize: params.pageSize,
          filter: this.activeFilter(),
        });
      },
      this.destroyRef,
    );

    toObservable(this.query)
      .pipe(skip(1), debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe(query => {
        this.datasource!.update({ query });
      });
  }

  ngOnInit() {
    this.datasource!.page$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(page => {
      this.totalPages = page.totalPages;
      this.totalItems = page.totalItems;
      this.pageSize = page.pageSize;
      this.pageIndex = page.pageIndex;
    });
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.totalPages = pageEvent.maxPages;

    this.datasource!.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }

  changeFilter(newFilter: ContactFilter) {
    this.activeFilter.set(newFilter);
    this.datasource!.update({ filter: newFilter, pageIndex: 0 });
  }

  unarchiveContact(contact: ContactListViewDto) {
    this.contactService.unarchiveContact(contact.id).subscribe(() => {
      this.snackbar.showSuccessMessage('Archivierung wurde aufgehoben für diesen Kontakt');
      this.datasource!.update({ pageIndex: this.pageIndex });
    });
  }
}
