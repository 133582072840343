import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PushDataSource } from '@core/data/tigon-datasource';
import { ChartOptions } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';

import { Pair } from '../../dto/statistics.dto';
import { ChartTransformerPipe } from '../../pipes/chart-transformer.pipe';

export interface BarChartData {
  data: number[];
  labels: string[];
}

@Component({
  selector: 'tgn-bar-chart',
  standalone: true,
  imports: [BaseChartDirective, ChartTransformerPipe, AsyncPipe],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss',
})
export class BarChartComponent implements OnInit {
  @Input({ required: true }) barChartData!: PushDataSource<Pair<string, number>>;
  @Input() horizontal = false;
  @Input({ required: true }) showLegend = true;

  currentLabels = [''];

  options!: ChartOptions;

  ngOnInit() {
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: this.horizontal ? 'y' : 'x',
      plugins: {
        legend: {
          display: this.showLegend,
          position: 'bottom',
        },
        title: {
          display: false,
          text: '',
        },
        datalabels: {
          display: this.horizontal,
          align: 'bottom',
          anchor: 'start',
          color: 'black',
          font: {
            weight: 'bold',
          },
          formatter: (value: number, context: Context) => context.dataset.label,
        },
      },
      scales: {
        x: {
          stacked: false,
        },
        y: {
          stacked: false,
          display: true,
        },
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
    };

    if (this.horizontal) {
      this.options!.plugins!.datalabels!.align = 'end';
      this.options!.plugins!.datalabels!.anchor = 'start';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.options!.plugins!.datalabels!.formatter = (value, _) => {
        return value.toString();
      };
      this.options!.plugins!.legend!.display = true;
      this.options!.plugins!.legend!.position = 'bottom';
    }
  }
}
