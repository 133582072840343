import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuditViewComponent } from '@audit/components/audit-view/audit-view.component';
import { ContactDto } from '@contact/dto/contact.dto';
import { ContactDetailStore } from '@contact/pages/contact-detail/contact-detail-layout/contact-detail-layout.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contact-log',
  standalone: true,
  imports: [AsyncPipe, AuditViewComponent],
  templateUrl: './contact-log.component.html',
  styleUrl: './contact-log.component.scss',
})
export class ContactLogComponent implements OnInit {
  contact$!: Observable<ContactDto>;

  constructor(private contactDetailStore: ContactDetailStore) {}

  ngOnInit() {
    this.contact$ = this.contactDetailStore.contact$;
  }
}
