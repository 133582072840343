import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipEditedEvent, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent } from '../icon/icon.component';

export interface ChipItem {
  name: string;
}

@Component({
  selector: 'tgn-chip-input',
  standalone: true,
  imports: [MatInputModule, MatChipsModule, CommonModule, MatIconModule, IconComponent, TranslateModule],
  templateUrl: './chip-input.component.html',
  styleUrl: './chip-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ChipInputComponent,
    },
  ],
})
export class ChipInputComponent implements ControlValueAccessor {
  @Input() placeholder = 'COMPONENT.ChipInput.placeholder';
  @Input() disabled = false;
  @Output() onItemsChanged: EventEmitter<ChipItem[]> = new EventEmitter<ChipItem[]>();

  chipItems: ChipItem[] = [];

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onChange = (_: string[]) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.chipItems.push({ name: value });
      this.update();
    }

    event.chipInput!.clear();
  }

  remove(chipItem: ChipItem): void {
    const index = this.chipItems.indexOf(chipItem);

    if (index >= 0) {
      this.chipItems.splice(index, 1);
      this.update();
    }
  }

  edit(chipItem: ChipItem, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.remove(chipItem);
      this.update();
      return;
    }

    // edit existing
    const index = this.chipItems.indexOf(chipItem);
    if (index >= 0) {
      this.chipItems[index].name = value;
      this.update();
    }
  }

  writeValue(items: string[]): void {
    this.chipItems = items.map((name: string) => ({ name }));
  }

  registerOnChange(onChange: never): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: never): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private update() {
    this.onChange(this.chipItems.map((item: ChipItem) => item.name));
    this.onItemsChanged.emit(this.chipItems);
    this.onTouched();
  }
}
