<div *tgnContextActions class="tgn-context-actions">
  <button (click)="addStation()" class="tgn-button" mat-flat-button>
    {{ 'PAGE.BASE_DATA_BOXES.ACTIONS.NEW_STATION' | translate }}
  </button>
</div>

<tgn-narrow-page-container>
  @if (stations$ | async; as stations) {
    @for (station of stations; track station.id) {
      <tgn-collapsible [expanded]="false" [title]="'Station ' + station.name" class="input-bg-white mt-large" variant="withBgColor">
        <tgn-base-data-station-boxes [station]="station" />
      </tgn-collapsible>
    }
  }
</tgn-narrow-page-container>
