import { Pipe, PipeTransform } from '@angular/core';
import { UserDto } from '@user/dto/user.dto';

interface WithFirstAndLastName {
  firstName: string | null;
  lastName: string | null;
}

@Pipe({ standalone: true, name: 'fullName' })
export class FullNamePipe implements PipeTransform {
  transform(value: WithFirstAndLastName | undefined | null, config?: { emptyPlaceholder: string }): string {
    if (!value || (!value.firstName && !value.lastName)) {
      return config?.emptyPlaceholder ?? '';
    }

    return [value.firstName, value.lastName].filter(it => it !== null).join(' ');
  }
}

@Pipe({ standalone: true, name: 'visum' })
export class VisumPipe implements PipeTransform {
  transform(user: UserDto | undefined | null): string {
    if (!user?.firstName && !user?.lastName) {
      return user?.uid ?? '';
    }

    return user?.visa ?? [user.firstName, user.lastName].filter(it => it !== null).join(' ');
  }
}
