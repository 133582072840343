import { state } from '@angular/animations';
import { TextFieldModule } from '@angular/cdk/text-field';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { GroupTag, compareWith } from '@case/dtos/base-data.dto';
import { CaseDto } from '@case/dtos/case.dto';
import { ChooseContactComponent } from '@contact/components/choose-contact/choose-contact.component';
import { ContactDto } from '@contact/dto/contact.dto';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ConfirmationDialogComponent } from '@core/components/confirmation-dialog/confirmation-dialog.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { ModalService } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { createEnumChoices, openDownloadedBlobPdf } from '@core/utils/helplers';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CaseProspectsService } from '@prospect/services/case-prospects.service';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { filter } from 'rxjs';

import { ProspectAnimalDto, ProspectDto, ProspectState, ProspectUpdateDto } from '../../dto/prospect.dto';

interface ProspectDetailForm {
  animals: FormControl<ProspectAnimalDto[]>;
  contact: FormControl<ContactDto | null>; // Can be temporarily null until saved
  state: FormControl<ProspectState>;
  consentSiteSupervision: FormControl<boolean>;
  catLockAvailable: FormControl<boolean>;
  writtenAuthorizationToKeepAnimals: FormControl<boolean>;
  hasAnimalsInHousehold: FormControl<boolean>;
  hasAnimalsInHouseholdDetails: FormControl<string | null>;
  imageOfAccommodationPresented: FormControl<boolean>;
  livingSituation: FormControl<string | null>;
  amicusId: FormControl<string | null>;
  contribution: FormControl<number | null>;
  donation: FormControl<number | null>;
}

@Component({
  selector: 'app-prospect-detail',
  standalone: true,
  imports: [
    MatSelectModule,
    FormElementComponent,
    TranslateModule,
    FormElementDirective,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    TextFieldModule,
    AutocompleteComponent,
    MatButtonModule,
    ContextActionsDirective,
    ChooseContactComponent,
    MatInput,
    RoleRestrictionDirective,
    SelectComponent,
    MatIcon,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
  ],
  templateUrl: './prospect-detail.component.html',
  styleUrl: './prospect-detail.component.scss',
})
export class ProspectDetailComponent implements OnInit {
  @Input({ required: true }) prospect!: ProspectDto;
  @Input({ required: true }) case!: CaseDto;

  hasCat = false;
  hasDog = false;
  hasBird = false;
  hasSmallAnimalOrExotic = false;

  form?: FormGroup<ProspectDetailForm>;

  animalChoices!: RadioChoice<ProspectAnimalDto>[];

  @Output() onSave = new EventEmitter<ProspectUpdateDto>();

  protected readonly compareWith = compareWith;
  protected readonly statusChoices = createEnumChoices(ProspectState, 'GENERAL.DOMAIN.ProspectState.');
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  protected readonly state = state;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly ProspectState = ProspectState;

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private modalService: ModalService,
    private snackbar: SnackbarService,
    private accessService: AccessService,
    private prospectService: CaseProspectsService,
  ) {}

  ngOnInit() {
    this.animalChoices = this.case.caseAnimals.map(it => {
      const dto: RadioChoice<ProspectAnimalDto> = {
        object: {
          name: it.animal.name,
          trdId: it.animal.trdId,
          id: it.animal.id,
        },
        label: it.animal.name ?? `Tier ${it.animal.trdId}`,
      };
      return dto;
    });

    this.form = this.fb.group({
      animals: this.fb.nonNullable.control(this.prospect.animals),
      contact: this.fb.control(this.prospect.contact, Validators.required),
      state: this.fb.nonNullable.control(this.prospect.state),
      consentSiteSupervision: this.fb.nonNullable.control(this.prospect.consentSiteSupervision),
      catLockAvailable: this.fb.nonNullable.control(this.prospect.catLockAvailable),
      writtenAuthorizationToKeepAnimals: this.fb.nonNullable.control(this.prospect.writtenAuthorizationToKeepAnimals),
      hasAnimalsInHousehold: this.fb.nonNullable.control(this.prospect.hasAnimalsInHousehold),
      hasAnimalsInHouseholdDetails: this.fb.control(this.prospect.hasAnimalsInHouseholdDetails ?? null),
      imageOfAccommodationPresented: this.fb.nonNullable.control(this.prospect.imageOfAccommodationPresented),
      livingSituation: this.fb.control(this.prospect.livingSituation ?? null),
      amicusId: this.fb.control(this.prospect.amicusId ?? null),
      contribution: this.fb.control(this.prospect.contribution ?? null, [Validators.min(0)]),
      donation: this.fb.control(this.prospect.donation ?? null, [Validators.min(0)]),
    });

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Case);

    this.hasCat = this.case.caseAnimals.some(it => {
      const tag = GroupTag.Cat;
      const animal = it.animal;
      return animal.group?.tag === tag || animal.subGroup?.tag === tag || animal.species?.tag === tag;
    });

    this.hasDog = this.case.caseAnimals.some(it => {
      const tag = GroupTag.Dog;
      const animal = it.animal;
      return animal.group?.tag === tag || animal.subGroup?.tag === tag || animal.species?.tag === tag;
    });

    this.hasCat = this.hasAnimalWithTag(this.case, GroupTag.Cat);
    this.hasDog = this.hasAnimalWithTag(this.case, GroupTag.Dog);
    this.hasSmallAnimalOrExotic =
      this.hasAnimalWithTag(this.case, GroupTag.SmallAnimal) || this.hasAnimalWithTag(this.case, GroupTag.Exotic);
    this.hasBird = this.hasAnimalWithTag(this.case, GroupTag.Bird);

    if (this.prospect.state === ProspectState.ContractConcluded) {
      this.form.disable();
    }
  }

  close() {
    this.location.back();
  }

  hasAnimalWithTag(caseDto: CaseDto, tag: GroupTag): boolean {
    return caseDto.caseAnimals.some(it => {
      const animal = it.animal;
      return animal.group?.tag === tag || animal.subGroup?.tag === tag || animal.species?.tag === tag;
    });
  }

  save() {
    if (!this.form || this.form.invalid) {
      return;
    }

    const value = this.form.getRawValue();
    const dto: ProspectUpdateDto = {
      contactId: value.contact!.id,
      state: value.state,
      animals: value.animals,
      consentSiteSupervision: value.consentSiteSupervision,
      catLockAvailable: value.catLockAvailable,
      writtenAuthorizationToKeepAnimals: value.writtenAuthorizationToKeepAnimals,
      hasAnimalsInHousehold: value.hasAnimalsInHousehold,
      hasAnimalsInHouseholdDetails: value.hasAnimalsInHouseholdDetails,
      imageOfAccommodationPresented: value.imageOfAccommodationPresented,
      livingSituation: value.livingSituation,
      amicusId: value.amicusId,
      contribution: value.contribution,
      donation: value.donation,
    };

    if (value.state === ProspectState.ContractConcluded) {
      this.modalService
        .open(
          ConfirmationDialogComponent,
          {
            title: 'Vertrag abschliessen?',
            description:
              // eslint-disable-next-line max-len
              'Sind Sie sicher, dass Sie den Vertrag abschliessen möchten? Der Kontakt wird dabei als Besitzer erfasst und der Vertrag wird als PDF in den Falldateien abgelegt. Zusätzlich werden alle offenen Aufgaben auf dem Tier in Status "abgeschlossen" gestellt.',
          },
          {
            maxWidth: '500px',
          },
        )
        .afterClosed()
        .pipe(filter(notNullish))
        .subscribe(result => {
          if (result.ok) {
            this.onSave.emit(dto);
            this.form?.disable();
            this.form?.markAsPristine();
            this.snackbar.showSuccessMessage('Verträge werden generiert und in den Falldateien abgelegt.');
          }
        });
    } else {
      this.onSave.emit(dto);
      this.form.markAsPristine();
    }
  }

  downloadContract(event: MouseEvent, prospect: ProspectDto, animal: ProspectAnimalDto) {
    if (this.form!.controls.state.value !== ProspectState.ContractConcluded) {
      this.prospectService.getProspectPreviewContract(prospect.id, this.case!.id, animal.id).subscribe(response => {
        openDownloadedBlobPdf(response, 'contract.pdf');
      });
    } else {
      this.prospectService.getProspectContract(prospect.id, this.case!.id, animal.id).subscribe(response => {
        openDownloadedBlobPdf(response, 'contract.pdf');
      });
    }
  }
}
