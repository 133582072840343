import { NgForOf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavItemComponent } from '@core/components/nav-item/nav-item.component';
import { routes_config } from '@core/constants';
import { RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { USER_ROLES_WITH_NOTIFICATIONS, UserRole } from '@core/models/general';
import { NavItemId, NavigationItem } from '@core/models/navigation-item';
import { TranslateModule } from '@ngx-translate/core';

import { UnreadNotificationsComponent } from '../../../../notification/components/unread-notifications/unread-notifications.component';

@Component({
  selector: 'tgn-main-nav',
  standalone: true,
  imports: [MatSidenavModule, NavItemComponent, NgForOf, TranslateModule, RoleRestrictionDirective, UnreadNotificationsComponent],
  templateUrl: './main-nav.component.html',
  styleUrl: './main-nav.component.scss',
})
export class MainNavComponent {
  @Output() onOpenSettingsNav = new EventEmitter<void>();

  settingsNavItem: NavigationItem = {
    icon: 'radixGear',
    path: null,
    text: 'NAVIGATION.SETTINGS',
    roleRestriction: {},
  };

  navigationItemsMain: NavigationItem[] = [
    {
      icon: 'radixDashboard',
      text: 'NAVIGATION.DASHBOARD',
      path: routes_config.DASHBOARD.path,
      roleRestriction: { exclude: [UserRole.Veterinarian] },
    },
    {
      id: NavItemId.Inbox,
      icon: 'radixBell',
      text: 'NAVIGATION.INBOX',
      path: routes_config.INBOX.path,
      roleRestriction: { exclude: [UserRole.Veterinarian, UserRole.Guest], restrictTo: USER_ROLES_WITH_NOTIFICATIONS },
    },
    {
      icon: 'radixMagnifyingGlass',
      text: 'NAVIGATION.SEARCH',
      path: routes_config.SEARCH.path,
      roleRestriction: { exclude: [UserRole.Veterinarian] },
    },
  ];

  navigationItemsAnimalCare: NavigationItem[] = [
    {
      iconPath: 'assets/icons/paw-icon.svg',
      text: 'NAVIGATION.ANIMALS',
      path: routes_config.ANIMALS.path,
    },
    {
      icon: 'radixClipboard',
      text: 'NAVIGATION.CARE',
      path: routes_config.CARE.path,
    },
  ];

  navigationItemsAdministration: NavigationItem[] = [
    {
      icon: 'radixFile',
      text: 'NAVIGATION.CASES',
      path: routes_config.CASES.path,
      roleRestriction: { exclude: [UserRole.Veterinarian] },
    },
    {
      icon: 'radixPerson',
      text: 'NAVIGATION.CONTACTS',
      path: routes_config.CONTACTS.path,
      roleRestriction: { exclude: [UserRole.Veterinarian] },
    },
    {
      icon: 'radixBarChart',
      text: 'NAVIGATION.STATISTICS',
      path: routes_config.STATISTICS.url(),
      roleRestriction: { exclude: [UserRole.Veterinarian] },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly NavItemId = NavItemId;
}
