import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const minDate = new Date(1000, 0);
const maxDate = new Date(9999, 11, 31);

/**
 * This validator makes sure that we only use dates in a certain range.
 * Some fields in the backend require dates to not exceed a certain date range.
 */
export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const isValid = isDateBetween(control.value as Date, minDate, maxDate);
      return isValid ? null : { dateRange: { value: 'Date out of range' } };
    }

    return null;
  };
}

function isDateBetween(date: Date, start: Date, end: Date): boolean {
  return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
}
