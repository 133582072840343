import { Pipe, PipeTransform } from '@angular/core';
import { notNullish } from '@core/utils/rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AnimalDto } from '../dtos/animal.dto';

@Pipe({
  name: 'animalTitle',
  standalone: true,
})
export class AnimalTitlePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(animal: AnimalDto): string {
    const state: string | null = animal.state ? this.translate.instant(`GENERAL.DOMAIN.AnimalState.${animal.state}`) : null;
    const values = [animal.name, animal.species?.name, state, `ID: ${animal.trdId}`].filter(notNullish);
    return values.join(' - ');
  }
}
