import { Component } from '@angular/core';
import { GlobalSearchComponent } from '@core/components/global-search/global-search.component';

@Component({
  selector: 'app-search-page',
  standalone: true,
  imports: [GlobalSearchComponent],
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.scss',
})
export class SearchPageComponent {}
