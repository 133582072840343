<div *tgnContextActions class="tgn-context-actions">
  <button (click)="openExportStatisticsModal()" [tgnRoleRestriction]="EXCLUDE_VETERINARIAN" class="tgn-button" mat-flat-button>
    <span>{{ 'GENERAL.BUTTON.EXPORT' | translate }}</span>
  </button>
</div>

<div class="content">
  <h2>{{ 'PAGE.STATISTICS.TITLE' | translate }}</h2>

  <div class="form-grid grid-cols-6">
    <div class="row">
      <div class="item col-span-6 filter-item">
        <mat-spinner [diameter]="24" [ngClass]="{ invisible: !isLoading }" />
        <div class="custom-date-range">
          @if (form.controls.filter.value === DateFilterChoice.Custom) {
            <tgn-date-input [control]="form.controls.startDate" [placeholder]="'von'" />
            <span class="separator">-</span>
            <tgn-date-input [control]="form.controls.endDate" [placeholder]="'bis'" />
          }

          @if (form.controls.filter.value === DateFilterChoice.Day) {
            <tgn-date-input [control]="form.controls.day" />
          }
          @if (form.controls.filter.value === DateFilterChoice.Month) {
            <tgn-date-input [control]="form.controls.month" [type]="DatePickerType.Month" />
          }
          @if (form.controls.filter.value === DateFilterChoice.Year) {
            <tgn-date-input [control]="form.controls.year" [type]="DatePickerType.Year" />
          }
          <div></div>
          <tgn-select [choices]="dateFilterChoices" [control]="form.controls.filter" [placeholder]="'Zeitraum'" />
        </div>
      </div>
    </div>
    <div class="row row-span-sm-3">
      <div class="item col-span-2 col-span-sm-6">
        <tgn-simple-stats-display-card [title]="'Tiere total'" [value]="statisticsDto?.numAnimals" />
      </div>

      <div class="item col-span-2 col-span-sm-6">
        <tgn-simple-stats-display-card [title]="'Heimtiere'" [value]="statisticsDto?.numDomesticAnimals" />
      </div>

      <div class="item col-span-2 col-span-sm-6">
        <tgn-simple-stats-display-card [title]="'Wildtiere'" [value]="statisticsDto?.numWildAnimals" />
      </div>
    </div>

    <div class="row row-span-sm-3">
      <div class="item col-span-2 col-span-sm-6">
        <tgn-simple-stats-display-card [title]="'Anzahl Fälle'" [value]="statisticsDto?.numCases" />
      </div>

      <div class="item col-span-2 col-span-sm-6">
        <tgn-simple-stats-display-card
          [smallText]="'seit ' + firstDayOfYear"
          [title]="'Anzahl Heimtiere'"
          [value]="statisticsDto?.numDomesticAnimalsSinceYearStart"
        />
      </div>

      <div class="item col-span-2 col-span-sm-6">
        <tgn-simple-stats-display-card
          [smallText]="'seit ' + firstDayOfYear"
          [title]="'Anzahl Wildtiere'"
          [value]="statisticsDto?.numWildAnimalsSinceYearStart"
        />
      </div>
    </div>

    <!-- Number of Animals by CaseEntryType -->
    <div class="row">
      <div class="item col-span-6">
        <tgn-statistics-card [title]="'Tiere pro Aufnahmeart'">
          <tgn-bar-chart [barChartData]="entryTypeDataSource" [horizontal]="true" [showLegend]="true" />
        </tgn-statistics-card>
      </div>
    </div>

    <!-- Number of Animals by SubmitReason -->
    <div class="row">
      <div class="item col-span-6">
        <tgn-statistics-card [title]="'Tiere pro Abgabegrund'">
          <tgn-bar-chart [barChartData]="submitReasonDataSource" [showLegend]="true" />
        </tgn-statistics-card>
      </div>
    </div>

    <!-- Number of animals by exitState -->
    <div class="row">
      <div class="item col-span-6">
        <tgn-statistics-card [title]="'Tiere pro Austrittsgrund'">
          <tgn-bar-chart [barChartData]="exitReasonDataSource" [showLegend]="true" />
        </tgn-statistics-card>
      </div>
    </div>

    <!--@TODO: how to handle wild animal statistics here?-->

    <div class="row">
      <div class="item col-span-3">
        <tgn-statistics-card [title]="'Heimtiere pro Gruppe'">
          <tgn-scrollable-table>
            <table
              #domesticAnimalsByGroup="matTable"
              [dataSource]="domesticGroupSubGroupDataSource"
              class="task-table max-height-card"
              mat-table
            >
              <tr *matHeaderRowDef="['group', 'subGroup', 'count']" mat-header-row></tr>
              <tr *matRowDef="let entry; table: domesticAnimalsByGroup; columns: ['group', 'subGroup', 'count']" mat-row></tr>

              <ng-container matColumnDef="group">
                <th *matHeaderCellDef mat-header-cell>Gruppe</th>
                <td *matCellDef="let entry; table: domesticAnimalsByGroup" mat-cell>
                  <tgn-single-line-text [text]="entry.groupOrEmpty" />
                </td>
              </ng-container>

              <ng-container matColumnDef="subGroup">
                <th *matHeaderCellDef mat-header-cell>Untergruppe</th>
                <td *matCellDef="let entry; table: domesticAnimalsByGroup" mat-cell>
                  <tgn-single-line-text [text]="entry.subGroupOrEmpty" />
                </td>
              </ng-container>

              <ng-container matColumnDef="count">
                <th *matHeaderCellDef mat-header-cell>Anzahl</th>
                <td *matCellDef="let entry; table: domesticAnimalsByGroup" mat-cell>
                  {{ entry.count }}
                </td>
              </ng-container>
            </table>
          </tgn-scrollable-table>
        </tgn-statistics-card>
      </div>

      <div class="item col-span-3">
        <tgn-statistics-card [title]="'Wildtiere pro Gruppe'">
          <tgn-scrollable-table class="max-height-card">
            <table #wildAnimalsByGroup="matTable" [dataSource]="wildGroupSubGroupDataSource" class="task-table" mat-table>
              <tr *matHeaderRowDef="['group', 'subGroup', 'count']" mat-header-row></tr>
              <tr *matRowDef="let entry; table: numBySpeciesTable; columns: ['group', 'subGroup', 'count']" mat-row></tr>

              <ng-container matColumnDef="group">
                <th *matHeaderCellDef mat-header-cell>Gruppe</th>
                <td *matCellDef="let entry; table: wildAnimalsByGroup" mat-cell>
                  <tgn-single-line-text [text]="entry.groupOrEmpty" />
                </td>
              </ng-container>

              <ng-container matColumnDef="subGroup">
                <th *matHeaderCellDef mat-header-cell>Untergruppe</th>
                <td *matCellDef="let entry; table: wildAnimalsByGroup" mat-cell>
                  <tgn-single-line-text [text]="entry.subGroupOrEmpty" />
                </td>
              </ng-container>

              <ng-container matColumnDef="count">
                <th *matHeaderCellDef mat-header-cell>Anzahl</th>
                <td *matCellDef="let entry; table: wildAnimalsByGroup" mat-cell>
                  {{ entry.count }}
                </td>
              </ng-container>
            </table>
          </tgn-scrollable-table>
        </tgn-statistics-card>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-3">
        <tgn-statistics-card [title]="'Tiere pro Tierart'">
          <tgn-scrollable-table class="max-height-card">
            <table #numBySpeciesTable="matTable" [dataSource]="numAnimalsBySpeciesDataSource" class="task-table" mat-table>
              <tr *matHeaderRowDef="['name', 'count']" mat-header-row></tr>
              <tr *matRowDef="let row; table: numBySpeciesTable; columns: ['name', 'count']" mat-row></tr>

              <ng-container matColumnDef="name">
                <th *matHeaderCellDef mat-header-cell>Tierart</th>
                <td *matCellDef="let row; table: numBySpeciesTable" mat-cell>
                  <tgn-single-line-text [text]="row.name" />
                </td>
              </ng-container>

              <ng-container matColumnDef="count">
                <th *matHeaderCellDef mat-header-cell>Anzahl</th>
                <td *matCellDef="let row; table: numBySpeciesTable" mat-cell>
                  {{ row.count }}
                </td>
              </ng-container>
            </table>
          </tgn-scrollable-table>
        </tgn-statistics-card>
      </div>

      <div class="item col-span-3">
        <tgn-statistics-card
          [info]="'Unabhängig vom Datumsfilter, Durchschnitt über alle Jahre'"
          [title]="'Anzahl Tage im Tierheim (Durchschnitt)'"
        >
          <table #averageDaysTable="matTable" [dataSource]="averageDaysInShelterDataSource" class="task-table" mat-table>
            <tr *matHeaderRowDef="['name', 'count']" mat-header-row></tr>
            <tr *matRowDef="let val; table: averageDaysTable; columns: ['name', 'count']" mat-row></tr>

            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-header-cell>Hauptgruppen</th>
              <td *matCellDef="let val; table: averageDaysTable" mat-cell>
                <tgn-single-line-text [text]="val.name" />
              </td>
            </ng-container>

            <ng-container matColumnDef="count">
              <th *matHeaderCellDef mat-header-cell>Tage</th>
              <td *matCellDef="let entry; table: averageDaysTable" mat-cell>
                {{ entry.count | number: '1.0-0' }}
              </td>
            </ng-container>
          </table>
        </tgn-statistics-card>
      </div>
    </div>
  </div>
</div>
