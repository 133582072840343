import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { AnimalDetailStore } from '@animal/pages/animal-detail/animal-detail-layout/animal-detail-layout.component';
import { AuditChangeComponent } from '@audit/components/audit-change/audit-change.component';
import { AuditViewComponent } from '@audit/components/audit-view/audit-view.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-animal-log',
  standalone: true,
  imports: [AsyncPipe, AuditChangeComponent, AuditViewComponent],
  templateUrl: './animal-log.component.html',
  styleUrl: './animal-log.component.scss',
})
export class AnimalLogComponent {
  animal$: Observable<AnimalDto>;

  constructor(private animalDetailStore: AnimalDetailStore) {
    this.animal$ = this.animalDetailStore.animal$;
  }
}
