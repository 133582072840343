import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateRaceDto, UpdateRaceDto } from '@baseData/dtos/create-race.dto';
import { RaceDto, RaceId } from '@case/dtos/base-data.dto';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RaceService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Inactive exitStates are included here
   */
  getAll(): Observable<RaceDto[]> {
    return this.http.get<RaceDto[]>(`${this.apiUrl}/master/races`);
  }

  getAllActive(): Observable<RaceDto[]> {
    return this.http.get<RaceDto[]>(`${this.apiUrl}/master/races/active`);
  }

  getById(id: RaceId): Observable<RaceDto> {
    return this.http.get<RaceDto>(`${this.apiUrl}/master/races/${id}`);
  }

  create(dto: CreateRaceDto): Observable<RaceDto> {
    return this.http.post<RaceDto>(`${this.apiUrl}/master/races`, dto);
  }

  update(id: RaceId, dto: UpdateRaceDto): Observable<RaceDto> {
    return this.http.put<RaceDto>(`${this.apiUrl}/master/races/${id}`, dto);
  }
}
