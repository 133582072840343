import { DestroyRef, Directive, ElementRef, Input, OnInit, Renderer2, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserRole } from '@core/models/general';
import { RoleAccess, RoleRestriction } from '@core/models/role';
import { CurrentUserService } from '@user/service/current-user.service';

@Directive({
  selector: '[tgnRoleRestriction]',
  standalone: true,
})
export class RoleRestrictionDirective implements OnInit {
  @Input({ alias: 'tgnRoleRestriction', required: true }) roleRestriction: RoleRestriction | undefined | null;

  destroyRef = inject(DestroyRef);

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private userService: CurrentUserService,
  ) {}

  ngOnInit() {
    this.userService.currentUser$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
      if (!this.roleRestriction) return;

      if (this.roleRestriction.restrictTo && this.roleRestriction.restrictTo.length > 0) {
        if (!RoleAccess.anyMatches(user.roles, this.roleRestriction.restrictTo)) {
          this.hideElement();
        }
      }

      if (this.roleRestriction.exclude && this.roleRestriction.exclude.length > 0) {
        if (RoleAccess.anyMatches(user.roles, this.roleRestriction.exclude)) {
          this.hideElement();
        }
      }
    });
  }

  hideElement() {
    this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
  }
}

export const GENERAL_WRITE_EXCLUDE: RoleRestriction = {
  exclude: [UserRole.Guest, UserRole.Veterinarian],
};

export const EXCLUDE_VETERINARIAN: RoleRestriction = {
  exclude: [UserRole.Veterinarian],
};
