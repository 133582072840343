import { DatePipe } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { RelationKindDto } from '@baseData/dtos/relation-kind.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseContactService } from '@case/services/case-contact.service';
import { AddRelatedContactDialogComponent } from '@contact/components/add-related-contact-dialog/add-related-contact-dialog.component';
import { RelatedContact, RelatedContactDialogComponent } from '@contact/components/related-contact-dialog/related-contact-dialog.component';
import { ContactId } from '@contact/dto/contact-list-view.dto';
import { CaseRelatedContactDto } from '@contact/dto/related-contact.dto';
import { ContactRelationService } from '@contact/service/contact-relation.service';
import { ContactService } from '@contact/service/contact-service';
import { OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PaginatorComponent } from '@core/components/paginator/paginator.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { ContextItemDirective } from '@core/directives/context-item.directive';
import { TypeSafeMatCellDef, TypeSafeMatRowDef } from '@core/directives/mat-table.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AndRoleRestrictionPipe, RESTRICT_CASE_WRITE } from '@core/models/role';
import { FullAddressPipe } from '@core/pipes/address.pipe';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { ListDisplay } from '@core/pipes/list-display.pipe';
import { StringToDatePipe } from '@core/pipes/string-to-date.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { filter, switchMap } from 'rxjs';

import { CaseDetailStore } from '../case-layout/case-layout.component';

@Component({
  selector: 'app-case-contacts',
  standalone: true,
  imports: [
    FormsModule,
    FullAddressPipe,
    FullNamePipe,
    ListDisplay,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    OptionButtonComponent,
    PaginatorComponent,
    TranslateModule,
    TypeSafeMatCellDef,
    RouterLink,
    TypeSafeMatRowDef,
    ConfirmationDialogDirective,
    MatIconModule,
    MatMenuModule,
    StringToDatePipe,
    DatePipe,
    ContextActionsDirective,
    SingleLineTextComponent,
    EnumDisplayPipe,
    RoleRestrictionDirective,
    AndRoleRestrictionPipe,
    ContextItemDirective,
  ],
  templateUrl: './case-contacts.component.html',
  styleUrl: './case-contacts.component.scss',
})
export class CaseContactsComponent {
  datasource?: TigonDatasource<CaseRelatedContactDto, {}>;
  readonly columns = [
    'personNumber',
    'status',
    'firstName',
    'lastName',
    'organization',
    'kind',
    'fromToDate',
    'note',
    'createdDate',
    'animal',
    'actions',
  ];

  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RESTRICT_CASE_WRITE = RESTRICT_CASE_WRITE;
  private case?: CaseDetailDto;
  private destroyRef = inject(DestroyRef);

  constructor(
    protected caseDetailStore: CaseDetailStore,
    private caseContactService: CaseContactService,
    private modalService: ModalService,
    private contactService: ContactService,
    private contactRelationService: ContactRelationService,
  ) {
    this.caseDetailStore.case$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((caseDto: CaseDetailDto) => {
      this.case = caseDto;
      this.datasource = new TigonDatasource<CaseRelatedContactDto, {}>({}, () => {
        return this.caseContactService.getCaseContacts(caseDto.id);
      });
    });
  }

  removeContactFromCase(contactDto: CaseRelatedContactDto) {
    if (!this.case) {
      return;
    }
    this.contactRelationService.deleteContactRelation(contactDto.contact.id, contactDto.relationId).subscribe(() => {
      this.refresh();
    });
  }

  openAddContactDialog() {
    this.modalService
      .open(AddRelatedContactDialogComponent, {}, { width: ModalWidth.Medium })
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe(result => {
        if (result.type === 'create') {
          this.createNewContact(this.case!);
        } else {
          this.relateExistingContact(this.case!, result.contactId, result.kind, result.fromDate, result.toDate);
        }
      });
  }

  editContact(caseRelatedContactDto: CaseRelatedContactDto) {
    const relatedContact: RelatedContact = {
      ...caseRelatedContactDto.contact,
      kind: caseRelatedContactDto.kind,
      fromDate: caseRelatedContactDto.fromDate,
      toDate: caseRelatedContactDto.toDate,
    };

    this.modalService
      .open(
        RelatedContactDialogComponent,
        {
          contact: relatedContact,
          disabled: this.case?.archived,
        },
        { width: ModalWidth.Large },
      )
      .afterClosed()
      .pipe(
        filter(notNullish),
        switchMap(result => {
          const { dto } = result;

          return this.contactService.update(caseRelatedContactDto.contact.id, dto).pipe(
            switchMap(updatedContact =>
              this.caseContactService.updateRelation(this.case!.id, updatedContact.id, {
                kind: dto.kind,
                fromDate: dto.fromDate,
                toDate: dto.toDate,
              }),
            ),
          );
        }),
      )
      .subscribe(() => {
        this.refresh();
      });
  }

  refresh() {
    this.datasource?.update();
  }

  private createNewContact(caseDto: CaseDetailDto) {
    this.modalService
      .open(RelatedContactDialogComponent, {}, { width: ModalWidth.Large })
      .afterClosed()
      .pipe(
        filter(notNullish),
        switchMap(result => {
          const dto: RelatedContact = result.dto;
          return this.contactService.create(dto).pipe(
            switchMap(contact =>
              this.caseContactService.relateContact(caseDto.id, {
                contactId: contact.id,
                kind: dto.kind,
                fromDate: dto.fromDate,
                toDate: dto.toDate,
              }),
            ),
          );
        }),
      )
      .subscribe(() => {
        this.refresh();
      });
  }

  private relateExistingContact(
    caseDto: CaseDetailDto,
    contactId: ContactId,
    kind: RelationKindDto,
    fromDate: string | null,
    toDate: string | null,
  ) {
    this.caseContactService
      .relateContact(caseDto.id, {
        contactId,
        kind,
        fromDate: fromDate,
        toDate: toDate,
      })
      .subscribe(() => {
        this.refresh();
      });
  }
}
