<button
  (click)="onTrigger.emit()"
  [disabled]="control?.disabled"
  [matMenuTriggerFor]="menu"
  [ngClass]="{ isDisabled: control?.disabled || disabled }"
  class="trigger"
>
  @if (icon) {
    <tgn-icon [iconName]="icon" />
  }
  <span class="trigger-label">
    @if (!displayValue || displayValue === '' || !isValid) {
      {{ defaultTitle }}
    } @else {
      {{ displayValue }}
    }
  </span>
  @if (removable && !(control?.disabled || disabled)) {
    <button (click)="clearValue($event)" class="clear-container" [disabled]="control?.disabled || disabled">
      @if (isValid && displayValue !== null && displayValue !== '') {
        <tgn-icon iconName="radixCross1" />
      }
    </button>
  }
</button>
<mat-menu #menu="matMenu">
  @if (choices && control) {
    @for (choice of choices; track $index) {
      <div class="custom-option" (click)="select(choice)">
        <span>{{ choice.label }}</span>
      </div>
    }
  } @else {
    <div (click)="$event.stopPropagation()" class="custom-container">
      <ng-content></ng-content>
    </div>
  }
</mat-menu>
