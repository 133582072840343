<div class="row row-span-sm-4">
  <div class="item col-span-2 col-span-sm-6">
    <tgn-form-element [control]="undefined" id="mediationHasProspect">
      <ng-template formElement let-control="control" let-id="id">
        <div class="column">
          <mat-checkbox [formControl]="placementForm.controls.mediationHasProspect">
            {{ 'ENTITY.Placement.mediationHasProspect' | translate }}
          </mat-checkbox>
          <mat-form-field appearance="outline">
            <input
              [formControl]="placementForm.controls.mediationHasProspectNote"
              [matTooltip]="placementForm.controls.mediationHasProspectNote.value ?? ''"
              matInput
              type="text"
            />
          </mat-form-field>

          <tgn-single-audit-field [singleAuditField]="placement.mediationHasProspectAuditField" />
        </div>
      </ng-template>
    </tgn-form-element>
  </div>
  <div class="item col-span-2 col-span-sm-6">
    <tgn-form-element [control]="undefined" id="mediationHasPlacementDate">
      <ng-template formElement let-control="control" let-id="id">
        <div class="column">
          <mat-checkbox [formControl]="placementForm.controls.mediationHasPlacementDate">
            {{ 'ENTITY.Placement.mediationHasPlacementDate' | translate }}
          </mat-checkbox>
          <mat-form-field appearance="outline">
            <input
              [formControl]="placementForm.controls.mediationHasPlacementDateNote"
              [matTooltip]="placementForm.controls.mediationHasPlacementDateNote.value ?? ''"
              matInput
              type="text"
            />
          </mat-form-field>
          <tgn-single-audit-field [singleAuditField]="placement.mediationHasPlacementDateAuditField" />
        </div>
      </ng-template>
    </tgn-form-element>
  </div>
  <div class="item col-span-2 col-span-sm-6">
    <tgn-form-element [control]="undefined" id="mediationHasPlacementNote">
      <ng-template formElement let-control="control" let-id="id">
        <div class="column">
          <mat-checkbox [formControl]="placementForm.controls.mediationIsInTrial">
            {{ 'ENTITY.Placement.mediationIsInTrial' | translate }}
          </mat-checkbox>
          <mat-form-field appearance="outline">
            <input
              [formControl]="placementForm.controls.mediationIsInTrialNote"
              [matTooltip]="placementForm.controls.mediationIsInTrialNote.value ?? ''"
              matInput
              type="text"
            />
          </mat-form-field>
          <tgn-single-audit-field [singleAuditField]="placement.mediationIsInTrialAuditField" />
        </div>
      </ng-template>
    </tgn-form-element>
  </div>
</div>
<div class="row row-span-sm-3">
  <div class="item col-span-2 col-span-sm-6">
    <tgn-form-element [control]="undefined" id="mediationPlacementSuccessful">
      <ng-template formElement let-control="control" let-id="id">
        <div class="column">
          <mat-checkbox [formControl]="placementForm.controls.mediationPlacementSuccessful">
            {{ 'ENTITY.Placement.mediationPlacementSuccessful' | translate }}
          </mat-checkbox>
          <mat-form-field appearance="outline">
            <input
              [formControl]="placementForm.controls.mediationPlacementSuccessfulNote"
              [matTooltip]="placementForm.controls.mediationPlacementSuccessfulNote.value ?? ''"
              matInput
              type="text"
            />
          </mat-form-field>
          <tgn-single-audit-field [singleAuditField]="placement.mediationPlacementSuccessfulAuditField" />
        </div>
      </ng-template>
    </tgn-form-element>
  </div>
  <div class="item col-span-2 col-span-sm-6">
    <tgn-form-element [control]="undefined" id="mediationPhonePlacementSupervision">
      <ng-template formElement let-control="control" let-id="id">
        <div class="column">
          <mat-checkbox [formControl]="placementForm.controls.mediationPhonePlacementSupervision">
            {{ 'ENTITY.Placement.mediationPhonePlacementSupervision' | translate }}
          </mat-checkbox>
          <mat-form-field appearance="outline">
            <input
              [formControl]="placementForm.controls.mediationPhonePlacementSupervisionNote"
              [matTooltip]="placementForm.controls.mediationPhonePlacementSupervisionNote.value ?? ''"
              matInput
              type="text"
            />
          </mat-form-field>
          <tgn-single-audit-field [singleAuditField]="placement.mediationPhonePlacementSupervisionAuditField" />
        </div>
      </ng-template>
    </tgn-form-element>
  </div>
  <div class="item col-span-2 col-span-sm-6">
    <tgn-form-element [control]="undefined" id="mediationOnPremisePlacementSupervision">
      <ng-template formElement let-control="control" let-id="id">
        <div class="column">
          <mat-checkbox [formControl]="placementForm.controls.mediationOnPremisePlacementSupervision">
            {{ 'ENTITY.Placement.mediationOnPremisePlacementSupervision' | translate }}
          </mat-checkbox>
          <mat-form-field appearance="outline">
            <input
              [formControl]="placementForm.controls.mediationOnPremisePlacementSupervisionNote"
              [matTooltip]="placementForm.controls.mediationOnPremisePlacementSupervisionNote.value ?? ''"
              matInput
              type="text"
            />
          </mat-form-field>
          <tgn-single-audit-field [singleAuditField]="placement.mediationOnPremisePlacementSupervisionAuditField" />
        </div>
      </ng-template>
    </tgn-form-element>
  </div>
</div>
<div class="row">
  <div class="item col-span-2 col-span-sm-6">
    <tgn-form-element [control]="undefined" id="mediationCompleted">
      <ng-template formElement let-control="control" let-id="id">
        <div class="column">
          <mat-checkbox [formControl]="placementForm.controls.mediationCompleted">
            {{ 'ENTITY.Placement.mediationCompleted' | translate }}
          </mat-checkbox>
          <mat-form-field appearance="outline">
            <input
              [formControl]="placementForm.controls.mediationCompletedNote"
              [matTooltip]="placementForm.controls.mediationCompletedNote.value ?? ''"
              matInput
              type="text"
            />
          </mat-form-field>
          <tgn-single-audit-field [singleAuditField]="placement.mediationCompletedAuditField" />
        </div>
      </ng-template>
    </tgn-form-element>
  </div>
</div>
