<div class="filter">
  <div class="filter-display-row">
    <button [matMenuTriggerFor]="menu" class="side-menu-button filter-button" type="button">
      <div class="filter-button-inner">
        <span class="no-filter-active">
          {{ 'COMPONENT.FILTER.TITLE' | translate }}
        </span>
        <tgn-icon [iconName]="'radixChevronDown'" />
      </div>
    </button>

    <tgn-active-filter-group [label]="'ENTITY.Case.caseEntryType' | translate" [show]="filter.filters.entryTypeFilter.values.length > 0">
      @for (item of filter.filters.entryTypeFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.CaseEntryType'"
          (onRemove)="onRemoveSingleEntryTypeFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Task.taskCategory' | translate" [show]="filter.filters.categoryFilter.values.length > 0">
      @for (item of filter.filters.categoryFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleCategoryFilter(item)" />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Animal.state' | translate" [show]="filter.filters.animalStateFilter.values.length > 0">
      @for (item of filter.filters.animalStateFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.AnimalState'"
          (onRemove)="onRemoveSingleAnimalStateFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'GENERAL.DOMAIN.BOX' | translate" [show]="filter.filters.boxFilter.values.length > 0">
      @for (item of filter.filters.boxFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleBoxFilter(item)" />
      }
    </tgn-active-filter-group>

    @if (showTaskStatusFilter) {
      <tgn-active-filter-group [label]="'ENTITY.Task.status' | translate" [show]="filter.filters.statusFilter.values.length > 0">
        @for (item of filter.filters.statusFilter.values; track $index) {
          <tgn-selected-filter-item
            [label]="item | enumDisplay: 'GENERAL.DOMAIN.TaskStatus'"
            (onRemove)="onRemoveSingleStatusFilter(item)"
          />
        }
      </tgn-active-filter-group>
    }

    <tgn-active-filter-group
      [label]="'COMPONENT.FILTER.TERMINATION_DATE' | translate"
      [show]="filter.filters.dateFilter.from !== null || filter.filters.dateFilter.to !== null"
    >
      <tgn-selected-filter-item
        (onRemove)="onRemoveDateFilter()"
        [label]="
          ((filter.filters.dateFilter.from | date: 'dd.MM.yyyy') ?? '...') +
          ' - ' +
          ((filter.filters.dateFilter.to | date: 'dd.MM.yyyy') ?? '...')
        "
      />
    </tgn-active-filter-group>

    @if (filter.anyFilterActive()) {
      <tgn-selected-filter-item
        class="clear-all-button"
        (click)="clearAllFilters()"
        [label]="'COMPONENT.FILTER.CLEAR_FILTER' | translate"
      />
    }
  </div>

  <mat-menu #menu class="filter-menu">
    <tgn-step-view (click)="$event.stopPropagation()" *matMenuContent>
      <ng-template [tgnStepInitial]="true" tgnStep>
        <div class="menu-item-list">
          <button [tgnNextStep]="categoryStep" class="step-button">
            {{ 'COMPONENT.FILTER.TASK_FILTER.FILTER_TYPE.CATEGORY' | translate }}
          </button>
          <button [tgnNextStep]="dateStep" class="step-button">
            {{ 'COMPONENT.FILTER.TASK_FILTER.FILTER_TYPE.TERMINATION_DATE' | translate }}
          </button>
          <button [tgnNextStep]="entryTypeStep" class="step-button">
            {{ 'COMPONENT.FILTER.TASK_FILTER.FILTER_TYPE.CASE_ENTRY_TYPE' | translate }}
          </button>
          <button [tgnNextStep]="animalStateStep" class="step-button">
            {{ 'COMPONENT.FILTER.TASK_FILTER.FILTER_TYPE.ANIMAL_STATE' | translate }}
          </button>
          <button [tgnNextStep]="boxStep" class="step-button">{{ 'COMPONENT.FILTER.TASK_FILTER.FILTER_TYPE.BOX' | translate }}</button>
          @if (showTaskStatusFilter) {
            <button [tgnNextStep]="stateStep" class="step-button">
              {{ 'COMPONENT.FILTER.TASK_FILTER.FILTER_TYPE.STATE' | translate }}
            </button>
          }
        </div>
      </ng-template>
      <ng-template #categoryStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onCategoryFilterChange($event)"
          [filter]="filter.filters.categoryFilter"
          [options]="taskCategories$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #dateStep="tgnStep" tgnStep>
        <div class="date-picker-container">
          <tgn-date-picker-range [control]="this.dateRangeGroup" />
        </div>
      </ng-template>

      <ng-template #entryTypeStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onEntryTypeFilterChange($event)"
          [filter]="filter.filters.entryTypeFilter"
          [options]="entryTypeChoices"
        />
      </ng-template>

      <ng-template #animalStateStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onAnimalStateFilterChange($event)"
          [filter]="filter.filters.animalStateFilter"
          [options]="animalStateChoices"
        />
      </ng-template>

      <ng-template #boxStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onBoxFilterChange($event)"
          [filter]="filter.filters.boxFilter"
          [options]="boxes$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #stateStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onStateFilterChange($event)"
          [filter]="filter.filters.statusFilter"
          [options]="stateChoices"
        />
      </ng-template>
    </tgn-step-view>
  </mat-menu>
</div>
