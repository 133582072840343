import { Pipe, PipeTransform } from '@angular/core';
import { AnimalType } from '@core/models/general';

import { DashboardStatisticsDto } from '../dto/statistics.dto';

@Pipe({
  name: 'dashboardStatsChange',
  standalone: true,
})
export class DashboardStatsChangePipe implements PipeTransform {
  transform(stats: DashboardStatisticsDto | undefined, animalType: AnimalType): string {
    if (!stats) {
      return '';
    }
    switch (animalType) {
      case AnimalType.DomesticAnimal:
        return `+ ${stats.numNewDomesticAnimalEntries.toString()} / - ${stats.numNewDomesticAnimalExits.toString()}`;
      case AnimalType.WildAnimal:
        return `+ ${stats.numNewWildAnimalEntries.toString()} / - ${stats.numNewWildAnimalExits.toString()}`;
      default:
        return '';
    }
  }
}
