import { DataSource } from '@angular/cdk/collections';
import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SortDirection as MatSortDirection } from '@angular/material/sort';
import { Observable, ReplaySubject, Subject, map, scan, shareReplay, startWith, switchMap } from 'rxjs';

export interface WithDataAndParamsAccess<T, P> {
  getData(): Observable<T[]>;

  getParams(): Observable<P>;
}

export class TigonDatasource<T, P> extends DataSource<T> implements WithDataAndParamsAccess<T, P> {
  private readonly params$: Observable<P>;
  private readonly data$: Observable<T[]>;

  private readonly paramsSubject = new Subject<Partial<P>>();

  constructor(initial: P, pageRequest: (params: P) => Observable<T[]>, destroyRef = inject(DestroyRef)) {
    super();

    this.params$ = this.paramsSubject.pipe(
      scan((original, update) => ({ ...original, ...update }), initial),
      startWith(initial),
      shareReplay(1),
      takeUntilDestroyed(destroyRef),
    );

    this.data$ = this.params$.pipe(
      switchMap(params => pageRequest(params)),
      shareReplay(1),
      takeUntilDestroyed(destroyRef),
    );
  }

  update(params: Partial<P> = {}) {
    this.paramsSubject.next(params);
  }

  refresh() {
    this.update({});
  }

  override connect(): Observable<readonly T[]> {
    return this.data$;
  }

  override disconnect(): void {
    this.paramsSubject.complete();
  }

  getData(): Observable<T[]> {
    return this.data$;
  }

  getParams(): Observable<P> {
    return this.params$;
  }
}

export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export function matSortToSortDirection(direction: MatSortDirection): SortDirection | undefined {
  if (!direction) {
    return undefined;
  }

  return direction === 'asc' ? SortDirection.Asc : SortDirection.Desc;
}

export interface PageResult<T> {
  items: T[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
}

export class PagedTigonDatasource<T, P> extends DataSource<T> implements WithDataAndParamsAccess<T, P> {
  readonly page$: Observable<Omit<PageResult<T>, 'items'>>;

  private readonly params$: Observable<P>;
  private readonly data$: Observable<T[]>;

  private readonly paramsSubject = new Subject<Partial<P>>();

  constructor(initial: P, pageRequest: (params: P) => Observable<PageResult<T>>, destroyRef = inject(DestroyRef)) {
    super();

    this.params$ = this.paramsSubject.pipe(
      scan((original, update) => ({ ...original, ...update }), initial),
      startWith(initial),
      shareReplay(1),
      takeUntilDestroyed(destroyRef),
    );

    const pageResult$ = this.params$.pipe(
      switchMap(params => pageRequest(params)),
      shareReplay(1),
      takeUntilDestroyed(destroyRef),
    );

    this.data$ = pageResult$.pipe(map(pageResult => pageResult.items));
    this.page$ = pageResult$.pipe(map(({ items, ...page }) => page));
  }

  update(params: Partial<P> = {}) {
    this.paramsSubject.next(params);
  }

  refresh() {
    this.update({});
  }

  override connect(): Observable<readonly T[]> {
    return this.data$;
  }

  override disconnect(): void {
    this.paramsSubject.complete();
  }

  getData(): Observable<T[]> {
    return this.data$;
  }

  getParams(): Observable<P> {
    return this.params$;
  }
}

export class PushDataSource<T> extends DataSource<T> {
  private readonly data$: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);

  constructor(data$?: T[]) {
    super();
    if (data$) {
      this.data$.next(data$);
    }
  }

  override connect(): Observable<readonly T[]> {
    return this.data$;
  }

  override disconnect(): void {
    this.data$.complete();
  }

  getData(): Observable<T[]> {
    return this.data$;
  }

  pushData(data: T[]) {
    this.data$.next(data);
  }
}
