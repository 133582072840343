import { Pipe, PipeTransform } from '@angular/core';
import { BrandedId } from '@core/utils/brand';

interface WithId {
  id: string | BrandedId<string>;
}

/**
 * Include an item in an array if it is not already included.
 * Convenience pipe for use with angular material selects which should only allow selecting
 * valid items which might not include the current item. However, if the current item is still present still
 * display it along the other items.
 */
@Pipe({
  name: 'includeItem',
  standalone: true,
})
export class IncludeItemPipe implements PipeTransform {
  transform<T>(items: T[], config: { item: T | null; compareIds: boolean }): T[] {
    if (config.item === null) {
      return items;
    }
    let isIncluded: boolean;
    if (config.compareIds) {
      isIncluded = items.some((item: T) => {
        const currentItem = item as WithId;
        const newItem = config.item as WithId;
        return currentItem.id === newItem.id && currentItem.id !== undefined && newItem.id !== undefined;
      });
    } else {
      isIncluded = items.includes(config.item);
    }
    return isIncluded ? items : [config.item, ...items];
  }
}
