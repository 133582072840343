import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

import { ProspectDto, ProspectId, ProspectUpdateDto } from '../dto/prospect.dto';

@Injectable({
  providedIn: 'root',
})
export class ProspectsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getProspect(id: ProspectId): Observable<ProspectDto> {
    return this.http.get<ProspectDto>(`${this.apiUrl}/prospects/${id}`);
  }

  updateProspect(id: string, dto: ProspectUpdateDto): Observable<ProspectDto> {
    return this.http.put<ProspectDto>(`${this.apiUrl}/prospects/${id}`, dto);
  }
}
