@if (!isReadOnly) {
  <button
    (click)="addEntry()"
    [disabled]="newPositionForm.invalid"
    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE | andRoleRestriction: roleRestriction"
    class="tgn-black-button mt-medium mb-medium bottom-action-button"
    mat-flat-button
  >
    {{ 'PAGE.BASE_DATA_POSITIONS.ACTIONS.ADD_POSITION' | translate }}
  </button>
}

<tgn-scrollable-table [showTableIfEmpty]="true">
  <div class="overflow-container">
    <form>
      <table #table="matTable" [dataSource]="datasource" mat-table>
        <tr *matHeaderRowDef="columns" mat-header-row></tr>
        <tr *matRowDef="let position; table: table; columns: columns" class="row-wide" mat-row></tr>
        <tr *matFooterRowDef="columns" class="footer-row" mat-footer-row></tr>

        <ng-container matColumnDef="isBilled">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.isBilled' | translate }}</th>
          <td *matCellDef="let position; table: table; let i = index" mat-cell>
            <mat-checkbox
              [checked]="position.isBilled && position.bill?.status !== BillStatus.Open"
              [disabled]="true"
              class="checkbox-item"
            ></mat-checkbox>
            @if (position.isBilled) {
              (
              <a [routerLink]="appRoutes.CASE_EDIT_BILL.url(position.bill!.caseId, position.bill!.id)">
                {{ position.bill!.billingNumber }}
              </a>
              )
            }
          </td>

          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="baseDataPosition">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.baseDataPosition' | translate }}*</th>
          <td *matCellDef="let position; table: table; let i = index" mat-cell>
            @if (positionFormGroups.get(position.id); as group) {
              @if (baseDataPositions$ | async; as baseDataPositions) {
                <tgn-select
                  [searchable]="true"
                  class="medium-input-field"
                  [compareIds]="true"
                  [choices]="
                    baseDataPositions
                      | includeItem: { item: group.controls.baseDataPosition.value, compareIds: true }
                      | toRadioChoice: 'name'
                  "
                  [matTooltip]="group.controls.baseDataPosition.value?.name ?? ''"
                  [control]="group.controls.baseDataPosition"
                />
              }
            } @else {
              {{ position.baseDataPosition.name }}
            }
          </td>

          <td *matFooterCellDef mat-footer-cell>
            <tgn-form-element [control]="newPositionForm.controls.baseDataPosition" [hideError]="true" [label]="null" id="value">
              <ng-template formElement let-control="control" let-id="id">
                @if (baseDataPositions$ | async; as baseDataPositions) {
                  <tgn-select
                    [searchable]="true"
                    class="medium-input-field"
                    [choices]="baseDataPositions | toRadioChoice: 'name'"
                    [compareIds]="true"
                    [control]="control"
                  />
                }
              </ng-template>
            </tgn-form-element>
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.description' | translate }}*</th>
          <td *matCellDef="let position; table: table; let i = index" mat-cell>
            @if (positionFormGroups.get(position.id); as group) {
              <mat-form-field appearance="outline" class="full-width">
                <input [matTooltip]="group.controls.description.value ?? ''" [formControl]="group.controls.description" matInput />
              </mat-form-field>
            } @else {
              <div class="max-width-description">
                <tgn-single-line-text [text]="position.description ?? ''" />
              </div>
            }
          </td>

          <td *matFooterCellDef mat-footer-cell>
            <mat-form-field appearance="outline" class="full-width">
              <input [formControl]="newPositionForm.controls.description" matInput />
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.amount' | translate }}*</th>
          <td *matCellDef="let position; table: table" mat-cell>
            <div class="max-width-column align-center">
              @if (positionFormGroups.get(position.id); as group) {
                @if (position.baseDataPosition.positionCategory.isPension) {
                  @if (position.bill) {
                    <span class="amount-field">{{ position.amount }}</span>
                  }

                  @if (position.bill && position.pensionBillingInfo !== null && position.pensionBillingInfo.length > 0) {
                    <div
                      class="position-info"
                      [matTooltip]="
                        'PAGE.CREATE_BILL.INFO.AUTO_CALCULATION' | translate: { pensionBillingInfo: position.pensionBillingInfo }
                      "
                    >
                      <tgn-icon [iconName]="'radixInfoCircled'" />
                    </div>
                  } @else {
                    <div class="position-info" [matTooltip]="'PAGE.CREATE_BILL.INFO.PENSION_POSITION_ENTRY_DATE' | translate">
                      <tgn-icon [iconName]="'radixInfoCircled'" />
                    </div>
                  }
                } @else if (!position.bill || position.bill.status === BillStatus.Open) {
                  <mat-form-field appearance="outline" class="amount-input-field">
                    <input [formControl]="group.controls.amount" matInput type="number" />
                  </mat-form-field>
                } @else {
                  <div class="amount-field">
                    {{ position.amount }}
                  </div>
                  <div class="position-info"></div>
                }
              } @else {
                <span class="amount-field">{{ position.amount }}</span>
                @if (position.pensionBillingInfo) {
                  <div
                    class="position-info"
                    [matTooltip]="'PAGE.CREATE_BILL.INFO.AUTO_CALCULATION' | translate: { pensionBillingInfo: position.pensionBillingInfo }"
                  >
                    <tgn-icon [iconName]="'radixInfoCircled'" />
                  </div>
                } @else {
                  <div class="position-info"></div>
                }
              }
            </div>
          </td>

          <td *matFooterCellDef mat-footer-cell>
            <div class="max-width-column align-center">
              @if (newPositionForm.controls.baseDataPosition.value?.positionCategory?.isPension) {
                <div class="amount-field"></div>
                <tgn-icon
                  class="info-tooltip position-info"
                  [iconName]="'radixInfoCircled'"
                  [matTooltip]="'PAGE.CREATE_BILL.INFO.PENSION_POSITION_ENTRY_DATE' | translate"
                />
              } @else {
                <tgn-form-element [control]="newPositionForm.controls.amount" [hideError]="true" [label]="null" id="'amount'">
                  <ng-template formElement let-control="control" let-id="id">
                    <div class="max-width-column">
                      <mat-form-field appearance="outline" class="amount-input-field">
                        <input
                          [formControl]="control"
                          [id]="id"
                          [placeholder]="'ENTITY.Position.amount' | translate"
                          matInput
                          type="number"
                        />
                      </mat-form-field>
                    </div>
                  </ng-template>
                </tgn-form-element>
              }
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.type' | translate }}</th>
          <td *matCellDef="let position; table: table" mat-cell>
            {{ position.baseDataPosition.type | enumDisplay: 'GENERAL.DOMAIN.PositionType' }}
          </td>

          <td *matFooterCellDef mat-footer-cell>
            @if (currentSelectedBasePosition) {
              {{ currentSelectedBasePosition.type | enumDisplay: 'GENERAL.DOMAIN.PositionType' }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.price' | translate }}</th>
          <td *matCellDef="let position; table: table" mat-cell>
            @if (positionFormGroups.get(position.id); as group) {
              @if (!position.baseDataPosition.positionCategory.isPension) {
                <mat-form-field appearance="outline" class="price-input-field">
                  <input
                    [formControl]="group.controls.price"
                    matInput
                    type="number"
                    [placeholder]="'ENTITY.Position.placeholderPrice' | translate"
                  />
                </mat-form-field>
              } @else {
                <!-- @TODO -->
              }
            } @else {
              {{ position.price | tgnCurrency: { withCHF: true } }}
            }
          </td>

          <td *matFooterCellDef class="" mat-footer-cell>
            @if (currentSelectedBasePosition && !currentSelectedBasePosition.positionCategory.isPension) {
              <mat-form-field appearance="outline" class="price-input-field">
                <input
                  [formControl]="newPositionForm.controls.price"
                  matInput
                  type="number"
                  [placeholder]="'ENTITY.Position.placeholderPrice' | translate"
                />
              </mat-form-field>
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="discountPercentage">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.discountPercentage' | translate }}</th>
          <td *matCellDef="let position; table: table" mat-cell>
            @if (positionFormGroups.get(position.id); as group) {
              <mat-form-field appearance="outline" class="small-input-field">
                <input [formControl]="group.controls.discountPercentage" matInput type="number" />
              </mat-form-field>
            } @else {
              {{ position.discountPercentage ? position.discountPercentage + '%' : '' }}
            }
          </td>

          <td *matFooterCellDef mat-footer-cell>
            <tgn-form-element
              [control]="newPositionForm.controls.discountPercentage"
              [hideError]="true"
              [label]="null"
              id="discountPercentage"
            >
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline" class="small-input-field">
                  <input
                    [formControl]="control"
                    [id]="id"
                    [placeholder]="'ENTITY.Position.placeholderDiscountPercentage' | translate"
                    matInput
                    type="number"
                  />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </td>
        </ng-container>

        <ng-container matColumnDef="vatTaxType">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.vatTaxType' | translate }}</th>
          <td *matCellDef="let position; table: table" mat-cell>
            @if (usedVatTax) {
              {{ position.baseDataPosition.vatTaxType | vatTaxDisplay: usedVatTax }}
            }
          </td>

          <td *matFooterCellDef mat-footer-cell>
            @if (usedVatTax && currentSelectedBasePosition) {
              {{ currentSelectedBasePosition.vatTaxType | vatTaxDisplay: usedVatTax }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.total' | translate }}</th>
          <td *matCellDef="let position; table: table; let i = index" class="align-right" mat-cell>
            @if (usedVatTax && (!position.baseDataPosition.positionCategory.isPension || position.bill !== null)) {
              {{ position | positionTotal: usedVatTax | tgnCurrency: { withCHF: true } }}
            }
          </td>

          <td *matFooterCellDef class="align-right" mat-footer-cell>
            @if (newPositionTotal$ | async; as newPositionTotal) {
              {{ newPositionTotal | tgnCurrency: { withCHF: true } }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let position; table: table" mat-cell>
            @if (!position.bill || position.bill.status === BillStatus.Open) {
              <button
                (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ position: position }"
                [matMenuTriggerFor]="menu"
                mat-icon-button
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            }
            <mat-menu #menu="matMenu">
              <ng-template let-position="position" matMenuContent>
                @if (!position.bill || position.bill?.status === BillStatus.Open) {
                  <button
                    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
                    (agreeEvent)="deletePosition(position)"
                    appConfirmationDialog
                    mat-menu-item
                  >
                    <span>{{ 'GENERAL.BUTTON.DELETE' | translate }}</span>
                  </button>
                }
              </ng-template>
            </mat-menu>
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>
      </table>
    </form>
  </div>
  <tgn-paginator
    (onPageEvent)="pageChanged($event)"
    [maxItemCount]="totalItems"
    [maxPages]="totalPages"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    class="paginator"
    paginator
  ></tgn-paginator>
</tgn-scrollable-table>
