import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ViewStore, ViewStoreQuery, toLoadable } from '@core/components/autocomplete/model/loadable';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { ModalComponent } from '@core/services/modal.service';
import { SearchResult, SearchResultEntity, SearchService, taskEntityTypeToEntityType } from '@core/services/search.service';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { map, of } from 'rxjs';

import { TaskDto, TaskEntityType } from '../../dtos/task.dto';
import { TaskService } from '../../services/task.service';

interface MoveOrCopyTaskResult {
  task: TaskDto;
}

interface MoveOrCopyTaskData {
  task: TaskDto;
  mode: MoveOrCopyMode;
}

interface EntityChoiceForm {
  entityType: FormControl<TaskEntityType | null>;
  entity: FormGroup<{
    entityId: FormControl<string | null>;
    name: FormControl<string | null>;
  }>;
}

export type MoveOrCopyMode = 'move' | 'copy';

@Component({
  selector: 'tgn-move-or-copy-task-dialog',
  standalone: true,
  imports: [MatDialogContent, RadioGroupComponent, AutocompleteComponent, MatDialogModule, MatButtonModule, TranslateModule],
  templateUrl: './move-or-copy-task-dialog.component.html',
  styleUrl: './move-or-copy-task-dialog.component.scss',
})
export class MoveOrCopyTaskDialogComponent extends ModalComponent<MoveOrCopyTaskData, MoveOrCopyTaskResult> implements OnInit {
  entityForm!: FormGroup<EntityChoiceForm>;
  taskData!: MoveOrCopyTaskData;

  searchAutocompleteStore: ViewStore<SearchResultEntity[], ViewStoreQuery> = new ViewStore<SearchResultEntity[], ViewStoreQuery>(
    { query: '' },
    value => {
      if (!this.entityForm.controls.entityType.value) {
        return of([]).pipe(toLoadable());
      }
      const entityType = taskEntityTypeToEntityType(this.entityForm.controls.entityType.value);
      return this.searchService.search(value.query, entityType).pipe(
        map((result: SearchResult) =>
          result.entities.filter((entity: SearchResultEntity) => {
            const task = this.taskData.task;
            switch (task.entityType) {
              case TaskEntityType.Animal:
                return entity.id !== task.animal?.id;
              case TaskEntityType.Case:
                return entity.id !== task.case?.id;
              case TaskEntityType.Station:
                return entity.id !== task.station?.id;
              case TaskEntityType.Box:
                return entity.id !== task.box?.id;
              default:
                return true;
            }
          }),
        ),
        toLoadable(),
      );
    },
  );

  taskTypeChoices: RadioChoice<TaskEntityType>[] = createEnumChoices(TaskEntityType, 'GENERAL.DOMAIN.TaskEntityType.');
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly TaskEntityType = TaskEntityType;

  constructor(
    dialogRef: MatDialogRef<MoveOrCopyTaskDialogComponent, MoveOrCopyTaskData>,
    @Inject(MAT_DIALOG_DATA) data: MoveOrCopyTaskData,
    private taskService: TaskService,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
  ) {
    super(dialogRef);

    this.taskData = data;

    this.buildForms();
  }

  ngOnInit() {
    this.entityForm.controls.entityType.valueChanges.subscribe(() => {
      this.entityForm.controls.entity.controls.entityId.setValue(null);
      this.entityForm.controls.entity.controls.name.setValue(null);
    });
  }

  displaySearchResultFn(entity: SearchResultEntity): string {
    return entity.name;
  }

  searchResultValueFn(entity: SearchResultEntity): string {
    return entity.id;
  }

  moveOrCopyTask() {
    const target = this.entityForm.getRawValue();
    if (!target.entityType || !target.entity.entityId) {
      return;
    }

    if (this.taskData.mode === 'move') {
      this.taskService.moveTask(this.taskData.task.id, target.entityType, target.entity.entityId).subscribe(task => {
        this.closeWithResult({ task: task });
      });
    } else {
      this.taskService.copyTask(this.taskData.task.id, target.entityType, target.entity.entityId).subscribe(task => {
        this.closeWithResult({ task: task });
      });
    }
  }

  setEntity(entity: SearchResultEntity) {
    this.entityForm.controls.entity.controls.entityId.setValue(entity.id);
    this.entityForm.controls.entity.controls.name.setValue(entity.name);
  }

  removeEntity() {
    this.entityForm.controls.entity.controls.entityId.setValue(null);
    this.entityForm.controls.entity.controls.name.setValue(null);
  }

  private buildForms() {
    const fb = this.formBuilder;
    this.entityForm = fb.group({
      entityType: fb.control<TaskEntityType | null>(null),
      entity: fb.group({
        name: fb.control<string | null>(null),
        entityId: fb.control<string | null>(null),
      }),
    });
  }
}
