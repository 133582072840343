import { ContactId } from '@contact/dto/contact-list-view.dto';

export interface ContactDto {
  id: ContactId;
  personNumber: number;
  firstName: string | null;
  lastName: string | null;
  company: string | null;
  companyAddendum: string | null;
  salutation: Salutation | null;
  address: string | null;
  addressAddendum: string | null;
  poBox: string | null;
  zip: string | null;
  city: string | null;
  country: string | null;
  phone: string | null;
  phoneWork: string | null;
  mobile: string | null;
  email: string | null;
  possibleDonor: boolean;
  noMailings: boolean;
  isDead: boolean;
  createdDate: string;
  legalRepresentationId: ContactId | null;
  status: ContactStatus;
  note: string | null;
  archived: boolean;
}

export type Salutation = `${SalutationEnum}`;

export enum SalutationEnum {
  Mister = 'Mister',
  Miss = 'Miss',
  MisterAndMiss = 'MisterAndMiss',
  Diverse = 'Diverse',
}

export enum ContactStatus {
  New = 'New',
  Active = 'Active',
  Inactive = 'Inactive',
}
