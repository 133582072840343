<div *tgnContextActions class="tgn-context-actions">
  <a [routerLink]="appRoutes.CASE.url()" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.ACTION.NEW_ENTRY' | translate }}
  </a>
  <button
    (click)="createNewTask()"
    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE | andRoleRestriction: GENERAL_WRITE_EXCLUDE"
    class="tgn-button"
    mat-flat-button
  >
    {{ 'GENERAL.ACTION.NEW_TASK' | translate }}
  </button>
</div>

<div class="content">
  <h2 class="dashboard-title">Dashboard</h2>

  <div class="form-grid grid-cols-8 mt-large">
    <div class="row row-span-sm-4">
      <div class="item col-span-2 col-span-sm-8">
        <tgn-simple-stats-display-card [title]="'Aktuelle Belegung'" [value]="dashboardStatistics?.numActiveAnimals" />
      </div>

      <div class="item col-span-2 col-span-sm-8">
        <tgn-simple-stats-display-card
          [smallText]="dashboardStatistics | dashboardStatsChange: AnimalType.WildAnimal"
          [title]="'Anzahl Wildtiere'"
          [value]="dashboardStatistics?.numActiveWildAnimals"
        />
      </div>

      <div class="item col-span-2 col-span-sm-8">
        <tgn-simple-stats-display-card
          [smallText]="dashboardStatistics | dashboardStatsChange: AnimalType.DomesticAnimal"
          [title]="'Anzahl Heimtiere'"
          [value]="dashboardStatistics?.numActiveDomesticAnimals"
        />
      </div>

      <div class="item col-span-2 col-span-sm-8">
        <tgn-simple-stats-display-card
          [smallText]="'+ ' + (dashboardStatistics?.numNewCases ?? '')"
          [title]="'Aktive Fälle'"
          [value]="dashboardStatistics?.numActiveCases"
        />
      </div>
    </div>

    <div class="row row-span-sm-2">
      <div class="item col-span-4 col-span-sm-8">
        <tgn-simple-stats-display-card
          [smallText]="'seit ' + firstDayOfYear"
          [title]="'Gerettete Wildtiere'"
          [value]="dashboardStatistics?.numWildAnimalsSinceYearStart"
        />
      </div>

      <div class="item col-span-4 col-span-sm-8">
        <tgn-simple-stats-display-card
          [smallText]="'seit ' + firstDayOfYear"
          [title]="'Gerettete Heimtiere'"
          [value]="dashboardStatistics?.numDomesticAnimalsSinceYearStart"
        />
      </div>
    </div>
  </div>

  <h2 class="mt-large dashboard-title">{{ 'PAGE.DASHBOARD.TODAYS_TASKS.TITLE' | translate }}</h2>

  <div class="action-container">
    @if (taskCategoryGroup === TaskCategoryGroup.Care) {
      <tgn-option-button
        (selectionChange)="changeStationFilter($event)"
        [options]="taskStationFilterOptions"
        [selectedValue]="activeTaskStationFilter()"
      />
    } @else {
      <tgn-option-button
        (selectionChange)="changeGeneralFilter($event)"
        [options]="adminDashboardOptions"
        [selectedValue]="adminFilter()"
      />
    }

    <tgn-task-filter
      (onFilterChange)="filterChanged($event)"
      [filter]="filter"
      [showTaskStatusFilter]="true"
      [taskGroupRestriction]="this.taskCategoryGroup"
    />
  </div>

  @if (datasource) {
    <tgn-scrollable-table>
      <table #table="matTable" [dataSource]="datasource" class="task-table" mat-table>
        <tr *matHeaderRowDef="columns" mat-header-row></tr>
        <tr (click)="openTask(task)" *matRowDef="let task; table: table; columns: columns" class="table-row-link" mat-row></tr>

        <ng-container matColumnDef="title">
          <th *matHeaderCellDef mat-header-cell>{{ 'PAGE.CARE_TASK.TABLE.LABEL_TASK_TITLE' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>{{ task.title }}</td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.taskCategory' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>{{ task.taskCategory.name }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.description' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            <div class="overflow-column">
              <tgn-single-line-text [text]="task.description | stripRichText" />
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="animal">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.name' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            <span class="icon-text">
              @if (isAnimalTask(task)) {
                {{ task.animal.name }}
              }
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="box">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.box' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            @if (isAnimalTask(task)) {
              {{ task.animal.box?.name ?? '' }}
            } @else if (isBoxTask(task)) {
              {{ task.box.name }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="station">
          <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.DOMAIN.STATION' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            @if (isStationTask(task)) {
              {{ task.station.name }}
            }
            <!--@TODO: -> Station auch bei anderen anzeigen? Tiere/ etc...-->
          </td>
        </ng-container>

        <ng-container matColumnDef="terminationDate">
          <th *matHeaderCellDef mat-header-cell>{{ 'PAGE.DASHBOARD.TODAYS_TASKS.LABEL_TERMINATION_DATE' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>{{ task.terminationDate | date: 'dd.MM.yyyy' }} {{ task.terminationTime }}</td>
        </ng-container>

        <ng-container matColumnDef="completionUser">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.completionUser' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            @if (task.completionUser) {
              <div class="visum-column">
                <tgn-single-line-text [text]="(task.completionUser | fullName) ?? ''" />
              </div>
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="creationUser">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.creationUser' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            @if (task.creationUser) {
              <div class="creationUser-column">
                <tgn-single-line-text [text]="(task.creationUser | fullName) ?? ''" />
              </div>
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.BUTTON.MENU_ACTIONS' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ task: task }" [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <ng-template let-task="task" matMenuContent>
                <button [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" (click)="moveTask(task)" mat-menu-item>
                  <span>{{ 'GENERAL.BUTTON.MOVE' | translate }}</span>
                </button>
                <button [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" (click)="copyTask(task)" mat-menu-item>
                  <span>{{ 'GENERAL.BUTTON.COPY' | translate }}</span>
                </button>
                <button [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" (agreeEvent)="deleteTask(task)" appConfirmationDialog mat-menu-item>
                  <span>{{ 'GENERAL.BUTTON.DELETE' | translate }}</span>
                </button>
              </ng-template>
            </mat-menu>
          </td>
        </ng-container>
      </table>

      <tgn-paginator
        (onPageEvent)="pageChanged($event)"
        [maxItemCount]="totalItems"
        [maxPages]="totalPages"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        class="paginator"
        paginator
      ></tgn-paginator>
    </tgn-scrollable-table>
  }
</div>
