import { Pipe, PipeTransform } from '@angular/core';
import { VatTaxDto, VatTaxGroup, VatTaxType } from '@baseData/dtos/vat-tax.dto';
import { BillStatus } from '@bill/dto/bill.dto';
import { PositionDto } from '@bill/dto/position.dto';
import { roundToNearest05 } from '@core/utils/helplers';

export interface PositionValues {
  amount: number;
  price: number;
  discountPercentage: number | null;
  vatTaxType: VatTaxType;
  // if position is billed use vatTax from position, otherwise use currentVatTax
  positionVatTax: VatTaxDto | null;
}

export interface PositionCostCalculation {
  absoluteTotal: number;
  vatRegularCostIncludedInTotal: number;
  vatReducedCostIncludedInTotal: number;
  vatTaxUsed: VatTaxDto | null;
}

export function calculatePositionDtoTotal(isStorno: boolean, position: PositionDto, defaultVatTaxes: VatTaxGroup): PositionCostCalculation {
  const values: PositionValues = {
    amount: position.amount,
    price: position.price,
    discountPercentage: position.discountPercentage,
    vatTaxType: position.baseDataPosition.vatTaxType,
    positionVatTax: position.vatTax,
  };
  return calculatePositionTotal(isStorno, values, defaultVatTaxes);
}

export function calculatePositionTotal(isStorno: boolean, position: PositionValues, defaultVatTaxes: VatTaxGroup): PositionCostCalculation {
  let defaultVatTax: VatTaxDto | null = null;
  if (position.vatTaxType === VatTaxType.Normal) {
    defaultVatTax = defaultVatTaxes.normal;
  } else if (position.vatTaxType === VatTaxType.Reduced) {
    defaultVatTax = defaultVatTaxes.reduced;
  }

  const usedVatTax: VatTaxDto | null = position.positionVatTax ?? defaultVatTax;

  // prices already include mws
  const totalIncludingMwst =
    (isStorno ? -1 : 1) * roundToNearest05(position.price * position.amount * (1 - (position.discountPercentage ?? 0) / 100));

  let vatRegularCostTotal = 0;
  let vatReducedCostTotal = 0;
  const vatTaxValue = usedVatTax?.value ?? 0;

  // TODO: Rounding
  const includedVatTaxCost = roundToNearest05((totalIncludingMwst / (1 + vatTaxValue / 100)) * (vatTaxValue / 100));

  if (position.vatTaxType === VatTaxType.Normal) {
    vatRegularCostTotal = includedVatTaxCost;
  } else {
    vatReducedCostTotal = includedVatTaxCost;
  }

  return {
    absoluteTotal: totalIncludingMwst,
    vatRegularCostIncludedInTotal: vatRegularCostTotal,
    vatReducedCostIncludedInTotal: vatReducedCostTotal,
    vatTaxUsed: usedVatTax,
  };
}

@Pipe({
  name: 'positionTotal',
  standalone: true,
  pure: false, // not pure as the position is passed but fields within it may change
})
export class PositionTotalPipe implements PipeTransform {
  transform(position: PositionDto, currentVatTax: VatTaxGroup): number {
    return calculatePositionDtoTotal(position.bill?.status == BillStatus.NewStorno, position, currentVatTax).absoluteTotal;
  }
}
