import { A11yModule } from '@angular/cdk/a11y';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SelectComponent } from '@core/components/select/select.component';
import { createEnumChoices } from '@core/utils/helplers';

import { RepetitionUnit } from '../../dtos/task.dto';

@Component({
  selector: 'tgn-repetition-picker',
  standalone: true,
  imports: [SelectComponent, MatInputModule, ReactiveFormsModule, A11yModule],
  templateUrl: './repetition-picker.component.html',
  styleUrl: './repetition-picker.component.scss',
})
export class RepetitionPickerComponent {
  @Input({ required: true }) control!: FormGroup<{
    interval: FormControl<number | null>;
    unit: FormControl<RepetitionUnit | null>;
  }>;

  repetitionChoices = createEnumChoices(RepetitionUnit, 'GENERAL.DOMAIN.RepetitionUnitDisplay.');
}
