import { Pipe, PipeTransform } from '@angular/core';
import { FileDto } from '@file/dto/file.dto';

@Pipe({
  name: 'isImage',
  standalone: true,
})
export class IsImagePipe implements PipeTransform {
  transform(file: FileDto): boolean {
    return file?.contentType?.startsWith('image') ?? false;
  }
}
