import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { FileListComponent } from '@file/component/file-list/file-list.component';
import { FileTagType } from '@file/dto/file-tag-type';
import { Observable } from 'rxjs';

import { AnimalDetailStore } from '../animal-detail-layout/animal-detail-layout.component';

@Component({
  selector: 'app-animal-files',
  standalone: true,
  imports: [FileListComponent, AsyncPipe],
  templateUrl: './animal-files.component.html',
  styleUrl: './animal-files.component.scss',
})
export class AnimalFilesComponent {
  animal$: Observable<AnimalDto>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly FileTagType = FileTagType;

  constructor(private animalDetailStore: AnimalDetailStore) {
    this.animal$ = this.animalDetailStore.animal$;
  }
}
