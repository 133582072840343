import 'moment/locale/de-ch';

import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDeCh from '@angular/common/locales/de-CH';
import { APP_INITIALIZER, ApplicationConfig, DEFAULT_CURRENCY_CODE, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  InMemoryScrollingOptions,
  PreloadAllModules,
  RouteReuseStrategy,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { providedIcons } from '@core/models/provided-icons';
import { CustomStringDateAdapter } from '@core/utils/date';
import { provideIcons } from '@ng-icons/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { firstValueFrom } from 'rxjs';

import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { InterceptorModule } from './interceptors/interceptor.module';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

// check if translations are ready and have been loaded
export function setupTranslateFactory(service: TranslateService): () => Promise<unknown> {
  return () => firstValueFrom(service.use('de'));
}

registerLocaleData(localeDeCh);

export const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.yyyy',
  },
  display: {
    dateInput: 'DD.MM.yyyy',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'DD.MM.yyyy',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

export class CustomReuseRouteStrategy extends BaseRouteReuseStrategy {
  /*
   * Override route reuse strategy to allow for refreshing of components if specified within routes data using
   * the 'refreshComponent' key.
   */
  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.data['refreshComponent'] ? false : future.routeConfig === curr.routeConfig;
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideIcons(providedIcons),
    { provide: RouteReuseStrategy, useClass: CustomReuseRouteStrategy },
    provideRouter(
      routes,
      withPreloading(PreloadAllModules),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
      withInMemoryScrolling(scrollConfig),
    ),
    provideAnimations(),
    importProvidersFrom(InterceptorModule),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();

      if (environment.useFirebaseAuthEmulator) {
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
      }

      return auth;
    }),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),

    { provide: LOCALE_ID, useValue: 'de-ch' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'CHF' },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'dd.MM.yyyy' },
    },

    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
    { provide: DateAdapter, useClass: CustomStringDateAdapter, deps: [] },
    // make sure translate service has loaded translations before continuing
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true,
    },
    provideCharts(withDefaultRegisterables(ChartDataLabels)),
  ],
};

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
