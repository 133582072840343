import { Pipe, PipeTransform } from '@angular/core';

interface MaybeWithId {
  id?: string;
}

@Pipe({
  name: 'addIfMissing',
  standalone: true,
})
export class AddIfMissingPipe implements PipeTransform {
  transform<T extends MaybeWithId>(list: T[] | null, item: T | null): T[] {
    if (!list || !item) {
      return list ?? [];
    }
    if (item.id === undefined) {
      return list.some(it => it == item) ? list : [...list, item];
    }
    return list.some((current: T) => current.id === item.id) ? list : [item, ...list];
  }
}
