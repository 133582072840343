import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { FileListPreviewComponent } from '@core/components/input-upload/file-list-preview/file-list-preview.component';
import { InputUploadComponent } from '@core/components/input-upload/input-upload.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { UserRole } from '@core/models/general';
import { RoleCombination } from '@core/models/role';
import { FileListComponent } from '@file/component/file-list/file-list.component';
import { FileTagType } from '@file/dto/file-tag-type';
import { FileBaseDto, FileDto } from '@file/dto/file.dto';
import { FileService, UploadResult } from '@file/service/file.service';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { from, take } from 'rxjs';

import { SettingsDto, UpdateSettingsDto } from '../../dtos/settings.dto';
import { SettingsService } from '../../services/settings.service';

type AnimalShelterSettingsForm = FormGroup<{
  shelterName: FormControl<string | null>;
  secondTitle: FormControl<string | null>;
  address: FormControl<string | null>;
  zipCode: FormControl<string | null>;
  city: FormControl<string | null>;
  phone: FormControl<string | null>;
  email: FormControl<string | null>;
  website: FormControl<string | null>;
  emergencyPhone: FormControl<string | null>;
  bankAccountName: FormControl<string | null>;
  bankAccountIban: FormControl<string | null>;
  vatTaxNumber: FormControl<string | null>;
}>;

@Component({
  selector: 'app-animal-shelter-settings-page',
  standalone: true,
  imports: [
    TranslateModule,
    CdkTextareaAutosize,
    FormElementComponent,
    MatFormField,
    FormElementDirective,
    ReactiveFormsModule,
    MatInput,
    NarrowPageContainerComponent,
    InputUploadComponent,
    FileListComponent,
    FileListPreviewComponent,
    RoleRestrictionDirective,
  ],
  templateUrl: './animal-shelter-settings-page.component.html',
  styleUrl: './animal-shelter-settings-page.component.scss',
})
export class AnimalShelterSettingsPageComponent implements OnInit {
  form?: AnimalShelterSettingsForm;
  settings?: SettingsDto;
  logo: FileBaseDto | null = null;
  initialized = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly FileTagType = FileTagType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RoleCombination = RoleCombination;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private fileService: FileService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.settingsService
      .getSettings()
      .pipe(take(1))
      .subscribe(settings => {
        this.createForm(settings);
        this.autoUpdateSettings();
        this.settings = settings;

        this.fileService
          .getFilesPaginated(this.settings.fileCollection, [], {
            pageIndex: 0,
            pageSize: 1,
          })
          .subscribe(page => {
            const items = page.items;
            if (items.length > 0) {
              this.logo = items[0];
            }
            this.initialized = true;
          });
      });
  }

  deleteFile(file: FileDto) {
    from(this.fileService.deleteFile(file))
      .pipe(take(1))
      .subscribe(() => {
        this.logo = null;
      });
  }

  fileUploaded(result: UploadResult<FileBaseDto>[]) {
    const upload = result[0];
    if (upload.file) {
      this.logo = upload.file;
    }
  }

  private autoUpdateSettings() {
    this.form!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const formValues: UpdateSettingsDto = this.form!.getRawValue();
      this.settingsService.updateSettings(formValues).subscribe();
    });
  }

  private createForm(settings: SettingsDto) {
    const fb = this.fb;
    this.form = fb.group({
      shelterName: fb.control(settings.shelterName),
      secondTitle: fb.control(settings.secondTitle),
      address: fb.control(settings.address),
      zipCode: fb.control(settings.zipCode),
      city: fb.control(settings.city),
      phone: fb.control(settings.phone),
      emergencyPhone: fb.control(settings.emergencyPhone),
      email: fb.control(settings.email),
      website: fb.control(settings.website),
      bankAccountName: fb.control(settings.bankAccountName),
      bankAccountIban: fb.control(settings.bankAccountIban),
      vatTaxNumber: fb.control(settings.vatTaxNumber),
    });

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Backoffice);
  }
}
