import { CdkStepperModule } from '@angular/cdk/stepper';
import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ChipInputComponent } from '@core/components/chip-input/chip-input.component';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { StepperComponent } from '@core/components/stepper/stepper.component';
import { routes_config } from '@core/constants';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { ReplaySubject, map, switchMap, take, tap } from 'rxjs';

import { AnimalEntryFormComponent } from '../../components/animal-entry-form/animal-entry-form.component';
import { EntryCheckComponent } from '../../components/entry-check/entry-check.component';
import { HealthFormComponent } from '../../components/health-form/health-form.component';
import { ParticularitiesFormComponent } from '../../components/particularities-form/particularities-form.component';
import { CaseDto, CaseId } from '../../dtos/case.dto';
import { DomesticCaseAnimalDto } from '../../dtos/domestic-case-animal.dto';
import { CaseAnimalService } from '../../services/case-animal.service';
import { CaseService } from '../../services/case.service';

export interface CurrentAnimalContext {
  animalIndex: number;
  caseDto: CaseDto;
  caseAnimalDto: DomesticCaseAnimalDto;
}

@Component({
  selector: 'app-entry-check-page',
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    CdkStepperModule,
    StepperComponent,
    ReactiveFormsModule,
    MatRadioModule,
    FormElementComponent,
    ChipInputComponent,
    ParticularitiesFormComponent,
    HealthFormComponent,
    AnimalEntryFormComponent,
    AsyncPipe,
    EntryCheckComponent,
  ],
  templateUrl: './entry-check-page.component.html',
  styleUrl: './entry-check-page.component.scss',
})
export class EntryCheckPageComponent implements OnInit {
  currentAnimalContext$: ReplaySubject<CurrentAnimalContext> = new ReplaySubject<CurrentAnimalContext>(1);
  resetStepper$: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(
    private caseService: CaseService,
    private route: ActivatedRoute,
    private caseAnimalService: CaseAnimalService,
    private router: Router,
    private snackbar: SnackbarService,
  ) {}

  ngOnInit() {
    this.loadDataFromParams();
  }

  previousAnimal(context: CurrentAnimalContext) {
    if (context.animalIndex === 0) {
      this.router.navigate(routes_config.CASE_NEW_EXISTING.url(context.caseDto.id));
      return;
    }

    const previousIndex = context.animalIndex - 1;
    this.router.navigate(routes_config.ENTRYCHECK.url(context.caseDto.id, previousIndex));
  }

  nextAnimalOrFinish(context: CurrentAnimalContext) {
    if (this.isLastAnimal(context)) {
      this.caseService
        .completeCase(context.caseDto.id)
        .pipe(take(1))
        .subscribe(() => {
          this.snackbar.showSuccessMessage('PAGE.NEW_CASE.CREATE_CASE_SUCCESS');
          this.router.navigate(routes_config.CARE_ANIMAL.url(context.caseDto.caseAnimals[0].animal.id));
        });
    } else {
      const nextIndex = context.animalIndex + 1;
      this.router.navigate(routes_config.ENTRYCHECK.url(context.caseDto.id, nextIndex), {});
    }
  }

  isLastAnimal(context: CurrentAnimalContext): boolean {
    return context.animalIndex === context.caseDto.caseAnimals.length - 1;
  }

  isFirstAnimal(context: CurrentAnimalContext): boolean {
    return context.animalIndex === 0;
  }

  private loadDataFromParams() {
    this.route.paramMap
      .pipe(
        tap(() => {
          this.resetStepper$.next();
        }),
        map((paramMap: ParamMap) => {
          return this.parseParams(paramMap);
        }),
        switchMap(({ caseId, animalIndex }: { caseId: CaseId; animalIndex: number }) => {
          return this.caseService.get(caseId as CaseId).pipe(
            take(1),
            map(caseDto => {
              return { animalIndex, caseDto };
            }),
          );
        }),
        switchMap(({ caseDto, animalIndex }: { caseDto: CaseDto; animalIndex: number }) => {
          return this.caseAnimalService.getDomestic(caseDto.id, caseDto.caseAnimals[animalIndex].animal.id).pipe(
            map((caseAnimalDto: DomesticCaseAnimalDto) => {
              const context: CurrentAnimalContext = {
                animalIndex: animalIndex,
                caseDto: caseDto,
                caseAnimalDto: caseAnimalDto,
              };
              return context;
            }),
          );
        }),
      )
      .subscribe((context: CurrentAnimalContext) => {
        this.currentAnimalContext$.next(context);
      });
  }

  private parseParams(paramMap: ParamMap) {
    const caseId: CaseId = paramMap.get('caseId') as CaseId;
    let animalIndex = 0;
    try {
      animalIndex = parseInt(paramMap.get('index') ?? '0');
    } catch (error: unknown) {}
    return { caseId, animalIndex };
  }
}
