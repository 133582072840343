import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

export interface Tab {
  label: string;
  path: string;
}

@Component({
  selector: 'tgn-router-nav-tabs',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, TranslateModule],
  templateUrl: './router-nav-tabs.component.html',
  styleUrls: ['./router-nav-tabs.component.scss', '../nav-tabs/nav-tabs.component.scss'],
})
export class RouterNavTabsComponent {
  @Input({ required: true }) tabs!: Tab[];
}
