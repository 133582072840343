import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { AnimalExitDialogComponent } from '@animal/components/animal-exit-dialog/animal-exit-dialog.component';
import { AnimalFilterComponent } from '@animal/components/animal-filter/animal-filter.component';
import { AnimalFilter, AnimalStateFilter, GeneralAnimalFilterChoice } from '@animal/components/animal-filter/model/animal-filter';
import { AnimalListViewDto } from '@animal/dtos/animal-list-view.dto';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { WithDefaultAnimalNamePipe } from '@animal/pipes/with-default-animal-name.pipe';
import { AnimalService } from '@animal/services/animal.service';
import { BaseDataDto, StationDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { CaseAnimalService } from '@case/services/case-animal.service';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { FilterOption, OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { WildAnimalIconComponent } from '@core/components/wild-animal-icon/wild-animal-icon.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { EXCLUDE_VETERINARIAN, GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AnimalState, AnimalType } from '@core/models/general';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { NotInPipe } from '@core/pipes/not-in.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { createEnumChoices, openDownloadedBlobPdf } from '@core/utils/helplers';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CurrentUserService } from '@user/service/current-user.service';
import { debounceTime, filter, skip, switchMap, take } from 'rxjs';

interface TableConfig {
  query: string;
  pageIndex: number;
  pageSize: number;
  filter: AnimalFilter;
  stationFilter: StationDto | null;
}

@Component({
  selector: 'tgn-care-animals-list',
  standalone: true,
  imports: [
    AnimalFilterComponent,
    AsyncPipe,
    AvatarImageComponent,
    ConfirmationDialogDirective,
    OptionButtonComponent,
    FormsModule,
    ScrollableTableComponent,
    TypesafeMatTableModule,
    MatTableModule,
    TranslateModule,
    WithDefaultAnimalNamePipe,
    WildAnimalIconComponent,
    EnumDisplayPipe,
    DatePipe,
    MatMenuModule,
    PaginatorComponent,
    MatIconModule,
    RouterLink,
    MatInputModule,
    SingleLineTextComponent,
    MatButtonModule,
    NotInPipe,
    RoleRestrictionDirective,
  ],
  templateUrl: './care-animals-list.component.html',
  styleUrl: './care-animals-list.component.scss',
})
export class CareAnimalsListComponent {
  stationFilterOptions: FilterOption<StationDto | null>[] = [];
  datasource: PagedTigonDatasource<AnimalListViewDto, TableConfig>;
  filter: AnimalFilter;
  query = signal('');
  animalButtonFilterChoices: FilterOption<GeneralAnimalFilterChoice>[] = createEnumChoices<GeneralAnimalFilterChoice>(
    GeneralAnimalFilterChoice,
    'GENERAL.DOMAIN.GeneralAnimalFilterChoice.',
  ).map((choice: RadioChoice<GeneralAnimalFilterChoice>) => {
    const filterOption: FilterOption<GeneralAnimalFilterChoice> = {
      value: choice.object,
      label: choice.label,
    };
    return filterOption;
  });

  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  totalPages = 1;
  totalItems = 0;
  columns = [
    'avatarImage',
    'box',
    'trdId',
    'entryDate',
    'entryType',
    'name',
    'group',
    'species',
    'feedingHabits',
    'sex',
    'birthdate',
    'coloring',
    'status',
    'actions',
  ];

  activeStationFilter = signal<StationDto | null>(null);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalState = AnimalState;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  protected readonly destroyRef = inject(DestroyRef);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly EXCLUDE_VETERINARIAN = EXCLUDE_VETERINARIAN;

  constructor(
    private animalService: AnimalService,
    private snackbar: SnackbarService,
    private router: Router,
    private baseDataService: BaseDataService,
    private caseAnimalService: CaseAnimalService,
    private modalService: ModalService,
    private currentUserService: CurrentUserService,
  ) {
    this.baseDataService
      .getBaseData()
      .pipe(take(1))
      .subscribe((baseData: BaseDataDto) => {
        const filters: FilterOption<StationDto | null>[] = baseData.stations.map(station => ({
          label: station.name,
          value: station,
        }));

        this.stationFilterOptions = [
          {
            label: 'GENERAL.DOMAIN.TaskStationFilter.All',
            value: null,
          },
          ...filters,
        ];
      });

    this.filter = AnimalFilter.empty().setStateFilter(this.filterNewAndInCare());

    this.datasource = new PagedTigonDatasource<AnimalListViewDto, TableConfig>(
      {
        query: '',
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        filter: this.filter,
        stationFilter: this.activeStationFilter(),
      },
      params => {
        return this.animalService.getAnimalsPaginated(
          {
            query: params.query,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            filter: this.filter,
          },
          GeneralAnimalFilterChoice.All,
          this.activeStationFilter(),
        );
      },
    );

    this.currentUserService.usedStation$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(usedStation => {
      if (usedStation) {
        this.activeStationFilter.set(usedStation);
        this.datasource.update({ stationFilter: usedStation, pageIndex: 0 });
      }
    });

    toObservable(this.query)
      .pipe(skip(1), debounceTime(300), takeUntilDestroyed())
      .subscribe(query => {
        this.datasource.update({ query });
      });

    this.datasource.page$.pipe(takeUntilDestroyed()).subscribe(page => {
      this.totalPages = page.totalPages;
      this.totalItems = page.totalItems;
      this.pageSize = page.pageSize;
      this.pageIndex = page.pageIndex;
    });
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.datasource.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }

  updateAnimalFilter(animalFilter: AnimalFilter) {
    this.filter = animalFilter;
    this.datasource.update({ filter: animalFilter, pageIndex: 0 });
  }

  openLastEntryCheck(animal: AnimalDto) {
    this.animalService.getLatestCase(animal.id).subscribe(latestCase => {
      const index = latestCase.caseAnimals.findIndex(it => it.animal.id === animal.id);
      this.router.navigate(this.appRoutes.ENTRYCHECK.url(latestCase.id, index));
    });
  }

  openLastExitCheck(animal: AnimalDto) {
    this.animalService.getLatestCase(animal.id).subscribe(latestCase => {
      const route = this.appRoutes.ANIMAL_EXITCHECK.url(latestCase.id, animal.id);
      this.router.navigate(route);
    });
  }

  changeFilter(stationFilter: StationDto | null) {
    this.activeStationFilter.set(stationFilter);
    this.datasource.update({ stationFilter: stationFilter, pageIndex: 0 });
  }

  downloadBoxCard(animal: AnimalListViewDto) {
    this.animalService
      .getLatestCase(animal.id)
      .pipe(
        switchMap(latestCase => {
          return this.caseAnimalService.downloadBoxCardPdf(animal.id, latestCase.id);
        }),
      )
      .subscribe(response => {
        openDownloadedBlobPdf(response);
      });
  }

  downloadAdmissionForm(animal: AnimalDto) {
    this.animalService
      .getLatestCase(animal.id)
      .pipe(
        switchMap(latestCase => {
          return this.caseAnimalService.downloadAdmissionFormPdf(animal.id, latestCase.id);
        }),
      )
      .subscribe(response => {
        openDownloadedBlobPdf(response);
      });
  }

  openExitAnimalDialog(animal: AnimalListViewDto) {
    this.animalService.getLatestCase(animal.id).subscribe(latestCase => {
      this.modalService
        .open(AnimalExitDialogComponent, { animal: animal, case: latestCase }, { width: ModalWidth.Medium })
        .afterClosed()
        .pipe(filter(notNullish))
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .subscribe(() => {
          this.datasource.refresh();
        });
    });
  }

  openCasePlacement(animal: AnimalListViewDto) {
    this.animalService.getLatestCase(animal.id).subscribe(latestCase => {
      this.router.navigate(this.appRoutes.CASE_DETAIL_PLACEMENT.url(latestCase.id));
    });
  }

  archiveAnimal(animal: AnimalListViewDto) {
    this.animalService.archiveAnimal(animal.id).subscribe(() => {
      this.snackbar.showSuccessMessage('Tier wurde archiviert');
      this.datasource.refresh();
    });
  }

  private filterNewAndInCare(): AnimalStateFilter {
    return {
      id: 'stateFilter',
      values: [AnimalState.Entry, AnimalState.InShelter],
    };
  }
}
