import { Component } from '@angular/core';
import { BaseDataRacesComponent } from '@baseData/components/base-data-races/base-data-races.component';
import { BaseDataRelationkindComponent } from '@baseData/components/base-data-relationkind/base-data-relationkind.component';
import { BaseDataSpeciesComponent } from '@baseData/components/base-data-species/base-data-species.component';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { AnimalType } from '@core/models/general';

@Component({
  selector: 'app-base-data-animal-page',
  standalone: true,
  imports: [
    BaseDataRelationkindComponent,
    CollapsibleComponent,
    NarrowPageContainerComponent,
    BaseDataSpeciesComponent,
    BaseDataRacesComponent,
  ],
  templateUrl: './base-data-animal-page.component.html',
  styleUrl: './base-data-animal-page.component.scss',
})
export class BaseDataAnimalPageComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
}
