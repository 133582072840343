import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateRelationKindDto, UpdateRelationKindDto } from '@baseData/dtos/create-relation-kind.dto';
import { RelationKindDto, RelationKindId } from '@baseData/dtos/relation-kind.dto';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RelationKindService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Inactive relationKinds are included here
   */
  getAll(): Observable<RelationKindDto[]> {
    return this.http.get<RelationKindDto[]>(`${this.apiUrl}/master/relationKinds`);
  }

  getAllActive(): Observable<RelationKindDto[]> {
    return this.http.get<RelationKindDto[]>(`${this.apiUrl}/master/relationKinds/active`);
  }

  getById(id: RelationKindId): Observable<RelationKindDto> {
    return this.http.get<RelationKindDto>(`${this.apiUrl}/master/relationKinds/${id}`);
  }

  create(dto: CreateRelationKindDto): Observable<RelationKindDto> {
    return this.http.post<RelationKindDto>(`${this.apiUrl}/master/relationKinds`, dto);
  }

  update(id: RelationKindId, dto: UpdateRelationKindDto): Observable<RelationKindDto> {
    return this.http.put<RelationKindDto>(`${this.apiUrl}/master/relationKinds/${id}`, dto);
  }
}
