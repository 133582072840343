import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { UserRole } from '@core/models/general';
import { RoleAccess, RoleRestriction } from '@core/models/role';
import { BaseService } from '@core/services/base-service';
import { UserDto } from '@user/dto/user.dto';
import { CurrentUserService } from '@user/service/current-user.service';
import { Observable, take } from 'rxjs';

export enum RestrictedSection {
  Animal = 'Animal',
  Case = 'Case',
  Backoffice = 'Backoffice',
}

function userHasRole(userDto: UserDto, role: UserRole): boolean {
  return userDto.roles.includes(role);
}

@Injectable({ providedIn: 'root' })
export class AccessService extends BaseService {
  currentUser$!: Observable<UserDto>;

  constructor(private currentUserService: CurrentUserService) {
    super();
    this.currentUser$ = currentUserService.currentUser$;
  }

  disableBasedOnRole(formGroup: FormGroup | AbstractControl, section: RestrictedSection) {
    this.currentUser$.pipe(take(1)).subscribe(user => {
      if (this.restrictWriteAccess(user, section)) {
        formGroup.disable();
      }
    });
  }

  disableBasedOnRoleRestriction(formGroup: FormGroup | AbstractControl, restriction: RoleRestriction) {
    this.currentUser$.pipe(take(1)).subscribe(user => {
      if (restriction.exclude && RoleAccess.anyMatches(user.roles, restriction.exclude)) {
        formGroup.disable();
      } else if (restriction.restrictTo && !RoleAccess.anyMatches(user.roles, restriction.restrictTo)) {
        formGroup.disable();
      }
    });
  }

  restrictWriteAccess(user: UserDto, section: RestrictedSection): boolean {
    if (userHasRole(user, UserRole.Admin)) {
      return false;
    }

    if (userHasRole(user, UserRole.Veterinarian) || userHasRole(user, UserRole.Guest)) {
      return true;
    } else {
      switch (section) {
        case RestrictedSection.Animal:
          return false;
        case RestrictedSection.Case:
          return this.handleAdminSection(user);
        case RestrictedSection.Backoffice:
          return this.handleBackofficeSection(user);
        default:
          return false;
      }
    }
  }

  private handleAdminSection(user: UserDto) {
    // Office and Tiermedizin role has write access to admin section
    return !(
      userHasRole(user, UserRole.Office) ||
      userHasRole(user, UserRole.VeterinaryMedicine) ||
      userHasRole(user, UserRole.VerantwortlicheHundewesen)
    );
  }

  private handleBackofficeSection(user: UserDto) {
    // Office + Superuser has access to backoffice
    return !(userHasRole(user, UserRole.Office) && userHasRole(user, UserRole.Superuser));
  }
}
