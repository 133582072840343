<h1 mat-dialog-title>
  {{ 'COMPONENT.CaseCloseDialog.TITLE' | translate: { caseName: caseName } }}
</h1>

@if (initialized) {
  <div class="mt-large" mat-dialog-content>
    <div class="form-grid grid-cols-6">
      <div class="row">
        <p class="item col-span-6">
          {{ 'COMPONENT.CaseCloseDialog.INFO_CLOSE_ANIMALS' | translate }}
        </p>
      </div>

      @for (animal of animals; track animal.id) {
        <div class="row">
          <div class="item">
            <mat-checkbox [formControl]="animalsFormArray.controls[$index]">
              <span>
                {{ animal | animalName }} (Status: {{ animal.state }} {{ animal.state | enumDisplay: 'GENERAL.DOMAIN.AnimalState' }})
              </span>
            </mat-checkbox>
          </div>
        </div>
      }
    </div>

    <div class="mt-large">
      <p>{{ 'COMPONENT.CaseCloseDialog.INFO' | translate }}</p>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>
      {{ 'GENERAL.BUTTON.CANCEL' | translate }}
    </button>
    <button (click)="complete()" class="tgn-button" mat-flat-button>
      {{ 'GENERAL.BUTTON.COMPLETE' | translate }}
    </button>
  </div>
}
