import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCell } from '@angular/material/table';
import { AddStationDialogComponent } from '@baseData/components/add-station-dialog/add-station-dialog.component';
import { BaseDataStationBoxesComponent } from '@baseData/components/base-data-station-boxes/base-data-station-boxes.component';
import { StationService } from '@baseData/services/station.service';
import { StationDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ReplaySubject, filter, map, take } from 'rxjs';

@Component({
  selector: 'app-base-data-box-page',
  standalone: true,
  imports: [
    AsyncPipe,
    FieldErrorTranslationPipe,
    FormElementComponent,
    MatButton,
    MatCell,
    TranslateModule,
    ContextActionsDirective,
    BaseDataStationBoxesComponent,
    CollapsibleComponent,
    NarrowPageContainerComponent,
  ],
  templateUrl: './base-data-box-page.component.html',
  styleUrl: './base-data-box-page.component.scss',
})
export class BaseDataBoxPageComponent implements OnInit {
  stations$: ReplaySubject<StationDto[]> = new ReplaySubject<StationDto[]>(1);

  constructor(
    private baseDataService: BaseDataService,
    private modalService: ModalService,
    private snackbarService: SnackbarService,
    private stationService: StationService,
  ) {}

  ngOnInit() {
    this.baseDataService
      .getBaseData()
      .pipe(
        map(data => data.stations),
        take(1),
      )
      .subscribe((stations: StationDto[]) => {
        this.stations$.next(stations);
      });
  }

  addStation() {
    this.modalService
      .open(AddStationDialogComponent, {}, { width: ModalWidth.Small })
      .afterClosed()
      .pipe(take(1), filter(notNullish))
      .subscribe(result => {
        this.snackbarService.showSuccessMessage(`Station '${result.name}' wurde hinzugefügt.`);

        this.stationService
          .getAll()
          .pipe(take(1))
          .subscribe((stations: StationDto[]) => {
            this.stations$.next(stations);
          });
      });
  }
}
