<tgn-narrow-page-container class="content">
  <h1>{{ 'PAGE.ANIMAL_SHELTER_SETTINGS.TITLE' | translate }}</h1>
  <p class="mt-medium mb-medium">Der Name und weitere Daten hier werden in der Rechnung aufgeführt!</p>
  @if (initialized && form && settings) {
    <div class="form-grid grid-cols-6">
      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="form.controls.shelterName" [label]="'ENTITY.Settings.shelterName' | translate" id="shelterName">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input matInput type="text" [formControl]="control" [placeholder]="'ENTITY.Settings.placeholderShelterName' | translate" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="form.controls.secondTitle" [label]="'ENTITY.Settings.secondTitle' | translate" id="secondTitle">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input matInput type="text" [formControl]="control" [placeholder]="'ENTITY.Settings.secondTitle' | translate" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="form.controls.address" [label]="'ENTITY.Settings.address' | translate" id="address">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input matInput type="text" [formControl]="control" [placeholder]="'ENTITY.Settings.placeholderAddress' | translate" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-2 col-span-sm-6">
          <tgn-form-element [control]="form.controls.zipCode" [label]="'ENTITY.Settings.zipCode' | translate" id="zipCode">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input matInput type="text" [formControl]="control" [placeholder]="'ENTITY.Settings.placeholderZipCode' | translate" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
        <div class="item col-span-4 col-span-sm-6">
          <tgn-form-element [control]="form.controls.city" [label]="'ENTITY.Settings.city' | translate" id="city">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input matInput type="text" [formControl]="control" [placeholder]="'ENTITY.Settings.placeholderCity' | translate" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.phone" [label]="'ENTITY.Settings.phone' | translate" id="phone">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input matInput type="text" [formControl]="control" [placeholder]="'ENTITY.Settings.placeholderPhone' | translate" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.email" [label]="'ENTITY.Settings.email' | translate" id="email">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input matInput type="text" [formControl]="control" [placeholder]="'ENTITY.Settings.placeholderEmail' | translate" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="form.controls.emergencyPhone"
            [label]="'ENTITY.Settings.emergencyPhone' | translate"
            id="emergencyPhone"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input matInput type="text" [formControl]="control" [placeholder]="'ENTITY.Settings.placeholderPhone' | translate" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.website" [label]="'ENTITY.Settings.website' | translate" id="website">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input matInput type="text" [formControl]="control" [placeholder]="'ENTITY.Settings.placeholderWebsite' | translate" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.bankAccountName"
            [label]="'ENTITY.Settings.bankAccountName' | translate"
            id="bankAccountName"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  [formControl]="control"
                  [placeholder]="'ENTITY.Settings.placeholderBankAccountName' | translate"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.bankAccountIban"
            [label]="'ENTITY.Settings.bankAccountIban' | translate"
            id="bankAccountIban"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  [formControl]="control"
                  [placeholder]="'ENTITY.Settings.placeholderBankAccountIban' | translate"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="form.controls.vatTaxNumber" [label]="'ENTITY.Settings.vatTaxNumber' | translate" id="vatTaxNumber">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input matInput type="text" [formControl]="control" [placeholder]="'ENTITY.Settings.placeholderVatTaxNumber' | translate" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="undefined" [label]="'ENTITY.Settings.logo' | translate" id="logo">
            <ng-template formElement let-control="control" let-id="id">
              @if (!logo) {
                <tgn-input-upload
                  [tgnRoleRestriction]="{ restrictTo: [UserRole.Admin, RoleCombination(UserRole.Office, UserRole.Superuser)] }"
                  [multiple]="false"
                  [dropzoneLabel]="'PAGE.ANIMAL_SHELTER_SETTINGS.ACTIONS.LOGO_UPLOAD' | translate"
                  [fileCollection]="settings.fileCollection"
                  (onUploadCompleted)="fileUploaded($event)"
                  [acceptedFileTypes]="['image']"
                  [tags]="[]"
                />
              } @else {
                <tgn-file-list-preview
                  [roleRestriction]="{ restrictTo: [UserRole.Admin, RoleCombination(UserRole.Office, UserRole.Superuser)] }"
                  [files]="logo ? [logo] : []"
                  (onFileDeleted)="deleteFile($event)"
                />
              }
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    </div>
  }
</tgn-narrow-page-container>
