@if (variant === 'badge') {
  <select [formControl]="control" class="badge-select">
    @for (choice of choices; track $index) {
      <option [value]="choice.object">
        <span>{{ choice.label }}</span>
      </option>
    }
  </select>
} @else {
  <mat-form-field appearance="outline" class="tgn-select full-size">
    <mat-select
      #singleSelect
      [compareWith]="compareIds ? idCompareFn : defaultCompareFn"
      [formControl]="control"
      [placeholder]="placeholder"
      [multiple]="multiple"
    >
      @if (searchable) {
        <mat-option>
          <ngx-mat-select-search [formControl]="optionFilterTextControl"></ngx-mat-select-search>
        </mat-option>
      }
      @if (includeEmptyChoice && control.value !== null && control.value !== undefined) {
        <mat-option [value]="null"></mat-option>
      }
      @for (option of filteredOptions | async; track $index) {
        <mat-option [value]="option.object">
          {{ option.label | translate }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
}
