<div *tgnContextActions class="tgn-context-actions">
  <a (click)="openCreatePositionCategoryModal()" class="tgn-button" mat-flat-button>
    {{ 'PAGE.BASE_DATA_POSITIONS.ACTIONS.ADD_CATEGORY_ENTRY' | translate }}
  </a>
</div>

<div>
  <tgn-form-element [control]="includeExpiredPositions" [id]="'title'" class="row-span-3">
    <ng-template formElement let-control="control" let-id="id">
      <mat-checkbox [checked]="control.value" [disabled]="control.disabled" [formControl]="includeExpiredPositions" class="checkbox-item">
        {{ 'PAGE.BASE_DATA_POSITIONS.LABELS.includeExpiredPositions' | translate }}
      </mat-checkbox>
    </ng-template>
  </tgn-form-element>
</div>

@for (entry of dataSources.entries(); track entry[0]) {
  <tgn-collapsible [title]="entry[0].name" [expanded]="true" [variant]="'withBgColorLighter'" class="mt-medium">
    @if (entry[0].isPension) {
      <p class="mt-medium mb-medium pension-info">
        {{ 'PAGE.BASE_DATA_POSITIONS.INFO.PENSION_CATEGORY' | translate }}
      </p>
    }
    <tgn-base-data-position-table [dataSource]="entry[1]" [category]="entry[0]" />
  </tgn-collapsible>
}

@if (dataSources.size === 0) {
  <div class="no-elements">{{ 'PAGE.BASE_DATA_POSITIONS.NO_CATEGORIES' | translate }}</div>
}
