import { NgClass } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  ISO_LOCAL_DATE_FORMAT,
  ISO_YEAR_MONTH_FORMAT,
  IsoLocalDateString,
  SWISS_LOCAL_DATE_FORMAT,
  SWISS_MONTH_YEAR_FORMAT,
  SwissMonthYearString,
  YearString,
} from '@core/utils/date';
import moment from 'moment/moment';
import { startWith } from 'rxjs';

export enum DatePickerType {
  Date = 'date',
  Month = 'month',
  Year = 'year',
}

function monthYearValidator(control: AbstractControl): ValidationErrors | null {
  return moment(control.value, 'MM.YYYY', true).isValid() ? null : { invalidMonthYear: true };
}

function yearValidator(control: AbstractControl): ValidationErrors | null {
  return moment(control.value, 'YYYY', true).isValid() ? null : { invalidYear: true };
}

@Component({
  selector: 'tgn-date-input',
  standalone: true,
  imports: [MatDatepickerModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatIconButton, NgClass],
  templateUrl: './date-input.component.html',
  styleUrl: './date-input.component.scss',
})
export class DateInputComponent implements OnInit {
  @Input({ required: true }) control!: FormControl;
  @Input() placeholder = 'Datum';

  @Input() type: DatePickerType = DatePickerType.Date;

  @ViewChild(MatDatepicker) datepicker!: MatDatepicker<unknown>;
  internalControl!: FormControl<SwissMonthYearString | YearString | null>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly DatePickerType = DatePickerType;
  private readonly destroyRef = inject(DestroyRef);

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.internalControl = this.fb.control(null, [this.type === DatePickerType.Month ? monthYearValidator : yearValidator]);
    if (this.type !== DatePickerType.Date) {
      this.internalControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
        if (this.type === DatePickerType.Month) {
          if (monthYearValidator(this.internalControl)) {
            return;
          }
          const date = moment(value, SWISS_MONTH_YEAR_FORMAT, true);
          if (date.isValid()) {
            this.setValue(date.format(ISO_YEAR_MONTH_FORMAT));
          }
        } else if (this.type === DatePickerType.Year) {
          if (yearValidator(this.internalControl)) {
            return;
          }
          this.setValue(value);
        }
      });
    }

    this.control.valueChanges.pipe(startWith(this.control.value), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.type === DatePickerType.Month) {
        const internalValue = moment(this.control.value, ISO_YEAR_MONTH_FORMAT).format(SWISS_MONTH_YEAR_FORMAT);
        this.internalControl.setValue(internalValue, { emitEvent: false });
      } else if (this.type === DatePickerType.Year) {
        this.internalControl.setValue(this.control.value, { emitEvent: false });
      } else {
        this.internalControl.setValue(moment(this.control.value, ISO_LOCAL_DATE_FORMAT).format(SWISS_LOCAL_DATE_FORMAT), {
          emitEvent: false,
        });
      }
    });
  }

  chosenYearHandler(normalizedYear: IsoLocalDateString) {
    //const ctrlValue = this.date.value;
    //ctrlValue.year(normalizedYear.year());
    //this.date.setValue(ctrlValue);
    if (this.type === DatePickerType.Year) {
      this.setValue(normalizedYear);
      this.datepicker.close();
    }
  }

  chosenMonthHandler(normalizedMonth: IsoLocalDateString, datepicker: MatDatepicker<unknown>) {
    if (this.type === DatePickerType.Month) {
      this.setValue(normalizedMonth);
      datepicker.close();
    }
  }

  setValue(value: IsoLocalDateString | null) {
    // convert to iso format
    if (value === null) {
      this.control.setValue(null);
      return;
    }

    let values = value.split('-');

    if (this.type === DatePickerType.Month) {
      values = values.slice(0, 2);
    } else if (this.type === DatePickerType.Year) {
      values = values.slice(0, 1);
    }

    const formatted = values.reverse().join('.');

    this.internalControl.setValue(formatted, { emitEvent: false });
    this.control.setValue(value);
  }
}
