import { Pipe, PipeTransform } from '@angular/core';

import { VatTaxGroup, VatTaxType } from '../dtos/vat-tax.dto';

@Pipe({
  name: 'vatTaxDisplay',
  standalone: true,
})
export class VatTaxDisplayPipe implements PipeTransform {
  transform(type: VatTaxType | undefined, currentVatTax: VatTaxGroup): string {
    if (!type) {
      return '';
    }

    switch (type) {
      case VatTaxType.Normal:
        return `${currentVatTax.normal.value.toString()}% (normal)`;
      case VatTaxType.Reduced:
        return `${currentVatTax.reduced.value.toString()}% (reduziert)`;
      case VatTaxType.None:
        return 'Ohne (0.0%)';
      default:
        console.error('forgot to handle vat tax type');
        return '';
    }
  }
}
