<form class="form-grid grid-cols-6">
  <h2 class="">
    {{ 'PAGE.ENTRY_CHECK.FORM.SECTION_AT_ANIMAL' | translate }}
  </h2>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.generalCondition"
        [emphasizedLabel]="true"
        [id]="'generalCondition'"
        [label]="'ENTITY.EntryCheck.generalCondition'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-4 grid-cols-sm-1">
            <tgn-radio-group [choices]="generalFieldChoices" [control]="control" [id]="id" cdkFocusInitial class="full-width" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.generalConditionNote"
        [id]="'generalConditionNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="true"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              class=""
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.weight"
        [emphasizedLabel]="true"
        [id]="'weight'"
        [label]="'ENTITY.EntryCheck.weight'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="weight-control w-full">
            <mat-form-field appearance="outline" class="flex-grow">
              <input
                [formControl]="control"
                [id]="id"
                [placeholder]="'ENTITY.EntryCheck.weight' | translate"
                class="weight-input"
                matInput
                type="text"
              />
            </mat-form-field>

            <tgn-select [choices]="weightUnitChoices" [control]="form.controls.weightUnit" class="unit-select" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.sex"
        [emphasizedLabel]="true"
        [id]="'sex'"
        [label]="'ENTITY.EntryCheck.sex'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-5 grid-cols-sm-1">
            <tgn-radio-group [choices]="sexChoices" [control]="control" [id]="id" class="full-width" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.eyes"
        [emphasizedLabel]="true"
        [id]="'eyes'"
        [label]="'ENTITY.EntryCheck.eyes'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-4 grid-cols-sm-1">
            <tgn-radio-group [choices]="generalFieldChoices" [control]="control" [id]="id" class="full-width" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.eyesAffected"
        [id]="'eyesAffected'"
        [label]="'ENTITY.EntryCheck.eyesAffected'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-3 grid-cols-sm-1">
            <tgn-radio-group
              [allowDeselect]="true"
              [choices]="affectedGeneralRegionChoices"
              [control]="control"
              [id]="id"
              class="full-width"
            />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.eyesNote"
        [id]="'eyesNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              class=""
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.ears"
        [emphasizedLabel]="true"
        [id]="'ears'"
        [label]="'ENTITY.EntryCheck.ears'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-4 grid-cols-sm-1">
            <tgn-radio-group [choices]="generalFieldChoices" [control]="control" [id]="id" class="full-width" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.earsAffected"
        [id]="'eyesAffected'"
        [label]="'ENTITY.EntryCheck.earsAffected'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-3 grid-cols-sm-1">
            <tgn-radio-group
              [allowDeselect]="true"
              [choices]="affectedGeneralRegionChoices"
              [control]="control"
              [id]="id"
              class="full-width"
            />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.earsNote"
        [id]="'earsNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              class=""
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.nose"
        [emphasizedLabel]="true"
        [id]="'nose'"
        [label]="'ENTITY.EntryCheck.nose'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-4 grid-cols-sm-1">
            <tgn-radio-group [choices]="generalFieldChoices" [control]="control" [id]="id" class="full-width" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.noseAffected"
        [id]="'eyesAffected'"
        [label]="'ENTITY.EntryCheck.noseAffected'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-3 grid-cols-sm-1">
            <tgn-radio-group
              [allowDeselect]="true"
              [choices]="affectedGeneralRegionChoices"
              [control]="control"
              [id]="id"
              class="full-width"
            />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.noseNote"
        [id]="'noseNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              class=""
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.mouthOrTeeth"
        [emphasizedLabel]="true"
        [id]="'mouthOrTeeth'"
        [label]="'ENTITY.EntryCheck.mouthOrTeeth'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-4 grid-cols-sm-1">
            <tgn-radio-group [choices]="generalFieldChoices" [control]="control" [id]="id" class="full-width" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.mouthOrTeethAffected"
        [id]="'mouthOrTeethAffected'"
        [label]="'ENTITY.EntryCheck.mouthOrTeethAffected'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-3 grid-cols-sm-1">
            <tgn-checkbox-group
              [choices]="extendedAffectedRegionChoices"
              [compareIds]="false"
              [control]="form.controls.mouthOrTeethAffected"
              [id]="id"
              class="full-width"
            />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.mouthOrTeethNote"
        [id]="'mouthOrTeethNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              class=""
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.skin"
        [emphasizedLabel]="true"
        [id]="'skin'"
        [label]="'ENTITY.EntryCheck.skin'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-4 grid-cols-sm-1">
            <tgn-radio-group [choices]="generalFieldChoices" [control]="control" [id]="id" class="full-width" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.skinNote"
        [id]="'skinNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              class=""
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.clawsOrFeet"
        [emphasizedLabel]="true"
        [id]="'clawsOrFeet'"
        [label]="'ENTITY.EntryCheck.clawsOrFeet'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-4 grid-cols-sm-1">
            <tgn-radio-group [choices]="generalFieldChoices" [control]="control" [id]="id" class="full-width" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.affectedLimbs"
        [id]="'affectedLimbs'"
        [label]="'ENTITY.EntryCheck.affectedLimbs'"
        class="row-span-3 mt-small"
      >
        <ng-template formElement>
          <tgn-checkbox-group [choices]="affectedLimbChoices" [compareIds]="false" [control]="form.controls.affectedLimbs" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.clawsOrFeetNote"
        [id]="'clawsOrFeetNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              class=""
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.coatOrFeathers"
        [emphasizedLabel]="true"
        [id]="'coatOrFeathers'"
        [label]="'ENTITY.EntryCheck.coatOrFeathers'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-4 grid-cols-sm-1">
            <tgn-radio-group [choices]="generalFieldChoices" [control]="control" [id]="id" class="full-width" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.coatOrFeathersNote"
        [id]="'coatOrFeathersNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              class=""
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.anus"
        [emphasizedLabel]="true"
        [id]="'anus'"
        [label]="'ENTITY.EntryCheck.anus'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-4 grid-cols-sm-1">
            <tgn-radio-group [choices]="generalFieldChoices" [control]="control" [id]="id" class="full-width" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.anusNote"
        [id]="'anusNote'"
        [label]="'GENERAL.FORM.LABEL.NOTE'"
        [optional]="false"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input
              [formControl]="control"
              [id]="id"
              [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
              class=""
              matInput
              type="text"
            />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
</form>
