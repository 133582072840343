<div *tgnContextActions class="tgn-context-actions">
  <button (click)="openDownloadRapports()" [tgnRoleRestriction]="{ exclude: [UserRole.Veterinarian] }" mat-flat-button>
    {{ 'PAGE.ANIMALS.ACTIONS.DOWNLOAD_RAPPORTS' | translate }}
  </button>
  <a [routerLink]="appRoutes.CASE.url()" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.ACTION.NEW_ENTRY' | translate }}
  </a>
</div>

<div class="content">
  <h1>{{ 'GENERAL.DOMAIN.ANIMAL_P' | translate }}</h1>

  <div class="action-container">
    <tgn-option-button
      (selectionChange)="changeGeneralAnimalFilter($event)"
      [options]="animalButtonFilterChoices"
      [selectedValue]="generalStateFilter()"
    />
    <mat-form-field appearance="outline" class="search-input">
      <input [(ngModel)]="query" matInput placeholder="Suchen" type="text" />
    </mat-form-field>
  </div>
  <div class="filter-container">
    <tgn-animal-filter (onFilterChange)="this.updateAnimalFilter($event)" [filter]="filter" />
  </div>

  @if (columns$ | async; as columns) {
    <tgn-scrollable-table>
      <table #table="matTable" [dataSource]="datasource" mat-table>
        <tr *matHeaderRowDef="columns" mat-header-row></tr>
        <tr
          *matRowDef="let animal; table: table; columns: columns"
          [routerLink]="appRoutes.ANIMAL_DETAIL_GENERAL.url(animal.id)"
          class="table-row-link"
          mat-row
        ></tr>

        <ng-container matColumnDef="avatarImage">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.avatarImage' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            <tgn-avatar-image [avatar]="animal" [hideImageUpload]="true" size="size-xl" />
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.name' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            <span class="icon-text">
              <div class="max-width-name-column">
                <tgn-single-line-text [text]="animal.name | withDefaultAnimalName" />
              </div>
              @if (animal.type === AnimalType.WildAnimal) {
                <tgn-wild-animal-icon class="ml-md" />
              }
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="trdId">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.trdId' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            <span class="icon-text">
              {{ animal.trdId }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="group">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.group' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>{{ animal.group?.name ?? '' }}</td>
        </ng-container>

        <ng-container matColumnDef="subGroup">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.subGroup' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            <tgn-single-line-text [text]="animal.subGroup?.name ?? ''" />
          </td>
        </ng-container>

        <ng-container matColumnDef="species">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.species' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            <tgn-single-line-text [text]="animal.species?.name ?? ''" />
          </td>
        </ng-container>

        <ng-container matColumnDef="race">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.race' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>{{ animal.race ?? '' }}</td>
        </ng-container>

        <ng-container matColumnDef="box">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.box' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>{{ animal.box?.name ?? '' }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.state' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>{{ animal.status | enumDisplay: 'GENERAL.DOMAIN.AnimalState' }}</td>
        </ng-container>

        <ng-container matColumnDef="placementType">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Placement.placementType' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>{{ animal.placementType | enumDisplay: 'GENERAL.DOMAIN.PlacementType' }}</td>
        </ng-container>

        <ng-container matColumnDef="placementState">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Placement.placementState' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            {{ animal.placementState | enumDisplay: 'GENERAL.DOMAIN.PlacementState' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="placementNote">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Placement.placementNote' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            <div class="max-width-column">
              <tgn-single-line-text [text]="animal.placementNote ?? ''" />
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="specials">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.specials' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            <div class="max-width-column">
              <tgn-single-line-text [text]="animal.specials ?? ''" />
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="behaviour">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.behaviour' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            <div class="max-width-column">
              <tgn-single-line-text [text]="animal.behaviour ?? ''" />
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="furLength">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.furLength' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            {{ animal.furLength | enumDisplay: 'GENERAL.DOMAIN.FurLength' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="entryType">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Case.caseEntryType' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            {{ animal?.currentCase?.entryType | enumDisplay: 'GENERAL.DOMAIN.CaseEntryType' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sex">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.sex' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>{{ animal.sex | enumDisplay: 'GENERAL.DOMAIN.AnimalSexShort' }}</td>
        </ng-container>

        <ng-container matColumnDef="birthdate">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.birthdate' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>{{ animal.birthdate | date }}</td>
        </ng-container>

        <ng-container matColumnDef="coloring">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.coloring' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>{{ animal.coloring }}</td>
        </ng-container>

        <ng-container matColumnDef="entryDate">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Case.entryDate' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>{{ animal.currentCase?.entryDate | date: 'dd.MM.yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.BUTTON.MENU_ACTIONS' | translate }}</th>
          <td *matCellDef="let animal; table: table" mat-cell>
            <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ animal: animal }" [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <ng-template let-animal="animal" matMenuContent>
                <a [routerLink]="appRoutes.ANIMAL_DETAIL_GENERAL.url(animal.id)" mat-menu-item>
                  <span>{{ 'PAGE.ANIMALS.ACTIONS.TO_ANIMAL_DETAIL' | translate }}</span>
                </a>
                <a [routerLink]="appRoutes.CARE_ANIMAL.url(animal.id)" mat-menu-item>
                  <span>{{ 'PAGE.ANIMALS.ACTIONS.OPEN_ANIMAL_CARE' | translate }}</span>
                </a>
                @if (animal.status | notIn: [AnimalState.Exited, AnimalState.Completed]) {
                  <button [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" (click)="openExitAnimalDialog(animal)" mat-menu-item>
                    <span>{{ 'PAGE.ANIMALS.ACTIONS.OPEN_EXIT_ANIMAL_DIALOG' | translate }}</span>
                  </button>
                }
                @if (animal.type === AnimalType.DomesticAnimal) {
                  <a [routerLink]="appRoutes.ANIMAL_DETAIL_PLACEMENT.url(animal.id)" mat-menu-item>
                    <span>{{ 'PAGE.ANIMALS.ACTIONS.OPEN_PLACEMENT' | translate }}</span>
                  </a>
                }
                <button (click)="openLastEntryCheck(animal)" mat-menu-item>
                  <span>{{ 'PAGE.ANIMALS.ACTIONS.OPEN_ENTRY_CHECK' | translate }}</span>
                </button>
                <button (click)="openLastExitCheck(animal)" mat-menu-item>
                  <span>{{ 'PAGE.ANIMALS.ACTIONS.OPEN_EXIT_CHECK' | translate }}</span>
                </button>
                @if (animal.type === AnimalType.DomesticAnimal) {
                  <button [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" (click)="openCasePlacement(animal)" mat-menu-item>
                    <span>{{ 'PAGE.ANIMALS.ACTIONS.START_PLACEMENT' | translate }}</span>
                  </button>
                }
                <button
                  (agreeEvent)="archiveAnimal(animal)"
                  appConfirmationDialog
                  [dialogTitle]="'PAGE.ANIMALS.CONFIRMATION.ARCHIVE_CONFIRMATION_TITLE' | translate"
                  [dialogDescription]="'PAGE.ANIMALS.CONFIRMATION.ARCHIVE_QUESTION' | translate"
                  mat-menu-item
                >
                  <span>{{ 'GENERAL.BUTTON.ARCHIVE' | translate }}</span>
                </button>
              </ng-template>
            </mat-menu>
          </td>
        </ng-container>
      </table>
      <tgn-paginator
        (onPageEvent)="pageChanged($event)"
        [maxItemCount]="totalItems"
        [maxPages]="totalPages"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        class="paginator"
        paginator
      ></tgn-paginator>
    </tgn-scrollable-table>
  }
</div>
