@if (billForm) {
  <div class="tgn-context-actions" *tgnContextActions>
    <a [routerLink]="appRoutes.CASE_DETAIL_BILLS.url(caseId)" mat-flat-button>{{ 'GENERAL.BUTTON.PREVIOUS' | translate }}</a>
    @if (this.bill) {
      <button (click)="downloadPdf($event, bill!)" mat-flat-button class="tgn-button" type="button">
        <span>{{ 'PAGE.CASE_BILLS.ACTIONS.CREATE_PDF' | translate }}</span>
      </button>
    }
    @if (!this.bill) {
      <button (click)="saveOrUpdateBill()" [disabled]="billForm.invalid" class="tgn-button ml-medium" mat-flat-button>
        {{ 'GENERAL.BUTTON.CREATE' | translate }}
      </button>
    }
    @if (
      this.bill &&
      this.bill.status !== BillStatus.NewStorno &&
      this.bill.status !== BillStatus.Cancelled &&
      this.bill.status !== BillStatus.Open
    ) {
      <button
        class="tgn-button ml-medium"
        mat-flat-button
        (agreeEvent)="createStornoBill(this.bill!)"
        [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
        appConfirmationDialog
        [dialogTitle]="'PAGE.CASE_BILLS.CONFIRM.STORNO_TITLE' | translate"
        [dialogDescription]="'PAGE.CASE_BILLS.CONFIRM.STORNO_MESSAGE' | translate"
      >
        <span>{{ 'PAGE.CREATE_BILL.ACTIONS.CREATE_STORNO' | translate }}</span>
      </button>
    }
  </div>

  <tgn-narrow-page-container [size]="NarrowPageContainerSize.Large">
    <h1>
      {{ 'PAGE.CREATE_BILL.TITLE' | translate: { billingNumber: billingNumber } }}
      @if (this.bill?.status === BillStatus.Cancelled) {
        <span>(Storniert)</span>
      }
      @if (this.bill?.status === BillStatus.NewStorno) {
        <span>(Neue Stornorechnung)</span>
      }
    </h1>

    @if (this.bill?.status === BillStatus.NewStorno && cancelledStornoParent) {
      <div class="storno-info">
        <span>Dies ist eine Storno-Rechnung für bestehende Rechnung:</span>
        <a class="with-link" [routerLink]="appRoutes.CASE_EDIT_BILL.url(caseId, cancelledStornoParent!.id)">
          {{ cancelledStornoParent!.billingNumber }}
        </a>
      </div>
    }

    @if (this.bill?.status === BillStatus.Cancelled && stornoChildBill) {
      <div class="storno-info">
        <span>Dies ist eine stornierte Rechnung. Neu erstellte Storno-Rechnung:</span>
        <a class="with-link" [routerLink]="appRoutes.CASE_EDIT_BILL.url(caseId, stornoChildBill!.id)">
          {{ stornoChildBill!.billingNumber }}
        </a>
      </div>
    }

    @if (this.bill?.status === BillStatus.Sent || this.bill?.status === BillStatus.Paid) {
      <div class="storno-info">
        <span>Diese Rechnung wurde bereits versendet oder bezahlt und kann nicht mehr bearbeitet werden.</span>
      </div>
      <div></div>
    }

    <div class="form-grid grid-cols-6">
      @if (this.bill) {
        <div class="row">
          <div class="item col-span-3">
            <tgn-form-element
              [control]="billForm.controls.status"
              [hideError]="true"
              [label]="'ENTITY.Bill.status' | translate"
              id="status"
            >
              <ng-template formElement let-control="control" let-id="id">
                <tgn-select [choices]="billStatusChoices" [control]="control" [id]="id" />
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
      }

      <div class="row row-span-sm-2">
        <div class="item col-span-3">
          <tgn-form-element
            [control]="billForm.controls.billingContact"
            [hideError]="true"
            [label]="'ENTITY.Bill.billingContact' | translate"
            id="billingContact"
          >
            <ng-template formElement let-control="control" let-id="id">
              @if (contactChoices.length === 0) {
                <div class="warn-container">
                  <tgn-icon [iconName]="'radixExclamationTriangle'" />
                  <span>Es ist kein Kontakt auf diesem Fall vorhanden. Es muss zuerst ein neuer Kontakt hinzugefügt werden!</span>
                </div>
              } @else {
                <tgn-select
                  [choices]="contactChoices"
                  [compareIds]="true"
                  [control]="control"
                  [id]="id"
                  [placeholder]="'GENERAL.PLACEHOLDER.CHOOSE' | translate"
                />
              }
            </ng-template>
          </tgn-form-element>
        </div>
        <div class="item col-span-3">
          <tgn-form-element
            [control]="billForm.controls.type"
            [hideError]="true"
            [label]="'ENTITY.Bill.billRangeType' | translate"
            id="type"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select
                [choices]="billRangeTypeChoices"
                [control]="control"
                [id]="id"
                [placeholder]="'GENERAL.PLACEHOLDER.CHOOSE' | translate"
              />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-3">
          <tgn-form-element
            [control]="billForm.controls.invoiceDocumentDate"
            [hideError]="true"
            [label]="'ENTITY.Bill.invoiceDocumentDate' | translate"
            id="invoiceDocumentDate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
        <div class="item col-span-3">
          <tgn-form-element
            [control]="billForm.controls.billingDate"
            [hideError]="true"
            [label]="'ENTITY.Bill.billingDate' | translate"
            id="billingDate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-3 row-span-sm-2">
        <div class="item col-span-6">
          <tgn-form-element [control]="billForm.controls.note" [hideError]="true" [label]="'ENTITY.Bill.note' | translate" id="note">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.Bill.note' | translate"
                  cdkAutosizeMaxRows="10"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  matInput
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="billForm.controls.paymentCondition"
            [hideError]="true"
            [label]="'ENTITY.Bill.paymentCondition' | translate"
            id="paymentCondition"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Bill.paymentCondition' | translate" matInput type="text" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-6">
          <tgn-form-element [control]="billForm.controls.alreadyPaidAmount" [label]="'ENTITY.Bill.alreadyPaidAmount' | translate" id="">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.Bill.placeholderPrepaidAmount' | translate"
                  matInput
                  type="number"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    </div>

    @if (billForm.controls.billingDate.value !== null) {
      <h2 class="section">{{ 'PAGE.CREATE_BILL.SECTION_POSITIONS' | translate }}</h2>

      @if (animalDatasourcesMap && usedVatTax) {
        @for (animalAndDatasource of animalDatasourcesMap.entries(); track animalAndDatasource[0]) {
          <tgn-collapsible class="billing-collapsible" [title]="animalAndDatasource[0] | animalName" [variant]="'withBgColorLighter'">
            @if ((animalAndDatasource[1].getData() | async)?.length! > 0) {
              <app-bill-services-position-table
                [caseId]="caseId"
                [animalId]="animalAndDatasource[0].id"
                [bill]="bill ?? null"
                (onUpdatePositions)="reloadPositions(animalAndDatasource[1])"
                (onAddPosition)="reloadPositions(animalAndDatasource[1])"
                [positionContext]="PositionContext.CaseAnimal"
                [datasource]="animalAndDatasource[1]"
                [billingPeriod$]="billingPeriod$"
                [usedVatTax]="usedVatTax"
              ></app-bill-services-position-table>
            } @else {
              <div class="empty-state-container">Es sind keine Leistungen auf diesem Tier vorhanden für diesen Zeitraum.</div>
            }
          </tgn-collapsible>
        }
      }

      @if (caseDatasource && usedVatTax) {
        <tgn-collapsible class="billing-collapsible" [title]="'PAGE.CREATE_BILL.CASE_COSTS' | translate" [variant]="'withBgColorLighter'">
          @if ((caseDatasource.getData() | async)?.length! > 0) {
            <app-bill-services-position-table
              (onUpdatePositions)="reloadPositions(caseDatasource)"
              (onAddPosition)="reloadPositions(caseDatasource)"
              [animalId]="null"
              [caseId]="caseId"
              [bill]="bill ?? null"
              [positionContext]="PositionContext.Case"
              [datasource]="caseDatasource"
              [billingPeriod$]="billingPeriod$"
              [usedVatTax]="usedVatTax"
            ></app-bill-services-position-table>
          } @else {
            <div class="empty-state-container">Es sind keine Leistungen auf diesem Fall vorhanden für diesen Zeitraum.</div>
          }
        </tgn-collapsible>
      }

      <h2 class="section">
        {{ 'PAGE.CREATE_BILL.SECTION_INVOICE_CALCULATIONS' | translate }}
        @if (stornoChildBill) {
          <span>
            (Storniert durch
            <a class="with-link" [routerLink]="appRoutes.CASE_EDIT_BILL.url(caseId, stornoChildBill!.id)">
              {{ stornoChildBill!.billingNumber }}
            </a>
            )
          </span>
        }
        @if (cancelledStornoParent) {
          <span>(Neue Stornorechnung)</span>
        }
      </h2>

      <div class="container-light-grey">
        @if (invoiceCalculations$ | async; as invoiceCalculations) {
          <div class="form-grid grid-cols-2 invoice-calculation">
            <div class="row">
              <div class="item item-bold">Zwischentotal</div>
              <div class="item item-end">
                {{ invoiceCalculations.totalTaxable | tgnCurrency: { withCHF: true } }}
              </div>
            </div>

            @if (usedVatTax$ | async; as usedVatTax) {
              <div class="row">
                <div class="item">
                  @if (usedVatTax$) {
                    <span>Mehrwehrtsteuer {{ usedVatTax.normal.value }}% (normal)</span>
                  }
                </div>
                <div class="item item-end">
                  {{ invoiceCalculations.vatRegular | tgnCurrency: { withCHF: true } }}
                </div>
              </div>

              <div class="row">
                <div class="item">
                  @if (usedVatTax$) {
                    <span>Mehrwehrtsteuer {{ usedVatTax.reduced.value }}% (reduziert)</span>
                  }
                </div>
                <div class="item item-end">
                  {{ invoiceCalculations.vatReduced | tgnCurrency: { withCHF: true } }}
                </div>
              </div>
            }

            <div class="row">
              <div class="item item-bold">Rechnungsbetrag</div>
              <div class="item item-end">
                {{ invoiceCalculations.total | tgnCurrency: { withCHF: true } }}
              </div>
            </div>

            <div class="row">
              <div class="item">Bisherige Zahlung</div>
              <div class="item item-end">
                <span>{{ invoiceCalculations.alreadyPaidAmount | tgnCurrency: { withCHF: true } }}</span>
              </div>
            </div>

            @if (bill?.status !== BillStatus.NewStorno) {
              <div class="row">
                <div class="item item-bold">Offener Betrag</div>
                <div class="item item-end">
                  {{ invoiceCalculations.openToPay | tgnCurrency: { withCHF: true } }}
                </div>
              </div>
            }
          </div>
        }
      </div>
    }

    @if (this.bill && this.bill.status !== BillStatus.Cancelled && this.bill.status !== BillStatus.NewStorno) {
      <button (click)="downloadPdf($event, bill!)" mat-flat-button class="tgn-button mt-medium" type="button">
        <span>{{ 'PAGE.CASE_BILLS.ACTIONS.CREATE_PDF' | translate }}</span>
      </button>
    }
  </tgn-narrow-page-container>
}
