<mat-expansion-panel
  [expanded]="expanded"
  [ngClass]="{ withBgColor: variant === 'withBgColor', withBgColorLighter: variant === 'withBgColorLighter' }"
  class="custom-collapsible"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h4 class="collapsible-title">{{ title }}</h4>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="spacing-800">
    <ng-content></ng-content>
  </div>
</mat-expansion-panel>
