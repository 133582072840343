import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { AnimalDetailStore } from '@animal/pages/animal-detail/animal-detail-layout/animal-detail-layout.component';
import { VatTaxGroup } from '@baseData/dtos/vat-tax.dto';
import { VatTaxDisplayPipe } from '@baseData/pipes/vat-tax-display.pipe';
import { VatTaxService } from '@baseData/services/vat-tax.service';
import { BillingRelevantInfoComponent } from '@bill/components/billing-relevant-info/billing-relevant-info.component';
import {
  PositionTableConfig,
  ServicesPositionContext,
  ServicesPositionTableComponent,
} from '@bill/components/services-position-table/services-position-table.component';
import { BillingRelevantInfoDto } from '@bill/dto/billing-relevant-info.dto';
import { CreatePositionDto, PositionDto } from '@bill/dto/position.dto';
import { PositionTotalPipe } from '@bill/pipes/position-total.pipe';
import { BillService } from '@bill/services/bill.service';
import { PositionService } from '@bill/services/position.service';
import { CaseDto } from '@case/dtos/case.dto';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SelectComponent } from '@core/components/select/select.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FvsCurrencyPipe } from '@core/pipes/currency.pipe';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';

@Component({
  selector: 'app-animal-services',
  standalone: true,
  imports: [
    ScrollableTableComponent,
    MatTableModule,
    TypesafeMatTableModule,
    TranslateModule,
    SingleLineTextComponent,
    FormElementComponent,
    ReactiveFormsModule,
    FormElementDirective,
    SelectComponent,
    MatButton,
    EnumDisplayPipe,
    FvsCurrencyPipe,
    VatTaxDisplayPipe,
    PositionTotalPipe,
    MatMenuModule,
    ConfirmationDialogDirective,
    MatIconButton,
    MatIcon,
    ServicesPositionTableComponent,
    BillingRelevantInfoComponent,
  ],
  templateUrl: './animal-services.component.html',
  styleUrl: './animal-services.component.scss',
})
export class AnimalServicesComponent implements OnInit {
  datasource?: PagedTigonDatasource<PositionDto, PositionTableConfig>;

  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  totalPages = 1;
  totalItems = 0;
  animal?: AnimalDto;
  latestCase?: CaseDto;

  billingRelevantInfo!: BillingRelevantInfoDto;
  currentVatTax?: VatTaxGroup;

  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly PositionContext = ServicesPositionContext;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private animalDetailStore: AnimalDetailStore,
    private positionService: PositionService,
    private billService: BillService,
    private vatTaxService: VatTaxService,
  ) {}

  ngOnInit() {
    this.animalDetailStore.state$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(state => {
      this.animal = state.animal;
      this.latestCase = state.latestCase;
      this.createDatasource(state.latestCase, this.animal);
      this.datasource!.page$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(page => {
        this.totalPages = page.totalPages;
        this.totalItems = page.totalItems;
      });

      this.billService
        .getBillingRelevantInfo(state.latestCase.id)
        .pipe(take(1))
        .subscribe((info: BillingRelevantInfoDto) => {
          this.billingRelevantInfo = info;
        });
    });

    this.vatTaxService.getCurrent().subscribe((vatTax: VatTaxGroup) => {
      this.currentVatTax = vatTax;
    });
  }

  addPosition(dto: CreatePositionDto) {
    this.positionService
      .createAnimalPosition(
        {
          caseId: this.latestCase!.id,
          animalId: this.animal!.id,
        },
        dto,
      )
      .subscribe(() => {
        this.datasource?.update({});
      });
  }

  private createDatasource(caseDto: CaseDto, animalDto: AnimalDto) {
    this.datasource = new PagedTigonDatasource<PositionDto, PositionTableConfig>(
      {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        target: {
          type: 'animal',
          caseId: caseDto.id,
          animalId: animalDto.id,
        },
      },
      params => {
        return this.positionService.findPositionsPaginated(
          params.target,
          {
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
          },
          true,
        );
      },
      this.destroyRef,
    );
  }
}
