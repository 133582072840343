import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TaskRepetition } from '../dtos/task.dto';

type Nullable<T> = {
  [K in keyof T]: T[K] | null;
};

@Pipe({
  name: 'repetitionDisplay',
  standalone: true,
})
export class RepetitionDisplayPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(repetition: Nullable<TaskRepetition> | null): string | null {
    if (repetition === null) {
      return null;
    }
    if (repetition.interval === null || repetition.unit === null) {
      return null;
    }
    if (!repetition.unit) {
      return null;
    }

    const unit = this.translate.instant('GENERAL.DOMAIN.RepetitionUnitDisplay.' + repetition.unit);

    const prefix = this.translate.instant('COMPONENT.RepetitionDisplay.LABEL_ALL');
    return `${prefix} ${repetition.interval} ${unit}`;
  }
}
