import { DatePipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AnimalTasksTableComponent } from '@animal/components/animal-tasks-table/animal-tasks-table.component';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { CreateTaskDialogComponent } from '@care/components/create-task-dialog/create-task-dialog.component';
import { TaskCategoryGroup, TaskEntityType, TaskId } from '@care/dtos/task.dto';
import { RepetitionDisplayPipe } from '@care/pipes/repetition-display.pipe';
import { OPEN_TASK_PARAM } from '@case/components/case-tasks/case-tasks.component';
import { OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PaginatorComponent } from '@core/components/paginator/paginator.component';
import { routes_config } from '@core/constants';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { ContextItemDirective } from '@core/directives/context-item.directive';
import { TypeSafeMatCellDef, TypeSafeMatRowDef } from '@core/directives/mat-table.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ReplaySubject, filter, map } from 'rxjs';

import { AnimalDetailStore } from '../animal-detail-layout/animal-detail-layout.component';

@Component({
  selector: 'app-animal-tasks',
  standalone: true,
  imports: [
    ConfirmationDialogDirective,
    DatePipe,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTableModule,
    PaginatorComponent,
    TranslateModule,
    TypeSafeMatCellDef,
    RepetitionDisplayPipe,
    ContextActionsDirective,
    TypeSafeMatRowDef,
    AnimalTasksTableComponent,
    OptionButtonComponent,
    RoleRestrictionDirective,
    ContextItemDirective,
  ],
  templateUrl: './animal-tasks.component.html',
  styleUrl: './animal-tasks.component.scss',
})
export class AnimalTasksComponent implements OnInit {
  animal?: AnimalDto;
  refreshTasks: ReplaySubject<void> = new ReplaySubject<void>(1);
  openTask$: ReplaySubject<TaskId> = new ReplaySubject<TaskId>(1);
  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    protected store: AnimalDetailStore,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
  ) {
    store.animal$.subscribe((animal: AnimalDto) => {
      this.animal = animal;
    });
  }

  ngOnInit() {
    this.openRoutedTask();
  }

  createNewTask() {
    this.modalService
      .open(
        CreateTaskDialogComponent,
        {
          entity: this.animal!,
          entityType: TaskEntityType.Animal,
          taskCategoryGroups: [TaskCategoryGroup.Care, TaskCategoryGroup.Admin],
        },
        { width: ModalWidth.ExtraLarge },
      )
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe(() => {
        this.refreshTasks.next();
      });
  }

  private openRoutedTask() {
    this.activatedRoute.queryParams
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(params => params[OPEN_TASK_PARAM]),
        filter(notNullish),
      )
      .subscribe((task: string) => {
        this.openTask$.next(task as TaskId);
      });
  }
}
