import { AsyncPipe } from '@angular/common';
import { Component, Injectable, WritableSignal, effect, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatChip } from '@angular/material/chips';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AnimalStateSwitchesComponent } from '@animal/pages/animal-detail/components/animal-state-switches/animal-state-switches.component';
import { ContactId } from '@contact/dto/contact-list-view.dto';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { NavTabsComponent, Tab } from '@core/components/nav-tabs/nav-tabs.component';
import { routes_config } from '@core/constants';
import { UserRole } from '@core/models/general';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { Observable, ReplaySubject, map, switchMap } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

import { ContactDto } from '../../../dto/contact.dto';
import { ContactService } from '../../../service/contact-service';

interface ContactState {
  contact: ContactDto;
}

@Injectable()
export class ContactDetailStore {
  state$: Observable<ContactState>;

  contact$: Observable<ContactDto>;

  private contactIdSubject = new ReplaySubject<ContactId>(1);

  constructor(contactService: ContactService) {
    const id$ = this.contactIdSubject.pipe(takeUntilDestroyed());

    this.contact$ = id$.pipe(switchMap(id => contactService.get(id)));

    this.state$ = this.contact$.pipe(
      map(it => {
        return {
          contact: it,
        };
      }),
    );
  }

  loadContact(id: ContactId) {
    this.contactIdSubject.next(id);
  }
}

const defaultTab = routes_config.CONTACT_DETAIL_GENERAL.path;

@Component({
  selector: 'app-contact-detail-layout',
  standalone: true,
  imports: [AnimalStateSwitchesComponent, AvatarImageComponent, NavTabsComponent, RouterOutlet, FullNamePipe, AsyncPipe, MatChip],
  templateUrl: './contact-detail-layout.component.html',
  styleUrl: './contact-detail-layout.component.scss',
  providers: [ContactDetailStore],
})
export class ContactDetailLayoutComponent {
  contact$: Observable<ContactDto>;
  selectedTab!: WritableSignal<string>;
  readonly appRoutes = routes_config;
  tabs: Tab[] = [
    {
      label: 'PAGE.CONTACT.DETAIL.TABS.GENERAL',
      value: 'general',
      roleRestriction: { exclude: [UserRole.Veterinarian] },
    },
    {
      label: 'PAGE.CONTACT.DETAIL.TABS.CASES',
      value: this.appRoutes.CONTACT_DETAIL_CASES.path,
      roleRestriction: { exclude: [UserRole.Veterinarian] },
    },
    { label: 'PAGE.CONTACT.DETAIL.TABS.ANIMALS', value: this.appRoutes.CONTACT_DETAIL_ANIMALS.path },
    {
      label: 'PAGE.CONTACT.DETAIL.TABS.LOG',
      value: this.appRoutes.CONTACT_DETAIL_LOG.path,
      roleRestriction: { exclude: [UserRole.Veterinarian, UserRole.Guest] },
    },
  ];

  constructor(
    private activeRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private store: ContactDetailStore,
    private router: Router,
  ) {
    this.contact$ = store.contact$;

    this.setInitialTabBasedOnRoute();

    this.listenForContactRouteChanges();

    this.listenForTabChanges();

    this.updateBreadcrumbFromContact();

    // Update the route when the selected tab changes
    this.updateRouteOnTabChange();
  }

  private setInitialTabBasedOnRoute() {
    const selectedTab = this.activeRoute.snapshot.firstChild?.url[0]?.path ?? defaultTab;
    this.selectedTab = signal(selectedTab);
  }

  private listenForContactRouteChanges() {
    this.activeRoute.params.pipe(takeUntilDestroyed()).subscribe(params => {
      this.store.loadContact(params['id']);
    });
  }

  private listenForTabChanges() {
    this.router.events.pipe(takeUntilDestroyed()).subscribe(event => {
      if (event instanceof NavigationEnd) {
        const tab = event.urlAfterRedirects.split('/').pop() ?? defaultTab;

        if (!this.tabs.map(t => t.value).includes(tab)) {
          return;
        }

        this.selectedTab.set(tab ?? defaultTab);
      }
    });
  }

  private updateBreadcrumbFromContact() {
    this.contact$.pipe(takeUntilDestroyed()).subscribe(contact => {
      this.breadcrumbService.set('@contactName', this.contactBreadcrumb(contact));
    });
  }

  private updateRouteOnTabChange() {
    effect(async () => {
      const tab = this.selectedTab();
      await this.router.navigate(['.', tab], { relativeTo: this.activeRoute });
    });
  }

  private contactBreadcrumb(contact: ContactDto): string {
    if (contact.firstName === null && contact.lastName === null) {
      return 'Kontakt';
    }
    return [contact.firstName, contact.lastName].filter(it => it !== null).join(' ');
  }
}
