import { Routes } from '@angular/router';
import { AnimalCasesComponent } from '@animal/pages/animal-detail/animal-cases/animal-cases.component';
import { AnimalContactsComponent } from '@animal/pages/animal-detail/animal-contacts/animal-contacts.component';
import { AnimalDetailLayoutComponent } from '@animal/pages/animal-detail/animal-detail-layout/animal-detail-layout.component';
import { AnimalFilesComponent } from '@animal/pages/animal-detail/animal-files/animal-files.component';
import { AnimalGeneralComponent } from '@animal/pages/animal-detail/animal-general/animal-general.component';
import { AnimalLogComponent } from '@animal/pages/animal-detail/animal-log/animal-log.component';
import { AnimalPhotosComponent } from '@animal/pages/animal-detail/animal-photos/animal-photos.component';
import { AnimalPlacementComponent } from '@animal/pages/animal-detail/animal-placement/animal-placement.component';
import { AnimalServicesComponent } from '@animal/pages/animal-detail/animal-services/animal-services.component';
import { AnimalTasksComponent } from '@animal/pages/animal-detail/animal-tasks/animal-tasks.component';
import { AnimalsPageComponent } from '@animal/pages/animals-page/animals-page.component';
import { ExitCheckPageComponent } from '@animal/pages/exit-check-page/exit-check-page.component';
import { AuditPageComponent } from '@audit/pages/audit-page/audit-page.component';
import { BaseDataAnimalPageComponent } from '@baseData/pages/base-data-animal-page/base-data-animal-page.component';
import { BaseDataBoxPageComponent } from '@baseData/pages/base-data-box-page/base-data-box-page.component';
import { BaseDataCasePageComponent } from '@baseData/pages/base-data-case-page/base-data-case-page.component';
import { BaseDataLayoutComponent } from '@baseData/pages/base-data-layout/base-data-layout.component';
import { BaseDataPositionsPageComponent } from '@baseData/pages/base-data-positions-page/base-data-positions-page.component';
import { BaseDataTasksPageComponent } from '@baseData/pages/base-data-tasks-page/base-data-tasks-page.component';
import { BaseDataVatTaxPageComponent } from '@baseData/pages/base-data-vat-tax-page/base-data-vat-tax-page.component';
import { AnimalCarePageComponent } from '@care/components/animal-care-page/animal-care-page.component';
import { CareTasksPageComponent } from '@care/pages/care-tasks-page/care-tasks-page.component';
import { CaseTasksComponent } from '@case/components/case-tasks/case-tasks.component';
import { CaseAnimalsComponent } from '@case/pages/case-detail/case-animals/case-animals.component';
import { CaseBillingItemsComponent } from '@case/pages/case-detail/case-billing-items/case-billing-items.component';
import { CaseBillsComponent } from '@case/pages/case-detail/case-bills/case-bills.component';
import { CaseContactsComponent } from '@case/pages/case-detail/case-contacts/case-contacts.component';
import { CaseFilesComponent } from '@case/pages/case-detail/case-files/case-files.component';
import { CaseGeneralComponent } from '@case/pages/case-detail/case-general/case-general.component';
import { CaseLayoutComponent } from '@case/pages/case-detail/case-layout/case-layout.component';
import { CaseLogComponent } from '@case/pages/case-detail/case-log/case-log.component';
import { CasePlacementComponent } from '@case/pages/case-detail/case-placement/case-placement.component';
import { CasesPageComponent } from '@case/pages/cases-page/cases-page.component';
import { CreateBillPageComponent } from '@case/pages/create-bill-page/create-bill-page.component';
import { EditEntryCheckPageComponent } from '@case/pages/edit-entry-check-page/edit-entry-check-page.component';
import { EntryCheckPageComponent } from '@case/pages/entry-check-page/entry-check-page.component';
import { NewCasePageComponent } from '@case/pages/new-case-page/new-case-page.component';
import { WildAnimalEntryCheckPageComponent } from '@case/pages/wild-animal-entry-check-page/wild-animal-entry-check-page.component';
import { ContactAnimalsComponent } from '@contact/pages/contact-detail/contact-animals/contact-animals.component';
import { ContactCasesComponent } from '@contact/pages/contact-detail/contact-cases/contact-cases.component';
import { ContactDetailLayoutComponent } from '@contact/pages/contact-detail/contact-detail-layout/contact-detail-layout.component';
import { ContactGeneralComponent } from '@contact/pages/contact-detail/contact-general/contact-general.component';
import { ContactLogComponent } from '@contact/pages/contact-detail/contact-log/contact-log.component';
import { ContactsPageComponent } from '@contact/pages/contacts-page/contacts-page.component';
import { isAuthenticated } from '@core/auth/guard/is-authenticated';
import { isNotDeactivated } from '@core/auth/guard/is-not-deactivated';
import { isNotAuthenticated } from '@core/auth/guard/is-not.authenticated';
import { roleRestriction } from '@core/auth/guard/role-restriction';
import { PageLayoutComponent } from '@core/components/page-layout/page-layout.component';
import { routes_config } from '@core/constants';
import { UserRole } from '@core/models/general';
import { RoleCombination } from '@core/models/role';
import { NotFoundPageComponent } from '@core/pages/not-found-page/not-found-page.component';
import { SearchPageComponent } from '@core/pages/search-page/search-page.component';
import { ProspectDetailPageComponent } from '@prospect/pages/prospect-detail-page/prospect-detail-page.component';
import { AdminUsersPageComponent } from '@user/pages/admin-users-page/admin-users-page.component';
import { UserProfilePageComponent } from '@user/pages/user-profile-page/user-profile-page.component';

import { ChooseTeamPageComponent } from './auth/pages/choose-team-page/choose-team-page.component';
import { DeactivatedPageComponent } from './auth/pages/deactivated-page/deactivated-page.component';
import { LoginPageComponent } from './auth/pages/login-page/login-page.component';
import { SetPasswordPageComponent } from './auth/pages/set-password-page/set-password-page.component';
import { DashboardPageComponent } from './dashboard/pages/dashboard-page/dashboard-page.component';
import { NotificationPageComponent } from './notification/notification-page/notification-page.component';
import { AnimalShelterSettingsPageComponent } from './settings/pages/animal-shelter-settings-page/animal-shelter-settings-page.component';
import { ArchivePageComponent } from './settings/pages/archive-page/archive-page.component';
import { StatisticsPageComponent } from './statistics/pages/statistics-page/statistics-page.component';

export const routes: Routes = [
  {
    path: routes_config.LOGIN.path,
    pathMatch: 'full',
    component: LoginPageComponent,
    canActivate: [isNotAuthenticated()],
  },
  {
    path: routes_config.SET_PASSWORD.path,
    pathMatch: 'full',
    component: SetPasswordPageComponent,
    canActivate: [],
  },
  {
    path: routes_config.DEACTIVATED_PAGE.path,
    pathMatch: 'full',
    component: DeactivatedPageComponent,
    canActivate: [isAuthenticated()],
  },
  {
    path: routes_config.CHOOSE_TEAM_AND_STATION.path,
    component: ChooseTeamPageComponent,
    pathMatch: 'full',
    canActivate: [isAuthenticated()],
  },
  {
    path: routes_config.ROOT.path,
    component: PageLayoutComponent,
    canActivate: [isAuthenticated(), isNotDeactivated()],
    children: getAppRoutes(),
  },
];

function getAppRoutes(): Routes {
  return [
    {
      path: routes_config.ROOT.path,
      pathMatch: 'full',
      redirectTo: routes_config.DASHBOARD.path,
    },
    {
      path: routes_config.DASHBOARD.path,
      component: DashboardPageComponent,
      data: {
        breadcrumb: {
          label: 'BREADCRUMB.DASHBOARD',
        },
      },
    },
    {
      path: routes_config.INBOX.path,
      component: NotificationPageComponent,
      data: {
        breadcrumb: {
          label: 'BREADCRUMB.INBOX',
        },
      },
    },
    {
      path: routes_config.DASHBOARD.path,
      component: DashboardPageComponent,
      data: {
        breadcrumb: {
          label: 'BREADCRUMB.DASHBOARD',
        },
      },
    },
    {
      path: routes_config.SEARCH.path,
      component: SearchPageComponent,
      data: {
        breadcrumb: {
          label: 'BREADCRUMB.SEARCH',
        },
      },
    },
    {
      path: routes_config.ANIMALS.path,
      data: {
        breadcrumb: {
          label: 'BREADCRUMB.ANIMALS',
        },
      },
      children: [
        {
          path: '',
          component: AnimalsPageComponent,
          pathMatch: 'full',
        },
        {
          path: routes_config.CASE.path,
          data: {
            breadcrumb: {
              label: 'BREADCRUMB.NEW_CASE',
            },
          },
          children: [
            {
              path: '',
              pathMatch: 'full',
              component: NewCasePageComponent,
            },
            {
              path: routes_config.CASE_NEW_EXISTING.path,
              component: NewCasePageComponent,
              data: {
                breadcrumb: {
                  skip: true,
                },
              },
            },
            {
              path: routes_config.ENTRYCHECK.path,
              component: EntryCheckPageComponent,
              data: {
                breadcrumb: {
                  skip: true,
                },
              },
            },
            {
              path: routes_config.WILD_ANIMAL_ENTRYCHECK.path,
              data: {
                breadcrumb: {
                  skip: true,
                },
              },
              children: [
                {
                  path: '',
                  pathMatch: 'full',
                  component: WildAnimalEntryCheckPageComponent,
                },
              ],
            },
          ],
        },
        {
          path: routes_config.ANIMAL_SPECIFIC.path,
          data: {
            breadcrumb: {
              alias: 'animalName',
            },
          },
          children: [
            {
              path: '',
              redirectTo: routes_config.ANIMAL_DETAIL.path,
              pathMatch: 'full',
            },
            {
              path: routes_config.ANIMAL_EXITCHECK.path,
              component: ExitCheckPageComponent,
              data: {
                breadcrumb: {
                  label: 'BREADCRUMB.EXIT',
                },
              },
            },
            {
              path: routes_config.WILD_ENTRYCHECK_EDIT.path,
              component: WildAnimalEntryCheckPageComponent,
              data: {
                edit: true,
                breadcrumb: {
                  label: 'BREADCRUMB.ENTRYCHECK_EDIT',
                },
              },
            },
            {
              path: routes_config.DOMESTIC_ENTRYCHECK_EDIT.path,
              component: EditEntryCheckPageComponent,
              data: {
                breadcrumb: {
                  label: 'BREADCRUMB.ENTRYCHECK_EDIT',
                },
              },
            },
            {
              path: routes_config.ANIMAL_DETAIL.path,
              component: AnimalDetailLayoutComponent,
              data: {
                breadcrumb: {
                  skip: true,
                },
              },
              children: [
                {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: routes_config.ANIMAL_DETAIL_GENERAL.path,
                },
                {
                  path: routes_config.ANIMAL_DETAIL_GENERAL.path,
                  component: AnimalGeneralComponent,
                  data: {
                    breadcrumb: {
                      skip: true,
                    },
                  },
                },
                {
                  path: routes_config.ANIMAL_DETAIL_TASKS.path,
                  component: AnimalTasksComponent,
                  data: {
                    breadcrumb: {
                      label: 'Aufgaben',
                    },
                  },
                },
                {
                  path: routes_config.ANIMAL_DETAIL_CASES.path,
                  component: AnimalCasesComponent,
                  data: {
                    breadcrumb: {
                      label: 'Fälle',
                    },
                  },
                },
                {
                  path: routes_config.ANIMAL_DETAIL_PLACEMENT.path,
                  component: AnimalPlacementComponent,
                  data: {
                    breadcrumb: {
                      label: 'Platzierung',
                    },
                  },
                },
                {
                  path: routes_config.ANIMAL_DETAIL_SERVICES.path,
                  component: AnimalServicesComponent,
                  data: {
                    breadcrumb: {
                      label: 'Leistungen',
                    },
                  },
                },
                {
                  path: routes_config.ANIMAL_DETAIL_CONTACTS.path,
                  component: AnimalContactsComponent,
                  data: {
                    breadcrumb: {
                      label: 'Kontakte',
                    },
                  },
                },
                {
                  path: routes_config.ANIMAL_DETAIL_PHOTOS.path,
                  component: AnimalPhotosComponent,
                  data: {
                    breadcrumb: {
                      label: 'Fotos',
                    },
                  },
                },
                {
                  path: routes_config.ANIMAL_DETAIL_FILES.path,
                  component: AnimalFilesComponent,
                  data: {
                    breadcrumb: {
                      label: 'Dateien',
                    },
                  },
                },
                {
                  path: routes_config.ANIMAL_DETAIL_LOG.path,
                  component: AnimalLogComponent,
                  data: {
                    breadcrumb: {
                      label: 'Log',
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: routes_config.CASES.path,
      canActivate: [roleRestriction({ exclude: [UserRole.Veterinarian] })],
      data: {
        breadcrumb: {
          label: 'BREADCRUMB.CASES',
        },
      },
      children: [
        {
          path: '',
          pathMatch: 'full',
          component: CasesPageComponent,
        },
        {
          path: routes_config.CASE_SPECIFIC.path,
          data: {
            breadcrumb: {
              alias: 'caseName',
            },
          },
          children: [
            {
              path: '',
              pathMatch: 'full',
              redirectTo: routes_config.CASE_DETAIL.path,
            },
            {
              path: routes_config.CASE_CREATE_BILL.path,
              component: CreateBillPageComponent,
              data: {
                breadcrumb: {
                  label: 'BREADCRUMB.CREATE_BILL',
                },
              },
            },
            {
              path: routes_config.CASE_EDIT_BILL.path,
              component: CreateBillPageComponent,
              data: {
                refreshComponent: true,
                edit: true,
                breadcrumb: {
                  alias: 'billingNumber',
                },
              },
            },
            {
              path: routes_config.CASE_DETAIL.path,
              component: CaseLayoutComponent,
              data: {
                breadcrumb: {
                  skip: true,
                },
              },
              children: [
                {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: routes_config.CASE_DETAIL_GENERAL.path,
                },
                {
                  path: routes_config.CASE_DETAIL_GENERAL.path,
                  component: CaseGeneralComponent,
                  data: {
                    breadcrumb: {
                      label: 'BREADCRUMB.CASE_DETAIL.GENERAL',
                    },
                  },
                },
                {
                  path: routes_config.CASE_DETAIL_TASKS.path,
                  component: CaseTasksComponent,
                  data: {
                    breadcrumb: {
                      label: 'BREADCRUMB.CASE_DETAIL.TASKS',
                    },
                  },
                },
                {
                  path: routes_config.CASE_DETAIL_ANIMALS.path,
                  component: CaseAnimalsComponent,
                  data: {
                    breadcrumb: {
                      label: 'BREADCRUMB.CASE_DETAIL.ANIMALS',
                    },
                  },
                },
                {
                  path: routes_config.CASE_DETAIL_PLACEMENT.path,
                  data: {
                    breadcrumb: {
                      label: 'BREADCRUMB.CASE_DETAIL.PROSPECTS',
                    },
                  },
                  children: [
                    {
                      path: '',
                      component: CasePlacementComponent,
                      pathMatch: 'full',
                    },
                    {
                      path: routes_config.CASE_DETAIL_PROSPECT_DETAILS.path,
                      component: ProspectDetailPageComponent,
                      data: {
                        breadcrumb: {
                          alias: 'prospectName',
                        },
                      },
                    },
                  ],
                },
                {
                  path: routes_config.CASE_DETAIL_CONTACTS.path,
                  component: CaseContactsComponent,
                  data: {
                    breadcrumb: {
                      label: 'BREADCRUMB.CASE_DETAIL.CONTACTS',
                    },
                  },
                },
                {
                  path: routes_config.CASE_DETAIL_BILLING_ITEMS.path,
                  component: CaseBillingItemsComponent,
                  data: {
                    breadcrumb: {
                      label: 'BREADCRUMB.CASE_DETAIL.BILLING_ITEMS',
                    },
                  },
                },
                {
                  path: routes_config.CASE_DETAIL_BILLS.path,
                  component: CaseBillsComponent,
                  data: {
                    breadcrumb: {
                      label: 'BREADCRUMB.CASE_DETAIL.BILLS',
                    },
                  },
                },
                {
                  path: routes_config.CASE_DETAIL_FILES.path,
                  component: CaseFilesComponent,
                  data: {
                    breadcrumb: {
                      label: 'BREADCRUMB.CASE_DETAIL.FILES',
                    },
                  },
                },
                {
                  path: routes_config.CASE_DETAIL_LOG.path,
                  component: CaseLogComponent,
                  data: {
                    breadcrumb: {
                      label: 'BREADCRUMB.CASE_DETAIL.LOG',
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },

    {
      path: routes_config.CONTACTS.path,
      canActivate: [roleRestriction({ exclude: [UserRole.Veterinarian] })],
      data: {
        breadcrumb: {
          label: 'BREADCRUMB.CONTACTS',
        },
      },
      children: [
        {
          path: '',
          pathMatch: 'full',
          component: ContactsPageComponent,
          data: {
            breadcrumb: {
              label: 'BREADCRUMB.CONTACTS',
            },
          },
        },
        {
          path: routes_config.CONTACT_DETAIL.path,
          component: ContactDetailLayoutComponent,
          data: {
            breadcrumb: {
              alias: 'contactName',
            },
          },
          children: [
            {
              path: '',
              pathMatch: 'full',
              redirectTo: routes_config.CONTACT_DETAIL_GENERAL.path,
            },
            {
              path: routes_config.CONTACT_DETAIL_GENERAL.path,
              component: ContactGeneralComponent,
              data: {
                breadcrumb: {
                  skip: true,
                },
              },
            },
            {
              path: routes_config.CONTACT_DETAIL_CASES.path,
              component: ContactCasesComponent,
              data: {
                breadcrumb: {
                  label: 'Fälle',
                },
              },
            },
            {
              path: routes_config.CONTACT_DETAIL_ANIMALS.path,
              component: ContactAnimalsComponent,
              data: {
                breadcrumb: {
                  label: 'Tiere',
                },
              },
            },
            {
              path: routes_config.CONTACT_DETAIL_LOG.path,
              component: ContactLogComponent,
              data: {
                breadcrumb: {
                  label: 'Log',
                },
              },
            },
          ],
        },
      ],
    },
    {
      path: routes_config.STATISTICS.path,
      component: StatisticsPageComponent,
      data: {
        breadcrumb: {
          label: 'BREADCRUMB.STATISTICS',
        },
      },
    },
    {
      path: routes_config.SETTINGS.path,
      data: {
        breadcrumb: {
          label: 'BREADCRUMB.SETTINGS',
        },
      },
      children: [
        {
          path: routes_config.SETTINGS_ANIMAL_SHELTER.path,
          component: AnimalShelterSettingsPageComponent,

          canActivate: [
            roleRestriction({ restrictTo: [UserRole.Admin, UserRole.Office, RoleCombination(UserRole.Office, UserRole.Superuser)] }),
          ],
          data: {
            requiredRole: [UserRole.Admin, RoleCombination(UserRole.Superuser)],
            breadcrumb: {
              label: 'BREADCRUMB.SETTINGS_USERS',
            },
          },
        },

        {
          path: routes_config.SETTINGS_USERS.path,
          component: AdminUsersPageComponent,
          canActivate: [roleRestriction({ restrictTo: [UserRole.Admin] })],
          data: {
            breadcrumb: {
              label: 'BREADCRUMB.SETTINGS_USERS',
            },
          },
        },
        {
          path: routes_config.SETTINGS_BASE_DATA.path,
          component: BaseDataLayoutComponent,
          data: {
            breadcrumb: {
              label: 'BREADCRUMB.SETTINGS_BASE_DATA.ROOT',
            },
          },
          children: [
            {
              path: '',
              pathMatch: 'full',
              redirectTo: routes_config.SETTINGS_BASE_DATA_ANIMAL.path,
            },
            {
              path: routes_config.SETTINGS_BASE_DATA_ANIMAL.path,
              component: BaseDataAnimalPageComponent,
              data: {
                breadcrumb: {
                  label: 'BREADCRUMB.SETTINGS_BASE_DATA.ANIMAL',
                },
              },
            },
            {
              path: routes_config.SETTINGS_BASE_DATA_CASE.path,
              component: BaseDataCasePageComponent,
              data: {
                breadcrumb: {
                  label: 'BREADCRUMB.SETTINGS_BASE_DATA.CASE',
                },
              },
            },
            {
              path: routes_config.SETTINGS_BASE_DATA_TASKS.path,
              component: BaseDataTasksPageComponent,
              data: {
                breadcrumb: {
                  label: 'BREADCRUMB.SETTINGS_BASE_DATA.TASKS',
                },
              },
            },
            {
              path: routes_config.SETTINGS_BASE_DATA_BOXES.path,
              component: BaseDataBoxPageComponent,
              data: {
                breadcrumb: {
                  label: 'BREADCRUMB.SETTINGS_BASE_DATA.BOXES',
                },
              },
            },
            {
              path: routes_config.SETTINGS_BASE_DATA_VAT_TAX.path,
              component: BaseDataVatTaxPageComponent,
              data: {
                breadcrumb: {
                  label: 'BREADCRUMB.SETTINGS_BASE_DATA.VAT_TAX',
                },
              },
            },
            {
              path: routes_config.SETTINGS_BASE_DATA_POSITIONS.path,
              component: BaseDataPositionsPageComponent,
              data: {
                breadcrumb: {
                  label: 'BREADCRUMB.SETTINGS_BASE_DATA.POSITIONS',
                },
              },
            },
          ],
        },
        {
          path: routes_config.SETTINGS_PROFILE.path,
          component: UserProfilePageComponent,
          data: {
            breadcrumb: {
              label: 'BREADCRUMB.SETTINGS_PROFILE',
            },
          },
        },
        {
          path: routes_config.SETTINGS_AUDIT_LOG.path,
          component: AuditPageComponent,
          canActivate: [roleRestriction({ restrictTo: [UserRole.Admin, UserRole.Superuser] })],
          data: {
            breadcrumb: {
              label: 'BREADCRUMB.SETTINGS_AUDIT_LOG',
            },
          },
        },
        {
          path: routes_config.SETTINGS_ARCHIVE.path,
          component: ArchivePageComponent,
          canActivate: [roleRestriction({ exclude: [UserRole.Veterinarian] })],
          data: {
            breadcrumb: {
              label: 'BREADCRUMB.SETTINGS_ARCHIVE',
            },
          },
        },
      ],
    },

    {
      path: routes_config.CARE.path,
      children: [
        {
          path: '',
          pathMatch: 'full',
          component: CareTasksPageComponent,
        },
        {
          path: routes_config.CARE_ANIMAL.path,
          component: AnimalCarePageComponent,
          data: {
            breadcrumb: {
              alias: 'animalName',
            },
          },
        },
      ],
      data: {
        breadcrumb: {
          label: 'BREADCRUMB.CARE',
        },
      },
    },

    {
      path: '**',
      pathMatch: 'full',
      component: NotFoundPageComponent,
      data: {
        breadcrumb: { skip: true },
      },
    },
  ];
}
