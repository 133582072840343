<h1 mat-dialog-title>
  {{ 'PAGE.CASE.DETAIL.ADD_CASE_ANIMAL_FORM.TITLE' | translate }}
</h1>

<div class="mt-large" mat-dialog-content>
  @if (form) {
    <div class="add-form">
      <tgn-radio-group [choices]="addChoices" [control]="form.controls.type" class="radio"></tgn-radio-group>

      @if (form.controls.type.value === addCaseAnimalTypes.useExisting) {
        <tgn-form-element [control]="form.controls.animal" [label]="'GENERAL.DOMAIN.ANIMAL' | translate" id="animalId">
          <ng-template formElement let-control="control" let-id="id">
            <tgn-autocomplete
              (onOptionSelected)="setExistingAnimal($event)"
              (onRemoveSelected)="removeSelectedAnimal()"
              [displayFn]="displayAnimalAutocomplete"
              [id]="id"
              [locked]="form.controls.animal.value !== null"
              [valueFn]="getAnimalValueFn"
              [value]="displayAnimalAutocomplete(form.controls.animal.value)"
              [viewStore]="animalViewStore"
              [placeholder]="'PAGE.CASE.DETAIL.ADD_CASE_ANIMAL_FORM.SEARCH_PLACEHOLDER' | translate"
            ></tgn-autocomplete>
          </ng-template>
        </tgn-form-element>
      } @else {
        <tgn-form-element [hideError]="true" [control]="form.controls.name" [label]="'ENTITY.Animal.name' | translate" id="name">
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input matInput [formControl]="form.controls.name" placeholder="Name" type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      }
    </div>
  }
</div>

<div mat-dialog-actions>
  @if (form) {
    <button mat-button mat-dialog-close>
      {{ 'GENERAL.BUTTON.CANCEL' | translate }}
    </button>
    <button
      (click)="close()"
      [disabled]="form.controls.type.value === addCaseAnimalTypes.create && !form.controls.name.value"
      class="tgn-button"
      mat-flat-button
    >
      {{ 'GENERAL.BUTTON.NEXT' | translate }}
    </button>
  }
</div>
