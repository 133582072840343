<div class="login-card">
  <h1>{{ 'PAGE.LOGIN.TITLE' | translate }}</h1>

  <form (submit)="loginWithPassword()" [formGroup]="form" class="form-grid grid-cols-1">
    <div class="row">
      <tgn-form-element [control]="form.controls.email" [hideError]="true" [label]="'PAGE.LOGIN.EMAIL' | translate" id="email">
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input [formControl]="control" [id]="id" matInput placeholder="name@tierrettungsdienst.ch" type="text" />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>

    @if (mode === PageMode.Login) {
      <div class="row">
        <tgn-form-element
          [control]="form.controls.password"
          [hideError]="true"
          [id]="'password'"
          [label]="'PAGE.LOGIN.PASSWORD' | translate"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input matInput [formControl]="control" [id]="id" type="password" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    }

    @if (mode === PageMode.Login) {
      <div class="row">
        <button [disabled]="form.invalid" class="tgn-button block item" mat-flat-button type="submit">
          {{ 'PAGE.LOGIN.ACTIONS.LOGIN' | translate }}
        </button>
      </div>
    } @else {
      <div class="row">
        <button
          (click)="sendResetPasswordEmail()"
          [disabled]="form.controls.email.invalid"
          class="tgn-button block"
          mat-flat-button
          type="button"
        >
          {{ 'PAGE.LOGIN.ACTIONS.RESET_PASSWORD' | translate }}
        </button>
      </div>
    }

    <div class="reset-button-container row">
      @if (mode === PageMode.Login) {
        <button (click)="showResetPassword()" mat-flat-button>{{ 'PAGE.LOGIN.FORGOT_PASSWORD' | translate }}</button>
      } @else {
        <button (click)="switchToLoginMode()" class="tgn-icon-button" mat-flat-button>
          {{ 'GENERAL.BUTTON.PREVIOUS' | translate }}
        </button>
      }
    </div>
  </form>

  @if (environment.showMicrosoftProviderLogin && PageMode.Login) {
    <div class="row mt-medium">
      <span class="provider-header">{{ 'PAGE.LOGIN.LOGIN_WITH' | translate }}</span>
    </div>
    <div class="row">
      <button (click)="loginWithMicrosoft()" class="block mt-medium" mat-stroked-button>
        {{ 'PAGE.LOGIN.PROVIDERS.MICROSOFT' | translate }}
      </button>
    </div>
  }

  @if (environment.showGoogleProviderLogin && PageMode.Login) {
    <div class="row">
      <button (click)="loginWithGoogle()" class="block mt-medium" mat-stroked-button>
        {{ 'PAGE.LOGIN.PROVIDERS.GOOGLE' | translate }}
      </button>
    </div>
  }
</div>
