import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { WildAnimalStatisticsFormComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics-form/wild-animal-statistics-form.component';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { WildAnimalStatisticsDto } from '@case/dtos/create-case.dto';
import { UpdateWildAnimalStatisticsDto } from '@case/dtos/update-wild-animal-statistics.dto';
import { WildAnimalStatisticsControls } from '@case/models/new-case-form';
import { CaseService } from '@case/services/case.service';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { NumberInputComponent } from '@core/components/number-input/number-input.component';
import { GENERAL_WRITE_EXCLUDE } from '@core/directives/role-restriction.directive';
import { AccessService } from '@user/service/access.service';

@Component({
  selector: 'tgn-wild-animal-statistics',
  standalone: true,
  imports: [FormElementComponent, ReactiveFormsModule, NumberInputComponent, FormElementDirective, WildAnimalStatisticsFormComponent],
  templateUrl: './wild-animal-statistics.component.html',
  styleUrl: './wild-animal-statistics.component.scss',
})
export class WildAnimalStatisticsComponent implements OnInit {
  wildAnimalStatisticsForm?: FormGroup<WildAnimalStatisticsControls>;
  @Input({ required: true }) caseDto!: CaseDetailDto;
  @Input() hideNumAnimals = false;
  @Input() variantDarker = false;
  @Output() onInitialized: EventEmitter<boolean> = new EventEmitter();
  @Output() onStatisticsUpdate = new EventEmitter<WildAnimalStatisticsDto | null>();
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private caseService: CaseService,
    private formBuilder: FormBuilder,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.buildWildAnimalStatisticsForm(this.caseDto);

    if (this.caseDto.archived) {
      this.wildAnimalStatisticsForm!.disable();
    }
    this.accessService.disableBasedOnRoleRestriction(this.wildAnimalStatisticsForm!, GENERAL_WRITE_EXCLUDE);
  }

  private buildWildAnimalStatisticsForm(caseDto: CaseDetailDto) {
    const fb = this.formBuilder;
    const statistics = caseDto.wildAnimalStatistics!;
    this.wildAnimalStatisticsForm = fb.group<WildAnimalStatisticsControls>({
      numAnimals: fb.nonNullable.control<number>(statistics.numAnimals, [Validators.min(0)]),
      numCareStation: fb.nonNullable.control<number>(statistics.numCareStation, [Validators.min(0)]),
      numVeterinarian: fb.nonNullable.control<number>(statistics.numVeterinarian, [Validators.min(0)]),
      numReleased: fb.nonNullable.control<number>(statistics.numReleased, [Validators.min(0)]),
      numDead: fb.nonNullable.control<number>(statistics.numDead, [Validators.min(0)]),
    });
    this.autoUpdateWildAnimalStatistics(caseDto);
    this.onInitialized.next(true);
  }

  private autoUpdateWildAnimalStatistics(caseDto: CaseDetailDto) {
    this.wildAnimalStatisticsForm?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const dto: UpdateWildAnimalStatisticsDto = this.wildAnimalStatisticsForm!.getRawValue();
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.caseService.updateWildAnimalStatistics(caseDto.id, dto).subscribe(value => {
        this.onStatisticsUpdate.emit(value.wildAnimalStatistics);
      });
    });
  }
}
