import { CommonModule } from '@angular/common';
import { Component, Inject, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { BoxDto, StationDto } from '@case/dtos/base-data.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseDto } from '@case/dtos/case.dto';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ViewStore, ViewStoreQuery, toLoadable } from '@core/components/autocomplete/model/loadable';
import { ButtonUploadComponent } from '@core/components/button-upload/button-upload.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { EditorComponent } from '@core/components/editor/editor.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { FileListPreviewComponent } from '@core/components/input-upload/file-list-preview/file-list-preview.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { TimeInputComponent } from '@core/components/time-input/time-input.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { ModalComponent } from '@core/services/modal.service';
import { SearchResult, SearchResultEntity, SearchService, taskEntityTypeToEntityType } from '@core/services/search.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { TigonValidators } from '@core/utils/validators';
import { FileCollectionDto } from '@file/dto/file-collection.dto';
import { FileService, UploadEntity, UploadEntityType } from '@file/service/file.service';
import { TranslateModule } from '@ngx-translate/core';
import { ReplaySubject, map, of } from 'rxjs';

import {
  CreateTaskDto,
  RepetitionUnit,
  TaskCategoryDto,
  TaskCategoryGroup,
  TaskEntityType,
  TaskRepetition,
  TaskStatus,
} from '../../dtos/task.dto';
import { RepetitionDisplayPipe } from '../../pipes/repetition-display.pipe';
import { TaskService } from '../../services/task.service';
import { RepetitionPickerComponent } from '../repetition-picker/repetition-picker.component';
import { TaskFormComponent } from '../task-form/task-form.component';
import { TaskSettingComponent } from '../task-setting/task-setting.component';

interface CreateTaskDialogResult {}

type CreateTaskDialogData = {
  taskCategoryGroups: TaskCategoryGroup[];
} & (
  | {
      entity: BoxDto;
      entityType: TaskEntityType.Box;
    }
  | {
      entity: AnimalDto;
      entityType: TaskEntityType.Animal;
    }
  | {
      entity: StationDto;
      entityType: TaskEntityType.Station;
    }
  | {
      entity: CaseDto | CaseDetailDto;
      entityType: TaskEntityType.Case;
    }
  | {
      entity: null;
      entityType: null;
    }
);

enum ComponentMode {
  ChooseTaskType = 'ChooseTaskType',
  ChooseEntity = 'ChooseEntity',
  TaskSettings = 'TaskSettings',
}

interface CreateTaskForm {
  title: FormControl<string>;
  description: FormControl<string | null>;
  report: FormControl<string | null>;
  status: FormControl<TaskStatus>;
  taskCategory: FormControl<TaskCategoryDto | null>;
  repetition: FormGroup<{
    interval: FormControl<number | null>;
    unit: FormControl<RepetitionUnit | null>;
  }>;
  terminationDate: FormControl<IsoLocalDateString | null>;
  terminationTime: FormControl<string | null>;
}

interface EntityChoiceForm {
  entityType: FormControl<TaskEntityType | null>;
  entity: FormGroup<{
    entityId: FormControl<string | null>;
    name: FormControl<string | null>;
  }>;
}

@Component({
  selector: 'app-create-task-dialog',
  standalone: true,
  imports: [
    AutocompleteComponent,
    FormElementComponent,
    FormElementDirective,
    MatButtonModule,
    MatDialogActions,
    CommonModule,
    TranslateModule,
    MatDialogModule,
    RadioGroupComponent,
    ReactiveFormsModule,
    MatInputModule,
    EditorComponent,
    IconComponent,
    ButtonUploadComponent,
    FileListPreviewComponent,
    TaskSettingComponent,
    SelectComponent,
    DateInputComponent,
    RepetitionPickerComponent,
    EnumDisplayPipe,
    ToRadioChoicePipe,
    TimeInputComponent,
    RepetitionDisplayPipe,
    FormsModule,
    TaskFormComponent,
    RoleRestrictionDirective,
  ],
  templateUrl: './create-task-dialog.component.html',
  styleUrl: './create-task-dialog.component.scss',
})
export class CreateTaskDialogComponent extends ModalComponent<CreateTaskDialogData, CreateTaskDialogResult> {
  form!: FormGroup<CreateTaskForm>;
  entityForm!: FormGroup<EntityChoiceForm>;
  taskData!: CreateTaskDialogData;
  fileCollection: FileCollectionDto | null = null;
  taskTitleBreadcrumb: string | null = null;

  taskTypeChoices: RadioChoice<TaskEntityType>[] = createEnumChoices(TaskEntityType, 'GENERAL.DOMAIN.TaskEntityType.');

  mode: ComponentMode = ComponentMode.ChooseTaskType;

  query = signal('');

  searchAutocompleteStore: ViewStore<SearchResultEntity[], ViewStoreQuery> = new ViewStore<SearchResultEntity[], ViewStoreQuery>(
    { query: '' },
    value => {
      if (!this.entityForm.controls.entityType.value) {
        return of([]).pipe(toLoadable());
      }
      const entityType = taskEntityTypeToEntityType(this.entityForm.controls.entityType.value);
      return this.searchService.search(value.query, entityType).pipe(
        map((result: SearchResult) => result.entities),
        toLoadable(),
      );
    },
  );

  triggerRefreshFiles: ReplaySubject<void> = new ReplaySubject(1);
  uploadEntity: UploadEntity | null = null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly TaskEntityType = TaskEntityType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly ComponentMode = ComponentMode;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    dialogRef: MatDialogRef<CreateTaskDialogComponent, CreateTaskDialogData>,
    @Inject(MAT_DIALOG_DATA) data: CreateTaskDialogData,
    private taskService: TaskService,
    private formBuilder: FormBuilder,
    private fileService: FileService,
    private searchService: SearchService,
    private snackbar: SnackbarService,
  ) {
    super(dialogRef);

    this.taskData = data;

    if (data.entity) {
      this.mode = ComponentMode.TaskSettings;
      if (data.entityType === TaskEntityType.Animal) {
        this.uploadEntity = {
          id: data.entity.id,
          type: UploadEntityType.AnimalTask,
        };
      }
    }

    this.fileService.createCollection().subscribe(collection => {
      this.fileCollection = collection;
    });

    if (data.entity) {
      const obj: { name?: string | null; title?: string | null } = data.entity;
      this.taskTitleBreadcrumb = obj.name ?? obj.title ?? null;
    }

    this.buildForms();
  }

  displaySearchResultFn(entity: SearchResultEntity): string {
    return entity.name;
  }

  searchResultValueFn(entity: SearchResultEntity): string {
    return entity.id;
  }

  createTask() {
    const formValue = this.form.getRawValue();
    const entityValue = this.entityForm.getRawValue();

    const taskCategory = formValue.taskCategory;
    if (!taskCategory) {
      return;
    }

    let taskRepetition: TaskRepetition | null = null;
    if (formValue.repetition.interval && formValue.repetition.unit) {
      taskRepetition = {
        interval: formValue.repetition.interval,
        unit: formValue.repetition.unit,
      };
    }

    if (!entityValue.entityType) {
      console.error('cannot create task without entityType, formvalues:', formValue);
      this.snackbar.showErrorMessage('PAGE.CARE_TASKS.CREATE_TASK.FEEDBACK.COULD_NOT_CREATE_TASK');
      return;
    }

    const dto: CreateTaskDto = {
      ...formValue,
      entityType: entityValue.entityType,
      entityId: entityValue.entity.entityId,
      taskCategory: taskCategory,
      repetition: taskRepetition,
      fileCollection: this.fileCollection!,
    };
    this.taskService.createTask(dto).subscribe(() => {
      this.closeWithResult({});
    });
  }

  setEntity(entity: SearchResultEntity | null) {
    this.entityForm.controls.entity.setValue({
      entityId: entity?.id ?? null,
      name: entity?.name ?? null,
    });
    if (entity) {
      this.taskTitleBreadcrumb = entity?.name;
    }
  }

  removeEntity() {
    this.entityForm.controls.entity.controls.entityId.setValue(null);
  }

  back() {
    switch (this.mode) {
      case ComponentMode.ChooseEntity:
        this.mode = ComponentMode.ChooseTaskType;
        break;
      case ComponentMode.TaskSettings:
        this.mode = ComponentMode.ChooseEntity;
        break;
      default:
        console.error('Unknown mode', this.mode);
    }
  }

  continue() {
    switch (this.mode) {
      case ComponentMode.ChooseTaskType:
        this.mode = ComponentMode.ChooseEntity;
        break;
      case ComponentMode.ChooseEntity:
        this.mode = ComponentMode.TaskSettings;
        break;
      default:
        console.error('Unknown mode', this.mode);
    }
  }

  private buildForms() {
    const fb = this.formBuilder;

    this.form = fb.group({
      title: fb.nonNullable.control<string>('', [Validators.required]),
      description: fb.control<string | null>(null),
      report: fb.control<string | null>(null),
      status: fb.nonNullable.control<TaskStatus>(TaskStatus.Open),
      taskCategory: fb.control<TaskCategoryDto | null>(null),
      terminationDate: fb.control<string | null>(null),
      terminationTime: fb.control<string | null>(null, [TigonValidators.isValidTime]),
      repetition: fb.group({
        interval: fb.control<number | null>(null),
        unit: fb.control<RepetitionUnit | null>(RepetitionUnit.Daily),
      }),
    });

    this.entityForm = fb.group({
      entityType: fb.control<TaskEntityType | null>(this.taskData.entityType ?? null),
      entity: fb.group({
        name: fb.control<string | null>(getEntityDisplayName(this.taskData.entity)),
        entityId: fb.control<string | null>(this.taskData.entity?.id ?? null),
      }),
    });
  }
}

interface DisplayProperties {
  name?: string | null;
  title?: string | null;
  caseNumber?: string | null;
  id: string;
}

export function getEntityDisplayName(entity: DisplayProperties | null): string {
  if (entity == null) {
    return '';
  }
  const values = [entity.name, entity.title, entity.caseNumber].filter(v => !!v);
  if (values.length === 0) {
    return entity.id;
  }
  return values.join(' - ');
}
