<h1 mat-dialog-title>
  {{ (data.contact ? 'PAGE.CONTACTS.EDIT_CONTACT' : 'PAGE.CONTACTS.NEW_CONTACT') | translate }}
</h1>

<div mat-dialog-content>
  @if (form) {
    <div class="form-grid grid-cols-6">
      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="form.controls.status" [id]="'status'" [label]="'ENTITY.Contact.status' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select [choices]="statusChoices" [control]="form.controls.status" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="form.controls.salutation" [id]="'salutation'" [label]="'ENTITY.Contact.salutation' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <tgn-radio-group
                [choices]="salutationChoices"
                [control]="form.controls.salutation"
                [id]="id"
                class="col-span-4 col-span-sm-1"
              />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.firstName" [id]="'firstName'" [label]="'ENTITY.Contact.firstName' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.Contact.firstName' | translate"
                  cdkFocusInitial
                  matInput
                  type="text"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="form.controls.lastName"
            [hideError]="true"
            [id]="'lastName'"
            [label]="'ENTITY.Contact.lastName' | translate"
            class="col-span-6 row-span-3 mt-sm"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  [formControl]="form.controls.lastName"
                  [placeholder]="'ENTITY.Contact.lastName' | translate"
                  id="lastName"
                  matInput
                  type="text"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="form.controls.company" [id]="'company'" [label]="'ENTITY.Contact.company' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.Contact.companyPlaceholder' | translate"
                  matInput
                  type="text"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.companyAddendum"
            [id]="'companyAddendum'"
            [label]="'ENTITY.Contact.companyAddendum' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.Contact.companyAddendum' | translate"
                  matInput
                  type="text"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="form.controls.kind" [id]="'company'" [label]="'ENTITY.Contact.KIND' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select
                [compareIds]="true"
                [choices]="relationKinds$ | async | addIfMissing: form.controls.kind.value | toRadioChoice: 'name'"
                [control]="control"
                [id]="id"
                [placeholder]="'ENTITY.Contact.KIND' | translate"
              />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row col-span-6 row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.fromDate" [id]="'fromDate'" [label]="'ENTITY.Contact.fromDate' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.toDate" [id]="'toDate'" [label]="'ENTITY.Contact.toDate' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.address"
            [hideError]="true"
            [id]="'address'"
            [label]="'ENTITY.Contact.address' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.Contact.addressPlaceholder' | translate"
                  matInput
                  type="text"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-2 col-span-sm-6">
          <tgn-form-element
            [control]="form.controls.zip"
            [hideError]="true"
            [id]="'zip'"
            [label]="'ENTITY.Contact.zip' | translate"
            label="Postleitzahl"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.zipPlaceholder' | translate" matInput type="text" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-4 col-span-sm-6">
          <tgn-form-element
            [control]="form.controls.city"
            [hideError]="true"
            [id]="'city'"
            [label]="'ENTITY.Contact.city' | translate"
            label="Ort"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.city' | translate" matInput type="text" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.country"
            [hideError]="true"
            [id]="'country'"
            [label]="'ENTITY.Contact.country' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.country' | translate" matInput type="text" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.phone" [id]="'phone'" [label]="'ENTITY.Contact.phone' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.Contact.phonePlaceholder' | translate"
                  matInput
                  type="text"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.email" [id]="'email'" [label]="'ENTITY.Contact.email' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.Contact.emailPlaceholder' | translate"
                  matInput
                  type="text"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="col-span-6">
          <tgn-form-element [control]="form.controls.possibleDonor" id="possibleDonor">
            <ng-template formElement let-control="control" let-id="id">
              <mat-checkbox [formControl]="control" [id]="id">
                {{ 'ENTITY.Contact.possibleDonor' | translate }}
              </mat-checkbox>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="col-span-6">
          <tgn-form-element [control]="form.controls.noMailings" id="noMailings">
            <ng-template formElement let-control="control" let-id="id">
              <mat-checkbox [formControl]="control" [id]="id">
                {{ 'ENTITY.Contact.noMailings' | translate }}
              </mat-checkbox>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="col-span-6">
          <tgn-form-element [control]="form.controls.isDead" id="isDead">
            <ng-template formElement let-control="control" let-id="id">
              <mat-checkbox [formControl]="control" [id]="id">
                {{ 'ENTITY.Contact.isDead' | translate }}
              </mat-checkbox>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    </div>
  }
</div>

<div mat-dialog-actions>
  @if (form) {
    <button mat-button mat-dialog-close>
      {{ 'GENERAL.BUTTON.CANCEL' | translate }}
    </button>
    <button [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" (click)="close()" [disabled]="form.invalid" class="tgn-button" mat-flat-button>
      {{ 'GENERAL.BUTTON.SAVE' | translate }}
    </button>
  }
</div>
