import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatInput } from '@angular/material/input';
import { MatCell, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { VatTaxDto, VatTaxType } from '@baseData/dtos/vat-tax.dto';
import { VatTaxService } from '@baseData/services/vat-tax.service';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { IsoLocalDateString } from '@core/utils/date';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { take } from 'rxjs';

interface VatTaxForm {
  value: FormControl<number | null>;
  validFrom: FormControl<IsoLocalDateString | null>;
}

function validateVatTaxInFuture(vatTaxes: VatTaxDto[]): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors | null {
    const validFrom: IsoLocalDateString | null = control.value;
    if (validFrom === null) {
      return null;
    }

    const isNewValidFromDateLargerThanOthers = vatTaxes.every((vatTax: VatTaxDto) => {
      return validFrom > vatTax.validFrom;
    });

    if (isNewValidFromDateLargerThanOthers) {
      return null;
    }
    return { vatTaxDateNotLargerThanOthers: true };
  };
}

@Component({
  selector: 'tgn-vat-tax-table',
  standalone: true,
  imports: [
    AvatarImageComponent,
    ConfirmationDialogDirective,
    EnumDisplayPipe,
    MatCell,
    MatTableModule,
    RouterLink,
    TypesafeMatTableModule,
    TranslateModule,
    DatePipe,
    MatButton,
    FormElementComponent,
    FormElementDirective,
    ReactiveFormsModule,
    DateInputComponent,
    MatInput,
    MatFormField,
  ],
  templateUrl: './vat-tax-table.component.html',
  styleUrl: './vat-tax-table.component.scss',
})
export class VatTaxTableComponent implements OnInit {
  @Input({ required: true }) dataSource!: TigonDatasource<VatTaxDto, {}>;
  @Input({ required: true }) vatTaxType!: VatTaxType;

  vatTaxForm?: FormGroup<VatTaxForm>;

  data: VatTaxDto[] = [];

  readonly columns = ['value', 'validFrom', 'validTo'];
  protected readonly appRoutes = routes_config;

  constructor(
    private fb: FormBuilder,
    private vatTaxService: VatTaxService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.dataSource
      .getData()
      .pipe(take(1))
      .subscribe(vatTaxes => {
        this.data = vatTaxes;
        this.vatTaxForm = this.fb.group({
          value: this.fb.control<number | null>(null, [Validators.required, Validators.min(0)]),
          validFrom: this.fb.control<IsoLocalDateString | null>(null, [Validators.required, validateVatTaxInFuture(this.data)]),
        });

        this.accessService.disableBasedOnRole(this.vatTaxForm, RestrictedSection.Backoffice);
      });
  }

  addEntry() {
    const values = this.vatTaxForm!.getRawValue();

    if (values.value === null || values.validFrom === null) {
      return;
    }

    const dto = {
      value: values.value,
      validFrom: values.validFrom,
      type: this.vatTaxType,
    };

    this.vatTaxService
      .create(dto)
      .pipe(take(1))
      .subscribe(() => {
        this.dataSource.update({});
        this.vatTaxForm!.patchValue(
          {
            value: null,
            validFrom: null,
          },
          { emitEvent: false },
        );
        this.vatTaxForm!.markAsPristine();
        this.vatTaxForm!.markAsUntouched();
      });
  }
}
