import { Pipe, PipeTransform } from '@angular/core';

import { DEFAULT_ANIMAL_NAME_DISPLAY } from '../dtos/animal.dto';

@Pipe({ standalone: true, name: 'withDefaultAnimalName' })
export class WithDefaultAnimalNamePipe implements PipeTransform {
  transform(name: string | null): string {
    if (name === null || name === '') {
      return DEFAULT_ANIMAL_NAME_DISPLAY;
    }
    return name;
  }
}
