import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'createStmzLink',
  standalone: true,
})
export class CreateStmzLinkPipe implements PipeTransform {
  transform(value: string | null): string {
    if (!value) {
      return '';
    }
    return `https://www.stmz.ch/de/${value}`;
  }
}
