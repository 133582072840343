import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { AnimalRaceInputComponent } from '@animal/components/animal-race-input/animal-race-input.component';
import { WildAnimalStatisticsComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics.component';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { UpdateAnimalDto } from '@animal/dtos/update-animal.dto';
import { AnimalTaxonomy, AnimalTaxonomyBaseData, getNextGroupSubgroupSpecies } from '@animal/model/animal';
import { SpeciesChoicesPipe } from '@animal/pipes/species-choices.pipe';
import { SubGroupChoicesPipe } from '@animal/pipes/sub-group-choices.pipe';
import { AnimalService } from '@animal/services/animal.service';
import { CaseReadOnlyInfoComponent } from '@case/components/case-read-only-info/case-read-only-info.component';
import { BaseDataDto, GroupDto, GroupTag, MainGroupType, SpeciesDto, SubGroupDto } from '@case/dtos/base-data.dto';
import { CompactCaseAnimalDto } from '@case/dtos/case-animal.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseDto } from '@case/dtos/case.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { CaseAnimalService } from '@case/services/case-animal.service';
import { CaseService } from '@case/services/case.service';
import { ContactDto } from '@contact/dto/contact.dto';
import { ContactService } from '@contact/service/contact-service';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ViewStore, ViewStoreQuery, toLoadable } from '@core/components/autocomplete/model/loadable';
import { ChipInputComponent } from '@core/components/chip-input/chip-input.component';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { routes_config } from '@core/constants';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { EXCLUDE_VETERINARIAN, GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AnimalSex, AnimalType, CaseEntryType, FurLength, UnitsLength, UserRole } from '@core/models/general';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { CastPipe } from '@core/pipes/cast.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { SnackbarService } from '@core/services/snackbar.service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices, openDownloadedBlobPdf } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, combineLatest, debounceTime, map, of, skip, switchMap, take } from 'rxjs';

import { AnimalDetailStore } from '../animal-detail-layout/animal-detail-layout.component';

interface AnimalFormGroup {
  name: FormControl<string | null>;
  type: FormControl<AnimalType>;
  chipId: FormControl<string | null>;
  ringId: FormControl<string | null>;
  group: FormControl<GroupDto | null>;
  subGroup: FormControl<SubGroupDto | null>;
  species: FormControl<SpeciesDto | null>;
  race: FormControl<string | null>;
  raceNote: FormControl<string | null>;
  sex: FormControl<AnimalSex | null>;
  coloring: FormControl<string | null>;
  furLength: FormControl<FurLength | null>;
  turtleShellLength: FormControl<string | null>;
  turtleShellLengthUnit: FormControl<UnitsLength | null>;
  vaccinations: FormControl<string | null>;
  birthdate: FormControl<IsoLocalDateString | null>;
  guessedAge: FormControl<string | null>;
  castrated: FormControl<boolean | null>;
  behaviour: FormControl<string | null>;
  specials: FormControl<string | null>;
  feedingHabits: FormControl<string | null>;
  accessories: FormControl<string[]>;
  vet: FormControl<ContactDto | null>;
}

interface LatestCaseFormGroup {
  entryType: FormControl<CaseEntryType>;
}

interface GeneralForm {
  animal: FormGroup<AnimalFormGroup>;
  latestCase: FormGroup<LatestCaseFormGroup>;
}

interface NonEditableForm {
  trdId: FormControl<string | null>;
  entryDate: FormControl<IsoLocalDateString | null>;
}

@Component({
  selector: 'app-animal-general',
  standalone: true,
  imports: [
    CommonModule,
    FormElementComponent,
    FormElementDirective,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    TextFieldModule,
    NarrowPageContainerComponent,
    SelectComponent,
    ToRadioChoicePipe,
    DateInputComponent,
    MatInputModule,
    AutocompleteComponent,
    AnimalRaceInputComponent,
    SpeciesChoicesPipe,
    SubGroupChoicesPipe,
    CastPipe,
    AutocompleteComponent,
    ChipInputComponent,
    CaseReadOnlyInfoComponent,
    CollapsibleComponent,
    MatAnchor,
    MatIcon,
    MatMenuModule,
    MatIconButton,
    ContextActionsDirective,
    MatButton,
    WildAnimalStatisticsComponent,
    AddIfMissingPipe,
    RoleRestrictionDirective,
    ConfirmationDialogDirective,
  ],
  templateUrl: './animal-general.component.html',
  styleUrl: './animal-general.component.scss',
})
export class AnimalGeneralComponent {
  animal$: Observable<AnimalDto>;
  case$: Observable<CaseDto>;
  baseData$: Observable<BaseDataDto>;

  latestCaseData$: Observable<{
    case: CaseDto;
    caseAnimal: CompactCaseAnimalDto;
  }>;

  taxonomyBaseData: AnimalTaxonomyBaseData = {
    groups: [],
    subGroups: [],
    species: [],
  };

  lastTaxonomy: AnimalTaxonomy = {
    group: null,
    subGroup: null,
    species: null,
  };

  form!: GeneralForm;

  nonEditableForm!: NonEditableForm;

  furLengthChoices: RadioChoice<FurLength>[] = createEnumChoices(FurLength, 'GENERAL.DOMAIN.FurLength.');
  lengthUnitChoices: RadioChoice<UnitsLength>[] = createEnumChoices(UnitsLength, 'GENERAL.DOMAIN.UnitsLength.');
  sexChoices: RadioChoice<AnimalSex>[] = createEnumChoices(AnimalSex, 'GENERAL.DOMAIN.AnimalSex.');

  contactViewStore: ViewStore<ContactDto[], ViewStoreQuery> = new ViewStore<ContactDto[], ViewStoreQuery>(
    { query: '' },
    (query: ViewStoreQuery) => {
      return this.contactService.searchContacts(query.query).pipe(toLoadable());
    },
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly GroupTag = GroupTag;

  destroyRef = inject(DestroyRef);
  mainGroupTypeChoices: RadioChoice<MainGroupType>[] = createEnumChoices(MainGroupType, 'GENERAL.DOMAIN.MainGroupType.');
  mainGroupTypeControl!: FormControl<MainGroupType | null>;
  caseDetail$: Observable<CaseDetailDto>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly MainGroupType = MainGroupType;
  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly EXCLUDE_VETERINARIAN = EXCLUDE_VETERINARIAN;

  constructor(
    protected store: AnimalDetailStore,
    fb: FormBuilder,
    private baseDataService: BaseDataService,
    private animalService: AnimalService,
    private contactService: ContactService,
    private caseAnimalService: CaseAnimalService,
    private caseService: CaseService,
    private accessService: AccessService,
    private snackbar: SnackbarService,
  ) {
    this.animal$ = store.animal$;
    this.case$ = store.latestCase$;
    this.caseDetail$ = store.latestCase$.pipe(switchMap(latestCase => this.caseService.getDetail(latestCase.id)));

    this.baseData$ = this.baseDataService.getBaseData();

    this.latestCaseData$ = store.state$.pipe(
      take(1),
      switchMap(({ animal, latestCase }) => {
        return combineLatest([of(latestCase), this.caseAnimalService.getCompactCaseAnimal(latestCase.id, animal.id)]);
      }),
      map(([latestCase, caseAnimal]: [CaseDto, CompactCaseAnimalDto]) => ({ case: latestCase, caseAnimal })),
    );

    // read only, control is just created to display a disabled select
    this.mainGroupTypeControl = fb.control({ value: null, disabled: true });

    store.state$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(({ animal, latestCase }) => {
      this.lastTaxonomy = {
        group: animal.group,
        subGroup: animal.subGroup,
        species: animal.species,
      };

      const mainGroupType: MainGroupType =
        animal.type === AnimalType.DomesticAnimal ? MainGroupType.DomesticAnimal : MainGroupType.WildAnimal;
      this.mainGroupTypeControl.setValue(mainGroupType, { emitEvent: false });

      this.nonEditableForm = {
        trdId: fb.control({ value: animal.trdId, disabled: true }),
        entryDate: fb.control({ value: latestCase.entryDate, disabled: true }),
      };

      this.form = {
        animal: fb.group({
          name: fb.control(animal.name),
          type: fb.nonNullable.control({ value: animal.type, disabled: true }),
          chipId: fb.control(animal.chipId),
          ringId: fb.control(animal.ringId),
          group: fb.control(animal.group ?? null),
          subGroup: fb.control(animal.subGroup ?? null),
          species: fb.control(animal.species ?? null),
          race: fb.control(animal.race),
          raceNote: fb.control(animal.raceNote),
          sex: fb.control(animal.sex),
          birthdate: fb.control(animal.birthdate),
          guessedAge: fb.control(animal.guessedAge),
          coloring: fb.control(animal.coloring),
          furLength: fb.control(animal.furLength),
          turtleShellLength: fb.control(animal.turtleShellLength),
          turtleShellLengthUnit: fb.control(animal.turtleShellLengthUnit),
          vaccinations: fb.control(''),
          castrated: fb.control(animal.castrated),
          behaviour: fb.control(animal.behaviour),
          specials: fb.control(animal.specials),
          feedingHabits: fb.control(animal.feedingHabits),
          accessories: fb.nonNullable.control(animal.accessories),
          vet: fb.control(animal.vet),
        }),
        latestCase: fb.group({
          entryType: fb.nonNullable.control({ value: latestCase.entryType, disabled: true }),
        }),
      };

      if (animal.archived) {
        this.form.animal.disable();
        if (latestCase.archived) {
          this.form.latestCase.disable();
        }
      }
      this.accessService.disableBasedOnRole(this.form.animal, RestrictedSection.Animal);
      this.accessService.disableBasedOnRole(this.form.latestCase, RestrictedSection.Case);

      this.baseDataService
        .getTaxonomy(animal.type)
        .pipe(take(1))
        .subscribe((taxonomyBaseDAta: AnimalTaxonomyBaseData) => {
          this.taxonomyBaseData = taxonomyBaseDAta;
        });

      this.form.animal.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(600)).subscribe(() => {
        if (this.form.animal.invalid) {
          console.info('form invalid, not updating state');
          return;
        }
        const currentAnimalForm = this.form.animal.getRawValue();

        const currentTaxonomy = {
          group: currentAnimalForm.group,
          subGroup: currentAnimalForm.subGroup,
          species: currentAnimalForm.species,
        };

        const nextTaxonomy = getNextGroupSubgroupSpecies(
          {
            group: this.lastTaxonomy.group,
            subGroup: this.lastTaxonomy.subGroup,
            species: this.lastTaxonomy.species,
          },
          {
            group: currentTaxonomy.group,
            subGroup: currentTaxonomy.subGroup,
            species: currentTaxonomy.species,
          },
          this.taxonomyBaseData,
        );

        this.form.animal.patchValue(
          {
            group: nextTaxonomy.group,
            subGroup: nextTaxonomy.subGroup,
            species: nextTaxonomy.species,
          },
          { emitEvent: false },
        );

        this.lastTaxonomy = nextTaxonomy;

        const dto: UpdateAnimalDto = {
          ...currentAnimalForm,
          groupId: nextTaxonomy.group?.id ?? null,
          subGroupId: nextTaxonomy.subGroup?.id ?? null,
          speciesId: nextTaxonomy.species?.id ?? null,
          vetId: currentAnimalForm.vet?.id ?? null,
        };

        this.animalService.update(animal.id, dto).subscribe(a => store.loadAnimal(a.id));
      });
    });

    // Update form when animal or latestCase changes
    store.state$.pipe(skip(1), takeUntilDestroyed(this.destroyRef)).subscribe(({ animal, latestCase }) => {
      this.form.animal.patchValue(animal, { emitEvent: false });
      this.form.latestCase.patchValue(latestCase, { emitEvent: false });
    });
  }

  downloadBoxCard() {
    this.animal$.pipe(take(1)).subscribe((animal: AnimalDto) => {
      this.animalService
        .getLatestCase(animal.id)
        .pipe(
          switchMap(latestCase => {
            return this.caseAnimalService.downloadBoxCardPdf(animal.id, latestCase.id);
          }),
        )
        .subscribe(response => {
          openDownloadedBlobPdf(response);
        });
    });
  }

  downloadAdmissionForm() {
    this.animal$.pipe(take(1)).subscribe((animal: AnimalDto) => {
      this.animalService
        .getLatestCase(animal.id)
        .pipe(
          switchMap(latestCase => {
            return this.caseAnimalService.downloadAdmissionFormPdf(animal.id, latestCase.id);
          }),
        )
        .subscribe(response => {
          openDownloadedBlobPdf(response);
        });
    });
  }

  unarchive(animal: AnimalDto) {
    this.animalService.unarchiveAnimal(animal.id).subscribe(() => {
      this.snackbar.showSuccessMessage('Archivierung wurde aufgehoben');
      this.store.loadAnimal(animal.id);
    });
  }
}
