import { AnimalId } from '@animal/dtos/animal.dto';
import { ContactId } from '@contact/dto/contact-list-view.dto';
import { ContactDto } from '@contact/dto/contact.dto';
import { ContactRelationId } from '@contact/dto/related-contact.dto';
import { BrandedId } from '@core/utils/brand';
import { Iso8601String } from '@core/utils/date';

export enum ProspectState {
  Open = 'Open',
  ContractPrepared = 'ContractPrepared',
  ContractConcluded = 'ContractConcluded',
  Cancelled = 'Cancelled',
}

export type ProspectId = BrandedId<'ProspectId'>;

export interface ProspectDto {
  id: ProspectId;
  contact: ContactDto;
  animals: ProspectAnimalDto[];
  state: ProspectState;
  consentSiteSupervision: boolean;
  catLockAvailable: boolean;
  writtenAuthorizationToKeepAnimals: boolean;
  hasAnimalsInHousehold: boolean;
  hasAnimalsInHouseholdDetails: string | null;
  imageOfAccommodationPresented: boolean;
  livingSituation: string | null;
  amicusId: string | null;
  contribution: number | null;
  donation: number | null;
  createdDate: Iso8601String;
}

export interface ProspectAnimalDto {
  id: AnimalId;
  name: string | null;
  trdId: string;
}

export interface ProspectUpdateDto {
  contactId: ContactId;
  state: ProspectState;
  animals: ProspectAnimalDto[];
  consentSiteSupervision: boolean;
  catLockAvailable: boolean;
  writtenAuthorizationToKeepAnimals: boolean;
  hasAnimalsInHousehold: boolean;
  hasAnimalsInHouseholdDetails: string | null;
  imageOfAccommodationPresented: boolean;
  livingSituation: string | null;
  amicusId: string | null;
  contribution: number | null;
  donation: number | null;
}

export interface CreateProspectDto {
  contactId: ContactId;
  contactRelationId: ContactRelationId;
}
