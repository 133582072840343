import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { RouterLink } from '@angular/router';
import { AnimalHeaderStateDto } from '@animal/dtos/animal-header-state.dto';
import { BaseDataDto } from '@case/dtos/base-data.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseDto } from '@case/dtos/case.dto';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { routes_config } from '@core/constants';
import { ExcludeRoleDirective } from '@core/directives/exclude-role.directive';
import { AnimalType, UserRole } from '@core/models/general';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FileBaseDto } from '@file/dto/file.dto';
import { FileService, UploadResult } from '@file/service/file.service';
import { TranslateModule } from '@ngx-translate/core';

import { AnimalDto } from '../../dtos/animal.dto';
import { AnimalStateSwitchesComponent } from '../../pages/animal-detail/components/animal-state-switches/animal-state-switches.component';
import { AnimalService } from '../../services/animal.service';

export enum AnimalViewContext {
  Care = 'Care',
  AnimalDetail = 'AnimalDetail',
}

@Component({
  selector: 'tgn-animal-header',
  standalone: true,
  imports: [
    AnimalStateSwitchesComponent,
    AsyncPipe,
    AvatarImageComponent,
    RouterLink,
    TranslateModule,
    EnumDisplayPipe,
    ExcludeRoleDirective,
    MatChip,
  ],
  templateUrl: './animal-header.component.html',
  styleUrl: './animal-header.component.scss',
})
export class AnimalHeaderComponent implements OnInit {
  @Input({ required: true }) animal!: AnimalDto;
  @Input({ required: true }) baseData!: BaseDataDto | null;
  @Input({ required: true }) context!: { type: AnimalViewContext; case: CaseDto | CaseDetailDto | null | undefined };

  animalHeaderState: AnimalHeaderStateDto | null = null;

  caseAnimalIndex: number | null = null;
  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalViewContext = AnimalViewContext;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;

  constructor(
    private fileService: FileService,
    private animalService: AnimalService,
  ) {}

  ngOnInit() {
    this.animalService.getAnimalHeaderState(this.animal.id).subscribe(state => {
      this.animalHeaderState = state;
    });
  }

  uploadAnimalAvatar = async (file: File, animal: AnimalDto): Promise<UploadResult<FileBaseDto>> => {
    return this.fileService.uploadAnimalProfileImage(animal.id, file);
  };
}
