import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateFilterFn, MatDateRangeInput, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldAppearance, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IconComponent } from '@core/components/icon/icon.component';
import { IsoLocalDateString } from '@core/utils/date';
import { dateValidator } from '@core/utils/date-validator';
import { TranslateModule } from '@ngx-translate/core';

import { DisableControlDirective } from '../../directives/disable-control.directive';
import { FieldErrorTranslationPipe } from '../../pipes/field-error-translation.pipe';

export interface DateRangeGroup {
  start: FormControl<IsoLocalDateString | null>;
  end: FormControl<IsoLocalDateString | null>;
}

const dateFirstOfYear = new Date(new Date().getFullYear(), 0, 1);
const dateEndOfYear = new Date(new Date().getFullYear(), 11, 31);

@Component({
  selector: 'tgn-date-picker-range',
  templateUrl: './date-picker-range.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    DisableControlDirective,
    TranslateModule,
    FieldErrorTranslationPipe,
    MatDatepickerModule,
    MatFormFieldModule,
    MatButtonModule,
    IconComponent,
    DisableControlDirective,
  ],
  providers: [DatePipe],
})
export class DatePickerRangeComponent<D> implements OnInit {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() readonly = false;
  @Input() startPlaceholder = this.datePipe.transform(dateFirstOfYear, 'dd.MM.yyyy');
  @Input() endPlaceholder = this.datePipe.transform(dateEndOfYear, 'dd.MM.yyyy');
  @Input() hideLabel = false;
  @Input() hideActions = false;
  @Input() datepickerFilterStart!: DateFilterFn<D | null>;
  @Input() datepickerFilterEnd!: DateFilterFn<D | null>;
  @Input({ required: true }) control!: FormGroup<DateRangeGroup>;
  @Input() autocomplete = 'off';
  @Input() skipDefaultValidators = true;
  @ViewChild(MatDateRangeInput) rangeInput!: MatDateRangeInput<Date>;

  @Output() onConfirm = new EventEmitter<void>();

  formGroup!: FormGroup;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    if (!this.skipDefaultValidators) {
      // Install date range validator for every field that is required
      const startControl = this.control.controls.start;
      const endControl = this.control.controls.end;

      if (startControl && startControl.hasValidator(Validators.required)) {
        startControl.addValidators([dateValidator()]);
      }

      if (endControl && endControl.hasValidator(Validators.required)) {
        endControl.addValidators([dateValidator()]);
      }
    }
  }

  onClose() {
    if (this.readonly) {
      return;
    }
  }
}
