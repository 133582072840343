<div class="filter">
  <div class="filter-display-row">
    <button [matMenuTriggerFor]="menu" class="side-menu-button filter-button" type="button">
      <div class="filter-button-inner">
        <span class="no-filter-active">
          {{ 'COMPONENT.FILTER.TITLE' | translate }}
        </span>
        <tgn-icon [iconName]="'radixChevronDown'" />
      </div>
    </button>

    <tgn-active-filter-group [label]="'ENTITY.Animal.state' | translate" [show]="filter.filters.stateFilter.values.length > 0">
      @for (item of filter.filters.stateFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.AnimalState'"
          (onRemove)="onRemoveSingleAnimalStateFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Case.caseEntryType' | translate" [show]="filter.filters.entryTypeFilter.values.length > 0">
      @for (item of filter.filters.entryTypeFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.CaseEntryType'"
          (onRemove)="onRemoveSingleEntryTypeFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Animal.group' | translate" [show]="filter.filters.groupFilter.values.length > 0">
      @for (item of filter.filters.groupFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleGroupFilter(item)" />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Animal.subGroup' | translate" [show]="filter.filters.subGroupFilter.values.length > 0">
      @for (item of filter.filters.subGroupFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleSubGroupFilter(item)" />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Animal.species' | translate" [show]="filter.filters.speciesFilter.values.length > 0">
      @for (item of filter.filters.speciesFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleSpeciesFilter(item)" />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Animal.box' | translate" [show]="filter.filters.boxFilter.values.length > 0">
      @for (item of filter.filters.boxFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleBoxFilter(item)" />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.ENTRY_DATE' | translate"
      [show]="filter.filters.entryDateFilter.from !== null || filter.filters.entryDateFilter.to !== null"
    >
      <tgn-selected-filter-item
        (onRemove)="onRemoveDateFilter()"
        [label]="
          ((filter.filters.entryDateFilter.from | date: 'dd.MM.yyyy') ?? '...') +
          ' - ' +
          ((filter.filters.entryDateFilter.to | date: 'dd.MM.yyyy') ?? '...')
        "
      />
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Animal.mainGroup' | translate" [show]="filter.filters.animalTypeFilter.values.length > 0">
      @for (item of filter.filters.animalTypeFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.AnimalType'"
          (onRemove)="onRemoveSingleAnimalTypeFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Animal.sex' | translate" [show]="filter.filters.animalSexFilter.values.length > 0">
      @for (item of filter.filters.animalSexFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.AnimalSex'"
          (onRemove)="onRemoveSingleAnimalSexFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'ENTITY.Placement.placementState' | translate"
      [show]="filter.filters.placementStateFilter.values.length > 0"
    >
      @for (item of filter.filters.placementStateFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.PlacementState'"
          (onRemove)="onRemoveSinglePlacementStateFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'ENTITY.Placement.placementType' | translate"
      [show]="filter.filters.placementTypeFilter.values.length > 0"
    >
      @for (item of filter.filters.placementTypeFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.PlacementType'"
          (onRemove)="onRemoveSinglePlacementTypeFilter(item)"
        />
      }
    </tgn-active-filter-group>

    @if (filter.anyFilterActive()) {
      <tgn-selected-filter-item
        class="clear-all-button"
        (click)="clearAllFilters()"
        [label]="'COMPONENT.FILTER.CLEAR_FILTER' | translate"
      />
    }
  </div>

  <mat-menu #menu class="filter-menu">
    <tgn-step-view (click)="$event.stopPropagation()" *matMenuContent>
      <ng-template [tgnStepInitial]="true" tgnStep>
        <div class="menu-item-list">
          <button [tgnNextStep]="animalStateStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.ANIMAL_STATE' | translate }}
          </button>
          <button [tgnNextStep]="entryTypeStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.CASE_ENTRY_TYPE' | translate }}
          </button>
          <button [tgnNextStep]="boxStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.BOX' | translate }}
          </button>
          <button [tgnNextStep]="animalTypeStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.MAIN_GROUP' | translate }}
          </button>
          <button [tgnNextStep]="groupStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.GROUP' | translate }}
          </button>
          <button [tgnNextStep]="subGroupStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.SUB_GROUP' | translate }}
          </button>
          <button [tgnNextStep]="speciesStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.SPECIES' | translate }}
          </button>
          <button [tgnNextStep]="entryDateStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.ENTRY_DATE' | translate }}
          </button>
          <button [tgnNextStep]="animalSexStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.ANIMAL_SEX' | translate }}
          </button>
          <button [tgnNextStep]="placementStateStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.PLACEMENT_STATE' | translate }}
          </button>
          <button [tgnNextStep]="placementTypeStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.PLACEMENT_TYPE' | translate }}
          </button>
        </div>
      </ng-template>

      <ng-template #animalStateStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onAnimalStateFilterChange($event)"
          [filter]="filter.filters.stateFilter"
          [options]="animalStateChoices"
        />
      </ng-template>

      <ng-template #entryTypeStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onEntryTypeFilterChange($event)"
          [filter]="filter.filters.entryTypeFilter"
          [options]="entryTypeChoices"
        />
      </ng-template>

      <ng-template #boxStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onBoxFilterChange($event)"
          [filter]="filter.filters.boxFilter"
          [options]="boxes$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #animalTypeStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onAnimalTypeFilterChange($event)"
          [filter]="filter.filters.animalTypeFilter"
          [options]="animalTypeChoices"
        />
      </ng-template>

      <ng-template #groupStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onGroupFilterChange($event)"
          [filter]="filter.filters.groupFilter"
          [options]="availableGroups$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #subGroupStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onSubGroupFilterChange($event)"
          [filter]="filter.filters.subGroupFilter"
          [options]="availableSubGroups$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #speciesStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onSpeciesFilterChange($event)"
          [filter]="filter.filters.speciesFilter"
          [options]="availableSpecies$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #entryDateStep="tgnStep" tgnStep>
        <div class="date-picker-container">
          <tgn-date-picker-range [control]="this.dateRangeGroup" />
        </div>
      </ng-template>

      <ng-template #animalSexStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onAnimalSexFilterChange($event)"
          [filter]="filter.filters.animalSexFilter"
          [options]="animalSexChoices"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #placementStateStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onPlacementStateFilterChange($event)"
          [filter]="filter.filters.placementStateFilter"
          [options]="placementStateChoices"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #placementTypeStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onPlacementTypeFilterChange($event)"
          [filter]="filter.filters.placementTypeFilter"
          [options]="placementTypeChoices"
          [searchable]="true"
        />
      </ng-template>
    </tgn-step-view>
  </mat-menu>
</div>
