import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, ContentChild, DestroyRef, Directive, Input, OnInit, TemplateRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { startWith } from 'rxjs';

interface FormElementContext {
  id: string;
  control: FormControl<unknown>;
}

@Directive({
  selector: 'ng-template[formElement]',
  standalone: true,
})
export class FormElementDirective {
  constructor(public template: TemplateRef<FormElementContext>) {}

  static ngTemplateContextGuard(directive: FormElementDirective, context: unknown): context is FormElementContext {
    return true;
  }
}

@Component({
  selector: 'tgn-form-element',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, TranslateModule, FieldErrorTranslationPipe, AsyncPipe],
  templateUrl: './form-element.component.html',
  styleUrl: './form-element.component.scss',
})
export class FormElementComponent implements OnInit {
  @Input({ required: true }) id!: string;
  @Input({ required: true }) control!: FormControl | undefined;

  @Input({ required: false }) label: string | null = null;

  @Input({ required: false }) optional = false;
  @Input({ required: false }) hideError = false;

  @Input() emphasizedLabel = false;

  @ContentChild(FormElementDirective, { read: TemplateRef }) formElement!: TemplateRef<FormElementContext>;

  required = false;
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.control?.statusChanges.pipe(startWith(null), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.checkRequired();
    });
  }

  private checkRequired() {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
  }
}
