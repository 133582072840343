@if (withContextAction) {
  <div class="tgn-context-actions" *tgnContextActions>
    <tgn-button-upload
      [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE | andRoleRestriction: roleRestriction"
      (onUploadCompleted)="reload()"
      [fileCollection]="fileCollection"
      [tags]="tags"
    />
  </div>
}

<div class="content">
  <tgn-scrollable-table>
    <table #table="matTable" [dataSource]="datasource" mat-table>
      <tr *matHeaderRowDef="['filename', 'uploadUser', 'contentType', 'createdDate', 'actions']" mat-header-row></tr>
      <tr *matRowDef="let file; table: table; columns: ['filename', 'uploadUser', 'contentType', 'createdDate', 'actions']" mat-row></tr>

      <ng-container matColumnDef="filename">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.FileDto.filename' | translate }}</th>
        <td *matCellDef="let file; table: table" mat-cell>
          @if (showImagesInline && (file | isImage)) {
            <span>{{ file.filename }}</span>
            <tgn-single-image-display [showDeleteButton]="false" [image]="file" />
          } @else {
            <a class="filename-link" download="{{ file.filename }}" href="{{ file | file | async }}">{{ file.filename }}</a>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="uploadUser">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.FileDto.uploadUser' | translate }}</th>
        <td *matCellDef="let file; table: table" mat-cell>{{ file.uploadUser | fullName }}</td>
      </ng-container>

      <ng-container matColumnDef="contentType">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.FileDto.contentType' | translate }}</th>
        <td *matCellDef="let file; table: table" mat-cell>{{ file | contentTypeDisplay }}</td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.FileDto.createdDate' | translate }}</th>
        <td *matCellDef="let file; table: table" mat-cell>{{ file.createdDate | date: 'dd.MM.yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.UI.MENU_ACTIONS' | translate }}</th>
        <td *matCellDef="let file; table: table" mat-cell>
          <button [matMenuTriggerData]="{ file: file }" [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <ng-template let-file="file" matMenuContent>
              <button (click)="openRenameFileDialog(file)" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" mat-menu-item>
                <span>{{ 'GENERAL.BUTTON.RENAME' | translate }}</span>
              </button>

              <a class="tgn-button" download="{{ file.filename }}" href="{{ file | file | async }}" mat-menu-item target="_blank">
                {{ 'GENERAL.BUTTON.DOWNLOAD' | translate }}
              </a>

              <button (agreeEvent)="deleteFile(file)" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" appConfirmationDialog mat-menu-item>
                <span>{{ 'GENERAL.BUTTON.DELETE' | translate }}</span>
              </button>
            </ng-template>
          </mat-menu>
        </td>
      </ng-container>
    </table>
    <tgn-paginator
      (onPageEvent)="pageChanged($event)"
      [maxItemCount]="totalItems"
      [maxPages]="totalPages"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      class="paginator"
      paginator
    ></tgn-paginator>
  </tgn-scrollable-table>
</div>
