import { BoxDto, GroupDto, SpeciesDto, SubGroupDto } from '@case/dtos/base-data.dto';
import { ContactDto } from '@contact/dto/contact.dto';
import { AnimalSex, AnimalState, AnimalType, FurLength, UnitsLength } from '@core/models/general';
import { BrandedId } from '@core/utils/brand';
import { IsoLocalDateString } from '@core/utils/date';
import { FileCollectionDto } from '@file/dto/file-collection.dto';
import { FileDto } from '@file/dto/file.dto';

export type AnimalId = BrandedId<'AnimalId'>;

export interface AnimalDto {
  id: AnimalId;
  trdId: string;
  name: string | null;
  type: AnimalType;
  group: GroupDto | null;
  subGroup: SubGroupDto | null;
  species: SpeciesDto | null;
  state: AnimalState;
  sex: AnimalSex;
  chipId: string | null;
  ringId: string | null;
  castrated: boolean;
  birthdate: IsoLocalDateString | null;
  guessedAge: string | null;
  race: string | null;
  raceNote: string | null;
  behaviour: string | null;
  specials: string | null;
  feedingHabits: string | null;
  isDraft: boolean;
  coloring: string | null;
  furLength: FurLength | null;
  turtleShellLength: string | null;
  turtleShellLengthUnit: UnitsLength | null;
  box: BoxDto | null;
  fileCollection: FileCollectionDto;
  avatarImage: FileDto | null;
  accessories: string[];
  vet: ContactDto | null;
  archived: boolean;
}

export const DEFAULT_ANIMAL_NAME_DISPLAY = 'Unbenanntes Tier';
