import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BaseDataPositionDto, UpdateBaseDataPositionDto } from '@baseData/dtos/base-data-position.dto';
import { BaseDataPositionService } from '@baseData/services/base-data-position.service';
import { ChooseContactComponent } from '@contact/components/choose-contact/choose-contact.component';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { ModalComponent } from '@core/services/modal.service';
import { IsoLocalDateString } from '@core/utils/date';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';

export interface UpdateBaseDataPositionDialogData {
  baseDataPosition: BaseDataPositionDto;
}

export interface UpdateBaseDataPositionDialogResult {}

interface UpdateForm {
  validFrom: FormControl<IsoLocalDateString | null>;
  validTo: FormControl<IsoLocalDateString | null>;
}

@Component({
  selector: 'app-update-base-position-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    AutocompleteComponent,
    FormElementComponent,
    MatButtonModule,
    TranslateModule,
    ChooseContactComponent,
    FormElementDirective,
    DateInputComponent,
    RoleRestrictionDirective,
  ],
  templateUrl: './update-base-position-dialog.component.html',
  styleUrl: './update-base-position-dialog.component.scss',
})
export class UpdateBasePositionDialogComponent extends ModalComponent<
  UpdateBaseDataPositionDialogData,
  UpdateBaseDataPositionDialogResult
> {
  form!: FormGroup<UpdateForm>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpdateBaseDataPositionDialogData,
    dialogRef: MatDialogRef<UpdateBasePositionDialogComponent, UpdateBaseDataPositionDialogData>,
    private baseDataPositionService: BaseDataPositionService,
    private fb: FormBuilder,
    private accessService: AccessService,
  ) {
    super(dialogRef);
    this.form = this.fb.group({
      validFrom: this.fb.control(this.data.baseDataPosition.validFrom),
      validTo: this.fb.control(this.data.baseDataPosition.validTo),
    });

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Backoffice);
  }

  close() {
    const dto: UpdateBaseDataPositionDto = {
      validFrom: this.form.controls.validFrom.value,
      validTo: this.form.controls.validTo.value,
    };

    this.baseDataPositionService.update(this.data.baseDataPosition.id, dto).subscribe(() => {
      this.closeWithResult({});
    });
  }
}
