import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, Injectable, OnInit, WritableSignal, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatChip } from '@angular/material/chips';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseId } from '@case/dtos/case.dto';
import { CaseTitleDisplayPipe } from '@case/pipes/case-title-display.pipe';
import { CaseService } from '@case/services/case.service';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { NavTabsComponent, Tab } from '@core/components/nav-tabs/nav-tabs.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { routes_config } from '@core/constants';
import { AnimalType, CaseState, UserRole } from '@core/models/general';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { createEnumChoices } from '@core/utils/helplers';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, ReplaySubject, shareReplay, switchMap } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

@Injectable()
export class CaseDetailStore {
  case$: Observable<CaseDetailDto>;

  private caseIdSubject = new ReplaySubject<CaseId>(1);

  constructor(caseService: CaseService) {
    const id$ = this.caseIdSubject.pipe(takeUntilDestroyed());

    this.case$ = id$.pipe(
      switchMap(id => caseService.getDetail(id)),
      shareReplay(1),
    );
  }

  loadCase(id: CaseId) {
    this.caseIdSubject.next(id);
  }
}

const defaultTab = routes_config.CASE_DETAIL_GENERAL.path;

@Component({
  selector: 'app-case-layout',
  standalone: true,
  imports: [
    AsyncPipe,
    NavTabsComponent,
    RouterOutlet,
    CaseTitleDisplayPipe,
    NarrowPageContainerComponent,
    ReactiveFormsModule,
    EnumDisplayPipe,
    SelectComponent,
    SingleLineTextComponent,
    MatChip,
  ],
  templateUrl: './case-layout.component.html',
  styleUrl: './case-layout.component.scss',
  providers: [CaseDetailStore],
})
export class CaseLayoutComponent implements OnInit {
  case$: Observable<CaseDetailDto>;
  selectedTab!: WritableSignal<string>;
  readonly appRoutes = routes_config;

  updateUrlWhenTabsChange = false;

  caseStates: RadioChoice<CaseState>[] = createEnumChoices(CaseState, 'GENERAL.DOMAIN.CaseState.');

  statusForm!: FormGroup<{
    status: FormControl<CaseState>;
  }>;

  tabs: Tab[] = [
    { label: 'PAGE.CASE.DETAIL.TABS.GENERAL', value: this.appRoutes.CASE_DETAIL_GENERAL.path },
    { label: 'PAGE.CASE.DETAIL.TABS.TASKS', value: this.appRoutes.CASE_DETAIL_TASKS.path },
    { label: 'PAGE.CASE.DETAIL.TABS.ANIMALS', value: this.appRoutes.CASE_DETAIL_ANIMALS.path },
    {
      label: 'PAGE.CASE.DETAIL.TABS.CONTACTS',
      value: this.appRoutes.CASE_DETAIL_CONTACTS.path,
      roleRestriction: {
        exclude: [UserRole.Veterinarian],
      },
    },
    {
      label: 'PAGE.CASE.DETAIL.TABS.PLACEMENT',
      value: this.appRoutes.CASE_DETAIL_PLACEMENT.path,
      roleRestriction: {
        exclude: [UserRole.Veterinarian],
      },
    },
    {
      label: 'PAGE.CASE.DETAIL.TABS.BILLING_ITEMS',
      value: this.appRoutes.CASE_DETAIL_BILLING_ITEMS.path,
      roleRestriction: {
        exclude: [UserRole.Veterinarian],
      },
    },
    {
      label: 'PAGE.CASE.DETAIL.TABS.BILLS',
      value: this.appRoutes.CASE_DETAIL_BILLS.path,
      roleRestriction: {
        exclude: [UserRole.Veterinarian],
      },
    },
    {
      label: 'PAGE.CASE.DETAIL.TABS.FILES',
      value: this.appRoutes.CASE_DETAIL_FILES.path,
      roleRestriction: {
        exclude: [UserRole.Veterinarian],
      },
    },
    {
      label: 'PAGE.CASE.DETAIL.TABS.LOG',
      value: this.appRoutes.CASE_DETAIL_LOG.path,
      roleRestriction: {
        exclude: [UserRole.Veterinarian, UserRole.Guest],
      },
    },
  ];

  tabsWildAnimal = this.tabs.filter(tab => tab.value !== this.appRoutes.CASE_DETAIL_PLACEMENT.path);

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private activeRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private store: CaseDetailStore,
    private caseService: CaseService,
    private router: Router,
    private fb: FormBuilder,
    private accessService: AccessService,
  ) {
    this.case$ = store.case$;

    this.setInitialTabBasedOnRoute();

    this.listenForCaseRouteChanges();

    this.listenForTabChanges();

    this.updateBreadcrumbFromCase();

    // Update the route when the selected tab changes
    this.updateRouteOnTabChange();
  }

  ngOnInit() {
    this.case$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((caseDto: CaseDetailDto) => {
      this.statusForm = this.fb.group({
        status: this.fb.nonNullable.control<CaseState>(caseDto.state),
      });

      if (caseDto.archived) {
        this.statusForm.disable();
      }

      this.accessService.disableBasedOnRole(this.statusForm, RestrictedSection.Case);

      this.statusForm!.valueChanges.subscribe(() => {
        const formValues = this.statusForm!.getRawValue();
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.caseService.updateState(caseDto.id, { state: formValues.status }).subscribe(() => {});
      });
    });
  }

  private setInitialTabBasedOnRoute() {
    const selectedTab = this.activeRoute.snapshot.firstChild?.url[0]?.path ?? defaultTab;
    this.selectedTab = signal(selectedTab);
  }

  private listenForCaseRouteChanges() {
    this.activeRoute.params.pipe(takeUntilDestroyed()).subscribe(params => {
      this.store.loadCase(params['caseId']);
    });
  }

  private listenForTabChanges() {
    this.router.events.pipe(takeUntilDestroyed()).subscribe(event => {
      if (event instanceof NavigationEnd) {
        const tab = event.urlAfterRedirects.split('/').pop() ?? defaultTab;

        if (!this.tabs.map(t => t.value).includes(tab)) {
          return;
        }

        this.selectedTab.set(tab ?? defaultTab);
      }
    });
  }

  private updateBreadcrumbFromCase() {
    this.case$.pipe(takeUntilDestroyed()).subscribe(caseDto => {
      this.breadcrumbService.set('@caseName', caseDto.caseNumber);
      if (caseDto.animalType === AnimalType.WildAnimal) {
        this.tabs = this.tabsWildAnimal;
      }
    });
  }

  private updateRouteOnTabChange() {
    effect(async () => {
      const tab = this.selectedTab();

      if (!this.updateUrlWhenTabsChange) {
        this.updateUrlWhenTabsChange = true;
        return;
      }

      await this.router.navigate(['.', tab], { relativeTo: this.activeRoute });
    });
  }
}
