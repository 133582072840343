@for (choice of choices; track choice.object) {
  <mat-checkbox
    [disabled]="control.disabled"
    [checked]="control.value | listIncludes: choice.object : compareFn : this.compareIds"
    class="checkbox-item"
    (change)="updateCheckboxChoice($event, choice)"
  >
    {{ choice.label | translate }}
  </mat-checkbox>
}
