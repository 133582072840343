@if (useIcon) {
  <button (click)="showSelectFileDialog($event)" class="fileUploadButton" mat-icon-button>
    <tgn-icon [iconName]="'uploadIcon'" />
  </button>
} @else {
  <button (click)="showSelectFileDialog($event)" class="tgn-button" mat-flat-button>
    <span>{{ label | translate }}</span>
  </button>
}

<input (change)="onFileSelected($event)" [accept]="acceptAttribute" [disabled]="false" [multiple]="true" type="file" />

@if (showFileList) {
  <tgn-file-list-preview [files]="files" (onFileDeleted)="removeFile($event)" />
}
