<div [tgnRoleRestriction]="{ exclude: [UserRole.Veterinarian] }" class="nav-section">
  @for (item of navigationItemsMain; track item) {
    @if (item.id === NavItemId.Inbox) {
      <tgn-nav-item [navItem]="item" [tgnRoleRestriction]="item.roleRestriction">
        <tgn-unread-notifications />
      </tgn-nav-item>
    } @else {
      <tgn-nav-item [navItem]="item" [tgnRoleRestriction]="item.roleRestriction"></tgn-nav-item>
    }
  }
</div>

<div class="nav-section">
  <h2 class="nav-section-title">
    {{ 'SIDEBAR.SECTION.ANIMAL_CARE' | translate }}
  </h2>
  @for (item of navigationItemsAnimalCare; track item) {
    <tgn-nav-item [navItem]="item" [tgnRoleRestriction]="item.roleRestriction"></tgn-nav-item>
  }
</div>

<div [tgnRoleRestriction]="{ exclude: [UserRole.Veterinarian] }" class="nav-section">
  <h2 class="nav-section-title">
    {{ 'SIDEBAR.SECTION.ADMINISTRATION' | translate }}
  </h2>
  @for (item of navigationItemsAdministration; track item) {
    <tgn-nav-item [navItem]="item" [tgnRoleRestriction]="item.roleRestriction"></tgn-nav-item>
  }
</div>
<div class="bottom-items">
  <tgn-nav-item (click)="onOpenSettingsNav.emit()" [navItem]="settingsNavItem" [tgnRoleRestriction]="settingsNavItem.roleRestriction" />
</div>
