<mat-form-field appearance="outline">
  <input
    [formControl]="control"
    [matDatepicker]="picker"
    [ngClass]="{ hidden: type !== DatePickerType.Date }"
    [placeholder]="placeholder"
    matInput
  />

  @if (type === DatePickerType.Date) {
  } @else {
    <input matInput [formControl]="internalControl" [placeholder]="type === DatePickerType.Month ? 'MM.YYYY' : 'YYYY'" />
  }
  <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
  <mat-datepicker
    #picker
    (monthSelected)="chosenMonthHandler($event, picker)"
    (yearSelected)="chosenYearHandler($event)"
    [startView]="type === DatePickerType.Date ? 'month' : 'multi-year'"
  ></mat-datepicker>
</mat-form-field>
