<div *tgnContextActions class="tgn-context-actions">
  <button
    (click)="createNewTask()"
    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE | andRoleRestriction: RESTRICT_CASE_WRITE"
    class="tgn-button"
    mat-flat-button
  >
    {{ 'GENERAL.ACTION.NEW_TASK' | translate }}
  </button>
</div>

<div class="case-tasks-container">
  <div class="action-container">
    <tgn-option-button
      (selectionChange)="changeFilter($event)"
      [options]="generalTaskFilterOptions"
      [selectedValue]="activeGeneralTaskFilter"
    />

    <mat-form-field appearance="outline" class="search-input">
      <input [(ngModel)]="query" matInput placeholder="Suchen" type="text" />
    </mat-form-field>
  </div>

  <div class="filter-container">
    <tgn-task-filter
      (onFilterChange)="this.updateFilter($event)"
      [filter]="filter"
      [taskGroupRestriction]="TaskCategoryGroup.Admin"
      class="case-task-filter"
    />
  </div>

  @if (datasource) {
    <tgn-scrollable-table>
      <table #table="matTable" [dataSource]="datasource" class="task-table" mat-table>
        <tr *matHeaderRowDef="columns" mat-header-row></tr>
        <tr (click)="openTask(task)" *matRowDef="let task; table: table; columns: columns" class="table-row-link" mat-row></tr>

        <ng-container matColumnDef="status">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.status' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            <span class="icon-text">
              {{ task.status | enumDisplay: 'GENERAL.DOMAIN.TaskStatus' }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.taskCategory' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            {{ task.taskCategory.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th *matHeaderCellDef mat-header-cell>{{ 'PAGE.CARE_TASK.TABLE.LABEL_TASK_TITLE' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            {{ task.title }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.description' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            <div class="overflow-column">
              <tgn-single-line-text [text]="task.description | stripRichText" />
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="terminationDate">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.terminationDate' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            <span>{{ task.terminationDate | date: 'dd.MM.yyyy' }} {{ task.terminationTime ?? '' }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="repetition">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.repetition' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            {{ (task.repetition | repetitionDisplay) ?? '' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="visum">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.completionUser' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            {{ task.completionUser | fullName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.BUTTON.MENU_ACTIONS' | translate }}</th>
          <td *matCellDef="let task; table: table" mat-cell>
            <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ task: task }" [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <ng-template let-task="task" matMenuContent>
                <button [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" (click)="moveTask(task)" mat-menu-item>
                  <span>{{ 'GENERAL.BUTTON.MOVE' | translate }}</span>
                </button>
                <button [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" (click)="copyTask(task)" mat-menu-item>
                  <span>{{ 'GENERAL.BUTTON.COPY' | translate }}</span>
                </button>
                <button [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" (agreeEvent)="deleteTask(task)" appConfirmationDialog mat-menu-item>
                  <span>{{ 'GENERAL.BUTTON.DELETE' | translate }}</span>
                </button>
              </ng-template>
            </mat-menu>
          </td>
        </ng-container>
      </table>
      <tgn-paginator
        paginator
        (onPageEvent)="pageChanged($event)"
        [maxItemCount]="totalItems"
        [maxPages]="totalPages"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        class="paginator"
      ></tgn-paginator>
    </tgn-scrollable-table>
  }
</div>
