import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, model, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenuItem, MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatCell, MatRow, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { AnimalFilterComponent } from '@animal/components/animal-filter/animal-filter.component';
import { WithDefaultAnimalNamePipe } from '@animal/pipes/with-default-animal-name.pipe';
import { CaseFilterComponent } from '@case/components/case-filter/case-filter.component';
import { CaseListViewDto } from '@case/dtos/case.dto';
import { CaseFilter, GeneralCaseFilterChoice } from '@case/models/case-filter';
import { CaseService } from '@case/services/case.service';
import { OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { WildAnimalIconComponent } from '@core/components/wild-animal-icon/wild-animal-icon.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource, SortDirection, matSortToSortDirection } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AnimalType } from '@core/models/general';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { NotInPipe } from '@core/pipes/not-in.pipe';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, skip } from 'rxjs';

interface TableConfig {
  query: string;
  sort: { entryType: SortDirection | undefined };
  pageIndex: number;
  pageSize: number;
  filter: CaseFilter;
  generalCaseStateFilter: GeneralCaseFilterChoice;
}

@Component({
  selector: 'tgn-archive-cases',
  standalone: true,
  imports: [
    CaseFilterComponent,
    DatePipe,
    FullNamePipe,
    MatCell,
    MatTableModule,
    TypesafeMatTableModule,
    WithDefaultAnimalNamePipe,
    WildAnimalIconComponent,
    SingleLineTextComponent,
    AsyncPipe,
    ScrollableTableComponent,
    AnimalFilterComponent,
    EnumDisplayPipe,
    RouterLink,
    NotInPipe,
    RoleRestrictionDirective,
    MatMenuItem,
    PaginatorComponent,
    MatMenuModule,
    MatIcon,
    MatIconButton,
    DatePipe,
    MatSortModule,
    FormsModule,
    MatFormField,
    OptionButtonComponent,
    MatInput,
    MatRow,
    TranslateModule,
    MatSort,
    ConfirmationDialogDirective,
  ],
  templateUrl: './archive-cases.component.html',
  styleUrl: './archive-cases.component.scss',
})
export class ArchiveCasesComponent {
  datasource: PagedTigonDatasource<CaseListViewDto, TableConfig>;

  columns = ['caseNumber', 'title', 'animalNames', 'box', 'entryType', 'owner', 'caseCreationDate', 'completeDate', 'state', 'actions'];

  filter: CaseFilter = CaseFilter.empty();
  query = model('');

  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;

  totalPages = 1;
  totalItems = 0;

  generalStateFilter = signal<GeneralCaseFilterChoice>(GeneralCaseFilterChoice.AllRunning);

  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    private caseService: CaseService,
    private snackbar: SnackbarService,
  ) {
    this.datasource = new PagedTigonDatasource<CaseListViewDto, TableConfig>(
      {
        query: '',
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        sort: { entryType: undefined },
        filter: this.filter,
        generalCaseStateFilter: this.generalStateFilter(),
      },
      params => {
        const sort = params.sort.entryType ? [{ column: 'entryType', direction: params.sort.entryType }] : [];

        return this.caseService.getArchivedCasesPaginated({
          query: params.query,
          pageIndex: params.pageIndex,
          pageSize: params.pageSize,
          sort: sort,
          filter: this.filter,
          generalCaseStateFilter: params.generalCaseStateFilter,
        });
      },
    );

    toObservable(this.query)
      .pipe(skip(1), debounceTime(300), takeUntilDestroyed())
      .subscribe(query => {
        this.datasource.update({ query });
      });

    this.datasource.page$.pipe(takeUntilDestroyed()).subscribe(page => {
      this.totalPages = page.totalPages;
      this.totalItems = page.totalItems;
      this.pageSize = page.pageSize;
      this.pageIndex = page.pageIndex;
    });
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.datasource.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }

  sortChanged(event: Sort) {
    if (event.active == 'entryType') {
      this.datasource.update({ sort: { entryType: matSortToSortDirection(event.direction) } });
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
  unarchiveCase(caseListViewDto: CaseListViewDto) {
    this.caseService.unarchiveCase(caseListViewDto.id).subscribe(() => {
      this.snackbar.showSuccessMessage('Archivierung wurde aufgehoben für diesen Fall');
      this.datasource.update({ pageIndex: 0 });
    });
  }

  updateCaseFilter(newFilter: CaseFilter) {
    this.filter = newFilter;
    this.datasource.update({ filter: newFilter, pageIndex: 0 });
  }
}
