import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

export const defaultLanguage = 'de';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, TranslateModule],
  providers: [],
})
export class CoreModule {
  constructor(translateService: TranslateService) {
    translateService.use(defaultLanguage);
  }
}
