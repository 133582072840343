<form class="form-grid grid-cols-6">
  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element [control]="undefined" [id]="'photo-upload'" [label]="'Fotos vom Tier'" [optional]="false" class="row-span-3">
        <tgn-input-upload
          [dropzoneLabel]="'GENERAL.ACTION.UPLOAD_PHOTOS' | translate"
          [fileCollection]="animal.fileCollection"
          [tags]="[FileTagType.AnimalImage]"
        />
      </tgn-form-element>
    </div>
  </div>

  <div class="row row-span-6 row-grow">
    <div class="item col-span-6 row-span-6">
      <tgn-form-element [control]="form.controls.behavior" [id]="'behavior'" [label]="'ENTITY.EntryCheck.behavior'" [optional]="false">
        <ng-template formElement let-control="control" let-id="id">
          <tgn-textarea
            [control]="form.controls.behavior"
            [id]="id"
            [placeholder]="'PAGE.ENTRY_CHECK.FORM.PLACEHOLDER.DESCRIBE_BEHAVIOR' | translate"
            [triggerAutosize]="true"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row row-grow">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.particularities"
        [id]="'particularities'"
        [label]="'ENTITY.EntryCheck.particularities'"
        [optional]="false"
        class="row-span-3"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-textarea
            [control]="form.controls.particularities"
            [id]="id"
            [placeholder]="'PAGE.ENTRY_CHECK.FORM.PLACEHOLDER.DESCRIBE_PARTICULARITIES' | translate"
            [triggerAutosize]="true"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.medications"
        [id]="'medication'"
        [label]="'ENTITY.EntryCheck.medication'"
        [optional]="false"
        class="row-span-3"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-textarea
            [control]="form.controls.medications"
            [id]="id"
            [placeholder]="'PAGE.ENTRY_CHECK.FORM.PLACEHOLDER.LIST_ALL_MEDICATION' | translate"
            [triggerAutosize]="true"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.accessories"
        [id]="'accessories'"
        [label]="'ENTITY.EntryCheck.accessories'"
        [optional]="false"
        class="row-span-3"
      >
        <tgn-chip-input [class]="'item-1'" [formControl]="form.controls.accessories" />
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.box"
        [hideError]="true"
        [id]="'accessories'"
        [label]="'PAGE.ENTRY_CHECK.FORM.LABEL.BOX'"
        [optional]="false"
        class="row-span-3"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-select
            [choices]="boxes | addIfMissing: form.controls.box.value | toRadioChoice: 'name'"
            [compareIds]="true"
            [control]="control"
            [id]="id"
            [placeholder]="'ENTITY.EntryCheck.box' | translate"
            [searchable]="true"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
</form>
