import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import {
  Auth,
  GoogleAuthProvider,
  OAuthCredential,
  User,
  confirmPasswordReset,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { routes_config } from '@core/constants';
import { BaseService } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUserService } from '@user/service/current-user.service';
import { OAuthProvider } from 'firebase/auth';
import { Observable, firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  auth = inject(Auth);
  snackbar = inject(SnackbarService);

  constructor(
    userService: CurrentUserService,
    translate: TranslateService,
    private httpClient: HttpClient,
    private router: Router,
  ) {
    super();
    // Prevent the user from logging in if they are not known to the backend
    this.auth.beforeAuthStateChanged(async user => {
      if (user) {
        const token = await user.getIdToken();

        // getInitialCurrentUser returns a 403 if the user is not known to the backend.
        const backendUser = await firstValueFrom(userService.getInitialCurrentUser(token)).catch(() => null);

        if (!backendUser) {
          this.snackbar.showErrorMessage(translate.instant('PAGE.LOGIN.ERRORS.NO_USER_FOR_LOGIN'));
          // Throwing an error here will cause the user to be prevented from being logged into the app
          throw new Error('Unknown user');
        }
      }
    });
  }

  get currentUser(): User | null {
    return this.auth.currentUser;
  }

  async loginWithPassword(email: string, password: string) {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
    } catch (e: unknown) {
      if (e instanceof FirebaseError) {
        console.error(e.message);
      } else {
        console.error(e);
      }
      this.snackbar.showErrorMessage('PAGE.LOGIN.ERRORS.INVALID_CREDENTIALS');
    }
  }

  async loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri: routes_config.CHOOSE_TEAM_AND_STATION.path,
    });
    await signInWithRedirect(this.auth, provider);
  }

  async loginWithMicrosoftAzure() {
    const provider: OAuthProvider = new OAuthProvider('microsoft.com');

    if (environment.msEntraIdTenantId) {
      provider.setCustomParameters({
        tenant: environment.msEntraIdTenantId,
        prompt: 'login',
      });
    }

    try {
      const result = await signInWithPopup(this.auth, provider);
      console.info('result', result);
    } catch (e: unknown) {
      const error = e as { code: string; customData: { email: string } };
      console.error('Error logging in with Microsoft Azure:', error.customData);
      console.error('code: ', error.code);
      console.error('code: ', JSON.stringify(error));
      if (error.code === 'auth/account-exists-with-different-credential') {
        const providers = await fetchSignInMethodsForEmail(this.auth, error.customData.email);
        const email = error.customData.email;
        console.log('email:', email);
        console.log('this.auth:', this.auth);
        console.log('this.auth email:', this.auth.currentUser?.email);
        console.log('this.auth displayName:', this.auth.currentUser?.displayName);
        console.log('this.auth emailVerified:', this.auth.currentUser?.emailVerified);
        console.log('this.auth uid:', this.auth.currentUser?.uid);
        console.log('providers', providers);
        console.log('try linking account');
        const pendingCredential = OAuthProvider.credentialFromError(e as FirebaseError);
        console.log('pendingCredential', pendingCredential);
        await this.handleAccountExistsWithDifferentCredential(email, pendingCredential!);
      } else {
        this.snackbar.showErrorMessage('Login fehlgeschlagen');
      }
    }
    this.router.navigate(routes_config.CHOOSE_TEAM_AND_STATION.url());
  }

  async isLoggedIn(): Promise<boolean> {
    await this.auth.authStateReady();
    return !!this.auth.currentUser;
  }

  async logout() {
    await this.auth.signOut();
  }

  async confirmPassword(newPassword: string, oobToken: string) {
    await confirmPasswordReset(this.auth, oobToken, newPassword);
  }

  setNewEmailPasswordForLinking(email: string, oauthProviderToken: string): Observable<string> {
    return this.httpClient.post<string>(
      `${this.apiUrl}/users/set-new-email-password-for-linking`,
      {
        email: email,
        oauthProviderToken: oauthProviderToken,
      },
      { responseType: 'text' as 'json' },
    );
  }

  async handleAccountExistsWithDifferentCredential(email: string, pendingCredential: OAuthCredential): Promise<void> {
    const methods = await fetchSignInMethodsForEmail(this.auth, email);
    try {
      let password;
      if (methods.length > 0) {
        // Prompt the user to sign in with their email and password
        password = prompt(
          // eslint-disable-next-line max-len
          `Für diesen Benutzer (${email}) sind sowohl  ein Passwort, wie auch ein Microsoft Login hinterlegt. Bitte geben Sie noch einmal das Tigon Passwort ein um beide zu verknüpfen.`,
        );
      } else {
        console.log('no sign in methods found -> auto setting new password');
        password = await firstValueFrom(this.setNewEmailPasswordForLinking(email, pendingCredential.idToken!));
        console.log('password', password); // @TODO:REMOVE
      }

      const result = await signInWithEmailAndPassword(this.auth, email, password!);
      console.log('resultSignIn', result);
      // Link the pending credential to the existing account
      const resultLinking = await linkWithCredential(result.user, pendingCredential);
      console.log('resultLinking', resultLinking);
    } catch (e: unknown) {
      console.error('Error linking account:', e);
      this.snackbar.showErrorMessage('Account konnte nicht verknüpft werden');
    }
  }
}
