import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { VatTaxGroup } from '@baseData/dtos/vat-tax.dto';
import { VatTaxService } from '@baseData/services/vat-tax.service';
import { BillingRelevantInfoComponent } from '@bill/components/billing-relevant-info/billing-relevant-info.component';
import {
  PositionTableConfig,
  ServicesPositionContext,
  ServicesPositionTableComponent,
} from '@bill/components/services-position-table/services-position-table.component';
import { CreatePositionDto, PositionDto } from '@bill/dto/position.dto';
import { PositionService } from '@bill/services/position.service';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseDetailStore } from '@case/pages/case-detail/case-layout/case-layout.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { CastPipe } from '@core/pipes/cast.pipe';

@Component({
  selector: 'app-case-billing-items',
  standalone: true,
  imports: [ServicesPositionTableComponent, CastPipe, BillingRelevantInfoComponent],
  templateUrl: './case-billing-items.component.html',
  styleUrl: './case-billing-items.component.scss',
})
export class CaseBillingItemsComponent implements OnInit {
  datasource?: PagedTigonDatasource<PositionDto, PositionTableConfig>;

  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  totalPages = 1;
  totalItems = 0;
  caseDto?: CaseDetailDto;

  currentVatTax?: VatTaxGroup;

  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly PositionContext = ServicesPositionContext;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    protected caseDetailStore: CaseDetailStore,
    private positionService: PositionService,
    private vatTaxService: VatTaxService,
  ) {}

  ngOnInit() {
    this.caseDetailStore.case$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(caseDto => {
      this.caseDto = caseDto;
      this.createDatasource(this.caseDto);
      this.datasource!.page$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(page => {
        this.totalPages = page.totalPages;
        this.totalItems = page.totalItems;
      });
    });

    this.vatTaxService.getCurrent().subscribe((vatTax: VatTaxGroup) => {
      this.currentVatTax = vatTax;
    });
  }

  addPosition(dto: CreatePositionDto) {
    this.positionService.createCasePosition(this.caseDto!.id, dto).subscribe(() => {
      this.datasource?.update({});
    });
  }

  private createDatasource(caseDto: CaseDetailDto) {
    this.datasource = new PagedTigonDatasource<PositionDto, PositionTableConfig>(
      {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        target: {
          type: 'case',
          caseId: caseDto.id,
        },
      },
      params => {
        return this.positionService.findPositionsPaginated(
          params.target,
          {
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
          },
          true,
        );
      },
      this.destroyRef,
    );
  }
}
