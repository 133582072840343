import { DatePipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatCell, MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { BillDto, BillStatus } from '@bill/dto/bill.dto';
import { BillService } from '@bill/services/bill.service';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseId } from '@case/dtos/case.dto';
import { CaseDetailStore } from '@case/pages/case-detail/case-layout/case-layout.component';
import { ChooseContactComponent } from '@contact/components/choose-contact/choose-contact.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SelectComponent } from '@core/components/select/select.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { ContextItemDirective } from '@core/directives/context-item.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AndRoleRestrictionPipe, RESTRICT_CASE_WRITE } from '@core/models/role';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FvsCurrencyPipe } from '@core/pipes/currency.pipe';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { openDownloadedBlobPdf } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';

interface TableConfig {
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-case-bills',
  standalone: true,
  imports: [
    DateInputComponent,
    FormElementComponent,
    FormElementDirective,
    FormsModule,
    MatFormField,
    MatInput,
    TranslateModule,
    ChooseContactComponent,
    SelectComponent,
    ReactiveFormsModule,
    DatePipe,
    EnumDisplayPipe,
    FvsCurrencyPipe,
    MatButton,
    MatCell,
    ScrollableTableComponent,
    MatTableModule,
    TypesafeMatTableModule,
    MatMenuModule,
    MatIconButton,
    MatIcon,
    RouterLink,
    MatAnchor,
    ContextActionsDirective,
    FullNamePipe,
    ConfirmationDialogDirective,
    RoleRestrictionDirective,
    AndRoleRestrictionPipe,
    ContextItemDirective,
  ],
  templateUrl: './case-bills.component.html',
  styleUrl: './case-bills.component.scss',
})
export class CaseBillsComponent implements OnInit {
  datasource?: PagedTigonDatasource<BillDto, TableConfig>;

  columns = ['billingNumber', 'status', 'type', 'total', 'createdBy', 'invoiceDocumentDate', 'billingDate', 'actions'];
  readonly appRoutes = routes_config;
  caseId!: CaseId;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly BillStatus = BillStatus;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RESTRICT_CASE_WRITE = RESTRICT_CASE_WRITE;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private billService: BillService,
    protected caseStore: CaseDetailStore,
    private router: Router,
  ) {}

  ngOnInit() {
    this.caseStore.case$.pipe(take(1)).subscribe((caseDto: CaseDetailDto) => {
      this.caseId = caseDto.id;
      this.datasource = new PagedTigonDatasource<BillDto, TableConfig>(
        {
          pageIndex: 0,
          pageSize: DEFAULT_PAGE_SIZE,
        },
        params => {
          return this.billService.findBillsPaginated(caseDto, params);
        },
        this.destroyRef,
      );
    });
  }

  createStornoBill(bill: BillDto) {
    this.billService
      .stornoCancelBill(bill.id)
      .pipe(take(1))
      .subscribe((stornoBill: BillDto) => {
        this.router.navigate(this.appRoutes.CASE_EDIT_BILL.url(this.caseId, stornoBill.id));
      });
  }

  downloadPdf($event: MouseEvent, bill: BillDto) {
    if ($event.ctrlKey || $event.metaKey) {
      window.open(this.billService.getBillPdfUrl(bill.id));
      return;
    }

    this.billService.downloadBillPdfUrl(bill.id).subscribe(response => {
      openDownloadedBlobPdf(response);
    });
  }
}
