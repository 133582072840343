<div>
  <tgn-form-element
    [control]="form.controls.description"
    [hideError]="true"
    [id]="'description'"
    [label]="'ENTITY.Task.description'"
    class="row-span-3 mt-small"
  >
    <ng-template formElement let-control="control" let-id="id">
      <tgn-editor
        [control]="form.controls.description"
        [id]="id"
        [placeholder]="'PAGE.CARE.NEW_TASK.PLACEHOLDER.DESCRIPTION' | translate"
      ></tgn-editor>
    </ng-template>
  </tgn-form-element>
</div>

@if (!isCreate) {
  @if (taskHistory.length > 0) {
    <tgn-collapsible class="mt-medium" [title]="'ENTITY.Task.history' | translate" [expanded]="false">
      @for (history of taskHistory; track history.id) {
        <div class="history-entry">
          <tgn-collapsible [title]="(history.createdDate | date: 'dd.MM.yyyy') ?? 'Aufgabe'" [expanded]="$index === taskHistory.length - 1">
            @if (lastHistoryTaskReportControl && $index === taskHistory.length - 1) {
              <tgn-form-element class="mt-small" [control]="form.controls.report" [hideError]="true" [id]="'report'">
                <ng-template formElement let-control="control" let-id="id">
                  <tgn-editor
                    [control]="lastHistoryTaskReportControl"
                    [id]="id"
                    [placeholder]="'ENTITY.Task.report' | translate"
                  ></tgn-editor>
                </ng-template>
              </tgn-form-element>
            } @else {
              <div class="report-display-container">
                <label class="report-label">{{ 'ENTITY.Task.report' | translate }}</label>
                @if (history.report && history.report.length > 0) {
                  <p [innerHTML]="history.report"></p>
                } @else {
                  <span>{{ 'COMPONENT.CreateTask.REPORT_EMPTY' | translate }}</span>
                }
              </div>
            }
          </tgn-collapsible>
        </div>
      }
    </tgn-collapsible>
  }

  <div class="mt-medium">
    <label class="mt-medium">{{ 'ENTITY.Task.report' | translate }}</label>
    <tgn-form-element class="mt-small" [control]="form.controls.report" [hideError]="true" [id]="'report'">
      <ng-template formElement let-control="control" let-id="id">
        <tgn-editor [control]="form.controls.report" [id]="id" [placeholder]="'ENTITY.Task.report' | translate"></tgn-editor>
      </ng-template>
    </tgn-form-element>
  </div>
}

<div class="task-settings">
  <tgn-multi-component-select
    [choices]="statusChoices"
    [control]="form.controls.status"
    [defaultTitle]="'ENTITY.Task.status' | translate"
    [displayValue]="form.controls.status.value | enumDisplay: 'GENERAL.DOMAIN.TaskStatus'"
    [icon]="form.controls.status.value === TaskStatus.Open ? 'taskStatusOpen' : 'radixCheck'"
    [isValid]="!form.controls.status.invalid"
    [removable]="false"
  ></tgn-multi-component-select>

  <tgn-multi-component-select
    [choices]="taskCategories$ | async | addIfMissing: form.controls.taskCategory.value | toRadioChoice: 'name'"
    [control]="form.controls.taskCategory"
    [defaultTitle]="'ENTITY.Task.taskCategory' | translate"
    [displayValue]="form.controls.taskCategory.value?.name ?? null"
    [icon]="'taskCategory'"
    [isValid]="!form.controls.taskCategory.invalid"
    [removable]="true"
  ></tgn-multi-component-select>

  <tgn-multi-component-select
    [control]="form.controls.terminationDate"
    [defaultTitle]="'COMPONENT.CreateTask.DATE' | translate"
    [displayValue]="form.controls.terminationDate.value | date"
    [icon]="'radixCalendar'"
    [isValid]="!form.controls.terminationDate.invalid"
    [removable]="true"
  >
    <tgn-date-input [control]="form.controls.terminationDate" />
  </tgn-multi-component-select>

  <tgn-multi-component-select
    [control]="form.controls.terminationTime"
    [defaultTitle]="'COMPONENT.CreateTask.TIME' | translate"
    [displayValue]="form.controls.terminationTime.value"
    [icon]="'radixClock'"
    [isValid]="!form.controls.terminationTime.invalid"
    [removable]="true"
  >
    <tgn-time-input [formControl]="form.controls.terminationTime" />
  </tgn-multi-component-select>

  <tgn-multi-component-select
    (onClear)="clearRepetitions()"
    (onTrigger)="setDefaultRepetitionIfEmpty()"
    [defaultTitle]="'COMPONENT.CreateTask.REPETITION' | translate"
    [disabled]="form.controls.repetition.controls.unit.disabled"
    [displayValue]="form.controls.repetition.getRawValue() | repetitionDisplay"
    [icon]="'repetition'"
    [isValid]="!form.controls.repetition.controls.unit.invalid && !form.controls.repetition.controls.interval.invalid"
    [removable]="true"
  >
    <tgn-repetition-picker [control]="form.controls.repetition" />
  </tgn-multi-component-select>
</div>

@if (existingTask?.creationUser) {
  <div class="mt-medium creation-row">
    <span>
      <label class="emphasizedLabel">{{ 'ENTITY.Task.creationUser' | translate }}:</label>
      {{ existingTask?.creationUser | fullName }}
    </span>
    <span>
      <label class="emphasizedLabel">{{ 'ENTITY.Task.createdDate' | translate }}:</label>
      {{ existingTask?.createdDate | date }}
    </span>
  </div>
}

@if (existingTask?.completionUser) {
  <div class="mt-medium completion-row">
    <span>
      <label class="emphasizedLabel">{{ 'ENTITY.Task.completionUser' | translate }}:</label>
      {{ existingTask?.completionUser | fullName }}
    </span>
    <span>
      <label class="emphasizedLabel">{{ 'ENTITY.Task.completionDate' | translate }}:</label>
      {{ existingTask?.completionDate | date }}
    </span>
  </div>
}

@if (fileCollection.numFiles > 0 || showFilesCollapsible) {
  <tgn-collapsible [expanded]="true" [title]="'Dateien'" class="mt-medium">
    <app-file-list
      [showImagesInline]="true"
      [fileCollection]="fileCollection"
      [tags]="[]"
      [triggerRefreshFiles]="triggerRefreshFiles"
      [withContextAction]="false"
    />
  </tgn-collapsible>
}
