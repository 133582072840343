import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { ModalComponent } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';

import { FileDto } from '../../dto/file.dto';
import { FileService } from '../../service/file.service';

export interface RenameFileModalData {
  file: FileDto;
}

export interface RenameFileResult {
  filename: string;
}

@Component({
  selector: 'app-rename-file-modal',
  standalone: true,
  imports: [
    FormElementComponent,
    TranslateModule,
    FormElementDirective,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatInput,
    RoleRestrictionDirective,
  ],
  templateUrl: './rename-file-modal.component.html',
  styleUrl: './rename-file-modal.component.scss',
})
export class RenameFileModalComponent extends ModalComponent<RenameFileModalData, RenameFileResult> {
  filename: FormControl<string>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RenameFileModalData,
    dialogRef: MatDialogRef<RenameFileModalComponent, RenameFileResult>,
    private fileService: FileService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
  ) {
    super(dialogRef);
    this.filename = this.fb.nonNullable.control<string>(data.file.filename, [Validators.minLength(1)]);
  }

  delete() {
    this.fileService.renameFile(this.data.file, this.filename.value);
  }

  rename() {
    if (this.filename.invalid) {
      console.error('New filename is not valid');
      return;
    }
    this.fileService.renameFile(this.data.file, this.filename.value).subscribe({
      next: () => {
        this.data.file.filename = this.filename.value;
        this.snackbar.showSuccessMessage('COMPONENT.RenameFileModal.FEEDBACK.SUCCESS');
        this.closeWithResult({ filename: this.filename.value });
      },
      error: (err: unknown) => {
        console.error('Could not rename file', this.data.file, ' err: ', err);
        this.snackbar.showErrorMessage('COMPONENT.RenameFileModal.ERROR.COULD_NOT_RENAME');
      },
    });
  }
}
