<button
  (click)="addEntry()"
  [disabled]="baseDataPositionForm.invalid"
  [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
  class="tgn-black-button mt-medium mb-medium bottom-action-button"
  mat-flat-button
>
  {{ 'PAGE.BASE_DATA_POSITIONS.ACTIONS.ADD_POSITION' | translate }}
</button>

<div class="table-container">
  <tgn-scrollable-table [showTableIfEmpty]="true">
    <table #table="matTable" [dataSource]="dataSource" mat-table>
      <tr *matHeaderRowDef="columns" mat-header-row></tr>
      <tr *matRowDef="let baseDataPosition; table: table; columns: columns" mat-row></tr>
      <tr *matFooterRowDef="columns" mat-footer-row></tr>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.BaseDataPosition.name' | translate }}*</th>
        <td *matCellDef="let baseDataPosition; table: table; let i = index" mat-cell>
          <div class="max-width-column padded-text">
            <tgn-single-line-text [text]="baseDataPosition.name" />
          </div>
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <tgn-form-element [control]="baseDataPositionForm.controls.name" [hideError]="true" [label]="null" id="value">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.BaseDataPosition.name' | translate" matInput type="text" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.BaseDataPosition.description' | translate }}</th>
        <td *matCellDef="let baseDataPosition; table: table" mat-cell>
          <div class="max-width-column padded-text">
            <tgn-single-line-text [text]="baseDataPosition.description ?? ''" />
          </div>
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <tgn-form-element [control]="baseDataPositionForm.controls.description" [hideError]="true" [label]="null" id="'description'">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.BaseDataPosition.description' | translate"
                  matInput
                  type="text"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.BaseDataPosition.type' | translate }}</th>
        <td *matCellDef="let baseDataPosition; table: table" mat-cell>
          <div>
            {{ baseDataPosition.type | enumDisplay: 'GENERAL.DOMAIN.PositionType' }}
          </div>
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <tgn-form-element [control]="baseDataPositionForm.controls.type" [hideError]="true" [label]="null" id="type">
            <ng-template formElement let-control="control" let-id="id">
              @if (category.isPension) {
                @if (baseDataPositionForm.controls.type) {
                  <div>
                    {{ baseDataPositionForm.controls.type.value | enumDisplay: 'GENERAL.DOMAIN.PositionType' }}
                  </div>
                }
              } @else {
                <tgn-select
                  [choices]="positionTypeChoices"
                  [class]="'min-width-0 position-select'"
                  [control]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.BaseDataPosition.type' | translate"
                />
              }
            </ng-template>
          </tgn-form-element>
        </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.BaseDataPosition.price' | translate }}*</th>
        <td *matCellDef="let baseDataPosition; table: table" mat-cell>
          <div>
            {{ baseDataPosition.price | tgnCurrency: { withCHF: true } }}
          </div>
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <tgn-form-element [control]="baseDataPositionForm.controls.price" [hideError]="true" [label]="null" id="price">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline" class="price-form-field">
                <input
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.BaseDataPosition.placeholderPrice' | translate"
                  matInput
                  type="number"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </td>
      </ng-container>

      <ng-container matColumnDef="vatTaxType">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.BaseDataPosition.vatTaxType' | translate }}*</th>
        <td *matCellDef="let baseDataPosition; table: table" mat-cell>
          @if (currentVatTax) {
            <div>
              {{ baseDataPosition.vatTaxType | enumDisplay: 'GENERAL.DOMAIN.VatTaxType' }}
            </div>
          }
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <tgn-form-element [control]="baseDataPositionForm.controls.vatTaxType" [hideError]="true" [label]="null" id="vatTaxType">
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select
                [choices]="vatTaxTypeChoices"
                [class]="'min-width-0'"
                [control]="control"
                [id]="id"
                [placeholder]="'ENTITY.BaseDataPosition.vatTaxType' | translate"
              />
            </ng-template>
          </tgn-form-element>
        </td>
      </ng-container>

      <ng-container matColumnDef="validFrom">
        <th *matHeaderCellDef mat-header-cell>
          <span class="no-wrap">{{ 'ENTITY.BaseDataPosition.validFrom' | translate }}</span>
        </th>
        <td *matCellDef="let baseDataPosition; table: table; let i = index" mat-cell>
          <div class="validTo-container padded-text">
            {{ baseDataPosition.validFrom | date }}
          </div>
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <tgn-form-element [control]="baseDataPositionForm.controls.validFrom" [hideError]="true" [label]="null" id="validFrom">
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" class="min-width-0" />
            </ng-template>
          </tgn-form-element>
        </td>
      </ng-container>

      <ng-container matColumnDef="validTo">
        <th *matHeaderCellDef mat-header-cell>
          <span class="no-wrap">{{ 'ENTITY.BaseDataPosition.validTo' | translate }}</span>
        </th>
        <td *matCellDef="let baseDataPosition; table: table; let i = index" mat-cell>
          <div class="validTo-container padded-text">
            {{ baseDataPosition.validTo | date }}
          </div>
        </td>

        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let baseDataPosition; table: table" mat-cell>
          <button
            (click)="$event.stopPropagation()"
            [matMenuTriggerData]="{ baseDataPosition: baseDataPosition }"
            [matMenuTriggerFor]="menu"
            mat-icon-button
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-template let-baseDataPosition="baseDataPosition" matMenuContent>
              <button (click)="openEditDialog(baseDataPosition)" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" mat-menu-item>
                <span>{{ 'GENERAL.BUTTON.EDIT' | translate }}</span>
              </button>
              @if (!baseDataPosition.validTo) {
                <button [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" (click)="openReplaceDialog(baseDataPosition)" mat-menu-item>
                  <span>{{ 'PAGE.BASE_DATA_POSITIONS.ACTIONS.REPLACE_EXISTING' | translate }}</span>
                </button>
              }
            </ng-template>
          </mat-menu>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
    </table>
  </tgn-scrollable-table>
</div>
