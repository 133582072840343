import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listIncludes',
  standalone: true,
})
export class ListIncludesPipe<T> implements PipeTransform {
  transform(list: T[], value: T, compareFn?: (value1: T, value2: T, compareById: boolean) => boolean, compareById?: boolean): unknown {
    if (compareFn) {
      return list.some((current: T) => compareFn(current, value, compareById ?? false));
    }
    return list.includes(value);
  }
}

@Pipe({
  name: 'listIncludesAny',
  standalone: true,
})
export class ListIncludesAnyPipe<T> implements PipeTransform {
  transform(list: T[], values: T[], compareFn?: (value1: T, value2: T, compareById: boolean) => boolean, compareById?: boolean): unknown {
    if (compareFn) {
      return list.some((current: T) => values.some(otherItem => compareFn(current, otherItem, compareById ?? false)));
    }
    return list.some((current: T) => values.includes(current));
  }
}
