import { DatePipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { IconComponent } from '@core/components/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';

import { AuditChangeComponent } from '../../components/audit-change/audit-change.component';
import { AuditDiffComponent } from '../../components/audit-diff/audit-diff.component';
import { AuditViewComponent } from '../../components/audit-view/audit-view.component';

@Component({
  selector: 'app-audit-page',
  standalone: true,
  imports: [TranslateModule, DatePipe, IconComponent, AuditDiffComponent, MatChip, NgClass, AuditChangeComponent, AuditViewComponent],
  templateUrl: './audit-page.component.html',
  styleUrl: './audit-page.component.scss',
})
export class AuditPageComponent {}
