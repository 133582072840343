import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnimalId } from '@animal/dtos/animal.dto';
import { CaseId } from '@case/dtos/case.dto';
import { ContactId } from '@contact/dto/contact-list-view.dto';
import { PageResult } from '@core/data/tigon-datasource';
import { BaseService } from '@core/services/base-service';
import { PaginatedParams } from '@core/utils/pagination';
import { Observable } from 'rxjs';

import { AuditChangeUnion } from '../dtos/auditChange';

type AuditChangePaginatedParams = Omit<PaginatedParams, 'sort' | 'query'>;

export type AuditChangeEntityPaginatedParams = Omit<PaginatedParams, 'sort' | 'query'> & {
  filter?: LogFilterDto;
};

interface LogFilterDto {
  entityId: AnimalId | CaseId | ContactId;
}

@Injectable({
  providedIn: 'root',
})
export class AuditService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getAuditChanges(params: AuditChangePaginatedParams): Observable<PageResult<AuditChangeUnion>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('pageIndex', params.pageIndex.toString());
    httpParams = httpParams.set('pageSize', params.pageSize.toString());
    return this.http.get<PageResult<AuditChangeUnion>>(`${this.apiUrl}/audit/logs`, { params: httpParams });
  }

  getAuditChangesByEntity(params: AuditChangeEntityPaginatedParams): Observable<PageResult<AuditChangeUnion>> {
    let httpParams = new HttpParams();
    if (params.filter) {
      httpParams = httpParams.set('filter.entityId', params.filter.entityId.toString());
    }
    httpParams = httpParams.set('pageIndex', params.pageIndex.toString());
    httpParams = httpParams.set('pageSize', params.pageSize.toString());

    return this.http.get<PageResult<AuditChangeUnion>>(`${this.apiUrl}/audit/logs/entity`, { params: httpParams });
  }
}
