import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripRichText',
  standalone: true,
})
/**
 * Strip tags from rich text, not used for sanitization but for displaying the text within a table
 * without the formatting that comes with the rich text.
 */
export class StripRichTextPipe implements PipeTransform {
  transform(richText: string | null): string {
    if (richText === null) {
      return '';
    }
    return stripRichText(richText);
  }
}

// quick and dirty implementation of stripping rich text
export function stripRichText(richText: string | undefined): string {
  if (richText === null || richText === undefined) {
    return '';
  }
  return richText
    .replace(/<(?:.|\n)*?>/gm, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
}
