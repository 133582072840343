export type UploadFileType = 'image' | 'video' | 'pdf' | 'all';

export const defaultUploadFileTypes: UploadFileType[] = ['all'];

export const acceptedImageMimeTypes = ['image/png', 'image/jpeg', 'image/webp'];
export const acceptedPdfMimeType = 'application/pdf';
export const acceptedVideoMimeTypes = ['video/mp4', 'video/mpeg'];

export const allAcceptedMimeTypes = [...acceptedImageMimeTypes, acceptedPdfMimeType, ...acceptedVideoMimeTypes];

export type InputAcceptTypes = '*' | 'image/*' | 'application/pdf' | 'video/*';

export function getInputAcceptTypes(acceptedFileType: UploadFileType): InputAcceptTypes {
  switch (acceptedFileType) {
    case 'image':
      return 'image/*';
    case 'pdf':
      return 'application/pdf';
    case 'video':
      return 'video/*';
    case 'all':
      return '*';
  }
}
