<h1 mat-dialog-title>
  {{ 'PAGE.BASE_DATA_POSITIONS.REPLACE_BASE_DATA_POSITION_MODAL.TITLE' | translate }}
</h1>

<div class="mt-large" mat-dialog-content>
  <p>{{ 'PAGE.BASE_DATA_POSITIONS.REPLACE_BASE_DATA_POSITION_MODAL.INFO' | translate }}</p>

  <p>{{ 'PAGE.BASE_DATA_POSITIONS.REPLACE_BASE_DATA_POSITION_MODAL.INFO_2' | translate }}</p>
  <div class="form-grid grid-cols-6">
    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element [control]="form.controls.replaceExisting" [hideError]="true" [label]="''" id="replaceExisting">
          <ng-template formElement let-control="control" let-id="id">
            <mat-checkbox [formControl]="control" class="checkbox-item">
              {{ 'ENTITY.BaseDataPosition.replaceExisting' | translate }}
            </mat-checkbox>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.previousPosition"
          [hideError]="true"
          [label]="'ENTITY.BaseDataPosition.previousPosition' | translate"
          id="previousPosition"
        >
          <ng-template formElement let-control="control" let-id="id">
            {{ form.controls.previousPosition.value.name }}
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element [control]="form.controls.name" [hideError]="true" [label]="'ENTITY.BaseDataPosition.name' | translate" id="name">
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" matInput type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.description"
          [hideError]="true"
          [label]="'ENTITY.BaseDataPosition.description' | translate"
          id="description"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" matInput type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.price"
          [hideError]="true"
          [label]="'ENTITY.BaseDataPosition.price' | translate"
          id="price"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" matInput type="number" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.vatTaxType"
          [hideError]="true"
          [label]="'ENTITY.BaseDataPosition.vatTaxType' | translate"
          id="vatTaxType"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-select [choices]="vatTaxTypeChoices" [control]="control" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-3">
        <tgn-form-element
          [control]="form.controls.validFrom"
          [hideError]="true"
          [label]="'ENTITY.BaseDataPosition.validFrom' | translate"
          id="validFrom"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-date-input [control]="control" />
          </ng-template>
        </tgn-form-element>
      </div>

      <div class="item col-span-3">
        <tgn-form-element
          [control]="form.controls.validTo"
          [hideError]="true"
          [label]="'ENTITY.BaseDataPosition.validTo' | translate"
          id="contactId"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-date-input [control]="control" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>
  <button (click)="close()" [disabled]="form.invalid" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.BUTTON.REPLACE' | translate }}
  </button>
</div>
