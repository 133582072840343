// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './environment.interface';
import { EnvironmentName } from './EnvironmentName';

// require all fields of environment but also allow additional fields
// when we change branch but our local environment still contains additional fields from other branches which are not yet merged
type ExtendedEnvironment = Environment & {
  [key: string]: unknown;
};

export const environment: ExtendedEnvironment = {
  name: EnvironmentName.Test,
  apiUrl: 'https://tigon-backend-pwymalua6a-oa.a.run.app', // @TODO:
  uploadMaxFileSize: 62914560, // 60MB in Bytes
  useFirebaseAuthEmulator: false,
  firebase: {
    apiKey: 'AIzaSyBcIEeStl2UrJSOC_8QZqk279uRw0HhGQ0',
    authDomain: 'test.tigon.ch',
    projectId: 'tigon-test',
    storageBucket: 'tigon-test-data',
    messagingSenderId: '58355599068',
    appId: '1:58355599068:web:1e1710ace59aff4692d619',
    sendPasswordResetLinkForNewUser: true,
  },
  msEntraIdTenantId: 'e694986d-5aa1-448d-a06a-d31c87dd92b7',
  showMicrosoftProviderLogin: true,

  showGoogleProviderLogin: false,
  showComponentExamples: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
