<h1 mat-dialog-title>
  {{ 'COMPONENT.RenameFileModal.TITLE' | translate }}
</h1>

<div mat-dialog-content>
  <tgn-form-element
    [control]="filename"
    [id]="'filename'"
    [label]="'ENTITY.FileDto.filename' | translate"
    class="col-span-6 row-span-3 mt-sm"
  >
    <ng-template formElement let-control="control" let-id="id">
      <mat-form-field appearance="outline">
        <input
          [formControl]="control"
          [id]="id"
          [placeholder]="'ENTITY.FileDto.filename' | translate"
          cdkFocusInitial
          matInput
          type="text"
        />
      </mat-form-field>
    </ng-template>
  </tgn-form-element>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>
      {{ 'GENERAL.BUTTON.CANCEL' | translate }}
    </button>
    <button
      (click)="rename()"
      [disabled]="filename.invalid"
      [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
      class="tgn-button"
      mat-flat-button
    >
      {{ 'GENERAL.BUTTON.SAVE' | translate }}
    </button>
  </div>
</div>
