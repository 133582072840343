import { DatePipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { Router } from '@angular/router';
import { AnimalId } from '@animal/dtos/animal.dto';
import { ShowTaskDialogComponentComponent } from '@care/components/show-task-dialog-component/show-task-dialog-component.component';
import { TaskCategoryGroup, TaskDto, TaskEntityType, TaskId } from '@care/dtos/task.dto';
import { TaskService } from '@care/services/task.service';
import { OPEN_TASK_PARAM } from '@case/components/case-tasks/case-tasks.component';
import { CaseId } from '@case/dtos/case.dto';
import { IconComponent } from '@core/components/icon/icon.component';
import { routes_config } from '@core/constants';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { take } from 'rxjs';

import { NotificationDto, NotificationEntityType } from '../../dto/notification.dto';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'tgn-notification',
  standalone: true,
  imports: [MatChip, NgClass, DatePipe, IconComponent],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
})
export class NotificationComponent {
  @Input({ required: true }) notification!: NotificationDto;
  @Output() onMarkAsRead = new EventEmitter<void>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly NotificationEntityType = NotificationEntityType;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private taskService: TaskService,
    private modalService: ModalService,
  ) {}

  openNotification() {
    this.notificationService
      .markAsRead(this.notification.id)
      .pipe(take(1))
      .subscribe(() => {
        this.onMarkAsRead.emit();
        this.routeToEntity(this.notification);
      });
  }

  private routeToEntity(notification: NotificationDto) {
    switch (notification.entityType) {
      case NotificationEntityType.Case:
        this.router.navigate(routes_config.CASE_DETAIL.url(notification.entityId as CaseId));
        break;
      case NotificationEntityType.Animal:
        this.router.navigate(routes_config.ANIMAL_DETAIL.url(notification.entityId as AnimalId));
        break;
      case NotificationEntityType.Task:
        this.taskService.getTask(notification.entityId as TaskId).subscribe((task: TaskDto) => {
          switch (task.entityType) {
            case TaskEntityType.Case:
              this.router.navigate(routes_config.CASE_DETAIL_TASKS.url(task.case.id), { queryParams: { [OPEN_TASK_PARAM]: task.id } });
              break;
            case TaskEntityType.Animal:
              this.router.navigate(routes_config.CARE_ANIMAL.url(task.animal.id), { queryParams: { [OPEN_TASK_PARAM]: task.id } });
              break;
            case TaskEntityType.Station:
              this.openTaskDirectly(task);
              break;
            case TaskEntityType.Box:
              this.openTaskDirectly(task);
              break;
          }
        });
        break;
    }
  }

  private openTaskDirectly(task: TaskDto) {
    this.modalService
      .open(
        ShowTaskDialogComponentComponent,
        {
          task: task,
          taskCategoryGroups: [TaskCategoryGroup.Care, TaskCategoryGroup.Admin],
        },
        { width: ModalWidth.ExtraLarge },
      )
      .afterClosed()
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .subscribe(() => {});
  }
}
