import { HttpParams } from '@angular/common/http';
import { ExitState } from '@baseData/dtos/exit-state.dto';
import { BoxFilter, addHttpParams, newFilter } from '@care/components/task-filter/model/task-filter';
import { GroupDto, SpeciesDto, SubGroupDto } from '@case/dtos/base-data.dto';
import { ExitDateFilter } from '@core/components/global-search/model/global-search-case-filter';
import { AnimalState, AnimalType } from '@core/models/general';
import { IsoLocalDateString } from '@core/utils/date';

export interface Filter<T, Id extends string> {
  values: T[];
  id: Id;
}

export type AnimalStateFilter = Filter<AnimalState, 'stateFilter'>;
export type GroupFilter = Filter<GroupDto, 'groupFilter'>;
export type SubGroupFilter = Filter<SubGroupDto, 'subGroupFilter'>;
export type SpeciesFilter = Filter<SpeciesDto, 'speciesFilter'>;
export type AnimalTypeFilter = Filter<AnimalType, 'animalTypeFilter'>;
export type ExitStateFilter = Filter<ExitState, 'exitStateFilter'>;

export interface DateFilter {
  from: IsoLocalDateString | null;
  to: IsoLocalDateString | null;
  id: 'dateFilter';
}

export class GlobalSearchAnimalFilter {
  filters: {
    stateFilter: AnimalStateFilter;
    animalTypeFilter: AnimalTypeFilter;
    groupFilter: GroupFilter;
    subGroupFilter: SubGroupFilter;
    speciesFilter: SpeciesFilter;
    boxFilter: BoxFilter;
    exitStateFilter: ExitStateFilter;
    exitDateFilter: ExitDateFilter;
  };

  constructor(filters: {
    stateFilter: AnimalStateFilter;
    animalTypeFilter: AnimalTypeFilter;
    groupFilter: GroupFilter;
    subGroupFilter: SubGroupFilter;
    speciesFilter: SpeciesFilter;
    boxFilter: BoxFilter;
    exitStateFilter: ExitStateFilter;
    exitDateFilter: ExitDateFilter;
  }) {
    this.filters = filters;
  }

  static empty(): GlobalSearchAnimalFilter {
    return new GlobalSearchAnimalFilter({
      stateFilter: newFilter('stateFilter'),
      animalTypeFilter: newFilter('animalTypeFilter'),
      groupFilter: newFilter('groupFilter'),
      subGroupFilter: newFilter('subGroupFilter'),
      speciesFilter: newFilter('speciesFilter'),
      boxFilter: newFilter('boxFilter'),
      exitStateFilter: newFilter('exitStateFilter'),
      exitDateFilter: { from: null, to: null, id: 'exitDateFilter' },
    });
  }

  setStateFilter(filter: AnimalStateFilter): GlobalSearchAnimalFilter {
    return new GlobalSearchAnimalFilter({ ...this.filters, stateFilter: filter });
  }

  setAnimalTypeFilter(filter: AnimalTypeFilter): GlobalSearchAnimalFilter {
    return new GlobalSearchAnimalFilter({ ...this.filters, animalTypeFilter: filter });
  }

  setGroupFilter(filter: GroupFilter): GlobalSearchAnimalFilter {
    return new GlobalSearchAnimalFilter({ ...this.filters, groupFilter: filter });
  }

  setSubGroupFilter(filter: SubGroupFilter): GlobalSearchAnimalFilter {
    return new GlobalSearchAnimalFilter({ ...this.filters, subGroupFilter: filter });
  }

  setSpeciesFilter(filter: SpeciesFilter): GlobalSearchAnimalFilter {
    return new GlobalSearchAnimalFilter({ ...this.filters, speciesFilter: filter });
  }

  setBoxFilter(filter: BoxFilter): GlobalSearchAnimalFilter {
    return new GlobalSearchAnimalFilter({ ...this.filters, boxFilter: filter });
  }

  setExitStateFilter(filter: ExitStateFilter): GlobalSearchAnimalFilter {
    return new GlobalSearchAnimalFilter({ ...this.filters, exitStateFilter: filter });
  }

  setExitDateFilter(filter: ExitDateFilter): GlobalSearchAnimalFilter {
    return new GlobalSearchAnimalFilter({ ...this.filters, exitDateFilter: filter });
  }

  anyFilterActive(): boolean {
    return Object.values(this.filters).some((filter: { values?: unknown[]; from?: unknown; to?: unknown }) => {
      if (filter?.values) {
        return filter.values.length > 0;
      } else {
        return filter.from || filter.to;
      }
    });
  }

  toAnimalFilterDto(): HttpParams {
    let params = new HttpParams();

    const filterParamName = 'animalFilter';

    params = addHttpParams(this.filters.stateFilter.values, `${filterParamName}.stateFilter`, params);
    params = addHttpParams(
      this.filters.boxFilter.values.map(it => it.id),
      `${filterParamName}.boxFilter`,
      params,
    );

    params = addHttpParams(this.filters.animalTypeFilter.values, `${filterParamName}.animalTypeFilter`, params);
    params = addHttpParams(
      this.filters.groupFilter.values.map(it => it.name),
      `${filterParamName}.groupFilter`,
      params,
    );

    params = addHttpParams(
      this.filters.subGroupFilter.values.map(it => it.name),
      `${filterParamName}.subGroupFilter`,
      params,
    );

    params = addHttpParams(
      this.filters.speciesFilter.values.map(it => it.name),
      `${filterParamName}.speciesFilter`,
      params,
    );

    if (this.filters.exitDateFilter.from) {
      params = params.append(`${filterParamName}.exitDateFromFilter`, this.filters.exitDateFilter.from);
    }
    if (this.filters.exitDateFilter.to) {
      params = params.append(`${filterParamName}.exitDateToFilter`, this.filters.exitDateFilter.to);
    }

    params = addHttpParams(
      this.filters.exitStateFilter.values.map(it => it.id),
      `${filterParamName}.exitStateFilter`,
      params,
    );
    return params;
  }
}
