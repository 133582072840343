import { AsyncPipe, NgIf } from '@angular/common';
import { Component, DestroyRef, Injectable, WritableSignal, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AnimalHeaderComponent, AnimalViewContext } from '@animal/components/animal-header/animal-header.component';
import { AnimalDto, AnimalId } from '@animal/dtos/animal.dto';
import { getAnimalName } from '@animal/model/animal';
import { AnimalService } from '@animal/services/animal.service';
import { BaseDataDto } from '@case/dtos/base-data.dto';
import { CaseDto } from '@case/dtos/case.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { NavTabsComponent, Tab } from '@core/components/nav-tabs/nav-tabs.component';
import { RouterNavTabsComponent } from '@core/components/router-nav-tabs/router-nav-tabs.component';
import { routes_config } from '@core/constants';
import { AnimalType, UserRole } from '@core/models/general';
import { Observable, ReplaySubject, map, shareReplay, switchMap, zip } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

import { AnimalStateSwitchesComponent } from '../components/animal-state-switches/animal-state-switches.component';

interface AnimalState {
  animal: AnimalDto;
  latestCase: CaseDto;
}

@Injectable()
export class AnimalDetailStore {
  state$: Observable<AnimalState>;

  animal$: Observable<AnimalDto>;
  latestCase$: Observable<CaseDto>;

  private animalIdSubject = new ReplaySubject<AnimalId>(1);
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    animalService: AnimalService,
    private breadcrumbService: BreadcrumbService,
  ) {
    const id$ = this.animalIdSubject.pipe(takeUntilDestroyed());

    const animal$ = id$.pipe(switchMap(id => animalService.getAnimal(id)));
    const latestCase$ = id$.pipe(switchMap(id => animalService.getLatestCase(id)));

    this.state$ = zip(animal$, latestCase$).pipe(
      map(([animal, latestCase]) => ({ animal, latestCase })),
      shareReplay(1),
    );

    this.animal$ = this.state$.pipe(map(state => state.animal));
    this.latestCase$ = this.state$.pipe(map(state => state.latestCase));

    this.animal$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(animal => {
      this.breadcrumbService.set('@animalName', getAnimalName(animal));
    });
  }

  loadAnimal(id: AnimalId) {
    this.animalIdSubject.next(id);
  }
}

const defaultTab = routes_config.ANIMAL_DETAIL_GENERAL.path;

@Component({
  selector: 'app-animal-detail-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    NgIf,
    AsyncPipe,
    NavTabsComponent,
    RouterNavTabsComponent,
    AnimalStateSwitchesComponent,
    AnimalStateSwitchesComponent,
    AvatarImageComponent,
    AnimalHeaderComponent,
  ],
  templateUrl: './animal-detail-layout.component.html',
  styleUrl: './animal-detail-layout.component.scss',
  providers: [AnimalDetailStore],
})
export class AnimalDetailLayoutComponent {
  animal$: Observable<AnimalDto>;
  latestCase$: Observable<CaseDto>;
  baseData$: Observable<BaseDataDto>;
  appRoutes = routes_config;

  tabs: Tab[] = [
    { label: 'PAGE.ANIMAL.DETAIL.TABS.GENERAL', value: this.appRoutes.ANIMAL_DETAIL_GENERAL.path },
    { label: 'PAGE.ANIMAL.DETAIL.TABS.TASKS', value: this.appRoutes.ANIMAL_DETAIL_TASKS.path },
    {
      label: 'PAGE.ANIMAL.DETAIL.TABS.CASES',
      value: this.appRoutes.ANIMAL_DETAIL_CASES.path,
      roleRestriction: { exclude: [UserRole.Veterinarian] },
    },
    {
      label: 'PAGE.ANIMAL.DETAIL.TABS.PLACEMENT',
      value: this.appRoutes.ANIMAL_DETAIL_PLACEMENT.path,
      roleRestriction: { exclude: [UserRole.Veterinarian] },
    },
    {
      label: 'PAGE.ANIMAL.DETAIL.TABS.CONTACTS',
      value: this.appRoutes.ANIMAL_DETAIL_CONTACTS.path,
      roleRestriction: { exclude: [UserRole.Veterinarian] },
    },
    {
      label: 'PAGE.ANIMAL.DETAIL.TABS.POSITIONS',
      value: this.appRoutes.ANIMAL_DETAIL_SERVICES.path,
      roleRestriction: { exclude: [UserRole.Veterinarian] },
    },
    { label: 'PAGE.ANIMAL.DETAIL.TABS.PHOTOS', value: this.appRoutes.ANIMAL_DETAIL_PHOTOS.path },
    {
      label: 'PAGE.ANIMAL.DETAIL.TABS.FILES',
      value: this.appRoutes.ANIMAL_DETAIL_FILES.path,
      roleRestriction: {
        exclude: [UserRole.Veterinarian],
      },
    },
    {
      label: 'PAGE.ANIMAL.DETAIL.TABS.LOG',
      value: this.appRoutes.ANIMAL_DETAIL_LOG.path,
      roleRestriction: {
        exclude: [UserRole.Veterinarian, UserRole.Guest],
      },
    },
  ];

  tabsWildAnimal = this.tabs.filter(tab => {
    return tab.value != 'placement';
  });

  selectedTab: WritableSignal<string>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalViewContext = AnimalViewContext;

  constructor(
    activeRoute: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    store: AnimalDetailStore,
    baseData: BaseDataService,
    router: Router,
  ) {
    this.animal$ = store.animal$;
    this.latestCase$ = store.latestCase$;
    this.baseData$ = baseData.getBaseData();

    // Set the initial tab based on the child route
    const selectedTab = activeRoute.snapshot.firstChild?.url[0]?.path ?? defaultTab;
    this.selectedTab = signal(selectedTab);

    // Load the animal based on the route param
    activeRoute.params.pipe(takeUntilDestroyed()).subscribe(params => {
      store.loadAnimal(params['animalId']);
    });

    // Select the tab based on the current route
    router.events.pipe(takeUntilDestroyed()).subscribe(event => {
      if (event instanceof NavigationEnd) {
        const tab = event.urlAfterRedirects.split('/').pop() ?? defaultTab;

        if (!this.tabs.map(t => t.value).includes(tab)) {
          return;
        }

        this.selectedTab.set(tab ?? defaultTab);
      }
    });

    // Set the breadcrumb based on the animal
    this.animal$.pipe(takeUntilDestroyed()).subscribe(animal => {
      breadcrumbService.set('@animalName', getAnimalName(animal));
      if (animal.type === AnimalType.WildAnimal) {
        this.tabs = this.tabsWildAnimal;
      }
    });

    // Update the route when the selected tab changes
    effect(async () => {
      const tab = this.selectedTab();
      await router.navigate(['.', tab], { relativeTo: activeRoute });
    });
  }
}
