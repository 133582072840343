import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { CaseDto } from '@case/dtos/case.dto';
import { CaseService } from '@case/services/case.service';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { SnackbarService } from '@core/services/snackbar.service';
import { fullName } from '@core/utils/person';
import { Observable, ReplaySubject, switchMap } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

import { ProspectDetailComponent } from '../../components/prospect-detail/prospect-detail.component';
import { ProspectDto, ProspectId, ProspectUpdateDto } from '../../dto/prospect.dto';
import { ProspectsService } from '../../services/prospects.service';

@Component({
  selector: 'app-prospect-detail-page',
  standalone: true,
  imports: [AsyncPipe, ProspectDetailComponent, NarrowPageContainerComponent],
  templateUrl: './prospect-detail-page.component.html',
  styleUrl: './prospect-detail-page.component.scss',
})
export class ProspectDetailPageComponent implements OnInit {
  prospectId?: ProspectId;

  prospect$: ReplaySubject<ProspectDto> = new ReplaySubject<ProspectDto>(1);
  case$!: Observable<CaseDto>;

  private route = inject(ActivatedRoute);
  private destroyRef = inject(DestroyRef);

  constructor(
    private caseService: CaseService,
    private breadcrumbService: BreadcrumbService,
    private prospectsService: ProspectsService,
    private snackbar: SnackbarService,
  ) {}

  ngOnInit() {
    this.case$ = this.route.params.pipe(switchMap(({ caseId }) => this.caseService.get(caseId)));

    this.route.params
      .pipe(switchMap(({ prospectId }) => this.prospectsService.getProspect(prospectId)))
      .subscribe((prospect: ProspectDto) => {
        this.prospect$.next(prospect);
      });

    this.prospect$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(prospect => {
      this.breadcrumbService.set('@prospectName', fullName(prospect.contact)!);
      this.prospectId = prospect.id;
    });
  }

  saveProspect(dto: ProspectUpdateDto) {
    this.prospectsService.updateProspect(this.prospectId!, dto).subscribe(() => {
      this.snackbar.showSuccessMessage('NOTIFICATION.PROSPECT.SAVED');
    });
  }
}
