import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CompareWithOrWithoutIdPipe } from '@core/pipes/compare-with-or-without-id.pipe';
import { TranslateModule } from '@ngx-translate/core';

export interface FilterOption<T> {
  label: string;
  value: T;
}

@Component({
  selector: 'tgn-option-button',
  standalone: true,
  imports: [CommonModule, TranslateModule, CompareWithOrWithoutIdPipe],
  templateUrl: './option-button.component.html',
  styleUrls: ['./option-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionButtonComponent<T> {
  @Input({ required: true }) options!: FilterOption<T>[];
  @Input({ required: true }) selectedValue: T | null = null;
  @Output() selectionChange = new EventEmitter<T>();

  onClick(option: FilterOption<T>) {
    if (this.selectedValue !== option.value) {
      this.selectionChange.emit(option.value);
    }
  }
}
