import { HttpParams } from '@angular/common/http';
import { addHttpParams, newFilter } from '@care/components/task-filter/model/task-filter';
import { SubmitReasonDto } from '@case/dtos/base-data.dto';
import { CaseEntryType, CaseState } from '@core/models/general';
import { IsoLocalDateString } from '@core/utils/date';

export interface Filter<T, Id extends string> {
  values: T[];
  id: Id;
}

export enum GeneralCaseFilterChoice {
  AllRunning = 'AllRunning',
  New = 'New',
  Active = 'Active',
  InPlacement = 'InPlacement',
  Completed = 'Completed',
}

export type CaseStateFilter = Filter<CaseState, 'stateFilter'>;
export type CaseEntryTypeFilter = Filter<CaseEntryType, 'entryTypeFilter'>;
export type SubmitReasonFilter = Filter<SubmitReasonDto, 'submitReasonFilter'>;

export interface EntryDateFilter {
  from: IsoLocalDateString | null;
  to: IsoLocalDateString | null;
  id: 'entryDateFilter';
}

export interface ExitDateFilter {
  from: IsoLocalDateString | null;
  to: IsoLocalDateString | null;
  id: 'exitDateFilter';
}

export class GlobalSearchCaseFilter {
  filters: {
    stateFilter: CaseStateFilter;
    entryTypeFilter: CaseEntryTypeFilter;
    entryDateFilter: EntryDateFilter;
    submitReasonFilter: SubmitReasonFilter;
  };

  constructor(filters: {
    stateFilter: CaseStateFilter;
    entryTypeFilter: CaseEntryTypeFilter;
    entryDateFilter: EntryDateFilter;
    submitReasonFilter: SubmitReasonFilter;
  }) {
    this.filters = filters;
  }

  static empty(): GlobalSearchCaseFilter {
    return new GlobalSearchCaseFilter({
      stateFilter: newFilter('stateFilter'),
      entryTypeFilter: newFilter('entryTypeFilter'),
      entryDateFilter: { from: null, to: null, id: 'entryDateFilter' },
      submitReasonFilter: newFilter('submitReasonFilter'),
    });
  }

  setStateFilter(filter: CaseStateFilter): GlobalSearchCaseFilter {
    return new GlobalSearchCaseFilter({ ...this.filters, stateFilter: filter });
  }

  setEntryTypeFilter(entryTypeFilter: CaseEntryTypeFilter): GlobalSearchCaseFilter {
    return new GlobalSearchCaseFilter({ ...this.filters, entryTypeFilter: entryTypeFilter });
  }

  setEntryDateFilter(filter: EntryDateFilter): GlobalSearchCaseFilter {
    return new GlobalSearchCaseFilter({ ...this.filters, entryDateFilter: filter });
  }

  setSubmitReasonFilter(filter: SubmitReasonFilter): GlobalSearchCaseFilter {
    return new GlobalSearchCaseFilter({ ...this.filters, submitReasonFilter: filter });
  }

  anyFilterActive(): boolean {
    return Object.values(this.filters).some((filter: { values?: unknown[]; from?: unknown; to?: unknown }) => {
      if (filter?.values) {
        return filter.values.length > 0;
      } else {
        return filter.from || filter.to;
      }
    });
  }

  toCaseFilterDto(): HttpParams {
    let params = new HttpParams();

    const filterParamName = 'caseFilter';

    params = addHttpParams(this.filters.stateFilter.values, `${filterParamName}.stateFilter`, params);
    params = addHttpParams(this.filters.entryTypeFilter.values, `${filterParamName}.entryTypeFilter`, params);
    params = addHttpParams(
      this.filters.submitReasonFilter.values.map(it => it.id),
      `${filterParamName}.submitReasonFilter`,
      params,
    );
    if (this.filters.entryDateFilter.from) {
      params = params.append(`${filterParamName}.entryDateFromFilter`, this.filters.entryDateFilter.from);
    }
    if (this.filters.entryDateFilter.to) {
      params = params.append(`${filterParamName}.entryDateToFilter`, this.filters.entryDateFilter.to);
    }

    return params;
  }
}
