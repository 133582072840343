import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { CastPipe } from '@core/pipes/cast.pipe';
import { BrandedId } from '@core/utils/brand';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReplaySubject } from 'rxjs';

export interface WithId {
  id: string | BrandedId<string>;
}

export type SelectVariant = 'badge';

@Component({
  selector: 'tgn-select',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, TranslateModule, NgxMatSelectSearchModule, CastPipe],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'tgn-select-overlay' },
    },
  ],
})
export class SelectComponent<T> implements OnInit, OnChanges {
  @Input({ required: true }) control!: FormControl;

  @Input({ required: true }) choices!: RadioChoice<T | WithId>[];

  @Input() includeEmptyChoice = false;

  @Input() searchable = false;

  @Input() placeholder = '';

  @Input() multiple = false;

  // if true ids of the objects are compared for equality, otherwise equality is used on both objects
  @Input() compareIds? = false;

  @Input() variant: SelectVariant | null = null;

  filteredOptions: ReplaySubject<RadioChoice<T | WithId>[]> = new ReplaySubject<RadioChoice<T | WithId>[]>(1);

  optionFilterTextControl: FormControl = new FormControl('');

  private readonly destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.optionFilterTextControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.filterOptions();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const choiceChange = changes['choices'];
    if (choiceChange !== undefined) {
      this.filterOptions();
    }
  }

  defaultCompareFn(c1: unknown | WithId, c2: unknown | WithId): boolean {
    return c1 == c2;
  }

  idCompareFn(c1: unknown | WithId, c2: unknown | WithId): boolean {
    const obj1 = c1 as Partial<WithId>;
    const obj2 = c2 as Partial<WithId>;
    if (obj1?.id == undefined || obj2?.id == undefined) {
      return false;
    }

    return obj1.id === obj2.id;
  }

  private filterOptions() {
    if (!this.choices) {
      return;
    }
    // get the search keyword
    let search = this.optionFilterTextControl.value;
    if (!search || search == '') {
      this.filteredOptions.next(this.choices);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredOptions.next(this.choices.filter(option => option.label.toLowerCase().indexOf(search) > -1));
  }
}
