<div class="form-grid grid-cols-6">
  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element [control]="form.controls.caseNumber" [id]="'animalType'" [label]="'ENTITY.Case.caseNumber' | translate">
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input [formControl]="control" [id]="id" matInput type="text" />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-3">
      @if (this.case.animalType === AnimalType.DomesticAnimal) {
        <tgn-form-element
          [control]="form.controls.entryType"
          [hideError]="true"
          [id]="'caseEntryType'"
          [label]="'GENERAL.FORM.LABEL.ENTRY_AS'"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-select [choices]="caseEntryTypeChoices" [control]="control" />
          </ng-template>
        </tgn-form-element>
      }
    </div>

    <div class="item col-span-3">
      <tgn-form-element [control]="form.controls.submitReason" [id]="'lastName'" [label]="'ENTITY.Case.submitReason' | translate">
        <ng-template formElement let-control="control" let-id="id">
          <tgn-select
            [choices]="submitReasons$ | async | addIfMissing: form.controls.submitReason.value | toRadioChoice: 'name'"
            [compareIds]="true"
            [control]="control"
            class="col-span-1"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-3">
      <tgn-form-element
        [control]="form.controls.entryViaType"
        [id]="'entryViaType'"
        [label]="'ENTITY.Case.entryViaType'"
        class="row-span-3 mt-medium"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-select [choices]="entryViaTypeChoices" [control]="control" />
        </ng-template>
      </tgn-form-element>
    </div>
    <div class="item col-span-3">
      <tgn-form-element
        [control]="form.controls.entryDate"
        [id]="'entryDate'"
        [label]="'ENTITY.Case.entryDate'"
        class="row-span-3 mt-medium"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-date-input [control]="control" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-2">
      <tgn-form-element [control]="form.controls.stmzDate" [id]="'stmzDate'" [label]="'ENTITY.Case.stmzDate'" class="row-span-3 mt-medium">
        <ng-template formElement let-control="control" let-id="id">
          <tgn-date-input [control]="control" />
        </ng-template>
      </tgn-form-element>
    </div>

    <div class="item col-span-2">
      <tgn-form-element
        [control]="form.controls.stmzDeadline1"
        [id]="'stmzDeadline1'"
        [label]="'ENTITY.Case.stmzDeadline1'"
        class="row-span-3 mt-medium"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-date-input [control]="control" />
        </ng-template>
      </tgn-form-element>
    </div>

    <div class="item col-span-2">
      <tgn-form-element
        [control]="form.controls.stmzDeadline2"
        [id]="'stmzDeadline1'"
        [label]="'ENTITY.Case.stmzDeadline2'"
        class="row-span-3 mt-medium"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-date-input [control]="control" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
</div>

<a [routerLink]="routes_config.CASE_DETAIL.url(case.id)" class="tgn-black-button mb-medium mt-medium" mat-flat-button>
  {{ 'GENERAL.ACTION.TO_CASE' | translate }}
</a>
