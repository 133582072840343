import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe } from '@angular/common';
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { WildAnimalStatisticsFormComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics-form/wild-animal-statistics-form.component';
import { WildAnimalStatisticsComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics.component';
import { AnimalListViewDto } from '@animal/dtos/animal-list-view.dto';
import { ExitState } from '@baseData/dtos/exit-state.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseDto } from '@case/dtos/case.dto';
import { WildAnimalStatisticsControls } from '@case/models/new-case-form';
import { BaseDataService } from '@case/services/base-data-service';
import { CaseAnimalService } from '@case/services/case-animal.service';
import { CaseService } from '@case/services/case.service';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ButtonUploadComponent } from '@core/components/button-upload/button-upload.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { NumberInputComponent } from '@core/components/number-input/number-input.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AnimalState, AnimalType } from '@core/models/general';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { ModalComponent } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { BehaviorSubject, Observable, map, take, tap } from 'rxjs';

interface DialogData {
  animal: AnimalListViewDto;
  case: CaseDto;
}

interface DialogResult {}

type ExitForm = FormGroup<{
  animalState: FormControl<AnimalState>;
  exitState: FormControl<ExitState | null>;
  exitDate: FormControl<IsoLocalDateString | null>;
  exitNote: FormControl<string | null>;
}>;

@Component({
  selector: 'app-animal-exit-dialog',
  standalone: true,
  imports: [
    AutocompleteComponent,
    ButtonUploadComponent,
    FormElementComponent,
    MatDialogModule,
    TranslateModule,
    MatButtonModule,
    CdkTextareaAutosize,
    DateInputComponent,
    FormElementDirective,
    SelectComponent,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    WildAnimalStatisticsComponent,
    AsyncPipe,
    AddIfMissingPipe,
    ToRadioChoicePipe,
    RoleRestrictionDirective,
    NumberInputComponent,
    WildAnimalStatisticsFormComponent,
  ],
  templateUrl: './animal-exit-dialog.component.html',
  styleUrl: './animal-exit-dialog.component.scss',
})
export class AnimalExitDialogComponent extends ModalComponent<DialogData, DialogResult> implements OnInit {
  exitForm!: ExitForm;

  animalStateChoices: RadioChoice<AnimalState>[] = createEnumChoices(AnimalState, 'GENERAL.DOMAIN.AnimalState.');
  exitStateChoices$!: Observable<ExitState[]>;

  caseDetail$!: Observable<CaseDetailDto>;

  initialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  wildAnimalStatisticsForm!: FormGroup;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    dialogRef: MatDialogRef<AnimalExitDialogComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private caseAnimalService: CaseAnimalService,
    private caseService: CaseService,
    private baseDataService: BaseDataService,
    private accessService: AccessService,
    private snackbar: SnackbarService,
  ) {
    super(dialogRef);
    this.caseDetail$ = this.caseService.getDetail(this.data.case.id).pipe(
      tap(caseDetail => {
        if (caseDetail.wildAnimalStatistics) {
          this.updateWildAnimalStatistics();
        }
      }),
    );

    this.exitStateChoices$ = this.baseDataService.getBaseData().pipe(
      take(1),
      map(baseData => baseData.exitStates),
    );

    // if wild animal then initialized when statistics are loaded
    if (this.data.animal.type === AnimalType.DomesticAnimal) {
      this.initialized$.next(true);
    }

    this.caseDetail$.subscribe(detail => {
      const statistics = detail.wildAnimalStatistics ?? {
        numAnimals: 0,
        numCareStation: 0,
        numVeterinarian: 0,
        numReleased: 0,
        numDead: 0,
      };

      this.wildAnimalStatisticsForm = fb.group<WildAnimalStatisticsControls>({
        numAnimals: fb.nonNullable.control<number>(statistics.numAnimals, [Validators.min(0)]),
        numCareStation: fb.nonNullable.control<number>(statistics.numCareStation, [Validators.min(0)]),
        numVeterinarian: fb.nonNullable.control<number>(statistics.numVeterinarian, [Validators.min(0)]),
        numReleased: fb.nonNullable.control<number>(statistics.numReleased, [Validators.min(0)]),
        numDead: fb.nonNullable.control<number>(statistics.numDead, [Validators.min(0)]),
      });
    });
  }

  ngOnInit() {
    this.createForm(this.data.animal);
  }

  @HostBinding('class.active')
  get activeClass(): Observable<boolean> {
    return this.initialized$;
  }

  save() {
    const formValues = this.exitForm.getRawValue();
    this.caseAnimalService.updateExitState(this.data.case.id, this.data.animal.id, formValues).subscribe(() => {
      this.snackbar.showSuccessMessage('Tieraustritt wurde erfolgreich gespeichert');
      this.closeWithResult({});
    });
  }

  updateWildAnimalStatistics() {
    this.setValidators(this.data.animal);
  }

  private createForm(animal: AnimalListViewDto) {
    const animalState = animal.status !== AnimalState.Completed ? AnimalState.Exited : AnimalState.Completed;

    this.exitForm = this.fb.group({
      animalState: this.fb.nonNullable.control<AnimalState>(animalState),
      exitState: this.fb.control<ExitState | null>(null),
      exitDate: this.fb.control<IsoLocalDateString | null>(null),
      exitNote: this.fb.control<string | null>(null),
    });

    this.setValidators(animal);

    this.accessService.disableBasedOnRole(this.exitForm, RestrictedSection.Animal);
  }

  private setValidators(animal: AnimalListViewDto) {
    if (animal.type == AnimalType.DomesticAnimal) {
      this.exitForm.controls.exitState.setValidators([Validators.required]);
      this.exitForm.controls.exitDate.setValidators([Validators.required]);
      this.exitForm.controls.animalState.setValidators([Validators.required]);
    } else {
      this.exitForm.controls.exitState.clearValidators();
      this.exitForm.controls.exitDate.setValidators([Validators.required]);
      this.exitForm.controls.animalState.setValidators([Validators.required]);
    }
  }
}
