import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralTaskFilter } from '@animal/components/animal-tasks-table/animal-tasks-table.component';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { StationDto } from '@case/dtos/base-data.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseDto } from '@case/dtos/case.dto';
import { PageResult } from '@core/data/tigon-datasource';
import { BaseService } from '@core/services/base-service';
import { IsoLocalDateString } from '@core/utils/date';
import { PaginatedParams } from '@core/utils/pagination';
import { Observable, map } from 'rxjs';

import { TaskFilter, addHttpParams } from '../components/task-filter/model/task-filter';
import { CopyTaskDto } from '../dtos/copy-task.dto';
import { MoveTaskDto } from '../dtos/move-task.dto';
import { TaskHistoryDto } from '../dtos/task-history.dto';
import { AnimalTaskDto, BaseTaskDto, CaseTaskDto, CreateTaskDto, TaskDto, TaskEntityType, TaskId, UpdateTaskDto } from '../dtos/task.dto';
import { UpdateTaskResultDto } from '../dtos/update-task-result.dto';
import { RapportExportType } from '../models/RapportExportType';

type TaskPaginatedParams = PaginatedParams & {
  filter: TaskFilter;
  stationFilter: StationDto | null;
};

type AnimalTaskPaginatedParams = PaginatedParams & {
  filter: TaskFilter;
  stationFilter: GeneralTaskFilter;
};

type CaseTaskPaginatedParams = PaginatedParams & {
  filter: TaskFilter;
  stationFilter: GeneralTaskFilter;
};

@Injectable({
  providedIn: 'root',
})
export class TaskService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getTask(taskId: TaskId): Observable<TaskDto> {
    return this.http.get<TaskDto>(`${this.apiUrl}/tasks/${taskId}`);
  }

  createTask(dto: CreateTaskDto) {
    return this.http.post<BaseTaskDto>(`${this.apiUrl}/tasks`, dto);
  }

  getTasksPaginated(params: TaskPaginatedParams, entityTypeFilter: TaskEntityType[]): Observable<PageResult<TaskDto>> {
    let httpParams: HttpParams = params.filter.toTaskFilterDto();

    const taskStationFilter = params.stationFilter?.id;
    if (taskStationFilter) {
      httpParams = httpParams.append('filter.stationFilter', taskStationFilter);
    }

    httpParams = addHttpParams(entityTypeFilter, 'filter.taskEntityTypeFilter', httpParams);

    const flattenedParams = {
      query: params.query,
      pageIndex: params.pageIndex.toString(),
      pageSize: params.pageSize.toString(),
      sort: params.sort.map(s => `${s.column}:${s.direction}`).join(':'),
    };

    httpParams = httpParams.appendAll(flattenedParams);

    return this.http.get<PageResult<TaskDto>>(`${this.apiUrl}/tasks/paginated`, { params: httpParams });
  }

  getAnimalCaseTasksPaginated(
    animal: AnimalDto,
    params: AnimalTaskPaginatedParams,
    includeCaseTasks: boolean,
  ): Observable<PageResult<AnimalTaskDto>> {
    let httpParams: HttpParams = params.filter.toTaskFilterDto();
    httpParams = httpParams.append('filter.generalTaskFilter', params.stationFilter);

    const flattenedParams = {
      query: params.query,
      pageIndex: params.pageIndex.toString(),
      pageSize: params.pageSize.toString(),
      sort: params.sort.map(s => `${s.column}:${s.direction}`).join(':'),
      includeCaseTasks: includeCaseTasks.toString(),
    };

    httpParams = httpParams.appendAll(flattenedParams);

    return this.http.get<PageResult<AnimalTaskDto>>(`${this.apiUrl}/tasks/animal/${animal.id}/paginated`, { params: httpParams });
  }

  getCaseTasksPaginated(caseDto: CaseDto | CaseDetailDto, params: CaseTaskPaginatedParams): Observable<PageResult<CaseTaskDto>> {
    let httpParams: HttpParams = params.filter.toTaskFilterDto();
    httpParams = httpParams.append('filter.generalTaskFilter', params.stationFilter);

    const flattenedParams = {
      query: params.query,
      pageIndex: params.pageIndex.toString(),
      pageSize: params.pageSize.toString(),
      sort: params.sort.map(s => `${s.column}:${s.direction}`).join(':'),
    };

    httpParams = httpParams.appendAll(flattenedParams);

    return this.http.get<PageResult<CaseTaskDto>>(`${this.apiUrl}/tasks/case/${caseDto.id}/paginated`, { params: httpParams });
  }

  create(dto: CreateTaskDto): Observable<BaseTaskDto> {
    return this.http.post<BaseTaskDto>(`${this.apiUrl}/tasks`, dto);
  }

  update(dto: UpdateTaskDto): Observable<UpdateTaskResultDto> {
    const body = {
      ...dto,
      terminationDate: dto.terminationDate,
    };
    return this.http.put<UpdateTaskResultDto>(`${this.apiUrl}/tasks/${dto.id}`, body);
  }

  delete(id: TaskId): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/tasks/${id}`);
  }

  moveTask(taskId: string, entityType: TaskEntityType, entityId: string): Observable<TaskDto> {
    const copyTaskDto: MoveTaskDto = {
      entityType: entityType,
      targetId: entityId,
    };
    return this.http.post<TaskDto>(`${this.apiUrl}/tasks/${taskId}/move`, copyTaskDto);
  }

  copyTask(taskId: string, entityType: TaskEntityType, entityId: string): Observable<TaskDto> {
    const copyTaskDto: CopyTaskDto = {
      entityType: entityType,
      targetId: entityId,
    };
    return this.http.post<TaskDto>(`${this.apiUrl}/tasks/${taskId}/copy`, copyTaskDto);
  }

  getTaskHistory(taskId: string): Observable<TaskDto[]> {
    return this.http.get<TaskHistoryDto>(`${this.apiUrl}/tasks/${taskId}/history`).pipe(map(history => history.tasks));
  }

  getRapportExportPdfUrl(date: IsoLocalDateString, type: RapportExportType): string {
    return `${this.apiUrl}/tasks/rapport-pdf?date=${date}&type=${type}`;
  }

  downloadRapportExportPdfUrl(date: IsoLocalDateString, type: RapportExportType): Observable<HttpResponse<Blob>> {
    return this.http.get(this.getRapportExportPdfUrl(date, type), {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
