import { HttpParams } from '@angular/common/http';
import { TaskCategoryDto, TaskStatus } from '@care/dtos/task.dto';
import { BoxDto } from '@case/dtos/base-data.dto';
import { AnimalState, CaseEntryType } from '@core/models/general';
import { IsoLocalDateString } from '@core/utils/date';

export interface Filter<T, Id extends string> {
  values: T[];
  id: Id;
}

export type TaskCategoryFilter = Filter<TaskCategoryDto, 'categoryFilter'>;

export type CaseEntryTypeFilter = Filter<CaseEntryType, 'entryTypeFilter'>;

export type AnimalStateFilter = Filter<AnimalState, 'animalStateFilter'>;

export type BoxFilter = Filter<BoxDto, 'boxFilter'>;

export type StatusFilter = Filter<TaskStatus, 'statusFilter'>;

export interface DateFilter {
  from: IsoLocalDateString | null;
  to: IsoLocalDateString | null;
  id: 'dateFilter';
}

interface Filters {
  categoryFilter: TaskCategoryFilter;
  entryTypeFilter: CaseEntryTypeFilter;
  animalStateFilter: AnimalStateFilter;
  boxFilter: BoxFilter;
  dateFilter: DateFilter;
  statusFilter: StatusFilter;
}

export class TaskFilter {
  filters: Filters;

  constructor(filters: Filters) {
    this.filters = { ...filters };
  }

  static empty(): TaskFilter {
    return new TaskFilter({
      categoryFilter: newFilter('categoryFilter'),
      entryTypeFilter: newFilter('entryTypeFilter'),
      animalStateFilter: newFilter('animalStateFilter'),
      boxFilter: newFilter('boxFilter'),
      dateFilter: {
        from: null,
        to: null,
        id: 'dateFilter',
      },
      statusFilter: newFilter('statusFilter'),
    });
  }

  setBoxFilter(value: BoxFilter): TaskFilter {
    return new TaskFilter({ ...this.filters, boxFilter: value });
  }

  setDateFilter(value: DateFilter): TaskFilter {
    return new TaskFilter({ ...this.filters, dateFilter: value });
  }

  setCategoryFilter(value: TaskCategoryFilter): TaskFilter {
    return new TaskFilter({ ...this.filters, categoryFilter: value });
  }

  setEntryTypeFilter(value: CaseEntryTypeFilter): TaskFilter {
    return new TaskFilter({ ...this.filters, entryTypeFilter: value });
  }

  setAnimalStateFilter(value: AnimalStateFilter): TaskFilter {
    return new TaskFilter({ ...this.filters, animalStateFilter: value });
  }

  setStatusFilter(value: StatusFilter): TaskFilter {
    return new TaskFilter({ ...this.filters, statusFilter: value });
  }

  anyFilterActive(): boolean {
    return Object.values(this.filters).some((filter: { values?: unknown[]; from?: unknown; to?: unknown }) => {
      if (filter?.values) {
        return filter.values.length > 0;
      } else {
        return filter.from || filter.to;
      }
    });
  }

  toTaskFilterDto(): HttpParams {
    let params = new HttpParams();

    const filterParamName = 'filter';

    params = addHttpParams(
      this.filters.categoryFilter.values.map(category => category.id),
      `${filterParamName}.categoryFilter`,
      params,
    );
    params = addHttpParams(this.filters.entryTypeFilter.values, `${filterParamName}.entryTypeFilter`, params);
    params = addHttpParams(this.filters.animalStateFilter.values, `${filterParamName}.animalStateFilter`, params);
    params = addHttpParams(
      this.filters.boxFilter.values.map(box => box.id),
      `${filterParamName}.boxFilter`,
      params,
    );
    params = addHttpParams(this.filters.statusFilter.values, `${filterParamName}.statusFilter`, params);

    if (this.filters.dateFilter.from) {
      params = params.append(`${filterParamName}.dateFromFilter`, this.filters.dateFilter.from);
    }
    if (this.filters.dateFilter.to) {
      params = params.append(`${filterParamName}.dateToFilter`, this.filters.dateFilter.to);
    }

    return params;
  }
}

export function addHttpParams(values: string[], paramName: string, params: HttpParams): HttpParams {
  values.forEach(value => {
    params = params.append(paramName, value);
  });
  return params;
}

export function newFilter<T, Id extends string>(id: Id): Filter<T, Id> {
  return { values: [], id: id };
}
