import { Pipe, PipeTransform } from '@angular/core';

interface WithFirstAndLastName {
  firstName: string | null;
  lastName: string | null;
}

@Pipe({ standalone: true, name: 'fullName' })
export class FullNamePipe implements PipeTransform {
  transform(value: WithFirstAndLastName | undefined | null, config?: { emptyPlaceholder: string }): string {
    if (!value || (!value.firstName && !value.lastName)) {
      return config?.emptyPlaceholder ?? '';
    }

    return [value.firstName, value.lastName].filter(it => it !== null).join(' ');
  }
}
