import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { IconComponent } from '@core/components/icon/icon.component';

@Component({
  selector: 'tgn-statistics-card',
  standalone: true,
  imports: [IconComponent, MatTooltip],
  templateUrl: './statistics-card.component.html',
  styleUrl: './statistics-card.component.scss',
})
export class StatisticsCardComponent {
  @Input({ required: true }) title!: string;
  @Input() info?: string;
}
