export enum UserRole {
  Admin = 'Admin',

  // Sekretariat
  Office = 'Office',

  // Tierpflege
  Care = 'Care',

  Hundewesen = 'Hundewesen',

  // Tiermedizin
  VeterinaryMedicine = 'VeterinaryMedicine',

  VerantwortlicheHundewesen = 'VerantwortlicheHundewesen',

  // read only role
  Guest = 'Guest',

  // Tierarzt, read only role
  Veterinarian = 'Veterinarian',

  // additional super user role in combination with either Hundewesen or Office
  Superuser = 'Superuser',

  Deactivated = 'Deactivated',
}

export enum CaseEntryType {
  Findeltier = 'Findeltier',
  Pensionstier = 'Pensionstier',
  Verzichtstier = 'Verzichtstier',
}

export enum CaseState {
  New = 'New',
  Active = 'Active',
  InPlacement = 'InPlacement',
  PlacementSupervision = 'PlacementSupervision',
  Completed = 'Completed',
}

export enum AnimalType {
  DomesticAnimal = 'DomesticAnimal',
  WildAnimal = 'WildAnimal',
}

export enum EntryViaType {
  Rescue = 'Rescue',
  Direct = 'Direct',
}

export enum FurLength {
  Short = 'Short',
  Medium = 'Medium',
  Long = 'Long',
}

export enum RoughAge {
  Juvenile = 'Juvenile',
  Adult = 'Adult',
  Senior = 'Senior',
}

export enum GeneralFieldChoice {
  Allright = 'Allright',
  Abnormalities = 'Abnormalities',
  NotAllright = 'NotAllright',
  Open = 'Open',
}

export enum OpenBooleanChoice {
  Yes = 'Yes',
  No = 'No',
  Open = 'Open',
}

export enum AnimalSex {
  Male = 'Male',
  MaleCastrated = 'MaleCastrated',
  Female = 'Female',
  FemaleCastrated = 'FemaleCastrated',
  Unknown = 'Unknown',
}

export enum AnimalState {
  Entry = 'Entry',
  InShelter = 'InShelter',
  InTrial = 'InTrial',
  ExternalCare = 'ExternalCare',
  Exited = 'Exited',
  Completed = 'Completed',
}

export enum UnknownBooleanChoice {
  Yes = 'Yes',
  No = 'No',
  Unknown = 'Unknown',
}

export enum AffectedGeneralRegion {
  BothSides = 'BothSides',
  Right = 'Right',
  Left = 'Left',
}

export enum ExtendedAffectedRegion {
  UpperLeft = 'UpperLeft',
  LowerLeft = 'LowerLeft',
  UpperRight = 'UpperRight',
  LowerRight = 'LowerRight',
}

export enum UnitsLength {
  Meter = 'Meter',
  Centimeter = 'Centimeter',
}

export enum UnitsWeight {
  Kilogram = 'Kilogram',
  Gram = 'Gram',
}

export enum Parasite {
  Ticks = 'Ticks',
  Fleas = 'Fleas',
  Mites = 'Mites',
  Featherlings = 'Featherlings',
  Worms = 'Worms',
  Hairlice = 'Hairlice',
}

export enum AffectedLimb {
  UpperLeft = 'UpperLeft',
  UpperRight = 'UpperRight',
  LowerLeft = 'LowerLeft',
  LowerRight = 'LowerRight',
}

export enum PlacementState {
  Yes = 'Yes',
  No = 'No',
  Limited = 'Limited',
}

export enum PlacementType {
  Single = 'Single',
  Group = 'Group',
}

export enum CastrationType {
  NotCastrated = 'NotCastrated',
  CastratedByVeterinarian = 'CastratedByVeterinarian',
  CastratedByPreviousOwner = 'CastratedByPreviousOwner',
}

export const USER_ROLES_WITH_NOTIFICATIONS = [UserRole.Office, UserRole.VeterinaryMedicine, UserRole.VerantwortlicheHundewesen];
