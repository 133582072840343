<h1 mat-dialog-title>
  @if (data.customTitle) {
    {{ data.customTitle | translate }}
  } @else {
    {{ 'PAGE.CONTACTS.NEW_CONTACT' | translate }}
  }
</h1>

<div mat-dialog-content>
  <div class="form-grid grid-cols-6">
    <div class="row">
      <div class="item">
        <tgn-radio-group [choices]="addChoices" [control]="form.controls.type" class="radio"></tgn-radio-group>
      </div>
    </div>
    @if (form.controls.type.value === 'relate') {
      <ng-container>
        <div class="row">
          <div class="item col-span-6">
            <tgn-form-element
              [control]="form.controls.contact"
              [hideError]="true"
              [label]="'PAGE.CONTACTS.RELATE.LABEL.CONTACT' | translate"
              id="contactId"
            >
              <ng-template formElement let-control="control" let-id="id">
                <tgn-choose-contact [allowNewCreation]="false" [control]="form.controls.contact" [id]="id" />
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row">
          <div class="item col-span-6">
            <tgn-form-element [control]="form.controls.kind" [label]="'PAGE.CONTACTS.RELATE.LABEL.KIND' | translate" id="kind">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-select
                  [compareIds]="true"
                  [choices]="relationKinds$ | async | addIfMissing: form.controls.kind.value | toRadioChoice: 'name'"
                  [control]="control"
                ></tgn-select>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row">
          <div class="fromToDate item col-span-6">
            <tgn-form-element [control]="form.controls.fromDate" [label]="'ENTITY.Contact.fromDate' | translate" id="fromDate">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-date-input [control]="control" />
              </ng-template>
            </tgn-form-element>

            <tgn-form-element [control]="form.controls.toDate" [label]="'ENTITY.Contact.toDate' | translate" id="toDate">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-date-input [control]="control" />
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
      </ng-container>
    }
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>
  <button (click)="close()" [disabled]="form.invalid" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.BUTTON.NEXT' | translate }}
  </button>
</div>
