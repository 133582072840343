import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WildAnimalStatisticsComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics.component';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { AnimalTitlePipe } from '@animal/pipes/animal-title.pipe';
import { SingleWildAnimalPlacementComponent } from '@case/components/case-general-animals/single-wild-animal-placement/single-wild-animal-placement.component';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { AnimalType } from '@core/models/general';
import { Observable, switchMap } from 'rxjs';

import { CaseDetailDto } from '../../dtos/case-detail.dto';
import { CaseService } from '../../services/case.service';
import { SingleAnimalPlacementComponent } from './single-animal-placement/single-animal-placement.component';

@Component({
  selector: 'tgn-case-general-animals',
  standalone: true,
  imports: [
    SingleAnimalPlacementComponent,
    AsyncPipe,
    AnimalTitlePipe,
    CollapsibleComponent,
    WildAnimalStatisticsComponent,
    SingleWildAnimalPlacementComponent,
  ],
  templateUrl: './case-general-animals.component.html',
  styleUrl: './case-general-animals.component.scss',
})
export class CaseGeneralAnimalsComponent implements OnInit {
  @Input({ required: true }) case$!: Observable<CaseDetailDto>;

  animals$!: Observable<AnimalDto[]>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  private readonly destroyRef = inject(DestroyRef);

  constructor(private caseService: CaseService) {}

  ngOnInit() {
    this.animals$ = this.case$.pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap((caseDto: CaseDetailDto) => {
        return this.caseService.getAnimals(caseDto.id);
      }),
    );
  }
}
