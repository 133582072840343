<div *tgnContextActions class="tgn-context-actions">
  <button (click)="openInviteUserDialog()" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="tgn-button" mat-flat-button>
    {{ 'PAGE.SETTINGS.USERS.ACTIONS.INVITE_USER' | translate }}
  </button>
</div>

<div class="content">
  <h1>Benutzer</h1>

  <mat-form-field appearance="outline" class="search-input">
    <input [(ngModel)]="query" matInput placeholder="Suchen" type="text" />
  </mat-form-field>

  <tgn-scrollable-table>
    <table #table="matTable" (matSortChange)="sortChanged($event)" [dataSource]="datasource" class="mt-medium" mat-table matSort>
      <tr *matHeaderRowDef="['avatar', 'name', 'role', 'superuser', 'status', 'email', 'actions']" mat-header-row></tr>
      <tr *matRowDef="let user; columns: ['avatar', 'name', 'role', 'superuser', 'status', 'email', 'actions']; table: table" mat-row></tr>

      <ng-container matColumnDef="avatar">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let user; table: table" mat-cell>
          <tgn-avatar-image [avatar]="user" [hideImageUpload]="true" [matTooltip]="user | fullName" size="size-xl" />
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>Name</th>
        <td *matCellDef="let user; table: table" mat-cell>{{ user | fullName: { emptyPlaceholder: '-' } }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Teams</th>
        <td *matCellDef="let user; table: table" [ngClass]="{ strikethrough: user.status === UserStatus.Deactivated }" mat-cell>
          {{
            user.roles
              | filterTeamRoles
              | listDisplay
                : {
                    translate: true,
                    prefix: 'GENERAL.DOMAIN.UserRole.'
                  }
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="superuser">
        <th *matHeaderCellDef mat-header-cell>Superuser</th>
        <td *matCellDef="let user; table: table" mat-cell>
          @if (user.roles | listIncludes: UserRole.Superuser) {
            <tgn-icon [iconName]="'radixCheck'" />
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let user; table: table" mat-cell>
          <div class="with-icon">
            <span>{{ user.status | enumDisplay: 'GENERAL.DOMAIN.UserStatus' }}</span>
            @if (user.status === UserStatus.Deactivated) {
              <tgn-icon class="ml-medium" [iconName]="'radixCross1'" />
            }
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th *matHeaderCellDef mat-header-cell>E-Mail</th>
        <td *matCellDef="let user; table: table" mat-cell>{{ user.email }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>Aktionen</th>
        <td *matCellDef="let user; table: table" mat-cell>
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button (click)="openEditUserModal(user)" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" mat-menu-item>
              <span>Bearbeiten</span>
            </button>
            <button (click)="passwordReset(user)" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" mat-menu-item>
              {{ 'PAGE.ADMIN_USERS.ACTIONS.PASSWORD_RESET' | translate }}
            </button>
            <button
              (agreeEvent)="toggleActive(user)"
              [dialogDescription]="
                user.status === UserStatus.Deactivated
                  ? 'Möchtest du den Benutzer wirklich aktivieren?'
                  : 'Möchtest du den Benutzer wirklich deaktivieren?'
              "
              [dialogTitle]="user.status === UserStatus.Deactivated ? 'Benutzer aktivieren?' : 'Benutzer deaktivieren?'"
              [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
              appConfirmationDialog
              mat-menu-item
            >
              @if (user.status === UserStatus.Deactivated) {
                <span>Aktivieren</span>
              } @else {
                <span>Deaktivieren</span>
              }
            </button>
          </mat-menu>
        </td>
      </ng-container>
    </table>
    <tgn-paginator
      (onPageEvent)="pageChanged($event)"
      [maxItemCount]="totalItems"
      [maxPages]="totalPages"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      class="paginator"
      paginator
    ></tgn-paginator>
  </tgn-scrollable-table>
</div>
