<div *tgnContextActions class="tgn-context-actions">
  <button (click)="openDownloadRapports()" [tgnRoleRestriction]="{ exclude: [UserRole.Veterinarian] }" mat-flat-button>
    {{ 'PAGE.ANIMALS.ACTIONS.DOWNLOAD_RAPPORTS' | translate }}
  </button>

  <button (click)="createNewTask()" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.ACTION.NEW_TASK' | translate }}
  </button>
</div>

<div class="content">
  <h1>{{ 'PAGE.CARE_TASK.TITLE' | translate }}</h1>

  <mat-tab-group animationDuration="0ms" class="mt-large" mat-stretch-tabs="false">
    <mat-tab label="Aufgaben" mat-align-tabs="start">
      <div class="action-container">
        <tgn-option-button
          (selectionChange)="changeFilter($event)"
          [options]="taskStationFilterOptions"
          [selectedValue]="activeTaskStationFilter()"
        />
        <mat-form-field appearance="outline" class="search-input">
          <input [(ngModel)]="query" matInput placeholder="Suchen" type="text" />
        </mat-form-field>
      </div>

      <div class="filter-container">
        <tgn-task-filter
          (onFilterChange)="this.updateFilter($event)"
          [filter]="filter"
          [showTaskStatusFilter]="true"
          [taskGroupRestriction]="TaskCategoryGroup.Care"
        />
      </div>

      <tgn-scrollable-table>
        <table #table="matTable" [dataSource]="datasource" mat-table>
          <tr *matHeaderRowDef="columns" mat-header-row></tr>
          <tr (click)="openTask(task)" *matRowDef="let task; table: table; columns: columns" class="table-row-link" mat-row></tr>

          <ng-container matColumnDef="box">
            <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.box' | translate }}</th>
            <td *matCellDef="let task; table: table" mat-cell>
              @if (isAnimalTask(task)) {
                {{ task.animal.box?.name ?? '' }}
              } @else if (isBoxTask(task)) {
                {{ task.box.name }}
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="trdId">
            <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.trdId' | translate }}</th>
            <td *matCellDef="let task; table: table" mat-cell>
              @if (isAnimalTask(task)) {
                {{ task.animal.trdId }}
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="entityType">
            <th *matHeaderCellDef mat-header-cell>{{ 'PAGE.TASKS.LABEL.entityType' | translate }}</th>
            <td *matCellDef="let task; table: table" mat-cell>
              @if (isAnimalTask(task)) {
                <div class="entity-display">
                  <label class="emphasizedLabel">{{ 'GENERAL.DOMAIN.ANIMAL' | translate }}:</label>
                  <span class="max-width-column-smaller"><tgn-single-line-text [text]="task.animal.name ?? task.animal.trdId" /></span>
                </div>
              }

              @if (isBoxTask(task)) {
                <div class="entity-display">
                  <label class="emphasizedLabel">{{ 'GENERAL.DOMAIN.BOX' | translate }}:</label>
                  <span class="max-width-column-smaller">
                    <tgn-single-line-text [text]="task.box.name" />
                  </span>
                </div>
              }

              @if (isStationTask(task)) {
                <div class="entity-display">
                  <label class="emphasizedLabel">{{ 'GENERAL.DOMAIN.STATION' | translate }}:</label>
                  <span class="max-width-column-smaller"><tgn-single-line-text [text]="task.station.name" /></span>
                </div>
              }

              @if (isCaseTask(task)) {
                <div class="entity-display">
                  <label class="emphasizedLabel">{{ 'GENERAL.DOMAIN.CASE' | translate }}:</label>
                  <span class="max-width-column-smaller">
                    <tgn-single-line-text [text]="task.case.caseNumber + (task.case.title ?? '')" />
                  </span>
                </div>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="species">
            <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.species' | translate }}</th>
            <td *matCellDef="let task; table: table" mat-cell>
              @if (isAnimalTask(task)) {
                <span class="max-width-column">
                  <tgn-single-line-text [text]="task.animal.species?.name ?? ''" />
                </span>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="behavior">
            <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.behaviour' | translate }}</th>
            <td *matCellDef="let task; table: table" mat-cell>
              @if (isAnimalTask(task)) {
                <div class="max-width-column">
                  <tgn-single-line-text class="breadcrumb-link" [text]="task.animal.behaviour ?? ''"></tgn-single-line-text>
                </div>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.status' | translate }}</th>
            <td *matCellDef="let task; table: table" mat-cell>
              <span class="icon-text">
                {{ task.status | enumDisplay: 'GENERAL.DOMAIN.TaskStatus' }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="terminationDate">
            <th *matHeaderCellDef mat-header-cell>
              <span class="no-wrap">{{ 'ENTITY.Task.terminationDate' | translate }}</span>
            </th>
            <td *matCellDef="let task; table: table" mat-cell>
              <span>{{ task.terminationDate | date: 'dd.MM.yyyy' }} {{ task.terminationTime ?? '' }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th *matHeaderCellDef mat-header-cell>{{ 'PAGE.CARE_TASK.TABLE.LABEL_TASK_TITLE' | translate }}</th>
            <td *matCellDef="let task; table: table" mat-cell>
              <div class="max-width-column">
                <tgn-single-line-text [text]="task.title" />
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.description' | translate }}</th>
            <td *matCellDef="let task; table: table" mat-cell>
              <div class="overflow-column">
                <tgn-single-line-text [text]="task.description | stripRichText" />
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="category">
            <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.taskCategory' | translate }}</th>
            <td *matCellDef="let task; table: table" mat-cell>{{ task.taskCategory.name }}</td>
          </ng-container>

          <ng-container matColumnDef="completionUser">
            <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Task.completionUser' | translate }}</th>
            <td *matCellDef="let task; table: table" mat-cell>
              @if (task.completionUser) {
                <div class="visum-column">
                  <tgn-single-line-text [text]="(task.completionUser | fullName) ?? ''" />
                </div>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.BUTTON.MENU_ACTIONS' | translate }}</th>
            <td *matCellDef="let task; table: table" mat-cell>
              <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ task: task }" [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <ng-template let-task="task" matMenuContent>
                  <button (click)="moveTask(task)" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" mat-menu-item>
                    <span>{{ 'GENERAL.BUTTON.MOVE' | translate }}</span>
                  </button>
                  <button (click)="copyTask(task)" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" mat-menu-item>
                    <span>{{ 'GENERAL.BUTTON.COPY' | translate }}</span>
                  </button>
                  <button (agreeEvent)="deleteTask(task)" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" appConfirmationDialog mat-menu-item>
                    <span>{{ 'GENERAL.BUTTON.DELETE' | translate }}</span>
                  </button>
                </ng-template>
              </mat-menu>
            </td>
          </ng-container>
        </table>

        <tgn-paginator
          (onPageEvent)="pageChanged($event)"
          [maxItemCount]="totalItems"
          [maxPages]="totalPages"
          [pageIndex]="pageIndex"
          [pageSize]="pageSize"
          class="paginator"
          paginator
        ></tgn-paginator>
      </tgn-scrollable-table>
    </mat-tab>
    <mat-tab label="Tiere">
      <tgn-care-animals-list />
    </mat-tab>
  </mat-tab-group>
</div>
