import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import { AnimalId } from '@animal/dtos/animal.dto';
import { CaseId } from '@case/dtos/case.dto';
import { ContactId } from '@contact/dto/contact-list-view.dto';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ReplaySubject, tap } from 'rxjs';

import { AuditChangeUnion } from '../../dtos/auditChange';
import { AuditChangeEntityPaginatedParams, AuditService } from '../../services/audit.service';
import { AuditChangeComponent } from '../audit-change/audit-change.component';

interface TableConfig {
  pageIndex: number;
  pageSize: number;
  entityId: AnimalId | CaseId | ContactId | null;
}

const pageSize = 50;

@Component({
  selector: 'tgn-audit-view',
  standalone: true,
  imports: [AsyncPipe, AuditChangeComponent, MatButton],
  templateUrl: './audit-view.component.html',
  styleUrl: './audit-view.component.scss',
})
export class AuditViewComponent implements OnInit {
  @Input({ required: true }) entityId?: AnimalId | CaseId | ContactId;
  dataSource!: PagedTigonDatasource<AuditChangeUnion, TableConfig>;

  changes$: ReplaySubject<AuditChangeUnion[]> = new ReplaySubject<AuditChangeUnion[]>(1);
  changes: AuditChangeUnion[] = [];

  hasMore = false;

  pageIndex = 0;

  private readonly destroyRef = inject(DestroyRef);

  constructor(private auditService: AuditService) {}

  ngOnInit() {
    this.dataSource = new PagedTigonDatasource<AuditChangeUnion, TableConfig>(
      {
        pageIndex: this.pageIndex,
        pageSize: pageSize,
        entityId: this.entityId ?? null,
      },
      config => {
        if (config.entityId) {
          const params: AuditChangeEntityPaginatedParams = {
            pageIndex: config.pageIndex,
            pageSize: config.pageSize,
            filter: {
              entityId: config.entityId,
            },
          };
          return this.auditService.getAuditChangesByEntity(params);
        }
        return this.auditService.getAuditChanges(config);
      },
      this.destroyRef,
    );

    this.changes$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(changes => {
      this.changes = changes;
    });

    this.dataSource
      .getData()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        this.changes$.next([...this.changes, ...data]);
      });

    this.dataSource.page$.pipe(tap(page => (this.hasMore = page.pageIndex < page.totalPages - 1))).subscribe();
  }

  loadMore() {
    this.pageIndex = this.pageIndex + 1;
    this.dataSource.update({
      pageIndex: this.pageIndex,
    });
  }
}
