import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { IconComponent } from '@core/components/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';

import { Filter } from '../model/task-filter';

interface FilterOption<T> {
  label: string;
  object: T;
}

@Component({
  selector: 'tgn-single-filter-list',
  standalone: true,
  imports: [IconComponent, TranslateModule, ReactiveFormsModule, MatFormField, MatInput],
  templateUrl: './single-filter-list.component.html',
  styleUrl: './single-filter-list.component.scss',
})
export class SingleFilterListComponent<T, Id extends string> implements OnInit {
  @Input({ required: true }) options!: FilterOption<T>[];
  filteredOptions!: FilterOption<T>[];
  @Input({ required: true }) filter!: Filter<T, Id>;
  @Input() searchable = false;
  @Output() onFilterChange = new EventEmitter<Filter<T, Id>>();
  searchInput!: FormControl<string | null>;
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.searchInput = new FormControl(null);
    this.filteredOptions = this.options;

    this.searchInput.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value === null || value === '') {
        this.filteredOptions = this.options;
        return;
      }
      this.filteredOptions = this.options.filter(option => option.label.toLowerCase().includes(value.toLowerCase()));
    });
  }

  toggleState(item: T) {
    const filterValues = this.filter.values;
    let newFilter: Filter<T, Id>;
    if (this.filter.values.includes(item)) {
      newFilter = {
        values: filterValues.filter(value => value !== item),
        id: this.filter.id,
      };
    } else {
      newFilter = {
        values: [...filterValues, item],
        id: this.filter.id,
      };
    }

    this.onFilterChange.emit(newFilter);
  }
}
