@if (animal$ | async; as animal) {
  <tgn-animal-header [animal]="animal" [baseData]="baseData$ | async" [context]="{ type: AnimalViewContext.Care, case: caseDetail }" />
}

<div *tgnContextActions class="tgn-context-actions">
  <button (click)="createNewTask()" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.ACTION.NEW_TASK' | translate }}
  </button>

  <button
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="menu"
    [tgnRoleRestriction]="{ exclude: [UserRole.Veterinarian] }"
    class="tgn-button"
    mat-flat-button
  >
    {{ 'PAGE.ANIMAL.DETAIL.ACTIONS.DOWNLOAD' | translate }}
  </button>

  <mat-menu #menu="matMenu">
    <ng-template matMenuContent>
      <button (click)="downloadBoxCard()" [tgnRoleRestriction]="EXCLUDE_VETERINARIAN" mat-menu-item>
        <span>{{ 'PAGE.ANIMALS.ACTIONS.DOWNLOAD_BOX_CARD' | translate }}</span>
      </button>
      <button (click)="downloadAdmissionForm()" [tgnRoleRestriction]="EXCLUDE_VETERINARIAN" mat-menu-item>
        <span>{{ 'PAGE.ANIMALS.ACTIONS.DOWNLOAD_ADMISSION_FORM' | translate }}</span>
      </button>
    </ng-template>
  </mat-menu>
</div>

@if (form) {
  <div class="content">
    <div class="form-grid grid-cols-6">
      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.behaviour" id="behaviour" [label]="'ENTITY.Animal.behaviour' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [formControl]="control"
                  [id]="id"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                  [placeholder]="'ENTITY.Animal.PLACEHOLDER.behaviour' | translate"
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="form.controls.feedingHabits" id="feedingHabits" [label]="'ENTITY.Animal.feedingHabits' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [formControl]="control"
                  [id]="id"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                  [placeholder]="'ENTITY.Animal.PLACEHOLDER.feedingHabits' | translate"
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    </div>

    <div class="row subsection mt-large">
      <div class="item col-span-6">
        <h4>{{ 'ENTITY.Animal.specials' | translate }}</h4>
        <p [innerHTML]="animal?.specials ?? 'GENERAL.FORM.PLACEHOLDER.NONE' | translate"></p>
      </div>
    </div>

    <div class="row subsection mt-large">
      <div class="item col-span-6 fact-container">
        <h4 class="emphasizedLabel">{{ 'ENTITY.Case.entryDate' | translate }}:</h4>
        <span>{{ caseDetail?.entryDate | date: 'dd.MM.yyyy' }}</span>
      </div>
    </div>

    <tgn-collapsible [expanded]="false" [title]="'PAGE.ANIMAL_CARE.SECTION_TITLE_ADDITIONAL_ANIMAL_INFOS' | translate" class="mt-large">
      <div class="form-grid grid-cols-6">
        <div class="row row-span-sm-3">
          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="form.controls.race" id="race" label="Rasse">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-animal-race-input [control]="form.controls.race" [taxonomy]="animal$ | async" />
              </ng-template>
            </tgn-form-element>
          </div>

          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="form.controls.raceNote" id="raceNote" [label]="'ENTITY.Animal.raceNote' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>

          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="form.controls.coloring" id="colouring" [label]="'ENTITY.Animal.coloring' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="form.controls.chipId" id="chipId" [label]="'ENTITY.Animal.chipId' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>

          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="form.controls.ringId" id="ringId" [label]="'ENTITY.Animal.ringId' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="form.controls.birthdate" id="birthdate" [label]="'ENTITY.Animal.birthdate' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-date-input [control]="control" [id]="id"></tgn-date-input>
              </ng-template>
            </tgn-form-element>
          </div>

          <div class="item col-span-2 col-span-sm-6">
            <tgn-form-element [control]="form.controls.sex" id="sex" [label]="'ENTITY.Animal.sex' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-select [choices]="sexOptions" [control]="control" [id]="id"></tgn-select>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        @if (animal?.type === AnimalType.DomesticAnimal) {
          <div class="row">
            <div class="item col-span-2 col-span-sm-6">
              <tgn-form-element [control]="form.controls.guessedAge" id="guessedAge" [label]="'Schätzung Alter'">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <input matInput [formControl]="control" [id]="id" type="text" />
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        }

        <div class="row">
          <div class="item col-span-4 col-span-sm-6">
            <tgn-form-element
              [control]="form.controls.accessories"
              [id]="'accessories'"
              [label]="'ENTITY.EntryCheck.accessories'"
              [optional]="false"
            >
              <tgn-chip-input [formControl]="form.controls.accessories" />
            </tgn-form-element>
          </div>
        </div>
      </div>
    </tgn-collapsible>

    <div class="form-grid grid-cols-8 grid-cols-md-6">
      @if (animal?.type === AnimalType.WildAnimal && caseDetail) {
        <tgn-wild-animal-statistics [caseDto]="caseDetail" [id]="'statistics'" />
      }
    </div>

    <div class="tasks-section">
      <h2>{{ 'PAGE.ANIMAL_CARE.SECTION_TITLE_TASKS' | translate }}</h2>
      @if (animal$ | async; as animal) {
        <tgn-animal-tasks-table
          [openTask$]="openTask$"
          [refresh]="refreshTasks"
          [animal]="animal"
          [includeLatestCaseTasks]="false"
          [taskCategoryGroups]="[TaskCategoryGroup.Care]"
        />
      }
    </div>
  </div>
}
