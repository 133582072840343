import { NgTemplateOutlet } from '@angular/common';
import { Component, Directive, HostListener, Input, OnInit, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[tgnStep]',
  exportAs: 'tgnStep',
  standalone: true,
})
export class StepDirective implements OnInit {
  @Input('tgnStepInitial') initial = false;

  constructor(
    readonly stepView: StepViewComponent,
    readonly template: TemplateRef<unknown>,
  ) {}

  ngOnInit() {
    if (this.initial) this.stepView.show(this.template);
  }
}

@Directive({
  selector: '[tgnNextStep]',
  standalone: true,
})
export class NextStepDirective {
  @Input('tgnNextStep') nextStep!: StepDirective;

  constructor(readonly stepView: StepViewComponent) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.stepView.show(this.nextStep.template);
  }
}

@Component({
  selector: 'tgn-step-view',
  standalone: true,
  imports: [NgTemplateOutlet],
  templateUrl: './step-view.component.html',
  styleUrl: './step-view.component.scss',
})
export class StepViewComponent {
  template: TemplateRef<unknown> | undefined = undefined;

  show(template: TemplateRef<unknown>) {
    this.template = template;
  }
}
