import { DestroyRef, Directive, ElementRef, Input, OnInit, Renderer2, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

export interface Archivable {
  archived: boolean;
}

export interface ContextItem {
  object?: Observable<Archivable>;
}

@Directive({
  selector: '[tgnContextItem]',
  standalone: true,
})
export class ContextItemDirective implements OnInit {
  @Input({ alias: 'tgnContextItem', required: true }) contextItem: ContextItem | undefined | null;

  destroyRef = inject(DestroyRef);

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.contextItem?.object?.pipe(takeUntilDestroyed(this.destroyRef))?.subscribe(object => {
      if (object?.archived) {
        this.hideElement();
      }
    });
  }

  hideElement() {
    this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
  }
}
