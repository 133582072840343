import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAnchor } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { WildAnimalStatisticsComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics.component';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { AnimalTitlePipe } from '@animal/pipes/animal-title.pipe';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseGeneralPlacementDto } from '@case/dtos/placement.dto';
import { CaseAnimalService } from '@case/services/case-animal.service';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { SelectComponent } from '@core/components/select/select.component';
import { routes_config } from '@core/constants';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { defaultDebounce } from '@core/services/base-service';
import { IsoLocalDateString } from '@core/utils/date';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { take } from 'rxjs';

type WildAnimalPlacementForm = FormGroup<{
  exitDate: FormControl<IsoLocalDateString | null>;
  exitNote: FormControl<string | null>;
}>;

@Component({
  selector: 'tgn-single-wild-animal-placement',
  standalone: true,
  imports: [
    AnimalTitlePipe,
    CollapsibleComponent,
    WildAnimalStatisticsComponent,
    MatAnchor,
    TranslateModule,
    RouterLink,
    AddIfMissingPipe,
    AsyncPipe,
    CdkTextareaAutosize,
    DateInputComponent,
    FormElementComponent,
    FormElementDirective,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    SelectComponent,
  ],
  templateUrl: './single-wild-animal-placement.component.html',
  styleUrl: './single-wild-animal-placement.component.scss',
})
export class SingleWildAnimalPlacementComponent implements OnInit {
  @Input({ required: true }) animal!: AnimalDto;
  @Input({ required: true }) caseDto!: CaseDetailDto;
  placementForm?: WildAnimalPlacementForm;
  protected readonly appRoutes = routes_config;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private formBuilder: FormBuilder,
    private caseAnimalService: CaseAnimalService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.caseAnimalService
      .getGeneralCasePlacement(this.caseDto.id, this.animal.id)
      .pipe(take(1))
      .subscribe((placement: CaseGeneralPlacementDto) => {
        this.createPlacementForm(placement);
        this.autoSavePlacement();
      });
  }

  private autoSavePlacement() {
    this.placementForm!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
      const formValues = this.placementForm!.getRawValue();
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.caseAnimalService.updateWildAnimalExitState(this.caseDto.id, this.animal.id, formValues).subscribe(() => {});
    });
  }

  private createPlacementForm(placement: CaseGeneralPlacementDto) {
    const fb = this.formBuilder;

    const formValues = {
      exitDate: fb.control<IsoLocalDateString | null>(placement.exitDate),
      exitNote: fb.control<string | null>(placement.exitNote),
    };
    this.placementForm = fb.group(formValues);

    if (this.caseDto.archived) {
      this.placementForm.disable();
    }

    this.accessService.disableBasedOnRole(this.placementForm, RestrictedSection.Case);
  }
}
