<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="optionSelected($event)"
  [displayWith]="displayInputValue"
  class="tgn-autocomplete"
>
  @for (option of options; track option) {
    <mat-option [value]="option">{{ option.label }}</mat-option>
  }
  @if (additionalAction) {
    <mat-optgroup [label]="additionalActionGroup">
      <mat-option [value]="null">{{ additionalAction }}</mat-option>
    </mat-optgroup>
  }
</mat-autocomplete>

<div class="input-container item-1">
  <mat-form-field appearance="outline" class="autocomplete-input">
    <input
      [disabled]="disabled"
      [formControl]="internalControl"
      [id]="id ?? ''"
      [matAutocomplete]="auto"
      [ngClass]="{ locked: locked }"
      [placeholder]="placeholder | translate"
      [readonly]="locked"
      matInput
      type="text"
    />
  </mat-form-field>

  @if (locked && allowRemoval && !disabled) {
    <button type="button" (click)="onRemoveSelected.emit()" class="remove-btn">
      <tgn-icon [iconName]="'radixCross2'" />
    </button>
  }
</div>
