import { AsyncPipe, NgClass } from '@angular/common';
import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatChip } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { TaskService } from '@care/services/task.service';
import { FilterOption, OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { SelectComponent } from '@core/components/select/select.component';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { EXCLUDE_VETERINARIAN } from '@core/directives/role-restriction.directive';
import { UserRole } from '@core/models/general';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CurrentUserService } from '@user/service/current-user.service';
import { Observable, tap } from 'rxjs';

import { NotificationFilterComponent } from '../components/notification-filter/notification-filter.component';
import { NotificationComponent } from '../components/notification/notification.component';
import { NotificationDto } from '../dto/notification.dto';
import { NotificationFilter, NotificationTimePeriod } from '../model/notification-filter';
import { NotificationService } from '../services/notification.service';

export interface NotificationTableConfig {
  pageIndex: number;
  pageSize: number;
}

const ROLES_WITH_NOTIFICATIONS = [UserRole.Office, UserRole.VeterinaryMedicine, UserRole.VerantwortlicheHundewesen];

@Component({
  selector: 'app-notification-page',
  standalone: true,
  imports: [
    MatChip,
    NgClass,
    PaginatorComponent,
    AsyncPipe,
    NotificationComponent,
    OptionButtonComponent,
    TranslateModule,
    NotificationFilterComponent,
    SelectComponent,
    MatMenu,
    MatMenuContent,
    MatIcon,
    MatIconButton,
    MatMenuTrigger,
    MatMenuItem,
    ConfirmationDialogDirective,
  ],
  templateUrl: './notification-page.component.html',
  styleUrl: './notification-page.component.scss',
})
export class NotificationPageComponent implements OnInit {
  datasource?: PagedTigonDatasource<NotificationDto, NotificationTableConfig>;

  notificationTimePeriodChoices = createEnumChoices(NotificationTimePeriod, 'GENERAL.DOMAIN.NotificationTimePeriod.');

  timePeriodControl!: FormControl<NotificationTimePeriod>;

  notifications$?: Observable<NotificationDto[]>;

  filter: NotificationFilter = NotificationFilter.empty().setTimePeriodFilter({
    values: [NotificationTimePeriod.Week],
    id: 'timePeriodFilter',
  });

  activeFilter = signal<UserRole | null>(null);

  pagination = {
    pageSize: 50,
    pageIndex: 0,
    totalItems: 0,
    totalPages: 0,
  };

  options: FilterOption<UserRole | null>[] = [];
  protected readonly destroyRef = inject(DestroyRef);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly EXCLUDE_VETERINARIAN = EXCLUDE_VETERINARIAN;

  constructor(
    private notificationService: NotificationService,
    private currentUserService: CurrentUserService,
    private translate: TranslateService,
    private taskService: TaskService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.timePeriodControl = this.fb.nonNullable.control(NotificationTimePeriod.Week);

    this.timePeriodControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.filter = this.filter.setTimePeriodFilter({ values: [this.timePeriodControl.value], id: 'timePeriodFilter' });
      this.datasource?.update();
    });

    this.currentUserService.currentUser$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
      this.setFilterBasedOnUserRole(user.usedRole);

      this.datasource = new PagedTigonDatasource<NotificationDto, NotificationTableConfig>(
        {
          pageSize: this.pagination.pageSize,
          pageIndex: this.pagination.pageIndex,
        },
        params => {
          return this.notificationService
            .getNotificationsPaginated({
              pageIndex: params.pageIndex,
              pageSize: params.pageSize,
              filter: this.filter,
            })
            .pipe(
              tap(page => {
                this.pagination.totalItems = page.totalItems;
                this.pagination.totalPages = page.totalPages;
              }),
            );
        },
        this.destroyRef,
      );

      this.notifications$ = this.datasource.getData();

      const roleOptions = user.roles
        .filter(it => ROLES_WITH_NOTIFICATIONS.includes(it))
        .map(role => ({
          value: role,
          label: this.translate.instant(`GENERAL.DOMAIN.UserRole.${role}`),
        }));

      let options: FilterOption<UserRole | null>[] = roleOptions;
      if (roleOptions.length > 0) {
        options = [
          {
            value: null,
            label: 'Alle',
          },
          ...roleOptions,
        ];
      }

      this.options = options;

      this.datasource.update({});
    });

    this.currentUserService.usedRole$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(role => {
      this.setFilterBasedOnUserRole(role);
      this.datasource?.update({});
    });
  }

  pageChanged($event: PageEvent) {
    this.pagination.pageIndex = $event.pageIndex;
    this.pagination.pageSize = $event.pageSize;
    this.datasource?.update({
      pageIndex: $event.pageIndex,
      pageSize: $event.pageSize,
    });
  }

  changeFilteredRole(userRole: UserRole | null) {
    this.filter = this.filter.setUserRoleFilter({ values: userRole ? [userRole] : [], id: 'userRoleFilter' });
    this.activeFilter.set(userRole ?? null);
    this.datasource?.update({});
  }

  refreshDatasource() {
    this.datasource?.update({});
  }

  filterChange($event: NotificationFilter) {
    this.filter = $event;
    this.datasource?.update({});
  }

  markAllAsRead() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.notificationService.markAllAsRead().subscribe(() => {});
  }

  private setFilterBasedOnUserRole(usedRole: UserRole | null) {
    if (usedRole && ROLES_WITH_NOTIFICATIONS.includes(usedRole)) {
      this.filter = this.filter.setUserRoleFilter({ values: [usedRole], id: 'userRoleFilter' });
      this.activeFilter.set(usedRole);
    } else {
      this.activeFilter.set(null);
      this.filter = this.filter.setUserRoleFilter({ values: [], id: 'userRoleFilter' });
    }
  }
}
