<h1 class="title" mat-dialog-title>
  @if (this.taskData.mode === 'move') {
    {{ 'PAGE.CARE_TASK.MOVE_OR_COPY_DIALOG.TITLE_MOVE' | translate }}
  } @else {
    {{ 'PAGE.CARE_TASK.MOVE_OR_COPY_DIALOG.TITLE_COPY' | translate }}
  }
</h1>

<div mat-dialog-content>
  <div class="mb-small">
    <span>
      @if (taskData.mode === 'move') {
        {{ 'PAGE.CARE_TASK.MOVE_OR_COPY_DIALOG.DESCRIPTION_MOVE' | translate: { title: taskData.task.title } }}
      } @else {
        {{ 'PAGE.CARE_TASK.MOVE_OR_COPY_DIALOG.DESCRIPTION_COPY' | translate: { title: taskData.task.title } }}
      }
    </span>
  </div>

  <div class="choice-container mb-medium">
    <tgn-radio-group [choices]="taskTypeChoices" [control]="entityForm.controls.entityType"></tgn-radio-group>
  </div>

  @if (entityForm.controls.entityType.value !== null) {
    <tgn-autocomplete
      (onOptionSelected)="setEntity($event)"
      (onRemoveSelected)="removeEntity()"
      [displayFn]="displaySearchResultFn"
      [valueFn]="searchResultValueFn"
      [placeholder]="'Suche'"
      [value]="entityForm.controls.entity.controls.name.value"
      [viewStore]="searchAutocompleteStore"
      [locked]="entityForm.controls.entity.value.entityId !== null"
    ></tgn-autocomplete>
  }

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>
      {{ 'GENERAL.BUTTON.CANCEL' | translate }}
    </button>

    @if (entityForm.controls.entity.value.entityId !== null && entityForm.controls.entityType.value !== null) {
      <button
        (click)="moveOrCopyTask()"
        class="tgn-button"
        mat-flat-button
        [disabled]="!entityForm.controls.entityType.value || !entityForm.controls.entity.value.entityId"
      >
        {{ (this.taskData.mode === 'move' ? 'GENERAL.BUTTON.MOVE' : 'GENERAL.BUTTON.COPY') | translate }}
      </button>
    }
  </div>
</div>
