import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { DateInputComponent, DatePickerType } from '@core/components/date-input/date-input.component';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { ModalComponent } from '@core/services/modal.service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';

import { FetchStatisticsDto } from '../../dto/statistics.dto';
import { DateFilterChoice } from '../../pages/statistics-page/statistics-page.component';
import { StatisticsService } from '../../services/statistics.service';

interface DialogData {
  filter: StatisticsFilterValues;
}

interface DialogResult {}

type StatisticsExportForm = FormGroup<{
  filter: FormControl<DateFilterChoice | null>;
  startDate: FormControl<IsoLocalDateString | null>;
  endDate: FormControl<IsoLocalDateString | null>;
  day: FormControl<IsoLocalDateString | null>;
  month: FormControl<IsoLocalDateString | null>;
  year: FormControl<IsoLocalDateString | null>;
}>;

interface StatisticsFilterValues {
  filter: DateFilterChoice | null;
  startDate: IsoLocalDateString | null;
  endDate: IsoLocalDateString | null;
  day: IsoLocalDateString | null;
  month: IsoLocalDateString | null;
  year: IsoLocalDateString | null;
}

@Component({
  selector: 'app-statistic-export-dialog',
  standalone: true,
  imports: [DateInputComponent, FormElementComponent, MatDialogModule, TranslateModule, SelectComponent, MatButton, MatProgressSpinner],
  templateUrl: './statistic-export-dialog.component.html',
  styleUrl: './statistic-export-dialog.component.scss',
})
export class StatisticExportDialogComponent extends ModalComponent<DialogData, DialogResult> implements OnInit {
  form!: StatisticsExportForm;
  dateFilterChoices: RadioChoice<DateFilterChoice>[] = createEnumChoices(DateFilterChoice, 'GENERAL.DOMAIN.DateFilterChoice.');
  exportData!: DialogData;
  isLoading = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly DatePickerType = DatePickerType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly DateFilterChoice = DateFilterChoice;

  constructor(
    dialogRef: MatDialogRef<StatisticExportDialogComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private statisticsService: StatisticsService,
    private fb: FormBuilder,
  ) {
    super(dialogRef);
    this.exportData = data;
  }

  ngOnInit() {
    this.form = this.fb.group({
      filter: this.fb.control<DateFilterChoice | null>(this.exportData.filter.filter, []),
      startDate: this.fb.control<string | null>(this.exportData.filter.startDate),
      endDate: this.fb.control<string | null>(this.exportData.filter.endDate),
      day: this.fb.control<string | null>(this.exportData.filter.day),
      month: this.fb.control<string | null>(this.exportData.filter.month),
      year: this.fb.control<string | null>(this.exportData.filter.year),
    });
  }

  export() {
    const dto: FetchStatisticsDto = this.form.getRawValue();
    this.isLoading = true;
    this.statisticsService
      .exportStatistics(dto)
      .pipe(take(1))
      .subscribe({
        error: () => {
          this.isLoading = false;
          this.closeWithResult(undefined);
        },
        next: (response: HttpResponse<Blob>) => {
          const url = window.URL.createObjectURL(response.body!);
          const a = document.createElement('a');
          a.href = url;

          a.download = response.headers.get('Content-Disposition')!.split('filename=')[1];
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          this.isLoading = false;
          this.closeWithResult({});
        },
      });
  }
}
