<div class="menu-item-list">
  @if (searchable) {
    <mat-form-field appearance="outline">
      <input
        matInput
        class="search-input"
        type="text"
        placeholder="{{ 'COMPONENT.FILTER.PLACEHOLDER.SEARCH' | translate }}"
        [formControl]="searchInput"
      />
    </mat-form-field>
  }
  @for (option of filteredOptions; track $index) {
    <button class="step-button" (click)="toggleState(option.object)">
      {{ option.label }}
      @if (filter.values.includes(option.object)) {
        <tgn-icon [iconName]="'radixCheck'" />
      }
    </button>
  }
</div>
