<mat-card>
  <mat-card-content>
    <div class="card-container">
      <div class="info-msg">
        <tgn-icon iconName="radixMagicWand" style="font-size: 22px" />
        <span class="ml-large">Wird noch bearbeitet</span>
      </div>

      <div class="link-container mt-large">
        <span>Siehe</span>

        <a [routerLink]="appRoutes.CASES.url()" class="mt-large" mat-button>Fallerstellung</a>

        <a [routerLink]="appRoutes.ANIMALS.url()" class="mt-large" mat-button>Tierübersicht</a>
      </div>
    </div>
  </mat-card-content>
</mat-card>
