<div class="stepper-container">
  <div class="stepper-header stepper-scrolltarget">
    <ol>
      @for (step of steps; track step) {
        <ng-container>
          <li
            (click)="selectedIndex = $index"
            [ngClass]="{
              active: selectedIndex === $index,
              underlined: $index <= selectedIndex
            }"
          >
            <a class="stepper-link">
              {{ step.label }}
            </a>
          </li>
        </ng-container>
      }
    </ol>
  </div>

  <div [ngTemplateOutlet]="selected?.content ?? null" class="stepper-content"></div>

  <div class="button-container">
    <button
      (click)="goToPrevious()"
      [disabled]="selectedIndex === 0 && !showPreviousInFirstStep"
      [ngClass]="{ hidden: selectedIndex === 0 && !showPreviousInFirstStep }"
      mat-flat-button
    >
      @if (showPreviousInFirstStep && selectedIndex === 0) {
        {{ firstPreviousLabel ?? 'GENERAL.BUTTON.PREVIOUS' | translate }}
      } @else {
        {{ 'GENERAL.BUTTON.PREVIOUS' | translate }}
      }
    </button>

    @if (selectedIndex !== steps.length - 1) {
      <button (click)="next()" class="tgn-button" mat-flat-button [disabled]="continueDisabled">
        {{ 'GENERAL.BUTTON.NEXT' | translate }}
      </button>
    }
    @if (lastActionLabel !== undefined && selectedIndex === steps.length - 1) {
      <button (click)="onLastAction.emit()" class="tgn-button" mat-flat-button [disabled]="continueDisabled">
        {{ lastActionLabel | translate }}
      </button>
    }
  </div>
</div>
