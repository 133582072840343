import { RoleRestriction } from '@core/models/role';

import { AvailableIcons } from './provided-icons';

type TranslationKey = string;

export interface NavigationItem {
  id?: NavItemId | null;
  icon?: AvailableIcons;
  iconPath?: string;
  iconAlt?: string;
  text: TranslationKey;
  path: string | string[] | null;
  roleRestriction?: RoleRestriction;
}

export enum NavItemId {
  Inbox = 'Inbox',
}
