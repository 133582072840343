import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterViewChecked, Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { min, take } from 'rxjs';

@Component({
  selector: 'tgn-textarea',
  standalone: true,
  imports: [CdkTextareaAutosize, MatFormField, MatInput, ReactiveFormsModule, TranslateModule],
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss',
})
export class TextareaComponent implements AfterViewChecked {
  @Input({ required: true }) control!: FormControl<string | null>;
  @Input({ required: true }) id!: string;
  @Input() placeholder: string | null = null;
  @Input() minRowsAutosize = 8;
  @Input() maxRowsAutosize = 15;

  // workaround for change detection issue when rendering textarea within cdk stepper
  // somehow the textarea size is not set correctly and only rendered on focus
  // could be a performance issue as the resize trigger is called ever after view checked lifecycle event
  @Input({ required: true }) triggerAutosize!: boolean;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;
  @ViewChild('textarea') textarea!: ElementRef;
  protected readonly min = min;

  constructor(private ngZone: NgZone) {}

  ngAfterViewChecked() {
    if (this.triggerAutosize) {
      this.triggerResize();
    }
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
}
