<h1 class="title" mat-dialog-title>{{ 'PAGE.AddStationDialog.TITLE' | translate }}</h1>

<div mat-dialog-content>
  <div class="form-grid grid-cols-6">
    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="stationForm.controls.name"
          [hideError]="stationForm.controls.name.pristine"
          [id]="'name'"
          [label]="'ENTITY.Station.name' | translate"
          class="row-span-3"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" matInput type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>
  <button (click)="save()" [disabled]="stationForm.invalid" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.BUTTON.SAVE' | translate }}
  </button>
</div>
