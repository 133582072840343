<tgn-collapsible [expanded]="true" [title]="animal | animalTitle" class="input-bg-white" variant="withBgColor">
  <div class="form-grid grid-cols-6">
    <tgn-wild-animal-statistics [caseDto]="caseDto" [variantDarker]="true" />

    <div class="row">
      <div class="item col-span-6">
        <h3 class="section-title">{{ 'PAGE.CASE.PLACEMENT.LABEL.EXIT' | translate }}</h3>
      </div>
    </div>

    @if (placementForm) {
      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.exitDate"
            [id]="'exitDate'"
            [label]="'ENTITY.Placement.exitDate' | translate"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="placementForm.controls.exitNote"
            [id]="'exitNote'"
            [label]="'ENTITY.Placement.exitNote' | translate"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [formControl]="control"
                  [id]="id"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    }

    <div class="row">
      <div class="button-container">
        <a [routerLink]="appRoutes.ANIMAL_DETAIL.url(animal.id)" class="tgn-black-button mt-medium mb-medium" mat-flat-button>
          {{ 'PAGE.CASE.PLACEMENT.ACTION.TO_ANIMAL' | translate }}
        </a>
      </div>
    </div>
  </div>
</tgn-collapsible>
