@if (datasource) {
  <div class="content">
    <tgn-scrollable-table>
      <table #table="matTable" [dataSource]="datasource" mat-table>
        <tr *matHeaderRowDef="columns" mat-header-row></tr>
        <!--@TODO: Platzierungsstatus-->
        <tr
          *matRowDef="let contactAnimal; table: table; columns: columns"
          [routerLink]="appRoutes.ANIMAL_DETAIL_GENERAL.url(contactAnimal.id)"
          class="table-row-link"
          mat-row
        ></tr>

        <ng-container matColumnDef="avatarImage">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.ContactAnimalDto.avatarImage' | translate }}</th>
          <td *matCellDef="let contactAnimal; table: table" mat-cell>
            <tgn-avatar-image [avatar]="contactAnimal" [hideImageUpload]="true" [onUpload]="uploadAnimalAvatar" size="size-lg" />
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.name' | translate }}</th>
          <td *matCellDef="let contactAnimal; table: table" mat-cell>
            <div class="max-width-column">
              <tgn-single-line-text [text]="contactAnimal.name | withDefaultAnimalName" />
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="trdId">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.trdId' | translate }}</th>
          <td *matCellDef="let contactAnimal; table: table" mat-cell>{{ contactAnimal.trdId }}</td>
        </ng-container>

        <ng-container matColumnDef="species">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.species' | translate }}</th>
          <td *matCellDef="let contactAnimal; table: table" mat-cell>{{ contactAnimal.species?.name ?? '' }}</td>
        </ng-container>

        <ng-container matColumnDef="race">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.box' | translate }}</th>
          <td *matCellDef="let contactAnimal; table: table" mat-cell>{{ contactAnimal.race ?? '' }}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.state' | translate }}</th>
          <td *matCellDef="let contactAnimal; table: table" mat-cell>
            {{ contactAnimal.state | enumDisplay: 'GENERAL.DOMAIN.AnimalState' }}
          </td>
        </ng-container>
      </table>

      <tgn-paginator
        paginator
        (onPageEvent)="pageChanged($event)"
        [maxItemCount]="totalItems"
        [maxPages]="totalPages"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        class="paginator"
      ></tgn-paginator>
    </tgn-scrollable-table>
  </div>
}
