import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartData } from 'chart.js';

import { Pair } from '../dto/statistics.dto';

const colors = ['#FFDDDD', '#FF9494', '#FF0000', '#D70000', '#B10303', '#500000', '#bb8481', '#A55D58', '#ca7289', '#D5679C', '#BB81B9'];

function getColor(index: number): string {
  const colorIndex = index % colors.length;
  return colors[colorIndex];
}

@Pipe({
  name: 'chartTransformer',
  standalone: true,
})
export class ChartTransformerPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(entries: Pair<string, number>[], labels: string[]): ChartData {
    return {
      labels,
      datasets: entries.map((entry, index) => {
        return {
          label: this.translate.instant(entry.first),
          data: [entry.second],
          pointBackgroundColor: getColor(index),
          borderColor: getColor(index),
          backgroundColor: getColor(index),
          hoverBackgroundColor: 'grey',
        };
      }),
    };
  }
}
