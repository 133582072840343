import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, sendPasswordResetEmail } from '@angular/fire/auth';
import { UserId } from '@case/dtos/animal-takeover.dto';
import { PageResult } from '@core/data/tigon-datasource';
import { BaseService } from '@core/services/base-service';
import { PaginatedParams } from '@core/utils/pagination';
import { Observable, from } from 'rxjs';

import { InviteUserDto, UpdateUserDto, UserDto } from '../dto/user.dto';

type UserPaginatedParams = PaginatedParams;

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private auth: Auth,
  ) {
    super();
  }

  getUsers(query?: string): Observable<UserDto[]> {
    let params = new HttpParams();

    if (query) {
      params = params.set('query', query);
    }

    return this.httpClient.get<UserDto[]>(`${this.apiUrl}/users`, { params: params });
  }

  userExists(email: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.apiUrl}/users/exists`, { params: { email: email } });
  }

  getUsersPaginated(params: UserPaginatedParams): Observable<PageResult<UserDto>> {
    const flattenedParams = {
      ...params,
      sort: params.sort.map(s => `${s.column}:${s.direction}`).join(':'),
    };

    return this.httpClient.get<PageResult<UserDto>>(`${this.apiUrl}/users/paginated`, { params: flattenedParams });
  }

  inviteUsers(users: InviteUserDto[]): Observable<UserDto[]> {
    return this.httpClient.post<UserDto[]>(`${this.apiUrl}/users/invite`, { users: users });
  }

  updateUser(userId: UserId, user: UpdateUserDto): Observable<UserDto> {
    return this.httpClient.put<UserDto>(`${this.apiUrl}/users/${userId}`, user);
  }

  sendForgotPasswordLink(email: string): Observable<void> {
    const url = window.location.origin;
    return from(sendPasswordResetEmail(this.auth, email, { url: url }));
  }

  sendResetPasswordLink(user: UserDto): Observable<void> {
    const url = window.location.origin;
    return from(sendPasswordResetEmail(this.auth, user.email, { url: url }));
  }

  toggleActive(userId: UserId): Observable<UserDto> {
    return this.httpClient.put<UserDto>(`${this.apiUrl}/users/${userId}/toggle-active`, {});
  }
}
