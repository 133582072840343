<tgn-scrollable-table [showTableIfEmpty]="true">
  @if (newSubmitReasonDtoForm) {
    <div class="overflow-container">
      <form>
        <table #table="matTable" class="table-large" [dataSource]="dataSource" mat-table>
          <tr *matHeaderRowDef="columns" mat-header-row></tr>
          <tr *matRowDef="let submitReason; table: table; columns: columns" class="row-wide" mat-row></tr>
          <tr *matFooterRowDef="columns" class="footer-row" mat-footer-row></tr>

          <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.SubmitReason.name' | translate }}</th>
            <td *matCellDef="let submitReason; table: table; let i = index" mat-cell>
              {{ submitReason.name }}
            </td>

            <td *matFooterCellDef mat-footer-cell>
              <tgn-form-element
                [control]="newSubmitReasonDtoForm.controls.name"
                [hideError]="true"
                [label]="'ENTITY.SubmitReason.name' | translate"
                id="name"
              >
                <mat-form-field appearance="outline" class="full-width">
                  <input [formControl]="newSubmitReasonDtoForm.controls.name" matInput />
                </mat-form-field>
              </tgn-form-element>
            </td>
          </ng-container>

          <ng-container matColumnDef="active">
            <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.SubmitReason.active' | translate }}</th>
            <td *matCellDef="let submitReason; table: table" mat-cell>
              @if (editSubmitReasonDtoFormGroups.get(submitReason.id); as group) {
                <tgn-select [choices]="activeInactiveChoice" [control]="group.controls.active" matInput />
              } @else {
                <div class="input-padding">
                  {{ (submitReason.active ? 'GENERAL.FORM.LABEL.Active' : 'GENERAL.FORM.LABEL.Inactive') | translate }}
                </div>
              }
            </td>
            <td *matFooterCellDef mat-footer-cell></td>
          </ng-container>
        </table>
      </form>

      <mat-error
        [ngClass]="{ invisible: (newSubmitReasonDtoForm.controls.name.value?.length ?? 0) === 0 }"
        class="errors input-padding mt-small mb-small"
      >
        {{ newSubmitReasonDtoForm.controls.name.errors | fieldErrorTranslation | async }}
      </mat-error>

      <button [disabled]="newSubmitReasonDtoForm.invalid" mat-flat-button class="tgn-black-button add-entry-button" (click)="addEntry()">
        {{ 'GENERAL.BUTTON.ADD_ENTRY' | translate }}
      </button>
    </div>
  }
</tgn-scrollable-table>
