<form class="form-grid grid-cols-6">
  <div class="row row-span-sm-3">
    <div class="item col-span-2 col-span-sm-6">
      <tgn-form-element
        [control]="form.controls.hasChip"
        [hideError]="true"
        [id]="'hasChip'"
        [label]="'PAGE.ENTRY_CHECK.FORM.LABEL.CHIP_LABELING'"
        class=""
      >
        <ng-template formElement let-control="control" let-id="id">
          <div class="form-grid grid-cols-2">
            <tgn-radio-group [choices]="yesNoChoices" [control]="control" [id]="id" class="col-span-2" />
          </div>
        </ng-template>
      </tgn-form-element>
    </div>

    <div class="item col-span-2 col-span-sm-6">
      <tgn-form-element
        [control]="form.controls.chipId"
        [id]="'chipId'"
        [label]="'PAGE.ENTRY_CHECK.FORM.LABEL.CHIP'"
        class="col-span-3 col-span-sm-6"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-autocomplete
            (onChange)="updateChipId($event)"
            (onOptionSelected)="replaceAnimal($event)"
            (onRemoveSelected)="removeSelectedAnimal()"
            [allowRemoval]="false"
            [displayFn]="displayAnimalAutocomplete"
            [id]="id"
            [locked]="!currentAnimalContext.caseAnimalDto.animal.isDraft"
            [placeholder]="'PAGE.ENTRY_CHECK.FORM.PLACEHOLDER.CHIP'"
            [valueFn]="getAnimalChipAutocompleteValue"
            [value]="form.controls.chipId.value"
            [viewStore]="animalAutocompleteStore"
          ></tgn-autocomplete>
        </ng-template>
      </tgn-form-element>
    </div>

    <div class="item col-span-2 col-span-sm-6">
      <tgn-form-element [control]="form.controls.ringId" [id]="'chipId'" [label]="'PAGE.ENTRY_CHECK.FORM.LABEL.RING'" class="col-span-3">
        <ng-template formElement let-control="control" let-id="id">
          <tgn-autocomplete
            (onChange)="updateRingId($event)"
            (onOptionSelected)="replaceAnimal($event)"
            (onRemoveSelected)="removeSelectedAnimal()"
            [displayFn]="displayAnimalAutocomplete"
            [id]="id"
            [locked]="!currentAnimalContext.caseAnimalDto.animal.isDraft"
            [placeholder]="'PAGE.ENTRY_CHECK.FORM.PLACEHOLDER.RING'"
            [valueFn]="getAnimalRingAutocompleteValue"
            [value]="form.controls.ringId.value"
            [viewStore]="animalAutocompleteStore"
          ></tgn-autocomplete>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.name"
        [hideError]="true"
        [id]="'name'"
        [label]="'ENTITY.Animal.name'"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Animal.name' | translate" matInput type="text" />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.group"
        [hideError]="true"
        [id]="'group'"
        [label]="'ENTITY.Animal.group'"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-select
            [choices]="taxonomyBaseData.groups | toRadioChoice: 'name'"
            [compareIds]="true"
            [control]="control"
            [id]="id"
            [includeEmptyChoice]="true"
            [searchable]="true"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.subGroup"
        [hideError]="true"
        [id]="'subGroup'"
        [label]="'ENTITY.Animal.subGroup'"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-select
            [choices]="taxonomyBaseData.subGroups | subGroupChoices: form.value.group | toRadioChoice: 'name'"
            [compareIds]="true"
            [control]="control"
            [id]="id"
            [searchable]="true"
            class="subgrid item-1"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.species"
        [hideError]="true"
        [id]="'species'"
        [label]="'ENTITY.Animal.species'"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-select
            [choices]="
              taxonomyBaseData.species
                | addIfMissing: form.controls.species.value
                | speciesChoices: form.value.group : form.value.subGroup : taxonomyBaseData.subGroups
                | toRadioChoice: 'name'
            "
            [compareIds]="true"
            [control]="control"
            [id]="id"
            [includeEmptyChoice]="true"
            [searchable]="true"
            class="subgrid item-1"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element [control]="form.controls.race" [id]="'race'" [label]="'ENTITY.Animal.race'" class="row-span-3 mt-small">
        <ng-template formElement let-control="control" let-id="id">
          <tgn-animal-race-input [control]="form.controls.race" [id]="id" [taxonomy]="form.value | cast" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element [control]="form.controls.raceNote" [id]="'raceNote'" [label]="'ENTITY.Animal.raceNote'" class="row-span-3 mt-small">
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input [formControl]="control" [id]="id" matInput type="text" />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element [control]="form.controls.coloring" [id]="'coloring'" [label]="'ENTITY.Animal.coloring'" class="row-span-3 mt-small">
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Animal.coloring' | translate" matInput type="text" />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.furLength"
        [id]="'furLength'"
        [label]="'ENTITY.Animal.furLength'"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group
            [allowDeselect]="true"
            [choices]="furLengthChoices"
            [control]="control"
            [id]="id"
            class="col-span-3 col-span-sm-1"
          />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.roughAge"
        [hideError]="true"
        [id]="'roughAge'"
        [label]="'ENTITY.Animal.roughAge'"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <tgn-radio-group [choices]="roughAgeChoices" [control]="control" [id]="id" class="col-span-3 col-span-sm-1" />
        </ng-template>
      </tgn-form-element>
    </div>
  </div>

  <div class="row">
    <div class="item col-span-6">
      <tgn-form-element
        [control]="form.controls.guessedAge"
        [id]="'guessedAge'"
        [label]="'ENTITY.Animal.guessedAge'"
        class="row-span-3 mt-small"
      >
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Animal.guessedAge' | translate" matInput type="text" />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>
    </div>
  </div>
</form>
