import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnimalTaxonomyBaseData } from '@animal/model/animal';
import { ExitState } from '@baseData/dtos/exit-state.dto';
import { RelationKindDto } from '@baseData/dtos/relation-kind.dto';
import { TaskCategoryDto } from '@care/dtos/task.dto';
import { AnimalType } from '@core/models/general';
import { BaseService, defaultRetry } from '@core/services/base-service';
import { Observable, ReplaySubject, combineLatest, map, take } from 'rxjs';

import { BaseDataDto, BoxDto, GroupDto, RaceDto, SpeciesDto, StationDto, SubGroupDto, SubmitReasonDto } from '../dtos/base-data.dto';

type BaseDataFetch = [
  GroupDto[],
  SubGroupDto[],
  SpeciesDto[],
  RaceDto[],
  SubmitReasonDto[],
  BoxDto[],
  TaskCategoryDto[],
  StationDto[],
  RelationKindDto[],
  ExitState[],
];

@Injectable({
  providedIn: 'root',
})
export class BaseDataService extends BaseService {
  private baseData$: ReplaySubject<BaseDataDto> = new ReplaySubject<BaseDataDto>(1);

  constructor(private http: HttpClient) {
    super();

    this.fetchBaseData();
  }

  getBaseData(): Observable<BaseDataDto> {
    return this.baseData$;
  }

  getTaxonomy(type: AnimalType): Observable<AnimalTaxonomyBaseData> {
    return this.baseData$.pipe(
      take(1),
      map((baseData: BaseDataDto) => {
        const isDomesticAnimal = type === AnimalType.DomesticAnimal;
        const groups = isDomesticAnimal ? baseData.domesticAnimal.groups : baseData.wildAnimal.groups;
        const subGroups = (isDomesticAnimal ? baseData.domesticAnimal.subGroups : baseData.wildAnimal.subGroups).sort((a, b) =>
          a.name.localeCompare(b.name),
        );

        // both these subgroups have the same species name -> maybe handle in database
        const postfixSgMap = new Map(subGroups.filter(it => it.name == 'Jungvögel' || it.name == 'Wildvögel').map(it => [it.id, it]));

        const species = (isDomesticAnimal ? baseData.domesticAnimal.species : baseData.wildAnimal.species)
          .filter(it => it.active)
          .map(it => {
            const postfixSg = postfixSgMap.get(it.subGroupId);
            if (postfixSg) {
              it.name = it.name + ' (' + postfixSg.name + ')';
            }
            return it;
          })
          .sort((a, b) => a.name.localeCompare(b.name));
        return {
          groups: groups,
          subGroups: subGroups,
          species: species,
        };
      }),
    );
  }

  private fetchBaseData() {
    combineLatest([
      this.http.get<GroupDto[]>(`${this.apiUrl}/master/groups`),
      this.http.get<SubGroupDto[]>(`${this.apiUrl}/master/subGroups`),
      this.http.get<SpeciesDto[]>(`${this.apiUrl}/master/species/active`),
      this.http.get<RaceDto[]>(`${this.apiUrl}/master/races`),
      this.http.get<SubmitReasonDto[]>(`${this.apiUrl}/master/submitReasons/active`),
      this.http.get<BoxDto[]>(`${this.apiUrl}/master/boxes/active`),
      this.http.get<TaskCategoryDto[]>(`${this.apiUrl}/master/taskCategories/active`),
      this.http.get<StationDto[]>(`${this.apiUrl}/master/stations`),
      this.http.get<RelationKindDto[]>(`${this.apiUrl}/master/relationKinds/active`),
      this.http.get<ExitState[]>(`${this.apiUrl}/master/exitStates/active`),
    ])
      .pipe(defaultRetry<BaseDataFetch>())
      .subscribe((value: BaseDataFetch) => {
        const [groups, subGroups, species, races] = [value[0], value[1], value[2], value[3]];

        const wildAnimalGroups = groups.filter(group => group.groupType === 'WildAnimal');
        const wildAnimalSubGroups = subGroups.filter(subGroup => wildAnimalGroups.some(group => group.id === subGroup.groupId));
        const wildAnimalSpecies = species.filter(specie => wildAnimalSubGroups.some(subGroup => subGroup.id === specie.subGroupId));

        const domesticAnimalGroups = groups.filter(group => group.groupType === 'DomesticAnimal');
        const domesticAnimalSubGroups = subGroups.filter(subGroup => domesticAnimalGroups.some(group => group.id === subGroup.groupId));
        const domesticAnimalSpecies = species.filter(specie => domesticAnimalSubGroups.some(subGroup => subGroup.id === specie.subGroupId));

        const baseData: BaseDataDto = {
          domesticAnimal: {
            groups: domesticAnimalGroups,
            subGroups: domesticAnimalSubGroups,
            species: domesticAnimalSpecies,
            races: races,
          },
          wildAnimal: {
            groups: wildAnimalGroups,
            subGroups: wildAnimalSubGroups,
            species: wildAnimalSpecies,
          },
          submitReasons: value[4],
          boxes: value[5],
          taskCategories: value[6],
          stations: value[7],
          relationKinds: value[8],
          exitStates: value[9],
        };
        this.baseData$.next(baseData);
      });
  }
}
