import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { UpdateAnimalStateDto } from '@animal/dtos/update-animal.dto';
import { AnimalService } from '@animal/services/animal.service';
import { BaseDataDto, BoxDto } from '@case/dtos/base-data.dto';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { AnimalState, PlacementState } from '@core/models/general';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { switchMap } from 'rxjs';

interface AnimalFormGroup {
  box: FormControl<BoxDto | null>;
  state: FormControl<AnimalState>;
  placementState: FormControl<PlacementState | null>;
}

@Component({
  selector: 'app-animal-state-switches',
  standalone: true,
  imports: [AsyncPipe, FormsModule, TranslateModule, ReactiveFormsModule, SelectComponent, ToRadioChoicePipe, AddIfMissingPipe],
  templateUrl: './animal-state-switches.component.html',
  styleUrl: './animal-state-switches.component.scss',
})
export class AnimalStateSwitchesComponent implements OnInit {
  @Input({ required: true }) animal!: AnimalDto;
  @Input({ required: true }) baseData!: BaseDataDto;
  @Input({ required: true }) placementState!: PlacementState;

  form!: FormGroup<AnimalFormGroup>;

  destroyRef = inject(DestroyRef);

  animalStates: RadioChoice<AnimalState>[] = createEnumChoices(AnimalState, 'GENERAL.DOMAIN.AnimalState.');
  placementStates: RadioChoice<PlacementState>[] = createEnumChoices(PlacementState, 'GENERAL.DOMAIN.PlacementState.');

  constructor(
    private fb: FormBuilder,
    private animalService: AnimalService,
    private accessService: AccessService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      box: this.fb.control<BoxDto | null>(this.animal.box),
      state: this.fb.nonNullable.control<AnimalState>(this.animal.state),
      placementState: this.fb.control<PlacementState | null>(this.placementState),
    });

    if (this.animal.archived) {
      this.form.disable();
    }

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Animal);

    this.form.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          const value = this.form.getRawValue();
          const dto: UpdateAnimalStateDto = {
            boxId: value.box?.id ?? null,
            state: value.state,
            placementState: value.placementState,
          };

          return this.animalService.updateState(this.animal.id, dto);
        }),
      )
      .subscribe();
  }

  get boxes(): BoxDto[] {
    return this.baseData.boxes;
  }
}
