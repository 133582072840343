import { Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, debounceTime } from 'rxjs';

export interface RadioChoice<T> {
  label: string;
  object: T;
}

interface DeselectData {
  choice: RadioChoice<unknown>;
  value: unknown;
}

@Component({
  selector: 'tgn-radio-group',
  standalone: true,
  imports: [MatRadioModule, TranslateModule, ReactiveFormsModule, MatFormFieldModule],
  templateUrl: './radio-group.component.html',
  styleUrl: './radio-group.component.scss',
})
export class RadioGroupComponent implements OnChanges, OnInit {
  @Input({ required: true }) control!: FormControl;

  @Input({ required: true }) choices!: RadioChoice<unknown>[];
  @Input() allowDeselect = false;

  checkDeselectEmitter: Subject<DeselectData> = new Subject();

  gridItemClass = 'item-4';

  private readonly destroyRef = inject(DestroyRef);

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['choices'];
    if (change !== undefined && change.currentValue != change.previousValue) {
      const choices = change.currentValue as RadioChoice<unknown>[];
      this.gridItemClass = `item-${choices.length}`;
    }
  }

  ngOnInit() {
    // the angular material radio group does not support deselecting a selected radio button
    // this is a workaround to allow deselecting a selected radio button
    // the debounce is necessary as clicking on the label emits the click event twice, while
    // clicking on the radio button emits the click event once. Otherwise the second click event would
    // reset the value again preventing the deselection
    // See: https://github.com/angular/components/issues/27680
    if (this.allowDeselect) {
      this.checkDeselectEmitter.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(400)).subscribe(res => {
        if (res.value === res.choice.object) {
          this.control.setValue(null);
        }
      });
    }
  }
}
