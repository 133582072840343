import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { CompactPlacementForm } from '@animal/components/compact-animal-placement/compact-animal-placement.component';
import { PlacementForm } from '@animal/pages/animal-detail/animal-placement/animal-placement.component';
import { SingleAuditFieldComponent } from '@audit/components/single-audit-field/single-audit-field.component';
import { CompactPlacementDto } from '@case/dtos/placement.dto';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { placementState } from '@core/models/form/form-helper';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'tgn-placement-mediation',
  standalone: true,
  imports: [
    FormElementComponent,
    MatCheckbox,
    MatFormField,
    ReactiveFormsModule,
    FormElementDirective,
    TranslateModule,
    MatInput,
    MatTooltip,
    FullNamePipe,
    DatePipe,
    SingleAuditFieldComponent,
  ],
  templateUrl: './placement-mediation.component.html',
  styleUrl: './placement-mediation.component.scss',
})
export class PlacementMediationComponent {
  @Input({ required: true }) placementForm!: CompactPlacementForm | PlacementForm;
  @Input({ required: true }) placement!: CompactPlacementDto;
  protected readonly placementState = placementState;
}
