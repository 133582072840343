import { AsyncPipe, NgClass } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCell, MatTableModule } from '@angular/material/table';
import { CreateSubmitReasonDto, UpdateSubmitReasonDto } from '@baseData/dtos/create-submit-reason.dto';
import { SubmitReasonService } from '@baseData/services/submit-reason.service';
import { SubmitReasonDto, SubmitReasonId } from '@case/dtos/base-data.dto';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SelectComponent } from '@core/components/select/select.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { defaultDebounce } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { uniqueNameValidator } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { shareReplay } from 'rxjs';

export type UpdateSubmitReasonForm = FormGroup<{
  active: FormControl<boolean>;
}>;

export type NewSubmitReasonForm = FormGroup<{
  name: FormControl<string | null>;
}>;

interface TableConfig {}

@Component({
  selector: 'tgn-base-data-submit-reason',
  standalone: true,
  imports: [
    AsyncPipe,
    FieldErrorTranslationPipe,
    FormElementComponent,
    MatButton,
    MatCell,
    ScrollableTableComponent,
    MatTableModule,
    TypesafeMatTableModule,
    MatError,
    NgClass,
    MatFormField,
    ReactiveFormsModule,
    TranslateModule,
    SelectComponent,
    MatInput,
  ],
  templateUrl: './base-data-submit-reason.component.html',
  styleUrl: './base-data-submit-reason.component.scss',
})
export class BaseDataSubmitReasonComponent {
  columns = ['name', 'active'];
  appRoutes = routes_config;

  newSubmitReasonDtoForm!: NewSubmitReasonForm;
  editSubmitReasonDtoFormGroups: Map<SubmitReasonId, UpdateSubmitReasonForm> = new Map<SubmitReasonId, UpdateSubmitReasonForm>();

  dataSource!: TigonDatasource<SubmitReasonDto, TableConfig>;
  activeInactiveChoice: RadioChoice<Boolean>[] = [
    {
      label: 'GENERAL.FORM.LABEL.Active',
      object: true,
    },
    {
      label: 'GENERAL.FORM.LABEL.Inactive',
      object: false,
    },
  ];

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private submitReasonService: SubmitReasonService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.dataSource = new TigonDatasource<SubmitReasonDto, TableConfig>(
      {},
      () => {
        return this.submitReasonService.getAll();
      },
      this.destroyRef,
    );

    this.dataSource.getData().subscribe(data => {
      this.editSubmitReasonDtoFormGroups = new Map();
      // only allow to deactivate submit reasons without a tag
      data
        .filter((submitReason: SubmitReasonDto) => {
          return submitReason.tag === null;
        })
        .forEach((submitReason: SubmitReasonDto) => {
          const submitReasonFormGroup: UpdateSubmitReasonForm = this.fb.group({
            active: this.fb.nonNullable.control<boolean>(submitReason.active, [Validators.required]),
          });

          this.accessService.disableBasedOnRole(submitReasonFormGroup, RestrictedSection.Backoffice);

          this.editSubmitReasonDtoFormGroups.set(submitReason.id, submitReasonFormGroup);
          this.autoUpdateRows(submitReasonFormGroup, submitReason);
        });
    });

    this.createNewSubmitReasonForm();
  }

  addEntry() {
    const form = this.newSubmitReasonDtoForm.getRawValue();
    if (!form.name) {
      return;
    }

    const createPositionDto: CreateSubmitReasonDto = {
      name: form.name,
    };

    this.submitReasonService.create(createPositionDto).subscribe({
      next: () => {
        this.newSubmitReasonDtoForm.setValue({
          name: null,
        });
        this.newSubmitReasonDtoForm.markAsUntouched();
        this.newSubmitReasonDtoForm.markAsPristine();
        this.snackbar.showSuccessMessage(`Der Abgabegrund: '${createPositionDto.name}' wurde erfolgreich erstellt.`);
        this.dataSource.refresh();
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Der Abgabegrund konnte nicht erstellt werden.');
      },
    });
  }

  private createNewSubmitReasonForm() {
    this.newSubmitReasonDtoForm = this.fb.group({
      name: this.fb.control<string | null>(
        null,

        {
          validators: [Validators.required, Validators.minLength(1)],

          asyncValidators: [uniqueNameValidator(this.dataSource.getData().pipe(shareReplay(1)))],
          updateOn: 'change',
        },
      ),
    });

    this.accessService.disableBasedOnRole(this.newSubmitReasonDtoForm, RestrictedSection.Backoffice);
  }

  private autoUpdateRows(formGroup: UpdateSubmitReasonForm, submitReason: SubmitReasonDto) {
    formGroup.valueChanges.pipe(defaultDebounce(), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        if (formGroup.invalid) {
          return;
        }

        const isActive = formGroup.getRawValue().active;

        const dto: UpdateSubmitReasonDto = {
          active: isActive,
        };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.submitReasonService.update(submitReason.id, dto).subscribe(() => {
          this.dataSource.refresh();
        });
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Der Abgabegrund konnte nicht aktualisiert werden.');
      },
    });
  }
}
