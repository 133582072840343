import { Pipe, PipeTransform } from '@angular/core';
import { GroupDto, SpeciesDto, SubGroupDto } from '@case/dtos/base-data.dto';

@Pipe({
  name: 'speciesChoices',
  standalone: true,
})
export class SpeciesChoicesPipe implements PipeTransform {
  transform(
    species: SpeciesDto[],
    currentGroup: GroupDto | null | undefined,
    currentSubGroup: SubGroupDto | null | undefined,
    allSubGroups: SubGroupDto[] | null | undefined,
  ): SpeciesDto[] {
    if (!currentGroup && !currentSubGroup) {
      return species;
    }
    if (currentSubGroup) {
      return species.filter(it => it.subGroupId === currentSubGroup.id);
    }

    if (currentGroup && allSubGroups) {
      const restrictedSubGroups = allSubGroups.filter(it => it.groupId === currentGroup.id);
      return species.filter(it => restrictedSubGroups.some(subGroup => subGroup.id === it.subGroupId));
    }
    return species;
  }
}
