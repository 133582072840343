import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SingleImageDisplayComponent } from '@core/components/image-display/components/single-image-display/single-image-display.component';
import { StringToDatePipe } from '@core/pipes/string-to-date.pipe';
import { ImageDto } from '@file/dto/file.dto';
import { FilePipe } from '@file/pipes/file.pipe';

@Component({
  selector: 'tgn-image-display',
  standalone: true,
  imports: [AsyncPipe, StringToDatePipe, DatePipe, FilePipe, SingleImageDisplayComponent],
  templateUrl: './image-display.component.html',
  styleUrl: './image-display.component.scss',
})
export class ImageDisplayComponent {
  @Input({ required: true }) images!: ImageDto[];

  @Output() onImageDeleted = new EventEmitter<ImageDto>();
}
