@if (mode === BillMode.CreateBill || mode === BillMode.EditBill) {
  <button
    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
    (click)="addEntry()"
    [disabled]="newPositionForm.invalid"
    class="tgn-black-button mt-medium mb-medium bottom-action-button"
    mat-flat-button
  >
    {{ 'PAGE.BASE_DATA_POSITIONS.ACTIONS.ADD_POSITION' | translate }}
  </button>
}

<tgn-scrollable-table [showTableIfEmpty]="true">
  <div>
    <table #table="matTable" [dataSource]="datasource" mat-table>
      <tr *matHeaderRowDef="columns" mat-header-row></tr>
      <tr *matRowDef="let position; table: table; columns: columns" class="row-wide" mat-row></tr>

      @if (mode === BillMode.EditBill || mode === BillMode.CreateBill) {
        <tr class="footer-row" *matFooterRowDef="columns" mat-footer-row></tr>
      }

      <ng-container matColumnDef="baseDataPosition">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.baseDataPosition' | translate }}*</th>
        <td *matCellDef="let position; table: table; let i = index" mat-cell>
          @if (positionFormGroups.get(position.id); as group) {
            @if (baseDataPositions$ | async; as baseDataPositions) {
              <tgn-select
                [searchable]="true"
                class="medium-input-field"
                [compareIds]="true"
                [choices]="
                  baseDataPositions | includeItem: { item: group.controls.baseDataPosition.value, compareIds: true } | toRadioChoice: 'name'
                "
                [control]="group.controls.baseDataPosition"
              />
            }
          } @else {
            <div class="medium-input-field">
              <tgn-single-line-text [text]="position.baseDataPosition.name" />
            </div>
          }
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <tgn-form-element [control]="newPositionForm.controls.baseDataPosition" [hideError]="true" [label]="null" id="value">
            <ng-template formElement let-control="control" let-id="id">
              @if (baseDataPositions$ | async; as baseDataPositions) {
                <tgn-select
                  [searchable]="true"
                  class="medium-input-field"
                  [choices]="baseDataPositions | toRadioChoice: 'name'"
                  [compareIds]="true"
                  [control]="control"
                />
              }
            </ng-template>
          </tgn-form-element>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.description' | translate }}*</th>
        <td *matCellDef="let position; table: table; let i = index" mat-cell>
          @if (positionFormGroups.get(position.id); as group) {
            <mat-form-field appearance="outline" class="medium-input-field">
              <input [formControl]="group.controls.description" matInput />
            </mat-form-field>
          } @else {
            {{ position.description ?? '' }}
          }
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <mat-form-field appearance="outline" class="medium-input-field">
            <input [formControl]="newPositionForm.controls.description" matInput />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.amount' | translate }}*</th>
        <td *matCellDef="let position; table: table" mat-cell>
          <div class="max-width-column">
            @if (positionFormGroups.get(position.id); as group) {
              @if (position.baseDataPosition.positionCategory.isPension) {
                <div class="pension-amount">
                  {{ position.amount }}
                  <div
                    class="position-info"
                    [matTooltip]="'PAGE.CREATE_BILL.INFO.AUTO_CALCULATION' | translate: { pensionBillingInfo: position.pensionBillingInfo }"
                  >
                    <tgn-icon [iconName]="'radixInfoCircled'" />
                  </div>
                </div>
              } @else if (!position.bill || position.bill.status === BillStatus.Open) {
                <mat-form-field appearance="outline" class="amount-input-field">
                  <input [formControl]="group.controls.amount" matInput type="number" />
                </mat-form-field>
              } @else {
                <div class="align-center">
                  {{ position.amount }}
                </div>
              }
            } @else {
              <div class="align-center">
                {{ position.amount }}
              </div>
            }
          </div>
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <tgn-form-element [control]="newPositionForm.controls.amount" [hideError]="true" [label]="null" id="'amount'">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline" class="amount-input-field">
                <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Position.amount' | translate" matInput type="number" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.type' | translate }}</th>
        <td *matCellDef="let position; table: table" mat-cell>
          {{ position.baseDataPosition.type | enumDisplay: 'GENERAL.DOMAIN.PositionType' }}
        </td>

        <td *matFooterCellDef mat-footer-cell>
          @if (currentSelectedBasePosition) {
            {{ currentSelectedBasePosition.type | enumDisplay: 'GENERAL.DOMAIN.PositionType' }}
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.price' | translate }}</th>
        <td *matCellDef="let position; table: table" class="align-right" mat-cell>
          @if (positionFormGroups.get(position.id); as group) {
            <mat-form-field appearance="outline" class="price-input-field">
              <input
                [formControl]="group.controls.price"
                matInput
                type="number"
                [placeholder]="'ENTITY.Position.placeholderPrice' | translate"
              />
            </mat-form-field>
          } @else {
            {{ position.price | tgnCurrency: { withCHF: true } }}
          }
        </td>

        <td *matFooterCellDef class="align-right" mat-footer-cell>
          @if (currentSelectedBasePosition) {
            <mat-form-field appearance="outline" class="price-input-field">
              <input
                [formControl]="newPositionForm.controls.price"
                matInput
                type="number"
                [placeholder]="'ENTITY.Position.placeholderPrice' | translate"
              />
            </mat-form-field>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="discountPercentage">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.discountPercentage' | translate }}</th>
        <td *matCellDef="let position; table: table" mat-cell>
          @if (positionFormGroups.get(position.id); as group) {
            <mat-form-field appearance="outline" class="small-input-field">
              <input [formControl]="group.controls.discountPercentage" matInput type="number" />
            </mat-form-field>
          } @else {
            {{ position.discountPercentage ? position.discountPercentage + '%' : '' }}
          }
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <tgn-form-element
            [control]="newPositionForm.controls.discountPercentage"
            [hideError]="true"
            [label]="null"
            id="discountPercentage"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline" class="small-input-field">
                <input
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'ENTITY.Position.placeholderDiscountPercentage' | translate"
                  matInput
                  type="number"
                />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </td>
      </ng-container>

      <ng-container matColumnDef="vatTaxType">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.vatTaxType' | translate }}</th>
        <td *matCellDef="let position; table: table" mat-cell>
          @if (usedVatTax) {
            {{ position.baseDataPosition.vatTaxType | vatTaxDisplay: usedVatTax }}
          }
        </td>

        <td *matFooterCellDef mat-footer-cell>
          @if (usedVatTax && currentSelectedBasePosition) {
            {{ currentSelectedBasePosition.vatTaxType | vatTaxDisplay: usedVatTax }}
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Position.total' | translate }}</th>

        <td *matCellDef="let position; table: table; let i = index" class="align-right" mat-cell>
          {{ position | positionTotal: usedVatTax | tgnCurrency: { withCHF: true } }}
        </td>

        <td *matFooterCellDef mat-footer-cell>
          @if (newPositionTotal$ | async; as total) {
            <div class="align-right">
              {{ total | tgnCurrency: { withCHF: true } }}
            </div>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let position; table: table" mat-cell>
          @if (mode === BillMode.CreateBill || mode === BillMode.EditBill) {
            <button
              (click)="$event.stopPropagation()"
              [matMenuTriggerData]="{ position: position }"
              [matMenuTriggerFor]="menu"
              mat-icon-button
              role="button"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-template let-position="position" matMenuContent>
                @if (mode === BillMode.EditBill || mode === BillMode.CreateBill) {
                  <button
                    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
                    (agreeEvent)="removePositionFromBill(position)"
                    appConfirmationDialog
                    mat-menu-item
                    [dialogTitle]="'PAGE.CREATE_BILL.CONFIRM_REMOVE_TITLE' | translate"
                    [dialogDescription]="'PAGE.CREATE_BILL.CONFIRM_REMOVE_TEXT' | translate"
                  >
                    <span>{{ 'PAGE.CREATE_BILL.ACTIONS.REMOVE_FROM_BILL' | translate }}</span>
                  </button>

                  @if (mode === BillMode.EditBill || mode === BillMode.CreateBill) {
                    <button
                      [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
                      (agreeEvent)="deletePosition(position)"
                      appConfirmationDialog
                      mat-menu-item
                    >
                      <span>{{ 'GENERAL.BUTTON.DELETE' | translate }}</span>
                    </button>
                  }
                }
              </ng-template>
            </mat-menu>
          }
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
    </table>
  </div>
</tgn-scrollable-table>

@if (total$ | async; as total) {
  <div class="total-container">
    <!-- @TODO: CHECK -->
    <span>Total: {{ total.total | tgnCurrency: { withCHF: true } }}</span>
  </div>
}

@if (mode === BillMode.CreateBill || mode === BillMode.EditBill) {
  <div class="remove-all-button-container">
    <button
      [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
      mat-flat-button
      class="tgn-button"
      type="button"
      (agreeEvent)="removeAllPositionsFromBill()"
      appConfirmationDialog
      [dialogTitle]="'PAGE.CREATE_BILL.CONFIRMATION.TITLE_REMOVE_ALL_POSITIONS'"
      [dialogDescription]="'PAGE.CREATE_BILL.CONFIRMATION.MESSAGE_REMOVE_ALL_POSITIONS'"
    >
      {{ 'PAGE.CREATE_BILL.ACTIONS.REMOVE_ALL_POSITIONS' | translate }}
    </button>
  </div>
}
