import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnimalDto, AnimalId } from '@animal/dtos/animal.dto';
import { UpdateExitStateDto } from '@animal/dtos/update-exit-state.dto';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

import { AnimalTakeoverDto } from '../dtos/animal-takeover.dto';
import { CompactCaseAnimalDto } from '../dtos/case-animal.dto';
import { CaseDetailDto } from '../dtos/case-detail.dto';
import { CaseDto, CaseId } from '../dtos/case.dto';
import { DomesticCaseAnimalDto, WildCaseAnimalDto } from '../dtos/domestic-case-animal.dto';
import { ExitCheckDto, UpdateExitCheckDto } from '../dtos/exit-check.dto';
import {
  CaseGeneralPlacementDto,
  CompactPlacementDto,
  PlacementDetailDto,
  UpdateCaseGeneralPlacementDto,
  UpdateCompactPlacementDto,
  UpdatePlacementDetailDto,
  UpdateWildAnimalExitStateDto,
} from '../dtos/placement.dto';
import { ReplaceAnimalDto } from '../dtos/replace-animal.dto';
import { UpdateDomesticCaseAnimalDto, UpdateWildCaseAnimalDto } from '../dtos/update-domestic-case-animal.dto';
import { UpdateStmzDto } from '../dtos/update-stmz.dto';

@Injectable({
  providedIn: 'root',
})
export class CaseAnimalService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  updateDomestic(caseId: CaseId, animalId: AnimalId, dto: UpdateDomesticCaseAnimalDto): Observable<DomesticCaseAnimalDto> {
    return this.http.put<DomesticCaseAnimalDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}`, dto);
  }

  getDomestic(caseId: CaseId, animalId: AnimalId): Observable<DomesticCaseAnimalDto> {
    return this.http.get<DomesticCaseAnimalDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}`);
  }

  updateWild(caseId: CaseId, animalId: AnimalId, dto: UpdateWildCaseAnimalDto): Observable<WildCaseAnimalDto> {
    return this.http.put<WildCaseAnimalDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/wildAnimal`, dto);
  }

  getWild(caseId: CaseId, animalId: AnimalId): Observable<WildCaseAnimalDto> {
    return this.http.get<WildCaseAnimalDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/wildAnimal`);
  }

  getPlacement(caseId: CaseId, animalId: AnimalId): Observable<PlacementDetailDto> {
    return this.http.get<PlacementDetailDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/placement`);
  }

  updateStmz(caseId: CaseId, animalId: AnimalId, updateStmzDto: UpdateStmzDto): Observable<CompactCaseAnimalDto> {
    return this.http.put<CompactCaseAnimalDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/stmz`, updateStmzDto);
  }

  getGeneralCasePlacement(caseId: CaseId, animalId: AnimalId): Observable<CaseGeneralPlacementDto> {
    return this.http.get<CaseGeneralPlacementDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/case-general-placement`);
  }

  getCompactCaseAnimal(caseId: CaseId, animalId: AnimalId): Observable<CompactCaseAnimalDto> {
    return this.http.get<CompactCaseAnimalDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/caseAnimal`);
  }

  updatePlacement(caseId: CaseId, animalId: AnimalId, dto: UpdatePlacementDetailDto): Observable<PlacementDetailDto> {
    return this.http.put<PlacementDetailDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/placement`, dto);
  }

  getPlacementCompact(caseId: CaseId, animalId: AnimalId): Observable<CompactPlacementDto> {
    return this.http.get<CompactPlacementDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/placementCompact`);
  }

  updatePlacementCompact(caseId: CaseId, animalId: AnimalId, dto: UpdateCompactPlacementDto): Observable<CompactPlacementDto> {
    return this.http.put<CompactPlacementDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/placementCompact`, dto);
  }

  updateGeneralPlacement(caseId: CaseId, animalId: AnimalId, dto: UpdateCaseGeneralPlacementDto): Observable<PlacementDetailDto> {
    return this.http.put<PlacementDetailDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/case-general-placement`, dto);
  }

  updateWildAnimalExitState(caseId: CaseId, animalId: AnimalId, dto: UpdateWildAnimalExitStateDto): Observable<PlacementDetailDto> {
    return this.http.put<PlacementDetailDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/case-general-wild-animal-exit-state`, dto);
  }

  delete(caseId: CaseId, animalId: AnimalId): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/delete`);
  }

  replaceAnimalInCaseAnimal(caseId: CaseId, oldAnimalId: AnimalId, newAnimalId: AnimalId): Observable<CompactCaseAnimalDto> {
    const dto: ReplaceAnimalDto = {
      newAnimalId: newAnimalId,
    };
    return this.http.post<CompactCaseAnimalDto>(`${this.apiUrl}/cases/${caseId}/animals/${oldAnimalId}/replace`, dto);
  }

  replaceAnimalInCaseWithDefault(caseId: CaseId, animalId: AnimalId) {
    return this.http.post<CompactCaseAnimalDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/replaceWithDefaultAnimal`, {});
  }

  addToCase(caseDto: CaseDto | CaseDetailDto, animal: AnimalDto): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/cases/${caseDto.id}/animals/${animal.id}`, {});
  }

  takeover(caseDto: CaseDetailDto, animal: AnimalDto, dto: AnimalTakeoverDto): Observable<CaseGeneralPlacementDto> {
    return this.http.post<CaseGeneralPlacementDto>(`${this.apiUrl}/cases/${caseDto.id}/animals/${animal.id}/takeover`, dto);
  }

  getExitCheck(caseId: CaseId, animalId: AnimalId): Observable<ExitCheckDto> {
    return this.http.get<ExitCheckDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/exitCheck`);
  }

  updateExitCheck(caseId: CaseId, animalId: AnimalId, dto: UpdateExitCheckDto): Observable<ExitCheckDto> {
    return this.http.put<ExitCheckDto>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/exitCheck`, dto);
  }

  updateExitState(caseId: CaseId, animalId: AnimalId, dto: UpdateExitStateDto): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/cases/${caseId}/animals/${animalId}/exitState`, dto);
  }

  getBoxCardPdfUrl(animalId: AnimalId, caseId: CaseId): string {
    return `${this.apiUrl}/cases/${caseId}/animals/${animalId}/boxcard-pdf`;
  }

  downloadBoxCardPdf(animalId: AnimalId, caseId: CaseId): Observable<HttpResponse<Blob>> {
    return this.http.get(this.getBoxCardPdfUrl(animalId, caseId), {
      responseType: 'blob',
      observe: 'response',
    });
  }

  getAdmissionFormPdfUrl(animalId: AnimalId, caseId: CaseId): string {
    return `${this.apiUrl}/cases/${caseId}/animals/${animalId}/admission-form-pdf`;
  }

  downloadAdmissionFormPdf(animalId: AnimalId, caseId: CaseId): Observable<HttpResponse<Blob>> {
    return this.http.get(this.getAdmissionFormPdfUrl(animalId, caseId), {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
