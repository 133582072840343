import { AsyncPipe, NgClass } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { BoxService } from '@baseData/services/box.service';
import { BoxDto, BoxId, CreateBoxDto, StationDto, UpdateBoxDto } from '@case/dtos/base-data.dto';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { defaultDebounce } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { uniqueNameValidator } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { shareReplay } from 'rxjs';

interface TableConfig {}

export type UpdateBoxForm = FormGroup<{
  active: FormControl<boolean>;
}>;

export type NewBoxForm = FormGroup<{
  name: FormControl<string | null>;
}>;

@Component({
  selector: 'tgn-base-data-station-boxes',
  standalone: true,
  imports: [
    AsyncPipe,
    FieldErrorTranslationPipe,
    FormElementComponent,
    MatButton,
    MatTableModule,
    TypesafeMatTableModule,
    TranslateModule,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    SelectComponent,
    MatError,
    NgClass,
  ],
  templateUrl: './base-data-station-boxes.component.html',
  styleUrl: './base-data-station-boxes.component.scss',
})
export class BaseDataStationBoxesComponent implements OnInit {
  @Input({ required: true }) station!: StationDto;

  columns = ['name', 'active'];
  appRoutes = routes_config;

  newBoxForm!: NewBoxForm;
  editBoxFormGroups: Map<BoxId, UpdateBoxForm> = new Map<BoxId, UpdateBoxForm>();

  dataSource!: TigonDatasource<BoxDto, TableConfig>;
  activeInactiveChoice: RadioChoice<Boolean>[] = [
    {
      label: 'GENERAL.FORM.LABEL.Active',
      object: true,
    },
    {
      label: 'GENERAL.FORM.LABEL.Inactive',
      object: false,
    },
  ];

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private boxService: BoxService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.dataSource = new TigonDatasource<BoxDto, TableConfig>(
      {},
      () => {
        return this.boxService.getAllInStation(this.station.id);
      },
      this.destroyRef,
    );

    this.dataSource.getData().subscribe(data => {
      this.editBoxFormGroups = new Map();
      data.forEach((box: BoxDto) => {
        const boxFormGroup: UpdateBoxForm = this.fb.group({
          active: this.fb.nonNullable.control<boolean>(box.active, [Validators.required]),
        });

        this.accessService.disableBasedOnRole(boxFormGroup, RestrictedSection.Backoffice);
        this.editBoxFormGroups.set(box.id, boxFormGroup);
        this.autoUpdateRows(boxFormGroup, box);
      });
    });

    this.createNewBoxForm();
  }

  addEntry() {
    const form = this.newBoxForm.getRawValue();
    if (!form.name) {
      return;
    }

    const createBoxDto: CreateBoxDto = {
      name: form.name,
      station: this.station,
    };

    this.boxService.create(createBoxDto).subscribe({
      next: () => {
        this.newBoxForm.setValue({
          name: null,
        });
        this.newBoxForm.markAsUntouched();
        this.newBoxForm.markAsPristine();
        this.snackbar.showSuccessMessage(`Die Box: '${createBoxDto.name}' wurde erfolgreich erstellt.`);
        this.dataSource.refresh();
      },
      error: (error: unknown) => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Die Box konnte nicht erstellt werden.');
      },
    });
  }

  private createNewBoxForm() {
    this.newBoxForm = this.fb.group({
      name: this.fb.control<string | null>(
        null,

        {
          validators: [Validators.required, Validators.minLength(1)],

          asyncValidators: [uniqueNameValidator(this.boxService.getAll().pipe(shareReplay(1)))],
          updateOn: 'change',
        },
      ),
    });

    this.accessService.disableBasedOnRole(this.newBoxForm, RestrictedSection.Backoffice);
  }

  private autoUpdateRows(formGroup: UpdateBoxForm, box: BoxDto) {
    formGroup.valueChanges.pipe(defaultDebounce(), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        if (formGroup.invalid) {
          return;
        }

        const isActive = formGroup.getRawValue().active;

        const dto: UpdateBoxDto = {
          active: isActive,
        };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.boxService.update(box.id, dto).subscribe(() => {
          this.dataSource.refresh();
        });
      },
      error: (error: unknown) => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Die Box konnte nicht aktualisiert werden.');
      },
    });
  }
}
