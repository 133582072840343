<div class="login-card">
  <h1>{{ 'PAGE.CHOOSE_TEAM.TITLE' | translate }}</h1>

  @if (form && initialized) {
    <div class="form-grid grid-cols-1">
      <div class="row">
        <tgn-form-element [control]="form.controls.team" [hideError]="true" [label]="'PAGE.CHOOSE_TEAM.TEAM' | translate" id="team">
          <ng-template formElement let-control="control" let-id="id">
            <tgn-select [choices]="teamChoices" [control]="control" [id]="id" />
          </ng-template>
        </tgn-form-element>
      </div>

      <div class="row">
        <tgn-form-element
          [control]="form.controls.station"
          [hideError]="true"
          [label]="'PAGE.CHOOSE_TEAM.STATION' | translate"
          id="station"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-select [compareIds]="true" [choices]="stationChoices$ | async | toRadioChoice: 'name'" [control]="control" [id]="id" />
          </ng-template>
        </tgn-form-element>
      </div>

      <div class="row">
        <button [disabled]="form.controls.team.value === null" (click)="continue()" class="block mt-medium" mat-stroked-button>
          {{ 'GENERAL.BUTTON.CONTINUE' | translate }}
        </button>
      </div>
    </div>
  }
</div>
