@if (newRaceDtoDtoForm) {
  <div class="overflow-container">
    <div class="endless-list">
      <div class="endless-list-header">
        <div>
          {{ 'ENTITY.RaceDto.name' | translate }}
        </div>
        <div>
          {{ 'ENTITY.RaceDto.species' | translate }}
        </div>
        <div>
          {{ 'ENTITY.RaceDto.active' | translate }}
        </div>
      </div>

      @if (dataSource.getData() | async; as data) {
        <cdk-virtual-scroll-viewport itemSize="50" class="endless-list-viewport">
          <div *cdkVirtualFor="let racesDto of data" class="endless-list-item">
            <div>
              <div class="max-name-column">
                <tgn-single-line-text [text]="racesDto.name" />
              </div>
            </div>
            <div>
              @if (editRaceDtoDtoFormGroups.get(racesDto.id); as group) {
                <tgn-select
                  [searchable]="true"
                  [choices]="domesticSpecies$ | async | toRadioChoice: 'name'"
                  [control]="group.controls.species"
                  class="fixed-height-input"
                  matInput
                />
              }
            </div>
            <div>
              @if (editRaceDtoDtoFormGroups.get(racesDto.id); as group) {
                <tgn-select class="fixed-height-input" [choices]="activeInactiveChoice" [control]="group.controls.active" matInput />
              } @else {
                <div class="input-padding">
                  {{ (racesDto.active ? 'GENERAL.FORM.LABEL.Active' : 'GENERAL.FORM.LABEL.Inactive') | translate }}
                </div>
              }
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      }

      <div class="create-container mt-large">
        <div>
          <tgn-form-element
            [control]="newRaceDtoDtoForm.controls.name"
            [hideError]="true"
            [label]="'ENTITY.RaceDto.name' | translate"
            id="name"
          >
            <mat-form-field appearance="outline" class="full-width">
              <input [formControl]="newRaceDtoDtoForm.controls.name" matInput />
            </mat-form-field>
          </tgn-form-element>
        </div>

        <div>
          <tgn-form-element
            [control]="newRaceDtoDtoForm.controls.species"
            [hideError]="true"
            [label]="'ENTITY.RaceDto.species' | translate"
            id="species"
          >
            <tgn-select
              [searchable]="true"
              [choices]="domesticSpecies$ | async | toRadioChoice: 'name'"
              [control]="newRaceDtoDtoForm.controls.species"
              matInput
            />
          </tgn-form-element>
        </div>

        <div></div>
      </div>
    </div>

    <mat-error
      [ngClass]="{ invisible: (newRaceDtoDtoForm.controls.name.value?.length ?? 0) === 0 }"
      class="errors input-padding mt-small mb-small"
    >
      {{ newRaceDtoDtoForm.controls.name.errors | fieldErrorTranslation | async }}
    </mat-error>

    <button [disabled]="newRaceDtoDtoForm.invalid" mat-flat-button class="tgn-black-button add-entry-button" (click)="addEntry()">
      {{ 'GENERAL.BUTTON.ADD_ENTRY' | translate }}
    </button>
  </div>
}
