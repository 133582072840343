@if (breadcrumbs$ | async; as breadcrumbs) {
  <ng-container>
    @for (breadcrumb of breadcrumbs; track trackByBreadcrumb($index, breadcrumb)) {
      <ng-container>
        <tgn-single-line-text
          *ngIf="isString(breadcrumb.label)"
          class="breadcrumb-link"
          [text]="breadcrumb.label | translate"
          [class.is-last-breadcrumb]="$index === breadcrumbs.length - 1"
          [routerLink]="breadcrumb.routeLink"
        ></tgn-single-line-text>
        @if ($index !== breadcrumbs.length - 1) {
          <tgn-icon [iconName]="'radixChevronRight'" />
        }
      </ng-container>
    }
  </ng-container>
}
