import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { SnackbarComponent, SnackbarData } from '../components/snackbar/snackbar.component';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  generalConfig: Partial<MatSnackBarConfig> = {
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'bottom',
  };

  constructor(private snackbar: MatSnackBar) {}

  showErrorMessage(message: string, title?: string) {
    const data: SnackbarData = {
      title: title ?? 'GENERAL.SNACKBAR.ERROR',
      message,
      messageType: 'error',
    };
    this.snackbar.openFromComponent<SnackbarComponent, SnackbarData>(SnackbarComponent, {
      panelClass: ['snackbar', 'error-snackbar'],
      data: data,
      ...this.generalConfig,
    });
  }

  showPendingMessage(message: string, title?: string) {
    const data: SnackbarData = {
      title: title ?? 'GENERAL.SNACKBAR.PENDING',
      message,
      messageType: 'pending',
    };
    this.snackbar.openFromComponent<SnackbarComponent, SnackbarData>(SnackbarComponent, {
      panelClass: ['snackbar', 'pending-snackbar'],
      data: data,
      ...this.generalConfig,
    });
  }

  showWarningMessage(message: string, title?: string) {
    const data: SnackbarData = {
      title: title ?? 'GENERAL.SNACKBAR.WARNING',
      message,
      messageType: 'warning',
    };
    this.snackbar.openFromComponent<SnackbarComponent, SnackbarData>(SnackbarComponent, {
      panelClass: ['snackbar', 'warning-snackbar'],
      data: data,
      ...this.generalConfig,
    });
  }

  showSuccessMessage(message: string, title?: string) {
    const data: SnackbarData = {
      title: title ?? 'GENERAL.SNACKBAR.SUCCESS',
      message,
      messageType: 'success',
    };
    this.snackbar.openFromComponent<SnackbarComponent, SnackbarData>(SnackbarComponent, {
      panelClass: ['snackbar', 'success-snackbar'],
      data: data,
      ...this.generalConfig,
    });
  }

  clearShowedMessage() {
    this.snackbar.dismiss();
  }
}
