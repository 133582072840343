import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SingleAuditFieldDto } from '@audit/dtos/auditChange';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { VisumPipe } from '@core/pipes/visum.pipe';

@Component({
  selector: 'tgn-single-audit-field',
  standalone: true,
  imports: [DatePipe, FullNamePipe, SingleLineTextComponent, VisumPipe],
  templateUrl: './single-audit-field.component.html',
  styleUrl: './single-audit-field.component.scss',
})
export class SingleAuditFieldComponent {
  @Input({ required: true }) singleAuditField!: SingleAuditFieldDto | null;
}
