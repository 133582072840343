import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatMenuItem } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AnimalNamePipe } from '@animal/pipes/animal-name.pipe';
import { WithDefaultAnimalNamePipe } from '@animal/pipes/with-default-animal-name.pipe';
import { VatTaxGroup } from '@baseData/dtos/vat-tax.dto';
import { VatTaxService } from '@baseData/services/vat-tax.service';
import {
  BillServicesPositionContext,
  BillServicesPositionTableComponent,
} from '@bill/components/bill-services-position-table/bill-services-position-table.component';
import { ServicesPositionTableComponent } from '@bill/components/services-position-table/services-position-table.component';
import { BillDto, BillRangeType, BillStatus, BillType, CreateBillDto, DEFAULT_PAYMENT_CONDITION, UpdateBillDto } from '@bill/dto/bill.dto';
import { BillingPeriod } from '@bill/dto/billing-period.dto';
import { PositionDto } from '@bill/dto/position.dto';
import { ResultTotalCalculationsDto } from '@bill/dto/total-calculations-result.dto';
import { BillService } from '@bill/services/bill.service';
import { CompactAnimalDto } from '@case/dtos/case-animal.dto';
import { CaseDto, CaseId } from '@case/dtos/case.dto';
import { CaseContactService } from '@case/services/case-contact.service';
import { CaseService } from '@case/services/case.service';
import { ChooseContactComponent } from '@contact/components/choose-contact/choose-contact.component';
import { ContactDto } from '@contact/dto/contact.dto';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { ConfirmationDialogComponent, DialogResponse } from '@core/components/confirmation-dialog/confirmation-dialog.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { IconComponent } from '@core/components/icon/icon.component';
import {
  NarrowPageContainerComponent,
  NarrowPageContainerSize,
} from '@core/components/narrow-page-container/narrow-page-container.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { FvsCurrencyPipe } from '@core/pipes/currency.pipe';
import { defaultDebounce } from '@core/services/base-service';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices, openDownloadedBlobPdf } from '@core/utils/helplers';
import { fullName } from '@core/utils/person';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, ReplaySubject, Subject, combineLatest, filter, identity, map, of, startWith, switchMap, take, tap } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

interface BillForm {
  billingContact: FormControl<ContactDto | null>;
  type: FormControl<BillRangeType | null>;
  invoiceDocumentDate: FormControl<IsoLocalDateString | null>;
  billingDate: FormControl<IsoLocalDateString | null>;
  note: FormControl<string | null>;
  paymentCondition: FormControl<string | null>;
  alreadyPaidAmount: FormControl<number | null>;
  status: FormControl<BillStatus>;
}

type AnimalDatasourcesMap = Map<CompactAnimalDto, TigonDatasource<PositionDto, TableConfig>>;

interface TableConfig {
  removedPositions: string[];
}

@Component({
  selector: 'app-create-bill-page',
  standalone: true,
  imports: [
    ChooseContactComponent,
    DateInputComponent,
    FormElementComponent,
    FormElementDirective,
    FormsModule,
    MatFormField,
    MatInput,
    SelectComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatButton,
    ContextActionsDirective,
    CdkTextareaAutosize,
    MatInputModule,
    CollapsibleComponent,
    WithDefaultAnimalNamePipe,
    AnimalNamePipe,
    ServicesPositionTableComponent,
    AsyncPipe,
    FvsCurrencyPipe,
    RouterLink,
    ConfirmationDialogDirective,
    MatMenuItem,
    NarrowPageContainerComponent,
    IconComponent,
    BillServicesPositionTableComponent,
    MatAnchor,
    RoleRestrictionDirective,
  ],
  templateUrl: './create-bill-page.component.html',
  styleUrl: './create-bill-page.component.scss',
})
export class CreateBillPageComponent implements OnInit {
  billForm?: FormGroup<BillForm>;
  billRangeTypeChoices: RadioChoice<BillRangeType>[] = createEnumChoices(BillRangeType, 'GENERAL.DOMAIN.BillRangeType.');
  caseId!: CaseId;
  animalDatasourcesMap?: AnimalDatasourcesMap;
  caseDatasource?: TigonDatasource<PositionDto, TableConfig>;
  billingNumber = '';
  usedVatTax$: ReplaySubject<VatTaxGroup> = new ReplaySubject<VatTaxGroup>(1);
  usedVatTax: VatTaxGroup | null = null;
  triggerUpdateInvoiceCalculations$: Subject<null> = new Subject<null>();

  // if in status open we can change to 'Sent', 'Paid'
  openStatusChoices = [BillStatus.Open, BillStatus.Sent, BillStatus.Paid].map((status: BillStatus) => ({
    object: status,
    label: this.translate.instant(`GENERAL.DOMAIN.BillStatus.${status}`),
  }));

  allStatusChoices: RadioChoice<BillStatus>[] = createEnumChoices(BillStatus, 'GENERAL.DOMAIN.BillStatus.');

  // if in status Sent we can change to status 'Paid' only
  sentStatusChoices: RadioChoice<BillStatus>[] = [BillStatus.Sent, BillStatus.Paid].map((status: BillStatus) => ({
    object: status,
    label: this.translate.instant(`GENERAL.DOMAIN.BillStatus.${status}`),
  }));

  billStatusChoices: RadioChoice<BillStatus>[] = this.openStatusChoices;

  invoiceCalculations$: ReplaySubject<ResultTotalCalculationsDto> = new ReplaySubject(1);

  bill?: BillDto;

  cancelledStornoParent: BillDto | null = null;
  stornoChildBill: BillDto | null = null;

  billingPeriod$: ReplaySubject<BillingPeriod> = new ReplaySubject<BillingPeriod>(1);
  contactChoices: RadioChoice<ContactDto>[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly BillStatus = BillStatus;
  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly NarrowPageContainerSize = NarrowPageContainerSize;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly PositionContext = BillServicesPositionContext;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private billService: BillService,
    private activeRoute: ActivatedRoute,
    private caseService: CaseService,
    private snackbar: SnackbarService,
    private router: Router,
    private vatTaxService: VatTaxService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    private modalService: ModalService,
    private caseContactService: CaseContactService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.activeRoute.params
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(params => {
          this.caseId = params['caseId'];
          if (!this.caseId) {
            console.error('No valid case id provided for create bill page');
            return of(null);
          }

          const billId = params['billId'];

          if (!billId) {
            return this.caseService.get(this.caseId);
          }

          return combineLatest([this.caseService.get(this.caseId), this.billService.getBill(billId)]);
        }),
      )
      .subscribe((result: CaseDto | [CaseDto, BillDto] | null) => {
        if (result === null || result === undefined) {
          this.router.navigate(this.appRoutes.CASES.url());
          return;
        }
        const caseDto = Array.isArray(result) ? result[0] : result;
        this.bill = Array.isArray(result) ? result[1] : undefined;

        this.breadcrumbService.set('@caseName', caseDto.caseNumber);
        if (this.bill) {
          this.breadcrumbService.set('@billingNumber', `Rechnung: ${this.bill.billingNumber}`);

          if (this.bill.status === BillStatus.Open) {
            this.billStatusChoices = this.openStatusChoices;
          } else if (this.bill.status === BillStatus.Sent) {
            this.billStatusChoices = this.sentStatusChoices;
          } else {
            this.billStatusChoices = this.allStatusChoices;
          }
        }

        this.caseContactService.getCaseContacts(this.caseId).subscribe(relatedContacts => {
          this.contactChoices = relatedContacts.map(relatedContact => {
            const contact = relatedContact.contact;

            const values = [fullName(contact), contact.company, contact.zip, contact.city, relatedContact.kind.name];

            const name = values
              .filter(notNullish)
              .filter(it => it?.trim().length > 0)
              .join(', ');

            const choice: RadioChoice<ContactDto> = {
              object: relatedContact.contact,
              label: name,
            };
            return choice;
          });
        });

        this.createForm(this.bill);

        this.accessService.disableBasedOnRole(this.billForm!, RestrictedSection.Case);

        this.caseDatasource = this.createCaseDatasource(this.bill, caseDto);
        this.animalDatasourcesMap = this.createAnimalDatasources(this.bill, caseDto);

        this.autoUpdateInvoiceCalculations(this.caseDatasource, this.animalDatasourcesMap);

        // if billing date changes then the pension positions number of dates that should be billed change
        // in this case refetch to get updated positions
        this.billForm?.controls.billingDate.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
          for (const datasource of this.animalDatasourcesMap!.values()) {
            datasource.update({});
          }
        });

        if (this.bill) {
          this.autoSaveChanges();
        }

        this.autoSetVatTaxBasedOnBillingDate();

        if (this.bill?.billingDate) {
          this.billService.getBillingPeriod(this.bill.billingDate, this.caseId).subscribe((billingPeriod: BillingPeriod) => {
            this.billingPeriod$.next(billingPeriod);
          });
        }

        if (!this.bill) {
          this.billService.getNextBillingNumber(BillType.Regular).subscribe((billingNumber: string) => {
            this.billingNumber = billingNumber;
          });
        } else {
          this.billingNumber = this.bill.billingNumber;
        }

        this.getUsedVatTax();

        this.getStornoAndCancelledBill();

        this.billForm?.controls.billingDate.valueChanges
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            filter(notNullish),
            switchMap((value: string | null) => {
              if (!value) {
                return of(null);
              }
              return this.billService.getBillingPeriod(value, this.caseId);
            }),
            filter(notNullish),
          )
          .subscribe((billingPeriod: BillingPeriod) => {
            this.billingPeriod$.next(billingPeriod);
          });
      });

    this.usedVatTax$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(vatTax => {
      this.usedVatTax = vatTax;
    });
  }

  createForm(bill?: BillDto) {
    const isDisabled = bill !== undefined && bill.status !== BillStatus.Open;
    this.billForm = this.fb.group({
      billingContact: this.fb.control<ContactDto | null>(
        {
          value: bill?.billingContact ?? null,
          disabled: isDisabled,
        },
        [Validators.required],
      ),
      type: this.fb.control<BillRangeType | null>(
        {
          value: bill?.billRangeType ?? null,
          disabled: isDisabled,
        },
        [Validators.required],
      ),
      invoiceDocumentDate: this.fb.control<IsoLocalDateString | null>(
        {
          value: bill?.invoiceDocumentDate ?? null,
          disabled: isDisabled,
        },
        [Validators.required],
      ),
      billingDate: this.fb.control<IsoLocalDateString | null>(
        {
          value: bill?.billingDate ?? null,
          // do not allow to change the billing date for existing bills
          disabled: isDisabled,
        },
        [Validators.required],
      ),
      note: this.fb.control<string | null>({ value: bill?.note ?? null, disabled: isDisabled }),
      paymentCondition: this.fb.control<string | null>({
        value: bill?.paymentCondition ?? DEFAULT_PAYMENT_CONDITION,
        disabled: isDisabled,
      }),
      alreadyPaidAmount: this.fb.control<number | null>(
        {
          value: bill?.alreadyPaidAmount ?? null,
          disabled: isDisabled,
        },
        [Validators.min(0)],
      ),
      status: this.fb.nonNullable.control<BillStatus>({
        value: bill?.status ?? BillStatus.Open,
        disabled: bill?.status !== BillStatus.Open && bill?.status !== BillStatus.Sent,
      }),
    });
  }

  saveOrUpdateBill() {
    const animalDatasources = Array.from(this.animalDatasourcesMap!.values()).map(datasource => datasource.getData());

    const isCreate = !this.bill;

    combineLatest([this.caseDatasource!.getData(), ...animalDatasources])
      .pipe(
        defaultDebounce(),
        take(1),
        switchMap(positionResults => {
          const positions = positionResults.flatMap(identity);
          if (this.bill) {
            return this.updateBill();
          } else {
            return this.createBill(positions);
          }
        }),
      )
      .subscribe((result: BillDto | null) => {
        if (!result) {
          return;
        }
        if (isCreate) {
          this.snackbar.showSuccessMessage('PAGE.CREATE_BILL.FEEDBACK.BILL_CREATED');
          this.router.navigate(this.appRoutes.CASE_EDIT_BILL.url(this.caseId, result.id), {
            onSameUrlNavigation: 'reload',
          });
        } else {
          if (result.status !== BillStatus.Open) {
            this.snackbar.showSuccessMessage('PAGE.CREATE_BILL.FEEDBACK.BILL_SAVED');
            this.router.navigate(this.appRoutes.CASE_EDIT_BILL.url(this.caseId, result.id), {
              onSameUrlNavigation: 'reload',
            });
          }
        }
      });
  }

  createStornoBill(bill: BillDto) {
    this.billService
      .stornoCancelBill(bill.id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigate(this.appRoutes.CASE_DETAIL_BILLS.url(this.caseId));
          this.snackbar.showSuccessMessage('PAGE.CREATE_BILL.FEEDBACK.STORNO_SUCCESSFUL');
        },
        error: err => {
          console.error(err);
          this.snackbar.showErrorMessage('PAGE.CREATE_BILL.FEEDBACK.STORNO_FAILED');
        },
      });
  }

  downloadPdf($event: MouseEvent, bill: BillDto) {
    if ($event.ctrlKey || $event.metaKey) {
      window.open(this.billService.getBillPdfUrl(bill.id));
      return;
    }

    this.billService.downloadBillPdfUrl(bill.id).subscribe(response => {
      openDownloadedBlobPdf(response);
    });
  }

  reloadPositions(datasource: TigonDatasource<PositionDto, TableConfig>) {
    datasource.refresh();
    this.triggerUpdateInvoiceCalculations$.next(null);
  }

  private updateBill(): Observable<BillDto | null> {
    const formValues = this.billForm!.getRawValue();
    if (formValues.type === null || formValues.invoiceDocumentDate === null || formValues.billingDate === null) {
      return of(null);
    }
    const dto: UpdateBillDto = {
      status: formValues.status,
      billingContactId: formValues.billingContact!.id,
      type: formValues.type,
      invoiceDocumentDate: formValues.invoiceDocumentDate,
      billingDate: formValues.billingDate,
      note: formValues.note,
      paymentCondition: formValues.paymentCondition,
      alreadyPaidAmount: formValues.alreadyPaidAmount,
    };

    let confirmUpdate = of(true);

    if (this.bill && this.bill.status === BillStatus.Open && dto.status != BillStatus.Open) {
      const confirmTitle = this.translate.instant('PAGE.CREATE_BILL.CONFIRMATION.CONFIRM_STATUS_CHANGE_TITLE');
      const confirmMessage = this.translate.instant('PAGE.CREATE_BILL.CONFIRMATION.CONFIRM_STATUS_CHANGE_MESSAGE');

      confirmUpdate = this.modalService
        .open(
          ConfirmationDialogComponent,
          {
            title: confirmTitle,
            description: confirmMessage,
          },
          { width: ModalWidth.Large },
        )
        .afterClosed()
        .pipe(
          tap((result: DialogResponse | undefined) => {
            if (result === undefined || !result.ok) {
              this.billForm!.controls.status.setValue(this.bill!.status);
            }
          }),
          map((result: DialogResponse | undefined) => {
            return result?.ok ?? false;
          }),
        );
    }

    return confirmUpdate.pipe(
      take(1),
      filter(result => {
        return result;
      }),
      switchMap(() => this.billService.updateBill(this.bill!, dto)),
    );
  }

  private createBill(positions: PositionDto[]): Observable<BillDto | null> {
    const formValues = this.billForm!.getRawValue();
    if (formValues.type === null || formValues.invoiceDocumentDate === null || formValues.billingDate === null) {
      return of(null);
    }
    const dto: CreateBillDto = {
      billingContactId: formValues.billingContact!.id,
      caseId: this.caseId,
      type: formValues.type,
      billingDate: formValues.billingDate,
      invoiceDocumentDate: formValues.invoiceDocumentDate,
      note: formValues.note,
      paymentCondition: formValues.paymentCondition,
      alreadyPaidAmount: formValues.alreadyPaidAmount,
      positions: positions,
      usedVatTax: this.usedVatTax!,
    };
    return this.billService.createBill(dto);
  }

  private autoUpdateInvoiceCalculations(
    caseDatasource: TigonDatasource<PositionDto, TableConfig>,
    animalDatasourcesMap: AnimalDatasourcesMap,
  ) {
    const animalDatasources = Array.from(animalDatasourcesMap.values()).map(datasource => datasource.getData());
    combineLatest([
      this.usedVatTax$,
      caseDatasource.getData(),
      this.billForm!.controls.alreadyPaidAmount.valueChanges.pipe(startWith(this.billForm!.controls.alreadyPaidAmount.value)),
      this.triggerUpdateInvoiceCalculations$.pipe(startWith(null)),
      ...animalDatasources.values(),
    ]).subscribe(values => {
      const vatTax: VatTaxGroup = values[0];
      const casePositions: PositionDto[] = values[1];
      const alreadyPaidAmount: number = values[2] ?? 0;
      const animalPositions = (values.slice(4) as PositionDto[][]).flatMap(identity);

      this.billService
        .calculateBillTotal(this.bill?.status == BillStatus.NewStorno, [...casePositions, ...animalPositions], vatTax, alreadyPaidAmount)
        .subscribe((result: ResultTotalCalculationsDto) => {
          this.invoiceCalculations$.next(result);
        });
    });
  }

  private createAnimalDatasources(bill: BillDto | undefined, caseDto: CaseDto): AnimalDatasourcesMap {
    const datasources: AnimalDatasourcesMap = new Map<CompactAnimalDto, TigonDatasource<PositionDto, TableConfig>>();

    caseDto.caseAnimals.forEach(caseAnimalDto => {
      const datasource = new TigonDatasource<PositionDto, TableConfig>(
        { removedPositions: [] },
        (params: TableConfig) => {
          if (bill) {
            return this.billService.getBillAnimalPositions(bill.id, caseDto.id, caseAnimalDto.animal.id).pipe(
              map((positions: PositionDto[]) => {
                return positions.filter(position => !params.removedPositions.includes(position.id));
              }),
            );
          } else {
            const billingDate = this.billForm?.controls?.billingDate?.value;
            if (!billingDate) {
              return of([]);
            }
            return this.billService.getAllAnimalPositionsForBilling(caseDto.id, caseAnimalDto.animal.id, billingDate).pipe(
              map((positions: PositionDto[]) => {
                return positions.filter(position => !params.removedPositions.includes(position.id));
              }),
            );
          }
        },
        this.destroyRef,
      );
      datasources.set(caseAnimalDto.animal, datasource);
    });
    return datasources;
  }

  private createCaseDatasource(bill: BillDto | undefined, caseDto: CaseDto): TigonDatasource<PositionDto, TableConfig> {
    return new TigonDatasource<PositionDto, TableConfig>(
      { removedPositions: [] },
      (params: TableConfig) => {
        if (bill) {
          return this.billService.getBillCasePositions(bill.id, this.caseId).pipe(
            map(positions => {
              return positions.filter(position => !params.removedPositions.includes(position.id));
            }),
          );
        } else {
          return this.billService.getAllCasePositionsForBilling(caseDto.id).pipe(
            map(positions => {
              return positions.filter(position => !params.removedPositions.includes(position.id));
            }),
          );
        }
      },
      this.destroyRef,
    );
  }

  private getUsedVatTax() {
    if (this.bill) {
      this.vatTaxService
        .getAtDate(this.bill.billingDate)
        .pipe(take(1))
        .subscribe(taxAtDate => {
          this.usedVatTax$.next(taxAtDate);
        });
    } else {
      this.vatTaxService
        .getCurrent()
        .pipe(take(1))
        .subscribe(currentVatTax => {
          this.usedVatTax$.next(currentVatTax);
        });
    }
  }

  private getStornoAndCancelledBill() {
    if (this.bill && this.bill.status === BillStatus.Cancelled) {
      this.billService
        .getStornoChildBill(this.bill.id)
        .pipe(take(1))
        .subscribe(child => {
          this.stornoChildBill = child;
        });
    }
    if (this.bill && this.bill.status === BillStatus.NewStorno) {
      this.billService
        .getCancelledStornoParentBill(this.bill.id)
        .pipe(take(1))
        .subscribe(parent => {
          this.cancelledStornoParent = parent;
        });
    }
  }

  private autoSetVatTaxBasedOnBillingDate() {
    this.billForm!.controls.billingDate.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((billingDate: string | null) => {
      if (billingDate == null) {
        this.vatTaxService
          .getCurrent()
          .pipe(take(1))
          .subscribe(currentVatTax => {
            this.usedVatTax$.next(currentVatTax);
          });
      } else {
        this.vatTaxService
          .getAtDate(billingDate)
          .pipe(take(1))
          .subscribe(taxAtDate => {
            this.usedVatTax$.next(taxAtDate);
          });
      }
    });
  }

  private autoSaveChanges() {
    this.billForm?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveOrUpdateBill();
    });
  }
}
