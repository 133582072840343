import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField, MatInput } from '@angular/material/input';
import { IconComponent } from '@core/components/icon/icon.component';
import { FileListPreviewComponent } from '@core/components/input-upload/file-list-preview/file-list-preview.component';
import { LoadingIndicatorComponent } from '@core/components/loading-indicator/loading-indicator.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { FileDownloadDirective } from '@file/directives/file-download.directive';
import { FileCollectionDto } from '@file/dto/file-collection.dto';
import { FileTagType } from '@file/dto/file-tag-type';
import { FileBaseDto } from '@file/dto/file.dto';
import {
  UploadFileType,
  acceptedImageMimeTypes,
  acceptedPdfMimeType,
  acceptedVideoMimeTypes,
  allAcceptedMimeTypes,
  defaultUploadFileTypes,
} from '@file/model/file';
import { FilePipe } from '@file/pipes/file.pipe';
import { FileService, UploadResult } from '@file/service/file.service';
import { NgIcon } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'tgn-input-upload',
  templateUrl: './input-upload.component.html',
  styleUrls: ['./input-upload.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    FileDownloadDirective,
    CommonModule,
    IconComponent,
    MatButtonModule,
    NgIcon,
    LoadingIndicatorComponent,
    FilePipe,
    FileListPreviewComponent,
    MatInput,
    MatFormField,
    RoleRestrictionDirective,
  ],
})
export class InputUploadComponent implements OnInit {
  @Input() acceptedFileTypes: UploadFileType[] = defaultUploadFileTypes;
  @Input({ required: true }) fileCollection!: FileCollectionDto; // null if upload is not managed via this component.
  @Input() dropzoneLabel?: string;
  @Input() autoUpload = true; // Automatically starts the upload as soon as files are selected or dropped.
  @Input() tags: FileTagType[] = [];
  @Input() multiple = true;

  @Output() onFilesChanged = new EventEmitter<FileList | null>();
  @Output() onUploadCompleted = new EventEmitter<UploadResult<FileBaseDto>[]>();
  @Output() onFileDeleted = new EventEmitter<FileBaseDto>();

  filesToUpload: File[] = [];
  files: FileBaseDto[] = [];
  isDragging = false;
  isLoading = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    private elRef: ElementRef,
    private fileService: FileService,
  ) {}

  ngOnInit(): void {
    this.fileService
      .getFilesPaginated(this.fileCollection, [], { pageIndex: 0, pageSize: 100 })
      .pipe(take(1))
      .subscribe(files => {
        this.files = files.items;
      });
  }

  get acceptAttribute() {
    return this.acceptedFileTypes.includes('all') ? '*' : this.acceptedMimeTypes().join(',');
  }

  get maxFileSize() {
    return environment.uploadMaxFileSize; // In bytes, must correspond to server limit.
  }

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
    if (!event.dataTransfer?.files) {
      return;
    }
    this.onFilesChanged.next(event.dataTransfer.files);
    this.addFilesToUpload(event.dataTransfer.files);
  }

  showSelectFileDialog(event: MouseEvent) {
    event.preventDefault();
    const elInputFile = this.elRef.nativeElement.querySelector('input[type="file"]') as HTMLElement;
    if (elInputFile) {
      elInputFile.click();
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    this.onFilesChanged.next(input.files);
    if (files) {
      this.addFilesToUpload(files);
    }
  }

  async upload() {
    this.isLoading = true;
    const results = await this.fileService.uploadFile(this.fileCollection, this.filesToUpload, this.tags);
    this.onUploadCompleted.next(results);
    this.filesToUpload = [];
    results.forEach(result => {
      if (result.success) {
        this.files.push(result.file!);
      }
    });
    this.isLoading = false;
  }

  async deleteFile(file: FileBaseDto) {
    this.isLoading = true;
    const isDeleted = await this.fileService.deleteFile(file);
    this.isLoading = false;
    if (isDeleted) {
      const index = this.files.findIndex(f => f.id === file.id);
      this.files.splice(index, 1);
      this.onFileDeleted.next(file);
    }
  }

  private validateFile(file: File) {
    if (file.size > this.maxFileSize) return false;
    if (this.acceptedFileTypes.includes('all')) {
      return true;
    }
    return this.acceptedMimeTypes().includes(file.type);
  }

  private acceptedMimeTypes() {
    return this.acceptedFileTypes.flatMap((acceptedFileType: UploadFileType) => {
      switch (acceptedFileType) {
        case 'image':
          return acceptedImageMimeTypes;
        case 'pdf':
          return [acceptedPdfMimeType];
        case 'video':
          return acceptedVideoMimeTypes;
        case 'all':
          return allAcceptedMimeTypes;
        default:
          console.error('Missing acceptedFileType in upload component!');
          return allAcceptedMimeTypes;
      }
    });
  }

  private addFilesToUpload(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      if (!this.validateFile(files[i])) {
        continue;
      }
      this.filesToUpload.push(files[i]);
    }

    if (this.autoUpload) {
      this.upload();
    }
  }
}
