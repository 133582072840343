import { Pipe, PipeTransform } from '@angular/core';
import { KeysMatching, PickKeysMatching } from '@core/pipes/to-radio-choice-pipe';
import { TranslateService } from '@ngx-translate/core';

interface Config {
  translate: boolean;
  prefix?: string;
}

@Pipe({ standalone: true, name: 'listDisplay' })
export class ListDisplay implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: unknown[], config: Config): string {
    if (!config.translate) {
      return value.join(', ');
    }

    return value.map(it => this.translate.instant(`${config.prefix ?? ''}${it}`)).join(', ');
  }
}

@Pipe({ standalone: true, name: 'listDisplayWithKey' })
export class ListDisplayWithKey implements PipeTransform {
  transform<T extends PickKeysMatching<T, string>>(value: T[], key: KeysMatching<T, string>): string {
    return value.map(it => it[key]).join(', ');
  }
}
