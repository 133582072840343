@if (newTaskCategoryForm) {
  <div class="overflow-container">
    <form>
      <table #table="matTable" class="table-large" [dataSource]="dataSource" mat-table>
        <tr *matHeaderRowDef="columns" mat-header-row></tr>
        <tr *matRowDef="let taskCategory; table: table; columns: columns" class="row-wide" mat-row></tr>
        <tr *matFooterRowDef="columns" class="footer-row" mat-footer-row></tr>

        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.TaskCategory.name' | translate }}</th>
          <td *matCellDef="let taskCategory; table: table; let i = index" mat-cell>
            {{ taskCategory.name }}
          </td>

          <td *matFooterCellDef mat-footer-cell>
            <tgn-form-element
              [control]="newTaskCategoryForm.controls.name"
              [hideError]="true"
              [label]="'ENTITY.TaskCategory.name' | translate"
              id="name"
            >
              <mat-form-field appearance="outline" class="full-width">
                <input [formControl]="newTaskCategoryForm.controls.name" matInput />
              </mat-form-field>
            </tgn-form-element>
          </td>
        </ng-container>

        <ng-container matColumnDef="active">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.TaskCategory.active' | translate }}</th>
          <td *matCellDef="let taskCategory; table: table" mat-cell>
            @if (editTaskCategoryFormGroups.get(taskCategory.id); as group) {
              <tgn-select [choices]="activeInactiveChoice" [control]="group.controls.active" matInput />
            } @else {
              <div class="input-padding">
                {{ (taskCategory.active ? 'GENERAL.FORM.LABEL.Active' : 'GENERAL.FORM.LABEL.Inactive') | translate }}
              </div>
            }
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>
      </table>
    </form>

    <mat-error
      [ngClass]="{ invisible: (newTaskCategoryForm.controls.name.value?.length ?? 0) === 0 }"
      class="errors input-padding mt-small mb-small"
    >
      {{ newTaskCategoryForm.controls.name.errors | fieldErrorTranslation | async }}
    </mat-error>

    <button [disabled]="newTaskCategoryForm.invalid" mat-flat-button class="tgn-black-button add-entry-button" (click)="addEntry()">
      {{ 'GENERAL.BUTTON.ADD_ENTRY' | translate }}
    </button>
  </div>
}
