<div *tgnContextActions class="tgn-context-actions">
  @if (!isReadOnly) {
    <tgn-button-upload
      (onUploadCompleted)="onUploadCompleted($event)"
      [acceptedFileTypes]="['image']"
      [fileCollection]="this.animal.fileCollection"
      [label]="'GENERAL.ACTION.ADD_PHOTOS'"
      [tags]="[FileTagType.AnimalImage]"
      [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
      class="mt-medium mb-medium"
    />
  }
</div>
<div class="images-container">
  @if (images$ | async; as images) {
    @if (images.length === 0) {
      <div class="no-items-container">Es wurden keine Elemente gefunden</div>
    }
    <tgn-image-display [images]="images" (onImageDeleted)="imageDeleted()" />
  }
</div>

@if (images$ | async; as images) {
  @if (images.length > 0) {
    <tgn-paginator
      (onPageEvent)="pageChanged($event)"
      [maxItemCount]="pagination.totalItems"
      [maxPages]="pagination.totalPages"
      [pageIndex]="pagination.pageIndex"
      [pageSize]="pagination.pageSize"
      class="paginator"
    ></tgn-paginator>
  }
}
