import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RelationKindDto, RelationKindTag } from '@baseData/dtos/relation-kind.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { ChooseContactComponent } from '@contact/components/choose-contact/choose-contact.component';
import { ContactId } from '@contact/dto/contact-list-view.dto';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { GENERAL_WRITE_EXCLUDE } from '@core/directives/role-restriction.directive';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { ModalComponent, ModalService, ModalWidth } from '@core/services/modal.service';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService } from '@user/service/access.service';
import { Observable, filter, map, startWith, take } from 'rxjs';

import { ContactDto } from '../../dto/contact.dto';

interface DialogData {
  customTitle?: string;
  preselectedKind?: RelationKindDto;
}

type DialogResult = CreateNewContact | RelateExistingContact;

type AddRelatedContactType = 'create' | 'relate';

interface AddRelatedContactResult {
  type: AddRelatedContactType;
  fromDate: string | null;
  toDate: string | null;
}

interface CreateNewContact extends AddRelatedContactResult {
  type: 'create';
}

interface RelateExistingContact extends AddRelatedContactResult {
  type: 'relate';
  contactId: ContactId;
  kind: RelationKindDto;
}

interface AddRelatedContactForm {
  type: FormControl<AddRelatedContactType>;
  kind: FormControl<RelationKindDto | null>;
  contact: FormControl<ContactDto | null>;
  fromDate: FormControl<string | null>;
  toDate: FormControl<string | null>;
}

@Component({
  selector: 'app-add-related-contact-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    MatButtonModule,
    RadioGroupComponent,
    FormElementComponent,
    FormElementDirective,
    AutocompleteComponent,
    ReactiveFormsModule,
    ChooseContactComponent,
    SelectComponent,
    DateInputComponent,
    ToRadioChoicePipe,
    AddIfMissingPipe,
  ],
  templateUrl: './add-related-contact-dialog.component.html',
  styleUrl: './add-related-contact-dialog.component.scss',
})
export class AddRelatedContactDialogComponent extends ModalComponent<DialogData, DialogResult> {
  form: FormGroup<AddRelatedContactForm>;

  addChoices: RadioChoice<AddRelatedContactType>[] = [
    { label: 'PAGE.CONTACTS.RELATE.CREATE_NEW', object: 'create' },
    { label: 'PAGE.CONTACTS.RELATE.RELATE_EXISTING', object: 'relate' },
  ];

  destroyRef = inject(DestroyRef);

  relationKinds$!: Observable<RelationKindDto[]>;

  constructor(
    fb: FormBuilder,
    dialogRef: MatDialogRef<AddRelatedContactDialogComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) protected data: DialogData,
    private baseDataService: BaseDataService,
    private accessService: AccessService,
  ) {
    super(dialogRef);

    const isDisabled = !!data.preselectedKind;

    this.relationKinds$ = this.baseDataService.getBaseData().pipe(map(baseData => baseData.relationKinds));

    const preselectedKind = data.preselectedKind;
    this.form = fb.group({
      type: fb.nonNullable.control<AddRelatedContactType>('create'),
      kind: fb.control({ value: preselectedKind ?? null, disabled: isDisabled }),
      contact: fb.control<ContactDto | null>(null),
      fromDate: fb.control<string | null>(null),
      toDate: fb.control<string | null>(null),
    });

    this.accessService.disableBasedOnRoleRestriction(this.form, GENERAL_WRITE_EXCLUDE);

    if (!preselectedKind) {
      this.relationKinds$.pipe(take(1)).subscribe(kinds => {
        const ownerKind = kinds.find(kind => kind.tag === RelationKindTag.Owner);
        if (ownerKind) {
          this.form.controls.kind.setValue(ownerKind, { emitEvent: false });
        }
      });
    }

    this.form.controls.type.valueChanges.pipe(startWith(null), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const value = this.form.getRawValue().type;
      if (value === 'create') {
        this.form.controls.contact.setValidators(null);
      } else {
        this.form.controls.contact.setValidators([Validators.required]);
      }

      this.form.controls.contact.updateValueAndValidity();
    });
  }

  close() {
    // Need raw value since 'kind' may be disabled
    const value = this.form.getRawValue();

    if (value.type === 'create') {
      this.closeWithResult({ type: 'create', fromDate: value.fromDate, toDate: value.toDate });
    } else {
      if (!value.contact || !value.kind) {
        return;
      }

      this.closeWithResult({
        type: 'relate',
        contactId: value.contact.id,
        kind: value.kind,
        fromDate: value.fromDate,
        toDate: value.toDate,
      });
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class RelateContactDialogService {
  constructor(private modalService: ModalService) {}

  openCreateOrRelateContactDialog(data: DialogData = {}): Observable<DialogResult> {
    return this.modalService
      .open(AddRelatedContactDialogComponent, data, { width: ModalWidth.Medium })
      .afterClosed()
      .pipe(filter(notNullish));
  }
}
