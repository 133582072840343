import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { Observable, switchMap, take } from 'rxjs';

import { environment } from '../../environments/environment';

export const skipJwtInterceptor = new HttpContextToken<boolean>(() => false);

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  auth = inject(Auth);
  idToken$ = idToken(this.auth);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(skipJwtInterceptor)) {
      return next.handle(request);
    }

    const isApiUrl = request.url.startsWith(environment.apiUrl);

    return this.idToken$.pipe(
      take(1),
      switchMap(token => {
        if (token && isApiUrl) {
          request = request.clone({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            setHeaders: { Authorization: `Bearer ${token}` },
          });
        }

        return next.handle(request);
      }),
    );
  }
}
