<tgn-narrow-page-container>
  <div class="container">
    <h1>{{ 'PAGE.WILD_ANIMAL_ENTRY_CHECK.TITLE' | translate }}</h1>

    <form class="form-grid grid-cols-6">
      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.group"
            [hideError]="true"
            [id]="'group'"
            [label]="'ENTITY.Animal.group'"
            class="row-span-3 mt-medium"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select
                [choices]="taxonomyBaseData.groups | toRadioChoice: 'name'"
                [compareIds]="true"
                [control]="control"
                [id]="id"
                [includeEmptyChoice]="true"
                [searchable]="true"
              />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.subGroup"
            [hideError]="true"
            [id]="'subGroups'"
            [label]="'ENTITY.Animal.subGroup'"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select
                [choices]="taxonomyBaseData.subGroups | subGroupChoices: form.controls.group.value | toRadioChoice: 'name'"
                [compareIds]="true"
                [control]="control"
                [id]="id"
                [includeEmptyChoice]="true"
                [searchable]="true"
              />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.species"
            [hideError]="true"
            [id]="'species'"
            [label]="'ENTITY.Animal.species'"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select
                [choices]="
                  taxonomyBaseData.species
                    | addIfMissing: form.controls.species.value
                    | speciesChoices: form.controls.group.value : form.controls.subGroup.value : taxonomyBaseData.subGroups
                    | toRadioChoice: 'name'
                "
                [compareIds]="true"
                [control]="control"
                [id]="id"
                [searchable]="true"
              />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="form.controls.roughAge" [id]="'roughAge'" [label]="'ENTITY.Animal.roughAge'" class="row-span-3">
            <ng-template formElement let-control="control" let-id="id">
              <div class="form-grid grid-cols-3 grid-cols-sm-1">
                <tgn-radio-group [choices]="roughAgeChoices" [control]="form.controls.roughAge" [id]="id" class="full-width" />
              </div>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.generalCondition"
            [id]="'generalCondition'"
            [label]="'ENTITY.EntryCheck.generalCondition'"
            [optional]="false"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <div class="form-grid grid-cols-4 grid-cols-sm-1">
                <tgn-radio-group [choices]="generalFieldChoices" [class]="'full-width'" [control]="control" [id]="id" />
              </div>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.generalConditionNote"
            [id]="'generalConditionNote'"
            [label]="'GENERAL.FORM.LABEL.NOTE'"
            [optional]="true"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-textarea
                [control]="form.controls.generalConditionNote"
                [id]="id"
                [placeholder]="'GENERAL.FORM.PLACEHOLDER.GENERAL_STATE' | translate"
                [triggerAutosize]="true"
              />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      @if (isHedgehog()) {
        <div class="row">
          <div class="item col-span-6">
            <tgn-form-element
              [control]="form.controls.weight"
              [id]="'weight'"
              [label]="'ENTITY.EntryCheck.weight'"
              [optional]="false"
              class="row-span-3"
            >
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [formControl]="control"
                    [id]="id"
                    [placeholder]="'PAGE.WILD_ANIMAL_ENTRY_CHECK.FORM.PLACEHOLDER.weight' | translate"
                    type="text"
                  />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
      }

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.box"
            [hideError]="true"
            [id]="'accessories'"
            [label]="'PAGE.ENTRY_CHECK.FORM.LABEL.BOX'"
            [optional]="false"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select
                [choices]="boxes | addIfMissing: form.controls.box.value | toRadioChoice: 'name'"
                [compareIds]="true"
                [control]="control"
                [id]="id"
                [placeholder]="'ENTITY.EntryCheck.box' | translate"
                [searchable]="true"
              />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    </form>

    <div class="bottom-action-bar">
      <button
        (click)="finish()"
        [disabled]="form.invalid"
        [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
        class="tgn-button ml-md"
        mat-flat-button
        type="button"
      >
        @if (isEdit) {
          {{ 'GENERAL.BUTTON.CLOSE' | translate }}
        } @else {
          {{ 'GENERAL.BUTTON.FINISH' | translate }}
        }
      </button>
    </div>
  </div>
</tgn-narrow-page-container>
