<div class="tgn-redactor">
  <mat-form-field appearance="outline" class="editor-textarea-container">
    <textarea
      #redactorTextArea
      [disabled]="control.disabled"
      [formControl]="control"
      [placeholder]="placeholder"
      class="redactor-textarea editor-textarea"
      matInput
    ></textarea>
  </mat-form-field>
</div>
