import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ImageDto } from '../dto/file.dto';

@Pipe({ name: 'file', standalone: true })
export class FilePipe implements PipeTransform {
  constructor(private http: HttpClient) {}

  transform(image: ImageDto): Observable<string | null> {
    return this.http.get(`${environment.apiUrl}${image.url}`, { responseType: 'blob' }).pipe(
      switchMap(blob => {
        return new Observable<string | null>(observer => {
          const objectURL = URL.createObjectURL(blob);
          observer.next(objectURL);

          return () => {
            URL.revokeObjectURL(objectURL);
          };
        });
      }),
    );
  }
}
