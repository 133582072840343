<div
  class="snackbar"
  [ngClass]="{
    error: data.messageType == 'error',
    warning: data.messageType == 'warning',
    success: data.messageType == 'success',
    pending: data.messageType == 'pending'
  }"
>
  <div class="content">
    <div class="title">{{ data.title | translate }}</div>
    <div class="message">{{ data.message | translate }}</div>
  </div>
  <div class="close" (click)="close()">
    <button
      mat-flat-button
      class="tgn-button"
      [ngClass]="{
        error: data.messageType == 'error',
        warning: data.messageType == 'warning',
        success: data.messageType == 'success',
        pending: data.messageType == 'pending'
      }"
    >
      OK
    </button>
  </div>
</div>
