import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { BaseDataPositionDto, ReplaceBaseDataPositionDto } from '@baseData/dtos/base-data-position.dto';
import { VatTaxType } from '@baseData/dtos/vat-tax.dto';
import { BaseDataPositionService } from '@baseData/services/base-data-position.service';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { ModalComponent } from '@core/services/modal.service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import moment from 'moment';

export interface ReplaceBaseDataPositionDialogData {
  baseDataPosition: BaseDataPositionDto;
}

export interface ReplaceBaseDataPositionDialogResult {}

export type ReplaceForm = FormGroup<{
  previousPosition: FormControl<BaseDataPositionDto>;
  name: FormControl<string>;
  description: FormControl<string | null>;
  replaceExisting: FormControl<boolean>;
  price: FormControl<number | null>;
  vatTaxType: FormControl<VatTaxType>;
  validFrom: FormControl<IsoLocalDateString>;
  validTo: FormControl<IsoLocalDateString | null>;
}>;

@Component({
  selector: 'app-replace-base-position-dialog',
  standalone: true,
  imports: [
    DateInputComponent,
    FormElementComponent,
    FormElementDirective,
    FormsModule,
    MatButton,
    MatCheckbox,
    MatDialogActions,
    TranslateModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormField,
    SelectComponent,
    MatDialogModule,
    MatInput,
  ],
  templateUrl: './replace-base-position-dialog.component.html',
  styleUrl: './replace-base-position-dialog.component.scss',
})
export class ReplaceBasePositionDialogComponent extends ModalComponent<
  ReplaceBaseDataPositionDialogData,
  ReplaceBaseDataPositionDialogResult
> {
  form!: ReplaceForm;

  vatTaxTypeChoices: RadioChoice<VatTaxType>[] = createEnumChoices(VatTaxType, 'GENERAL.DOMAIN.VatTaxType.');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReplaceBaseDataPositionDialogData,
    dialogRef: MatDialogRef<ReplaceBasePositionDialogComponent, ReplaceBaseDataPositionDialogData>,
    private baseDataPositionService: BaseDataPositionService,
    private fb: FormBuilder,
    private accessService: AccessService,
  ) {
    super(dialogRef);
    this.form = this.fb.group({
      previousPosition: this.fb.nonNullable.control<BaseDataPositionDto>(
        {
          value: this.data.baseDataPosition,
          disabled: true,
        },
        [Validators.required],
      ),
      name: this.fb.nonNullable.control<string>(this.data.baseDataPosition.name, [Validators.required]),
      description: this.fb.control<string | null>(this.data.baseDataPosition.description),
      replaceExisting: this.fb.nonNullable.control<boolean>(true),
      price: this.fb.control<number | null>(this.data.baseDataPosition.price, []),
      vatTaxType: this.fb.nonNullable.control<VatTaxType>(this.data.baseDataPosition.vatTaxType, [Validators.required]),
      validFrom: this.fb.nonNullable.control<IsoLocalDateString>(moment().format('yyyy-MM-DD'), [Validators.required]),
      validTo: this.fb.control(this.data.baseDataPosition.validTo),
    });

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Backoffice);
  }

  close() {
    const values = this.form.getRawValue();
    const dto: ReplaceBaseDataPositionDto = {
      ...values,
      previousPositionId: this.data.baseDataPosition.id,
      price: values.price,
    };

    this.baseDataPositionService.replace(dto).subscribe(() => {
      this.closeWithResult({});
    });
  }
}
