<div class="actions">
  <tgn-select
    [choices]="boxes | addIfMissing: form.controls.box.value | toRadioChoice: 'name'"
    [compareIds]="true"
    [control]="form.controls.box"
    [placeholder]="'ENTITY.Animal.box' | translate"
    [searchable]="true"
    class="responsive"
  />

  <tgn-select
    [choices]="animalStates"
    [control]="form.controls.state"
    [placeholder]="'ENTITY.Animal.state' | translate"
    [searchable]="true"
    class="responsive"
  />

  <tgn-select
    [choices]="placementStates"
    [control]="form.controls.placementState"
    [placeholder]="'ENTITY.Animal.placementState' | translate"
    [searchable]="true"
    class="responsive"
  />
</div>
