<div *tgnContextActions class="tgn-context-actions">
  <button
    (click)="openAddContactDialog()"
    [tgnContextItem]="{ object: caseDetailStore.case$ }"
    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
    class="tgn-button"
    mat-flat-button
  >
    Kontakt hinzufügen
  </button>
</div>

<div class="content mt-large">
  @if (datasource) {
    <table #table="matTable" [dataSource]="datasource" mat-table>
      <tr *matHeaderRowDef="columns" mat-header-row></tr>
      <tr *matRowDef="let rel; table: table; columns: columns" (click)="editContact(rel)" class="table-row-link" mat-row></tr>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.status' | translate }}</th>
        <td *matCellDef="let rel; table: table" mat-cell>{{ rel.contact.status | enumDisplay: 'GENERAL.DOMAIN.ContactStatus' }}</td>
      </ng-container>

      <ng-container matColumnDef="personNumber">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.personNumber' | translate }}</th>
        <td *matCellDef="let rel; table: table" mat-cell>{{ rel.contact.personNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.firstName' | translate }}</th>
        <td *matCellDef="let rel; table: table" mat-cell>{{ rel.contact.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.lastName' | translate }}</th>
        <td *matCellDef="let rel; table: table" mat-cell>{{ rel.contact.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="organization">
        <th *matHeaderCellDef mat-header-cell>{{ 'PAGE.ANIMAL.DETAIL.CONTACT.organization' | translate }}</th>
        <td *matCellDef="let rel; table: table" mat-cell>{{ rel.contact.company }}</td>
      </ng-container>

      <ng-container matColumnDef="kind">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.type' | translate }}</th>
        <td *matCellDef="let rel; table: table" mat-cell>
          {{ rel.kind.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="fromToDate">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.fromToDate' | translate }}</th>
        <td *matCellDef="let rel; table: table" mat-cell>
          <span>{{ rel.fromDate | date }}</span>
          @if (rel.fromDate || rel.toDate) {
            <span class="separator">-</span>
          }
          <span>{{ rel.toDate | date }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.note' | translate }}</th>
        <td *matCellDef="let rel; table: table" mat-cell>
          <div class="max-width-column">
            <tgn-single-line-text [text]="rel.contact.note ?? ''" />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.createdDate' | translate }}</th>
        <td *matCellDef="let rel; table: table" mat-cell>
          {{ rel.contact.createdDate | stringToDate | date: 'dd.MM.yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="animal">
        <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.DOMAIN.ANIMAL' | translate }}</th>
        <td *matCellDef="let rel; table: table" mat-cell>
          <div class="max-width-column">
            <tgn-single-line-text [text]="rel.animal?.name ?? ''" />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.BUTTON.MENU_ACTIONS' | translate }}</th>
        <td *matCellDef="let rel; table: table" mat-cell>
          <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ rel: rel }" [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <ng-template let-rel="rel" matMenuContent>
              <a [routerLink]="appRoutes.CONTACT_DETAIL.url(rel.contact.id)" mat-menu-item>
                <span>{{ 'PAGE.CASE.DETAIL.ACTIONS.TO_CONTACT' | translate }}</span>
              </a>
              <button
                [tgnContextItem]="{ object: caseDetailStore.case$ }"
                [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
                (click)="editContact(rel)"
                mat-menu-item
              >
                <span>{{ 'GENERAL.BUTTON.EDIT' | translate }}</span>
              </button>
              <button
                [tgnContextItem]="{ object: caseDetailStore.case$ }"
                [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
                (agreeEvent)="removeContactFromCase(rel)"
                appConfirmationDialog
                [dialogTitle]="
                  (rel.animal ? 'PAGE.ANIMAL.DETAIL.REMOVE_CONTACT_TITLE' : 'PAGE.CASE.DETAIL.REMOVE_CONTACT_TITLE') | translate
                "
                [yesButtonLabel]="'GENERAL.BUTTON.REMOVE' | translate"
                mat-menu-item
              >
                <span>
                  {{ (rel.animal ? 'PAGE.ANIMAL.DETAIL.ACTIONS.REMOVE_CONTACT' : 'PAGE.CASE.DETAIL.ACTIONS.REMOVE_CONTACT') | translate }}
                </span>
              </button>
            </ng-template>
          </mat-menu>
        </td>
      </ng-container>
    </table>
  }
</div>
