import { Pipe, PipeTransform } from '@angular/core';

import { GroupDto, SubGroupDto } from '../../case/dtos/base-data.dto';

@Pipe({
  name: 'subGroupChoices',
  standalone: true,
})
export class SubGroupChoicesPipe implements PipeTransform {
  transform(subGroups: SubGroupDto[], currentGroup: GroupDto | null | undefined): SubGroupDto[] {
    if (!currentGroup) {
      return subGroups;
    }
    return subGroups.filter(subGroup => subGroup.groupId === currentGroup.id);
  }
}
