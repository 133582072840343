import { DatePipe, NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { MatTooltip } from '@angular/material/tooltip';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { TranslateModule } from '@ngx-translate/core';

import { AuditChangeUnion, isAuditCreate, isAuditDelete, isAuditUpdate } from '../../dtos/auditChange';
import { AuditDiffComponent } from '../audit-diff/audit-diff.component';

@Component({
  selector: 'tgn-audit-change',
  standalone: true,
  imports: [AuditDiffComponent, DatePipe, MatChip, TranslateModule, NgClass, MatTooltip, SingleLineTextComponent],
  templateUrl: './audit-change.component.html',
  styleUrl: './audit-change.component.scss',
})
export class AuditChangeComponent {
  @Input({ required: true }) change!: AuditChangeUnion;
  protected readonly isAuditCreate = isAuditCreate;
  protected readonly isAuditDelete = isAuditDelete;
  protected readonly isAuditUpdate = isAuditUpdate;
}
