/* eslint  @typescript-eslint/naming-convention: 0 */

import { AnimalId } from '@animal/dtos/animal.dto';
import { BillId } from '@bill/dto/bill.dto';
import { CaseId } from '@case/dtos/case.dto';
import { ContactId } from '@contact/dto/contact-list-view.dto';
import { ProspectId } from '@prospect/dto/prospect.dto';

export const APP_NAME = 'Tigon';

export const routes_config = {
  ROOT: {
    path: '',
    url: () => {
      return [routes_config.ROOT.path];
    },
  },
  DEACTIVATED_PAGE: {
    path: 'deactivated',
    url: () => {
      return ['/', routes_config.DEACTIVATED_PAGE.path];
    },
  },
  LOGIN: {
    path: 'auth/login',
    url: () => {
      return ['/', 'auth', 'login'];
    },
  },
  SET_PASSWORD: {
    path: 'auth/set-password',
    url: () => {
      return ['/', 'auth', 'set-password'];
    },
  },
  CHOOSE_TEAM_AND_STATION: {
    path: 'auth/choose-team',
    url: () => {
      return ['/', 'auth', 'choose-team'];
    },
  },
  DASHBOARD: {
    path: 'dashboard',
    url: () => {
      return ['/', routes_config.DASHBOARD.path];
    },
  },
  INBOX: {
    path: 'inbox',
    url: () => {
      return ['/', routes_config.INBOX.path];
    },
  },
  SEARCH: {
    path: 'search',
    url: () => {
      return ['/', routes_config.SEARCH.path];
    },
  },
  CASES: {
    path: 'cases',
    url: () => {
      return ['/', routes_config.CASES.path];
    },
  },

  CASE_SPECIFIC: {
    path: ':caseId',
    url: (caseId: CaseId) => {
      return ['/', routes_config.CASES.path, caseId];
    },
  },

  CASE_DETAIL: {
    path: 'detail',
    url: (caseId: CaseId) => {
      return [...routes_config.CASE_SPECIFIC.url(caseId), 'detail'];
    },
  },
  CASE_DETAIL_GENERAL: {
    path: 'general',
    url: (caseId: CaseId) => {
      return [...routes_config.CASE_DETAIL.url(caseId), routes_config.CASE_DETAIL_GENERAL.path];
    },
  },
  CASE_DETAIL_TASKS: {
    path: 'tasks',
    url: (caseId: CaseId) => {
      return [...routes_config.CASE_DETAIL.url(caseId), routes_config.CASE_DETAIL_TASKS.path];
    },
  },
  CASE_DETAIL_ANIMALS: {
    path: 'animals',
    url: (caseId: CaseId) => {
      return [...routes_config.CASE_DETAIL.url(caseId), routes_config.CASE_DETAIL_ANIMALS.path];
    },
  },
  CASE_DETAIL_PLACEMENT: {
    path: 'placement',
    url: (caseId: CaseId) => {
      return [...routes_config.CASE_DETAIL.url(caseId), routes_config.CASE_DETAIL_PLACEMENT.path];
    },
  },
  CASE_DETAIL_PROSPECT_DETAILS: {
    path: ':prospectId',
    url: (caseId: CaseId, prospectId: ProspectId) => {
      return [...routes_config.CASE_DETAIL_PLACEMENT.url(caseId), prospectId];
    },
  },
  CASE_DETAIL_CONTACTS: {
    path: 'contacts',
    url: (caseId: CaseId) => {
      return [...routes_config.CASE_DETAIL.url(caseId), routes_config.CASE_DETAIL_CONTACTS.path];
    },
  },
  CASE_DETAIL_BILLING_ITEMS: {
    path: 'invoiceListings',
    url: (caseId: CaseId) => {
      return [...routes_config.CASE_DETAIL.url(caseId), routes_config.CASE_DETAIL_BILLING_ITEMS.path];
    },
  },
  CASE_DETAIL_BILLS: {
    path: 'bills',
    url: (caseId: CaseId) => {
      return [...routes_config.CASE_DETAIL.url(caseId), routes_config.CASE_DETAIL_BILLS.path];
    },
  },
  CASE_DETAIL_FILES: {
    path: 'files',
    url: (caseId: CaseId) => {
      return [...routes_config.CASE_DETAIL.url(caseId), routes_config.CASE_DETAIL_FILES.path];
    },
  },
  CASE_DETAIL_LOG: {
    path: 'log',
    url: (caseId: CaseId) => {
      return [...routes_config.CASE_DETAIL.url(caseId), routes_config.CASE_DETAIL_LOG.path];
    },
  },
  CASE_DETAIL_ANIMAL_ENTRYCHECK: {
    path: 'entryCheck/:caseId/:animalId',
    url: (caseId: CaseId, animalId: AnimalId) => {
      return ['/', routes_config.CASES.path, 'entryCheck', caseId, animalId];
    },
  },
  CASE_CREATE_BILL: {
    path: 'new-bill',
    url: (caseId: CaseId) => {
      return [...routes_config.CASE_SPECIFIC.url(caseId), routes_config.CASE_CREATE_BILL.path];
    },
  },
  CASE_EDIT_BILL: {
    path: 'bill/:billId',
    url: (caseId: CaseId, billId: BillId) => {
      return [...routes_config.CASE_SPECIFIC.url(caseId), 'bill', billId];
    },
  },
  ANIMALS: {
    path: 'animal',
    url: () => {
      return ['/', routes_config.ANIMALS.path];
    },
  },

  ANIMAL_SPECIFIC: {
    path: ':animalId',
    url: (animalId: AnimalId) => {
      return ['/', routes_config.ANIMALS.path, animalId];
    },
  },

  ANIMAL_DETAIL: {
    path: 'detail',
    url: (animalId: AnimalId) => {
      return [...routes_config.ANIMAL_SPECIFIC.url(animalId), 'detail'];
    },
  },
  ANIMAL_DETAIL_GENERAL: {
    path: 'general',
    url: (animalId: AnimalId) => {
      return [...routes_config.ANIMAL_DETAIL.url(animalId), 'general'];
    },
  },
  ANIMAL_DETAIL_TASKS: {
    path: 'tasks',
    url: (animalId: AnimalId) => {
      return [...routes_config.ANIMAL_DETAIL.url(animalId), 'tasks'];
    },
  },
  ANIMAL_DETAIL_CASES: {
    path: 'cases',
    url: (animalId: AnimalId) => {
      return [...routes_config.ANIMAL_DETAIL.url(animalId), 'cases'];
    },
  },
  ANIMAL_DETAIL_PLACEMENT: {
    path: 'placement',
    url: (animalId: AnimalId) => {
      return [...routes_config.ANIMAL_DETAIL.url(animalId), 'placement'];
    },
  },
  ANIMAL_DETAIL_SERVICES: {
    path: 'services',
    url: (animalId: AnimalId) => {
      return [...routes_config.ANIMAL_DETAIL.url(animalId), 'services'];
    },
  },
  ANIMAL_DETAIL_CONTACTS: {
    path: 'contacts',
    url: (animalId: AnimalId) => {
      return [...routes_config.ANIMAL_DETAIL.url(animalId), 'contacts'];
    },
  },
  ANIMAL_DETAIL_PHOTOS: {
    path: 'photos',
    url: (animalId: AnimalId) => {
      return [...routes_config.ANIMAL_DETAIL.url(animalId), 'photos'];
    },
  },
  ANIMAL_DETAIL_FILES: {
    path: 'files',
    url: (animalId: AnimalId) => {
      return [...routes_config.ANIMAL_DETAIL.url(animalId), 'files'];
    },
  },

  ANIMAL_DETAIL_LOG: {
    path: 'log',
    url: (animalId: AnimalId) => {
      return [...routes_config.ANIMAL_DETAIL.url(animalId), 'log'];
    },
  },

  ANIMAL_EXITCHECK: {
    path: 'exitCheck/:caseId',
    url: (caseId: CaseId, animalId: AnimalId) => {
      return [...routes_config.ANIMAL_SPECIFIC.url(animalId), 'exitCheck', caseId];
    },
  },

  DOMESTIC_ENTRYCHECK_EDIT: {
    path: 'entryCheck/:caseId/domestic-edit',
    url: (caseId: CaseId, animalId: AnimalId) => {
      return [...routes_config.ANIMAL_SPECIFIC.url(animalId), 'entryCheck', caseId, 'domestic-edit'];
    },
  },

  WILD_ENTRYCHECK_EDIT: {
    path: 'entryCheck/:caseId/wild-edit',
    url: (caseId: CaseId, animalId: AnimalId) => {
      return [...routes_config.ANIMAL_SPECIFIC.url(animalId), 'entryCheck', caseId, 'wild-edit'];
    },
  },

  CARE: {
    path: 'care',
    url: () => {
      return ['/', routes_config.CARE.path];
    },
  },
  CARE_ANIMAL: {
    path: 'animal/:animalId',
    url: (animalId: AnimalId) => {
      return ['/', routes_config.CARE.path, 'animal', animalId];
    },
  },
  CONTACTS: {
    path: 'contacts',
    url: () => {
      return ['/', routes_config.CONTACTS.path];
    },
  },
  CONTACT_DETAIL: {
    path: ':id',
    url: (contactId: ContactId) => {
      return ['/', routes_config.CONTACTS.path, contactId];
    },
  },
  CONTACT_DETAIL_GENERAL: {
    path: 'general',
    url: (contactId: ContactId) => {
      return ['/', routes_config.CONTACTS.path, contactId, routes_config.CONTACT_DETAIL_GENERAL.path];
    },
  },
  CONTACT_DETAIL_CASES: {
    path: 'cases',
    url: (contactId: ContactId) => {
      return ['/', routes_config.CONTACTS.path, contactId, routes_config.CONTACT_DETAIL_CASES.path];
    },
  },
  CONTACT_DETAIL_ANIMALS: {
    path: 'animals',
    url: (contactId: ContactId) => {
      return ['/', routes_config.CONTACTS.path, contactId, routes_config.CONTACT_DETAIL_ANIMALS.path];
    },
  },
  CONTACT_DETAIL_LOG: {
    path: 'log',
    url: (contactId: ContactId) => {
      return ['/', routes_config.CONTACTS.path, contactId, routes_config.CONTACT_DETAIL_LOG.path];
    },
  },
  STATISTICS: {
    path: 'statistics',
    url: () => {
      return ['/', routes_config.STATISTICS.path];
    },
  },
  CASE: {
    path: 'case',
    url: () => {
      return ['/', routes_config.ANIMALS.path, routes_config.CASE.path];
    },
  },
  CASE_NEW_EXISTING: {
    path: ':id/new-case',
    url: (caseId: CaseId) => {
      return ['/', routes_config.ANIMALS.path, routes_config.CASE.path, caseId, 'new-case'];
    },
  },

  ENTRYCHECK: {
    path: ':caseId/entrycheck/:index',
    url: (caseId: CaseId, index: number) => {
      return ['/', routes_config.ANIMALS.path, routes_config.CASE.path, caseId, 'entrycheck', index];
    },
  },
  WILD_ANIMAL_ENTRYCHECK: {
    path: ':caseId/wildtiere/:animalId',
    url: (caseId: CaseId, animalId: AnimalId) => {
      return ['/', routes_config.ANIMALS.path, routes_config.CASE.path, caseId, 'wildtiere', animalId];
    },
  },

  SETTINGS: {
    path: 'settings',
    url: () => {
      return ['/', routes_config.SETTINGS.path];
    },
  },

  SETTINGS_AUDIT_LOG: {
    path: 'audit',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_AUDIT_LOG.path];
    },
  },

  SETTINGS_ARCHIVE: {
    path: 'archive',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_ARCHIVE.path];
    },
  },

  SETTINGS_ANIMAL_SHELTER: {
    path: 'animal_shelter',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_ANIMAL_SHELTER.path];
    },
  },

  SETTINGS_USERS: {
    path: 'users',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_USERS.path];
    },
  },

  // Base Data
  SETTINGS_BASE_DATA: {
    path: 'base_data',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_BASE_DATA.path];
    },
  },

  SETTINGS_BASE_DATA_GENERAL: {
    path: 'general',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_BASE_DATA.path, routes_config.SETTINGS_BASE_DATA_GENERAL.path];
    },
  },

  SETTINGS_BASE_DATA_ANIMAL: {
    path: 'animal',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_BASE_DATA.path, routes_config.SETTINGS_BASE_DATA_ANIMAL.path];
    },
  },

  SETTINGS_BASE_DATA_CASE: {
    path: 'case',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_BASE_DATA.path, routes_config.SETTINGS_BASE_DATA_CASE.path];
    },
  },

  SETTINGS_BASE_DATA_TASKS: {
    path: 'care',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_BASE_DATA.path, routes_config.SETTINGS_BASE_DATA_TASKS.path];
    },
  },

  SETTINGS_BASE_DATA_BOXES: {
    path: 'boxes',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_BASE_DATA.path, routes_config.SETTINGS_BASE_DATA_BOXES.path];
    },
  },

  SETTINGS_BASE_DATA_MEDICATION: {
    path: 'medication',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_BASE_DATA.path, routes_config.SETTINGS_BASE_DATA_MEDICATION.path];
    },
  },

  SETTINGS_BASE_DATA_VAT_TAX: {
    path: 'mwst',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_BASE_DATA.path, routes_config.SETTINGS_BASE_DATA_VAT_TAX.path];
    },
  },

  SETTINGS_BASE_DATA_POSITIONS: {
    path: 'positions',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_BASE_DATA.path, routes_config.SETTINGS_BASE_DATA_POSITIONS.path];
    },
  },

  // Profile
  SETTINGS_PROFILE: {
    path: 'profile',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_PROFILE.path];
    },
  },

  SETTINGS_PREFERENCES: {
    path: 'preferences',
    url: () => {
      return ['/', routes_config.SETTINGS.path, routes_config.SETTINGS_PREFERENCES.path];
    },
  },
};

export const DEFAULT_PAGE_SIZE = 20;
