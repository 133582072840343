import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatMenuItem } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { AnimalRaceInputComponent } from '@animal/components/animal-race-input/animal-race-input.component';
import { PlacementMediationComponent } from '@animal/components/placement-mediation/placement-mediation.component';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { AnimalTaxonomyBaseData } from '@animal/model/animal';
import { AnimalNamePipe } from '@animal/pipes/animal-name.pipe';
import { AnimalService } from '@animal/services/animal.service';
import { CaseReadOnlyInfoComponent } from '@case/components/case-read-only-info/case-read-only-info.component';
import { BaseDataDto, GroupDto, GroupTag, SpeciesDto, SubGroupDto } from '@case/dtos/base-data.dto';
import { CompactCaseAnimalDto } from '@case/dtos/case-animal.dto';
import { CaseDto } from '@case/dtos/case.dto';
import {
  IllnessKind,
  PlacementDetailDto,
  TransferMaterial,
  UpdatePlacementDetailDto,
  VeterinaryExaminationCondition,
} from '@case/dtos/placement.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { CaseAnimalService } from '@case/services/case-animal.service';
import { CheckboxGroupComponent } from '@core/components/checkbox-group/checkbox-group.component';
import { ChipInputComponent } from '@core/components/chip-input/chip-input.component';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { routes_config } from '@core/constants';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { ContextItemDirective } from '@core/directives/context-item.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AnimalSex, AnimalType, CastrationType, FurLength, PlacementState, PlacementType, UnitsLength } from '@core/models/general';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { CastPipe } from '@core/pipes/cast.pipe';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { defaultDebounce } from '@core/services/base-service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { UserDto } from '@user/dto/user.dto';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { CurrentUserService } from '@user/service/current-user.service';
import { Observable, combineLatest, of, switchMap, take } from 'rxjs';

import { AnimalDetailStore } from '../animal-detail-layout/animal-detail-layout.component';

interface AnimalFormGroup {
  name: FormControl<string | null>;
  chipId: FormControl<string | null>;
  ringId: FormControl<string | null>;
  type: FormControl<AnimalType>;
  group: FormControl<GroupDto | null>;
  subGroup: FormControl<SubGroupDto | null>;
  species: FormControl<SpeciesDto | null>;
  race: FormControl<string | null>;
  raceNote: FormControl<string | null>;
  coloring: FormControl<string | null>;
  furLength: FormControl<FurLength | null>;
  sex: FormControl<AnimalSex | null>;
  birthdate: FormControl<IsoLocalDateString | null>;
  turtleShellLength: FormControl<string | null>;
  turtleShellLengthUnit: FormControl<UnitsLength | null>;
  castrated: FormControl<boolean | null>;
  behaviour: FormControl<string | null>;
}

export type PlacementForm = FormGroup<{
  placementType: FormControl<PlacementType | null>;
  placementState: FormControl<PlacementState | null>;
  mediationHasProspect: FormControl<boolean | null>;
  mediationHasProspectNote: FormControl<string | null>;
  mediationHasPlacementDate: FormControl<boolean | null>;
  mediationHasPlacementDateNote: FormControl<string | null>;
  mediationIsInTrial: FormControl<boolean | null>;
  mediationIsInTrialNote: FormControl<string | null>;
  mediationPlacementSuccessful: FormControl<boolean | null>;
  mediationPlacementSuccessfulNote: FormControl<string | null>;
  mediationPhonePlacementSupervision: FormControl<boolean | null>;
  mediationPhonePlacementSupervisionNote: FormControl<string | null>;
  mediationOnPremisePlacementSupervision: FormControl<boolean | null>;
  mediationOnPremisePlacementSupervisionNote: FormControl<string | null>;
  mediationCompleted: FormControl<boolean | null>;
  mediationCompletedNote: FormControl<string | null>;
  placementNote: FormControl<string | null>;
  castrationType: FormControl<CastrationType | null>;
  isCastrationCoveredByTrd: FormControl<boolean | null>;
  isVaccinatedAccordingToPassport: FormControl<boolean | null>;
  followUpVaccinationSecondIklsDate: FormControl<IsoLocalDateString | null>;
  followUpVaccinationThirdIklsDate: FormControl<IsoLocalDateString | null>;
  leukosisAndFivNegativeDate: FormControl<IsoLocalDateString | null>;
  parasiteCareDate: FormControl<IsoLocalDateString | null>;
  veterinaryExaminationCondition: FormControl<VeterinaryExaminationCondition | null>;
  illnessKinds: FormControl<IllnessKind[]>;
  illnessNote: FormControl<string | null>;
  additionalNote: FormControl<string | null>;
  behavior: FormControl<string | null>;
  transferMaterials: FormControl<TransferMaterial[]>;
  vaccinationPassHandedOver: FormControl<boolean | null>;
  fosterAnimalPassportHandedOver: FormControl<boolean | null>;
}>;

@Component({
  selector: 'app-animal-placement',
  standalone: true,
  imports: [
    CollapsibleComponent,
    AnimalRaceInputComponent,
    CastPipe,
    ChipInputComponent,
    DateInputComponent,
    FormElementComponent,
    FormElementDirective,
    ReactiveFormsModule,
    SelectComponent,
    NarrowPageContainerComponent,
    ToRadioChoicePipe,
    TranslateModule,
    MatCheckboxModule,
    RadioGroupComponent,
    CheckboxGroupComponent,
    AsyncPipe,
    TextFieldModule,
    CaseReadOnlyInfoComponent,
    MatFormField,
    MatInput,
    FullNamePipe,
    MatButton,
    MatMenuItem,
    RouterLink,
    ContextActionsDirective,
    MatAnchor,
    AnimalNamePipe,
    EnumDisplayPipe,
    PlacementMediationComponent,
    AddIfMissingPipe,
    RoleRestrictionDirective,
    ContextItemDirective,
  ],
  templateUrl: './animal-placement.component.html',
  styleUrl: './animal-placement.component.scss',
})
export class AnimalPlacementComponent implements OnInit {
  animal$: Observable<AnimalDto>;
  latestCase$!: Observable<CaseDto>;
  caseAnimal$!: Observable<CompactCaseAnimalDto>;
  baseData$: Observable<BaseDataDto>;
  animalForm?: FormGroup<AnimalFormGroup>;
  placementForm?: PlacementForm;
  furLengthChoices: RadioChoice<FurLength>[] = createEnumChoices(FurLength, 'GENERAL.DOMAIN.FurLength.');
  castrationTypeChoices: RadioChoice<CastrationType>[] = createEnumChoices(CastrationType, 'GENERAL.DOMAIN.CastrationType.');
  placement: PlacementDetailDto | null = null;

  lengthUnitChoices: RadioChoice<UnitsLength>[] = createEnumChoices(UnitsLength, 'GENERAL.DOMAIN.UnitsLength.');
  sexChoices: RadioChoice<AnimalSex>[] = createEnumChoices(AnimalSex, 'GENERAL.DOMAIN.AnimalSex.');
  taxonomyBaseData: AnimalTaxonomyBaseData = {
    groups: [],
    subGroups: [],
    species: [],
  };

  mainTypeChoices: RadioChoice<AnimalType>[] = createEnumChoices(AnimalType, 'GENERAL.DOMAIN.AnimalType.');
  placementTypeChoices: RadioChoice<PlacementType>[] = createEnumChoices(PlacementType, 'GENERAL.DOMAIN.PlacementType.');
  placementStateChoices: RadioChoice<PlacementState>[] = createEnumChoices(PlacementState, 'GENERAL.DOMAIN.PlacementState.');

  veterinaryExaminationConditionChoices: RadioChoice<VeterinaryExaminationCondition>[] = createEnumChoices(
    VeterinaryExaminationCondition,
    'GENERAL.DOMAIN.VeterinaryExaminationCondition.',
  );

  illnessKindChoices: RadioChoice<IllnessKind>[] = createEnumChoices(IllnessKind, 'GENERAL.DOMAIN.IllnessKind.');
  transferMaterialsChoices: RadioChoice<TransferMaterial>[] = createEnumChoices(TransferMaterial, 'GENERAL.DOMAIN.TransferMaterial.');
  handedInChoices: RadioChoice<boolean>[] = [
    {
      label: 'GENERAL.FORM.LABEL.YES',
      object: true,
    },
    {
      label: 'PAGE.PLACEMENT.CHOICE_HANDOVER_LATER',
      object: false,
    },
  ];

  lastEditUser: UserDto | null = null;

  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GroupTag = GroupTag;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    protected animalDetailStore: AnimalDetailStore,
    private formBuilder: FormBuilder,
    private baseDataService: BaseDataService,
    private caseAnimalService: CaseAnimalService,
    private animalService: AnimalService,
    private currentUserService: CurrentUserService,
    private accessService: AccessService,
  ) {
    this.animal$ = animalDetailStore.animal$;
    this.baseData$ = this.baseDataService.getBaseData();
  }

  ngOnInit() {
    const fb = this.formBuilder;

    this.latestCase$ = this.animal$.pipe(
      take(1),
      switchMap(animal => {
        return this.animalService.getLatestCase(animal.id);
      }),
    );

    this.caseAnimal$ = combineLatest([this.latestCase$, this.animal$]).pipe(
      take(1),
      switchMap(([caseDto, animal]) => {
        return this.caseAnimalService.getCompactCaseAnimal(caseDto.id, animal.id);
      }),
    );

    this.animal$.pipe(take(1)).subscribe((animal: AnimalDto) => {
      this.baseData$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(baseData => {
        this.taxonomyBaseData = {
          groups: AnimalType.DomesticAnimal ? baseData.domesticAnimal.groups : baseData.wildAnimal.groups,
          subGroups: AnimalType.DomesticAnimal ? baseData.domesticAnimal.subGroups : baseData.wildAnimal.subGroups,
          species: AnimalType.DomesticAnimal ? baseData.domesticAnimal.species : baseData.wildAnimal.species,
        };
      });
      const values: AnimalFormGroup = {
        name: fb.control({ value: animal.name, disabled: true }),
        type: fb.nonNullable.control({ value: animal.type, disabled: true }),
        chipId: fb.control({ value: animal.chipId, disabled: true }),
        ringId: fb.control({ value: animal.ringId, disabled: true }),
        group: fb.control({ value: animal.group ?? null, disabled: true }),
        subGroup: fb.control({ value: animal.subGroup ?? null, disabled: true }),
        species: fb.control({ value: animal.species ?? null, disabled: true }),
        race: fb.control({ value: animal.race, disabled: true }),
        raceNote: fb.control({ value: animal.raceNote, disabled: true }),
        sex: fb.control({ value: animal.sex, disabled: true }),
        birthdate: fb.control({ value: animal.birthdate, disabled: true }),
        coloring: fb.control({ value: animal.coloring, disabled: true }),
        furLength: fb.control({ value: animal.furLength, disabled: true }),
        turtleShellLength: fb.control({ value: animal.turtleShellLength, disabled: true }),
        turtleShellLengthUnit: fb.control({ value: animal.turtleShellLengthUnit, disabled: true }),
        behaviour: fb.control({ value: animal.behaviour, disabled: true }),
        castrated: fb.control({ value: animal.castrated, disabled: true }),
      };
      this.animalForm = fb.group(values);

      if (animal.archived) {
        this.animalForm.disable();
      }
      this.accessService.disableBasedOnRole(this.animalForm, RestrictedSection.Animal);

      this.latestCase$
        .pipe(
          take(1),
          switchMap(caseDto => {
            return combineLatest([this.caseAnimalService.getPlacement(caseDto.id, animal.id), of(caseDto)]);
          }),
        )
        .subscribe(([placement, caseDto]: [PlacementDetailDto, CaseDto]) => {
          this.lastEditUser = placement.lastUpdatedBy;
          this.placement = placement;

          this.placementForm = fb.group({
            placementType: fb.control(placement.placementType),
            placementState: fb.control(placement.placementState),
            mediationHasProspect: fb.control(placement.mediationHasProspect),
            mediationHasProspectNote: fb.control(placement.mediationHasProspectNote),
            mediationHasPlacementDate: fb.control(placement.mediationHasPlacementDate),
            mediationHasPlacementDateNote: fb.control(placement.mediationHasPlacementDateNote),
            mediationIsInTrial: fb.control(placement.mediationIsInTrial),
            mediationIsInTrialNote: fb.control(placement.mediationIsInTrialNote),
            mediationPlacementSuccessful: fb.control(placement.mediationPlacementSuccessful),
            mediationPlacementSuccessfulNote: fb.control(placement.mediationPlacementSuccessfulNote),
            mediationPhonePlacementSupervision: fb.control(placement.mediationPhonePlacementSupervision),
            mediationPhonePlacementSupervisionNote: fb.control(placement.mediationPhonePlacementSupervisionNote),
            mediationOnPremisePlacementSupervision: fb.control(placement.mediationOnPremisePlacementSupervision),
            mediationOnPremisePlacementSupervisionNote: fb.control(placement.mediationOnPremisePlacementSupervisionNote),
            mediationCompleted: fb.control(placement.mediationCompleted),
            mediationCompletedNote: fb.control(placement.mediationCompletedNote),
            placementNote: fb.control(placement.placementNote),
            castrationType: fb.control(placement.castrationType),
            isCastrationCoveredByTrd: fb.control(placement.isCastrationCoveredByTrd),
            isVaccinatedAccordingToPassport: fb.control(placement.isVaccinatedAccordingToPassport),
            followUpVaccinationSecondIklsDate: fb.control(placement.followUpVaccinationSecondIklsDate),
            followUpVaccinationThirdIklsDate: fb.control(placement.followUpVaccinationThirdIklsDate),
            leukosisAndFivNegativeDate: fb.control(placement.leukosisAndFivNegativeDate),
            parasiteCareDate: fb.control(placement.parasiteCareDate),
            veterinaryExaminationCondition: fb.control(placement.veterinaryExaminationCondition),
            illnessKinds: fb.nonNullable.control(placement.illnessKinds),
            illnessNote: fb.control(placement.illnessNote),
            additionalNote: fb.control(placement.additionalNote),
            behavior: fb.control(placement.behavior),
            transferMaterials: fb.nonNullable.control(placement.transferMaterials),
            vaccinationPassHandedOver: fb.control(placement.vaccinationPassHandedOver),
            fosterAnimalPassportHandedOver: fb.control(placement.fosterAnimalPassportHandedOver),
          });

          if (animal.archived || caseDto.archived) {
            this.placementForm.disable();
          }
          this.accessService.disableBasedOnRole(this.placementForm, RestrictedSection.Animal);

          this.placementForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
            const updatePlacementDetailDto: UpdatePlacementDetailDto = this.placementForm!.getRawValue();
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.caseAnimalService.updatePlacement(caseDto.id, animal.id, updatePlacementDetailDto).subscribe(() => {
              this.currentUserService.currentUser$.pipe(take(1)).subscribe(user => {
                this.lastEditUser = user;
              });

              this.caseAnimalService
                .getPlacement(caseDto.id, animal.id)
                .pipe(take(1))
                .subscribe(newPlacement => {
                  this.placement = newPlacement;
                });
            });
          });
        });
    });
  }

  updateIsCastrationCoveredByTrd($event: MatCheckboxChange) {
    this.placementForm!.controls.isCastrationCoveredByTrd.setValue($event.checked);
  }
}
