<div class="login-card">
  <h1>{{ 'Passwort setzen' }}</h1>

  @if (form) {
    <div class="form-grid grid-cols-1">
      <div class="row">
        <tgn-form-element
          [control]="form.controls.password"
          [hideError]="form.controls.password.pristine"
          [label]="'Passwort'"
          id="password"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input
                [formControl]="control"
                [id]="id"
                matInput
                placeholder=""
                type="password"
                [type]="showPassword ? 'text' : 'password'"
              />

              @if (showPassword) {
                <tgn-icon class="toggle-visibility" (click)="toggleShowPassword()" [iconName]="'radixEyeOpen'" />
              } @else {
                <tgn-icon class="toggle-visibility" (click)="toggleShowPassword()" [iconName]="'radixEyeClosed'" />
              }
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>

      <div class="row">
        <tgn-form-element
          [control]="form.controls.confirmPassword"
          [hideError]="form.controls.confirmPassword.pristine"
          [label]="'Passwort wiederholen'"
          id="confirmPassword"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" matInput placeholder="" [type]="showConfirmPassword ? 'text' : 'password'" />

              @if (showConfirmPassword) {
                <tgn-icon class="toggle-visibility" (click)="toggleShowConfirmPassword()" [iconName]="'radixEyeOpen'" />
              } @else {
                <tgn-icon class="toggle-visibility" (click)="toggleShowConfirmPassword()" [iconName]="'radixEyeClosed'" />
              }
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>

      <div class="row">
        <div class="item">
          <mat-error class="errors item-1">{{ form.errors | fieldErrorTranslation | async }}</mat-error>
        </div>
      </div>

      <div class="row">
        <button (click)="changePassword()" [disabled]="form.invalid" class="mt-medium tgn-button" mat-flat-button role="button">
          Passwort setzen
        </button>
      </div>
    </div>
  }
</div>
