import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateExitState, ExitState, ExitStateId, UpdateExitState } from '@baseData/dtos/exit-state.dto';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExitStateService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Inactive exitStates are included here
   */
  getAll(): Observable<ExitState[]> {
    return this.http.get<ExitState[]>(`${this.apiUrl}/master/exitStates`);
  }

  getAllActive(): Observable<ExitState[]> {
    return this.http.get<ExitState[]>(`${this.apiUrl}/master/exitStates/active`);
  }

  getById(id: ExitStateId): Observable<ExitState> {
    return this.http.get<ExitState>(`${this.apiUrl}/master/exitStates/${id}`);
  }

  create(dto: CreateExitState): Observable<ExitState> {
    return this.http.post<ExitState>(`${this.apiUrl}/master/exitStates`, dto);
  }

  update(id: ExitStateId, dto: UpdateExitState): Observable<ExitState> {
    return this.http.put<ExitState>(`${this.apiUrl}/master/exitStates/${id}`, dto);
  }
}
