import { Pipe, PipeTransform } from '@angular/core';
import { UserDto } from '@user/dto/user.dto';

@Pipe({
  name: 'initials',
  standalone: true,
})
export class InitialsPipe implements PipeTransform {
  transform(value: UserDto): string {
    return userInitials(value);
  }
}

function userInitials(user: UserDto): string {
  if (user.firstName && user.lastName) {
    return user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase();
  }

  const [before, after] = user.email.split('@');

  return before[0].toUpperCase() + after[0].toUpperCase();
}
