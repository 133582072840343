@if (label !== null) {
  <label [for]="id" [class.emphasizedLabel]="emphasizedLabel" class="label-no-gap">
    <span>{{ label | translate }}</span>
    @if (required) {
      <span class="text-light">*</span>
    }
  </label>
}

@if (formElement) {
  <ng-container *ngTemplateOutlet="formElement; context: { id: id, control: control }"></ng-container>
} @else {
  <ng-content></ng-content>
}

@if (control && control.invalid && !hideError) {
  <mat-error class="errors item-1">{{ control.errors | fieldErrorTranslation | async }}</mat-error>
}
