import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { AnimalImagesComponent } from '@animal/components/animal-images/animal-images.component';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { Observable } from 'rxjs';

import { AnimalDetailStore } from '../animal-detail-layout/animal-detail-layout.component';

@Component({
  selector: 'app-animal-photos',
  standalone: true,
  imports: [AsyncPipe, AnimalImagesComponent],
  templateUrl: './animal-photos.component.html',
  styleUrl: './animal-photos.component.scss',
})
export class AnimalPhotosComponent {
  animal$: Observable<AnimalDto>;

  constructor(private animalDetailStore: AnimalDetailStore) {
    this.animal$ = this.animalDetailStore.animal$;
  }
}
