<div class="overflow-container">
  <form>
    <table #table="matTable" [dataSource]="dataSource" class="table-large" mat-table>
      <tr *matHeaderRowDef="columns" mat-header-row></tr>
      <tr *matRowDef="let box; table: table; columns: columns" class="row-wide" mat-row></tr>
      <tr *matFooterRowDef="columns" class="footer-row" mat-footer-row></tr>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Box.name' | translate }}</th>
        <td *matCellDef="let box; table: table; let i = index" mat-cell>
          {{ box.name }}
        </td>

        <td *matFooterCellDef mat-footer-cell>
          <tgn-form-element [control]="newBoxForm.controls.name" [hideError]="true" [label]="'ENTITY.Box.name' | translate" id="name">
            <mat-form-field appearance="outline" class="full-width">
              <input [formControl]="newBoxForm.controls.name" matInput />
            </mat-form-field>
          </tgn-form-element>
        </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Box.active' | translate }}</th>
        <td *matCellDef="let box; table: table" mat-cell>
          @if (editBoxFormGroups.get(box.id); as group) {
            <tgn-select [choices]="activeInactiveChoice" [control]="group.controls.active" matInput />
          } @else {
            <div class="input-padding">
              {{ (box.active ? 'GENERAL.FORM.LABEL.Active' : 'GENERAL.FORM.LABEL.Inactive') | translate }}
            </div>
          }
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
    </table>
  </form>

  <mat-error [ngClass]="{ invisible: (newBoxForm.controls.name.value?.length ?? 0) === 0 }" class="errors input-padding mt-small mb-small">
    {{ newBoxForm.controls.name.errors | fieldErrorTranslation | async }}
  </mat-error>

  <button (click)="addEntry()" [disabled]="newBoxForm.invalid" class="tgn-black-button add-entry-button" mat-flat-button>
    {{ 'GENERAL.BUTTON.ADD_ENTRY' | translate }}
  </button>
</div>
