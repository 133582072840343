import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { CloseCaseDto } from '@case/dtos/close-case.dto';
import { UpdateWildAnimalStatisticsDto } from '@case/dtos/update-wild-animal-statistics.dto';
import { PageResult } from '@core/data/tigon-datasource';
import { BaseService } from '@core/services/base-service';
import { PaginatedParams } from '@core/utils/pagination';
import { Observable, of, tap } from 'rxjs';

import { CaseDetailDto, UpdateCaseDetailDto } from '../dtos/case-detail.dto';
import { CaseDto, CaseId, CaseListViewDto } from '../dtos/case.dto';
import { CreateDomesticCaseDto, CreateWildCaseDto } from '../dtos/create-case.dto';
import { NewCaseUpdateDto } from '../dtos/new-case-update.dto';
import { UpdateCaseStateDto } from '../dtos/update-case-state.dto';
import { CaseFilter, GeneralCaseFilterChoice } from '../models/case-filter';
import { NewCaseFormValues } from '../models/new-case-form';

type CasePaginatedParams = PaginatedParams & {
  filter: CaseFilter;
  generalCaseStateFilter: GeneralCaseFilterChoice;
};

@Injectable({
  providedIn: 'root',
})
export class CaseService extends BaseService {
  private caseCache: Map<CaseId, CaseDto> = new Map();

  private readonly localStorageCaseKey: string = 'case';

  constructor(private http: HttpClient) {
    super();
  }

  get(id: CaseId): Observable<CaseDto> {
    const cachedCase: CaseDto | undefined = this.caseCache.get(id);
    if (cachedCase !== undefined) {
      return of(cachedCase);
    } else {
      return this.http.get<CaseDto>(`${this.apiUrl}/cases/${id}`).pipe(
        tap((caseDto: CaseDto) => {
          this.caseCache.set(id, caseDto);
        }),
      );
    }
  }

  update(id: CaseId, dto: UpdateCaseDetailDto): Observable<CaseDetailDto> {
    return this.http.put<CaseDetailDto>(`${this.apiUrl}/cases/${id}/detail`, dto);
  }

  updateNewCase(id: CaseId, dto: NewCaseUpdateDto): Observable<CaseDetailDto> {
    return this.http.put<CaseDetailDto>(`${this.apiUrl}/cases/${id}/new-case`, dto);
  }

  updateState(id: CaseId, dto: UpdateCaseStateDto): Observable<CaseDetailDto> {
    return this.http.put<CaseDetailDto>(`${this.apiUrl}/cases/${id}/state`, dto);
  }

  updateWildAnimalStatistics(id: CaseId, dto: UpdateWildAnimalStatisticsDto): Observable<CaseDetailDto> {
    return this.http.put<CaseDetailDto>(`${this.apiUrl}/cases/${id}/wild-animal-statistics`, dto);
  }

  getDetail(id: CaseId): Observable<CaseDetailDto> {
    return this.http.get<CaseDetailDto>(`${this.apiUrl}/cases/${id}/detail`);
  }

  getCasesPaginated(params: CasePaginatedParams): Observable<PageResult<CaseListViewDto>> {
    let httpParams: HttpParams = params.filter?.toCaseFilterDto();

    const flattenedParams = {
      sort: params.sort.map(s => `${s.column}:${s.direction}`).join(':'),
      query: params.query,
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
    };

    httpParams = httpParams.appendAll(flattenedParams);
    httpParams = httpParams.append('filter.generalCaseFilter', params.generalCaseStateFilter);

    return this.http.get<PageResult<CaseListViewDto>>(`${this.apiUrl}/cases/paginated`, { params: httpParams });
  }

  getArchivedCasesPaginated(params: CasePaginatedParams): Observable<PageResult<CaseListViewDto>> {
    let httpParams: HttpParams = params.filter?.toCaseFilterDto();

    const flattenedParams = {
      sort: params.sort.map(s => `${s.column}:${s.direction}`).join(':'),
      query: params.query,
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
    };

    httpParams = httpParams.appendAll(flattenedParams);
    httpParams = httpParams.append('filter.generalCaseFilter', params.generalCaseStateFilter);

    return this.http.get<PageResult<CaseListViewDto>>(`${this.apiUrl}/cases/archived/paginated`, { params: httpParams });
  }

  create(dto: CreateDomesticCaseDto | CreateWildCaseDto): Observable<CaseDto> {
    return this.http.post<CaseDto>(`${this.apiUrl}/cases`, dto);
  }

  completeCase(id: CaseId): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/cases/${id}/complete`, {});
  }

  getAnimals(id: CaseId): Observable<AnimalDto[]> {
    return this.http.get<AnimalDto[]>(`${this.apiUrl}/cases/${id}/animals`);
  }

  saveCaseToLocalStorage(form: NewCaseFormValues) {
    localStorage.setItem(this.localStorageCaseKey, JSON.stringify(form));
  }

  getLocalStorageCase(): NewCaseFormValues | null {
    const jsonString: string | null = localStorage.getItem(this.localStorageCaseKey);
    if (jsonString == null) {
      return null;
    }
    try {
      return JSON.parse(jsonString);
    } catch (error: unknown) {
      console.error(`Could not parse localstorage case: `, jsonString, ' error: ', error);
    }
    return null;
  }

  removeLocalStorageCase() {
    localStorage.removeItem(this.localStorageCaseKey);
  }

  searchCases(query: string): Observable<PageResult<CaseListViewDto>> {
    const flattenedParams = {
      query: query,
      sort: '',
    };

    return this.http.get<PageResult<CaseListViewDto>>(`${this.apiUrl}/cases/search`, { params: flattenedParams });
  }

  closeCase(id: CaseId, dto: CloseCaseDto): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/cases/${id}/close`, dto);
  }

  archiveCase(id: CaseId): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/cases/${id}/archive`, {});
  }

  unarchiveCase(id: CaseId): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/cases/${id}/unarchive`, {});
  }
}
