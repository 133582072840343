<div class="filter">
  <div class="filter-display-row">
    <button [matMenuTriggerFor]="menu" class="side-menu-button filter-button" type="button">
      <div class="filter-button-inner">
        <span class="no-filter-active">
          {{ 'COMPONENT.FILTER.TITLE' | translate }}
        </span>
        <tgn-icon [iconName]="'radixChevronDown'" />
      </div>
    </button>

    <tgn-active-filter-group
      [label]="'COMPONENT.FILTER.GLOBAL_SEARCH_FILTER.FILTER_TYPE.ANIMAL_STATE' | translate"
      [show]="animalFilter.filters.stateFilter.values.length > 0"
    >
      @for (item of animalFilter.filters.stateFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.AnimalState'"
          (onRemove)="onRemoveSingleAnimalStateFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'ENTITY.Case.caseEntryType' | translate"
      [show]="caseFilter.filters.entryTypeFilter.values.length > 0"
    >
      @for (item of caseFilter.filters.entryTypeFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.CaseEntryType'"
          (onRemove)="onRemoveSingleEntryTypeFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Animal.group' | translate" [show]="animalFilter.filters.groupFilter.values.length > 0">
      @for (item of animalFilter.filters.groupFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleGroupFilter(item)" />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Animal.subGroup' | translate" [show]="animalFilter.filters.subGroupFilter.values.length > 0">
      @for (item of animalFilter.filters.subGroupFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleSubGroupFilter(item)" />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Animal.species' | translate" [show]="animalFilter.filters.speciesFilter.values.length > 0">
      @for (item of animalFilter.filters.speciesFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleSpeciesFilter(item)" />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Animal.box' | translate" [show]="animalFilter.filters.boxFilter.values.length > 0">
      @for (item of animalFilter.filters.boxFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleBoxFilter(item)" />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.ENTRY_DATE' | translate"
      [show]="caseFilter.filters.entryDateFilter.from !== null || caseFilter.filters.entryDateFilter.to !== null"
    >
      <tgn-selected-filter-item
        (onRemove)="onRemoveEntryDateFilter()"
        [label]="
          ((caseFilter.filters.entryDateFilter.from | date: 'dd.MM.yyyy') ?? '...') +
          ' - ' +
          ((caseFilter.filters.entryDateFilter.to | date: 'dd.MM.yyyy') ?? '...')
        "
      />
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'COMPONENT.FILTER.GLOBAL_SEARCH_FILTER.FILTER_TYPE.EXIT_DATE' | translate"
      [show]="animalFilter.filters.exitDateFilter.from !== null || animalFilter.filters.exitDateFilter.to !== null"
    >
      <tgn-selected-filter-item
        (onRemove)="onRemoveExitDateFilter()"
        [label]="
          ((animalFilter.filters.exitDateFilter.from | date: 'dd.MM.yyyy') ?? '...') +
          ' - ' +
          ((animalFilter.filters.exitDateFilter.to | date: 'dd.MM.yyyy') ?? '...')
        "
      />
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'ENTITY.Animal.mainGroup' | translate"
      [show]="animalFilter.filters.animalTypeFilter.values.length > 0"
    >
      @for (item of animalFilter.filters.animalTypeFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.AnimalType'"
          (onRemove)="onRemoveSingleAnimalTypeFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'ENTITY.Placement.exitState' | translate"
      [show]="animalFilter.filters.exitStateFilter.values.length > 0"
    >
      @for (item of animalFilter.filters.exitStateFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleExitStateFilter(item)" />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'COMPONENT.FILTER.GLOBAL_SEARCH_FILTER.FILTER_TYPE.CASE_STATE' | translate"
      [show]="caseFilter.filters.stateFilter.values.length > 0"
    >
      @for (item of caseFilter.filters.stateFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.CaseState'"
          (onRemove)="onRemoveSingleCaseStateFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'COMPONENT.FILTER.GLOBAL_SEARCH_FILTER.FILTER_TYPE.SUBMIT_REASON' | translate"
      [show]="caseFilter.filters.submitReasonFilter.values.length > 0"
    >
      @for (item of caseFilter.filters.submitReasonFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item.name" (onRemove)="onRemoveSingleSubmitReasonFilter(item)" />
      }
    </tgn-active-filter-group>

    @if (animalFilter.anyFilterActive() || caseFilter.anyFilterActive()) {
      <tgn-selected-filter-item
        class="clear-all-button"
        (click)="clearAllFilters()"
        [label]="'COMPONENT.FILTER.CLEAR_FILTER' | translate"
      />
    }
  </div>

  <mat-menu #menu class="filter-menu">
    <tgn-step-view (click)="$event.stopPropagation()" *matMenuContent>
      <ng-template [tgnStepInitial]="true" tgnStep>
        <div class="menu-item-list">
          <h3 class="group-title">Fall</h3>
          <button [tgnNextStep]="entryDateStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.ENTRY_DATE' | translate }}
          </button>
          <button [tgnNextStep]="entryTypeStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.CASE_ENTRY_TYPE' | translate }}
          </button>
          <button [tgnNextStep]="caseStateStep" class="step-button">
            {{ 'COMPONENT.FILTER.GLOBAL_SEARCH_FILTER.FILTER_TYPE.CASE_STATE' | translate }}
          </button>
          <button [tgnNextStep]="submitReasonStep" class="step-button">
            {{ 'COMPONENT.FILTER.GLOBAL_SEARCH_FILTER.FILTER_TYPE.SUBMIT_REASON' | translate }}
          </button>

          <h3 class="group-title">Tier</h3>
          <button [tgnNextStep]="animalStateStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.ANIMAL_STATE' | translate }}
          </button>
          <button [tgnNextStep]="boxStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.BOX' | translate }}
          </button>
          <button [tgnNextStep]="animalTypeStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.MAIN_GROUP' | translate }}
          </button>
          <button [tgnNextStep]="groupStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.GROUP' | translate }}
          </button>
          <button [tgnNextStep]="subGroupStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.SUB_GROUP' | translate }}
          </button>
          <button [tgnNextStep]="speciesStep" class="step-button">
            {{ 'COMPONENT.FILTER.ANIMAL_FILTER.FILTER_TYPE.SPECIES' | translate }}
          </button>
          <button [tgnNextStep]="exitDateStep" class="step-button">
            {{ 'COMPONENT.FILTER.GLOBAL_SEARCH_FILTER.FILTER_TYPE.EXIT_DATE' | translate }}
          </button>
          <button [tgnNextStep]="exitStateStep" class="step-button">
            {{ 'COMPONENT.FILTER.GLOBAL_SEARCH_FILTER.FILTER_TYPE.EXIT_STATE' | translate }}
          </button>
        </div>
      </ng-template>

      <ng-template #animalStateStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onAnimalStateFilterChange($event)"
          [filter]="animalFilter.filters.stateFilter"
          [options]="animalStateChoices"
        />
      </ng-template>

      <ng-template #entryTypeStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onEntryTypeFilterChange($event)"
          [filter]="caseFilter.filters.entryTypeFilter"
          [options]="entryTypeChoices"
        />
      </ng-template>

      <ng-template #boxStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onBoxFilterChange($event)"
          [filter]="animalFilter.filters.boxFilter"
          [options]="boxes$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #animalTypeStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onAnimalTypeFilterChange($event)"
          [filter]="animalFilter.filters.animalTypeFilter"
          [options]="animalTypeChoices"
        />
      </ng-template>

      <ng-template #groupStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onGroupFilterChange($event)"
          [filter]="animalFilter.filters.groupFilter"
          [options]="availableGroups$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #subGroupStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onSubGroupFilterChange($event)"
          [filter]="animalFilter.filters.subGroupFilter"
          [options]="availableSubGroups$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #speciesStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onSpeciesFilterChange($event)"
          [filter]="animalFilter.filters.speciesFilter"
          [options]="availableSpecies$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #entryDateStep="tgnStep" tgnStep>
        <div class="date-picker-container">
          <tgn-date-picker-range [control]="this.entryDateRangeGroup" />
        </div>
      </ng-template>

      <ng-template #exitDateStep="tgnStep" tgnStep>
        <div class="date-picker-container">
          <tgn-date-picker-range [control]="this.exitDateRangeGroup" />
        </div>
      </ng-template>

      <ng-template #exitStateStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onAnimalExitStateFilterChange($event)"
          [filter]="animalFilter.filters.exitStateFilter"
          [options]="exitStateChoices$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #caseStateStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onCaseStateFilterChange($event)"
          [filter]="caseFilter.filters.stateFilter"
          [options]="caseStateChoices"
          [searchable]="true"
        />
      </ng-template>

      <ng-template #submitReasonStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onSubmitReasonFilterChange($event)"
          [filter]="caseFilter.filters.submitReasonFilter"
          [options]="submitReasonChoices$ | async | toRadioChoice: 'name'"
          [searchable]="true"
        />
      </ng-template>
    </tgn-step-view>
  </mat-menu>
</div>
