<div *tgnContextActions class="tgn-context-actions">
  <button
    (click)="createNewTask()"
    [tgnContextItem]="{ object: store.animal$ }"
    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
    class="tgn-button"
    mat-flat-button
  >
    {{ 'GENERAL.ACTION.NEW_TASK' | translate }}
  </button>
</div>

<div class="content">
  @if (animal) {
    <tgn-animal-tasks-table [animal]="animal" [includeLatestCaseTasks]="true" [refresh]="refreshTasks" [openTask$]="openTask$" />
  }
</div>
