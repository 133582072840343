<div *tgnContextActions class="tgn-context-actions">
  <button
    (click)="openAddProspectDialog()"
    [tgnContextItem]="{ object: caseDetailStore.case$ }"
    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE | andRoleRestriction: RESTRICT_CASE_WRITE"
    class="tgn-button"
    mat-flat-button
  >
    Interessent hinzufügen
  </button>
</div>

<tgn-narrow-page-container>
  @if (animals$ | async; as animals) {
    @if (case) {
      @for (animal of animals; track animal.id) {
        <tgn-compact-animal-placement class="mt-medium" [animal]="animal" [case]="case" />
      }
    }
  }
</tgn-narrow-page-container>

<h2 class="mt-large">{{ 'PAGE.PLACEMENT.SECTION_PROSPECTS' | translate }}</h2>

<table #table="matTable" *ngIf="datasource && case" [dataSource]="datasource" class="mt-large" mat-table>
  <tr *matHeaderRowDef="columns" mat-header-row></tr>
  <tr
    *matRowDef="let prospect; table: table; columns: columns"
    [routerLink]="appRoutes.CASE_DETAIL_PROSPECT_DETAILS.url(case.id, prospect.id)"
    class="table-row-link"
    mat-row
  ></tr>

  <ng-container matColumnDef="firstName">
    <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.firstName' | translate }}</th>
    <td *matCellDef="let prospect; table: table" mat-cell>
      {{ prospect.contact.firstName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.lastName' | translate }}</th>
    <td *matCellDef="let prospect; table: table" mat-cell>
      {{ prospect.contact.lastName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="animals">
    <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Prospect.animals' | translate }}</th>
    <td *matCellDef="let prospect; table: table" mat-cell>
      {{ getAnimalNames(prospect) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="relationKind">
    <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.KIND' | translate }}</th>
    <td *matCellDef="let prospect; table: table" mat-cell>
      {{ 'GENERAL.DOMAIN.RelationKind.Prospect' | translate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Prospect.date' | translate }}</th>
    <td *matCellDef="let prospect; table: table" mat-cell>
      {{ prospect.createdDate | date }}
    </td>
  </ng-container>

  <ng-container matColumnDef="state">
    <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Prospect.status' | translate }}</th>
    <td *matCellDef="let prospect; table: table" mat-cell>
      {{ 'GENERAL.DOMAIN.ProspectState.' + prospect.state | translate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let prospect; table: table" mat-cell>
      @if (prospect.animals.length > 0) {
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="animals">
            <span>Vertrag herunterladen</span>
          </button>
        </mat-menu>
        <mat-menu #animals="matMenu">
          @for (animal of prospect.animals; track animal.id) {
            <button mat-menu-item (click)="downloadContract($event, prospect, animal)">
              <span>{{ animal.name }}</span>
            </button>
          }
        </mat-menu>
      }
    </td>
  </ng-container>
</table>
