import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { routes_config } from '@core/constants';
import { RoleAccess, RoleRestriction } from '@core/models/role';
import { CurrentUserService } from '@user/service/current-user.service';
import { firstValueFrom } from 'rxjs';

export function roleRestriction(roleRules: RoleRestriction): CanActivateFn {
  return async () => {
    const router = inject(Router);
    const currentUserService = inject(CurrentUserService);

    const currentUser = await firstValueFrom(currentUserService.currentUser$);

    if (roleRules.restrictTo && roleRules.restrictTo.length > 0) {
      if (!RoleAccess.anyMatches(currentUser.roles, roleRules.restrictTo)) {
        return router.navigateByUrl(routes_config.ROOT.path);
      }
    }

    if (roleRules.exclude && roleRules.exclude.length > 0) {
      if (RoleAccess.anyMatches(currentUser.roles, roleRules.exclude)) {
        return router.navigateByUrl(routes_config.ROOT.path);
      }
    }
    return true;
  };
}
