import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContactDialogComponent, ContactDialogResult } from '@contact/components/contact-dialog/contact-dialog.component';
import { ContactDto } from '@contact/dto/contact.dto';
import { ContactService } from '@contact/service/contact-service';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ViewStore, ViewStoreQuery, toLoadable } from '@core/components/autocomplete/model/loadable';
import { IconComponent } from '@core/components/icon/icon.component';
import { ContactDisplayPipe } from '@core/pipes/contact-display.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { fullName } from '@core/utils/person';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';

@Component({
  selector: 'tgn-choose-contact',
  standalone: true,
  imports: [AutocompleteComponent, IconComponent, MatButtonModule, TranslateModule, MatTooltipModule, ContactDisplayPipe],
  templateUrl: './choose-contact.component.html',
  styleUrl: './choose-contact.component.scss',
})
export class ChooseContactComponent {
  @Input({ required: true }) control!: FormControl<ContactDto | null>;
  @Input({ required: true }) id!: string;
  @Input({ required: true }) allowNewCreation!: boolean;

  contactViewStore: ViewStore<ContactDto[], ViewStoreQuery> = new ViewStore<ContactDto[], ViewStoreQuery>(
    { query: '' },
    (query: ViewStoreQuery) => {
      return this.contactService.searchContacts(query.query).pipe(toLoadable());
    },
  );

  constructor(
    private contactService: ContactService,
    private modalService: ModalService,
    private snackbar: SnackbarService,
  ) {}

  contactDisplayFn(contact: ContactDto | null): string {
    if (!contact) {
      return '';
    }

    const values = [fullName(contact) ?? null, contact.company, contact.zip, contact.city];

    return values
      .filter(notNullish)
      .filter(it => it?.trim().length > 0)
      .join(', ');
  }

  getContactValueFn(dto: ContactDto) {
    dto.id;
  }

  setRelatedContact(contract: ContactDto | null) {
    this.control.setValue(contract);
  }

  removeSelectedContact() {
    this.control.setValue(null);
  }

  editContact($event: Event) {
    $event.stopImmediatePropagation();
    const oldContact = this.control.value;
    if (!oldContact) {
      console.error('No contact selected');
      return;
    }

    this.modalService
      .open(ContactDialogComponent, { contact: oldContact, edit: true }, { width: ModalWidth.Large })
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe((result: ContactDialogResult) => {
        this.control.setValue(result.dto);
      });
  }

  openContactModal() {
    this.modalService
      .open(ContactDialogComponent, {}, { width: ModalWidth.Large })
      .afterClosed()
      .subscribe({
        next: result => {
          if (!result) {
            return;
          }
          this.control.setValue(result.dto);
        },
        error: (err: unknown) => {
          console.error('Could not create contact: ', err);
          this.snackbar.showErrorMessage('PAGE.CONTACTS.ERROR.COULD_NOT_CREATE_CONTACT');
        },
      });
  }
}
