import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '@core/components/icon/icon.component';

@Component({
  selector: 'tgn-selected-filter-item',
  standalone: true,
  imports: [IconComponent, MatButtonModule],
  templateUrl: './selected-filter-item.component.html',
  styleUrl: './selected-filter-item.component.scss',
})
export class SelectedFilterItemComponent {
  @Input({ required: true }) label!: string;

  @Output() onRemove = new EventEmitter<void>();
}
