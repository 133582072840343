<h1 mat-dialog-title>
  {{ 'PAGE.BASE_DATA_POSITIONS.CREATE_POSITION_CATEGORY_MODAL.TITLE' | translate }}
</h1>

<div class="mt-large" mat-dialog-content>
  <tgn-form-element
    [control]="nameControl"
    [hideError]="true"
    [id]="'nameControl'"
    [label]="'ENTITY.BaseDataPosition.name' | translate"
    class="mt-small"
  >
    <ng-template formElement let-control="control" let-id="id">
      <mat-form-field appearance="outline">
        <input [formControl]="control" [id]="id" matInput type="text" />
      </mat-form-field>
    </ng-template>
  </tgn-form-element>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>
  <button
    (click)="close()"
    [disabled]="nameControl.value === null || nameControl.value === ''"
    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
    class="tgn-button"
    mat-flat-button
  >
    {{ 'GENERAL.BUTTON.SAVE' | translate }}
  </button>
</div>
