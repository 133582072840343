import {
  radixArchive,
  radixArrowLeft,
  radixArrowRight,
  radixBarChart,
  radixBell,
  radixCalendar,
  radixCaretSort,
  radixCheck,
  radixChevronDown,
  radixChevronLeft,
  radixChevronRight,
  radixClipboard,
  radixClock,
  radixCross1,
  radixCross2,
  radixDashboard,
  radixDotFilled,
  radixEnvelopeClosed,
  radixExclamationTriangle,
  radixExternalLink,
  radixEyeClosed,
  radixEyeOpen,
  radixFile,
  radixFilePlus,
  radixFileText,
  radixGear,
  radixHamburgerMenu,
  radixHome,
  radixInfoCircled,
  radixMagicWand,
  radixMagnifyingGlass,
  radixMinus,
  radixMixerHorizontal,
  radixPencil1,
  radixPerson,
  radixPlus,
  radixSymbol,
  radixTrash,
} from '@ng-icons/radix-icons';

export const providedIcons = {
  radixHome,
  radixMagnifyingGlass,
  radixGear,
  radixDashboard,
  radixClipboard,
  radixPerson,
  radixFileText,
  radixFile,
  radixBell,
  radixPlus,
  radixArrowLeft,
  radixMixerHorizontal,
  radixCaretSort,
  radixChevronDown,
  radixCross2,
  radixChevronLeft,
  radixChevronRight,
  radixHamburgerMenu,
  radixMinus,
  radixCross1,
  radixExclamationTriangle,
  radixMagicWand,
  radixFilePlus,
  radixCalendar,
  radixClock,
  radixSymbol,
  radixCheck,
  radixExternalLink,
  radixInfoCircled,
  radixPencil1,
  radixTrash,
  radixDotFilled,
  radixEnvelopeClosed,
  radixEyeOpen,
  radixEyeClosed,
  radixArrowRight,
  radixBarChart,
  radixArchive,
};

export const customIcons = {
  repetition: 'assets/icons/repetition-icon.svg',
  taskStatusOpen: 'assets/icons/task-status-open.svg',
  taskCategory: 'assets/icons/task-category-icon.svg',
  uploadIcon: 'assets/icons/upload-icon.svg',
  animalPawIcon: 'assets/icons/paw-icon.svg',
};

// has to match
export type AvailableIcons = keyof typeof providedIcons | keyof typeof customIcons;
// ! insert also into provided icons above when added!
