import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { NextStepDirective, StepDirective, StepViewComponent } from '@care/components/step-view/step-view.component';
import { ActiveFilterGroupComponent } from '@care/components/task-filter/active-filter-group/active-filter-group.component';
import { CaseEntryTypeFilter } from '@care/components/task-filter/model/task-filter';
import { SelectedFilterItemComponent } from '@care/components/task-filter/selected-filter-item/selected-filter-item.component';
import { SingleFilterListComponent } from '@care/components/task-filter/single-filter-list/single-filter-list.component';
import { DatePickerRangeComponent, DateRangeGroup } from '@core/components/date-picker/date-picker-range.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { CaseEntryType, CaseState, PlacementState, PlacementType } from '@core/models/general';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { defaultDebounce } from '@core/services/base-service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';

import { CaseFilter, CaseStateFilter, PlacementStateFilter, PlacementTypeFilter } from '../../models/case-filter';

@Component({
  selector: 'tgn-case-filter',
  standalone: true,
  imports: [
    ActiveFilterGroupComponent,
    AsyncPipe,
    DatePickerRangeComponent,
    TranslateModule,
    MatMenuModule,
    SelectedFilterItemComponent,
    EnumDisplayPipe,
    DatePipe,
    StepDirective,
    SingleFilterListComponent,
    NextStepDirective,
    StepViewComponent,
    IconComponent,
  ],
  templateUrl: './case-filter.component.html',
  styleUrl: './case-filter.component.scss',
})
export class CaseFilterComponent implements OnInit {
  @Input({ required: true }) filter!: CaseFilter;
  @Output() onFilterChange = new EventEmitter<CaseFilter>();

  caseStateChoices: RadioChoice<CaseState>[] = createEnumChoices(CaseState, 'GENERAL.DOMAIN.CaseState.');

  entryTypeChoices: RadioChoice<CaseEntryType>[] = createEnumChoices(CaseEntryType, 'GENERAL.DOMAIN.CaseEntryType.');

  @ViewChild(MatMenuTrigger) menu!: MatMenuTrigger;

  dateRangeGroup!: FormGroup<DateRangeGroup>;
  private destroyRef = inject(DestroyRef);

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.dateRangeGroup = this.fb.group({
      start: this.fb.control<IsoLocalDateString | null>(null),
      end: this.fb.control<IsoLocalDateString | null>(null),
    });

    this.dateRangeGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
      if (this.dateRangeGroup.invalid) {
        console.info('skipping update, invalid date range');
        return;
      }
      this.onFilterChange.emit(
        this.filter.setDateFilter({
          from: this.dateRangeGroup.value.start ?? null,
          to: this.dateRangeGroup.value.end ?? null,
          id: 'dateFilter',
        }),
      );
    });

    this.onFilterChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.menu.closeMenu();
    });
  }

  onStateFilterChange(newFilter: CaseStateFilter) {
    this.onFilterChange.emit(this.filter.setStateFilter(newFilter));
  }

  onRemoveSingleStateFilter(filter: CaseState) {
    const newFilter: CaseStateFilter = {
      values: this.filter.filters.stateFilter.values.filter(value => value !== filter),
      id: 'stateFilter',
    };
    this.onFilterChange.emit(this.filter.setStateFilter(newFilter));
  }

  onEntryTypeFilterChange(newFilter: CaseEntryTypeFilter) {
    this.onFilterChange.emit(this.filter.setEntryTypeFilter(newFilter));
  }

  onRemoveSingleEntryTypeFilter(filter: CaseEntryType) {
    const newFilter: CaseEntryTypeFilter = {
      values: this.filter.filters.entryTypeFilter.values.filter(value => value !== filter),
      id: 'entryTypeFilter',
    };
    this.onFilterChange.emit(this.filter.setEntryTypeFilter(newFilter));
  }

  onRemoveDateFilter() {
    this.dateRangeGroup.setValue({ start: null, end: null }, { emitEvent: false });
    this.onFilterChange.emit(this.filter.setDateFilter({ from: null, to: null, id: 'dateFilter' }));
  }

  onRemoveSinglePlacementTypeFilter(filter: PlacementType) {
    const newFilter: PlacementTypeFilter = {
      values: this.filter.filters.placementTypeFilter.values.filter(value => value !== filter),
      id: 'placementTypeFilter',
    };
    this.onFilterChange.emit(this.filter.setPlacementTypeFilter(newFilter));
  }

  onRemoveSinglePlacementStateFilter(filter: PlacementState) {
    const newFilter: PlacementStateFilter = {
      values: this.filter.filters.placementStateFilter.values.filter(value => value !== filter),
      id: 'placementStateFilter',
    };
    this.onFilterChange.emit(this.filter.setPlacementStateFilter(newFilter));
  }

  clearAllFilters() {
    this.onFilterChange.emit(CaseFilter.empty());
    this.dateRangeGroup.setValue({ start: null, end: null });
  }
}
