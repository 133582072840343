import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { AnimalHeaderComponent, AnimalViewContext } from '@animal/components/animal-header/animal-header.component';
import { AnimalRaceInputComponent } from '@animal/components/animal-race-input/animal-race-input.component';
import { AnimalTasksTableComponent } from '@animal/components/animal-tasks-table/animal-tasks-table.component';
import { WildAnimalStatisticsComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics.component';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { UpdateAnimalCareDto } from '@animal/dtos/update-animal.dto';
import { getAnimalName } from '@animal/model/animal';
import { AnimalService } from '@animal/services/animal.service';
import { CreateTaskDialogComponent } from '@care/components/create-task-dialog/create-task-dialog.component';
import { TaskCategoryGroup, TaskEntityType, TaskId } from '@care/dtos/task.dto';
import { OPEN_TASK_PARAM } from '@case/components/case-tasks/case-tasks.component';
import { BaseDataDto } from '@case/dtos/base-data.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { UpdateWildAnimalStatisticsDto } from '@case/dtos/update-wild-animal-statistics.dto';
import { WildAnimalStatisticsControls } from '@case/models/new-case-form';
import { BaseDataService } from '@case/services/base-data-service';
import { CaseAnimalService } from '@case/services/case-animal.service';
import { CaseService } from '@case/services/case.service';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ChipInputComponent } from '@core/components/chip-input/chip-input.component';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { EXCLUDE_VETERINARIAN, GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AnimalSex, AnimalType, UserRole } from '@core/models/general';
import { CastPipe } from '@core/pipes/cast.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices, openDownloadedBlobPdf } from '@core/utils/helplers';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, ReplaySubject, filter, map, switchMap, take } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

interface AnimalCareForm {
  behaviour: FormControl<string | null>;
  feedingHabits: FormControl<string | null>;
  race: FormControl<string | null>;
  raceNote: FormControl<string | null>;
  coloring: FormControl<string | null>;
  chipId: FormControl<string | null>;
  ringId: FormControl<string | null>;
  sex: FormControl<AnimalSex>;
  birthdate: FormControl<IsoLocalDateString | null>;
  guessedAge: FormControl<string | null>;
  accessories: FormControl<string[]>;
}

@Component({
  selector: 'app-animal-care-page',
  standalone: true,
  imports: [
    AnimalHeaderComponent,
    AsyncPipe,
    AnimalTasksTableComponent,
    FormElementComponent,
    ReactiveFormsModule,
    FormElementDirective,
    ChipInputComponent,
    DateInputComponent,
    TranslateModule,
    SelectComponent,
    TextFieldModule,
    CollapsibleComponent,
    NarrowPageContainerComponent,
    AutocompleteComponent,
    AnimalRaceInputComponent,
    CastPipe,
    DatePipe,
    MatFormField,
    MatInput,
    WildAnimalStatisticsComponent,
    MatButton,
    MatMenu,
    MatMenuTrigger,
    ContextActionsDirective,
    MatMenuItem,
    MatMenuContent,
    RoleRestrictionDirective,
  ],
  templateUrl: './animal-care-page.component.html',
  styleUrl: './animal-care-page.component.scss',
})
export class AnimalCarePageComponent implements OnInit {
  baseData$!: Observable<BaseDataDto>;
  animal$!: Observable<AnimalDto>;
  animal?: AnimalDto;
  caseDetail?: CaseDetailDto;

  form!: FormGroup<AnimalCareForm>;

  wildAnimalStatisticsForm?: FormGroup<WildAnimalStatisticsControls>;

  destroyRef = inject(DestroyRef);
  sexOptions: RadioChoice<AnimalSex>[] = createEnumChoices(AnimalSex, 'GENERAL.DOMAIN.AnimalSex.');
  refreshTasks: ReplaySubject<void> = new ReplaySubject<void>(1);
  openTask$: ReplaySubject<TaskId> = new ReplaySubject<TaskId>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalViewContext = AnimalViewContext;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly EXCLUDE_VETERINARIAN = EXCLUDE_VETERINARIAN;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly TaskCategoryGroup = TaskCategoryGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private animalService: AnimalService,
    private baseDataService: BaseDataService,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    private caseService: CaseService,
    private caseAnimalService: CaseAnimalService,
    private modalService: ModalService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.baseData$ = this.baseDataService.getBaseData();

    this.animal$ = this.activatedRoute.params.pipe(
      map(params => params['animalId']),
      switchMap((animalId: string) => this.animalService.getAnimal(animalId)),
    );

    this.animal$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(animal => {
      this.animal = animal;
      this.buildForm(animal);
      this.autoSave();

      this.animalService.getLatestCaseDetail(this.animal.id).subscribe((caseDto: CaseDetailDto) => {
        this.caseDetail = caseDto;
        if (this.animal?.type === AnimalType.WildAnimal) {
          this.buildWildAnimalStatisticsForm(caseDto);
          this.autoUpdateWildAnimalStatistics(caseDto);
        }
      });

      this.breadcrumbService.set('@animalName', getAnimalName(animal));
    });

    this.openRoutedTask();
  }

  downloadBoxCard() {
    this.animal$.pipe(take(1)).subscribe((animal: AnimalDto) => {
      this.animalService
        .getLatestCase(animal.id)
        .pipe(
          switchMap(latestCase => {
            return this.caseAnimalService.downloadBoxCardPdf(animal.id, latestCase.id);
          }),
        )
        .subscribe(response => {
          openDownloadedBlobPdf(response);
        });
    });
  }

  downloadAdmissionForm() {
    this.animal$.pipe(take(1)).subscribe((animal: AnimalDto) => {
      this.animalService
        .getLatestCase(animal.id)
        .pipe(
          switchMap(latestCase => {
            return this.caseAnimalService.downloadAdmissionFormPdf(animal.id, latestCase.id);
          }),
        )
        .subscribe(response => {
          openDownloadedBlobPdf(response);
        });
    });
  }

  createNewTask() {
    this.modalService
      .open(
        CreateTaskDialogComponent,
        {
          entity: this.animal!,
          entityType: TaskEntityType.Animal,
          taskCategoryGroups: [TaskCategoryGroup.Care, TaskCategoryGroup.Admin],
        },
        { width: ModalWidth.ExtraLarge },
      )
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe(() => {
        this.refreshTasks.next();
      });
  }

  private buildWildAnimalStatisticsForm(caseDto: CaseDetailDto) {
    const fb = this.formBuilder;
    const statistics = caseDto.wildAnimalStatistics!;
    this.wildAnimalStatisticsForm = fb.group<WildAnimalStatisticsControls>({
      numAnimals: fb.nonNullable.control<number>(statistics.numAnimals, [Validators.min(0)]),
      numCareStation: fb.nonNullable.control<number>(statistics.numCareStation, [Validators.min(0)]),
      numVeterinarian: fb.nonNullable.control<number>(statistics.numVeterinarian, [Validators.min(0)]),
      numReleased: fb.nonNullable.control<number>(statistics.numReleased, [Validators.min(0)]),
      numDead: fb.nonNullable.control<number>(statistics.numDead, [Validators.min(0)]),
    });
  }

  private autoUpdateWildAnimalStatistics(caseDto: CaseDetailDto) {
    this.wildAnimalStatisticsForm?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const dto: UpdateWildAnimalStatisticsDto = this.wildAnimalStatisticsForm!.getRawValue();
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.caseService.updateWildAnimalStatistics(caseDto.id, dto).subscribe(() => {});
    });
  }

  private buildForm(animal: AnimalDto) {
    const fb = this.formBuilder;

    this.form = fb.group<AnimalCareForm>({
      behaviour: fb.control<string | null>(animal.behaviour),
      feedingHabits: fb.control<string | null>(animal.feedingHabits),
      race: fb.control<string | null>(animal.race),
      raceNote: fb.control<string | null>(animal.raceNote),
      coloring: fb.control<string | null>(animal.coloring),
      chipId: fb.control<string | null>(animal.chipId),
      ringId: fb.control<string | null>(animal.ringId),
      sex: fb.nonNullable.control<AnimalSex>(animal.sex ?? AnimalSex.Unknown),
      birthdate: fb.control<IsoLocalDateString | null>(animal.birthdate),
      guessedAge: fb.control<string | null>(animal.guessedAge),
      accessories: fb.nonNullable.control<string[]>(animal.accessories),
    });

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Animal);
  }

  private autoSave() {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (!this.animal) {
        return;
      }
      const dto: UpdateAnimalCareDto = {
        ...this.form.getRawValue(),
      };
      this.animalService.updateAnimalCare(this.animal.id, dto).subscribe();
    });
  }

  private openRoutedTask() {
    this.activatedRoute.queryParams
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(params => params[OPEN_TASK_PARAM]),
        filter(notNullish),
      )
      .subscribe((task: string) => {
        this.openTask$.next(task as TaskId);
      });
  }
}
