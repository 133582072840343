import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cast',
  pure: true,
  standalone: true,
})
/* Use this pipe if you want to narrow/type assertion a union value to a specific type.
 *  Problem is that we cannot use the `as` keyword in the template for type assertion.
 *  This pipe is a workaround for that and automatically infers the input and output type but still
 *  checks that the output type is one of the input types (union type).
 *  Basically an automatic cast to a specific type of a union.
 * */
export class CastPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
  transform<T extends S, S>(value: S): T {
    return value as T;
  }
}
