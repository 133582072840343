import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnimalId } from '@animal/dtos/animal.dto';
import { CreatePositionDto, PositionDto, UpdatePositionDto } from '@bill/dto/position.dto';
import { CaseId } from '@case/dtos/case.dto';
import { PageResult } from '@core/data/tigon-datasource';
import { BaseService } from '@core/services/base-service';
import { PaginatedParams } from '@core/utils/pagination';
import { Observable } from 'rxjs';

type PositionPaginatedParams = Omit<PaginatedParams, 'sort' | 'query'>;

interface CreateAnimalPositionParams {
  caseId: CaseId;
  animalId: AnimalId;
}

export type PositionTarget = { type: 'case'; caseId: CaseId } | { type: 'animal'; caseId: CaseId; animalId: AnimalId };

@Injectable({
  providedIn: 'root',
})
export class PositionService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  createAnimalPosition({ caseId, animalId }: CreateAnimalPositionParams, dto: CreatePositionDto): Observable<PositionDto> {
    return this.http.post<PositionDto>(`${this.apiUrl}/positions/case/${caseId}/animal/${animalId}`, dto);
  }

  createCasePosition(caseId: CaseId, dto: CreatePositionDto): Observable<PositionDto> {
    return this.http.post<PositionDto>(`${this.apiUrl}/positions/case/${caseId}`, dto);
  }

  delete(positionId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/positions/${positionId}`);
  }

  update(positionId: string, dto: UpdatePositionDto): Observable<PositionDto> {
    return this.http.put<PositionDto>(`${this.apiUrl}/positions/${positionId}`, dto);
  }

  findPositionsPaginated(
    target: PositionTarget,
    params: PositionPaginatedParams,
    excludeCancelledOrStorno: boolean,
  ): Observable<PageResult<PositionDto>> {
    let httpParams: HttpParams = new HttpParams();

    const flattenedParams = {
      pageIndex: params.pageIndex.toString(),
      pageSize: params.pageSize.toString(),
      excludeCancelledOrStorno: excludeCancelledOrStorno.toString(),
    };

    httpParams = httpParams.appendAll(flattenedParams);

    if (target.type === 'case') {
      return this.http.get<PageResult<PositionDto>>(`${this.apiUrl}/positions/case/${target.caseId}`, { params: httpParams });
    } else {
      return this.http.get<PageResult<PositionDto>>(`${this.apiUrl}/positions/case/${target.caseId}/animal/${target.animalId}`, {
        params: httpParams,
      });
    }
  }
}
