import { DatePipe } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { AnimalExitDialogComponent } from '@animal/components/animal-exit-dialog/animal-exit-dialog.component';
import { AnimalListViewDto } from '@animal/dtos/animal-list-view.dto';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { WithDefaultAnimalNamePipe } from '@animal/pipes/with-default-animal-name.pipe';
import { AnimalService } from '@animal/services/animal.service';
import { AddCaseAnimalDialogComponent } from '@case/components/add-case-animal-dialog/add-case-animal-dialog.component';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseAnimalService } from '@case/services/case-animal.service';
import { CaseService } from '@case/services/case.service';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { ContextItemDirective } from '@core/directives/context-item.directive';
import { TypeSafeMatCellDef, TypeSafeMatRowDef } from '@core/directives/mat-table.directive';
import { EXCLUDE_VETERINARIAN, GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AnimalState, AnimalType } from '@core/models/general';
import { AndRoleRestrictionPipe, RESTRICT_CASE_WRITE } from '@core/models/role';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { NotInPipe } from '@core/pipes/not-in.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { openDownloadedBlobPdf } from '@core/utils/helplers';
import { notNullish } from '@core/utils/rxjs';
import { FileBaseDto } from '@file/dto/file.dto';
import { FileService, UploadResult } from '@file/service/file.service';
import { TranslateModule } from '@ngx-translate/core';
import { filter, firstValueFrom, switchMap } from 'rxjs';

import { CaseDetailStore } from '../case-layout/case-layout.component';

@Component({
  selector: 'app-case-animals',
  standalone: true,
  imports: [
    ConfirmationDialogDirective,
    DatePipe,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTableModule,
    TranslateModule,
    TypeSafeMatCellDef,
    RouterLink,
    TypeSafeMatRowDef,
    AvatarImageComponent,
    ContextActionsDirective,
    WithDefaultAnimalNamePipe,
    EnumDisplayPipe,
    SingleLineTextComponent,
    NotInPipe,
    RoleRestrictionDirective,
    AndRoleRestrictionPipe,
    ContextItemDirective,
  ],
  templateUrl: './case-animals.component.html',
  styleUrl: './case-animals.component.scss',
})
export class CaseAnimalsComponent {
  datasource?: TigonDatasource<AnimalDto, {}>;
  case?: CaseDetailDto;
  readonly columns = ['avatarImage', 'name', 'trdId', 'caseEntryType', 'mainGroup', 'species', 'race', 'animalState', 'actions'];

  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalState = AnimalState;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RESTRICT_CASE_WRITE = RESTRICT_CASE_WRITE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly EXCLUDE_VETERINARIAN = EXCLUDE_VETERINARIAN;
  private destroyRef = inject(DestroyRef);

  constructor(
    private caseService: CaseService,
    protected caseDetailStore: CaseDetailStore,
    private caseAnimalService: CaseAnimalService,
    private modalService: ModalService,
    private animalService: AnimalService,
    private snackbar: SnackbarService,
    private router: Router,
    private fileService: FileService,
  ) {
    this.caseDetailStore.case$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((caseDto: CaseDetailDto) => {
      this.case = caseDto;
      this.datasource = new TigonDatasource<AnimalDto, {}>({}, () => {
        return this.caseService.getAnimals(caseDto.id);
      });
    });
  }

  uploadAnimalAvatar = async (file: File, animal: AnimalDto): Promise<UploadResult<FileBaseDto>> => {
    return this.fileService.uploadAnimalProfileImage(animal.id, file);
  };

  editEntryCheck(animalDto: AnimalDto) {
    if (animalDto.type === AnimalType.DomesticAnimal) {
      this.router.navigate(routes_config.DOMESTIC_ENTRYCHECK_EDIT.url(this.case!.id, animalDto.id));
    } else {
      this.router.navigate(routes_config.WILD_ENTRYCHECK_EDIT.url(this.case!.id, animalDto.id));
    }
  }

  async openAddAnimalDialog() {
    this.modalService
      .open(AddCaseAnimalDialogComponent, { case: this.case! }, { width: ModalWidth.Small })
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe(async result => {
        if (result.type === 'create') {
          this.animalService
            .create({
              type: AnimalType.DomesticAnimal,
              name: result.name,
              chipId: null,
              ringId: null,
              groupId: null,
              subGroupId: null,
              speciesId: null,
            })
            .pipe(
              switchMap((animal: AnimalDto) => {
                return this.caseAnimalService.addToCase(this.case!, animal);
              }),
            )
            .subscribe(() => {
              this.datasource?.update();
            });
        } else {
          const animals = await firstValueFrom(this.datasource!.getData());

          if (animals.some(it => it.id === result.animal.id)) {
            this.snackbar.showErrorMessage('PAGE.CASE.DETAIL.ADD_CASE_ANIMAL_FORM.ERROR.ANIMAL_ALREADY_ADDED');
            return;
          }

          this.caseAnimalService.addToCase(this.case!, result.animal).subscribe(() => {
            this.datasource?.update();
          });
        }
      });
  }

  openExitAnimalDialog(animal: AnimalListViewDto) {
    this.animalService.getLatestCase(animal.id).subscribe(latestCase => {
      this.modalService
        .open(AnimalExitDialogComponent, { animal: animal, case: latestCase }, { width: ModalWidth.Medium })
        .afterClosed()
        .pipe(filter(notNullish))
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .subscribe(() => {
          this.datasource?.refresh();
        });
    });
  }

  downloadBoxCard(animal: AnimalDto, caseDto: CaseDetailDto) {
    this.caseAnimalService.downloadBoxCardPdf(animal.id, caseDto.id).subscribe(response => {
      openDownloadedBlobPdf(response);
    });
  }

  downloadAdmissionForm(animal: AnimalDto, caseDto: CaseDetailDto) {
    this.caseAnimalService.downloadAdmissionFormPdf(animal.id, caseDto.id).subscribe(response => {
      openDownloadedBlobPdf(response);
    });
  }
}
