import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { AuditViewComponent } from '@audit/components/audit-view/audit-view.component';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseDetailStore } from '@case/pages/case-detail/case-layout/case-layout.component';
import { FileListComponent } from '@file/component/file-list/file-list.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-case-log',
  standalone: true,
  imports: [AsyncPipe, FileListComponent, AuditViewComponent],
  templateUrl: './case-log.component.html',
  styleUrl: './case-log.component.scss',
})
export class CaseLogComponent {
  case$: Observable<CaseDetailDto>;

  constructor(private caseDetailStore: CaseDetailStore) {
    this.case$ = this.caseDetailStore.case$;
  }
}
