@if (avatar.avatarImage) {
  <img [class]="'avatar ' + size" [src]="avatar.avatarImage | file | async" alt="" />
  @if (!hideImageUpload) {
    <button
      [tgnRoleRestriction]="noRoleRestriction ? null : GENERAL_WRITE_EXCLUDE"
      class="mt-small tgn-text-button"
      (click)="showSelectFileDialog($event)"
      mat-flat-button
    >
      {{ 'GENERAL.ACTION.CHANGE_PICTURE' | translate }}
    </button>
    <ng-container *ngTemplateOutlet="fileInput"></ng-container>
  }
} @else if (!hideImageUpload) {
  <div [class]="'no-image avatar ' + size">
    <button
      [tgnRoleRestriction]="noRoleRestriction ? null : GENERAL_WRITE_EXCLUDE"
      class="add-new-avatar"
      (click)="showSelectFileDialog($event)"
    >
      <tgn-icon class="add-icon" [iconName]="'radixPlus'" />
    </button>
  </div>

  <button
    [tgnRoleRestriction]="noRoleRestriction ? null : GENERAL_WRITE_EXCLUDE"
    class="mt-small tgn-text-button"
    (click)="showSelectFileDialog($event)"
    mat-flat-button
  >
    {{ 'GENERAL.ACTION.CHOOSE_PICTURE' | translate }}
  </button>
  <ng-container *ngTemplateOutlet="fileInput"></ng-container>
  <input (change)="onFileSelected($event)" [accept]="acceptAttribute" [disabled]="false" [multiple]="false" type="file" />
}

<ng-template #fileInput>
  <input (change)="onFileSelected($event)" [accept]="acceptAttribute" [disabled]="false" [multiple]="false" type="file" />
</ng-template>
