<div class="filter">
  <div class="filter-display-row">
    <button [matMenuTriggerFor]="menu" class="side-menu-button filter-button" type="button">
      <div class="filter-button-inner">
        <span class="no-filter-active">
          {{ 'COMPONENT.FILTER.TITLE' | translate }}
        </span>
        <tgn-icon [iconName]="'radixChevronDown'" />
      </div>
    </button>

    <tgn-active-filter-group [label]="'ENTITY.Case.state' | translate" [show]="filter.filters.stateFilter.values.length > 0">
      @for (item of filter.filters.stateFilter.values; track $index) {
        <tgn-selected-filter-item [label]="item | enumDisplay: 'GENERAL.DOMAIN.CaseState'" (onRemove)="onRemoveSingleStateFilter(item)" />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group [label]="'ENTITY.Case.caseEntryType' | translate" [show]="filter.filters.entryTypeFilter.values.length > 0">
      @for (item of filter.filters.entryTypeFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.CaseEntryType'"
          (onRemove)="onRemoveSingleEntryTypeFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'COMPONENT.FILTER.CASE_FILTER.FILTER_TYPE.CASE_CREATION_DATE' | translate"
      [show]="filter.filters.dateFilter.from !== null || filter.filters.dateFilter.to !== null"
    >
      <tgn-selected-filter-item
        (onRemove)="onRemoveDateFilter()"
        [label]="
          ((filter.filters.dateFilter.from | date: 'dd.MM.yyyy') ?? '...') +
          ' - ' +
          ((filter.filters.dateFilter.to | date: 'dd.MM.yyyy') ?? '...')
        "
      />
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'COMPONENT.FILTER.CASE_FILTER.FILTER_TYPE.PLACEMENT_TYPE' | translate"
      [show]="filter.filters.placementTypeFilter.values.length > 0"
    >
      @for (item of filter.filters.placementTypeFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.PlacementType'"
          (onRemove)="onRemoveSinglePlacementTypeFilter(item)"
        />
      }
    </tgn-active-filter-group>

    <tgn-active-filter-group
      [label]="'COMPONENT.FILTER.CASE_FILTER.FILTER_TYPE.PLACEMENT_STATE' | translate"
      [show]="filter.filters.placementStateFilter.values.length > 0"
    >
      @for (item of filter.filters.placementStateFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item | enumDisplay: 'GENERAL.DOMAIN.PlacementState'"
          (onRemove)="onRemoveSinglePlacementStateFilter(item)"
        />
      }
    </tgn-active-filter-group>

    @if (filter.anyFilterActive()) {
      <tgn-selected-filter-item
        class="clear-all-button"
        (click)="clearAllFilters()"
        [label]="'COMPONENT.FILTER.CLEAR_FILTER' | translate"
      />
    }
  </div>

  <mat-menu #menu class="filter-menu">
    <tgn-step-view (click)="$event.stopPropagation()" *matMenuContent>
      <ng-template [tgnStepInitial]="true" tgnStep>
        <div class="menu-item-list">
          <button [tgnNextStep]="stateStep" class="step-button">
            {{ 'COMPONENT.FILTER.CASE_FILTER.FILTER_TYPE.CASE_STATE' | translate }}
          </button>
          <button [tgnNextStep]="entryTypeStep" class="step-button">
            {{ 'COMPONENT.FILTER.CASE_FILTER.FILTER_TYPE.CASE_ENTRY_TYPE' | translate }}
          </button>
          <button [tgnNextStep]="entryDateStep" class="step-button">
            {{ 'COMPONENT.FILTER.CASE_FILTER.FILTER_TYPE.CASE_CREATION_DATE' | translate }}
          </button>
        </div>
      </ng-template>

      <ng-template #stateStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onStateFilterChange($event)"
          [filter]="filter.filters.stateFilter"
          [options]="caseStateChoices"
        />
      </ng-template>

      <ng-template #entryTypeStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onEntryTypeFilterChange($event)"
          [filter]="filter.filters.entryTypeFilter"
          [options]="entryTypeChoices"
        />
      </ng-template>

      <ng-template #entryDateStep="tgnStep" tgnStep>
        <div class="date-picker-container">
          <tgn-date-picker-range [control]="this.dateRangeGroup" />
        </div>
      </ng-template>
    </tgn-step-view>
  </mat-menu>
</div>
