import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { RapportExportType } from '@care/models/RapportExportType';
import { TaskService } from '@care/services/task.service';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { ModalComponent } from '@core/services/modal.service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices, openDownloadedBlobPdf } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';

interface DialogData {}

interface DialogResult {}

type RapportForm = FormGroup<{
  date: FormControl<IsoLocalDateString | null>;
  type: FormControl<RapportExportType | null>;
}>;

@Component({
  selector: 'app-rapport-export-dialog',
  standalone: true,
  imports: [
    AutocompleteComponent,
    FormElementComponent,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    FormElementDirective,
    FormsModule,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    RadioGroupComponent,
    DateInputComponent,
  ],
  templateUrl: './rapport-export-dialog.component.html',
  styleUrl: './rapport-export-dialog.component.scss',
})
export class RapportExportDialogComponent extends ModalComponent<DialogData, DialogResult> implements OnInit {
  form!: RapportForm;

  rapportExportChoices = createEnumChoices(RapportExportType, 'GENERAL.DOMAIN.RapportExportType.');

  constructor(
    dialogRef: MatDialogRef<RapportExportDialogComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private taskService: TaskService,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    const today = moment().local().format('yyyy-MM-DD');

    this.form = this.fb.group({
      date: this.fb.control<IsoLocalDateString | null>(today, [Validators.required]),
      type: this.fb.control<RapportExportType | null>(null, [Validators.required]),
    });
  }

  create() {
    const formValues = this.form.getRawValue();
    if (!formValues.type || !formValues.date) {
      return;
    }
    this.taskService.downloadRapportExportPdfUrl(formValues.date, formValues.type).subscribe(response => {
      openDownloadedBlobPdf(response);
      this.closeWithResult({});
    });
  }
}
