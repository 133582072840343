import { NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { IconComponent } from '@core/components/icon/icon.component';
import { FileListPreviewComponent } from '@core/components/input-upload/file-list-preview/file-list-preview.component';
import { FileCollectionDto } from '@file/dto/file-collection.dto';
import { FileTagType } from '@file/dto/file-tag-type';
import { FileDto } from '@file/dto/file.dto';
import { UploadFileType, defaultUploadFileTypes, getInputAcceptTypes } from '@file/model/file';
import { FileService, UploadEntity, UploadResult } from '@file/service/file.service';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'tgn-button-upload',
  standalone: true,
  imports: [TranslateModule, MatButtonModule, NgTemplateOutlet, FileListPreviewComponent, IconComponent, MatFormField, MatInput],
  templateUrl: './button-upload.component.html',
  styleUrl: './button-upload.component.scss',
})
export class ButtonUploadComponent implements OnInit {
  @Input() acceptedFileTypes: UploadFileType[] = defaultUploadFileTypes;
  @Input({ required: true }) fileCollection!: FileCollectionDto; // null if upload is not managed via this component.
  @Input() label = 'COMPONENT.InputUpload.LABEL.UPLOAD_FILES';
  @Input() tags: FileTagType[] = [];
  @Input() useIcon = false;
  @Input() showFileList = false;
  @Input() uploadEntity: UploadEntity | null = null;

  @ContentChild(TemplateRef) formElement!: TemplateRef<unknown>;

  @Output() onFilesChanged = new EventEmitter<FileList | null>();
  @Output() onUploadCompleted = new EventEmitter<UploadResult<FileDto>[]>();

  filesToUpload: File[] = [];
  files: FileDto[] = [];
  isLoading = false;

  constructor(
    private elRef: ElementRef,
    private fileService: FileService,
  ) {}

  ngOnInit() {
    this.fileService
      .getFilesPaginated(this.fileCollection, [], { pageIndex: 0, pageSize: 100 })
      .pipe(take(1))
      .subscribe(files => {
        this.files = files.items;
      });
  }

  get maxFileSize() {
    return environment.uploadMaxFileSize; // In bytes, must correspond to server limit.
  }

  get acceptAttribute() {
    return this.acceptedMimeTypes().join(',');
  }

  showSelectFileDialog(event: MouseEvent) {
    event.preventDefault();
    const elInputFile = this.elRef.nativeElement.querySelector('input[type="file"]') as HTMLElement;
    if (elInputFile) {
      elInputFile.click();
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    this.onFilesChanged.next(input.files);
    if (files) {
      this.addFilesToUpload(files);
    }
  }

  async upload() {
    this.isLoading = true;
    const results = await this.fileService.uploadFile(this.fileCollection, this.filesToUpload, this.tags, this.uploadEntity ?? undefined);
    this.onUploadCompleted.next(results);
    this.filesToUpload = [];
    results.forEach(result => {
      if (result.success) {
        this.files.push(result.file!);
      }
    });
    this.isLoading = false;
  }

  async removeFile(file: FileDto) {
    this.isLoading = true;
    const isDeleted = await this.fileService.deleteFile(file);
    this.isLoading = false;
    if (isDeleted) {
      const index = this.files.findIndex(f => f.id === file.id);
      this.files.splice(index, 1);
    }
  }

  private validateFile(file: File) {
    return file.size <= this.maxFileSize;
  }

  private acceptedMimeTypes() {
    return this.acceptedFileTypes.flatMap((acceptedFileType: UploadFileType) => {
      return getInputAcceptTypes(acceptedFileType);
    });
  }

  private addFilesToUpload(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      if (!this.validateFile(files[i])) {
        continue;
      }
      this.filesToUpload.push(files[i]);
    }

    this.upload();
  }
}
