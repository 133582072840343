import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIcon } from '@ng-icons/core';

import { AvailableIcons, customIcons } from '../../models/provided-icons';

@Component({
  selector: 'tgn-icon',
  standalone: true,
  imports: [NgIcon],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() iconSize: `${number}` = '20';

  @Input() iconName!: AvailableIcons;
  protected readonly customIcons = customIcons as { [key: string]: string };
}
