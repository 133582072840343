@for (key of Object.keys(newStateObj); track key) {
  <div class="key">
    <tgn-single-line-text [text]="'ENTITY.' + translationDomainName + '.' + key | translate" />
  </div>

  <span class="change-from align-left">
    <tgn-single-line-text [text]="oldStateObj[key] | stateValueDisplay: key : translationDomainName" />
  </span>
  <span>geändert zu</span>
  <span class="change-to align-left">
    <tgn-single-line-text [text]="newStateObj[key] | stateValueDisplay: key : translationDomainName" />
  </span>
}
