<h1 mat-dialog-title>
  {{ 'PAGE.CONTACTS.ExportContactDialog.TITLE' | translate }}
</h1>

<div mat-dialog-content>
  <form class="form-grid grid-cols-6">
    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element [control]="form.controls.includeNoMailings" [id]="'includeNoMailings'" class="row-span-3">
          <ng-template formElement let-control="control" let-id="id">
            <mat-checkbox [formControl]="form.controls.includeNoMailings" [id]="id">
              {{ 'PAGE.CONTACTS.ExportContactDialog.LABEL.includeNoMailings' | translate }}
            </mat-checkbox>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>
  <button (click)="close()" [disabled]="form.invalid" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.BUTTON.EXPORT' | translate }}
  </button>
</div>
