import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { NextStepDirective, StepDirective, StepViewComponent } from '@care/components/step-view/step-view.component';
import { ActiveFilterGroupComponent } from '@care/components/task-filter/active-filter-group/active-filter-group.component';
import { SelectedFilterItemComponent } from '@care/components/task-filter/selected-filter-item/selected-filter-item.component';
import { SingleFilterListComponent } from '@care/components/task-filter/single-filter-list/single-filter-list.component';
import { DatePickerRangeComponent } from '@core/components/date-picker/date-picker-range.component';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationFilter, ReadUnreadFilter } from '../../model/notification-filter';

@Component({
  selector: 'tgn-notification-filter',
  standalone: true,
  imports: [
    MatMenuModule,
    TranslateModule,
    MatButtonModule,
    IconComponent,
    StepViewComponent,
    NextStepDirective,
    StepDirective,
    SingleFilterListComponent,
    EnumDisplayPipe,
    AsyncPipe,
    ToRadioChoicePipe,
    SelectedFilterItemComponent,
    MatDatepickerModule,
    MatInputModule,
    FormElementComponent,
    DatePickerRangeComponent,
    DatePipe,
    ActiveFilterGroupComponent,
  ],
  templateUrl: './notification-filter.component.html',
  styleUrl: './notification-filter.component.scss',
})
export class NotificationFilterComponent {
  @Input({ required: true }) filter!: NotificationFilter;
  @Output() onFilterChange = new EventEmitter<NotificationFilter>();

  readUnreadOptions: RadioChoice<boolean>[] = [
    { object: true, label: 'Gelesen' },
    { object: false, label: 'Ungelesen' },
  ];

  onRemoveSingleReadUnreadFilterItem(filterItem: boolean) {
    const newFilter: ReadUnreadFilter = {
      values: this.filter.filters.readUnreadFilter.values.filter(value => value !== filterItem),
      id: 'readUnreadFilter',
    };
    this.onFilterChange.emit(this.filter.setReadUnreadFilter(newFilter));
  }

  onReadUnreadFilterChange(newFilter: ReadUnreadFilter) {
    this.onFilterChange.emit(this.filter.setReadUnreadFilter(newFilter));
  }

  clearAllFilters() {
    this.onFilterChange.emit(NotificationFilter.empty());
  }
}
