import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { StationDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { AuthService } from '@core/auth/auth.service';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { APP_NAME, routes_config } from '@core/constants';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { UserRole } from '@core/models/general';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserBadgeComponent } from '@user/components/user-badge/user-badge.component';
import { UserDto } from '@user/dto/user.dto';
import { CurrentUserService } from '@user/service/current-user.service';
import { Observable, map, take } from 'rxjs';

type CurrentSettingsForm = FormGroup<{
  usedRole: FormControl<UserRole | null>;
  usedStation: FormControl<StationDto | null>;
}>;

@Component({
  selector: 'tgn-sidebar',
  standalone: true,
  imports: [
    TranslateModule,
    UserBadgeComponent,
    MatMenuModule,
    AsyncPipe,
    ConfirmationDialogDirective,
    RouterLink,
    SelectComponent,
    ToRadioChoicePipe,
    FullNamePipe,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  appRoutes = routes_config;
  stations!: Observable<StationDto[]>;
  roles!: Observable<RadioChoice<UserRole>[]>;
  currentSettings?: CurrentSettingsForm;
  protected readonly appName = APP_NAME;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private baseDataService: BaseDataService,
    private currentUserService: CurrentUserService,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.roles = this.currentUser.pipe(
      map(user => user?.roles ?? []),
      map(roles => {
        return roles
          .filter(it => it !== UserRole.Superuser && it !== UserRole.Deactivated)
          .map(role => {
            const choice: RadioChoice<UserRole> = {
              label: this.translate.instant(`GENERAL.DOMAIN.UserRole.${role}`),
              object: role,
            };
            return choice;
          });
      }),
    );

    this.currentUserService.currentUser$.pipe(take(1)).subscribe(user => {
      this.currentSettings = new FormGroup({
        usedRole: this.fb.control<UserRole | null>(user.usedRole),
        usedStation: this.fb.control<StationDto | null>(user.usedStation),
      });

      this.currentSettings.valueChanges.subscribe(() => {
        const form = this.currentSettings!.getRawValue();

        if (form.usedRole !== user.usedRole) {
          this.currentUserService.updateUsedRole(form.usedRole).subscribe(() => {
            user.usedRole = form.usedRole;
          });
        }
        if (form.usedStation?.id !== user.usedStation?.id) {
          this.currentUserService.updateUsedStation(form.usedStation?.id ?? null).subscribe(() => {
            user.usedStation = form.usedStation;
          });
        }
      });

      this.currentUserService.usedStation$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(usedStation => {
        this.currentSettings!.controls.usedStation.setValue(usedStation, { emitEvent: false });
      });

      this.currentUserService.usedRole$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(usedRole => {
        this.currentSettings!.controls.usedRole.setValue(usedRole, { emitEvent: false });
      });
    });

    this.stations = this.baseDataService.getBaseData().pipe(
      take(1),
      map(data => data.stations),
    );
  }

  get currentUser(): Observable<UserDto | null> {
    return this.currentUserService.currentUser$;
  }

  async goToProfile() {
    await this.router.navigate(routes_config.SETTINGS_PROFILE.url());
  }

  async logout() {
    await this.authService.logout();
    await this.router.navigateByUrl(routes_config.LOGIN.path, { replaceUrl: true });
  }
}
