<div class="info-container">
  @if (billingRelevantInfo.daysSinceEntry && billingRelevantInfo.daysSinceEntry > 0) {
    <div class="info-row">
      <label>Tage seit Eintritt:</label>
      <div class="info-value">{{ billingRelevantInfo.daysSinceEntry }}</div>
    </div>
  }
  @if (billingRelevantInfo.daysSinceLastBill && billingRelevantInfo.daysSinceLastBill > 0) {
    <div class="info-row">
      <label>Tage seit letzter Rechnung:</label>
      <div class="info-value">{{ billingRelevantInfo.daysSinceLastBill }}</div>
    </div>
  }
</div>
