<div class="background">
  @for (tab of tabs; track tab.value) {
    <div
      class="tab"
      [class.active]="tab.value === activeTab()"
      (click)="activeTab.set(tab.value)"
      [tgnRoleRestriction]="tab.roleRestriction"
    >
      {{ tab.label | translate }}
    </div>
  }
</div>
