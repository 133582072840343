<tgn-narrow-page-container>
  <tgn-collapsible [expanded]="false" [title]="'Tierarten - Heimtiere'" class="input-bg-white" variant="withBgColor">
    <tgn-base-data-species [animalType]="AnimalType.DomesticAnimal" />
  </tgn-collapsible>

  <tgn-collapsible [expanded]="false" [title]="'Tierarten - Wildtiere'" class="input-bg-white mt-large" variant="withBgColor">
    <tgn-base-data-species [animalType]="AnimalType.WildAnimal" />
  </tgn-collapsible>

  <tgn-collapsible [expanded]="false" [title]="'Rassen'" class="input-bg-white mt-large" variant="withBgColor">
    <tgn-base-data-races />
  </tgn-collapsible>
</tgn-narrow-page-container>
