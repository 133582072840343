import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AnimalId } from '@animal/dtos/animal.dto';
import { getAnimalName } from '@animal/model/animal';
import { routes_config } from '@core/constants';
import { SnackbarService } from '@core/services/snackbar.service';
import { ReplaySubject, map, switchMap, take, throwError } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

import { EntryCheckComponent } from '../../components/entry-check/entry-check.component';
import { CaseDto, CaseId } from '../../dtos/case.dto';
import { DomesticCaseAnimalDto } from '../../dtos/domestic-case-animal.dto';
import { CaseAnimalService } from '../../services/case-animal.service';
import { CaseService } from '../../services/case.service';
import { CurrentAnimalContext } from '../entry-check-page/entry-check-page.component';

@Component({
  selector: 'app-edit-entry-check-page',
  standalone: true,
  imports: [AsyncPipe, EntryCheckComponent],
  templateUrl: './edit-entry-check-page.component.html',
  styleUrl: './edit-entry-check-page.component.scss',
})
export class EditEntryCheckPageComponent implements OnInit {
  currentAnimalContext$: ReplaySubject<CurrentAnimalContext> = new ReplaySubject<CurrentAnimalContext>(1);

  constructor(
    private caseService: CaseService,
    private route: ActivatedRoute,
    private caseAnimalService: CaseAnimalService,
    private snackbar: SnackbarService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
  ) {}

  ngOnInit() {
    this.loadDataFromParams();
  }

  toCarePage(context: CurrentAnimalContext) {
    this.caseService
      .completeCase(context.caseDto.id)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate(routes_config.CARE_ANIMAL.url(context.caseAnimalDto.animal.id));
      });
  }

  private loadDataFromParams() {
    this.route.paramMap
      .pipe(
        map((paramMap: ParamMap) => {
          return this.parseParams(paramMap);
        }),
        switchMap(({ caseId, animalId }: { caseId: CaseId; animalId: AnimalId }) => {
          return this.caseService.get(caseId).pipe(
            take(1),
            map(caseDto => {
              return { animalId, caseDto };
            }),
          );
        }),
        switchMap(({ caseDto, animalId }: { caseDto: CaseDto; animalId: AnimalId }) => {
          const animal = caseDto.caseAnimals.find(it => it.animal);
          if (!animal) {
            this.snackbar.showErrorMessage('PAGE.ENTRYCHECK_EDIT.ERROR.COULD_NOT_FIND_ANIMAL');
            return throwError(() => Error(`Did not find animal with id: ${animalId}`));
          }
          this.breadcrumbService.set('@animalName', getAnimalName(animal.animal));
          const animalIndex = caseDto.caseAnimals.indexOf(animal);
          return this.caseAnimalService.getDomestic(caseDto.id, animalId).pipe(
            map((caseAnimalDto: DomesticCaseAnimalDto) => {
              const context: CurrentAnimalContext = {
                animalIndex: animalIndex,
                caseDto: caseDto,
                caseAnimalDto: caseAnimalDto,
              };
              return context;
            }),
          );
        }),
      )
      .subscribe({
        next: (context: CurrentAnimalContext) => {
          this.currentAnimalContext$.next(context);
        },
      });
  }

  private parseParams(paramMap: ParamMap) {
    const caseId: CaseId = paramMap.get('caseId') as CaseId;
    const animalId: AnimalId = paramMap.get('animalId') as AnimalId;
    return { caseId, animalId };
  }
}
