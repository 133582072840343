import { BaseDataPositionDto } from '@baseData/dtos/base-data-position.dto';
import { VatTaxDto } from '@baseData/dtos/vat-tax.dto';
import { CompactBillDto } from '@bill/dto/bill.dto';
import { BrandedId } from '@core/utils/brand';

export type PositionId = BrandedId<'PositionId'>;

export interface PositionDto {
  id: PositionId;
  // either price of base data position or override price on position itself
  price: number;
  description: string | null;
  baseDataPosition: BaseDataPositionDto;
  amount: number;
  pensionBillingInfo: string | null;
  discountPercentage: number | null;
  isBilled: boolean;
  // only set with the actual vat tax percentage if billed
  vatTaxPercentage: number | null;
  vatTax: VatTaxDto | null;
  bill: CompactBillDto | null;
}

export enum PositionType {
  Days = 'Days',
  Units = 'Units',
  Kilometers = 'Kilometers',
  FlatRate = 'FlatRate',
  Hours = 'Hours',
}

export interface UpdatePositionDto {
  baseDataPositionId: string;
  description: string | null;
  price: number;
  amount: number;
  discountPercentage: number | null;
}

export interface CreatePositionDto {
  baseDataPositionId: string;
  description: string | null;
  price: number;
  amount: number;
  discountPercentage: number | null;
}

export enum PositionEntityType {
  CaseAnimal = 'CaseAnimal',
  Case = 'Case',
}
