<h1 mat-dialog-title>
  {{ 'PAGE.SETTINGS.USERS.ACTIONS.INVITE_USER' | translate }}
</h1>

<div mat-dialog-content>
  <form class="form-grid grid-cols-6">
    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.firstName"
          [hideError]="form.controls.lastName.pristine"
          [label]="'ENTITY.User.firstName' | translate"
          class="col-span-6 row-span-2"
          id="firstName"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input
                [formControl]="control"
                [id]="id"
                [placeholder]="'ENTITY.Contact.firstName' | translate"
                cdkFocusInitial
                matInput
                type="text"
              />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.lastName"
          [hideError]="form.controls.lastName.pristine"
          [label]="'ENTITY.User.lastName' | translate"
          class="col-span-6 row-span-2"
          id="lastName"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.lastName' | translate" matInput type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.email"
          [hideError]="form.controls.email.pristine"
          [label]="'ENTITY.User.email' | translate"
          class="col-span-12 row-span-3"
          id="email"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.emailPlaceholder' | translate" matInput type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.roles"
          [hideError]="true"
          [label]="'ENTITY.User.roles' | translate"
          class="row-span-2"
          id="role"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-select [choices]="roles" [control]="control" [multiple]="true" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    @if (form.controls.roles.value | listIncludesAny: [UserRole.Office, UserRole.Hundewesen]) {
      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.isSuperuser"
            [hideError]="true"
            [label]="'ENTITY.User.isSuperuser' | translate"
            class="row-span-2"
            id="isSuperuser"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-checkbox [formControl]="form.controls.isSuperuser">ist Superuser</mat-checkbox>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    }

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.defaultStation"
          [label]="'ENTITY.User.defaultStation' | translate"
          class="row-span-2"
          id="defaultStation"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-select [choices]="stations$ | async | toRadioChoice: 'name'" [control]="control" [includeEmptyChoice]="true" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element [control]="form.controls.sendInvite" class="row-span-2" id="sendInvite">
          <ng-template formElement let-control="control" let-id="id">
            <mat-checkbox [formControl]="control">Login mit Email und Passwort</mat-checkbox>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>
  <button (click)="close()" [disabled]="!form.valid" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.BUTTON.SAVE' | translate }}
  </button>
</div>
