import { CdkStepper } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'tgn-stepper',
  standalone: true,
  imports: [CommonModule, MatButtonModule, TranslateModule],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
  providers: [
    {
      provide: CdkStepper,
      useExisting: StepperComponent,
    },
  ],
})
export class StepperComponent extends CdkStepper {
  @Input() showPreviousInFirstStep = false;
  @Input() continueDisabled = false;

  @Input() lastActionLabel?: string;
  @Input() firstPreviousLabel?: string;

  @Output() onFirstPreviousAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() onLastAction: EventEmitter<void> = new EventEmitter<void>();

  goToPrevious() {
    if (this.selectedIndex === 0) {
      this.onFirstPreviousAction.emit();
    } else {
      this.previous();
    }
  }

  override next() {
    super.next();
    this.scrollToTop();
  }

  override previous() {
    super.previous();
    this.scrollToTop();
  }

  private scrollToTop() {
    setTimeout(() => {
      const element = document.getElementsByClassName('stepper-scrolltarget').item(0);
      element?.scrollIntoView({ behavior: 'instant', block: 'center', inline: 'nearest' });
    }, 0);
  }
}
