import { CdkCellDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatCellDef, MatRowDef, MatTable } from '@angular/material/table';

@Directive({
  selector: '[matCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: TypeSafeMatCellDef }],
  standalone: true,
})
export class TypeSafeMatCellDef<T> extends MatCellDef {
  @Input() matCellDefTable?: MatTable<T>;

  static ngTemplateContextGuard<T>(dir: TypeSafeMatCellDef<T>, ctx: unknown): ctx is { $implicit: T; index: number } {
    return true;
  }
}

@Directive({
  selector: '[matRowDef]',
  providers: [{ provide: CdkCellDef, useExisting: TypeSafeMatCellDef }],
  standalone: true,
})
export class TypeSafeMatRowDef<T> extends MatRowDef<T> {
  @Input() matRowDefTable?: MatTable<T>;

  static ngTemplateContextGuard<T>(dir: TypeSafeMatRowDef<T>, ctx: unknown): ctx is { $implicit: T; index: number } {
    return true;
  }
}
