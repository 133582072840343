<h1 mat-dialog-title>
  {{ 'PAGE.CONTACTS.NEW_CONTACT' | translate }}
</h1>

<div mat-dialog-content>
  <form class="form-grid grid-cols-6">
    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.salutation"
          [id]="'salutation'"
          [label]="'ENTITY.Contact.salutation' | translate"
          class="row-span-3"
        >
          <ng-template formElement let-control="control" let-id="id">
            <div class="form-grid grid-cols-4">
              <tgn-radio-group [choices]="salutationChoices" [control]="form.controls.salutation" [id]="id" class="full-width" />
            </div>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row row-span-sm-2">
      <div class="item col-span-3 col-span-sm-6">
        <tgn-form-element
          [control]="form.controls.firstName"
          [id]="'firstName'"
          [label]="'ENTITY.Contact.firstName' | translate"
          class="col-span-6 row-span-3 mt-sm"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input
                [formControl]="control"
                [id]="id"
                [placeholder]="'ENTITY.Contact.firstName' | translate"
                cdkFocusInitial
                matInput
                type="text"
              />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>

      <div class="item col-span-3 col-span-sm-6">
        <tgn-form-element
          [control]="form.controls.lastName"
          [hideError]="true"
          [id]="'lastName'"
          [label]="'ENTITY.Contact.lastName' | translate"
          class="col-span-6 row-span-3 mt-sm"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input
                [formControl]="form.controls.lastName"
                [placeholder]="'ENTITY.Contact.lastName' | translate"
                id="lastName"
                matInput
                type="text"
              />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row row-span-sm-2">
      <div class="item col-span-3 col-span-sm-6">
        <tgn-form-element [control]="form.controls.company" [id]="'company'" [label]="'ENTITY.Contact.company' | translate">
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input
                [formControl]="control"
                [id]="id"
                [placeholder]="'ENTITY.Contact.companyPlaceholder' | translate"
                matInput
                type="text"
              />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>

      <div class="item col-span-3 col-span-sm-6">
        <tgn-form-element
          [control]="form.controls.companyAddendum"
          [id]="'companyAddendum'"
          [label]="'ENTITY.Contact.companyAddendum' | translate"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.companyAddendum' | translate" matInput type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.address"
          [hideError]="true"
          [id]="'address'"
          [label]="'ENTITY.Contact.address' | translate"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input
                [formControl]="control"
                [id]="id"
                [placeholder]="'ENTITY.Contact.addressPlaceholder' | translate"
                matInput
                type="text"
              />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row row-span-sm-2">
      <div class="item col-span-3 col-span-sm-6">
        <tgn-form-element
          [control]="form.controls.zip"
          [hideError]="true"
          [id]="'zip'"
          [label]="'ENTITY.Contact.zip' | translate"
          class="col-span-3 row-span-3 mt-sm"
          label="Postleitzahl"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.zipPlaceholder' | translate" matInput type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>

      <div class="item col-span-3 col-span-sm-6">
        <tgn-form-element
          [control]="form.controls.city"
          [hideError]="true"
          [id]="'city'"
          [label]="'ENTITY.Contact.city' | translate"
          class="col-span-9 row-span-3 mt-sm"
          label="Ort"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.city' | translate" matInput type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.country"
          [hideError]="true"
          [id]="'country'"
          [label]="'ENTITY.Contact.country' | translate"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.country' | translate" matInput type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-3">
        <div class="form-grid grid-cols-2">
          <div class="row">
            <div class="item col-span-1">
              <tgn-form-element
                [control]="form.controls.phone"
                [hideError]="true"
                [id]="'phone'"
                [label]="'ENTITY.Contact.phone' | translate"
                class="col-span-3 row-span-3 mt-sm"
              >
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <input
                      [formControl]="control"
                      [id]="id"
                      [placeholder]="'ENTITY.Contact.phonePlaceholder' | translate"
                      matInput
                      type="text"
                    />
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>

            <div class="item col-span-1">
              <tgn-form-element
                [control]="form.controls.mobile"
                [hideError]="true"
                [id]="'phone'"
                [label]="'ENTITY.Contact.mobile' | translate"
                class="col-span-3 row-span-3 mt-sm"
              >
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <input
                      [formControl]="control"
                      [id]="id"
                      [placeholder]="'ENTITY.Contact.phonePlaceholder' | translate"
                      matInput
                      type="text"
                    />
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        </div>
      </div>
      <div class="item col-span-3">
        <tgn-form-element
          [control]="form.controls.email"
          [hideError]="true"
          [id]="'email'"
          [label]="'ENTITY.Contact.email' | translate"
          class="col-span-6 row-span-3 mt-sm"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.emailPlaceholder' | translate" matInput type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element [control]="form.controls.possibleDonor" id="possibleDonor">
          <ng-template formElement let-control="control" let-id="id">
            <mat-checkbox [formControl]="control" [id]="id">
              {{ 'ENTITY.Contact.possibleDonor' | translate }}
            </mat-checkbox>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element [control]="form.controls.noMailings" id="noMailings">
          <ng-template formElement let-control="control" let-id="id">
            <mat-checkbox [formControl]="control" [id]="id">
              {{ 'ENTITY.Contact.noMailings' | translate }}
            </mat-checkbox>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element [control]="form.controls.isDead" id="isDead">
          <ng-template formElement let-control="control" let-id="id">
            <mat-checkbox [formControl]="control" [id]="id">
              {{ 'ENTITY.Contact.isDead' | translate }}
            </mat-checkbox>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element [control]="form.controls.note" [label]="'ENTITY.Contact.note' | translate" class="col-span-12 mt-sm" id="note">
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <textarea
                [formControl]="control"
                [id]="id"
                cdkAutosizeMaxRows="10"
                cdkAutosizeMinRows="5"
                cdkTextareaAutosize
                matInput
              ></textarea>
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>
  <button (click)="close()" [disabled]="form.invalid" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.BUTTON.SAVE' | translate }}
  </button>
</div>
