import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BillStatus } from '@bill/dto/bill.dto';
import { StationDto, StationId } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { SelectComponent } from '@core/components/select/select.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { UserRole } from '@core/models/general';
import { ListIncludesAnyPipe } from '@core/pipes/list-includes.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { ModalComponent } from '@core/services/modal.service';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { UserService } from '@user/service/user.service';
import { Observable, catchError, map, of, take } from 'rxjs';

interface DialogResult {
  firstName: string | null;
  lastName: string | null;
  email: string;
  roles: UserRole[];
  defaultStation: StationId | null;
  sendInvite: boolean;
}

interface UserInviteForm {
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string>;
  roles: FormControl<UserRole[]>;
  isSuperuser: FormControl<boolean | null>;
  defaultStation: FormControl<StationDto | null>;
  sendInvite: FormControl<boolean>;
}

export function nameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (!value) {
      return { inviteName: 'Wert wird benötigt' };
    }

    // Regular expression to match a name with no special characters except letters, spaces, and accented characters
    const validNamePattern = /^[A-Za-zÄäÖöÜüßÀ-ÖØ-öø-ÿ]+$/;

    const isValid = validNamePattern.test(value);

    return !isValid ? { inviteName: 'Name kann nur aus Buchstaben bestehen und darf keine Sonderzeichen enthalten.' } : null;
  };
}

@Component({
  selector: 'app-invite-user-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    TranslateModule,
    MatDialogContent,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    FormsModule,
    ReactiveFormsModule,
    FormElementComponent,
    AsyncPipe,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    FormElementDirective,
    MatInput,
    SelectComponent,
    ToRadioChoicePipe,
    MatCheckbox,
    RoleRestrictionDirective,
    ListIncludesAnyPipe,
  ],
  templateUrl: './invite-user-dialog.component.html',
  styleUrl: './invite-user-dialog.component.scss',
})
export class InviteUserDialogComponent extends ModalComponent<{}, DialogResult> {
  form: FormGroup<UserInviteForm>;
  roles = createEnumChoices(UserRole, 'GENERAL.DOMAIN.UserRole.').filter(
    it => it.object !== UserRole.Superuser && it.object !== UserRole.Deactivated,
  );

  stations$!: Observable<StationDto[]>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly BillStatus = BillStatus;

  constructor(
    fb: FormBuilder,
    dialogRef: MatDialogRef<InviteUserDialogComponent, DialogResult>,
    private baseDataService: BaseDataService,
    private userService: UserService,
  ) {
    super(dialogRef);

    this.stations$ = this.baseDataService.getBaseData().pipe(
      take(1),
      map(data => data.stations),
    );

    this.form = fb.group(
      {
        firstName: fb.control<string | null>('', [Validators.required, nameValidator()]),
        lastName: fb.control<string | null>('', [Validators.required, nameValidator()]),
        email: fb.nonNullable.control<string>('', [Validators.required, Validators.email], [this.checkEmailExists()]),
        roles: fb.nonNullable.control<UserRole[]>([], [Validators.required]),
        isSuperuser: fb.control<boolean | null>(false),
        defaultStation: fb.control<StationDto | null>(null),
        sendInvite: fb.nonNullable.control<boolean>(false),
      },
      { updateOn: 'change' },
    );
  }

  checkEmailExists(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }

      return this.userService.userExists(control.value).pipe(
        map(exists => (exists ? { emailExists: 'Es gibt bereits einen Nutzer mit dieser Email Adresse' } : null)),
        catchError(() => of(null)),
      );
    };
  }

  close() {
    if (!this.form.valid) {
      return;
    }

    const value = this.form.getRawValue();

    let roles = value.roles;

    if (value.isSuperuser) {
      roles = [...roles, UserRole.Superuser];
    }

    const dialogResult: DialogResult = {
      ...value,
      roles: roles,
      defaultStation: value.defaultStation?.id ?? null,
    };

    this.closeWithResult(dialogResult);
  }
}
