@switch (mode) {
  @case (ComponentMode.ChooseTaskType) {
    <h1 mat-dialog-title class="title">{{ 'COMPONENT.CreateTask.TITLE' | translate }}</h1>

    <div mat-dialog-content class="choice-container">
      <tgn-radio-group [choices]="taskTypeChoices" [control]="entityForm.controls.entityType"></tgn-radio-group>
    </div>

    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>
        {{ 'GENERAL.BUTTON.CANCEL' | translate }}
      </button>
      <button (click)="continue()" class="tgn-button" mat-flat-button [disabled]="!entityForm.controls.entityType.value">
        {{ 'GENERAL.BUTTON.NEXT' | translate }}
      </button>
    </div>
  }
  @case (ComponentMode.ChooseEntity) {
    <h1 mat-dialog-title class="title">{{ 'COMPONENT.CreateTask.TITLE' | translate }}</h1>

    <div mat-dialog-content>
      <div class="mb-small">
        @switch (entityForm.controls.entityType.value) {
          @case (TaskEntityType.Animal) {
            <span>{{ 'GENERAL.DOMAIN.ANIMAL' | translate }}</span>
          }
          @case (TaskEntityType.Case) {
            <span>{{ 'GENERAL.DOMAIN.CASE' | translate }}</span>
          }
          @case (TaskEntityType.Box) {
            <span>{{ 'GENERAL.DOMAIN.BOX' | translate }}</span>
          }
          @case (TaskEntityType.Station) {
            <span>{{ 'GENERAL.DOMAIN.STATION' | translate }}</span>
          }
        }
      </div>
      <tgn-autocomplete
        (onOptionSelected)="setEntity($event)"
        (onRemoveSelected)="removeEntity()"
        [displayFn]="this.displaySearchResultFn"
        [valueFn]="searchResultValueFn"
        [placeholder]="'Suche'"
        [value]="entityForm.controls.entity.controls.name.value"
        [viewStore]="searchAutocompleteStore"
        [locked]="entityForm.controls.entity.controls.entityId.value !== null"
      ></tgn-autocomplete>
    </div>

    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>
        {{ 'GENERAL.BUTTON.CANCEL' | translate }}
      </button>
      <button (click)="back()" mat-button>
        {{ 'GENERAL.BUTTON.PREVIOUS' | translate }}
      </button>
      <button (click)="continue()" class="tgn-button" mat-flat-button [disabled]="!entityForm.controls.entity.controls.entityId.value">
        {{ 'GENERAL.BUTTON.NEXT' | translate }}
      </button>
    </div>
  }
  @case (ComponentMode.TaskSettings) {
    <div class="task-breadcrumb mdc-dialog__content">
      @if (taskTitleBreadcrumb) {
        <span>{{ taskTitleBreadcrumb }}</span>
        <tgn-icon [iconName]="'radixChevronRight'" />
      }
      <span>{{ 'PAGE.CARE.NEW_TASK.BREADCRUMB_NEW_TASK' | translate }}</span>
    </div>
    <div mat-dialog-content>
      <tgn-form-element [control]="form.controls.title" [hideError]="true" [id]="'title'" class="">
        <ng-template formElement let-control="control" let-id="id">
          <mat-form-field appearance="outline">
            <input [formControl]="control" [id]="id" [placeholder]="'PAGE.CARE.NEW_TASK.PLACEHOLDER.TITLE' | translate" matInput />
          </mat-form-field>
        </ng-template>
      </tgn-form-element>

      @if (fileCollection !== null) {
        <tgn-task-form
          [form]="form"
          [fileCollection]="fileCollection"
          [isCreate]="true"
          [taskHistory]="[]"
          [taskTypeEntity]="entityForm.controls.entityType.value"
          [triggerRefreshFiles]="triggerRefreshFiles"
          [existingTask]="null"
          [taskCategoryGroups]="this.taskData.taskCategoryGroups"
        />
      }
    </div>

    <div mat-dialog-actions>
      <div class="modal-footer-left">
        @if (fileCollection !== null) {
          <tgn-button-upload
            [fileCollection]="fileCollection"
            [useIcon]="true"
            [showFileList]="false"
            (onUploadCompleted)="triggerRefreshFiles.next()"
            [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
            [uploadEntity]="uploadEntity"
          />
        }
      </div>

      <button mat-button mat-dialog-close>
        {{ 'GENERAL.BUTTON.CANCEL' | translate }}
      </button>
      <button (click)="back()" mat-button>
        {{ 'GENERAL.BUTTON.PREVIOUS' | translate }}
      </button>
      <button (click)="createTask()" class="tgn-button" mat-flat-button [disabled]="!form.controls.taskCategory.value">
        {{ 'GENERAL.BUTTON.CREATE' | translate }}
      </button>
    </div>
  }
}
