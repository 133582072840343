<div *tgnContextActions class="tgn-context-actions">
  <a
    [routerLink]="appRoutes.CASE_CREATE_BILL.url(this.caseId)"
    [tgnContextItem]="{ object: caseStore.case$ }"
    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE | andRoleRestriction: RESTRICT_CASE_WRITE"
    class="tgn-button"
    mat-flat-button
  >
    {{ 'PAGE.CREATE_BILL.ACTIONS.CREATE_BILL' | translate }}
  </a>
</div>

@if (datasource) {
  <tgn-scrollable-table>
    <table #table="matTable" [dataSource]="datasource" mat-table>
      <tr *matHeaderRowDef="columns" mat-header-row></tr>
      <tr
        *matRowDef="let bill; table: table; columns: columns"
        mat-row
        [routerLink]="appRoutes.CASE_EDIT_BILL.url(caseId, bill.id)"
        class="table-row-link"
      ></tr>

      <ng-container matColumnDef="billingNumber">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Bill.billingNumber' | translate }}</th>
        <td *matCellDef="let bill; table: table" mat-cell>
          {{ bill.billingNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Bill.status' | translate }}</th>
        <td *matCellDef="let bill; table: table" mat-cell>
          {{ bill.status | enumDisplay: 'GENERAL.DOMAIN.BillStatus' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Bill.billRangeType' | translate }}</th>
        <td *matCellDef="let bill; table: table" mat-cell>
          {{ bill.billRangeType | enumDisplay: 'GENERAL.DOMAIN.BillRangeTypeShort' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Bill.total' | translate }}</th>
        <td *matCellDef="let bill; table: table" mat-cell class="align-left">
          {{ bill.totalPrice | tgnCurrency: { withCHF: true } }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Bill.createdBy' | translate }}</th>
        <td *matCellDef="let bill; table: table" mat-cell>
          {{ bill.createdBy | fullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceDocumentDate">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Bill.invoiceDocumentDate' | translate }}</th>
        <td *matCellDef="let bill; table: table" mat-cell>
          {{ bill.invoiceDocumentDate | date }}
        </td>
      </ng-container>

      <ng-container matColumnDef="billingDate">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Bill.billingDate' | translate }}</th>
        <td *matCellDef="let bill; table: table" mat-cell>
          {{ bill.billingDate | date }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let bill; table: table" mat-cell>
          <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ bill: bill }" [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-template let-bill="bill" matMenuContent>
              <a mat-menu-item [routerLink]="appRoutes.CASE_EDIT_BILL.url(caseId, bill.id)">
                <span>{{ 'GENERAL.BUTTON.OPEN' | translate }}</span>
              </a>
              <button (click)="downloadPdf($event, bill)" mat-menu-item>
                <span>{{ 'PAGE.CASE_BILLS.ACTIONS.CREATE_PDF' | translate }}</span>
              </button>
              @if (bill.status !== BillStatus.Cancelled && bill.status !== BillStatus.NewStorno) {
                <button
                  [tgnContextItem]="{ object: caseStore.case$ }"
                  [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
                  (agreeEvent)="createStornoBill(bill)"
                  mat-menu-item
                  appConfirmationDialog
                  [dialogTitle]="'PAGE.CASE_BILLS.CONFIRM.STORNO_TITLE' | translate"
                  [dialogDescription]="'PAGE.CASE_BILLS.CONFIRM.STORNO_MESSAGE' | translate"
                >
                  <span>{{ 'PAGE.CASE_BILLS.ACTIONS.CREATE_STORNO' | translate }}</span>
                </button>
              }
            </ng-template>
          </mat-menu>
        </td>
        <td *matFooterCellDef mat-footer-cell></td>
      </ng-container>
    </table>
  </tgn-scrollable-table>
}
