import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { GroupTag } from '@case/dtos/base-data.dto';
import { CheckboxGroupComponent } from '@core/components/checkbox-group/checkbox-group.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import {
  affectedGeneralRegionChoices,
  affectedLimbChoices,
  extendedAffectedRegionChoices,
  generalFieldChoices,
  sexChoices,
  weightUnitChoices,
} from '@core/models/form/form-helper';
import { TranslateModule } from '@ngx-translate/core';

import { ExitCheckForm } from '../exit-check-page.component';

@Component({
  selector: 'app-exit-check-health',
  standalone: true,
  imports: [
    FormElementComponent,
    RadioGroupComponent,
    FormElementDirective,
    TranslateModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    CheckboxGroupComponent,
    SelectComponent,
    MatFormField,
    MatInput,
  ],
  templateUrl: './exit-check-health.component.html',
  styleUrl: './exit-check-health.component.scss',
})
export class ExitCheckHealthComponent {
  @Input({ required: true }) form!: FormGroup<ExitCheckForm>;
  @Input({ required: true }) animal!: AnimalDto;
  protected readonly generalFieldChoices = generalFieldChoices;
  protected readonly affectedGeneralRegionChoices = affectedGeneralRegionChoices;
  protected readonly extendedAffectedRegionChoices = extendedAffectedRegionChoices;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GroupTag = GroupTag;
  protected readonly affectedLimbChoices = affectedLimbChoices;
  protected readonly sexChoices = sexChoices;
  protected readonly weightUnitChoices = weightUnitChoices;
}
