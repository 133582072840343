import { Component, Input, model } from '@angular/core';
import { RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { RoleRestriction } from '@core/models/role';
import { TranslateModule } from '@ngx-translate/core';

export interface Tab {
  label: string;
  value: string;
  roleRestriction?: RoleRestriction;
}

@Component({
  selector: 'tgn-nav-tabs',
  standalone: true,
  imports: [TranslateModule, RoleRestrictionDirective],
  templateUrl: './nav-tabs.component.html',
  styleUrl: './nav-tabs.component.scss',
})
export class NavTabsComponent {
  @Input({ required: true }) tabs!: Tab[];

  activeTab = model.required<string | undefined>();
}
