import { AsyncPipe, NgClass } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCell, MatTableModule } from '@angular/material/table';
import { TaskCategoryService } from '@baseData/services/task-category.service';
import { CreateTaskCategoryDto, TaskCategoryDto, TaskCategoryGroup, TaskCategoryId, UpdateTaskCategoryDto } from '@care/dtos/task.dto';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { defaultDebounce } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { uniqueNameValidator } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { map, shareReplay } from 'rxjs';

interface TableConfig {}

export type UpdateTaskCategoryForm = FormGroup<{
  active: FormControl<boolean>;
}>;

export type NewTaskCategoryForm = FormGroup<{
  name: FormControl<string | null>;
}>;

@Component({
  selector: 'tgn-base-data-categories-by-group',
  standalone: true,
  imports: [
    AsyncPipe,
    FieldErrorTranslationPipe,
    FormElementComponent,
    MatButton,
    MatCell,
    TranslateModule,
    MatTableModule,
    TypesafeMatTableModule,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    SelectComponent,
    MatError,
    NgClass,
  ],
  templateUrl: './base-data-categories-by-group.component.html',
  styleUrl: './base-data-categories-by-group.component.scss',
})
export class BaseDataCategoriesByGroupComponent implements OnInit {
  @Input({ required: true }) categoryGroup!: TaskCategoryGroup;
  columns = ['name', 'active'];
  appRoutes = routes_config;

  newTaskCategoryForm!: NewTaskCategoryForm;
  editTaskCategoryFormGroups: Map<TaskCategoryId, UpdateTaskCategoryForm> = new Map<TaskCategoryId, UpdateTaskCategoryForm>();

  dataSource!: TigonDatasource<TaskCategoryDto, TableConfig>;
  activeInactiveChoice: RadioChoice<Boolean>[] = [
    {
      label: 'GENERAL.FORM.LABEL.Active',
      object: true,
    },
    {
      label: 'GENERAL.FORM.LABEL.Inactive',
      object: false,
    },
  ];

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private taskCategoryService: TaskCategoryService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.dataSource = new TigonDatasource<TaskCategoryDto, TableConfig>(
      {},
      () => {
        return this.taskCategoryService.getAll().pipe(
          map(data =>
            data.filter(taskCategory => {
              return taskCategory.group === this.categoryGroup;
            }),
          ),
        );
      },
      this.destroyRef,
    );

    this.dataSource.getData().subscribe(data => {
      this.editTaskCategoryFormGroups = new Map();
      data.forEach((taskCategory: TaskCategoryDto) => {
        const taskCategoryFormGroup: UpdateTaskCategoryForm = this.fb.group({
          active: this.fb.nonNullable.control<boolean>(taskCategory.active, [Validators.required]),
        });
        this.editTaskCategoryFormGroups.set(taskCategory.id, taskCategoryFormGroup);
        this.autoUpdateRows(taskCategoryFormGroup, taskCategory);
      });
    });

    this.createNewTaskCategoryForm();
  }

  addEntry() {
    const form = this.newTaskCategoryForm.getRawValue();
    if (!form.name) {
      return;
    }

    const createTaskCategoryDto: CreateTaskCategoryDto = {
      name: form.name,
      group: this.categoryGroup,
    };

    this.taskCategoryService.create(createTaskCategoryDto).subscribe({
      next: () => {
        this.newTaskCategoryForm.setValue({
          name: null,
        });
        this.newTaskCategoryForm.markAsUntouched();
        this.newTaskCategoryForm.markAsPristine();
        this.snackbar.showSuccessMessage(`Die Kategorie: '${createTaskCategoryDto.name}' wurde erfolgreich erstellt.`);
        this.dataSource.refresh();
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Die Kategorie konnte nicht erstellt werden.');
      },
    });
  }

  private createNewTaskCategoryForm() {
    this.newTaskCategoryForm = this.fb.group({
      name: this.fb.control<string | null>(
        null,

        {
          validators: [Validators.required, Validators.minLength(1)],

          asyncValidators: [uniqueNameValidator(this.dataSource.getData().pipe(shareReplay(1)))],
          updateOn: 'change',
        },
      ),
    });
    this.accessService.disableBasedOnRole(this.newTaskCategoryForm, RestrictedSection.Backoffice);
  }

  private autoUpdateRows(formGroup: UpdateTaskCategoryForm, taskCategory: TaskCategoryDto) {
    formGroup.valueChanges.pipe(defaultDebounce(), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        if (formGroup.invalid) {
          return;
        }

        const isActive = formGroup.getRawValue().active;

        const dto: UpdateTaskCategoryDto = {
          active: isActive,
        };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.taskCategoryService.update(taskCategory.id, dto).subscribe(() => {
          this.dataSource.refresh();
        });
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Die Kategorie konnte nicht aktualisiert werden.');
      },
    });
  }
}
