import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, User as FirebaseUser } from '@angular/fire/auth';
import { StationDto, StationId } from '@case/dtos/base-data.dto';
import { UserRole } from '@core/models/general';
import { BaseService } from '@core/services/base-service';
import { BehaviorSubject, Observable, ReplaySubject, filter, shareReplay, switchMap, take, tap } from 'rxjs';

import { UpdateUserProfileDto } from '../dto/user-profile.dto';
import { UpdateUsedRoleDto, UpdateUsedStationDto, UserDto } from '../dto/user.dto';

@Injectable({ providedIn: 'root' })
export class CurrentUserService extends BaseService {
  currentUser$: Observable<UserDto>;
  usedStation$: ReplaySubject<StationDto | null> = new ReplaySubject<StationDto | null>(1);
  usedRole$: ReplaySubject<UserRole | null> = new ReplaySubject<UserRole | null>(1);

  private http: HttpClient;
  private firebaseUserSubject = new BehaviorSubject<FirebaseUser | null>(null);
  private firebaseUser$: Observable<FirebaseUser | null> = this.firebaseUserSubject.asObservable();
  private currentUser: UserDto | null = null;

  constructor(
    backend: HttpBackend,
    private auth: Auth,
    private authorizedClient: HttpClient,
  ) {
    super();
    this.http = new HttpClient(backend);

    this.auth.onAuthStateChanged(user => {
      if (user) {
        this.firebaseUserSubject.next(user);
      } else {
        this.firebaseUserSubject.next(null);
      }
    });

    this.currentUser$ = this.firebaseUser$.pipe(
      switchMap(() => this.getCurrentUser()),
      shareReplay(1),
      filter(user => !!user),
    );

    this.currentUser$.subscribe(user => {
      this.currentUser = user;
      this.usedStation$.next(user.usedStation);
      this.usedRole$.next(user.usedRole);
    });
  }

  getInitialCurrentUser(token: string): Observable<UserDto> {
    return this.http.get<UserDto>(`${this.apiUrl}/me/user`, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  updateCurrentUser(user: UpdateUserProfileDto): Observable<UserDto> {
    return this.authorizedClient.put<UserDto>(`${this.apiUrl}/me/user`, user).pipe(
      // Trigger update to current user with new data
      tap(() => this.firebaseUserSubject.next(this.firebaseUserSubject.value)),
    );
  }

  updateCurrentUserAndRole(station: StationId | null, role: UserRole): Observable<UserDto> {
    return this.updateUsedStation(station).pipe(switchMap(() => this.updateUsedRole(role)));
  }

  updateUsedRole(usedRole: UserRole | null): Observable<UserDto> {
    const dto: UpdateUsedRoleDto = {
      role: usedRole,
    };
    return this.authorizedClient.put<UserDto>(`${this.apiUrl}/me/user/used-role`, dto).pipe(
      take(1),
      tap(() => {
        if (this.currentUser) {
          this.currentUser.usedRole = usedRole;
          this.usedRole$.next(usedRole);
        }
      }),
    );
  }

  updateUsedStation(usedStation: StationId | null): Observable<UserDto> {
    const dto: UpdateUsedStationDto = {
      station: usedStation,
    };
    return this.authorizedClient.put<UserDto>(`${this.apiUrl}/me/user/used-station`, dto).pipe(
      take(1),
      tap(result => {
        if (this.currentUser) {
          this.currentUser.usedStation = result.usedStation;
          this.usedStation$.next(result.usedStation);
        }
      }),
    );
  }

  private getCurrentUser(): Observable<UserDto> {
    return this.authorizedClient.get<UserDto>(`${this.apiUrl}/me/user`);
  }
}
