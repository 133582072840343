import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PlacementMediationComponent } from '@animal/components/placement-mediation/placement-mediation.component';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { AnimalTitlePipe } from '@animal/pipes/animal-title.pipe';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseDto } from '@case/dtos/case.dto';
import { CompactPlacementDto, UpdateCompactPlacementDto } from '@case/dtos/placement.dto';
import { CaseAnimalService } from '@case/services/case-animal.service';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { PlacementState, PlacementType } from '@core/models/general';
import { defaultDebounce } from '@core/services/base-service';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { take } from 'rxjs';

export type CompactPlacementForm = FormGroup<{
  placementType: FormControl<PlacementType | null>;
  placementState: FormControl<PlacementState | null>;
  placementNote: FormControl<string | null>;
  mediationHasProspect: FormControl<boolean | null>;
  mediationHasProspectNote: FormControl<string | null>;
  mediationHasPlacementDate: FormControl<boolean | null>;
  mediationHasPlacementDateNote: FormControl<string | null>;
  mediationIsInTrial: FormControl<boolean | null>;
  mediationIsInTrialNote: FormControl<string | null>;
  mediationPlacementSuccessful: FormControl<boolean | null>;
  mediationPlacementSuccessfulNote: FormControl<string | null>;
  mediationPhonePlacementSupervision: FormControl<boolean | null>;
  mediationPhonePlacementSupervisionNote: FormControl<string | null>;
  mediationOnPremisePlacementSupervision: FormControl<boolean | null>;
  mediationOnPremisePlacementSupervisionNote: FormControl<string | null>;
  mediationCompleted: FormControl<boolean | null>;
  mediationCompletedNote: FormControl<string | null>;
}>;

@Component({
  selector: 'tgn-compact-animal-placement',
  standalone: true,
  imports: [
    CdkTextareaAutosize,
    FormElementComponent,
    FormElementDirective,
    MatFormField,
    MatInput,
    PlacementMediationComponent,
    ReactiveFormsModule,
    SelectComponent,
    TranslateModule,
    AnimalTitlePipe,
    CollapsibleComponent,
  ],
  templateUrl: './compact-animal-placement.component.html',
  styleUrl: './compact-animal-placement.component.scss',
})
export class CompactAnimalPlacementComponent implements OnInit {
  placementForm?: CompactPlacementForm;
  placement?: CompactPlacementDto;
  @Input({ required: true }) case!: CaseDto | CaseDetailDto;
  @Input({ required: true }) animal!: AnimalDto;
  placementStateChoices: RadioChoice<PlacementState>[] = createEnumChoices<PlacementState>(
    PlacementState,
    'GENERAL.DOMAIN.PlacementState.',
  );

  placementTypeChoices: RadioChoice<PlacementType>[] = createEnumChoices<PlacementType>(PlacementType, 'GENERAL.DOMAIN.PlacementType.');
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private caseAnimalService: CaseAnimalService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.caseAnimalService
      .getPlacementCompact(this.case.id, this.animal.id)
      .pipe(take(1))
      .subscribe((placement: CompactPlacementDto) => {
        this.placement = placement;
        this.buildPlacementForm(placement);
        if (this.case.archived || this.animal.archived) {
          this.placementForm!.disable();
        }
        this.accessService.disableBasedOnRole(this.placementForm!, RestrictedSection.Case);
        this.placementForm!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
          const dto: UpdateCompactPlacementDto = this.placementForm!.getRawValue();
          this.caseAnimalService.updatePlacementCompact(this.case.id, this.animal.id, dto).subscribe(() => {
            this.caseAnimalService
              .getPlacementCompact(this.case.id, this.animal.id)
              .pipe(take(1))
              .subscribe((newPlacement: CompactPlacementDto) => {
                this.placement = newPlacement;
              });
          });
        });
      });
  }

  buildPlacementForm(placement: CompactPlacementDto) {
    const fb = this.fb;
    this.placementForm = this.fb.group(
      {
        placementType: fb.control({ value: placement.placementType, disabled: true }),
        placementState: fb.control({ value: placement.placementState, disabled: true }),
        mediationHasProspect: fb.control(placement.mediationHasProspect),
        mediationHasProspectNote: fb.control(placement.mediationHasProspectNote),
        mediationHasPlacementDate: fb.control(placement.mediationHasPlacementDate),
        mediationHasPlacementDateNote: fb.control(placement.mediationHasPlacementDateNote),
        mediationIsInTrial: fb.control(placement.mediationIsInTrial),
        mediationIsInTrialNote: fb.control(placement.mediationIsInTrialNote),
        mediationPlacementSuccessful: fb.control(placement.mediationPlacementSuccessful),
        mediationPlacementSuccessfulNote: fb.control(placement.mediationPlacementSuccessfulNote),
        mediationPhonePlacementSupervision: fb.control(placement.mediationPhonePlacementSupervision),
        mediationPhonePlacementSupervisionNote: fb.control(placement.mediationPhonePlacementSupervisionNote),
        mediationOnPremisePlacementSupervision: fb.control(placement.mediationOnPremisePlacementSupervision),
        mediationOnPremisePlacementSupervisionNote: fb.control(placement.mediationOnPremisePlacementSupervisionNote),
        mediationCompleted: fb.control(placement.mediationCompleted),
        mediationCompletedNote: fb.control(placement.mediationCompletedNote),
        placementNote: fb.control({ value: placement.placementNote, disabled: true }),
      },
      { disable: true },
    );
  }
}
