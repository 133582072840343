import { Component, Input } from '@angular/core';
import { BillingRelevantInfoDto } from '@bill/dto/billing-relevant-info.dto';

@Component({
  selector: 'app-billing-relevant-info',
  standalone: true,
  imports: [],
  templateUrl: './billing-relevant-info.component.html',
  styleUrl: './billing-relevant-info.component.scss',
})
export class BillingRelevantInfoComponent {
  @Input({ required: true }) billingRelevantInfo!: BillingRelevantInfoDto;
  @Input({ required: true }) type!: 'animal' | 'case';
}
