import { Pipe, PipeTransform } from '@angular/core';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';

export type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];

export type PickKeysMatching<T, V> = {
  [key in KeysMatching<T, V>]: V;
};

/**
 * Transform a list of a generic object that has at least some string value as label into a RadioChoice list.
 * Labelkey is restricted to all keys in the object that have a string value.
 * see: https://stackoverflow.com/questions/54520676/in-typescript-how-to-get-the-keys-of-an-object-type-whose-values-are-of-a-given
 */
@Pipe({ standalone: true, name: 'toRadioChoice' })
export class ToRadioChoicePipe implements PipeTransform {
  transform<T extends PickKeysMatching<T, string>>(values: T[] | undefined | null, labelKey: KeysMatching<T, string>): RadioChoice<T>[] {
    if (values === undefined || values === null) {
      return [];
    }
    return values.map((it: T) => {
      const radioChoice: RadioChoice<T> = {
        label: it[labelKey],
        object: it,
      };
      return radioChoice;
    });
  }
}
