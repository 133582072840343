import { TextFieldModule } from '@angular/cdk/text-field';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { ModalComponent } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService } from '@user/service/access.service';
import { take } from 'rxjs';

import { ContactDto, ContactStatus, Salutation, SalutationEnum } from '../../dto/contact.dto';
import { ContactService } from '../../service/contact-service';

export interface ContactDialogResult {
  dto: ContactDto;
}

interface DialogData {
  contact?: ContactDto;
  edit?: boolean;
}

export interface ContactDialogForm {
  salutation: FormControl<Salutation | null>;
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  company: FormControl<string | null>;
  companyAddendum: FormControl<string | null>;
  address: FormControl<string | null>;
  addressAddendum: FormControl<string | null>;
  poBox: FormControl<string | null>;
  zip: FormControl<string | null>;
  city: FormControl<string | null>;
  country: FormControl<string | null>;
  phone: FormControl<string | null>;
  phoneWork: FormControl<string | null>;
  mobile: FormControl<string | null>;
  email: FormControl<string | null>;
  possibleDonor: FormControl<boolean>;
  noMailings: FormControl<boolean>;
  isDead: FormControl<boolean>;
  status: FormControl<ContactStatus>;
  legalRepresentationId: FormControl<string | null>;
  note: FormControl<string | null>;
}

export const DEFAULT_COUNTRY = 'Schweiz';

@Component({
  selector: 'app-contact-dialog',
  standalone: true,
  imports: [
    FormElementComponent,
    MatButtonModule,
    MatRadioModule,
    MatDialogModule,
    RadioGroupComponent,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormElementDirective,
    IconComponent,
    TextFieldModule,
    MatInputModule,
    RoleRestrictionDirective,
  ],
  templateUrl: './contact-dialog.component.html',
  styleUrl: './contact-dialog.component.scss',
})
export class ContactDialogComponent extends ModalComponent<DialogData, ContactDialogResult> {
  form: FormGroup<ContactDialogForm>;

  salutationChoices: RadioChoice<Salutation>[] = createEnumChoices(SalutationEnum, 'GENERAL.DOMAIN.Salutation.');

  data: DialogData;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    fb: FormBuilder,
    dialogRef: MatDialogRef<ContactDialogComponent, ContactDialogResult>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private contactService: ContactService,
    private snackbar: SnackbarService,
    private accessService: AccessService,
  ) {
    super(dialogRef);
    this.data = data;
    this.form = createContactForm(fb, data.contact);

    this.accessService.disableBasedOnRoleRestriction(this.form, GENERAL_WRITE_EXCLUDE);
  }

  close() {
    const value = this.form.getRawValue();

    if (this.data.edit) {
      this.contactService
        .update(this.data!.contact!.id, value)
        .pipe(take(1))
        .subscribe({
          next: (dto: ContactDto) => {
            this.closeWithResult({ dto: dto });
          },
          error: (err: unknown) => {
            console.error('Could not create contact: ', err);
            this.snackbar.showErrorMessage('PAGE.CONTACTS.ERROR.COULD_NOT_CREATE_CONTACT');
            this.closeWithResult();
          },
        });
    } else {
      this.contactService
        .create(value)
        .pipe(take(1))
        .subscribe({
          next: (dto: ContactDto) => {
            this.closeWithResult({ dto: dto });
          },
          error: (err: unknown) => {
            console.error('Could not create contact: ', err);
            this.snackbar.showErrorMessage('PAGE.CONTACTS.ERROR.COULD_NOT_CREATE_CONTACT');
            this.closeWithResult();
          },
        });
    }
  }
}

export function createContactForm(fb: FormBuilder, contact?: ContactDto): FormGroup<ContactDialogForm> {
  return fb.group<ContactDialogForm>(
    {
      salutation: fb.control<Salutation | null>(contact?.salutation ?? null),
      firstName: fb.control<string | null>(contact?.firstName ?? null),
      lastName: fb.control<string | null>(contact?.lastName ?? null, [Validators.required]),
      company: fb.control<string | null>(null),
      companyAddendum: fb.control<string | null>(contact?.companyAddendum ?? null),
      address: fb.control<string | null>(contact?.address ?? null, [Validators.required]),
      addressAddendum: fb.control<string | null>(contact?.addressAddendum ?? null),
      poBox: fb.control<string | null>(contact?.poBox ?? null),
      zip: fb.control<string | null>(contact?.zip ?? null, [Validators.required]),
      city: fb.control<string | null>(contact?.city ?? null, [Validators.required]),
      country: fb.control<string | null>(contact ? contact.country : DEFAULT_COUNTRY, [Validators.required]),
      phone: fb.control<string | null>(contact?.phone ?? null),
      phoneWork: fb.control<string | null>(contact?.phoneWork ?? null),
      mobile: fb.control<string | null>(contact?.mobile ?? null),
      email: fb.control<string | null>(contact?.email ?? null, [Validators.email]),
      possibleDonor: fb.nonNullable.control<boolean>(contact?.possibleDonor ?? false),
      noMailings: fb.nonNullable.control<boolean>(contact?.noMailings ?? false),
      isDead: fb.nonNullable.control<boolean>(contact?.isDead ?? false),
      status: fb.nonNullable.control<ContactStatus>(contact?.status ?? ContactStatus.New),
      legalRepresentationId: fb.control<string | null>(contact?.legalRepresentationId ?? null),
      note: fb.control<string | null>(contact?.note ?? null),
    },
    {},
  );
}
