import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { AnimalNamePipe } from '@animal/pipes/animal-name.pipe';
import { WithDefaultAnimalNamePipe } from '@animal/pipes/with-default-animal-name.pipe';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseService } from '@case/services/case.service';
import { CheckboxGroupComponent } from '@core/components/checkbox-group/checkbox-group.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { AnimalState } from '@core/models/general';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { ModalComponent } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

interface DialogData {
  case: CaseDetailDto;
}

interface DialogResult {}

@Component({
  selector: 'app-case-close-dialog',
  standalone: true,
  imports: [
    DateInputComponent,
    FormElementComponent,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    CheckboxGroupComponent,
    MatCheckbox,
    ReactiveFormsModule,
    WithDefaultAnimalNamePipe,
    AnimalNamePipe,
    EnumDisplayPipe,
  ],
  templateUrl: './case-close-dialog.component.html',
  styleUrl: './case-close-dialog.component.scss',
})
export class CaseCloseDialogComponent extends ModalComponent<DialogData, DialogResult> implements OnInit {
  animalsFormArray: FormArray<FormControl<boolean>> = this.fb.array<FormControl<boolean>>([]);
  animals: AnimalDto[] = [];
  initialized = false;

  caseName = '';

  constructor(
    dialogRef: MatDialogRef<CaseCloseDialogComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private caseService: CaseService,
    private snackbar: SnackbarService,
    private translate: TranslateService,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.caseName = [this.data.case.title, this.data.case.caseNumber].filter(notNullish).join(' - ');
    this.caseService.get(this.data.case.id).subscribe(caseDto => {
      const animals = caseDto.caseAnimals.map(caseAnimal => caseAnimal.animal);

      this.animalsFormArray = this.fb.array<FormControl<boolean>>(
        animals.map(animal => {
          const shouldComplete = animal.state !== AnimalState.Completed;
          return this.fb.nonNullable.control<boolean>({
            value: shouldComplete,
            disabled: animal.state === AnimalState.Completed,
          });
        }),
      );
      this.animals = animals;
      this.initialized = true;
    });
  }

  complete() {
    const shouldComplete = this.animalsFormArray.getRawValue();

    const animals = this.animals.filter((_, i) => shouldComplete[i]);

    const closeCaseDto = {
      animalIds: animals.map(animal => animal.id),
    };
    this.caseService.closeCase(this.data.case.id, closeCaseDto).subscribe(() => {
      this.snackbar.showSuccessMessage(this.translate.instant('COMPONENT.CaseCloseDialog.INFO_SUCCESS'));
      this.closeWithResult({});
    });
  }
}
