import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { UserRole } from '@core/models/general';
import { RESTRICT_CASE_WRITE } from '@core/models/role';
import { FileListComponent } from '@file/component/file-list/file-list.component';
import { FileDto } from '@file/dto/file.dto';
import { Observable } from 'rxjs';

import { CaseDetailStore } from '../case-layout/case-layout.component';

interface TableConfig {
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-case-files',
  standalone: true,
  imports: [FileListComponent, AsyncPipe],
  templateUrl: './case-files.component.html',
  styleUrl: './case-files.component.scss',
})
export class CaseFilesComponent {
  datasource?: PagedTigonDatasource<FileDto, TableConfig>;

  case$: Observable<CaseDetailDto>;

  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RESTRICT_CASE_WRITE = RESTRICT_CASE_WRITE;

  constructor(private caseDetailStore: CaseDetailStore) {
    this.case$ = this.caseDetailStore.case$;
  }
}
