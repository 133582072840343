<div class="statistics-card">
  <h3 class="card-title">
    {{ title }}
    @if (info) {
      <tgn-icon [iconName]="'radixInfoCircled'" [matTooltip]="info" />
    }
  </h3>
  <div class="statistics-card-content">
    <ng-content></ng-content>
  </div>
</div>
