import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { WildAnimalStatisticsControls } from '@case/models/new-case-form';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { NumberInputComponent } from '@core/components/number-input/number-input.component';

@Component({
  selector: 'tgn-wild-animal-statistics-form',
  standalone: true,
  imports: [FormElementComponent, FormElementDirective, NumberInputComponent, ReactiveFormsModule, NgTemplateOutlet, NgClass],
  templateUrl: './wild-animal-statistics-form.component.html',
  styleUrl: './wild-animal-statistics-form.component.scss',
})
export class WildAnimalStatisticsFormComponent {
  @Input({ required: true }) form!: FormGroup<WildAnimalStatisticsControls>;
  @Input() inline = false;
  @Input() variantDarker = false;
}
