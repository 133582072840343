<tgn-autocomplete
  (onAdditionalActionSelected)="openContactModal()"
  (onOptionSelected)="setRelatedContact($event)"
  (onRemoveSelected)="removeSelectedContact()"
  [additionalAction]="allowNewCreation ? ('COMPONENT.Autocomplete.ACTION.CREATE_NEW_CONTACT' | translate) : undefined"
  [disabled]="control.disabled"
  [displayFn]="contactDisplayFn"
  [id]="id"
  [locked]="control.value !== null"
  [matTooltip]="control.value | contactDisplay"
  [placeholder]="'PAGE.ENTRY_CHECK.FORM.PLACEHOLDER.ADD_CONTACT'"
  [valueFn]="getContactValueFn"
  [value]="control.value | contactDisplay"
  [viewStore]="contactViewStore"
  class="contact-autocomplete"
></tgn-autocomplete>
@if (control.value !== null && !control.disabled) {
  <button type="button" role="button" class="edit-contact-button" (click)="editContact($event)" mat-icon-button>
    <tgn-icon [iconName]="'radixPencil1'" />
  </button>
}
