import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AnimalTaxonomyBaseData } from '@animal/model/animal';
import { GroupDto, RaceDto, SpeciesDto, SubGroupDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ViewStore, ViewStoreQuery, toLoadable } from '@core/components/autocomplete/model/loadable';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, map, shareReplay, take } from 'rxjs';

@Component({
  selector: 'tgn-animal-race-input',
  standalone: true,
  imports: [AutocompleteComponent, TranslateModule],
  templateUrl: './animal-race-input.component.html',
  styleUrl: './animal-race-input.component.scss',
})
export class AnimalRaceInputComponent implements OnChanges {
  @Input({ required: true }) control!: FormControl<string | null>;
  @Input({ required: true }) taxonomy!: {
    group?: GroupDto | null;
    subGroup?: SubGroupDto | null;
    species?: SpeciesDto | null;
  } | null;

  races$!: Observable<RaceDto[]>;

  searchAnimalRaceStore: ViewStore<RaceDto[], ViewStoreQuery>;

  baseDataTaxonomy?: AnimalTaxonomyBaseData;

  constructor(private baseDataService: BaseDataService) {
    this.races$ = this.baseDataService.getBaseData().pipe(
      take(1),
      map(baseData => {
        return baseData.domesticAnimal.races;
      }, shareReplay(1)),
    );

    // Race only needed for domestic animals, so we can use this base data taxonomy
    this.baseDataService
      .getBaseData()
      .pipe(take(1))
      .subscribe(baseData => {
        this.baseDataTaxonomy = {
          groups: baseData.domesticAnimal.groups,
          subGroups: baseData.domesticAnimal.subGroups,
          species: baseData.domesticAnimal.species,
        };
      });

    this.searchAnimalRaceStore = new ViewStore<RaceDto[], ViewStoreQuery>({ query: '' }, (params: ViewStoreQuery) => {
      let species = null;

      if (this.taxonomy) {
        species = this.taxonomy.species ? [this.taxonomy.species] : this.baseDataTaxonomy?.species ?? null;

        if (!species) {
          if (this.taxonomy.subGroup) {
            species =
              this.baseDataTaxonomy?.species?.filter(filteredSpecies => filteredSpecies.subGroupId === this.taxonomy?.subGroup?.id) ?? null;
          } else if (this.taxonomy.group) {
            species =
              this.baseDataTaxonomy?.species?.filter(filteredSpecies =>
                this.baseDataTaxonomy?.subGroups
                  ?.filter(subGroup => subGroup.groupId === this.taxonomy?.group?.id)
                  .map(subGroup => subGroup.id)
                  .includes(filteredSpecies.subGroupId),
              ) ?? null;
          }
        }
      }

      const speciesIds = species?.map(it => it.id) ?? null;

      return this.races$.pipe(
        take(1),
        map((races: RaceDto[]) => {
          return races.filter((race: RaceDto): boolean => {
            if (speciesIds != null) {
              if (!speciesIds.includes(race.speciesId)) {
                return false;
              }
            }
            return race.name.toLowerCase().includes(params.query.toLowerCase());
          });
        }),
        toLoadable(),
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const taxonomyChanges = changes['taxonomy'];
    if (taxonomyChanges) {
      this.searchAnimalRaceStore.update({});
    }
  }

  displayFn(race: RaceDto): string {
    return race.name;
  }

  setRace(race: RaceDto) {
    this.control.setValue(race.name);
  }
}
