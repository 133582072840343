import { DatePipe } from '@angular/common';
import { Component, DestroyRef, Inject, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { ButtonUploadComponent } from '@core/components/button-upload/button-upload.component';
import { EditorComponent } from '@core/components/editor/editor.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { ModalComponent } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { IsoLocalDateString } from '@core/utils/date';
import { TigonValidators } from '@core/utils/validators';
import { UploadEntity, UploadEntityType } from '@file/service/file.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { ReplaySubject, startWith, take } from 'rxjs';

import {
  RepetitionUnit,
  TaskCategoryDto,
  TaskCategoryGroup,
  TaskDto,
  TaskEntityType,
  TaskRepetition,
  TaskStatus,
  UpdateTaskDto,
  getTaskEntity,
  isAnimalTask,
} from '../../dtos/task.dto';
import { UpdateTaskResultDto } from '../../dtos/update-task-result.dto';
import { TaskService } from '../../services/task.service';
import { TaskFormComponent } from '../task-form/task-form.component';

interface ShowTaskDialogResult {}

interface ShowTaskDialogData {
  task: TaskDto;
  taskCategoryGroups: TaskCategoryGroup[];
}

interface UpdateTaskForm {
  title: FormControl<string>;
  description: FormControl<string | null>;
  report: FormControl<string | null>;
  status: FormControl<TaskStatus>;
  taskCategory: FormControl<TaskCategoryDto | null>;
  repetition: FormGroup<{
    interval: FormControl<number | null>;
    unit: FormControl<RepetitionUnit | null>;
  }>;
  terminationDate: FormControl<IsoLocalDateString | null>;
  terminationTime: FormControl<string | null>;
}

@Component({
  selector: 'app-show-task-dialog-component',
  standalone: true,
  imports: [
    TaskFormComponent,
    MatDialogModule,
    IconComponent,
    TranslateModule,
    FormElementComponent,
    ReactiveFormsModule,
    FormElementDirective,
    EditorComponent,
    ButtonUploadComponent,
    MatButtonModule,
    MatInputModule,
    RoleRestrictionDirective,
  ],
  providers: [DatePipe],
  templateUrl: './show-task-dialog-component.component.html',
  styleUrl: './show-task-dialog-component.component.scss',
})
export class ShowTaskDialogComponentComponent extends ModalComponent<ShowTaskDialogData, ShowTaskDialogResult> {
  form!: FormGroup<UpdateTaskForm>;
  task!: TaskDto;
  taskTitleBreadcrumb: string | null = null;
  destroyRef = inject(DestroyRef);

  taskHistory: TaskDto[] = [];
  lastHistoryTaskReportControl?: FormControl<string | null>;

  taskData!: ShowTaskDialogData;

  restrictedSection = RestrictedSection.Animal;

  query = signal('');

  refreshFiles: ReplaySubject<void> = new ReplaySubject(1);
  uploadEntity: UploadEntity | null = null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly TaskStatus = TaskStatus;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    dialogRef: MatDialogRef<ShowTaskDialogComponentComponent, ShowTaskDialogData>,
    @Inject(MAT_DIALOG_DATA) data: ShowTaskDialogData,
    private taskService: TaskService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private accessService: AccessService,
  ) {
    super(dialogRef);

    this.task = data.task;

    this.taskData = data;

    if (this.task.entityType === TaskEntityType.Case) {
      this.restrictedSection = RestrictedSection.Case;
    }

    if (isAnimalTask(this.taskData.task)) {
      this.uploadEntity = {
        id: this.taskData.task.animal.id,
        type: UploadEntityType.AnimalTask,
      };
    }

    this.setTitle();

    this.taskService
      .getTaskHistory(this.task.id)
      .pipe(take(1))
      .subscribe((tasks: TaskDto[]) => {
        this.taskHistory = tasks;
        if (tasks.length > 0) {
          const lastTask = tasks[tasks.length - 1];
          this.lastHistoryTaskReportControl = this.formBuilder.control<string | null>(lastTask.report ?? null);

          this.accessService.disableBasedOnRole(this.lastHistoryTaskReportControl, this.restrictedSection);

          // allow editing the report of the last task in the history
          this.lastHistoryTaskReportControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            lastTask.report = this.lastHistoryTaskReportControl!.getRawValue();
            this.taskService.update(lastTask).subscribe({
              error: () => {
                this.snackbar.showErrorMessage('PAGE.CARE_TASK.FEEDBACK.COULD_NOT_UPDATE_TASK');
              },
            });
          });
        }
      });

    this.buildForm();

    this.autoUpdateTask();
  }

  private buildForm() {
    const fb = this.formBuilder;

    const isDisabled = this.task.status === TaskStatus.Done;

    this.form = fb.group({
      title: fb.nonNullable.control<string>({ value: this.task.title, disabled: isDisabled }, [Validators.required]),
      description: fb.control<string | null>({ value: this.task.description, disabled: isDisabled }),
      report: fb.control<string | null>({ value: this.task.report, disabled: isDisabled }),
      status: fb.nonNullable.control<TaskStatus>({ value: this.task.status, disabled: isDisabled }),
      taskCategory: fb.control<TaskCategoryDto>({ value: this.task.taskCategory, disabled: isDisabled }),
      terminationDate: fb.control<string | null>({ value: this.task.terminationDate, disabled: isDisabled }),
      terminationTime: fb.control<string | null>(
        {
          value: this.task.terminationTime,
          disabled: isDisabled,
        },
        [TigonValidators.isValidTime],
      ),
      repetition: fb.nonNullable.group({
        interval: fb.control<number | null>({ value: this.task.repetition?.interval ?? null, disabled: isDisabled }),
        unit: fb.control<RepetitionUnit | null>({ value: this.task.repetition?.unit ?? null, disabled: isDisabled }),
      }),
    });

    this.accessService.disableBasedOnRole(this.form, this.restrictedSection);
  }

  private autoUpdateTask() {
    this.form.valueChanges.pipe(startWith(null), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const status = this.form.value.status;
      if (status === TaskStatus.Done) {
        this.form.disable({ emitEvent: false });
      } else {
        this.form.enable({ emitEvent: false });
      }
    });

    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const values = this.form.getRawValue();
      let taskRepetition: TaskRepetition | null = null;
      if (values.repetition.interval && values.repetition.unit) {
        taskRepetition = {
          interval: values.repetition.interval,
          unit: values.repetition.unit,
        };
      }
      if (!values.taskCategory) {
        return;
      }
      const dto: UpdateTaskDto = {
        id: this.task.id,
        ...values,
        taskCategory: values.taskCategory,
        repetition: taskRepetition,
      };
      this.taskService.update(dto).subscribe((result: UpdateTaskResultDto) => {
        this.task = result.task;
        if (result.taskClosed) {
          this.snackbar.showSuccessMessage('PAGE.CARE_TASK.FEEDBACK.TASK_CLOSED');
        }
        if (result.repeatedTask) {
          const terminationDate = this.datePipe.transform(result.repeatedTask.terminationDate, 'dd.MM.yyyy');
          const message = this.translate.instant('PAGE.CARE_TASK.FEEDBACK.REPETITION_TASK_CREATED', { terminationDate: terminationDate });
          this.snackbar.showSuccessMessage(message);
        }
      });
    });
  }

  private setTitle() {
    const title: { name?: string | null; title?: string | null } = getTaskEntity(this.task);
    this.taskTitleBreadcrumb = title.name ?? title.title ?? null;
  }
}
