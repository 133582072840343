import { AnimalId } from '@animal/dtos/animal.dto';
import { BillId, BillRangeType, BillStatus } from '@bill/dto/bill.dto';
import { CaseId } from '@case/dtos/case.dto';
import { ContactId } from '@contact/dto/contact-list-view.dto';
import { ContactDto } from '@contact/dto/contact.dto';
import { AnimalState, AnimalType, CaseEntryType, CaseState } from '@core/models/general';
import { IsoLocalDateString } from '@core/utils/date';

export enum SearchResultType {
  Animal = 'Animal',
  Case = 'Case',
  Contact = 'Contact',
  Bill = 'Bill',
}

export interface AnimalGlobalSearchResultDto {
  id: AnimalId;
  // search fields
  name: string | null;
  ringId: string | null;
  chipId: string | null;
  trdId: string | null;
  coloring: string | null;
  boxName: string | null;
  // additional fields
  state: AnimalState;
  group: string | null;
  species: string | null;
  race: string | null;
  type: SearchResultType.Animal;
  animalType: AnimalType;
}

export interface SearchCaseAnimalDto {
  id: AnimalId;
  name: string | null;
  stmzNumber: string | null;
}

export interface CaseGlobalSearchResultDto {
  id: CaseId;
  // search fields
  title: string | null;
  caseNumber: string | null;
  rescueNumber: string | null;
  foundLocation: string | null;
  // additional fields
  entryType: CaseEntryType;
  state: CaseState;
  caseAnimals: SearchCaseAnimalDto[];
  type: SearchResultType.Case;
}

export interface ContactGlobalSearchResultDto {
  id: ContactId;
  personNumber: number | null;
  // search fields
  firstName: string | null;
  lastName: string | null;
  company: string | null;
  // additional fields
  address: string | null;
  addressAddendum: string | null;
  zip: string | null;
  city: string | null;
  country: string | null;
  type: SearchResultType.Contact;
}

export interface BillGlobalSearchResultDto {
  id: BillId;
  // search fields
  billingNumber: string;
  // additional fields

  invoiceDocumentDate: IsoLocalDateString;
  billingDate: IsoLocalDateString;
  status: BillStatus;
  billRangeType: BillRangeType;
  billingContact: ContactDto;
  createdDate: IsoLocalDateString;
  totalPrice: number;

  caseId: CaseId;
  type: SearchResultType.Bill;
}

export interface SearchResult<T> {
  entities: T[];
  hasMore: boolean;
}

export interface GlobalSearchResultDto {
  animals: SearchResult<AnimalGlobalSearchResultDto>;
  cases: SearchResult<CaseGlobalSearchResultDto>;
  contacts: SearchResult<ContactGlobalSearchResultDto>;
  bills: SearchResult<BillGlobalSearchResultDto>;
}
