import { DatePipe, DecimalPipe, NgClass } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatMenuItem } from '@angular/material/menu';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatCell, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { GroupDto, SubGroupDto } from '@case/dtos/base-data.dto';
import { DateInputComponent, DatePickerType } from '@core/components/date-input/date-input.component';
import { DatePickerRangeComponent } from '@core/components/date-picker/date-picker-range.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SelectComponent } from '@core/components/select/select.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { PushDataSource } from '@core/data/tigon-datasource';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { EXCLUDE_VETERINARIAN, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import {
  ISO_LOCAL_DATE_FORMAT,
  ISO_YEAR_FORMAT,
  ISO_YEAR_MONTH_FORMAT,
  IsoLocalDateString,
  SWISS_LOCAL_DATE_FORMAT,
} from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment/moment';
import { filter, startWith, take } from 'rxjs';

import { BarChartComponent } from '../../components/bar-chart/bar-chart.component';
import { SimpleStatsDisplayCardComponent } from '../../components/simple-stats-display-card/simple-stats-display-card.component';
import { StatisticExportDialogComponent } from '../../components/statistic-export-dialog/statistic-export-dialog.component';
import { StatisticsCardComponent } from '../../components/statistics-card/statistics-card.component';
import { Pair, StatisticsDto } from '../../dto/statistics.dto';
import { StatisticsService } from '../../services/statistics.service';

type BackendStatsData<K> = Pair<K, number>[];

interface GroupSubGroupRow {
  groupOrEmpty: string;
  subGroupOrEmpty: string;
  count: number;
}

interface CountRow {
  name: string;
  count: number;
}

export enum DateFilterChoice {
  Day = 'Day',
  Month = 'Month',
  Year = 'Year',
  Custom = 'Custom',
}

type FilterForm = FormGroup<{
  filter: FormControl<DateFilterChoice | null>;
  startDate: FormControl<IsoLocalDateString | null>;
  endDate: FormControl<IsoLocalDateString | null>;
  day: FormControl<IsoLocalDateString | null>;
  month: FormControl<IsoLocalDateString | null>;
  year: FormControl<IsoLocalDateString | null>;
}>;

@Component({
  selector: 'app-statistics-page',
  standalone: true,
  imports: [
    TranslateModule,
    MatButton,
    RouterLink,
    ContextActionsDirective,
    BarChartComponent,
    StatisticsCardComponent,
    EnumDisplayPipe,
    MatCell,
    MatTableModule,
    TypesafeMatTableModule,
    SingleLineTextComponent,
    ScrollableTableComponent,
    DecimalPipe,
    SelectComponent,
    DateInputComponent,
    DatePickerRangeComponent,
    MatProgressSpinner,
    NgClass,
    DatePipe,
    SimpleStatsDisplayCardComponent,
    MatMenuItem,
    RoleRestrictionDirective,
  ],
  templateUrl: './statistics-page.component.html',
  styleUrl: './statistics-page.component.scss',
})
export class StatisticsPageComponent implements OnInit {
  statisticsDto?: StatisticsDto;

  form!: FilterForm;

  domesticGroupSubGroupDataSource: PushDataSource<GroupSubGroupRow> = new PushDataSource<GroupSubGroupRow>();
  wildGroupSubGroupDataSource: PushDataSource<GroupSubGroupRow> = new PushDataSource<GroupSubGroupRow>();
  numAnimalsBySpeciesDataSource: PushDataSource<CountRow> = new PushDataSource<CountRow>();
  averageDaysInShelterDataSource: PushDataSource<CountRow> = new PushDataSource<CountRow>();

  entryTypeDataSource: PushDataSource<Pair<string, number>> = new PushDataSource<Pair<string, number>>();
  submitReasonDataSource: PushDataSource<Pair<string, number>> = new PushDataSource<Pair<string, number>>();
  exitReasonDataSource: PushDataSource<Pair<string, number>> = new PushDataSource<Pair<string, number>>();

  dateFilterChoices: RadioChoice<DateFilterChoice>[] = createEnumChoices(DateFilterChoice, 'GENERAL.DOMAIN.DateFilterChoice.');
  isLoading = false;

  firstDayOfYear = moment().startOf('year').format(SWISS_LOCAL_DATE_FORMAT);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly DateFilterChoice = DateFilterChoice;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly DatePickerType = DatePickerType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly EXCLUDE_VETERINARIAN = EXCLUDE_VETERINARIAN;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private statistics: StatisticsService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    const today = moment();
    this.form = this.fb.group({
      filter: this.fb.control<DateFilterChoice | null>(DateFilterChoice.Year),
      startDate: this.fb.control<string | null>(null),
      endDate: this.fb.control<string | null>(null),
      day: this.fb.control<string | null>(today.format(ISO_LOCAL_DATE_FORMAT)),
      month: this.fb.control<string | null>(today.format(ISO_YEAR_MONTH_FORMAT)),
      year: this.fb.control<string | null>(today.format(ISO_YEAR_FORMAT)),
    });

    this.form.valueChanges.pipe(startWith(null), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const formValue = this.form.getRawValue();

      if (formValue.filter === DateFilterChoice.Custom) {
        if (!this.form.valid) {
          return;
        }
      }

      this.isLoading = true;

      this.statistics
        .getStatistics(formValue)
        .pipe(take(1))
        .subscribe(data => {
          this.fillDataSources(data);
          this.isLoading = false;
        });
    });
  }

  openExportStatisticsModal() {
    this.modalService
      .open(
        StatisticExportDialogComponent,
        {
          filter: this.form.getRawValue(),
        },
        { width: ModalWidth.Medium },
      )
      .afterClosed()
      .pipe(filter(notNullish))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .subscribe(() => {});
  }

  private fillDataSources(statistics: StatisticsDto) {
    this.statisticsDto = statistics;
    const domesticGroupSubGroupTable = this.createGroupSubGroupTable(
      statistics.numDomesticAnimalsByGroup,
      statistics.numDomesticAnimalsBySubGroup,
    );
    this.domesticGroupSubGroupDataSource.pushData(domesticGroupSubGroupTable);

    const wildGroupSubGroupTable = this.createGroupSubGroupTable(statistics.numWildAnimalsByGroup, statistics.numWildAnimalsBySubGroup);
    this.wildGroupSubGroupDataSource.pushData(wildGroupSubGroupTable);

    this.numAnimalsBySpeciesDataSource.pushData(
      statistics.numAnimalsBySpecies
        .sort((a, b) => a.first.name.localeCompare(b.first.name))
        .map(it => ({
          name: it.first.name,
          count: it.second,
        })),
    );

    this.averageDaysInShelterDataSource.pushData([
      { name: 'Heimtiere', count: statistics.averageDaysInShelterDomesticAnimal },
      { name: 'Wildtiere', count: statistics.averageDayInShelterWildAnimal },
    ]);

    this.submitReasonDataSource.pushData(
      statistics.numAnimalsBySubmitReason.map(it => {
        return { first: this.translate.instant(it.first.name), second: it.second };
      }),
    );
    this.entryTypeDataSource.pushData(
      statistics.numAnimalsByEntryType.map(it => {
        return { first: this.translate.instant(`GENERAL.DOMAIN.CaseEntryType.${it.first}`), second: it.second };
      }),
    );

    this.exitReasonDataSource.pushData(
      statistics.numAnimalsByExitState.map(it => {
        return { first: it.first.name, second: it.second };
      }),
    );
  }

  private createGroupSubGroupTable(groups: BackendStatsData<GroupDto>, subGroups: BackendStatsData<SubGroupDto>): GroupSubGroupRow[] {
    const rows: GroupSubGroupRow[] = [];

    const sortedGroups = groups.sort((a, b) => a.first.name.localeCompare(b.first.name));
    const sortedSubGroups = subGroups.sort((a, b) => a.first.name.localeCompare(b.first.name));

    sortedGroups.forEach(group => {
      const sgs = sortedSubGroups.filter(sg => sg.first.groupId === group.first.id);
      rows.push({ groupOrEmpty: group.first.name, subGroupOrEmpty: '', count: group.second });

      if (sgs.length !== 1 || sgs[0].first.name !== group.first.name) {
        sgs.forEach(sg => {
          rows.push({ groupOrEmpty: '', subGroupOrEmpty: sg.first.name, count: sg.second });
        });
      }
    });

    return rows;
  }
}
