@if (datasource) {
  <div class="content">
    <tgn-scrollable-table>
      <table [dataSource]="datasource" mat-table matSort #table="matTable">
        <tr *matHeaderRowDef="columns" mat-header-row></tr>
        <tr
          *matRowDef="let contactCaseDto; table: table; columns: columns"
          mat-row
          [routerLink]="appRoutes.CASE_DETAIL_GENERAL.url(contactCaseDto.id)"
          class="table-row-link"
        ></tr>

        <ng-container matColumnDef="caseNumber">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.caseNumber' | translate }}</th>
          <td *matCellDef="let contactCaseDto; table: table" mat-cell>{{ contactCaseDto.caseNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.title' | translate }}</th>
          <td *matCellDef="let contactCaseDto; table: table" mat-cell>{{ contactCaseDto.title }}</td>
        </ng-container>

        <ng-container matColumnDef="caseCreationDate">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.caseCreationDate' | translate }}</th>
          <td *matCellDef="let contactCaseDto; table: table" mat-cell>{{ contactCaseDto.caseCreationDate | date }}</td>
        </ng-container>

        <ng-container matColumnDef="completeDate">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.completeDate' | translate }}</th>
          <td *matCellDef="let contactCaseDto; table: table" mat-cell>{{ contactCaseDto.completeDate | date }}</td>
        </ng-container>

        <ng-container matColumnDef="kind">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.KIND' | translate }}</th>
          <td *matCellDef="let contactCaseDto; table: table" mat-cell>
            {{ contactCaseDto.kind.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="fromToDate">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.fromToDate' | translate }}</th>
          <td *matCellDef="let contactCaseDto; table: table" mat-cell>
            <span>{{ contactCaseDto.fromDate | date }}</span>
            @if (contactCaseDto.fromDate || contactCaseDto.toDate) {
              <span class="separator">-</span>
            }
            <span>{{ contactCaseDto.toDate | date }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="entryViaType">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Case.entryViaType' | translate }}</th>
          <td *matCellDef="let contactCaseDto; table: table" mat-cell>
            {{ 'GENERAL.DOMAIN.CaseEntryViaType.' + contactCaseDto.entryViaType | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="contactStatus">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.ContactCaseDto.contactStatus' | translate }}</th>
          <td *matCellDef="let contactCaseDto; table: table" mat-cell>
            {{ 'GENERAL.DOMAIN.ContactStatus.' + contactCaseDto.contactStatus | translate }}
          </td>
        </ng-container>
      </table>

      <tgn-paginator
        paginator
        (onPageEvent)="pageChanged($event)"
        [maxItemCount]="totalItems"
        [maxPages]="totalPages"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        class="paginator"
      ></tgn-paginator>
    </tgn-scrollable-table>
  </div>
}
