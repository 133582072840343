import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { routes_config } from '@core/constants';

export function isNotAuthenticated(): CanActivateFn {
  return async () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    const isLoggedIn = await authService.isLoggedIn();

    if (!isLoggedIn) {
      return true;
    }

    return router.navigateByUrl(routes_config.ROOT.path);
  };
}
