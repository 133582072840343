import { DatePipe } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MoveOrCopyTaskDialogComponent } from '@care/components/move-or-copy-task-dialog/move-or-copy-task-dialog.component';
import { ShowTaskDialogComponentComponent } from '@care/components/show-task-dialog-component/show-task-dialog-component.component';
import { TaskFilter } from '@care/components/task-filter/model/task-filter';
import { TaskFilterComponent } from '@care/components/task-filter/task-filter.component';
import { AnimalTaskDto, BaseTaskDto, TaskCategoryGroup, TaskDto, TaskId } from '@care/dtos/task.dto';
import { RepetitionDisplayPipe } from '@care/pipes/repetition-display.pipe';
import { TaskService } from '@care/services/task.service';
import { FilterOption, OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { TypeSafeMatCellDef, TypeSafeMatRowDef } from '@core/directives/mat-table.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { StripRichTextPipe } from '@core/pipes/strip-rich-text.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, ReplaySubject, debounceTime, of, skip, switchMap } from 'rxjs';

import { AnimalDto } from '../../dtos/animal.dto';

export enum GeneralTaskFilter {
  All = 'All',
  Today = 'Today',
  Planned = 'Planned',
  Done = 'Done',
}

interface TableConfig {
  query: string;
  pageIndex: number;
  pageSize: number;
  filter: TaskFilter;
  generalTaskFilter: GeneralTaskFilter;
}

@Component({
  selector: 'tgn-animal-tasks-table',
  standalone: true,
  imports: [
    ConfirmationDialogDirective,
    DatePipe,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    PaginatorComponent,
    RepetitionDisplayPipe,
    TranslateModule,
    TypeSafeMatCellDef,
    TypeSafeMatRowDef,
    ContextActionsDirective,
    MatInputModule,
    ReactiveFormsModule,
    TaskFilterComponent,
    FormsModule,
    OptionButtonComponent,
    EnumDisplayPipe,
    FullNamePipe,
    ScrollableTableComponent,
    SingleLineTextComponent,
    StripRichTextPipe,
    RoleRestrictionDirective,
  ],
  templateUrl: './animal-tasks-table.component.html',
  styleUrl: './animal-tasks-table.component.scss',
})
export class AnimalTasksTableComponent implements OnInit {
  // if true in addition to tasks for the given animal the tasks of the corresponding (newest) case are also included
  @Input({ required: true }) includeLatestCaseTasks!: boolean;
  @Input() refresh: Observable<void> | null = null;
  // default is both care and admin tasks (animal tasks page) but for care page only the care tasks are shown
  @Input() taskCategoryGroups: TaskCategoryGroup[] = [TaskCategoryGroup.Care, TaskCategoryGroup.Admin];
  @Input() openTask$: ReplaySubject<TaskId> | null = null;
  @Input() isReadOnly = false;

  datasource: PagedTigonDatasource<AnimalTaskDto, TableConfig>;
  query = signal('');
  filter = TaskFilter.empty();
  activeGeneralTaskFilter = GeneralTaskFilter.Today;
  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  totalPages = 1;
  totalItems = 0;

  destroyRef = inject(DestroyRef);

  columns = ['status', 'category', 'title', 'description', 'terminationDate', 'repetition', 'creationUser', 'visum', 'actions'];

  @Input({ required: true }) animal!: AnimalDto;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  generalTaskFilterOptions: FilterOption<GeneralTaskFilter>[] = Object.values(GeneralTaskFilter).map((value: GeneralTaskFilter) => {
    return {
      label: 'GENERAL.DOMAIN.GeneralTaskFilter.' + value.toString(),
      value: value,
    };
  });

  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly TaskCategoryGroup = TaskCategoryGroup;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    private taskService: TaskService,
    private snackbar: SnackbarService,
    private modalService: ModalService,
  ) {
    this.datasource = new PagedTigonDatasource<AnimalTaskDto, TableConfig>(
      {
        query: '',
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        filter: this.filter,
        generalTaskFilter: this.activeGeneralTaskFilter,
      },
      params => {
        if (!this.animal) {
          return of({
            items: [],
            pageIndex: 0,
            pageSize: 0,
            totalPages: 0,
            totalItems: 0,
          });
        }
        return this.taskService.getAnimalCaseTasksPaginated(
          this.animal,
          {
            query: params.query,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            filter: this.filter,
            stationFilter: this.activeGeneralTaskFilter,
            sort: [], // no sorting here
          },
          this.includeLatestCaseTasks,
        );
      },
    );
    toObservable(this.query)
      .pipe(skip(1), debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe(query => {
        this.datasource.update({ query });
      });
  }

  ngOnInit() {
    if (this.refresh) {
      this.refresh.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.datasource.update({});
      });
    }

    this.datasource.page$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(page => {
      this.totalPages = page.totalPages;
      this.totalItems = page.totalItems;
      this.pageSize = page.pageSize;
      this.pageIndex = page.pageIndex;
    });
    this.datasource.update({});

    if (this.openTask$) {
      this.openTask$
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          switchMap((taskId: TaskId) => {
            return this.taskService.getTask(taskId);
          }),
        )
        .subscribe(task => {
          this.openTask(task);
        });
    }
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.datasource.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }

  deleteTask(task: BaseTaskDto) {
    this.taskService.delete(task.id).subscribe(() => {
      this.datasource.update({});
      this.snackbar.showSuccessMessage('GENERAL.INFO.TASK_DELETED');
    });
  }

  openTask(task: TaskDto) {
    this.modalService
      .open(
        ShowTaskDialogComponentComponent,
        {
          task: task,
          taskCategoryGroups: this.taskCategoryGroups,
        },
        { width: ModalWidth.ExtraLarge },
      )
      .afterClosed()
      .subscribe(() => {
        this.datasource.update({});
      });
  }

  updateFilter(newFilter: TaskFilter) {
    this.filter = newFilter;
    this.datasource.update({ filter: newFilter, pageIndex: 0 });
  }

  changeFilter(newFilter: GeneralTaskFilter) {
    this.activeGeneralTaskFilter = newFilter;
    this.datasource.update({ generalTaskFilter: newFilter, pageIndex: 0 });
  }

  moveTask(task: TaskDto) {
    this.modalService
      .open(MoveOrCopyTaskDialogComponent, { task: task, mode: 'move' }, { width: ModalWidth.Large })
      .afterClosed()
      .subscribe(() => {
        this.datasource.update({});
      });
  }

  copyTask(task: TaskDto) {
    this.modalService
      .open(MoveOrCopyTaskDialogComponent, { task: task, mode: 'copy' }, { width: ModalWidth.Large })
      .afterClosed()
      .subscribe(() => {
        this.datasource.update({});
      });
  }
}
