<tgn-narrow-page-container>
  <tgn-collapsible [expanded]="false" [title]="'Beziehungstypen'" class="input-bg-white" variant="withBgColor">
    <tgn-base-data-relationkind />
  </tgn-collapsible>

  <tgn-collapsible [expanded]="false" [title]="'Abgabgegrund'" class="input-bg-white mt-large" variant="withBgColor">
    <tgn-base-data-submit-reason />
  </tgn-collapsible>

  <tgn-collapsible [expanded]="false" [title]="'Austrittsgrund'" class="input-bg-white mt-large" variant="withBgColor">
    <tgn-base-data-exit-state />
  </tgn-collapsible>
</tgn-narrow-page-container>
