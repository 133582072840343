import { Pipe, PipeTransform } from '@angular/core';

interface MaybeWithId {
  id?: string;
}

@Pipe({
  name: 'compareWithOrWithoutId',
  standalone: true,
})
export class CompareWithOrWithoutIdPipe implements PipeTransform {
  transform<T>(value: T | null, compareWith: T | null): boolean {
    if (value === null || compareWith === null) {
      return value === compareWith;
    }

    if (typeof value === 'object' && 'id' in value) {
      return (value as MaybeWithId).id === (compareWith as MaybeWithId).id;
    }

    return value === compareWith;
  }
}
