import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnInit, ViewChild, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '@core/components/header/header.component';
import { MainNavComponent } from '@core/components/sidebar/main-nav/main-nav.component';
import { SettingsNavComponent } from '@core/components/sidebar/settings-nav/settings-nav.component';
import { SidebarComponent } from '@core/components/sidebar/sidebar/sidebar.component';
import { routes_config } from '@core/constants';
import { Observable, distinctUntilChanged, filter, map, startWith } from 'rxjs';

@Component({
  selector: 'app-page-layout',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, MainNavComponent, MatSidenavModule, SettingsNavComponent, SidebarComponent],
  templateUrl: './page-layout.component.html',
  styleUrl: './page-layout.component.scss',
})
export class PageLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('settingsNav') settingsNav!: MatSidenav;
  @ViewChild('mainNav') mainNav!: MatSidenav;

  isSidebarOpen = signal(true);

  isSettingsActive = signal(false);

  isSmallViewportWidth = signal(false);

  appRoutes = routes_config;

  private readonly navigateToSettings$: Observable<boolean> = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    map(e => (e as NavigationEnd).urlAfterRedirects),
    startWith(this.router.url),
    map(url => url.startsWith(`/${this.appRoutes.SETTINGS.path}/`)),
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );

  constructor(
    private router: Router,
    private media: MediaMatcher,
  ) {}

  ngOnInit() {
    const isMobileQuery = this.media.matchMedia('(max-width: 768px)');

    this.isSmallViewportWidth.set(isMobileQuery.matches);

    this.isSidebarOpen.set(!this.isSmallViewportWidth());

    this.navigateToSettings$.subscribe(isSettings => this.isSettingsActive.set(isSettings));

    if (this.isSmallViewportWidth()) {
      this.isSidebarOpen.set(false);
    }
  }

  ngAfterViewInit() {
    this.mainNav.openedChange.subscribe(value => {
      if (!value && this.isSettingsActive()) {
        this.isSidebarOpen.set(false);
      }
    });
  }

  openSettingsNav() {
    this.isSettingsActive.set(true);
  }

  closeSettingsNav() {
    this.isSettingsActive.set(false);
  }

  toggleMobileMenu() {
    this.isSidebarOpen.set(!this.isSidebarOpen());
  }
}
