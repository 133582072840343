<div *tgnContextActions class="tgn-context-actions">
  <a
    (click)="openAddAnimalDialog()"
    [tgnContextItem]="{ object: caseDetailStore.case$ }"
    [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE | andRoleRestriction: RESTRICT_CASE_WRITE"
    class="tgn-button"
    mat-flat-button
  >
    {{ 'PAGE.CASE.DETAIL.ACTIONS.ADD_ANIMAL' | translate }}
  </a>
</div>

<div class="content">
  @if (datasource) {
    <table #table="matTable" [dataSource]="datasource" mat-table>
      <tr *matHeaderRowDef="columns" mat-header-row></tr>
      <tr
        *matRowDef="let animal; table: table; columns: columns"
        [routerLink]="appRoutes.ANIMAL_DETAIL_GENERAL.url(animal.id)"
        class="table-row-link"
        mat-row
      ></tr>

      <ng-container matColumnDef="avatarImage">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.ContactAnimalDto.avatarImage' | translate }}</th>
        <td *matCellDef="let animal; table: table" mat-cell>
          <tgn-avatar-image [avatar]="animal" [hideImageUpload]="true" [onUpload]="uploadAnimalAvatar" size="size-lg" />
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.name' | translate }}</th>
        <td *matCellDef="let animal; table: table" mat-cell>
          <div class="max-width-name-column">
            <tgn-single-line-text [text]="animal.name | withDefaultAnimalName" />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="trdId">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.trdId' | translate }}</th>
        <td *matCellDef="let animal; table: table" mat-cell>{{ animal.trdId }}</td>
      </ng-container>

      <ng-container matColumnDef="caseEntryType">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Case.caseEntryType' | translate }}</th>
        <td *matCellDef="let animal; table: table" mat-cell>{{ case?.entryType | enumDisplay: 'GENERAL.DOMAIN.CaseEntryType' }}</td>
      </ng-container>

      <ng-container matColumnDef="mainGroup">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.mainGroup' | translate }}</th>
        <td *matCellDef="let animal; table: table" mat-cell>{{ animal.type | enumDisplay: 'GENERAL.DOMAIN.AnimalType' }}</td>
      </ng-container>

      <ng-container matColumnDef="species">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.species' | translate }}</th>
        <td *matCellDef="let animal; table: table" mat-cell>{{ animal.species?.name ?? '' }}</td>
      </ng-container>

      <ng-container matColumnDef="race">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.race' | translate }}</th>
        <td *matCellDef="let animal; table: table" mat-cell>{{ animal.race ?? '' }}</td>
      </ng-container>

      <ng-container matColumnDef="animalState">
        <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Animal.state' | translate }}</th>
        <td *matCellDef="let animal; table: table" mat-cell>{{ animal.state | enumDisplay: 'GENERAL.DOMAIN.AnimalState' }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.BUTTON.MENU_ACTIONS' | translate }}</th>
        <td *matCellDef="let animal; table: table" mat-cell>
          <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ animal: animal }" [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <ng-template let-animal="animal" matMenuContent>
              <a [routerLink]="appRoutes.ANIMAL_DETAIL.url(animal.id)" mat-menu-item>
                <span>{{ 'PAGE.ANIMALS.ACTIONS.TO_ANIMAL_DETAIL' | translate }}</span>
              </a>
              @if (case) {
                <a [routerLink]="appRoutes.ANIMAL_EXITCHECK.url(case.id, animal.id)" mat-menu-item>
                  <span>{{ 'PAGE.ANIMALS.ACTIONS.OPEN_EXIT_CHECK' | translate }}</span>
                </a>
                <button (click)="editEntryCheck(animal)" mat-menu-item>
                  <span>{{ 'PAGE.ANIMALS.ACTIONS.OPEN_ENTRY_CHECK' | translate }}</span>
                </button>
                @if (animal.status | notIn: [AnimalState.Exited, AnimalState.Completed]) {
                  <button (click)="openExitAnimalDialog(animal)" mat-menu-item [tgnRoleRestriction]="EXCLUDE_VETERINARIAN">
                    <span>{{ 'PAGE.ANIMALS.ACTIONS.OPEN_EXIT_ANIMAL_DIALOG' | translate }}</span>
                  </button>
                }
                <button (click)="downloadBoxCard(animal, case)" mat-menu-item [tgnRoleRestriction]="EXCLUDE_VETERINARIAN">
                  <span>{{ 'PAGE.ANIMALS.ACTIONS.DOWNLOAD_BOX_CARD' | translate }}</span>
                </button>
                <button (click)="downloadAdmissionForm(animal, case)" mat-menu-item [tgnRoleRestriction]="EXCLUDE_VETERINARIAN">
                  <span>{{ 'PAGE.ANIMALS.ACTIONS.DOWNLOAD_ADMISSION_FORM' | translate }}</span>
                </button>
                @if (animal.type === AnimalType.DomesticAnimal) {
                  <a [routerLink]="appRoutes.CASE_DETAIL_PLACEMENT.url(case.id)" mat-menu-item [tgnRoleRestriction]="EXCLUDE_VETERINARIAN">
                    <span>{{ 'PAGE.ANIMALS.ACTIONS.START_PLACEMENT' | translate }}</span>
                  </a>
                }
              }
            </ng-template>
          </mat-menu>
        </td>
      </ng-container>
    </table>
  }
</div>
