import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

export type CollapsibleVariant = 'withBgColor' | 'withBgColorLighter';

@Component({
  selector: 'tgn-collapsible',
  standalone: true,
  imports: [MatExpansionModule, NgClass],
  templateUrl: './collapsible.component.html',
  styleUrl: './collapsible.component.scss',
})
export class CollapsibleComponent {
  @Input({ required: true }) title!: string;
  @Input() expanded = true;
  @Input() variant: CollapsibleVariant | null = null;
}
