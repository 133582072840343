import { DatePipe } from '@angular/common';
import { Component, model, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { WithDefaultAnimalNamePipe } from '@animal/pipes/with-default-animal-name.pipe';
import { TaskFilterComponent } from '@care/components/task-filter/task-filter.component';
import { FilterOption, OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SelectComponent } from '@core/components/select/select.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { WildAnimalIconComponent } from '@core/components/wild-animal-icon/wild-animal-icon.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource, SortDirection, matSortToSortDirection } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AnimalType, CaseState } from '@core/models/general';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { SnackbarService } from '@core/services/snackbar.service';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, skip } from 'rxjs';

import { CaseFilterComponent } from '../../components/case-filter/case-filter.component';
import { CaseListViewDto } from '../../dtos/case.dto';
import { CaseFilter, GeneralCaseFilterChoice } from '../../models/case-filter';
import { CaseService } from '../../services/case.service';

interface TableConfig {
  query: string;
  sort: { entryType: SortDirection | undefined };
  pageIndex: number;
  pageSize: number;
  filter: CaseFilter;
  generalCaseStateFilter: GeneralCaseFilterChoice;
}

@Component({
  selector: 'app-cases-page',
  standalone: true,
  imports: [
    ConfirmationDialogDirective,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    PaginatorComponent,
    TranslateModule,
    TypesafeMatTableModule,
    RouterLink,
    ContextActionsDirective,
    SelectComponent,
    OptionButtonComponent,
    DatePipe,
    WildAnimalIconComponent,
    TaskFilterComponent,
    CaseFilterComponent,
    ScrollableTableComponent,
    WithDefaultAnimalNamePipe,
    FullNamePipe,
    SingleLineTextComponent,
    RoleRestrictionDirective,
  ],
  templateUrl: './cases-page.component.html',
  styleUrl: './cases-page.component.scss',
})
export class CasesPageComponent {
  datasource: PagedTigonDatasource<CaseListViewDto, TableConfig>;

  columns = ['caseNumber', 'title', 'animalNames', 'box', 'entryType', 'owner', 'caseCreationDate', 'completeDate', 'state', 'actions'];

  filter: CaseFilter = CaseFilter.empty();
  query = model('');

  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;

  totalPages = 1;
  totalItems = 0;

  generalStateFilter = signal<GeneralCaseFilterChoice>(GeneralCaseFilterChoice.AllRunning);

  caseButtonFilterChoices: FilterOption<GeneralCaseFilterChoice>[] = createEnumChoices<GeneralCaseFilterChoice>(
    GeneralCaseFilterChoice,
    'GENERAL.DOMAIN.GeneralCaseFilterChoice.',
  ).map((choice: RadioChoice<GeneralCaseFilterChoice>) => {
    const filterOption: FilterOption<GeneralCaseFilterChoice> = {
      value: choice.object,
      label: choice.label,
    };
    return filterOption;
  });

  // does not include filters already in the button/tab filter component
  caseStateChoices = createEnumChoices(CaseState, 'GENERAL.DOMAIN.CaseState.');

  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    private caseService: CaseService,
    private snackbar: SnackbarService,
  ) {
    this.datasource = new PagedTigonDatasource<CaseListViewDto, TableConfig>(
      {
        query: '',
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        sort: { entryType: undefined },
        filter: this.filter,
        generalCaseStateFilter: this.generalStateFilter(),
      },
      params => {
        const sort = params.sort.entryType ? [{ column: 'entryType', direction: params.sort.entryType }] : [];

        return this.caseService.getCasesPaginated({
          query: params.query,
          pageIndex: params.pageIndex,
          pageSize: params.pageSize,
          sort: sort,
          filter: this.filter,
          generalCaseStateFilter: params.generalCaseStateFilter,
        });
      },
    );

    toObservable(this.query)
      .pipe(skip(1), debounceTime(300), takeUntilDestroyed())
      .subscribe(query => {
        this.datasource.update({ query });
      });

    this.datasource.page$.pipe(takeUntilDestroyed()).subscribe(page => {
      this.totalPages = page.totalPages;
      this.totalItems = page.totalItems;
      this.pageSize = page.pageSize;
      this.pageIndex = page.pageIndex;
    });
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.datasource.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }

  sortChanged(event: Sort) {
    if (event.active == 'entryType') {
      this.datasource.update({ sort: { entryType: matSortToSortDirection(event.direction) } });
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
  archiveCase(caseListDto: CaseListViewDto) {
    this.caseService.archiveCase(caseListDto.id).subscribe(() => {
      this.snackbar.showSuccessMessage('Fall wurde archiviert');
      this.datasource.update({ pageIndex: 0 });
    });
  }

  updateCaseFilter(newFilter: CaseFilter) {
    this.filter = newFilter;
    this.datasource.update({ filter: newFilter, pageIndex: 0 });
  }

  changeGeneralStateFilter(newState: GeneralCaseFilterChoice) {
    this.generalStateFilter.set(newState);
    this.datasource.update({ generalCaseStateFilter: newState, pageIndex: 0 });
  }
}
