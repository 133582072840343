import { DatePipe } from '@angular/common';
import { Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { GeneralTaskFilter } from '@animal/components/animal-tasks-table/animal-tasks-table.component';
import { CreateTaskDialogComponent } from '@care/components/create-task-dialog/create-task-dialog.component';
import { MoveOrCopyTaskDialogComponent } from '@care/components/move-or-copy-task-dialog/move-or-copy-task-dialog.component';
import { ShowTaskDialogComponentComponent } from '@care/components/show-task-dialog-component/show-task-dialog-component.component';
import { TaskFilter } from '@care/components/task-filter/model/task-filter';
import { TaskFilterComponent } from '@care/components/task-filter/task-filter.component';
import { BaseTaskDto, CaseTaskDto, TaskCategoryGroup, TaskDto, TaskEntityType, TaskId } from '@care/dtos/task.dto';
import { RepetitionDisplayPipe } from '@care/pipes/repetition-display.pipe';
import { TaskService } from '@care/services/task.service';
import { FilterOption, OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { DEFAULT_PAGE_SIZE } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AndRoleRestrictionPipe, RESTRICT_CASE_WRITE } from '@core/models/role';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { StripRichTextPipe } from '@core/pipes/strip-rich-text.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, debounceTime, filter, map, skip, switchMap, take } from 'rxjs';

import { CaseDetailDto } from '../../dtos/case-detail.dto';
import { CaseDetailStore } from '../../pages/case-detail/case-layout/case-layout.component';

export const OPEN_TASK_PARAM = 'openTaskId';

interface TableConfig {
  query: string;
  pageIndex: number;
  pageSize: number;
  filter: TaskFilter;
  generalTaskFilter: GeneralTaskFilter;
}

@Component({
  selector: 'app-case-tasks',
  standalone: true,
  imports: [
    ConfirmationDialogDirective,
    DatePipe,
    EnumDisplayPipe,
    MatButtonModule,
    TranslateModule,
    TypesafeMatTableModule,
    OptionButtonComponent,
    ContextActionsDirective,
    FormsModule,
    TaskFilterComponent,
    PaginatorComponent,
    MatMenuModule,
    MatIconModule,
    RepetitionDisplayPipe,
    MatTableModule,
    MatInputModule,
    FullNamePipe,
    ScrollableTableComponent,
    SingleLineTextComponent,
    StripRichTextPipe,
    RoleRestrictionDirective,
    AndRoleRestrictionPipe,
  ],
  templateUrl: './case-tasks.component.html',
  styleUrl: './case-tasks.component.scss',
})
export class CaseTasksComponent {
  case$: Observable<CaseDetailDto>;
  case: CaseDetailDto | null = null;

  datasource?: PagedTigonDatasource<CaseTaskDto, TableConfig>;
  query = signal('');
  filter = TaskFilter.empty();
  activeGeneralTaskFilter = GeneralTaskFilter.Today;
  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  totalPages = 1;
  totalItems = 0;

  columns = ['status', 'category', 'title', 'description', 'terminationDate', 'repetition', 'visum', 'actions'];
  generalTaskFilterOptions: FilterOption<GeneralTaskFilter>[] = Object.values(GeneralTaskFilter).map((value: GeneralTaskFilter) => {
    return {
      label: 'GENERAL.DOMAIN.GeneralTaskFilter.' + value.toString(),
      value: value,
    };
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly TaskCategoryGroup = TaskCategoryGroup;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RESTRICT_CASE_WRITE = RESTRICT_CASE_WRITE;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private caseDetailStore: CaseDetailStore,
    private taskService: TaskService,
    private snackbar: SnackbarService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.case$ = this.caseDetailStore.case$;

    this.openRoutedTask();

    this.case$.pipe(take(1)).subscribe((caseDto: CaseDetailDto) => {
      this.datasource = new PagedTigonDatasource<CaseTaskDto, TableConfig>(
        {
          query: '',
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
          filter: this.filter,
          generalTaskFilter: this.activeGeneralTaskFilter,
        },
        params => {
          return this.taskService.getCaseTasksPaginated(caseDto, {
            query: params.query,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            filter: this.filter,
            stationFilter: this.activeGeneralTaskFilter,
            sort: [], // no sorting here
          });
        },
      );
    });

    toObservable(this.query)
      .pipe(skip(1), debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe(query => {
        this.datasource?.update({ query });
      });
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.datasource?.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }

  createNewTask() {
    this.case$
      .pipe(
        take(1),
        switchMap((caseDto: CaseDetailDto) => {
          return this.modalService
            .open(
              CreateTaskDialogComponent,
              {
                taskCategoryGroups: [TaskCategoryGroup.Admin],
                entity: caseDto,
                entityType: TaskEntityType.Case,
              },
              { width: ModalWidth.ExtraLarge },
            )
            .afterClosed();
        }),
      )
      .pipe(filter(notNullish))
      .subscribe(() => {
        this.datasource?.update({});
      });
  }

  deleteTask(task: BaseTaskDto) {
    this.taskService.delete(task.id).subscribe(() => {
      this.datasource?.update({});
      this.snackbar.showSuccessMessage('GENERAL.INFO.TASK_DELETED');
    });
  }

  moveTask(task: TaskDto) {
    this.modalService
      .open(MoveOrCopyTaskDialogComponent, { task: task, mode: 'move' }, { width: ModalWidth.Large })
      .afterClosed()
      .subscribe(() => {
        this.datasource?.update({});
      });
  }

  copyTask(task: TaskDto) {
    this.modalService
      .open(MoveOrCopyTaskDialogComponent, { task: task, mode: 'copy' }, { width: ModalWidth.Large })
      .afterClosed()
      .subscribe(() => {
        this.datasource?.update({});
      });
  }

  changeFilter(newFilter: GeneralTaskFilter) {
    this.activeGeneralTaskFilter = newFilter;
    this.datasource?.update({ generalTaskFilter: newFilter, pageIndex: 0 });
  }

  openTask(task: TaskDto) {
    this.modalService
      .open(
        ShowTaskDialogComponentComponent,
        {
          task: task,
          taskCategoryGroups: [TaskCategoryGroup.Admin],
        },
        { width: ModalWidth.ExtraLarge },
      )
      .afterClosed()
      .subscribe(() => {
        this.datasource?.update({});
      });
  }

  updateFilter(newFilter: TaskFilter) {
    this.filter = newFilter;
    this.datasource?.update({ filter: newFilter, pageIndex: 0 });
  }

  private openRoutedTask() {
    this.activatedRoute.queryParams
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(params => params[OPEN_TASK_PARAM]),
        filter(notNullish),
        switchMap(taskId => {
          return this.taskService.getTask(taskId as TaskId);
        }),
        filter(notNullish),
      )
      .subscribe((task: TaskDto) => {
        this.openTask(task);
      });
  }
}
