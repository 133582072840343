import { AnimalId } from '@animal/dtos/animal.dto';
import { TaskId } from '@care/dtos/task.dto';
import { CaseId } from '@case/dtos/case.dto';
import { BrandedId } from '@core/utils/brand';
import { IsoLocalDateString } from '@core/utils/date';

export type NotificationId = BrandedId<'NotificationId'>;

export interface NotificationDto {
  id: NotificationId;
  title: string;
  message: string | null;
  date: IsoLocalDateString;
  read: boolean;
  entityId: CaseId | AnimalId | TaskId;
  entityType: NotificationEntityType;
}

export enum NotificationEntityType {
  Case = 'Case',
  Animal = 'Animal',
  Task = 'Task',
}
