import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { Component, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { PagedTigonDatasource, SortDirection, matSortToSortDirection } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { UserRole } from '@core/models/general';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { ListDisplay } from '@core/pipes/list-display.pipe';
import { ListIncludesPipe } from '@core/pipes/list-includes.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { EditUserDialogComponent } from '@user/components/edit-user-dialog/edit-user-dialog.component';
import { FilterTeamRolesPipe } from '@user/pipes/filter-team-roles.pipe';
import { debounceTime, lastValueFrom, skip } from 'rxjs';

import { InviteUserDialogComponent } from '../../components/invite-user-dialog/invite-user-dialog.component';
import { UserDto, UserStatus } from '../../dto/user.dto';
import { UserService } from '../../service/user.service';

interface TableConfig {
  query: string;
  roleSort?: SortDirection;
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-admin-users-page',
  standalone: true,
  imports: [
    MatButtonModule,
    TranslateModule,
    ContextActionsDirective,
    AsyncPipe,
    NgForOf,
    MatTableModule,
    MatIconModule,
    MatMenuModule,
    MatSortModule,
    FormsModule,
    MatInputModule,
    TypesafeMatTableModule,
    PaginatorComponent,
    FullNamePipe,
    ScrollableTableComponent,
    ListDisplay,
    RoleRestrictionDirective,
    EnumDisplayPipe,
    IconComponent,
    NgClass,
    ConfirmationDialogDirective,
    AvatarImageComponent,
    MatTooltip,
    FilterTeamRolesPipe,
    ListIncludesPipe,
  ],
  templateUrl: './admin-users-page.component.html',
  styleUrl: './admin-users-page.component.scss',
})
export class AdminUsersPageComponent {
  datasource: PagedTigonDatasource<UserDto, TableConfig>;

  query = signal('');
  pageIndex = 0;
  pageSize = 50;

  totalPages = 1;
  totalItems = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserStatus = UserStatus;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;

  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private snackbar: SnackbarService,
  ) {
    this.datasource = new PagedTigonDatasource<UserDto, TableConfig>(
      {
        query: '',
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      },
      params => {
        const sort = params.roleSort ? [{ column: 'role', direction: params.roleSort }] : [];

        return this.userService.getUsersPaginated({
          query: params.query,
          pageIndex: params.pageIndex,
          pageSize: params.pageSize,
          sort: sort,
        });
      },
    );

    toObservable(this.query)
      .pipe(skip(1), debounceTime(300), takeUntilDestroyed())
      .subscribe(query => {
        this.datasource.update({ query });
      });

    this.datasource.page$.pipe(takeUntilDestroyed()).subscribe(page => {
      this.totalPages = page.totalPages;
      this.totalItems = page.totalItems;
      this.pageSize = page.pageSize;
      this.pageIndex = page.pageIndex;
    });
  }

  async openInviteUserDialog() {
    const result = await lastValueFrom(this.modalService.open(InviteUserDialogComponent, {}, { width: ModalWidth.Medium }).afterClosed());

    if (!result) {
      return;
    }

    const newUser = await lastValueFrom(this.userService.inviteUsers([result]));

    if (result.sendInvite) {
      for (const user of newUser) {
        this.passwordReset(user);
      }
      this.snackbar.showSuccessMessage('PAGE.ADMIN_USERS.FEEDBACK.SENT_INVITE_EMAIL_SUCCESS');
    } else {
      this.snackbar.showSuccessMessage('PAGE.ADMIN_USERS.FEEDBACK.INVITED_NEW_USER');
    }

    this.reload();
  }

  reload() {
    this.datasource.update();
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.datasource.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }

  sortChanged(event: Sort) {
    this.datasource.update({ roleSort: matSortToSortDirection(event.direction) });
  }

  passwordReset(user: UserDto) {
    this.userService.sendResetPasswordLink(user).subscribe({
      next: () => {
        this.snackbar.showSuccessMessage('PAGE.ADMIN_USERS.FEEDBACK.SENT_PASSWORD_RESET_EMAIL_SUCCESS');
      },
      error: (error: unknown) => {
        console.error('could not send password reset email: ', error);
        this.snackbar.showErrorMessage('PAGE.ADMIN_USERS.FEEDBACK.SENT_EMAIL_ERROR');
      },
    });
  }

  openEditUserModal(user: UserDto) {
    this.modalService
      .open(EditUserDialogComponent, { user }, { width: ModalWidth.Medium })
      .afterClosed()
      .subscribe(() => {
        this.reload();
      });
  }

  toggleActive(user: UserDto) {
    this.userService.toggleActive(user.id).subscribe(() => {
      const message = user.status === UserStatus.Active ? 'Benutzer wurde deaktiviert' : 'Benutzer wurde aktiviert';
      this.snackbar.showSuccessMessage(message);
      this.reload();
    });
  }
}
