import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { routes_config } from '@core/constants';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';

type NewPasswordForm = FormGroup<{
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
}>;

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (!value) {
      return { password: 'GENERAL.ERRORS.MIN_LENGTH' };
    }

    const hasMinLength = value.length >= 8;

    if (!hasMinLength) {
      return { password: 'GENERAL.ERRORS.PASSWORD_MIN_LENGTH_EIGHT_CHARACTERS' };
    }
    const hasUpperCase = /[A-Z]/.test(value);
    if (!hasUpperCase) {
      return { password: 'GENERAL.ERRORS.PASSWORD_UPPER_CASE_REQUIRED' };
    }
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    if (!hasSpecialChar) {
      return { password: 'GENERAL.ERRORS.PASSWORD_SPECIAL_CHARACTER_REQUIRED' };
    }

    const isPasswordInvalid = hasMinLength && hasUpperCase && hasSpecialChar;

    return !isPasswordInvalid ? { password: 'GENERAL.ERRORS.PASSWORD_INVALID' } : null;
  };
}

export function passwordsMatchValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const fb = control as NewPasswordForm;

    const password = fb.controls.password?.value;
    const confirmPassword = fb.controls.confirmPassword?.value;

    if (!password || !confirmPassword) {
      return null; // Do not validate if either field is empty
    }

    return password === confirmPassword ? null : { password: 'GENERAL.ERRORS.PASSWORD_NOT_MATCHING' };
  };
}

@Component({
  selector: 'tgn-set-password-password-page',
  standalone: true,
  imports: [
    FormElementComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatFormField,
    FormElementDirective,
    MatButton,
    MatInput,
    MatError,
    AsyncPipe,
    FieldErrorTranslationPipe,
    IconComponent,
  ],
  templateUrl: './set-password-page.component.html',
  styleUrl: './set-password-page.component.scss',
})
export class SetPasswordPageComponent implements OnInit {
  oobCode: string | null = null;

  showPassword = false;
  showConfirmPassword = false;
  form?: NewPasswordForm;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private router: Router,
  ) {
    this.form = fb.group({
      password: fb.nonNullable.control<string>('', [passwordValidator(), Validators.required]),
      confirmPassword: fb.nonNullable.control<string>('', [Validators.required]),
    });

    this.form.setValidators(passwordsMatchValidator());
  }

  async ngOnInit() {
    try {
      const isLoggedIn = await this.authService.isLoggedIn();

      if (isLoggedIn) {
        await this.authService.logout();
      }
    } catch (e) {
      console.error('Error while checking if user is logged in', e);
    }

    this.route.queryParams.subscribe((it: Params) => {
      this.oobCode = it['oobCode'];
      console.log(this.oobCode);
    });
  }

  async changePassword() {
    const formValues = this.form!.getRawValue();
    if (!this.oobCode) {
      console.error('oobCode is not set');
      return;
    }
    console.log('try password reset');
    try {
      await this.authService.confirmPassword(formValues.password, this.oobCode);
    } catch (e) {
      console.error('Password reset failed', e);
      this.snackbar.showErrorMessage('Fehler beim Setzen des Passworts');
      return;
    }
    this.snackbar.showSuccessMessage('Passwort wurde erfolgreich gesetzt');
    this.router.navigate(routes_config.ROOT.url());
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  toggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
