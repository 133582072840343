import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormField, MatInput } from '@angular/material/input';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ViewStore, ViewStoreQuery, toLoadable } from '@core/components/autocomplete/model/loadable';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { salutationChoices } from '@core/models/form/form-helper';
import { DEFAULT_DEBOUNCE_TIME } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { createEnumChoices } from '@core/utils/helplers';
import { fullName } from '@core/utils/person';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, debounceTime, firstValueFrom } from 'rxjs';

import { ContactDialogForm } from '../../../components/contact-dialog/contact-dialog.component';
import { ContactDto, ContactStatus, Salutation } from '../../../dto/contact.dto';
import { UpdateContactDetailDto } from '../../../dto/update-contact-detail.dto';
import { ContactService } from '../../../service/contact-service';
import { ContactDetailStore } from '../contact-detail-layout/contact-detail-layout.component';

type ContactDetailForm = ContactDialogForm & {
  isDead: FormControl<boolean>;
  legalRepresentationId: FormControl<string | null>;
};

@Component({
  selector: 'app-contact-general',
  standalone: true,
  imports: [
    FormElementComponent,
    ReactiveFormsModule,
    TranslateModule,
    FormElementDirective,
    NgIf,
    FormsModule,
    MatCheckboxModule,
    RadioGroupComponent,
    AutocompleteComponent,
    AsyncPipe,
    NarrowPageContainerComponent,
    TextFieldModule,
    MatInput,
    MatFormField,
    SelectComponent,
    MatButton,
    MatMenu,
    ContextActionsDirective,
    RoleRestrictionDirective,
    ConfirmationDialogDirective,
  ],
  templateUrl: './contact-general.component.html',
  styleUrl: './contact-general.component.scss',
})
export class ContactGeneralComponent {
  form?: FormGroup<ContactDetailForm>;
  legalRepContact: ContactDto | null = null;

  destroyRef = inject(DestroyRef);
  contact$: Observable<ContactDto>;

  contactViewStore: ViewStore<ContactDto[], ViewStoreQuery> = new ViewStore<ContactDto[], ViewStoreQuery>(
    { query: '' },
    (query: ViewStoreQuery) => {
      return this.contactService.searchContacts(query.query).pipe(toLoadable());
    },
  );

  protected readonly salutationChoices = salutationChoices;
  protected readonly statusChoices = createEnumChoices(ContactStatus, 'GENERAL.DOMAIN.ContactStatus.');

  constructor(
    private store: ContactDetailStore,
    private fb: FormBuilder,
    private contactService: ContactService,
    private snackbar: SnackbarService,
    private accessService: AccessService,
    private router: Router,
  ) {
    this.contact$ = store.contact$;

    this.store.contact$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (contact: ContactDto) => {
      this.form = this.buildForm();
      this.form.patchValue(contact, { emitEvent: false });

      if (contact.archived) {
        this.form.disable();
      }

      if (contact.legalRepresentationId) {
        this.legalRepContact = await firstValueFrom(this.contactService.get(contact.legalRepresentationId));
      }

      this.form.valueChanges.pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        const dto: UpdateContactDetailDto = this.form!.getRawValue();

        this.contactService.update(contact.id, dto).subscribe({
          error: (err: unknown) => {
            this.snackbar.showErrorMessage('PAGE.CONTACT.ERROR.COULD_NOT_UPDATE_ENTITY');
            console.error('Could not update contact, err: ', err);
          },
        });
      });

      this.accessService.disableBasedOnRole(this.form, RestrictedSection.Case);
    });
  }

  buildForm(): FormGroup<ContactDetailForm> {
    const fb = this.fb;

    return fb.group<ContactDetailForm>({
      salutation: fb.control<Salutation | null>(null),
      firstName: fb.control<string | null>(null),
      lastName: fb.control<string | null>(null),
      company: fb.control<string | null>(null),
      companyAddendum: fb.control<string | null>(null),
      address: fb.control<string | null>(null),
      addressAddendum: fb.control<string | null>(null),
      poBox: fb.control<string | null>(null),
      zip: fb.control<string | null>(null),
      city: fb.control<string | null>(null),
      country: fb.control<string | null>(null),
      phone: fb.control<string | null>(null),
      phoneWork: fb.control<string | null>(null),
      mobile: fb.control<string | null>(null),
      email: fb.control<string | null>(null),
      possibleDonor: fb.nonNullable.control<boolean>(false),
      noMailings: fb.nonNullable.control<boolean>(false),
      isDead: fb.nonNullable.control<boolean>(false),
      status: fb.nonNullable.control(ContactStatus.New),
      legalRepresentationId: fb.control<string | null>(null),
      note: fb.control<string | null>(null),
    });
  }

  contactDisplayFn(contact: ContactDto | null): string {
    if (!contact) {
      return '';
    }

    return fullName(contact) ?? '-';
  }

  getContactValueFn(dto: ContactDto) {
    dto.id;
  }

  setRelatedContact(contact: ContactDto | null) {
    if (this.form && contact) {
      this.form.controls.legalRepresentationId.setValue(contact.id);
      this.legalRepContact = contact;
    }
  }

  removeSelectedContact() {
    this.form?.controls?.legalRepresentationId?.setValue(null);
    this.legalRepContact = null;
  }

  unarchive(contact: ContactDto) {
    this.contactService.unarchiveContact(contact.id).subscribe(() => {
      this.snackbar.showSuccessMessage('Archivierung wurde aufgehoben');
      this.store.loadContact(contact.id);
    });
  }
}
