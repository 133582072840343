import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateTaskCategoryDto, TaskCategoryDto, TaskCategoryId, UpdateTaskCategoryDto } from '@care/dtos/task.dto';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskCategoryService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Inactive exitStates are included here
   */
  getAll(): Observable<TaskCategoryDto[]> {
    return this.http.get<TaskCategoryDto[]>(`${this.apiUrl}/master/taskCategories`);
  }

  getAllActive(): Observable<TaskCategoryDto[]> {
    return this.http.get<TaskCategoryDto[]>(`${this.apiUrl}/master/taskCategories/active`);
  }

  getById(id: TaskCategoryId): Observable<TaskCategoryDto> {
    return this.http.get<TaskCategoryDto>(`${this.apiUrl}/master/taskCategories/${id}`);
  }

  create(dto: CreateTaskCategoryDto): Observable<TaskCategoryDto> {
    return this.http.post<TaskCategoryDto>(`${this.apiUrl}/master/taskCategories`, dto);
  }

  update(id: TaskCategoryId, dto: UpdateTaskCategoryDto): Observable<TaskCategoryDto> {
    return this.http.put<TaskCategoryDto>(`${this.apiUrl}/master/taskCategories/${id}`, dto);
  }
}
