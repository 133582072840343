import { DatePipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { WithDefaultAnimalNamePipe } from '@animal/pipes/with-default-animal-name.pipe';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { TypeSafeMatCellDef, TypeSafeMatRowDef } from '@core/directives/mat-table.directive';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FileBaseDto } from '@file/dto/file.dto';
import { FileService, UploadResult } from '@file/service/file.service';
import { TranslateModule } from '@ngx-translate/core';

import { ContactAnimalDto } from '../../../dto/contact-animal.dto';
import { ContactDto } from '../../../dto/contact.dto';
import { ContactService } from '../../../service/contact-service';
import { ContactDetailStore } from '../contact-detail-layout/contact-detail-layout.component';

interface TableConfig {
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-contact-animals',
  standalone: true,
  imports: [
    PaginatorComponent,
    ConfirmationDialogDirective,
    DatePipe,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTableModule,
    TranslateModule,
    TypeSafeMatCellDef,
    RouterLink,
    TypeSafeMatRowDef,
    AvatarImageComponent,
    WithDefaultAnimalNamePipe,
    ScrollableTableComponent,
    EnumDisplayPipe,
    SingleLineTextComponent,
  ],
  templateUrl: './contact-animals.component.html',
  styleUrl: './contact-animals.component.scss',
})
export class ContactAnimalsComponent implements OnInit {
  datasource?: PagedTigonDatasource<ContactAnimalDto, TableConfig>;

  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  totalPages = 1;
  totalItems = 0;
  readonly columns = ['avatarImage', 'name', 'trdId', 'species', 'race', 'state'];
  protected readonly appRoutes = routes_config;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private contactDetailStore: ContactDetailStore,
    private contactService: ContactService,
    private fileService: FileService,
  ) {}

  ngOnInit() {
    const contact$ = this.contactDetailStore.contact$;

    contact$.subscribe((contact: ContactDto) => {
      this.datasource = new PagedTigonDatasource<ContactAnimalDto, TableConfig>(
        {
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
        },
        params => {
          return this.contactService.getContactAnimalsPaginated(contact, {
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
          });
        },
        this.destroyRef,
      );
    });
  }

  uploadAnimalAvatar = async (file: File, animal: ContactAnimalDto): Promise<UploadResult<FileBaseDto>> => {
    return this.fileService.uploadAnimalProfileImage(animal.id, file);
  };

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.totalPages = pageEvent.maxPages;

    this.datasource?.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }
}
