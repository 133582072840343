import { CdkStepperModule } from '@angular/cdk/stepper';
import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { WildAnimalStatisticsFormComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics-form/wild-animal-statistics-form.component';
import { WildAnimalStatisticsComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics.component';
import { AnimalEntryFormComponent } from '@case/components/animal-entry-form/animal-entry-form.component';
import { HealthFormComponent } from '@case/components/health-form/health-form.component';
import { ParticularitiesFormComponent } from '@case/components/particularities-form/particularities-form.component';
import { BaseDataDto, BoxDto } from '@case/dtos/base-data.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseId } from '@case/dtos/case.dto';
import { WildAnimalStatisticsDto } from '@case/dtos/create-case.dto';
import { ExitCheckDto, UpdateExitCheckDto } from '@case/dtos/exit-check.dto';
import { WildAnimalStatisticsControls } from '@case/models/new-case-form';
import { BaseDataService } from '@case/services/base-data-service';
import { CaseAnimalService } from '@case/services/case-animal.service';
import { CaseService } from '@case/services/case.service';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { StepperComponent } from '@core/components/stepper/stepper.component';
import { TextareaComponent } from '@core/components/textarea/textarea.component';
import { routes_config } from '@core/constants';
import { enumArrayValidator, nullableEnumValidator } from '@core/models/form/enum.validator';
import {
  AffectedGeneralRegion,
  AffectedLimb,
  AnimalSex,
  AnimalType,
  ExtendedAffectedRegion,
  GeneralFieldChoice,
  UnitsWeight,
} from '@core/models/general';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { TigonValidators } from '@core/utils/validators';
import { TranslateModule } from '@ngx-translate/core';
import { UserDto } from '@user/dto/user.dto';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { CurrentUserService } from '@user/service/current-user.service';
import { Observable, combineLatest, map, switchMap, take, tap } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

import { AnimalDto, AnimalId } from '../../dtos/animal.dto';
import { getAnimalName } from '../../model/animal';
import { AnimalService } from '../../services/animal.service';
import { ExitCheckHealthComponent } from './exit-check-health/exit-check-health.component';

interface Params {
  caseId: CaseId;
  animalId: AnimalId;
}

export interface ExitCheckForm {
  name: FormControl<string | null>;
  trdId: FormControl<string | null>;

  box: FormControl<BoxDto | null>;
  chipChecked: FormControl<boolean | null>;
  chipId: FormControl<string | null>;
  ringId: FormControl<string | null>;
  generalCondition: FormControl<GeneralFieldChoice>;
  generalConditionNote: FormControl<string | null>;

  weightUnit: FormControl<UnitsWeight | null>;
  weight: FormControl<number | null>;

  sex: FormControl<AnimalSex | null>;
  eyes: FormControl<GeneralFieldChoice>;
  eyesAffected: FormControl<AffectedGeneralRegion | null>;
  eyesNote: FormControl<string | null>;
  ears: FormControl<GeneralFieldChoice>;
  earsAffected: FormControl<AffectedGeneralRegion | null>;
  earsNote: FormControl<string | null>;
  nose: FormControl<GeneralFieldChoice>;
  noseAffected: FormControl<AffectedGeneralRegion | null>;
  noseNote: FormControl<string | null>;
  mouthOrTeeth: FormControl<GeneralFieldChoice>;
  mouthOrTeethAffected: FormControl<ExtendedAffectedRegion[]>;
  mouthOrTeethNote: FormControl<string | null>;
  skin: FormControl<GeneralFieldChoice>;
  skinNote: FormControl<string | null>;
  coatOrFeathers: FormControl<GeneralFieldChoice>;
  coatOrFeathersNote: FormControl<string | null>;
  clawsOrFeet: FormControl<GeneralFieldChoice>;
  affectedLimbs: FormControl<AffectedLimb[]>;
  clawsOrFeetNote: FormControl<string | null>;
  anus: FormControl<GeneralFieldChoice>;
  anusNote: FormControl<string | null>;
  generalNote: FormControl<string | null>;
}

@Component({
  selector: 'app-exit-check-page',
  standalone: true,
  imports: [
    AnimalEntryFormComponent,
    AsyncPipe,
    CdkStepperModule,
    HealthFormComponent,
    NarrowPageContainerComponent,
    ParticularitiesFormComponent,
    StepperComponent,
    TranslateModule,
    FormElementComponent,
    FormElementDirective,
    ReactiveFormsModule,
    TextFieldModule,
    ExitCheckHealthComponent,
    RadioGroupComponent,
    SelectComponent,
    ToRadioChoicePipe,
    MatInputModule,
    TextareaComponent,
    FullNamePipe,
    WildAnimalStatisticsComponent,
    WildAnimalStatisticsFormComponent,
    AddIfMissingPipe,
  ],
  templateUrl: './exit-check-page.component.html',
  styleUrl: './exit-check-page.component.scss',
})
export class ExitCheckPageComponent implements OnInit {
  params!: Params;

  form?: FormGroup<ExitCheckForm>;
  wildAnimalStatistics?: FormGroup<WildAnimalStatisticsControls>;

  animal?: AnimalDto;
  doneBy: UserDto | null = null;

  yesNoChoice: RadioChoice<boolean>[] = [
    {
      label: 'GENERAL.FORM.LABEL.YES',
      object: true,
    },
    {
      label: 'GENERAL.FORM.LABEL.NO',
      object: false,
    },
  ];

  boxes$: Observable<BoxDto[]>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private animalService: AnimalService,
    private baseDataService: BaseDataService,
    private breadcrumbService: BreadcrumbService,
    private currentUserService: CurrentUserService,
    private caseAnimalService: CaseAnimalService,
    private caseService: CaseService,
    private accessService: AccessService,
  ) {
    this.boxes$ = this.baseDataService.getBaseData().pipe(
      take(1),
      map((baseData: BaseDataDto) => {
        return baseData.boxes;
      }),
    );
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map((paramMap: ParamMap) => {
          const caseId: CaseId = paramMap.get('caseId') as CaseId;
          const animalId: AnimalId = paramMap.get('animalId') as AnimalId;
          return {
            caseId: caseId,
            animalId: animalId,
          };
        }),
        tap((params: Params) => {
          this.params = params;
        }),
        switchMap((params: Params) => {
          return combineLatest([
            this.caseAnimalService.getExitCheck(params.caseId as CaseId, params.animalId as AnimalId),
            this.animalService.getAnimal(params.animalId),
          ]).pipe(take(1));
        }),
      )
      .subscribe(([exitCheck, animal]: [ExitCheckDto, AnimalDto]) => {
        if (animal.type === AnimalType.WildAnimal) {
          this.animalService
            .getLatestCaseDetail(animal.id)
            .pipe(take(1))
            .subscribe((caseDto: CaseDetailDto) => {
              this.buildWildAnimalForm(caseDto);
              this.autoUpdateWildAnimalForm();
            });
        }

        this.breadcrumbService.set('@animalName', getAnimalName(animal));
        this.doneBy = exitCheck.doneBy;
        if (exitCheck.doneBy === null) {
          this.currentUserService.currentUser$.pipe(take(1)).subscribe((user: UserDto | null) => {
            this.doneBy = user;
          });
        }
        this.buildDomesticForm(exitCheck);
        this.autoUpdateDomesticForm();
        this.animal = animal;
      });
  }

  finishExitCheck() {
    this.router.navigate(routes_config.ANIMAL_DETAIL_GENERAL.url(this.params.animalId));
  }

  close() {
    this.router.navigate(['..']);
  }

  private buildWildAnimalForm(caseDto: CaseDetailDto) {
    const fb = this.formBuilder;
    this.wildAnimalStatistics = this.formBuilder.group({
      numAnimals: fb.nonNullable.control<number>(caseDto.wildAnimalStatistics?.numAnimals ?? 0, [Validators.min(0)]),
      numCareStation: fb.nonNullable.control<number>(caseDto.wildAnimalStatistics?.numCareStation ?? 0, [Validators.min(0)]),
      numVeterinarian: fb.nonNullable.control<number>(caseDto.wildAnimalStatistics?.numVeterinarian ?? 0, [Validators.min(0)]),
      numReleased: fb.nonNullable.control<number>(caseDto.wildAnimalStatistics?.numReleased ?? 0, [Validators.min(0)]),
      numDead: fb.nonNullable.control<number>(caseDto.wildAnimalStatistics?.numDead ?? 0, [Validators.min(0)]),
    });

    this.accessService.disableBasedOnRole(this.wildAnimalStatistics, RestrictedSection.Animal);
  }

  private autoUpdateWildAnimalForm() {
    this.wildAnimalStatistics!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const dto: WildAnimalStatisticsDto = this.wildAnimalStatistics!.getRawValue();
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.caseService.updateWildAnimalStatistics(this.params.caseId, dto).subscribe(() => {});
    });
  }

  private buildDomesticForm(exitCheck: ExitCheckDto) {
    const fb = this.formBuilder;
    const formControls: ExitCheckForm = {
      name: fb.control<string | null>({ value: exitCheck.name, disabled: true }),
      trdId: fb.control<string | null>({ value: exitCheck.trdId, disabled: true }),
      chipId: fb.control<string | null>(exitCheck.chipId, []),
      ringId: fb.control<string | null>(exitCheck.ringId, []),
      chipChecked: fb.control<boolean | null>(exitCheck.chipChecked, []),
      box: fb.control<BoxDto | null>(exitCheck.box, []),
      generalCondition: fb.nonNullable.control<GeneralFieldChoice>(exitCheck.generalCondition, [nullableEnumValidator(GeneralFieldChoice)]),
      generalConditionNote: fb.control<string | null>(exitCheck.generalConditionNote, []),

      weightUnit: fb.control<UnitsWeight | null>(exitCheck.weightUnit, [nullableEnumValidator(UnitsWeight)]),
      weight: fb.control<number | null>(exitCheck.weight, [TigonValidators.numberInputValidator(false, 0, Number.MAX_SAFE_INTEGER, true)]),

      sex: fb.control<AnimalSex | null>(exitCheck.sex, [nullableEnumValidator(AnimalSex)]),
      eyes: fb.nonNullable.control<GeneralFieldChoice>(exitCheck.eyes, [nullableEnumValidator(GeneralFieldChoice)]),
      eyesAffected: fb.control<AffectedGeneralRegion | null>(exitCheck.eyesAffected, [nullableEnumValidator(AffectedGeneralRegion)]),
      eyesNote: fb.control<string | null>(exitCheck.eyesNote, []),
      ears: fb.nonNullable.control<GeneralFieldChoice>(exitCheck.ears, [nullableEnumValidator(GeneralFieldChoice)]),
      earsAffected: fb.control<AffectedGeneralRegion | null>(exitCheck.earsAffected, [nullableEnumValidator(AffectedGeneralRegion)]),
      earsNote: fb.control<string | null>(exitCheck.earsNote, []),
      nose: fb.nonNullable.control<GeneralFieldChoice>(exitCheck.nose, [nullableEnumValidator(GeneralFieldChoice)]),
      noseAffected: fb.control<AffectedGeneralRegion | null>(exitCheck.noseAffected, [nullableEnumValidator(AffectedGeneralRegion)]),
      noseNote: fb.control<string | null>(exitCheck.noseNote, []),
      mouthOrTeeth: fb.nonNullable.control<GeneralFieldChoice>(exitCheck.mouthOrTeeth, [nullableEnumValidator(GeneralFieldChoice)]),
      mouthOrTeethAffected: fb.nonNullable.control<ExtendedAffectedRegion[]>(exitCheck.mouthOrTeethAffected, []),
      mouthOrTeethNote: fb.control<string | null>(exitCheck.mouthOrTeethNote, []),
      skin: fb.nonNullable.control<GeneralFieldChoice>(exitCheck.skin, [nullableEnumValidator(GeneralFieldChoice)]),
      skinNote: fb.control<string | null>(exitCheck.skinNote, []),
      coatOrFeathers: fb.nonNullable.control<GeneralFieldChoice>(exitCheck.coatOrFeathers, [nullableEnumValidator(GeneralFieldChoice)]),
      coatOrFeathersNote: fb.control<string | null>(exitCheck.coatOrFeathersNote, []),
      clawsOrFeet: fb.nonNullable.control<GeneralFieldChoice>(exitCheck.clawsOrFeet, [nullableEnumValidator(GeneralFieldChoice)]),
      affectedLimbs: fb.nonNullable.control<AffectedLimb[]>(exitCheck.affectedLimbs, [enumArrayValidator(AffectedLimb)]),
      clawsOrFeetNote: fb.control<string | null>(exitCheck.clawsOrFeetNote, []),

      anus: fb.nonNullable.control<GeneralFieldChoice>(exitCheck.anus, [nullableEnumValidator(GeneralFieldChoice)]),
      anusNote: fb.control<string | null>(exitCheck.anusNote, []),

      generalNote: fb.control<string | null>(exitCheck.generalNote, []),
    };

    this.form = fb.group(formControls);

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Animal);
  }

  private autoUpdateDomesticForm() {
    this.form!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const dto: UpdateExitCheckDto = this.form!.getRawValue();
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.caseAnimalService.updateExitCheck(this.params.caseId, this.params.animalId, dto).subscribe(() => {});
    });
  }
}
