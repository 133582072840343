import { NgClass } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
declare let Redactor: any;

@Component({
  selector: 'tgn-editor',
  standalone: true,

  imports: [FormsModule, ReactiveFormsModule, MatInputModule, NgClass],
  templateUrl: './editor.component.html',
  styleUrl: './editor.component.scss',
})
export class EditorComponent implements AfterViewInit {
  @Input() placeholder = 'Text eingeben...';
  @Input({ required: true }) control!: FormControl<string | null>;
  @Input() hideHtml = true;
  @Input() hideLink = true;

  @ViewChild('redactorTextArea') textArea!: ElementRef;

  protected readonly toolbar = toolbar;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private redactor: any;

  ngAfterViewInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const t: EditorComponent = this;
    this.redactor = Redactor(this.textArea.nativeElement, {
      lang: 'de',
      buttons: ['format', 'bold', 'underline', 'ol', 'ul'],
      callbacks: {
        changed: function (html: string) {
          t.control.setValue(html);
        },
      },
    });
    this.redactor.source.setCode(this.control.value);
  }
}
