import { CdkStepperModule } from '@angular/cdk/stepper';
import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Data, ParamMap, Router } from '@angular/router';
import { AnimalId } from '@animal/dtos/animal.dto';
import { AnimalTaxonomy, AnimalTaxonomyBaseData, getNextGroupSubgroupSpecies } from '@animal/model/animal';
import { SpeciesChoicesPipe } from '@animal/pipes/species-choices.pipe';
import { SubGroupChoicesPipe } from '@animal/pipes/sub-group-choices.pipe';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { StepperComponent } from '@core/components/stepper/stepper.component';
import { TextareaComponent } from '@core/components/textarea/textarea.component';
import { routes_config } from '@core/constants';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { generalFieldChoices } from '@core/models/form/form-helper';
import { AnimalType, GeneralFieldChoice, RoughAge } from '@core/models/general';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { defaultDebounce } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TigonValidators } from '@core/utils/validators';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { map, switchMap, take } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

import { BaseDataDto, BoxDto, GroupDto, GroupTag, SpeciesDto, SubGroupDto } from '../../dtos/base-data.dto';
import { CaseId } from '../../dtos/case.dto';
import { WildCaseAnimalDto } from '../../dtos/domestic-case-animal.dto';
import { UpdateWildCaseAnimalDto } from '../../dtos/update-domestic-case-animal.dto';
import { BaseDataService } from '../../services/base-data-service';
import { CaseAnimalService } from '../../services/case-animal.service';
import { CaseService } from '../../services/case.service';

interface WildAnimalEntryCheckForm {
  group: FormControl<GroupDto | null>;
  subGroup: FormControl<SubGroupDto | null>;
  species: FormControl<SpeciesDto | null>;
  roughAge: FormControl<RoughAge | null>;
  generalCondition: FormControl<GeneralFieldChoice>;
  generalConditionNote: FormControl<string | null>;
  weight: FormControl<number | null>;
  box: FormControl<BoxDto | null>;
}

@Component({
  selector: 'app-wild-animal-entry-check-page',
  standalone: true,
  imports: [
    CdkStepperModule,
    StepperComponent,
    TranslateModule,
    FormElementComponent,
    FormsModule,
    RadioGroupComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    SelectComponent,
    MatButtonModule,
    FormElementDirective,
    AsyncPipe,
    ToRadioChoicePipe,
    NarrowPageContainerComponent,
    SubGroupChoicesPipe,
    SpeciesChoicesPipe,
    MatInput,
    TextareaComponent,
    AddIfMissingPipe,
    RoleRestrictionDirective,
  ],
  templateUrl: './wild-animal-entry-check-page.component.html',
  styleUrl: './wild-animal-entry-check-page.component.scss',
})
export class WildAnimalEntryCheckPageComponent implements OnInit {
  form!: FormGroup<WildAnimalEntryCheckForm>;
  taxonomyBaseData: AnimalTaxonomyBaseData = {
    groups: [],
    subGroups: [],
    species: [],
  };

  boxes: BoxDto[] = [];

  caseId!: CaseId;
  wildCaseAnimalDto?: WildCaseAnimalDto;

  isEdit = false;

  lastTaxonomy: AnimalTaxonomy = {
    group: null,
    subGroup: null,
    species: null,
  };

  protected readonly generalFieldChoices: RadioChoice<GeneralFieldChoice>[] = generalFieldChoices;
  protected readonly roughAgeChoices: RadioChoice<RoughAge>[] = Object.values(RoughAge).map((roughAge: RoughAge) => {
    return {
      label: 'GENERAL.DOMAIN.RoughAge.' + roughAge,
      object: roughAge,
    };
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GroupTag = GroupTag;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private baseDataService: BaseDataService,
    private caseService: CaseService,
    private route: ActivatedRoute,
    private caseAnimalService: CaseAnimalService,
    private snackbar: SnackbarService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private accessService: AccessService,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(map((data: Data) => data['edit'] === true)).subscribe(isEdit => {
      this.isEdit = isEdit;
    });

    this.createForm();

    this.baseDataService
      .getTaxonomy(AnimalType.WildAnimal)
      .pipe(take(1))
      .subscribe(taxonomyBaseData => {
        this.taxonomyBaseData = taxonomyBaseData;
      });

    this.route.paramMap
      .pipe(
        switchMap((paramMap: ParamMap) => {
          this.caseId = paramMap.get('caseId') as CaseId;
          const animalId: AnimalId = paramMap.get('animalId') as AnimalId;
          return this.caseAnimalService.getWild(this.caseId, animalId).pipe(take(1));
        }),
      )
      .subscribe((wildCaseAnimalDto: WildCaseAnimalDto) => {
        this.wildCaseAnimalDto = wildCaseAnimalDto;
        this.breadcrumbService.set(
          '@animalName',
          wildCaseAnimalDto.animal.name ?? wildCaseAnimalDto.animal.chipId ?? wildCaseAnimalDto.animal.id,
        );
        this.lastTaxonomy = {
          group: wildCaseAnimalDto.caseAnimalData.group,
          subGroup: wildCaseAnimalDto.caseAnimalData.subGroup,
          species: wildCaseAnimalDto.caseAnimalData.species,
        };

        this.setFormValuesWithoutEvent(wildCaseAnimalDto);
      });

    this.fetchBaseData();

    this.autoUpdateForms();
  }

  finish() {
    this.caseService
      .completeCase(this.caseId)
      .pipe(takeUntilDestroyed(this.destroyRef), take(1))
      .subscribe(() => {
        if (!this.isEdit) {
          this.snackbar.showSuccessMessage('PAGE.NEW_CASE.CREATE_CASE_SUCCESS');
        }
        this.router.navigate(routes_config.CARE_ANIMAL.url(this.wildCaseAnimalDto!.animal.id));
      });
  }

  isHedgehog(): boolean {
    return this.form.controls.species.value?.tag === GroupTag.Hedgehog || this.form.controls.group.value?.tag === GroupTag.Hedgehog;
  }

  private autoUpdateForms() {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
      const formValues = this.form.getRawValue();

      const currentTaxonomy = {
        group: formValues.group,
        subGroup: formValues.subGroup,
        species: formValues.species,
      };

      const nextTaxonomy = getNextGroupSubgroupSpecies(
        {
          group: this.lastTaxonomy.group,
          subGroup: this.lastTaxonomy.subGroup,
          species: this.lastTaxonomy.species,
        },
        {
          group: currentTaxonomy.group,
          subGroup: currentTaxonomy.subGroup,
          species: currentTaxonomy.species,
        },
        this.taxonomyBaseData,
      );

      this.lastTaxonomy = nextTaxonomy;

      this.form.patchValue(
        {
          group: nextTaxonomy.group,
          subGroup: nextTaxonomy.subGroup,
          species: nextTaxonomy.species,
        },
        { onlySelf: true, emitEvent: false },
      );

      const dto: UpdateWildCaseAnimalDto = {
        caseAnimalData: {
          hasChip: null,
          chipId: null,
          ringId: null,
          coloring: null,
          name: null,
          race: null,
          furLength: null,
          guessedAge: null,
          ...formValues,
          group: nextTaxonomy.group,
          subGroup: nextTaxonomy.subGroup,
          species: nextTaxonomy.species,
        },
        entryCheck: {
          ...formValues,
        },
      };
      if (!this.wildCaseAnimalDto) {
        console.error('wildCaseAnimalDto is null');
        return;
      }
      this.caseAnimalService.updateWild(this.caseId, this.wildCaseAnimalDto.animal.id, dto).subscribe({
        error: (err: unknown) => {
          console.error(`Error updating caseAnimal, caseId: ${this.caseId}, animalId: ${this.wildCaseAnimalDto!.animal.id}, err: `, err);
          this.snackbar.showErrorMessage('PAGE.ENTRY_CHECK.ERROR.AUTO_UPDATE_ERROR');
        },
      });
    });
  }

  private setFormValuesWithoutEvent(caseAnimalDto: WildCaseAnimalDto) {
    const options = { onlySelf: true, emitEvent: false };
    this.form.patchValue({ ...caseAnimalDto.caseAnimalData, ...caseAnimalDto.entryCheck }, options);
  }

  private createForm() {
    const fb: FormBuilder = this.fb;
    this.form = fb.group({
      group: fb.control<GroupDto | null>(null, [Validators.required]),
      subGroup: fb.control<SubGroupDto | null>(null, [Validators.required]),
      species: fb.control<SpeciesDto | null>(null, [Validators.required]),
      roughAge: fb.control<RoughAge | null>(null, []),
      generalCondition: fb.nonNullable.control<GeneralFieldChoice>(GeneralFieldChoice.Allright, []),
      generalConditionNote: fb.control<string | null>(null, []),
      weight: fb.control<number | null>(null, [TigonValidators.numberInputValidator(false, 0, Number.MAX_SAFE_INTEGER, true)]),
      box: fb.control<BoxDto | null>(null, [Validators.required]),
    });

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Animal);
  }

  private fetchBaseData() {
    this.baseDataService.getBaseData().subscribe((baseData: BaseDataDto) => {
      this.boxes = baseData.boxes;
    });
  }
}
