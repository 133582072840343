<mat-form-field appearance="outline" class="chip-list">
  <mat-chip-grid #chipGrid [disabled]="disabled" [placeholder]="placeholder">
    @for (chipItem of chipItems; track chipItem) {
      <mat-chip-row
        (edited)="edit(chipItem, $event)"
        (removed)="remove(chipItem)"
        [aria-description]="'press enter to edit ' + chipItem.name"
        [editable]="true"
        class="chip-item"
      >
        <span class="chip-text">
          {{ chipItem.name }}
        </span>
        <button [attr.aria-label]="'remove ' + chipItem.name" class="chip" matChipRemove>
          <tgn-icon [iconName]="'radixCross2'" />
        </button>
      </mat-chip-row>
    }

    <input
      (matChipInputTokenEnd)="add($event)"
      [matChipInputAddOnBlur]="addOnBlur"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [placeholder]="placeholder | translate"
      matInput
    />
  </mat-chip-grid>
</mat-form-field>
