import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatCell } from '@angular/material/table';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { CareAnimalsListComponent } from '@care/components/care-animals-list/care-animals-list.component';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { TranslateModule } from '@ngx-translate/core';

import { ArchiveAnimalsComponent } from './archive-animals/archive-animals.component';
import { ArchiveCasesComponent } from './archive-cases/archive-cases.component';
import { ArchiveContactsComponent } from './archive-contacts/archive-contacts.component';

@Component({
  selector: 'tgn-archive-page',
  standalone: true,
  imports: [
    CareAnimalsListComponent,
    ConfirmationDialogDirective,
    DatePipe,
    EnumDisplayPipe,
    FullNamePipe,
    MatCell,
    TranslateModule,
    MatTabGroup,
    MatTab,
    ArchiveCasesComponent,
    ArchiveAnimalsComponent,
    ArchiveContactsComponent,
  ],
  templateUrl: './archive-page.component.html',
  styleUrl: './archive-page.component.scss',
})
export class ArchivePageComponent {}
