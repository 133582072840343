import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { ChooseContactComponent } from '@contact/components/choose-contact/choose-contact.component';
import { ContactDto } from '@contact/dto/contact.dto';
import { ContactService } from '@contact/service/contact-service';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ButtonUploadComponent } from '@core/components/button-upload/button-upload.component';
import { CheckboxGroupComponent } from '@core/components/checkbox-group/checkbox-group.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { CaseEntryType } from '@core/models/general';
import { ContactDisplayPipe } from '@core/pipes/contact-display.pipe';
import { ModalComponent } from '@core/services/modal.service';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';

import { AnimalTakeoverDto } from '../../dtos/animal-takeover.dto';
import { CaseDetailDto } from '../../dtos/case-detail.dto';
import { CaseGeneralPlacementDto } from '../../dtos/placement.dto';
import { CaseAnimalService } from '../../services/case-animal.service';

interface OwnerTakeOverDialogData {
  animal: AnimalDto;
  case: CaseDetailDto;
  placement: CaseGeneralPlacementDto;
}

export interface OwnerTakeOverDialogResult {
  newPlacement: CaseGeneralPlacementDto;
  withNewCase: boolean;
}

interface OwnerTakeoverForm {
  previousOwner: FormControl<ContactDto | null>;
  takeoverType: FormControl<FindelTakeoverType | PensionTakeoverType | null>;
  newOwner: FormControl<ContactDto | null>;
}

export enum FindelTakeoverType {
  StmzDateExpired = 'StmzDateExpired',
  RenounceTakeoverWithNewCase = 'RenounceTakeoverWithNewCase',
}

export enum PensionTakeoverType {
  PensionToRenounceWithNewCase = 'PensionToRenounceWithNewCase',
}

@Component({
  selector: 'app-owner-takeover-dialog',
  standalone: true,
  imports: [
    ButtonUploadComponent,
    FormElementComponent,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormElementDirective,
    MatInputModule,
    AutocompleteComponent,
    CheckboxGroupComponent,
    MatCheckboxModule,
    ContactDisplayPipe,
    ChooseContactComponent,
    RadioGroupComponent,
  ],
  templateUrl: './owner-takeover-dialog.component.html',
  styleUrl: './owner-takeover-dialog.component.scss',
})
export class OwnerTakeoverDialogComponent extends ModalComponent<OwnerTakeOverDialogData, OwnerTakeOverDialogResult> {
  form!: FormGroup<OwnerTakeoverForm>;
  data: OwnerTakeOverDialogData;

  findelTakeoverChoices: RadioChoice<FindelTakeoverType>[] = createEnumChoices(FindelTakeoverType, 'GENERAL.DOMAIN.FindelTakeoverType.');

  pensionTakeoverChoices: RadioChoice<FindelTakeoverType>[] = createEnumChoices(PensionTakeoverType, 'GENERAL.DOMAIN.PensionTakeoverType.');

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly CaseEntryType = CaseEntryType;

  constructor(
    dialogRef: MatDialogRef<OwnerTakeoverDialogComponent, OwnerTakeOverDialogResult>,
    @Inject(MAT_DIALOG_DATA) data: OwnerTakeOverDialogData,
    private formBuilder: FormBuilder,
    private caseAnimalService: CaseAnimalService,
    private contactService: ContactService,
  ) {
    super(dialogRef);
    this.data = data;
    this.buildForm(this.data.placement);

    this.contactService
      .getTrdContact()
      .pipe(take(1))
      .subscribe((contactDto: ContactDto) => {
        this.form.controls.newOwner.patchValue(contactDto, { emitEvent: false });
      });
  }

  performTakeover() {
    const formValues = this.form.getRawValue();

    const isWithNewCase =
      formValues.takeoverType === FindelTakeoverType.RenounceTakeoverWithNewCase ||
      formValues.takeoverType === PensionTakeoverType.PensionToRenounceWithNewCase;

    const dto: AnimalTakeoverDto = {
      isWithNewCase: isWithNewCase,
      newOwnerId: formValues.newOwner?.id ?? null,
    };

    this.caseAnimalService
      .takeover(this.data.case, this.data.animal, dto)
      .pipe(take(1))
      .subscribe((newPlacement: CaseGeneralPlacementDto) => {
        this.closeWithResult({
          newPlacement: newPlacement,
          withNewCase: formValues.takeoverType === FindelTakeoverType.RenounceTakeoverWithNewCase,
        });
      });
  }

  private buildForm(placement: CaseGeneralPlacementDto) {
    const fb = this.formBuilder;
    this.form = fb.group({
      previousOwner: fb.control<ContactDto | null>({ value: placement.previousTakeoverContact, disabled: true }),
      takeoverType: fb.control<FindelTakeoverType | PensionTakeoverType | null>(null),
      newOwner: fb.control<ContactDto | null>(null, [Validators.required]),
    });
  }
}
