import { DatePipe } from '@angular/common';
import { ResolvedValue } from '@angular/compiler-cli/src/ngtsc/partial_evaluator';
import { Pipe, PipeTransform } from '@angular/core';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { BaseDataPositionDto } from '@baseData/dtos/base-data-position.dto';
import { PositionCategoryDto } from '@baseData/dtos/position-category.dto';
import { VatTaxDto } from '@baseData/dtos/vat-tax.dto';
import { BillDto } from '@bill/dto/bill.dto';
import { PositionDto, PositionEntityType } from '@bill/dto/position.dto';
import { TaskCategoryDto, TaskDto } from '@care/dtos/task.dto';
import { GroupDto, SpeciesDto, SubGroupDto } from '@case/dtos/base-data.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { DomesticEntryCheckDto, WildAnimalEntryCheckDto } from '@case/dtos/entry-check.dto';
import { ExitCheckDto } from '@case/dtos/exit-check.dto';
import { CaseGeneralPlacementDto, PlacementDetailDto } from '@case/dtos/placement.dto';
import { ContactDto } from '@contact/dto/contact.dto';
import { CaseRelatedContactDto } from '@contact/dto/related-contact.dto';
import { stripRichText } from '@core/pipes/strip-rich-text.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ProspectDto } from '@prospect/dto/prospect.dto';
import { UserDto } from '@user/dto/user.dto';

import { ReferenceField, StateValue } from '../components/audit-diff/audit-diff.component';

// use keyof T to get the keys of the object
export type AuditValueConfig<T> = Partial<{
  [K in keyof T]: string;
}> & {
  dateValues: string[]; // @TODO typing
};

export interface AuditDisplayConfig {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Contact: AuditValueConfig<ContactDto>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Case: AuditValueConfig<CaseDetailDto>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Animal: AuditValueConfig<AnimalDto | { hasChip: boolean }>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CaseContactRelation: AuditValueConfig<CaseRelatedContactDto>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AnimalContactRelation: AuditValueConfig<CaseRelatedContactDto>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Placement: AuditValueConfig<PlacementDetailDto & CaseGeneralPlacementDto>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  User: AuditValueConfig<UserDto>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Prospect: AuditValueConfig<ProspectDto>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EntryCheck: AuditValueConfig<DomesticEntryCheckDto | WildAnimalEntryCheckDto>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ExitCheck: AuditValueConfig<ExitCheckDto>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Task: AuditValueConfig<TaskDto>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  Group: AuditValueConfig<GroupDto & { active: boolean }>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Subgroup: AuditValueConfig<SubGroupDto & { active: boolean }>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Species: AuditValueConfig<SpeciesDto & { active: boolean }>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  VatTax: AuditValueConfig<VatTaxDto>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PositionCategory: AuditValueConfig<PositionCategoryDto>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  BaseDataPosition: AuditValueConfig<BaseDataPositionDto>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  TaskCategory: AuditValueConfig<TaskCategoryDto>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  Bill: AuditValueConfig<BillDto>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  Position: AuditValueConfig<PositionDto & { entityType: PositionEntityType }>;
}

const translationPrefixes: AuditDisplayConfig = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Contact: {
    status: 'GENERAL.DOMAIN.ContactStatus',
    salutation: 'GENERAL.DOMAIN.Salutation',
    possibleDonor: 'GENERAL.DOMAIN.Boolean',
    noMailings: 'GENERAL.DOMAIN.Boolean',
    isDead: 'GENERAL.DOMAIN.Boolean',
    archived: 'GENERAL.DOMAIN.Boolean',
    dateValues: [],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Case: {
    state: 'GENERAL.DOMAIN.CaseState',
    animalType: 'GENERAL.DOMAIN.AnimalType',
    entryType: 'GENERAL.DOMAIN.CaseEntryType',
    entryViaType: 'GENERAL.DOMAIN.EntryViaType',
    archived: 'GENERAL.DOMAIN.Boolean',
    dateValues: ['entryDate', 'completeDate'],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Animal: {
    type: 'GENERAL.DOMAIN.AnimalType',
    sex: 'GENERAL.DOMAIN.AnimalSex',
    castrated: 'GENERAL.DOMAIN.Boolean',
    furLength: 'GENERAL.DOMAIN.FurLength',
    turtleShellLengthUnit: 'GENERAL.DOMAIN.UnitsLength',
    state: 'GENERAL.DOMAIN.AnimalState',
    archived: 'GENERAL.DOMAIN.Boolean',
    hasChip: 'GENERAL.DOMAIN.Boolean',
    dateValues: ['birthdate'],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Placement: {
    placementType: 'GENERAL.DOMAIN.PlacementType',
    placementState: 'GENERAL.DOMAIN.PlacementState',
    isCastrationCoveredByTrd: 'GENERAL.DOMAIN.Boolean',
    isVaccinatedAccordingToPassport: 'GENERAL.DOMAIN.Boolean',
    mediationHasProspect: 'GENERAL.DOMAIN.Boolean',
    mediationHasPlacementDate: 'GENERAL.DOMAIN.Boolean',
    mediationIsInTrial: 'GENERAL.DOMAIN.Boolean',
    mediationPlacementSuccessful: 'GENERAL.DOMAIN.Boolean',
    mediationPhonePlacementSupervision: 'GENERAL.DOMAIN.Boolean',
    mediationOnPremisePlacementSupervision: 'GENERAL.DOMAIN.Boolean',
    mediationCompleted: 'GENERAL.DOMAIN.Boolean',
    castrationType: 'GENERAL.DOMAIN.CastrationType',
    isPublishDisallowed: 'GENERAL.DOMAIN.Boolean',
    exitState: 'GENERAL.DOMAIN.ExitState',
    vaccinationPassHandedOver: 'GENERAL.DOMAIN.Boolean',
    fosterAnimalPassportHandedOver: 'GENERAL.DOMAIN.Boolean',
    veterinaryExaminationCondition: 'GENERAL.DOMAIN.VeterinaryExaminationCondition',
    illnessKinds: 'GENERAL.DOMAIN.IllnessKind',
    transferMaterials: 'GENERAL.DOMAIN.TransferMaterial',
    dateValues: [
      'castrationDate',
      'exitDate',
      'takeoverDate',
      'leukosisAndFivNegativeDate',
      'followUpVaccinationSecondIklsDate',
      'followUpVaccinationThirdIklsDate',
    ],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CaseContactRelation: {
    dateValues: ['fromDate', 'toDate'],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AnimalContactRelation: {
    dateValues: ['fromDate', 'toDate'],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  User: {
    roles: 'GENERAL.DOMAIN.UserRole',
    status: 'GENERAL.DOMAIN.UserStatus',
    dateValues: [],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Prospect: {
    state: 'GENERAL.DOMAIN.ProspectState',
    consentSiteSupervision: 'GENERAL.DOMAIN.Boolean',
    catLockAvailable: 'GENERAL.DOMAIN.Boolean',
    writtenAuthorizationToKeepAnimals: 'GENERAL.DOMAIN.Boolean',
    hasAnimalsInHousehold: 'GENERAL.DOMAIN.Boolean',
    imageOfAccommodationPresented: 'GENERAL.DOMAIN.Boolean',
    dateValues: ['date'],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EntryCheck: {
    generalCondition: 'GENERAL.DOMAIN.GeneralFieldChoice',
    constitution: 'GENERAL.DOMAIN.GeneralFieldChoice',
    nutritionalCondition: 'GENERAL.DOMAIN.GeneralFieldChoice',
    eyes: 'GENERAL.DOMAIN.GeneralFieldChoice',
    eyesAffected: 'GENERAL.DOMAIN.AffectedGeneralRegion',
    ears: 'GENERAL.DOMAIN.GeneralFieldChoice',
    earsAffected: 'GENERAL.DOMAIN.AffectedGeneralRegion',
    nose: 'GENERAL.DOMAIN.GeneralFieldChoice',
    noseAffected: 'GENERAL.DOMAIN.AffectedGeneralRegion',
    mouthOrTeeth: 'GENERAL.DOMAIN.GeneralFieldChoice',
    mouthOrTeethAffected: 'GENERAL.DOMAIN.ExtendedAffectedRegion',
    skin: 'GENERAL.DOMAIN.GeneralFieldChoice',
    coatOrFeathers: 'GENERAL.DOMAIN.GeneralFieldChoice',
    clawsOrFeet: 'GENERAL.DOMAIN.GeneralFieldChoice',
    affectedLimbs: 'GENERAL.DOMAIN.AffectedLimb',
    anus: 'GENERAL.DOMAIN.GeneralFieldChoice',
    flightBehaviorOrSittingPosition: 'GENERAL.DOMAIN.GeneralFieldChoice',
    hasParasites: 'GENERAL.DOMAIN.OpenBooleanChoice',
    sex: 'GENERAL.DOMAIN.AnimalSex',
    turtleShellLengthUnit: 'GENERAL.DOMAIN.UnitsLength',
    parasites: 'GENERAL.DOMAIN.Parasite',
    castrated: 'GENERAL.DOMAIN.UnknownBooleanChoice',
    weightUnit: 'GENERAL.DOMAIN.UnitsWeight',
    dateValues: [],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ExitCheck: {
    chipChecked: 'GENERAL.DOMAIN.Boolean',
    weightUnit: 'GENERAL.DOMAIN.UnitsWeight',
    sex: 'GENERAL.DOMAIN.AnimalSex',
    generalCondition: 'GENERAL.DOMAIN.GeneralFieldChoice',
    eyes: 'GENERAL.DOMAIN.GeneralFieldChoice',
    eyesAffected: 'GENERAL.DOMAIN.AffectedGeneralRegion',
    ears: 'GENERAL.DOMAIN.GeneralFieldChoice',
    earsAffected: 'GENERAL.DOMAIN.AffectedGeneralRegion',
    nose: 'GENERAL.DOMAIN.GeneralFieldChoice',
    noseAffected: 'GENERAL.DOMAIN.AffectedGeneralRegion',
    mouthOrTeeth: 'GENERAL.DOMAIN.GeneralFieldChoice',
    mouthOrTeethAffected: 'GENERAL.DOMAIN.ExtendedAffectedRegion',
    skin: 'GENERAL.DOMAIN.GeneralFieldChoice',
    coatOrFeathers: 'GENERAL.DOMAIN.GeneralFieldChoice',
    clawsOrFeet: 'GENERAL.DOMAIN.GeneralFieldChoice',
    affectedLimbs: 'GENERAL.DOMAIN.AffectedLimb',
    anus: 'GENERAL.DOMAIN.GeneralFieldChoice',
    dateValues: [],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Task: {
    status: 'GENERAL.DOMAIN.TaskStatus',
    entityType: 'GENERAL.DOMAIN.TaskEntityType',
    dateValues: ['dueDate'],
    repetition: 'GENERAL.DOMAIN.TaskRepetition',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Group: {
    active: 'GENERAL.DOMAIN.Boolean',
    dateValues: [],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Subgroup: {
    active: 'GENERAL.DOMAIN.Boolean',
    dateValues: [],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Species: {
    active: 'GENERAL.DOMAIN.Boolean',
    dateValues: [],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PositionCategory: {
    isPension: 'GENERAL.DOMAIN.Boolean',
    dateValues: [],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  VatTax: {
    type: 'GENERAL.DOMAIN.VatTaxType',
    dateValues: ['validFrom', 'validTo'],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BaseDataPosition: {
    vatTaxType: 'GENERAL.DOMAIN.VatTaxType',
    positionCategory: 'GENERAL.DOMAIN.PositionCategory',
    type: 'GENERAL.DOMAIN.PositionType',
    dateValues: ['validFrom', 'validTo'],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  TaskCategory: {
    type: 'GENERAL.DOMAIN.TaskCategoryType',
    group: 'GENERAL.DOMAIN.TaskCategoryGroup',
    dateValues: [],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Bill: {
    status: 'GENERAL.DOMAIN.BillStatus',
    billRangeType: 'GENERAL.DOMAIN.BillRangeType',
    dateValues: ['billDate'],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Position: {
    isBilled: 'GENERAL.DOMAIN.Boolean',
    entityType: 'GENERAL.DOMAIN.PositionEntityType',
    dateValues: [],
  },
};

@Pipe({
  name: 'stateValueDisplay',
  standalone: true,
})
export class StateValueDisplayPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private datePipe: DatePipe,
  ) {}

  transform(value: StateValue, key: string, entityType: string): string {
    if (value === null || value === undefined) {
      return '';
    }

    // manually strip rich text
    if (entityType === 'Task' && (key === 'description' || (key === 'report' && !Array.isArray(value)))) {
      value = stripRichText(value as string);
    }

    // @ts-expect-error -> entityType might not be in translationPrefixes
    const translationConfig = translationPrefixes[entityType] as AuditValueConfig<unknown> | undefined;

    if (translationConfig) {
      if (translationConfig['dateValues'].includes(key)) {
        return this.datePipe.transform(value as string, 'dd.MM.yyyy') as string;
      }

      const translationPrefix = (translationConfig as unknown as Record<string, string>)[key] as string | undefined;
      if (translationPrefix) {
        if (Array.isArray(value)) {
          return (value as string[]).map(v => this.translate.instant(`${translationPrefix}.${v}`)).join(', ');
        }
        return this.translate.instant(`${translationPrefix}.${value}`);
      }
    }

    if (isReferenceField(value)) {
      return value.name;
    }
    if (isReferenceFieldArray(value)) {
      return (value as ReferenceField[]).map(v => v.name).join(', ');
    }

    if (Array.isArray(value)) {
      return value.join(', ');
    }

    return value;
  }
}

interface MaybeReferenceField {
  id?: string;
  name?: string;
}

export function isReferenceField(value: StateValue | null): value is ReferenceField {
  if (value === null) {
    return false;
  }
  return typeof value === 'object' && 'id' in (value as MaybeReferenceField) && 'name' in (value as MaybeReferenceField);
}

export function isReferenceFieldArray(value: StateValue): value is ReferenceField[] {
  return Array.isArray(value as ResolvedValue) && (value as ReferenceField[]).every(isReferenceField);
}
