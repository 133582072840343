import { HttpParams, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, take } from 'rxjs';

export function isDefined<T>(subject: T | undefined | null): subject is T {
  return subject !== null && subject !== undefined;
}

export function isNotDefined<T>(subject: T | null): subject is null;
export function isNotDefined<T>(subject: T | undefined | null): subject is undefined | null {
  return !isDefined(subject);
}

export function ifDefined<T, R>(subject: T | undefined, transform: (value: NonNullable<T>) => R): R | undefined;
export function ifDefined<T, R>(subject: T | undefined | null, transform: (value: NonNullable<T>) => R): R | undefined | null {
  return isDefined(subject) ? transform(subject as NonNullable<T>) : subject;
}

interface EnumObject {
  [key: string]: string;
}

/**
 * Generic function to create all choices for an enum.
 * Uses the fact that an enum is compiled to an object with key and value pairs the strings and assumes that
 * the enum value as string is already translated and can be inserted directly into the template.
 */
export function createEnumChoices<T>(enumType: EnumObject, prefix?: string): RadioChoice<T>[] {
  return Object.keys(enumType).map(key => {
    const enumValue = enumType[key];
    const translate = inject(TranslateService);

    const label = prefix ? translate.instant(`${prefix}${enumValue}`) : enumValue.toString();
    return {
      label: label,
      object: enumValue as T,
    };
  });
}

export function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

export function roundToNearest05(num: number): number {
  return Math.round(num * 20) / 20;
}

export function openDownloadedBlobPdf(response: HttpResponse<Blob>, defaultFilename?: string) {
  const blob = response.body!;
  const filename = response.headers.get('content-disposition')?.split('filename=')[1] ?? defaultFilename ?? 'download.pdf';

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

export function mergeHttpParams(params1: HttpParams, params2: HttpParams): HttpParams {
  let mergedParams = new HttpParams();

  // Append params1 to mergedParams
  params1.keys().forEach(key => {
    params1.getAll(key)?.forEach(value => {
      mergedParams = mergedParams.append(key, value);
    });
  });

  // Append params2 to mergedParams
  params2.keys().forEach(key => {
    params2.getAll(key)?.forEach(value => {
      mergedParams = mergedParams.append(key, value);
    });
  });

  return mergedParams;
}

export interface WithName {
  name: string;
}

export function uniqueNameValidator(data: Observable<WithName[]>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return data.pipe(
      take(1),
      map(list => {
        return list.some(item => item.name === control.value) ? { uniqueName: true } : null;
      }),
    );
  };
}

export function overwriteObjectValues<T>(original: T, newValues: Partial<T>): T {
  Object.keys(newValues).forEach(key => {
    // @ts-expect-error ignore
    if (newValues[key] !== undefined) {
      // @ts-expect-error ignore
      original[key] = newValues[key] as unknown;
    }
  });
  return original;
}
