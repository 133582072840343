<header>
  <div class="left-block">
    <button (click)="toggleMobileMenu.emit()" disableRipple="true" mat-icon-button>
      <tgn-icon [iconName]="'radixHamburgerMenu'" />
    </button>
    <tgn-breadcrumbs />
  </div>

  @if (contextActionsPortal.currentPortal; as contextActions) {
    <div>
      <!--use context action directive for specifying content that should be rendered here-->
      @if (!isMobileViewPort) {
        <ng-template [cdkPortalOutlet]="contextActions"></ng-template>
      } @else {
        <ng-template [cdkPortalOutlet]="contextActions"></ng-template>
      }
    </div>
  }
</header>
