<ng-container *ngIf="animal() as animal">
  <div *ngIf="contacts() as contacts">
    <tgn-scrollable-table>
      <table #table="matTable" [dataSource]="contacts" mat-table>
        <tr *matHeaderRowDef="columns" mat-header-row></tr>
        <tr (click)="editContactWithRel(rel)" *matRowDef="let rel; table: table; columns: columns" class="table-row-link" mat-row></tr>

        <ng-container matColumnDef="personNumber">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.personNumber' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>{{ rel.contact.personNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.status' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>{{ rel.contact.status | enumDisplay: 'GENERAL.DOMAIN.ContactStatus' }}</td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.firstName' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>{{ rel.contact.firstName }}</td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.lastName' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>{{ rel.contact.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="organization">
          <th *matHeaderCellDef mat-header-cell>{{ 'PAGE.ANIMAL.DETAIL.CONTACT.organization' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>{{ rel.contact.company }}</td>
        </ng-container>

        <ng-container matColumnDef="kind">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.KIND' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>{{ rel.kind.name }}</td>
        </ng-container>

        <ng-container matColumnDef="fromToDate">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.fromToDate' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>
            <span>{{ rel.fromDate | date }}</span>
            @if (rel.fromDate || rel.toDate) {
              <span class="separator">-</span>
            }
            <span>{{ rel.toDate | date }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.note' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>
            <div class="max-width-column">
              <tgn-single-line-text [text]="rel.contact.note ?? ''" />
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Contact.createdDate' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>{{ rel.relatedDate | date }}</td>
        </ng-container>

        <ng-container matColumnDef="caseNumber">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.caseNumber' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>
            @if (rel.caseId) {
              <a [routerLink]="appRoutes.CASE_DETAIL.url(rel.caseId)">{{ rel.caseNumber }}</a>
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="caseState">
          <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.caseState' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>
            {{ rel.caseState | enumDisplay: 'GENERAL.DOMAIN.CaseState' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell>{{ 'GENERAL.BUTTON.MENU_ACTIONS' | translate }}</th>
          <td *matCellDef="let rel; table: table" mat-cell>
            <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ animal: animal }" [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <ng-template matMenuContent>
                <a [routerLink]="appRoutes.CONTACT_DETAIL.url(rel.contact.id)" mat-menu-item>
                  <span>{{ 'PAGE.CASE.DETAIL.ACTIONS.TO_CONTACT' | translate }}</span>
                </a>
                <button
                  (agreeEvent)="deleteContact(rel)"
                  [dialogTitle]="'PAGE.ANIMAL.DETAIL.REMOVE_CONTACT_TITLE'"
                  [tgnContextItem]="{ object: animalDetailStore.animal$ }"
                  [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
                  [yesButtonLabel]="'GENERAL.BUTTON.REMOVE' | translate"
                  appConfirmationDialog
                  mat-menu-item
                >
                  <span>{{ 'PAGE.ANIMAL.DETAIL.ACTIONS.REMOVE_CONTACT' | translate }}</span>
                </button>
              </ng-template>
            </mat-menu>
          </td>
        </ng-container>
      </table>
    </tgn-scrollable-table>
  </div>
</ng-container>
