import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField, MatInput } from '@angular/material/input';
import { IconComponent } from '@core/components/icon/icon.component';

@Component({
  selector: 'tgn-number-input',
  standalone: true,
  imports: [MatButtonModule, IconComponent, MatInput, MatFormField, NgClass],
  templateUrl: './number-input.component.html',
  styleUrl: './number-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NumberInputComponent,
    },
  ],
})
export class NumberInputComponent implements ControlValueAccessor {
  value = 0;
  @Input() minValue?: number;
  @Input() variantDarker = false;

  isDisabled = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  onChange = (_: number) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  registerOnChange(onChange: never): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: never): void {
    this.onTouched = onTouched;
  }

  writeValue(value: number): void {
    this.value = value;
  }

  increase() {
    this.value = this.value + 1;
    this.onChange(this.value);
    this.onTouched();
  }

  decrease() {
    this.value = this.value - 1;
    this.onChange(this.value);
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
