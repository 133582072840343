import { NgClass } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { CoreModule } from '@core/core.module';
import { NgIcon } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';

export type MessageType = 'error' | 'warning' | 'success' | 'pending';

export interface SnackbarData {
  title: string;
  message: string;
  messageType: MessageType;
}

@Component({
  selector: 'tgn-snackbar',
  standalone: true,
  imports: [CoreModule, NgIcon, MatButtonModule, TranslateModule, NgClass],
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
    private snackBarRef: MatSnackBarRef<SnackbarComponent>,
  ) {}

  close() {
    this.snackBarRef.dismiss();
  }
}
