import { debounceTime, retry, timer } from 'rxjs';

import { environment } from '../../../environments/environment';

export abstract class BaseService {
  protected get apiUrl(): string {
    return environment.apiUrl;
  }
}

// 1s
export const DEFAULT_RETRY_DELAY = 1000;
export const DEFAULT_RETRY_COUNT = 3;

export function defaultRetry<T>() {
  return retry<T>({
    count: DEFAULT_RETRY_COUNT,
    delay: () => timer(DEFAULT_RETRY_DELAY),
  });
}

export const DEFAULT_DEBOUNCE_TIME = 400;

export function defaultDebounce<T>() {
  return debounceTime<T>(DEFAULT_DEBOUNCE_TIME);
}
