import { UserId } from '@case/dtos/animal-takeover.dto';
import { StationDto, StationId } from '@case/dtos/base-data.dto';
import { UserRole } from '@core/models/general';
import { ImageDto } from '@file/dto/file.dto';

export interface UserDto {
  id: UserId;
  uid: string;
  firstName: string | null;
  lastName: string | null;
  visa: string | null;
  email: string;
  status: UserStatus;
  roles: UserRole[];
  usedRole: UserRole | null;
  usedStation: StationDto | null;
  defaultStation: StationDto | null;
  avatarImage: ImageDto | null;
}

export enum UserStatus {
  Active = 'Active',
  Deactivated = 'Deactivated',
  Invited = 'Invited',
}

export interface InviteUserDto {
  firstName: string | null;
  lastName: string | null;
  email: string;
  roles: UserRole[];
  defaultStation: StationId | null;
}

export interface UpdateUserDto {
  firstName: string | null;
  lastName: string | null;
  roles: UserRole[];
  defaultStation: StationId | null;
}

export interface UpdateUsedRoleDto {
  role: UserRole | null;
}

export interface UpdateUsedStationDto {
  station: StationId | null;
}
