import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShowIfTruncatedDirective } from '@core/directives/show-if-truncated.directive';

@Component({
  selector: 'tgn-single-line-text',
  standalone: true,
  imports: [MatTooltipModule, ShowIfTruncatedDirective],
  templateUrl: './single-line-text.component.html',
  styleUrl: './single-line-text.component.scss',
})
export class SingleLineTextComponent {
  @Input() text!: string;
}
