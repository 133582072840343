@if (contact$ | async; as contact) {
  <ng-container>
    <div class="header">
      @if (contact.archived) {
        <mat-chip class="custom-chip">Archiviert</mat-chip>
      }
      <h1>{{ (contact | fullName) + ' - ID: ' + contact.personNumber }}</h1>
    </div>
  </ng-container>
}

<tgn-nav-tabs [(activeTab)]="selectedTab" [tabs]="tabs"></tgn-nav-tabs>

<div class="content">
  <router-outlet></router-outlet>
</div>
