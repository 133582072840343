import { ExitState } from '@baseData/dtos/exit-state.dto';
import { RelationKindDto } from '@baseData/dtos/relation-kind.dto';
import { TaskCategoryDto } from '@care/dtos/task.dto';
import { BrandedId } from '@core/utils/brand';

export interface Entity {
  id: string;
}

export enum GroupTag {
  Dog = 'Dog',
  Turtle = 'Turtle',
  Bird = 'Bird',
  Hedgehog = 'Hedgehog',
  Exotic = 'Exotic',
  Cat = 'Cat',
  SmallAnimal = 'SmallAnimal',
}

export enum MainGroupType {
  DomesticAnimal = 'DomesticAnimal',
  WildAnimal = 'WildAnimal',
}

export type GroupId = BrandedId<'GroupId'>;

export interface GroupDto extends Entity {
  id: GroupId;
  name: string;
  tag: GroupTag | null;
  groupType: MainGroupType;
}

export type SubGroupId = BrandedId<'SubGroupId'>;

export interface SubGroupDto extends Entity {
  id: SubGroupId;
  name: string;
  groupId: string;
  tag: GroupTag | null;
}

export type SpeciesId = BrandedId<'SpeciesId'>;

export interface SpeciesDto extends Entity {
  id: SpeciesId;
  name: string;
  tag: GroupTag | null;
  subGroupId: SubGroupId;
  active: boolean;
}

export type RaceId = BrandedId<'RaceDtoId'>;

export interface RaceDto extends Entity {
  id: RaceId;
  name: string;
  speciesId: SpeciesId;
  active: boolean;
}

export type SubmitReasonId = BrandedId<'SubmitReasonId'>;

export interface SubmitReasonDto extends Entity {
  id: SubmitReasonId;
  name: string;
  tag: SubmitReasonTag | null;
  active: boolean;
}

export enum SubmitReasonTag {
  OwnerDead = 'OwnerDead',
  Renounced = 'Renounced',
  TakeoverFromPension = 'TakeoverFromPension',
  BornInShelter = 'BornInShelter',
}

export type BoxId = BrandedId<'BoxId'>;

export interface BoxDto extends Entity {
  id: BoxId;
  name: string;
  description: string;
  active: boolean;
}

export interface CreateBoxDto {
  name: string;
  station: StationDto;
}

export interface UpdateBoxDto {
  active: boolean;
}

export type StationId = BrandedId<'StationId'>;

export interface StationDto extends Entity {
  id: StationId;
  name: string;
  description: string;
  boxes: BoxDto[];
}

export interface CreateStationDto {
  name: string;
}

export interface BaseDataDto {
  wildAnimal: {
    groups: GroupDto[];
    subGroups: SubGroupDto[];
    species: SpeciesDto[];
  };
  domesticAnimal: {
    groups: GroupDto[];
    subGroups: SubGroupDto[];
    species: SpeciesDto[];
    races: RaceDto[];
  };

  submitReasons: SubmitReasonDto[];
  boxes: BoxDto[];
  taskCategories: TaskCategoryDto[];
  stations: StationDto[];
  relationKinds: RelationKindDto[];
  exitStates: ExitState[];
}

interface WithId {
  id: string | BrandedId<string>;
}

export function compareWith(item1: WithId | null, item2: WithId | null) {
  return item1?.id === item2?.id;
}
