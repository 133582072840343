import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

export enum NarrowPageContainerSize {
  Regular = 'Regular',
  Large = 'Large',
}

@Component({
  selector: 'tgn-narrow-page-container',
  standalone: true,
  imports: [NgClass],
  templateUrl: './narrow-page-container.component.html',
  styleUrl: './narrow-page-container.component.scss',
})
export class NarrowPageContainerComponent {
  @Input() size: NarrowPageContainerSize = NarrowPageContainerSize.Regular;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly NarrowPageContainerSize = NarrowPageContainerSize;
}
