import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatCell, MatTableModule } from '@angular/material/table';
import { ReplaceBasePositionDialogComponent } from '@baseData/components/replace-base-position-dialog/replace-base-position-dialog.component';
import { UpdateBasePositionDialogComponent } from '@baseData/components/update-base-position-dialog/update-base-position-dialog.component';
import { BaseDataPositionDto, CreateBaseDataPositionDto } from '@baseData/dtos/base-data-position.dto';
import { PositionCategoryDto } from '@baseData/dtos/position-category.dto';
import { VatTaxGroup, VatTaxType } from '@baseData/dtos/vat-tax.dto';
import { VatTaxDisplayPipe } from '@baseData/pipes/vat-tax-display.pipe';
import { BaseDataPositionService } from '@baseData/services/base-data-position.service';
import { VatTaxService } from '@baseData/services/vat-tax.service';
import { PositionType } from '@bill/dto/position.dto';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SelectComponent } from '@core/components/select/select.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FvsCurrencyPipe } from '@core/pipes/currency.pipe';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { filter, take } from 'rxjs';

interface BaseDataPositionForm {
  name: FormControl<string | null>;
  description: FormControl<string | null>;
  type: FormControl<PositionType | null>;
  price: FormControl<number | null>;
  vatTaxType: FormControl<VatTaxType | null>;
  validFrom: FormControl<IsoLocalDateString | null>;
}

@Component({
  selector: 'tgn-base-data-position-table',
  standalone: true,
  imports: [
    DateInputComponent,
    DatePipe,
    FormElementComponent,
    FormElementDirective,
    MatButton,
    MatCell,
    MatTableModule,
    TypesafeMatTableModule,
    TranslateModule,
    ReactiveFormsModule,
    EnumDisplayPipe,
    VatTaxDisplayPipe,
    CurrencyPipe,
    SelectComponent,
    ScrollableTableComponent,
    SingleLineTextComponent,
    FvsCurrencyPipe,
    MatIcon,
    MatMenuModule,
    MatIconButton,
    MatFormField,
    MatInput,
    RoleRestrictionDirective,
  ],
  templateUrl: './base-data-position-table.component.html',
  styleUrl: './base-data-position-table.component.scss',
})
export class BaseDataPositionTableComponent implements OnInit {
  @Input({ required: true }) category!: PositionCategoryDto;
  @Input({ required: true }) dataSource!: TigonDatasource<BaseDataPositionDto, {}>;

  baseDataPositionForm!: FormGroup<BaseDataPositionForm>;

  readonly columns = ['name', 'description', 'type', 'price', 'vatTaxType', 'validFrom', 'validTo', 'actions'];
  currentVatTax?: VatTaxGroup;
  positionTypeChoices: RadioChoice<PositionType>[] = createEnumChoices(PositionType, 'GENERAL.DOMAIN.PositionType.');
  vatTaxTypeChoices: RadioChoice<VatTaxType | null>[] = [];
  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    private fb: FormBuilder,
    private baseDataPositionService: BaseDataPositionService,
    private vatTaxService: VatTaxService,
    private modalService: ModalService,
    private translate: TranslateService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    if (this.category.isPension) {
      this.positionTypeChoices = [PositionType.Days].map(type => {
        return { object: type, label: this.translate.instant(`GENERAL.DOMAIN.PositionType.${type}`) };
      });
    }

    this.vatTaxService
      .getCurrent()
      .pipe(take(1))
      .subscribe(currentVatTax => {
        this.currentVatTax = currentVatTax;

        this.vatTaxTypeChoices = [
          { object: VatTaxType.Normal, label: `${currentVatTax.normal.value}% (normal)` },
          { object: VatTaxType.Reduced, label: `${currentVatTax.reduced.value}% (reduziert)` },
          { object: VatTaxType.None, label: `Ohne (0.0%)` },
        ];
      });

    this.baseDataPositionForm = this.fb.group({
      name: this.fb.control<string | null>(null, Validators.required),
      description: this.fb.control<string | null>(null),
      type: this.fb.control<PositionType | null>(this.category.isPension ? PositionType.Days : null, Validators.required),
      price: this.fb.control<number | null>(null, [Validators.min(0.01)]),
      vatTaxType: this.fb.control<VatTaxType | null>(null, Validators.required),
      validFrom: this.fb.control<IsoLocalDateString | null>(null),
    });

    this.accessService.disableBasedOnRole(this.baseDataPositionForm, RestrictedSection.Backoffice);
  }

  addEntry() {
    const values: {
      name: string | null;
      description: string | null;
      type: PositionType | null;
      price: number | null;
      vatTaxType: VatTaxType | null;
      validFrom: IsoLocalDateString | null;
    } = this.baseDataPositionForm!.getRawValue();

    if (values.name === null || values.type === null || values.vatTaxType === null) {
      return;
    }

    const dto: CreateBaseDataPositionDto = {
      name: values.name,
      description: values.description,
      type: values.type,
      price: values.price,
      vatTaxType: values.vatTaxType,
      positionCategoryId: this.category.id,
      validFrom: values.validFrom,
    };

    this.baseDataPositionService
      .create(dto)
      .pipe(take(1))
      .subscribe(() => {
        this.baseDataPositionForm!.patchValue(
          {
            name: null,
            description: null,
            type: this.category.isPension ? PositionType.Days : null,
            price: null,
            vatTaxType: null,
          },
          { emitEvent: false },
        );
        this.baseDataPositionForm!.markAsPristine();
        this.baseDataPositionForm!.markAsUntouched();
        this.dataSource.update({});
      });
  }

  openEditDialog(baseDataPosition: BaseDataPositionDto) {
    this.modalService
      .open(UpdateBasePositionDialogComponent, { baseDataPosition: baseDataPosition }, { width: ModalWidth.Medium })
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe(() => {
        this.dataSource.update({});
      });
  }

  openReplaceDialog(baseDataPosition: BaseDataPositionDto) {
    this.modalService
      .open(ReplaceBasePositionDialogComponent, { baseDataPosition: baseDataPosition }, { width: ModalWidth.Medium })
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe(() => {
        this.dataSource.update({});
      });
  }
}
