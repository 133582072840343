<h1 class="title" mat-dialog-title>
  {{ 'PAGE.CASE.PLACEMENT.PLACEMENT_DIALOG_TITLE' | translate }}
</h1>

<div mat-dialog-content>
  <div>
    <div class="form-grid grid-cols-6">
      @if (data.case.entryType === CaseEntryType.Findeltier) {
        <div class="row">
          <div class="item col-span-6">
            <tgn-form-element [control]="form.controls.takeoverType" [hideError]="true" [id]="'isRenounceTakeover'" [label]="''" class="">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-radio-group [control]="form.controls.takeoverType" [choices]="findelTakeoverChoices" />
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
      } @else if (data.case.entryType === CaseEntryType.Pensionstier) {
        <div class="row">
          <div class="item">
            <tgn-form-element [control]="form.controls.takeoverType" [hideError]="true" [id]="'isRenounceTakeover'" [label]="''" class="">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-radio-group [control]="form.controls.takeoverType" [choices]="pensionTakeoverChoices" />
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
      }

      @if (form.controls.previousOwner.value !== null) {
        <tgn-form-element [control]="form.controls.previousOwner" [hideError]="true" [id]="'previousOwner'" class="">
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input [value]="form.controls.previousOwner.value | contactDisplay" [disabled]="true" [id]="id" matInput type="text" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      }
    </div>

    <tgn-form-element
      [control]="form.controls.newOwner"
      [hideError]="true"
      [id]="'newOwner'"
      [label]="'PAGE.CASE.PLACEMENT.LABEL.NEW_OWNER' | translate"
      class="mt-medium"
    >
      <ng-template formElement let-control="control" let-id="id">
        <tgn-choose-contact [allowNewCreation]="true" [control]="form.controls.newOwner" [id]="id"></tgn-choose-contact>
      </ng-template>
    </tgn-form-element>
  </div>
</div>

<div mat-dialog-actions>
  <div class="modal-footer-left">
    <button mat-button mat-dialog-close>
      {{ 'GENERAL.BUTTON.CANCEL' | translate }}
    </button>
    <button (click)="performTakeover()" [disabled]="form.invalid" class="tgn-button" mat-flat-button>
      {{ 'GENERAL.BUTTON.TAKEOVER' | translate }}
    </button>
  </div>
</div>
