import { AsyncPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ModalComponent } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { ImageDto } from '@file/dto/file.dto';
import { FilePipe } from '@file/pipes/file.pipe';
import { FileService } from '@file/service/file.service';
import { TranslateModule } from '@ngx-translate/core';
import { from } from 'rxjs';

export interface ImageLightboxDialogData {
  image: ImageDto;
  showDeleteButton?: boolean;
}

export interface ImageLightboxResult {
  deleted?: boolean;
}

@Component({
  selector: 'app-image-lightbox-modal',
  standalone: true,
  imports: [AsyncPipe, MatButtonModule, TranslateModule, ConfirmationDialogDirective, FilePipe],
  templateUrl: './image-lightbox-modal.component.html',
  styleUrl: './image-lightbox-modal.component.scss',
})
export class ImageLightboxModalComponent extends ModalComponent<ImageLightboxDialogData, ImageLightboxResult> {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImageLightboxDialogData,
    dialogRef: MatDialogRef<ImageLightboxModalComponent, ImageLightboxResult>,
    private fileService: FileService,
    private snackbar: SnackbarService,
  ) {
    super(dialogRef);
  }

  delete() {
    from(this.fileService.deleteFile(this.data.image)).subscribe({
      next: () => {
        this.closeWithResult({ deleted: true });
        this.snackbar.showSuccessMessage('GENERAL.ACTION_FEEDBACK.IMAGE_DELETED');
      },
      error: () => {
        this.snackbar.showErrorMessage('GENERAL.ACTION_ERRORS.COULD_NOT_DELETE_IMAGE');
      },
    });
  }
}
