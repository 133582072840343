import { trigger } from '@angular/animations';
import { AsyncPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { IconComponent } from '@core/components/icon/icon.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { AvailableIcons } from '@core/models/provided-icons';
import { TranslateModule } from '@ngx-translate/core';
import { UserBadgeComponent } from '@user/components/user-badge/user-badge.component';

@Component({
  selector: 'tgn-multi-component-select',
  standalone: true,
  imports: [
    AsyncPipe,
    ConfirmationDialogDirective,
    MatMenuModule,
    TranslateModule,
    UserBadgeComponent,
    IconComponent,
    MatSelectModule,
    ReactiveFormsModule,
    NgClass,
  ],
  templateUrl: './multi-component-select.component.html',
  styleUrl: './multi-component-select.component.scss',
})
export class MultiComponentSelectComponent<T> implements OnInit {
  @Input({ required: true }) displayValue!: string | null;
  @Input({ required: true }) defaultTitle!: string;
  @Input({ required: true }) removable = true;
  @Input({ required: true }) isValid!: boolean;

  @Input() choices?: RadioChoice<T>[];
  @Input() displayCustomComponent?: boolean;

  @Input() control?: FormControl<T | null>;
  @Input() disabled = false;

  @Output() onClear = new EventEmitter<void>();
  @Output() onTrigger = new EventEmitter<void>();
  @Input() icon?: AvailableIcons;

  //@Input({ required: true }) onClose$!: Observable<unknown>;
  @ViewChild(MatMenuTrigger) menu!: MatMenuTrigger;
  protected readonly trigger = trigger;

  ngOnInit() {
    if (this.choices && !this.control) {
      throw new Error('If select choices are present, then a control must be provided');
    }
  }

  select(choice: RadioChoice<T>) {
    this.control?.setValue(choice.object);
  }

  clearValue(event: Event) {
    event.stopPropagation();
    this.control?.setValue(null);
    this.onClear.emit();
  }
}
