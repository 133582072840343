import { CdkTrapFocus } from '@angular/cdk/a11y';
import { AsyncPipe, Location } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { GlobalSearchFilterComponent } from '@core/components/global-search/global-search-filter/global-search-filter.component';
import { GlobalSearchResultComponent } from '@core/components/global-search/global-search-result/global-search-result.component';
import { GlobalSearchAnimalFilter } from '@core/components/global-search/model/global-search-animal-filter';
import { GlobalSearchCaseFilter } from '@core/components/global-search/model/global-search-case-filter';
import { IconComponent } from '@core/components/icon/icon.component';
import { routes_config } from '@core/constants';
import { GlobalSearchResultDto } from '@core/dto/global-search-result.dto';
import { defaultDebounce } from '@core/services/base-service';
import { NumGlobalSearchResults, SearchService } from '@core/services/search.service';
import { BehaviorSubject, Observable, combineLatest, map, of, shareReplay, startWith, switchMap, tap } from 'rxjs';

const NUM_ENTRIES_PER_CATEGORY = 10;

@Component({
  selector: 'tgn-global-search',
  standalone: true,
  imports: [
    MatFormField,
    FormsModule,
    MatInput,
    ReactiveFormsModule,
    AsyncPipe,
    GlobalSearchResultComponent,
    MatSuffix,
    IconComponent,
    MatIcon,
    MatProgressSpinner,
    RouterLink,
    GlobalSearchFilterComponent,
    MatButton,
    CdkTrapFocus,
  ],
  templateUrl: './global-search.component.html',
  styleUrl: './global-search.component.scss',
})
export class GlobalSearchComponent implements OnInit {
  searchControl!: FormControl<string>;
  searchResults$: Observable<GlobalSearchResultDto | null> = of(null);

  appRoutes = routes_config;

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  animalFilter = GlobalSearchAnimalFilter.empty();
  caseFilter = GlobalSearchCaseFilter.empty();
  onFilterChange$: BehaviorSubject<void> = new BehaviorSubject<void>(void null);

  numEntries: NumGlobalSearchResults = {
    numAnimalEntries: NUM_ENTRIES_PER_CATEGORY,
    numCaseEntries: NUM_ENTRIES_PER_CATEGORY,
    numContactEntries: NUM_ENTRIES_PER_CATEGORY,
    numBillEntries: NUM_ENTRIES_PER_CATEGORY,
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly routes_config = routes_config;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private searchService: SearchService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  ngOnInit() {
    this.searchControl = this.fb.nonNullable.control('');

    this.searchControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.resetNumEntriesShown();
    });

    this.searchResults$ = combineLatest([
      this.searchControl.valueChanges.pipe(defaultDebounce(), startWith('')),
      this.onFilterChange$,
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(([query]) => query),
      tap((query: string) => {
        const url = this.location.path().split('?')[0];
        const queryParams = new URLSearchParams(`query=${query}`);
        this.location.replaceState(url, queryParams.toString());
      }),
      switchMap((query: string) => {
        return this.searchService.globalSearch(query, this.animalFilter, this.caseFilter, this.numEntries);
      }),
      shareReplay(1),
    );

    this.searchControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isLoading$.next(true);
    });

    this.searchResults$.subscribe(() => {
      this.isLoading$.next(false);
    });

    this.route.queryParams.subscribe(it => {
      const query = it['query'];
      if (query) {
        this.searchControl.setValue(query);
      }
    });
  }

  updateAnimalFilter(newFilter: GlobalSearchAnimalFilter) {
    this.animalFilter = newFilter;
    this.resetNumEntriesShown();
    this.onFilterChange$.next();
  }

  updateCaseFilter(caseFilter: GlobalSearchCaseFilter) {
    this.caseFilter = caseFilter;
    this.resetNumEntriesShown();
    this.onFilterChange$.next();
  }

  resetNumEntriesShown() {
    this.numEntries = {
      numAnimalEntries: NUM_ENTRIES_PER_CATEGORY,
      numCaseEntries: NUM_ENTRIES_PER_CATEGORY,
      numContactEntries: NUM_ENTRIES_PER_CATEGORY,
      numBillEntries: NUM_ENTRIES_PER_CATEGORY,
    };
  }

  loadMoreAnimals() {
    this.numEntries.numAnimalEntries += NUM_ENTRIES_PER_CATEGORY;
    this.onFilterChange$.next();
  }

  loadMoreCases() {
    this.numEntries.numCaseEntries += NUM_ENTRIES_PER_CATEGORY;
    this.onFilterChange$.next();
  }

  loadMoreContacts() {
    this.numEntries.numContactEntries += NUM_ENTRIES_PER_CATEGORY;
    this.onFilterChange$.next();
  }

  loadMoreBills() {
    this.numEntries.numBillEntries += NUM_ENTRIES_PER_CATEGORY;
    this.onFilterChange$.next();
  }
}
