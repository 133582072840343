import { CommonModule } from '@angular/common';
import { Component, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { AnimalContactsService } from '@animal/services/animal-contacts.service';
import { RelationKindDto } from '@baseData/dtos/relation-kind.dto';
import { CaseContactService } from '@case/services/case-contact.service';
import { AddRelatedContactDialogComponent } from '@contact/components/add-related-contact-dialog/add-related-contact-dialog.component';
import { RelatedContact, RelatedContactDialogComponent } from '@contact/components/related-contact-dialog/related-contact-dialog.component';
import { ContactId } from '@contact/dto/contact-list-view.dto';
import { AnimalRelatedContactDto } from '@contact/dto/related-contact.dto';
import { ContactRelationService } from '@contact/service/contact-relation.service';
import { ContactService } from '@contact/service/contact-service';
import { IconComponent } from '@core/components/icon/icon.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { routes_config } from '@core/constants';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { ContextItemDirective } from '@core/directives/context-item.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { notNullish } from '@core/utils/rxjs';
import { FilePipe } from '@file/pipes/file.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, filter, of, switchMap } from 'rxjs';

import { AnimalDetailStore } from '../animal-detail-layout/animal-detail-layout.component';

@Component({
  selector: 'app-animal-contacts',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
    ContextActionsDirective,
    MatTableModule,
    TypesafeMatTableModule,
    ConfirmationDialogDirective,
    FilePipe,
    MatIconModule,
    MatMenuModule,
    FullNamePipe,
    RouterLink,
    IconComponent,
    ScrollableTableComponent,
    SingleLineTextComponent,
    EnumDisplayPipe,
    RoleRestrictionDirective,
    ContextItemDirective,
  ],
  templateUrl: './animal-contacts.component.html',
  styleUrl: './animal-contacts.component.scss',
})
export class AnimalContactsComponent {
  animal: Signal<AnimalDto | undefined>;
  contacts: Signal<AnimalRelatedContactDto[] | undefined>;
  readonly columns = [
    'personNumber',
    'status',
    'firstName',
    'lastName',
    'organization',
    'kind',
    'fromToDate',
    'note',
    'date',
    'caseNumber',
    'caseState',
    'actions',
  ];

  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  private refresh = new BehaviorSubject<void>(void 0);

  constructor(
    protected animalDetailStore: AnimalDetailStore,
    private animalContactsService: AnimalContactsService,
    private contactsService: ContactService,
    private modalService: ModalService,
    private contactRelationService: ContactRelationService,
    private caseContactService: CaseContactService,
  ) {
    this.animal = toSignal(animalDetailStore.animal$);
    this.contacts = toSignal(
      combineLatest([animalDetailStore.animal$, this.refresh]).pipe(
        switchMap(([animal]) => {
          return animalContactsService.getAnimalContacts(animal.id);
        }),
      ),
    );
  }

  openNewContactDialog(animal: AnimalDto) {
    this.modalService
      .open(AddRelatedContactDialogComponent, {}, { width: ModalWidth.Medium })
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe(result => {
        if (result.type === 'create') {
          this.createNewContact(animal);
        } else {
          this.relateExistingContact(animal, result.contactId, result.kind, result.fromDate, result.toDate);
        }
        this.refresh.next();
      });
  }

  relateExistingContact(animal: AnimalDto, contactId: ContactId, kind: RelationKindDto, fromDate: string | null, toDate: string | null) {
    this.animalContactsService
      .relateContact(animal.id, {
        contactId,
        kind,
        fromDate: fromDate,
        toDate: toDate,
      })
      .subscribe(() => {
        this.refresh.next();
      });
  }

  createNewContact(animal: AnimalDto) {
    this.modalService
      .open(RelatedContactDialogComponent, {}, { width: ModalWidth.Large })
      .afterClosed()
      .pipe(
        filter(notNullish),
        switchMap(result => {
          const { dto } = result;

          return this.contactsService.create(dto).pipe(
            switchMap(contact =>
              this.animalContactsService.relateContact(animal.id, {
                contactId: contact.id,
                kind: dto.kind,
                fromDate: dto.fromDate,
                toDate: dto.toDate,
              }),
            ),
          );
        }),
      )
      .subscribe(() => {
        this.refresh.next();
      });
  }

  editContact(animal: AnimalDto, contact: AnimalRelatedContactDto) {
    const relatedContact: RelatedContact = {
      ...contact.contact,
      kind: contact.kind,
      fromDate: contact.fromDate,
      toDate: contact.toDate,
    };

    this.modalService
      .open(
        RelatedContactDialogComponent,
        {
          contact: relatedContact,
          disabled: animal.archived,
        },
        { width: ModalWidth.Large },
      )
      .afterClosed()
      .pipe(
        filter(notNullish),
        switchMap(result => {
          const { dto } = result;

          return this.contactsService.update(contact.contact.id, dto).pipe(
            switchMap(updatedContact => {
              if (!contact.caseId) {
                return of(null);
              }
              return this.caseContactService.updateRelation(contact.caseId, updatedContact.id, {
                kind: dto.kind,
                fromDate: dto.fromDate,
                toDate: dto.toDate,
              });
            }),
          );
        }),
      )
      .subscribe(() => {
        this.refresh.next();
      });
  }

  deleteContact(animalRelatedContactDto: AnimalRelatedContactDto) {
    this.contactRelationService
      .deleteContactRelation(animalRelatedContactDto.contact.id, animalRelatedContactDto.relationId)
      .subscribe(() => {
        this.refresh.next();
      });
  }

  editContactWithRel(rel: AnimalRelatedContactDto) {
    const animal = this.animal();
    if (animal) {
      this.editContact(animal, rel);
    }
  }
}
