import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { ContextActionsPortalService } from '../../services/context-actions-portal.service';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'tgn-header',
  standalone: true,
  imports: [BreadcrumbsComponent, PortalModule, CommonModule, MatButtonModule, IconComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input({ required: true }) isMobileViewPort = false;

  @Output() toggleMobileMenu = new EventEmitter<void>();

  constructor(readonly contextActionsPortal: ContextActionsPortalService) {}
}
