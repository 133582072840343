@if (currentAnimalContext$ | async; as context) {
  <app-entry-check
    [currentAnimalContext$]="currentAnimalContext$"
    [resetStepperIndex$]="resetStepper$"
    [showPreviousInFirstStep]="true"
    [firstPreviousLabel]="isFirstAnimal(context) ? 'GENERAL.BUTTON.PREVIOUS' : 'PAGE.ENTRY_CHECK.BUTTON.PREVIOUS_ANIMAL'"
    [lastActionLabel]="isLastAnimal(context) ? 'GENERAL.BUTTON.FINISH' : 'PAGE.ENTRY_CHECK.BUTTON.CHECK_IN_NEXT_ANIMAL'"
    (onFirstPreviousAction)="previousAnimal($event)"
    (onLastAction)="nextAnimalOrFinish($event)"
  />
}
