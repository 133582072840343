import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

import { DashboardStatisticsDto, FetchStatisticsDto, StatisticsDto } from '../dto/statistics.dto';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getStatistics(dto: FetchStatisticsDto): Observable<StatisticsDto> {
    const params = statisticsDtoToParams(dto);
    return this.http.get<StatisticsDto>(`${this.apiUrl}/statistics`, { params: params });
  }

  getDashboardStatistics(): Observable<DashboardStatisticsDto> {
    return this.http.get<DashboardStatisticsDto>(`${this.apiUrl}/statistics/dashboard`);
  }

  exportStatistics(dto: FetchStatisticsDto): Observable<HttpResponse<Blob>> {
    const params = statisticsDtoToParams(dto);
    return this.http.get(`${this.apiUrl}/statistics/export`, { params, responseType: 'blob', observe: 'response' });
  }
}

function statisticsDtoToParams(dto: FetchStatisticsDto): HttpParams {
  let params = new HttpParams();
  if (dto.filter) {
    params = params.set('filter', dto.filter);
  }
  if (dto.startDate) {
    params = params.set('startDate', dto.startDate);
  }
  if (dto.endDate) {
    params = params.set('endDate', dto.endDate);
  }
  if (dto.day) {
    params = params.set('day', dto.day);
  }
  if (dto.month) {
    params = params.set('month', dto.month);
  }
  if (dto.year) {
    params = params.set('year', dto.year);
  }
  return params;
}
