import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchResultDto } from '@core/components/autocomplete/dto/search-result.dto';
import { PageResult } from '@core/data/tigon-datasource';
import { BaseService } from '@core/services/base-service';
import { PaginatedParams } from '@core/utils/pagination';
import { Observable, map, of } from 'rxjs';

import { ContactAnimalDto } from '../dto/contact-animal.dto';
import { ContactCaseDto } from '../dto/contact-case.dto';
import { ContactId, ContactListViewDto } from '../dto/contact-list-view.dto';
import { ContactDto } from '../dto/contact.dto';
import { CreateContactDto } from '../dto/create-contact.dto';
import { UpdateContactDetailDto } from '../dto/update-contact-detail.dto';

type ContactPaginatedParams = Omit<PaginatedParams, 'sort'> & {
  filter: ContactFilter | null;
};

type ContactAnimalsPaginatedParams = Omit<PaginatedParams, 'sort' | 'query'>;
type ContactCasesPaginatedParams = Omit<PaginatedParams, 'sort' | 'query'>;

export enum ContactFilter {
  All = 'All',
  Finder = 'Finder',
  Prospect = 'Prospect',
  Owner = 'Owner',
}

@Injectable({
  providedIn: 'root',
})
export class ContactService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id: ContactId): Observable<ContactDto> {
    return this.http.get<ContactDto>(`${this.apiUrl}/contacts/${id}`);
  }

  create(dto: CreateContactDto): Observable<ContactDto> {
    return this.http.post<ContactDto>(`${this.apiUrl}/contacts`, dto);
  }

  update(id: ContactId, dto: UpdateContactDetailDto): Observable<ContactDto> {
    return this.http.put<ContactDto>(`${this.apiUrl}/contacts/${id}`, dto);
  }

  searchContacts(query: string): Observable<ContactDto[]> {
    if (query === '') {
      return of([]);
    }
    let params: HttpParams = new HttpParams();
    params = params.set('query', query);
    return this.http.get<SearchResultDto<ContactDto>>(`${this.apiUrl}/contacts/search`, { params: params }).pipe(
      map((searchResult: SearchResultDto<ContactDto>) => {
        return searchResult.data;
      }),
    );
  }

  getContactsPaginated(params: ContactPaginatedParams): Observable<PageResult<ContactListViewDto>> {
    const flattenedParams = {
      ...params,
      filter: (params.filter ?? ContactFilter.All).toString(),
    };

    return this.http.get<PageResult<ContactListViewDto>>(`${this.apiUrl}/contacts/paginated`, { params: flattenedParams });
  }

  getArchivedContactsPaginated(params: ContactPaginatedParams): Observable<PageResult<ContactListViewDto>> {
    const flattenedParams = {
      ...params,
      filter: (params.filter ?? ContactFilter.All).toString(),
    };

    return this.http.get<PageResult<ContactListViewDto>>(`${this.apiUrl}/contacts/archived/paginated`, { params: flattenedParams });
  }

  getContactAnimalsPaginated(contactDto: ContactDto, params: ContactAnimalsPaginatedParams): Observable<PageResult<ContactAnimalDto>> {
    return this.http.get<PageResult<ContactAnimalDto>>(`${this.apiUrl}/contacts/${contactDto.id}/animals`, { params: params });
  }

  getContactCasesPaginated(contactDto: ContactDto, params: ContactCasesPaginatedParams): Observable<PageResult<ContactCaseDto>> {
    return this.http.get<PageResult<ContactCaseDto>>(`${this.apiUrl}/contacts/${contactDto.id}/cases`, { params: params });
  }

  getTrdContact(): Observable<ContactDto> {
    return this.http.get<ContactDto>(`${this.apiUrl}/contacts/trd-contact`);
  }

  downloadContactsCsv(includeNoMailings: boolean): Observable<HttpResponse<Blob>> {
    let params: HttpParams = new HttpParams();
    params = params.set('includeNoMailings', includeNoMailings);
    return this.http.get(`${this.apiUrl}/contacts/export`, {
      responseType: 'blob',
      observe: 'response',
      params: params,
    });
  }

  archiveContact(contactId: ContactId): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/contacts/${contactId}/archive`, {});
  }

  unarchiveContact(contactId: ContactId): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/contacts/${contactId}/unarchive`, {});
  }
}
