import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { ModalComponent } from '@core/services/modal.service';
import { NgIcon } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';

export interface DialogData {
  title: string;
  description?: string;
  objectName?: string;
  noButtonLabel?: string;
  yesButtonLabel?: string;
  closeButton?: boolean;
}

export interface DialogResponse {
  ok: boolean;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogContent, MatDialogActions, TranslateModule, NgIcon, MatDialogTitle, MatButtonModule],
})
export class ConfirmationDialogComponent extends ModalComponent<DialogData, DialogResponse> {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    dialogRef: MatDialogRef<ConfirmationDialogComponent, DialogResponse>,
  ) {
    super(dialogRef);
  }

  onClose() {
    this.closeWithResult();
  }

  onClick(ok: boolean) {
    this.closeWithResult({ ok });
  }
}
