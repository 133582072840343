import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { CompactAnimalPlacementComponent } from '@animal/components/compact-animal-placement/compact-animal-placement.component';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { RelationKindDto, RelationKindProspect, isProspect } from '@baseData/dtos/relation-kind.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { CaseContactService } from '@case/services/case-contact.service';
import { CaseService } from '@case/services/case.service';
import { RelateContactDialogService } from '@contact/components/add-related-contact-dialog/add-related-contact-dialog.component';
import { RelatedContact, RelatedContactDialogComponent } from '@contact/components/related-contact-dialog/related-contact-dialog.component';
import { ContactId } from '@contact/dto/contact-list-view.dto';
import { ContactDto } from '@contact/dto/contact.dto';
import { CaseRelatedContactDto, ContactRelationId } from '@contact/dto/related-contact.dto';
import { ContactService } from '@contact/service/contact-service';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { ContextItemDirective } from '@core/directives/context-item.directive';
import { TypeSafeMatCellDef, TypeSafeMatRowDef } from '@core/directives/mat-table.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AndRoleRestrictionPipe, RESTRICT_CASE_WRITE } from '@core/models/role';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { openDownloadedBlobPdf } from '@core/utils/helplers';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ProspectAnimalDto, ProspectDto } from '@prospect/dto/prospect.dto';
import { CaseProspectsService } from '@prospect/services/case-prospects.service';
import { Observable, debounceTime, filter, map, skip, switchMap, take, tap } from 'rxjs';

import { CaseDetailStore } from '../case-layout/case-layout.component';

@Component({
  selector: 'app-case-placement',
  standalone: true,
  imports: [
    MatButtonModule,
    ContextActionsDirective,
    ConfirmationDialogDirective,
    DatePipe,
    MatMenuModule,
    MatTableModule,
    TranslateModule,
    TypeSafeMatCellDef,
    NgIf,
    TypeSafeMatRowDef,
    RouterLink,
    MatButtonModule,
    MatIconModule,
    AsyncPipe,
    CompactAnimalPlacementComponent,
    NarrowPageContainerComponent,
    RoleRestrictionDirective,
    AndRoleRestrictionPipe,
    ContextItemDirective,
  ],
  templateUrl: './case-placement.component.html',
  styleUrl: './case-placement.component.scss',
})
export class CasePlacementComponent implements OnInit {
  datasource?: PagedTigonDatasource<ProspectDto, {}>;
  case?: CaseDetailDto;
  animals$?: Observable<AnimalDto[]>;

  query = signal('');

  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;

  totalPages = 1;
  totalItems = 0;

  protected readonly columns = ['firstName', 'lastName', 'animals', 'relationKind', 'date', 'state', 'actions'];
  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RESTRICT_CASE_WRITE = RESTRICT_CASE_WRITE;

  constructor(
    protected caseDetailStore: CaseDetailStore,
    private modalService: ModalService,
    private relateContactDialogService: RelateContactDialogService,
    private caseContactService: CaseContactService,
    private contactService: ContactService,
    private prospectService: CaseProspectsService,
    private caseService: CaseService,
    private baseDataService: BaseDataService,
  ) {
    this.caseDetailStore.case$.pipe(takeUntilDestroyed()).subscribe(caseDto => {
      this.case = caseDto;
      this.datasource = new PagedTigonDatasource<ProspectDto, {}>({}, () => {
        return this.prospectService.getProspectsPaginated(caseDto.id);
      });

      toObservable(this.query)
        .pipe(skip(1), debounceTime(300), takeUntilDestroyed())
        .subscribe(query => {
          this.datasource?.update({ query });
        });

      this.datasource.page$.pipe(takeUntilDestroyed()).subscribe(page => {
        this.totalPages = page.totalPages;
        this.totalItems = page.totalItems;
        this.pageSize = page.pageSize;
        this.pageIndex = page.pageIndex;
      });
    });
  }

  ngOnInit() {
    this.animals$ = this.caseDetailStore.case$.pipe(
      tap((caseDto: CaseDetailDto) => {
        this.case = caseDto;
      }),
      switchMap((caseDto: CaseDetailDto) => {
        return this.caseService.getAnimals(caseDto.id);
      }),
    );
  }

  openAddProspectDialog() {
    this.baseDataService
      .getBaseData()
      .pipe(
        map(baseData => {
          return baseData.relationKinds.find(isProspect)!;
        }),
        take(1),
        switchMap((prospectKind: RelationKindProspect) => {
          return this.relateContactDialogService.openCreateOrRelateContactDialog({
            customTitle: 'PAGE.CASE.DETAIL.PLACEMENT.ADD_PROSPECT',
            preselectedKind: prospectKind,
          });
        }),
        switchMap(result => {
          if (result.type === 'create') {
            return this.createNewContact(this.case!);
          } else {
            return this.relateExistingContact(this.case!, result.contactId, result.kind, result.fromDate, result.toDate);
          }
        }),
        switchMap((contact: CaseRelatedContactDto) => {
          return this.createProspect(contact.contact, contact.relationId);
        }),
      )
      .subscribe(() => {
        this.refresh();
      });
  }

  getAnimalNames(prospect: ProspectDto): string {
    return prospect.animals.map(animal => animal.name!).join(', ');
  }

  downloadContract(event: MouseEvent, prospect: ProspectDto, animal: ProspectAnimalDto) {
    if (event.ctrlKey || event.metaKey) {
      window.open(this.prospectService.getProspectContractPreviewUrl(prospect.id, this.case!.id, animal.id));
      return;
    }

    this.prospectService.getProspectContract(prospect.id, this.case!.id, animal.id).subscribe(response => {
      openDownloadedBlobPdf(response, 'contract.pdf');
    });
  }

  private createNewContact(caseDto: CaseDetailDto): Observable<CaseRelatedContactDto> {
    return this.baseDataService.getBaseData().pipe(
      map(baseData => {
        return baseData.relationKinds.find(isProspect)!;
      }),
      switchMap((prospectKind: RelationKindProspect) => {
        return this.modalService
          .open(RelatedContactDialogComponent, { preselectedKind: prospectKind }, { width: ModalWidth.Large })
          .afterClosed()
          .pipe(
            filter(notNullish),
            switchMap(result => {
              const dto: RelatedContact = result.dto;
              return this.contactService.create(dto).pipe(
                switchMap(contact =>
                  this.caseContactService.relateContact(caseDto.id, {
                    contactId: contact.id,
                    kind: dto.kind,
                    fromDate: dto.fromDate,
                    toDate: dto.toDate,
                  }),
                ),
              );
            }),
          );
      }),
    );
  }

  private relateExistingContact(
    caseDto: CaseDetailDto,
    contactId: ContactId,
    kind: RelationKindDto,
    fromDate: string | null,
    toDate: string | null,
  ) {
    return this.caseContactService.relateContact(caseDto.id, {
      contactId,
      kind,
      fromDate: fromDate,
      toDate: toDate,
    });
  }

  private createProspect(contact: ContactDto, contactRelationId: ContactRelationId) {
    return this.prospectService.createProspect(this.case!.id, contact.id, contactRelationId);
  }

  private refresh() {
    this.datasource?.update();
  }
}
