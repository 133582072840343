import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatMenuItem, MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { AnimalExitDialogComponent } from '@animal/components/animal-exit-dialog/animal-exit-dialog.component';
import { AnimalFilterComponent } from '@animal/components/animal-filter/animal-filter.component';
import { AnimalFilter, GeneralAnimalFilterChoice } from '@animal/components/animal-filter/model/animal-filter';
import { AnimalListViewDto } from '@animal/dtos/animal-list-view.dto';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { WithDefaultAnimalNamePipe } from '@animal/pipes/with-default-animal-name.pipe';
import { AnimalService } from '@animal/services/animal.service';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { FilterOption, OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { WildAnimalIconComponent } from '@core/components/wild-animal-icon/wild-animal-icon.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AnimalState, AnimalType, UserRole } from '@core/models/general';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { NotInPipe } from '@core/pipes/not-in.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { createEnumChoices } from '@core/utils/helplers';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, debounceTime, filter, map, of, skip } from 'rxjs';

interface TableConfig {
  query: string;
  pageIndex: number;
  pageSize: number;
  filter: AnimalFilter;
  generalAnimalStateFilter: GeneralAnimalFilterChoice;
}

@Component({
  selector: 'tgn-archive-animals',
  standalone: true,
  imports: [
    MatTableModule,
    TypesafeMatTableModule,
    TranslateModule,
    AvatarImageComponent,
    WithDefaultAnimalNamePipe,
    WildAnimalIconComponent,
    SingleLineTextComponent,
    AsyncPipe,
    ScrollableTableComponent,
    AnimalFilterComponent,
    EnumDisplayPipe,
    RouterLink,
    NotInPipe,
    RoleRestrictionDirective,
    MatMenuItem,
    PaginatorComponent,
    MatMenuModule,
    MatIcon,
    MatIconButton,
    DatePipe,
    FormsModule,
    MatFormField,
    OptionButtonComponent,
    MatInput,
    ConfirmationDialogDirective,
  ],
  templateUrl: './archive-animals.component.html',
  styleUrl: './archive-animals.component.scss',
})
export class ArchiveAnimalsComponent {
  datasource: PagedTigonDatasource<AnimalListViewDto, TableConfig>;
  filter = AnimalFilter.empty();
  query = signal<string>('');
  generalStateFilter = signal<GeneralAnimalFilterChoice>(GeneralAnimalFilterChoice.All);
  animalButtonFilterChoices: FilterOption<GeneralAnimalFilterChoice>[] = createEnumChoices<GeneralAnimalFilterChoice>(
    GeneralAnimalFilterChoice,
    'GENERAL.DOMAIN.GeneralAnimalFilterChoice.',
  ).map((choice: RadioChoice<GeneralAnimalFilterChoice>) => {
    const filterOption: FilterOption<GeneralAnimalFilterChoice> = {
      value: choice.object,
      label: choice.label,
    };
    return filterOption;
  });

  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  totalPages = 1;
  totalItems = 0;
  columnsGeneral = [
    'avatarImage',
    'name',
    'trdId',
    'group',
    'subGroup',
    'species',
    'race',
    'box',
    'status',
    'entryType',
    'sex',
    'birthdate',
    'coloring',
    'entryDate',
    'actions',
  ];

  columns$: Observable<string[]> = of(this.columnsGeneral);
  columnsPlacement = [
    'avatarImage',
    'name',
    'trdId',
    'species',
    'box',
    'placementType',
    'placementState',
    'placementNote',
    'behaviour',
    'specials',
    'furLength',
    'entryType',
    'sex',
    'birthdate',
    'coloring',
    'entryDate',
    'actions',
  ];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly routes_config = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalState = AnimalState;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private animalService: AnimalService,
    private snackbar: SnackbarService,
    private router: Router,
    private modalService: ModalService,
  ) {
    this.datasource = new PagedTigonDatasource<AnimalListViewDto, TableConfig>(
      {
        query: '',
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        filter: this.filter,
        generalAnimalStateFilter: this.generalStateFilter(),
      },
      params => {
        return this.animalService.getArchivedAnimalsPaginated(
          {
            query: params.query,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            filter: this.filter,
          },
          params.generalAnimalStateFilter,
          null,
        );
      },
    );

    toObservable(this.query)
      .pipe(skip(1), debounceTime(300), takeUntilDestroyed())
      .subscribe(query => {
        this.datasource.update({ query });
      });

    this.datasource.page$.pipe(takeUntilDestroyed()).subscribe(page => {
      this.totalPages = page.totalPages;
      this.totalItems = page.totalItems;
      this.pageSize = page.pageSize;
      this.pageIndex = page.pageIndex;
    });

    this.columns$ = toObservable(this.generalStateFilter).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((state: GeneralAnimalFilterChoice) => {
        if (state === GeneralAnimalFilterChoice.Placeable) {
          return this.columnsPlacement;
        } else {
          return this.columnsGeneral;
        }
      }),
    );
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.datasource.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }

  updateAnimalFilter(animalFilter: AnimalFilter) {
    this.filter = animalFilter;
    this.datasource.update({ filter: animalFilter, pageIndex: 0 });
  }

  openLastEntryCheck(animal: AnimalDto) {
    this.animalService.getLatestCase(animal.id).subscribe(latestCase => {
      if (animal.type === AnimalType.DomesticAnimal) {
        this.router.navigate(routes_config.DOMESTIC_ENTRYCHECK_EDIT.url(latestCase.id, animal.id));
      } else {
        this.router.navigate(routes_config.WILD_ENTRYCHECK_EDIT.url(latestCase.id, animal.id));
      }
    });
  }

  openLastExitCheck(animal: AnimalDto) {
    this.animalService.getLatestCase(animal.id).subscribe(latestCase => {
      const route = this.appRoutes.ANIMAL_EXITCHECK.url(latestCase.id, animal.id);
      this.router.navigate(route);
    });
  }

  openExitAnimalDialog(animal: AnimalListViewDto) {
    this.animalService.getLatestCase(animal.id).subscribe(latestCase => {
      this.modalService
        .open(AnimalExitDialogComponent, { animal: animal, case: latestCase }, { width: ModalWidth.Medium })
        .afterClosed()
        .pipe(filter(notNullish))
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .subscribe(() => {
          this.datasource.refresh();
        });
    });
  }

  openCasePlacement(animal: AnimalListViewDto) {
    this.animalService.getLatestCase(animal.id).subscribe(latestCase => {
      this.router.navigate(routes_config.CASE_DETAIL_PLACEMENT.url(latestCase.id));
    });
  }

  unarchiveAnimal(animal: AnimalDto) {
    this.animalService.unarchiveAnimal(animal.id).subscribe(() => {
      this.snackbar.showSuccessMessage('Archivierung wurde aufgehoben');
      this.datasource.refresh();
    });
  }
}
