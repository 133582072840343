import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { ListIncludesPipe } from '@core/pipes/list-includes.pipe';
import { TranslateModule } from '@ngx-translate/core';

interface WithOrWithoutId {
  id?: string;
}

@Component({
  selector: 'tgn-checkbox-group',
  standalone: true,
  imports: [MatCheckboxModule, TranslateModule, ListIncludesPipe],
  templateUrl: './checkbox-group.component.html',
  styleUrl: './checkbox-group.component.scss',
})
export class CheckboxGroupComponent<T> {
  @Input({ required: true }) choices!: RadioChoice<T>[];
  @Input({ required: true }) control!: FormControl<T[]>;
  @Input({ required: true }) compareIds = false;

  compareFn(list: T[], value: T, compareById: boolean): boolean {
    if (compareById) {
      return list.some((current: T) => (current as WithOrWithoutId).id === (value as WithOrWithoutId).id);
    }
    return list.includes(value);
  }

  updateCheckboxChoice(event: MatCheckboxChange, choice: RadioChoice<T>) {
    let values = this.control.value;
    if (event.checked) {
      values.push(choice.object);
    } else {
      values = values.filter((current: T) => {
        if (this.compareIds) {
          return (current as WithOrWithoutId).id !== (choice.object as WithOrWithoutId).id;
        }
        return current !== choice.object;
      });
    }
    this.control.setValue(values);
  }
}
