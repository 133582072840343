import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DownloadableFileDto } from '../dto/file.dto';

@Directive({
  selector: '[fileDownload]',
  standalone: true,
})
export class FileDownloadDirective {
  @Input({ required: true }) file!: DownloadableFileDto;
  @Input() downloadFile = false; // Download instead of navigating to the resolved file.

  constructor(
    private http: HttpClient,
    private el: ElementRef,
  ) {}

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    // Download the blob on first click and set it to the href prop, then trigger a click again.
    if (!this.el.nativeElement.href.startsWith('blob')) {
      event.preventDefault();
      this.setBlobAsHref().then(() => {
        this.el.nativeElement.click();
      });
    }
  }

  private async setBlobAsHref() {
    const request = this.http.get(`${environment.apiUrl}${this.file.url}`, { responseType: 'blob' });
    const blob = await firstValueFrom(request);
    this.el.nativeElement.href = URL.createObjectURL(blob);
    if (this.downloadFile) {
      this.el.nativeElement.download = this.file.filename;
    }
  }
}
