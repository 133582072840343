import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContextActionsPortalService {
  private portals: TemplatePortal[] = [];

  pushPortal(portal: TemplatePortal) {
    requestAnimationFrame(() => {
      this.portals.push(portal);
    });
  }

  popPortal() {
    requestAnimationFrame(() => {
      this.portals.pop();
    });
  }

  get currentPortal(): TemplatePortal | null {
    return this.portals[this.portals.length - 1];
  }
}
