@if (case$ | async; as caseDetailDto) {
  <ng-container>
    <div class="header">
      @if (caseDetailDto.archived) {
        <mat-chip class="custom-chip">Archiviert</mat-chip>
      }
      <h1 class="case-title">
        <tgn-single-line-text
          [text]="(caseDetailDto.title?.length ?? -1) > 0 ? caseDetailDto.title ?? '' : 'Fall '"
          class="max-title-name"
        />
        <span class="title-case-number">- Nr: {{ caseDetailDto.caseNumber }}</span>
      </h1>
      @if (statusForm) {
        <tgn-select [choices]="caseStates" [control]="statusForm.controls.status" variant="badge" />
      }
    </div>
  </ng-container>

  <tgn-nav-tabs [(activeTab)]="selectedTab" [tabs]="tabs"></tgn-nav-tabs>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
}
