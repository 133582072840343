<div *ngIf="!hidden" class="pagination-container">
  <div class="page-size-container">
    <span>{{ 'PAGINATION.ITEMS_PER_PAGE' | translate }}</span>
    <div class="page-select-container">
      <mat-form-field appearance="outline" class="pageSelect">
        <mat-select (selectionChange)="updatePageSize($event)" [formControl]="selectionFormControl">
          @for (option of pageSizeChoices; track option) {
            <mat-option [value]="option">
              <span>{{ option }}</span>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="right-side">
    <button
      (click)="previous()"
      [disabled]="pageSize == 0 || pageIndex == 0"
      [ngClass]="{ hidden: pageSize == 0 || pageIndex == 0 }"
      class="tgn-icon-button"
      mat-flat-button
    >
      <tgn-icon iconName="radixChevronLeft" />
      {{ 'PAGINATION.PREVIOUS' | translate }}
    </button>
    <span>{{ 'PAGINATION.PAGE_OF' | translate: { pageFrom: pageIndex + 1, pageTo: maxPages } }}</span>
    <button
      (click)="next()"
      [disabled]="maxPages <= 1 || pageIndex === maxPages - 1"
      [ngClass]="{ hidden: maxPages <= 1 || pageIndex === maxPages - 1 }"
      class="tgn-button tgn-icon-button"
      mat-flat-button
    >
      {{ 'PAGINATION.NEXT' | translate }}
      <tgn-icon iconName="radixChevronRight" />
    </button>
  </div>
</div>
