import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { BaseDataDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { ButtonUploadComponent } from '@core/components/button-upload/button-upload.component';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { EditorComponent } from '@core/components/editor/editor.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { MultiComponentSelectComponent } from '@core/components/multi-component-select/multi-component-select.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { TimeInputComponent } from '@core/components/time-input/time-input.component';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { FileListComponent } from '@file/component/file-list/file-list.component';
import { FileCollectionDto } from '@file/dto/file-collection.dto';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';

import { RepetitionUnit, TaskCategoryDto, TaskCategoryGroup, TaskDto, TaskEntityType, TaskStatus } from '../../dtos/task.dto';
import { RepetitionDisplayPipe } from '../../pipes/repetition-display.pipe';
import { RepetitionPickerComponent } from '../repetition-picker/repetition-picker.component';
import { TaskSettingComponent } from '../task-setting/task-setting.component';

export interface TaskForm {
  title: FormControl<string>;
  description: FormControl<string | null>;
  report: FormControl<string | null>;
  status: FormControl<TaskStatus>;
  taskCategory: FormControl<TaskCategoryDto | null>;
  repetition: FormGroup<{
    interval: FormControl<number | null>;
    unit: FormControl<RepetitionUnit | null>;
  }>;
  terminationDate: FormControl<IsoLocalDateString | null>;
  terminationTime: FormControl<string | null>;
}

@Component({
  selector: 'tgn-task-form',
  standalone: true,
  imports: [
    DateInputComponent,
    DatePipe,
    EnumDisplayPipe,
    RepetitionDisplayPipe,
    RepetitionPickerComponent,
    SelectComponent,
    TaskSettingComponent,
    TimeInputComponent,
    ToRadioChoicePipe,
    ReactiveFormsModule,
    EditorComponent,
    FormElementComponent,
    TranslateModule,
    FormElementDirective,
    ButtonUploadComponent,
    MatExpansionModule,
    MultiComponentSelectComponent,
    CollapsibleComponent,
    AsyncPipe,
    FileListComponent,
    FullNamePipe,
    AddIfMissingPipe,
  ],
  templateUrl: './task-form.component.html',
  styleUrl: './task-form.component.scss',
})
export class TaskFormComponent implements OnInit {
  @Input({ required: true }) form!: FormGroup<TaskForm>;
  @Input({ required: true }) fileCollection!: FileCollectionDto;
  @Input({ required: true }) isCreate!: boolean;
  @Input({ required: true }) taskHistory!: TaskDto[];
  @Input() lastHistoryTaskReportControl?: FormControl<string | null>;
  @Input({ required: true }) taskTypeEntity!: TaskEntityType | null;
  @Input({ required: true }) triggerRefreshFiles!: Observable<void>;
  @Input({ required: true }) existingTask!: TaskDto | null;
  @Input({ required: true }) taskCategoryGroups!: TaskCategoryGroup[];

  taskCategories$!: Observable<TaskCategoryDto[]>;

  statusChoices: RadioChoice<TaskStatus>[] = createEnumChoices(TaskStatus, 'GENERAL.DOMAIN.TaskStatus.');
  showFilesCollapsible!: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly TaskStatus = TaskStatus;
  private readonly destroyRef = inject(DestroyRef);

  constructor(private baseDataService: BaseDataService) {}

  ngOnInit() {
    this.taskCategories$ = this.baseDataService.getBaseData().pipe(
      map((baseData: BaseDataDto) => {
        return baseData.taskCategories.filter(it => this.taskCategoryGroups.includes(it.group));
      }),
    );

    this.showFilesCollapsible = this.fileCollection.numFiles > 0;
    if (this.triggerRefreshFiles) {
      this.triggerRefreshFiles.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.showFilesCollapsible = true;
      });
    }
  }

  clearRepetitions() {
    this.form.controls.repetition.controls.interval.setValue(null);
    this.form.controls.repetition.controls.unit.setValue(null);
  }

  setDefaultRepetitionIfEmpty() {
    const formValues = this.form.getRawValue();
    if (formValues.repetition.interval === null || formValues.repetition.unit === null) {
      this.form.controls.repetition.controls.interval.setValue(1);
      this.form.controls.repetition.controls.unit.setValue(RepetitionUnit.Daily);
    }
  }
}
