import { DestroyRef, Directive, ElementRef, Input, OnInit, Renderer2, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserRole } from '@core/models/general';
import { CurrentUserService } from '@user/service/current-user.service';

@Directive({
  selector: '[tgnExcludeRole]',
  standalone: true,
})
export class ExcludeRoleDirective implements OnInit {
  @Input({ alias: 'tgnExcludeRole', required: true }) role: UserRole | undefined;

  destroyRef = inject(DestroyRef);

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private userService: CurrentUserService,
  ) {}

  ngOnInit() {
    this.userService.currentUser$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
      if (this.role && user.roles.some(it => it === this.role)) {
        this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
      }
    });
  }
}
