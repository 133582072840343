import { NgClass } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { ViewStore, ViewStoreQuery, unwrapState } from '@core/components/autocomplete/model/loadable';
import { IconComponent } from '@core/components/icon/icon.component';
import { defaultDebounce } from '@core/services/base-service';
import { TranslateModule } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs';

export interface AutocompleteOption<T> {
  label: string;
  value: T;
}

@Component({
  selector: 'tgn-autocomplete',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    NgClass,
    IconComponent,
    MatButtonModule,
  ],
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss',
})
export class AutocompleteComponent<TData> implements OnInit, OnChanges {
  @Input() placeholder = '';
  @Input({ required: true }) viewStore!: ViewStore<TData[], ViewStoreQuery>;
  @Input() locked = false;
  @Input() id?: string;
  @Input() value: string | null = '';
  @Input() additionalAction?: string = undefined;
  @Input() allowRemoval = true;
  @Input() disabled = false;
  @Output() onOptionSelected: EventEmitter<TData> = new EventEmitter();
  @Output() onAdditionalActionSelected: EventEmitter<void> = new EventEmitter();
  @Output() onRemoveSelected: EventEmitter<void> = new EventEmitter();
  @Output() onChange: EventEmitter<string | null> = new EventEmitter();

  options: AutocompleteOption<TData>[] = [];
  @Input({ required: true }) displayFn!: (v: TData) => string;
  internalControl: FormControl<AutocompleteOption<TData> | string | null> = new FormControl(null);
  readonly additionalActionGroup: string = 'additionalAction';
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.viewStore.data$.pipe(takeUntilDestroyed(this.destroyRef), unwrapState()).subscribe((value: TData[]) => {
      this.options = value.map((current: TData) => {
        return {
          label: this.displayFn(current),
          value: current,
        };
      });
    });

    this.internalControl.valueChanges
      .pipe(defaultDebounce(), takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe((value: AutocompleteOption<TData> | string | null) => {
        if (typeof value == 'string' || value === null) {
          this.onChange.emit(value);
          if (value != null) {
            this.viewStore.update({ query: value ?? undefined });
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const valueChange: SimpleChange = changes['value'];
    if (valueChange !== undefined && valueChange.currentValue != valueChange.previousValue) {
      this.internalControl.setValue(valueChange.currentValue);
    }

    const lockedChange: SimpleChange = changes['locked'];
    if (lockedChange !== undefined && lockedChange.currentValue != lockedChange.previousValue) {
      if (lockedChange.currentValue) {
        this.internalControl.disable();
      } else {
        this.internalControl.enable();
      }
    }

    const disabledChange: SimpleChange = changes['disabled'];
    if (disabledChange !== undefined && disabledChange.currentValue != disabledChange.previousValue) {
      if (disabledChange.currentValue) {
        this.internalControl.disable();
      } else {
        this.internalControl.enable();
      }
    }
  }

  @Input() valueFn: (v: TData) => unknown = (v: TData) => v;

  displayInputValue(option: AutocompleteOption<TData> | string): string {
    if (typeof option == 'string') {
      return option;
    }
    return (option?.value as AnimalDto)?.chipId ?? '';
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    if (event.option.group?.label == this.additionalActionGroup) {
      this.onAdditionalActionSelected.emit();
    } else {
      const value = event.option.value?.value;
      this.onOptionSelected.emit(value);
    }
  }
}
