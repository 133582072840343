import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CaseRelatedContactDto,
  CreateRelatedContactDto,
  RelatedContactDto,
  UpdateRelatedContactDto,
} from '@contact/dto/related-contact.dto';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

import { CaseId } from '../dtos/case.dto';

@Injectable({
  providedIn: 'root',
})
export class CaseContactService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getCaseContacts(id: CaseId): Observable<CaseRelatedContactDto[]> {
    return this.http.get<CaseRelatedContactDto[]>(`${this.apiUrl}/cases/${id}/contacts`);
  }

  create(id: CaseId, dto: RelatedContactDto): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/cases/${id}/contacts`, dto);
  }

  relateContact(id: CaseId, dto: CreateRelatedContactDto): Observable<CaseRelatedContactDto> {
    return this.http.post<CaseRelatedContactDto>(`${this.apiUrl}/cases/${id}/contacts`, dto);
  }

  updateRelation(id: CaseId, contactId: string, dto: UpdateRelatedContactDto): Observable<CaseRelatedContactDto> {
    return this.http.put<CaseRelatedContactDto>(`${this.apiUrl}/cases/${id}/contacts/${contactId}`, dto);
  }
}
