<div>
  <img [src]="data.image | file | async" alt="" />

  <div class="button-bar">
    @if (data.showDeleteButton) {
      <button (agreeEvent)="delete()" [dialogTitle]="'GENERAL.CONFIRMATION.DELETE_PHOTO' | translate" appConfirmationDialog mat-flat-button>
        {{ 'GENERAL.ACTION.DELETE_PHOTO' | translate }}
      </button>
    }

    <div class="buttons-right-container">
      <button (click)="closeWithResult()" cdkFocusInitial mat-flat-button>
        {{ 'GENERAL.BUTTON.CANCEL' | translate }}
      </button>

      <a
        (click)="closeWithResult()"
        class="tgn-button"
        download="{{ data.image.filename }}"
        href="{{ data.image | file | async }}"
        mat-flat-button
      >
        {{ 'GENERAL.BUTTON.DOWNLOAD' | translate }}
      </a>
    </div>
  </div>
</div>
