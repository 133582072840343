import { VatTaxGroup } from '@baseData/dtos/vat-tax.dto';
import { PositionDto } from '@bill/dto/position.dto';
import { CaseId } from '@case/dtos/case.dto';
import { ContactDto } from '@contact/dto/contact.dto';
import { BrandedId } from '@core/utils/brand';
import { IsoLocalDateString } from '@core/utils/date';
import { UserDto } from '@user/dto/user.dto';

export type BillId = BrandedId<'BillId'>;

export interface CompactBillDto {
  id: BillId;
  billingNumber: string;
  caseId: CaseId;
  status: BillStatus;
  usedVatTax: VatTaxGroup;
}

export interface BillDto {
  id: BillId;
  status: BillStatus;
  billRangeType: BillRangeType;
  billingContact: ContactDto | null;
  billingNumber: string;
  invoiceDocumentDate: IsoLocalDateString;
  billingDate: IsoLocalDateString;
  createdDate: IsoLocalDateString;
  note: string | null;
  paymentCondition: string | null;
  alreadyPaidAmount: number | null;
  createdBy: UserDto;
  totalPrice: number;
}

export interface CreateBillDto {
  billingContactId: string;
  caseId: CaseId;
  type: BillRangeType;
  invoiceDocumentDate: IsoLocalDateString;
  billingDate: IsoLocalDateString;
  note: string | null;
  paymentCondition: string | null;
  alreadyPaidAmount: number | null;
  positions: PositionDto[];
  usedVatTax: VatTaxGroup;
}

export interface UpdateBillDto {
  status: BillStatus;
  type: BillRangeType;
  billingContactId: string;
  invoiceDocumentDate: IsoLocalDateString;
  billingDate: IsoLocalDateString;
  note: string | null;
  paymentCondition: string | null;
  alreadyPaidAmount: number | null;
}

export enum BillRangeType {
  IntermediateBill = 'IntermediateBill',
  FinalBill = 'FinalBill',
}

export enum BillType {
  Regular = 'Regular',
  Storno = 'Storno',
}

export enum BillStatus {
  Open = 'Open',
  Sent = 'Sent',
  Paid = 'Paid',
  // Existing bill which has been cancelled
  Cancelled = 'Cancelled',
  // The new 'Storno' bill which has been created from an existing bill
  NewStorno = 'NewStorno',
}

export const DEFAULT_PAYMENT_CONDITION = '30 Tage netto';
