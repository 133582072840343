import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '@core/components/icon/icon.component';
import { RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { RoleRestriction } from '@core/models/role';
import { FileDownloadDirective } from '@file/directives/file-download.directive';
import { FileDto } from '@file/dto/file.dto';
import { FilePipe } from '@file/pipes/file.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'tgn-file-list-preview',
  standalone: true,
  imports: [AsyncPipe, FileDownloadDirective, FilePipe, IconComponent, MatButtonModule, TranslateModule, RoleRestrictionDirective],
  templateUrl: './file-list-preview.component.html',
  styleUrl: './file-list-preview.component.scss',
})
export class FileListPreviewComponent {
  @Input({ required: true }) files!: FileDto[];
  @Input() roleRestriction?: RoleRestriction;
  @Output() onFileDeleted = new EventEmitter<FileDto>();
}
