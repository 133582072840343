@if (datasource && currentVatTax) {
  <app-services-position-table
    [isExcludePension]="true"
    [isReadOnly]="caseDto?.archived ?? false"
    [datasource]="datasource"
    (onAddPosition)="addPosition($event)"
    [usedVatTax]="currentVatTax"
    [positionContext]="PositionContext.Case"
  />
}
