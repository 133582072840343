import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateStationDto, StationDto } from '@case/dtos/base-data.dto';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StationService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getAll(): Observable<StationDto[]> {
    return this.http.get<StationDto[]>(`${this.apiUrl}/master/stations`);
  }

  create(dto: CreateStationDto): Observable<StationDto> {
    return this.http.post<StationDto>(`${this.apiUrl}/master/stations`, dto);
  }
}
