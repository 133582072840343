import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnimalId } from '@animal/dtos/animal.dto';
import { VatTaxGroup } from '@baseData/dtos/vat-tax.dto';
import { BillDto, BillId, BillType, CreateBillDto, UpdateBillDto } from '@bill/dto/bill.dto';
import { BillingPeriod } from '@bill/dto/billing-period.dto';
import { BillingRelevantInfoDto } from '@bill/dto/billing-relevant-info.dto';
import { PositionDto, PositionId, UpdatePositionDto } from '@bill/dto/position.dto';
import { RequestTotalCalculationsDto, ResultTotalCalculationsDto } from '@bill/dto/total-calculations-result.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseDto, CaseId } from '@case/dtos/case.dto';
import { PageResult } from '@core/data/tigon-datasource';
import { ErrorKeys } from '@core/models/error';
import { BaseService } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { IsoLocalDateString } from '@core/utils/date';
import { PaginatedParams } from '@core/utils/pagination';
import { Observable, catchError, mergeMap, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

type BillPaginatedParams = Omit<PaginatedParams, 'sort' | 'query'>;

@Injectable({
  providedIn: 'root',
})
export class BillService extends BaseService {
  constructor(
    private http: HttpClient,
    private snackbar: SnackbarService,
  ) {
    super();
  }

  updatePosition(id: PositionId, dto: UpdatePositionDto): Observable<PositionDto> {
    return this.http.put<PositionDto>(`${this.apiUrl}/positions/${id}`, dto);
  }

  createBill(dto: CreateBillDto): Observable<BillDto> {
    return this.http.post<BillDto>(`${this.apiUrl}/bills`, dto).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        return fromPromise(blobToJson<{ key: string }>(errorResponse)).pipe(
          mergeMap(error => {
            if (error.key === ErrorKeys.NewBillingDateSoonerThanLastBill) {
              this.snackbar.showErrorMessage('PAGE.CREATE_BILL.ERRORS.NEW_BILLING_DATE_SOONER_THAN_LAST_BILL');
            }
            return throwError(() => errorResponse);
          }),
        );
      }),
    );
  }

  updateBill(bill: BillDto, dto: UpdateBillDto): Observable<BillDto> {
    return this.http.put<BillDto>(`${this.apiUrl}/bills/bill/${bill.id}`, dto);
  }

  findBillsPaginated(caseDto: CaseDto | CaseDetailDto, params: BillPaginatedParams): Observable<PageResult<BillDto>> {
    let httpParams: HttpParams = new HttpParams();

    const flattenedParams = {
      pageIndex: params.pageIndex.toString(),
      pageSize: params.pageSize.toString(),
    };

    httpParams = httpParams.appendAll(flattenedParams);

    return this.http.get<PageResult<BillDto>>(`${this.apiUrl}/bills/case/${caseDto.id}`, {
      params: httpParams,
    });
  }

  getAllCasePositionsForBilling(caseId: CaseId): Observable<PositionDto[]> {
    return this.http.get<PositionDto[]>(`${this.apiUrl}/bills/case/${caseId}/open-positions`);
  }

  // billing date is necessary to calculate pension costs which start either at entryDate or last intermediate bill
  // and are calculated up to and including the billing date
  getAllAnimalPositionsForBilling(caseId: CaseId, animalId: AnimalId, billingDate: IsoLocalDateString): Observable<PositionDto[]> {
    const params = { billingDate: billingDate };
    return this.http.get<PositionDto[]>(`${this.apiUrl}/bills/case/${caseId}/animal/${animalId}/open-positions`, { params: params });
  }

  getBillCasePositions(billId: BillId, caseId: CaseId): Observable<PositionDto[]> {
    return this.http.get<PositionDto[]>(`${this.apiUrl}/bills/bill/${billId}/case/${caseId}/positions`);
  }

  getBillAnimalPositions(billId: string, caseId: CaseId, animalId: AnimalId): Observable<PositionDto[]> {
    return this.http.get<PositionDto[]>(`${this.apiUrl}/bills/bill/${billId}/case/${caseId}/animal/${animalId}/positions`);
  }

  getNextBillingNumber(billType: BillType): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/bills/billingNumber`, { params: { billType: billType } });
  }

  getBill(billId: string): Observable<BillDto> {
    return this.http.get<BillDto>(`${this.apiUrl}/bills/bill/${billId}`);
  }

  stornoCancelBill(billId: string): Observable<BillDto> {
    return this.http.post<BillDto>(`${this.apiUrl}/bills/storno/${billId}`, {});
  }

  getCancelledStornoParentBill(newStornoBillId: string): Observable<BillDto> {
    return this.http.get<BillDto>(`${this.apiUrl}/bills/bill/${newStornoBillId}/stornoParent`);
  }

  getStornoChildBill(cancelledBillId: BillId): Observable<BillDto> {
    return this.http.get<BillDto>(`${this.apiUrl}/bills/bill/${cancelledBillId}/stornoChild`);
  }

  getBillingRelevantInfo(caseId: CaseId): Observable<BillingRelevantInfoDto> {
    return this.http.get<BillingRelevantInfoDto>(`${this.apiUrl}/bills/billingRelevantInfo/${caseId}`);
  }

  getBillingPeriod(newBillingDate: IsoLocalDateString, caseId: CaseId): Observable<BillingPeriod> {
    return this.http.get<BillingPeriod>(`${this.apiUrl}/bills/billingPeriod/${caseId}/${newBillingDate}`);
  }

  calculateBillTotal(
    isStorno: boolean,
    positions: PositionDto[],
    vatTaxGroup: VatTaxGroup,
    alreadyPaidAmount: number,
  ): Observable<ResultTotalCalculationsDto> {
    const dto: RequestTotalCalculationsDto = {
      isStorno,
      positions,
      vatTaxGroup,
      alreadyPaidAmount: alreadyPaidAmount,
    };
    return this.http.post<ResultTotalCalculationsDto>(`${this.apiUrl}/bills/calculateTotal`, dto);
  }

  getBillPdfUrl(billId: string): string {
    return `${this.apiUrl}/bills/bill/${billId}/pdf`;
  }

  downloadBillPdfUrl(billId: string): Observable<HttpResponse<Blob>> {
    return this.http
      .get(this.getBillPdfUrl(billId), {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          return fromPromise(blobToJson<{ key: string }>(errorResponse)).pipe(
            mergeMap(error => {
              if (error.key === ErrorKeys.MissingSettingsFields) {
                this.snackbar.showErrorMessage('PAGE.CASE_BILLS.ERRORS.MISSING_SETTINGS_FIELDS');
              } else if (error.key === ErrorKeys.MissingBillingContactFields) {
                this.snackbar.showErrorMessage('PAGE.CASE_BILLS.ERRORS.MISSING_BILLING_CONTACT_FIELDS');
              } else {
                this.snackbar.showErrorMessage('PAGE.CASE_BILLS.ERRORS.QR_GENERATION');
              }
              return throwError(() => errorResponse);
            }),
          );
        }),
      );
  }
}

export function blobToJson<T>(response: HttpErrorResponse): Promise<T> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        const json = JSON.parse(reader.result as string);
        resolve(json);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsText(response.error!);
  });
}
