<tgn-collapsible [expanded]="true" [title]="animal | animalTitle" class="input-bg-white" variant="withBgColor">
  @if (stmzForm) {
    <div class="form-grid grid-cols-6">
      <div class="row">
        <h3 class="section-title">{{ 'PAGE.CASE.PLACEMENT.LABEL.STMZ' | translate }}</h3>
      </div>

      @if (placement?.billingNumber) {
        <div class="row">
          <div class="item col-span-6">
            <tgn-form-element [control]="undefined" [id]="'billingNumber'" [label]="'ENTITY.Case.billingNumber' | translate">
              <ng-template formElement let-control="control" let-id="id">
                {{ placement?.billingNumber ?? '' }}
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
      }

      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="stmzForm.controls.stmzNumber" [id]="'stmzNumber'" [label]="'ENTITY.Case.stmzNumber' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline" class="with-link">
                <input [formControl]="control" [id]="id" type="text" matInput [placeholder]="'ENTITY.Case.stmzNumber' | translate" />
                @if (stmzForm.controls.stmzNumber.value) {
                  <a [href]="stmzForm.controls.stmzNumber.value | createStmzLink">
                    <tgn-icon [iconName]="'radixExternalLink'" />
                  </a>
                }
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element [control]="stmzForm.controls.stmzDate" [id]="'stmzDate'" [label]="'ENTITY.Case.stmzDate' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="stmzForm.controls.stmzDeadline1"
            [id]="'stmzDeadline1'"
            [label]="'ENTITY.Case.stmzDeadline1' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="stmzForm.controls.stmzDeadline2"
            [id]="'stmzDeadline2'"
            [label]="'ENTITY.Case.stmzDeadline2' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    </div>
  }

  @if (placementForm) {
    <div class="form-grid grid-cols-6">
      <div class="row">
        <h3 class="section-title">{{ 'PAGE.CASE.PLACEMENT.LABEL.PLACEMENT' | translate }}</h3>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element [control]="placementForm.controls.isPublishDisallowed" [id]="'title'" class="row-span-3">
            <ng-template formElement let-control="control" let-id="id">
              <mat-checkbox
                (change)="updateIsPublishDisallowed($event)"
                [checked]="control.value"
                [disabled]="control.disabled"
                class="checkbox-item"
              >
                {{ 'ENTITY.Placement.isPublishDisallowed' | translate }}
              </mat-checkbox>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.placementType"
            [id]="'placementType'"
            [label]="'ENTITY.Placement.placementType' | translate"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select [choices]="placementTypeChoices" [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.placementState"
            [id]="'placementState'"
            [label]="'ENTITY.Placement.placementState' | translate"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select [choices]="placementStateChoices" [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="placementForm.controls.placementNote"
            [id]="'placementNote'"
            [label]="'ENTITY.Placement.placementNote' | translate"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [formControl]="control"
                  [id]="id"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <h3 class="section-title">{{ 'PAGE.CASE.PLACEMENT.LABEL.TAKEOVER' | translate }}</h3>
        </div>
      </div>

      @if (generalPlacement && generalPlacement.takeoverContact !== null) {
        <div class="row row-span-sm-2">
          <div class="item col-span-3 col-span-sm-6">
            <tgn-form-element
              [control]="placementForm.controls.previousTakeoverContact"
              [hideError]="true"
              [label]="'PAGE.CASE.PLACEMENT.LABEL.PREVIOUS_OWNER' | translate"
              [id]="'previousOwner'"
              class=""
            >
              <ng-template formElement let-control="control" let-id="id">
                <span>
                  <tgn-single-line-text
                    [text]="placementForm.controls.previousTakeoverContact.value | contactDisplay: { showUnknown: true, onlyName: true }"
                  />
                </span>
              </ng-template>
            </tgn-form-element>
          </div>

          <div class="item col-span-3 col-span-sm-6">
            <tgn-form-element
              [control]="placementForm.controls.takeoverContact"
              [hideError]="true"
              [id]="'newOwner'"
              [label]="'PAGE.CASE.PLACEMENT.LABEL.NEW_OWNER' | translate"
            >
              <ng-template formElement let-control="control" let-id="id">
                <span>
                  <tgn-single-line-text
                    [text]="placementForm.controls.takeoverContact.value | contactDisplay: { showUnknown: true, onlyName: true }"
                  />
                </span>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
      }

      @if (generalPlacement && generalPlacement.takeoverCase === null) {
        <div class="row">
          <div class="item col-span-6">
            <button
              [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE | andRoleRestriction: RESTRICT_CASE_WRITE"
              (click)="openNewPlacementModal()"
              class="tgn-black-button mt-6"
              mat-flat-button
            >
              {{ 'PAGE.PLACEMENT.FORM.ACTION.NEW_PLACEMENT' | translate }}
            </button>
          </div>
        </div>
      }

      @if (generalPlacement && generalPlacement.takeoverContact !== null) {
        <div class="row">
          <div class="item col-span-6">
            <div class="placement-info">
              <tgn-icon [iconName]="'radixInfoCircled'" />
              <span>
                {{
                  'PAGE.CASE.PLACEMENT.OWNER_CHANGE_INFO'
                    | translate
                      : {
                          previousOwnerName:
                            generalPlacement.previousTakeoverContact
                            | contactDisplay
                              : {
                                  showUnknown: true,
                                  onlyName: true
                                },
                          newOwnerName:
                            generalPlacement.takeoverContact
                            | contactDisplay
                              : {
                                  showUnknown: true,
                                  onlyName: true
                                },
                          takeoverDate: generalPlacement.takeoverDate | date: 'dd.MM.yyyy',
                          takeoverInitiator: generalPlacement.takeoverInitiator | fullName
                        }
                }}
              </span>
            </div>
          </div>
        </div>

        @if (generalPlacement.takeoverCase !== null) {
          <div class="row">
            <div class="item col-span-6">
              <button (click)="routeToNewCase()" class="tgn-black-button mt-6" mat-flat-button>
                {{ 'PAGE.PLACEMENT.FORM.ACTION.TO_NEW_CASE' | translate }}
              </button>
            </div>
          </div>
        }
      }

      <div class="row">
        <div class="item col-span-6">
          <h3 class="section-title">{{ 'PAGE.CASE.PLACEMENT.LABEL.EXIT' | translate }}</h3>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.exitState"
            [id]="'exitState'"
            [label]="'ENTITY.Placement.exitState' | translate"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select
                [choices]="exitStates$ | async | addIfMissing: placementForm.controls.exitState.value | toRadioChoice: 'name'"
                [compareIds]="true"
                [control]="control"
                [id]="id"
              />
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.exitDate"
            [id]="'exitDate'"
            [label]="'ENTITY.Placement.exitDate' | translate"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-date-input [control]="control" [id]="id" />
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="placementForm.controls.exitNote"
            [id]="'exitNote'"
            [label]="'ENTITY.Placement.exitNote' | translate"
            class="row-span-3"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [formControl]="control"
                  [id]="id"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    </div>
  }
  <div class="button-container">
    <a [routerLink]="appRoutes.ANIMAL_DETAIL.url(animal.id)" class="tgn-black-button mt-medium mb-medium" mat-flat-button>
      {{ 'PAGE.CASE.PLACEMENT.ACTION.TO_ANIMAL' | translate }}
    </a>
    @if (this.case !== null) {
      <a
        [routerLink]="appRoutes.ANIMAL_EXITCHECK.url(this.case.id, animal.id)"
        class="tgn-black-button mt-medium mb-medium"
        mat-flat-button
      >
        {{ 'PAGE.CASE.PLACEMENT.ACTION.TO_EXITCHECK' | translate }}
      </a>
    }
  </div>
</tgn-collapsible>
