<div class="mt-large change-container">
  <div class="commit-header entry-grid">
    <mat-chip class="custom-chip">
      @if (isAuditUpdate(change)) {
        <span>Aktualisiert</span>
      } @else if (isAuditCreate(change)) {
        <span>Neu</span>
      } @else if (isAuditDelete(change)) {
        <span>Gelöscht</span>
      }
    </mat-chip>

    <div class="change-header__entry object-info">
      <label>{{ 'AUDIT.ENTITY.' + change.affectedObject.entityType | translate }}:</label>
      <tgn-single-line-text [text]="change.affectedObject.name ?? change.affectedObject.id" />
    </div>

    <div class="change-header__entry object-info">
      <label>Benutzer:</label>
      <tgn-single-line-text [text]="change.metadata.author.name + ' (' + change.metadata.author.email + ')'" />
    </div>

    <div class="change-header__entry object-info align-right">
      <label>Datum:</label>
      <tgn-single-line-text [text]="(change.metadata.commitDate | date: 'dd.MM.yyyy HH:mm') ?? ''" />
    </div>
  </div>

  <div class="entry-grid">
    @if (isAuditUpdate(change)) {
      <tgn-audit-diff [oldState]="change.old" [newState]="change.new" [translationDomainName]="change.affectedObject.entityType" />
    } @else if (isAuditCreate(change)) {
      <tgn-audit-diff [oldState]="null" [newState]="change.new" [translationDomainName]="change.affectedObject.entityType" />
    } @else if (isAuditDelete(change)) {
      <span class="full-line">Wurde gelöscht</span>
    }
  </div>
</div>
