import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateSubmitReasonDto, UpdateSubmitReasonDto } from '@baseData/dtos/create-submit-reason.dto';
import { SubmitReasonDto, SubmitReasonId } from '@case/dtos/base-data.dto';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubmitReasonService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Inactive relationKinds are included here
   */
  getAll(): Observable<SubmitReasonDto[]> {
    return this.http.get<SubmitReasonDto[]>(`${this.apiUrl}/master/submitReasons`);
  }

  getAllActive(): Observable<SubmitReasonDto[]> {
    return this.http.get<SubmitReasonDto[]>(`${this.apiUrl}/master/submitReasons/active`);
  }

  getById(id: SubmitReasonId): Observable<SubmitReasonDto> {
    return this.http.get<SubmitReasonDto>(`${this.apiUrl}/master/submitReasons/${id}`);
  }

  create(dto: CreateSubmitReasonDto): Observable<SubmitReasonDto> {
    return this.http.post<SubmitReasonDto>(`${this.apiUrl}/master/submitReasons`, dto);
  }

  update(id: SubmitReasonId, dto: UpdateSubmitReasonDto): Observable<SubmitReasonDto> {
    return this.http.put<SubmitReasonDto>(`${this.apiUrl}/master/submitReasons/${id}`, dto);
  }
}
