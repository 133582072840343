<tgn-narrow-page-container>
  @if (currentAnimalContext$ | async; as context) {
    <div class="container">
      <tgn-stepper
        #stepper
        (onFirstPreviousAction)="onFirstPreviousAction.emit(context)"
        (onLastAction)="onLastAction.emit(context)"
        [showPreviousInFirstStep]="showPreviousInFirstStep"
        [firstPreviousLabel]="firstPreviousLabel"
        [lastActionLabel]="lastActionLabel"
        [continueDisabled]="stepperContinueDisabled"
        (selectedIndexChange)="stepChanged()"
      >
        <cdk-step
          [label]="
            ('PAGE.ENTRY_CHECK.STEPPER.ANIMAL_N' | translate: { n: context.animalIndex + 1 }) +
            (animalForm.controls.name.value ? ' - ' + animalForm.controls.name.value : '')
          "
        >
          <h2>
            {{ 'PAGE.ENTRY_CHECK.FORM.SECTION_GENERAL_FEATURES' | translate }}
          </h2>
          <app-animal-entry-form
            [form]="animalForm"
            [currentAnimalContext]="context"
            (onCaseAnimalChange)="updateCaseAnimal(context, $event)"
          />
        </cdk-step>

        <cdk-step [label]="'PAGE.ENTRY_CHECK.STEPPER.HEALTH' | translate">
          <h1>{{ 'PAGE.ENTRY_CHECK.TITLE_HEALTH' | translate }}</h1>
          <app-health-form
            [form]="entryCheckForm"
            [group]="animalForm.controls.group.value"
            [subGroup]="animalForm.controls.subGroup.value"
          />
        </cdk-step>

        <cdk-step [label]="'PAGE.ENTRY_CHECK.STEPPER.PARTICULARITIES' | translate">
          <h2>{{ 'PAGE.ENTRY_CHECK.TITLE_PARTICULARITIES' | translate }}</h2>

          @if (boxes$ | async; as boxes) {
            <app-particularities-form [form]="particularitiesForm" [boxes]="boxes" [animal]="context.caseAnimalDto.animal" />
          }
        </cdk-step>
      </tgn-stepper>
    </div>
  }
</tgn-narrow-page-container>
