@if (data.closeButton) {
  <div class="close-button">
    <div class="closable" (click)="onClose()">
      <ng-icon name="radixCross1" />
    </div>
  </div>
}
<span mat-dialog-title>{{ data.title | translate: { name: data.objectName } }}</span>
@if (data.description) {
  <mat-dialog-content>
    {{ data.description | translate }}
  </mat-dialog-content>
}
<mat-dialog-actions class="d-flex justify-content-end" role="group">
  <button (click)="onClick(false)" mat-flat-button type="button">
    {{ data.noButtonLabel || 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>
  <button (click)="onClick(true)" cdkFocusInitial class="tgn-button" color="primary" mat-flat-button type="button">
    {{ data.yesButtonLabel || 'GENERAL.BUTTON.OK' | translate }}
  </button>
</mat-dialog-actions>
