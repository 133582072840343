import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatMenu, MatMenuContent, MatMenuTrigger } from '@angular/material/menu';
import {
  AnimalStateFilter,
  AnimalTypeFilter,
  GroupFilter,
  SpeciesFilter,
  SubGroupFilter,
} from '@animal/components/animal-filter/model/animal-filter';
import { ExitState } from '@baseData/dtos/exit-state.dto';
import { NextStepDirective, StepDirective, StepViewComponent } from '@care/components/step-view/step-view.component';
import { ActiveFilterGroupComponent } from '@care/components/task-filter/active-filter-group/active-filter-group.component';
import { BoxFilter, CaseEntryTypeFilter } from '@care/components/task-filter/model/task-filter';
import { SelectedFilterItemComponent } from '@care/components/task-filter/selected-filter-item/selected-filter-item.component';
import { SingleFilterListComponent } from '@care/components/task-filter/single-filter-list/single-filter-list.component';
import { TaskCategoryDto } from '@care/dtos/task.dto';
import { BaseDataDto, BoxDto, GroupDto, MainGroupType, SpeciesDto, SubGroupDto, SubmitReasonDto } from '@case/dtos/base-data.dto';
import { CaseStateFilter } from '@case/models/case-filter';
import { BaseDataService } from '@case/services/base-data-service';
import { DatePickerRangeComponent, DateRangeGroup } from '@core/components/date-picker/date-picker-range.component';
import { ExitStateFilter, GlobalSearchAnimalFilter } from '@core/components/global-search/model/global-search-animal-filter';
import { GlobalSearchCaseFilter, SubmitReasonFilter } from '@core/components/global-search/model/global-search-case-filter';
import { IconComponent } from '@core/components/icon/icon.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { AnimalState, AnimalType, CaseEntryType, CaseState } from '@core/models/general';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { defaultDebounce } from '@core/services/base-service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, combineLatest, map, shareReplay, startWith, take } from 'rxjs';

@Component({
  selector: 'tgn-global-search-filter',
  standalone: true,
  imports: [
    ActiveFilterGroupComponent,
    AsyncPipe,
    DatePickerRangeComponent,
    DatePipe,
    EnumDisplayPipe,
    IconComponent,
    MatMenu,
    SelectedFilterItemComponent,
    SingleFilterListComponent,
    StepDirective,
    MatMenuTrigger,
    TranslateModule,
    NextStepDirective,
    ToRadioChoicePipe,
    MatMenuContent,
    StepViewComponent,
    AddIfMissingPipe,
  ],
  templateUrl: './global-search-filter.component.html',
  styleUrl: './global-search-filter.component.scss',
})
export class GlobalSearchFilterComponent implements OnInit {
  @Input({ required: true }) caseFilter!: GlobalSearchCaseFilter;

  @Input({ required: true }) animalFilter!: GlobalSearchAnimalFilter;

  @Output() onAnimalFilterChange = new EventEmitter<GlobalSearchAnimalFilter>();
  @Output() onCaseFilterChange = new EventEmitter<GlobalSearchCaseFilter>();

  @ViewChild(MatMenuTrigger) menu!: MatMenuTrigger;

  taskCategories$!: Observable<TaskCategoryDto[]>;
  boxes$!: Observable<BoxDto[]>;

  entryDateRangeGroup!: FormGroup<DateRangeGroup>;
  exitDateRangeGroup!: FormGroup<DateRangeGroup>;
  animalStateChoices: RadioChoice<AnimalState>[] = createEnumChoices(AnimalState, 'GENERAL.DOMAIN.AnimalState.');

  groups$!: Observable<GroupDto[]>;
  subGroups$!: Observable<SubGroupDto[]>;
  species$!: Observable<SpeciesDto[]>;
  animalTypeChoices: RadioChoice<AnimalType>[] = createEnumChoices(AnimalType, 'GENERAL.DOMAIN.AnimalType.');

  baseData$!: Observable<BaseDataDto>;

  // currently available choices based on filters selected (for example only show certain species/subGroup etc if some group / subGroup is selected)
  availableAnimalTypes$!: Observable<AnimalType[]>;
  availableGroups$!: Observable<GroupDto[]>;
  availableSubGroups$!: Observable<SubGroupDto[]>;
  availableSpecies$!: Observable<SpeciesDto[]>;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  exitStateChoices$!: Observable<ExitState[]>;

  caseStateChoices: RadioChoice<CaseState>[] = createEnumChoices(CaseState, 'GENERAL.DOMAIN.CaseState.');

  entryTypeChoices: RadioChoice<CaseEntryType>[] = createEnumChoices(CaseEntryType, 'GENERAL.DOMAIN.CaseEntryType.');

  submitReasonChoices$!: Observable<SubmitReasonDto[]>;

  private destroyRef = inject(DestroyRef);

  constructor(
    private baseDataService: BaseDataService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.baseData$ = this.baseDataService.getBaseData().pipe(shareReplay(1));

    this.exitStateChoices$ = this.baseData$.pipe(take(1)).pipe(map(baseData => baseData.exitStates));

    this.submitReasonChoices$ = this.baseData$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map((baseData: BaseDataDto) => {
        return baseData.submitReasons;
      }),
    );

    this.getGroupsSubGroupsSpecies();

    this.setAvailableChoicesBasedOnFilter();

    this.entryDateRangeGroup = this.fb.group({
      start: this.fb.control<IsoLocalDateString | null>(null),
      end: this.fb.control<IsoLocalDateString | null>(null),
    });

    this.exitDateRangeGroup = this.fb.group({
      start: this.fb.control<IsoLocalDateString | null>(null),
      end: this.fb.control<IsoLocalDateString | null>(null),
    });

    this.entryDateRangeGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
      if (this.entryDateRangeGroup.invalid) {
        console.info('skipping update, invalid date range');
        return;
      }
      this.onCaseFilterChange.emit(
        this.caseFilter.setEntryDateFilter({
          from: this.entryDateRangeGroup.value.start ?? null,
          to: this.entryDateRangeGroup.value.end ?? null,
          id: 'entryDateFilter',
        }),
      );
    });

    this.exitDateRangeGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
      if (this.exitDateRangeGroup.invalid) {
        console.info('skipping update, invalid date range');
        return;
      }
      this.onAnimalFilterChange.emit(
        this.animalFilter.setExitDateFilter({
          from: this.exitDateRangeGroup.value.start ?? null,
          to: this.exitDateRangeGroup.value.end ?? null,
          id: 'exitDateFilter',
        }),
      );
    });
    this.taskCategories$ = this.baseDataService.getBaseData().pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData => baseData.taskCategories),
    );
    this.boxes$ = this.baseDataService.getBaseData().pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData => baseData.boxes),
    );

    this.onAnimalFilterChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.menu.closeMenu();
    });
  }

  getGroupsSubGroupsSpecies() {
    this.groups$ = this.baseData$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData => [...baseData.domesticAnimal.groups, ...baseData.wildAnimal.groups].sort((a, b) => a.name.localeCompare(b.name))),
      shareReplay(1),
    );

    this.subGroups$ = this.baseData$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData =>
        [...baseData.domesticAnimal.subGroups, ...baseData.wildAnimal.subGroups].sort((a, b) => a.name.localeCompare(b.name)),
      ),
    );

    this.species$ = this.baseDataService.getBaseData().pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData => [...baseData.domesticAnimal.species, ...baseData.wildAnimal.species].sort((a, b) => a.name.localeCompare(b.name))),
    );
  }

  setAvailableChoicesBasedOnFilter() {
    const onAnimalFilterChange = this.onAnimalFilterChange.pipe(startWith(this.animalFilter), shareReplay(1));

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAnimalFilterChange.subscribe(() => {});

    this.availableAnimalTypes$ = onAnimalFilterChange.pipe(
      map((filter: GlobalSearchAnimalFilter) => {
        const filteredAnimalTypes = filter.filters.animalTypeFilter.values;
        return filteredAnimalTypes.length > 0 ? filteredAnimalTypes : [AnimalType.DomesticAnimal, AnimalType.WildAnimal];
      }),
      shareReplay(1),
    );

    this.availableGroups$ = combineLatest([this.groups$, this.availableAnimalTypes$]).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(([groups, availableAnimalTypes]) => {
        return groups.filter((it: GroupDto) => {
          const animalType = it.groupType === MainGroupType.DomesticAnimal ? AnimalType.DomesticAnimal : AnimalType.WildAnimal;
          return availableAnimalTypes.includes(animalType);
        });
      }),
      shareReplay(1),
    );

    this.availableSubGroups$ = combineLatest([this.subGroups$, this.availableGroups$, onAnimalFilterChange]).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(([subGroups, availableGroup, filter]) => {
        const filteredGroups = filter.filters.groupFilter.values.length > 0 ? filter.filters.groupFilter.values : availableGroup;
        return subGroups.filter((it: SubGroupDto) => {
          return filteredGroups.some(group => group.id === it.groupId);
        });
      }),
      shareReplay(1),
    );

    this.availableSpecies$ = combineLatest([this.species$, this.availableSubGroups$, onAnimalFilterChange]).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(([species, availableSubGroups, filter]) => {
        const filteredSubGroups =
          filter.filters.subGroupFilter.values.length > 0 ? filter.filters.subGroupFilter.values : availableSubGroups;
        return species.filter((it: SpeciesDto) => filteredSubGroups.some(subGroup => subGroup.id === it.subGroupId));
      }),
      shareReplay(1),
    );
  }

  onAnimalStateFilterChange(newFilter: AnimalStateFilter) {
    this.onAnimalFilterChange.emit(this.animalFilter.setStateFilter(newFilter));
  }

  onRemoveSingleAnimalStateFilter(filter: AnimalState) {
    const newFilter: AnimalStateFilter = {
      values: this.animalFilter.filters.stateFilter.values.filter(value => value !== filter),
      id: 'stateFilter',
    };
    this.onAnimalFilterChange.emit(this.animalFilter.setStateFilter(newFilter));
  }

  onAnimalTypeFilterChange($event: AnimalTypeFilter) {
    this.onAnimalFilterChange.emit(this.animalFilter.setAnimalTypeFilter($event));
  }

  onRemoveSingleAnimalTypeFilter(filter: AnimalType) {
    const newFilter: AnimalTypeFilter = {
      values: this.animalFilter.filters.animalTypeFilter.values.filter(value => value !== filter),
      id: 'animalTypeFilter',
    };
    this.onAnimalFilterChange.emit(this.animalFilter.setAnimalTypeFilter(newFilter));
  }

  onGroupFilterChange($event: GroupFilter) {
    this.onAnimalFilterChange.emit(this.animalFilter.setGroupFilter($event));
  }

  onRemoveSingleGroupFilter(filter: GroupDto) {
    const newFilter: GroupFilter = {
      values: this.animalFilter.filters.groupFilter.values.filter(value => value !== filter),
      id: 'groupFilter',
    };
    this.onAnimalFilterChange.emit(this.animalFilter.setGroupFilter(newFilter));
  }

  onSubGroupFilterChange($event: SubGroupFilter) {
    this.onAnimalFilterChange.emit(this.animalFilter.setSubGroupFilter($event));
  }

  onSpeciesFilterChange($event: SpeciesFilter) {
    this.onAnimalFilterChange.emit(this.animalFilter.setSpeciesFilter($event));
  }

  onAnimalExitStateFilterChange($event: ExitStateFilter) {
    this.onAnimalFilterChange.emit(this.animalFilter.setExitStateFilter($event));
  }

  onRemoveSingleSubGroupFilter(filter: SubGroupDto) {
    const newFilter: SubGroupFilter = {
      values: this.animalFilter.filters.subGroupFilter.values.filter(value => value !== filter),
      id: 'subGroupFilter',
    };
    this.onAnimalFilterChange.emit(this.animalFilter.setSubGroupFilter(newFilter));
  }

  onRemoveSingleSpeciesFilter(filter: SpeciesDto) {
    const newFilter: SpeciesFilter = {
      values: this.animalFilter.filters.speciesFilter.values.filter(value => value !== filter),
      id: 'speciesFilter',
    };
    this.onAnimalFilterChange.emit(this.animalFilter.setSpeciesFilter(newFilter));
  }

  onRemoveSingleExitStateFilter(filter: ExitState) {
    const newFilter: ExitStateFilter = {
      values: this.animalFilter.filters.exitStateFilter.values.filter(value => value !== filter),
      id: 'exitStateFilter',
    };
    this.onAnimalFilterChange.emit(this.animalFilter.setExitStateFilter(newFilter));
  }

  onBoxFilterChange($event: BoxFilter) {
    this.onAnimalFilterChange.emit(this.animalFilter.setBoxFilter($event));
  }

  onRemoveSingleBoxFilter(filter: BoxDto) {
    const newFilter: BoxFilter = {
      values: this.animalFilter.filters.boxFilter.values.filter(value => value !== filter),
      id: 'boxFilter',
    };
    this.onAnimalFilterChange.emit(this.animalFilter.setBoxFilter(newFilter));
  }

  onRemoveEntryDateFilter() {
    this.onCaseFilterChange.emit(this.caseFilter.setEntryDateFilter({ from: null, to: null, id: 'entryDateFilter' }));
    this.entryDateRangeGroup.setValue({ start: null, end: null }, { emitEvent: false });
  }

  onRemoveExitDateFilter() {
    this.onAnimalFilterChange.emit(this.animalFilter.setExitDateFilter({ from: null, to: null, id: 'exitDateFilter' }));
    this.entryDateRangeGroup.setValue({ start: null, end: null }, { emitEvent: false });
  }

  onCaseStateFilterChange(newFilter: CaseStateFilter) {
    this.onCaseFilterChange.emit(this.caseFilter.setStateFilter(newFilter));
  }

  onRemoveSingleCaseStateFilter(filter: CaseState) {
    const newFilter: CaseStateFilter = {
      values: this.caseFilter.filters.stateFilter.values.filter(value => value !== filter),
      id: 'stateFilter',
    };
    this.onCaseFilterChange.emit(this.caseFilter.setStateFilter(newFilter));
  }

  onEntryTypeFilterChange(newFilter: CaseEntryTypeFilter) {
    this.onCaseFilterChange.emit(this.caseFilter.setEntryTypeFilter(newFilter));
  }

  onRemoveSingleEntryTypeFilter(filter: CaseEntryType) {
    const newFilter: CaseEntryTypeFilter = {
      values: this.caseFilter.filters.entryTypeFilter.values.filter(value => value !== filter),
      id: 'entryTypeFilter',
    };
    this.onCaseFilterChange.emit(this.caseFilter.setEntryTypeFilter(newFilter));
  }

  onSubmitReasonFilterChange($event: SubmitReasonFilter) {
    this.onCaseFilterChange.emit(this.caseFilter.setSubmitReasonFilter($event));
  }

  onRemoveSingleSubmitReasonFilter(filter: SubmitReasonDto) {
    const newFilter: SubmitReasonFilter = {
      values: this.caseFilter.filters.submitReasonFilter.values.filter(value => value !== filter),
      id: 'submitReasonFilter',
    };
    this.onCaseFilterChange.emit(this.caseFilter.setSubmitReasonFilter(newFilter));
  }

  clearAllFilters() {
    this.onAnimalFilterChange.emit(GlobalSearchAnimalFilter.empty());
    this.onCaseFilterChange.emit(GlobalSearchCaseFilter.empty());
    this.entryDateRangeGroup.setValue({ start: null, end: null }, { emitEvent: false });
    this.exitDateRangeGroup.setValue({ start: null, end: null }, { emitEvent: false });
  }
}
