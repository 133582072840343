import { Component, Input } from '@angular/core';

@Component({
  selector: 'tgn-active-filter-group',
  standalone: true,
  imports: [],
  templateUrl: './active-filter-group.component.html',
  styleUrl: './active-filter-group.component.scss',
})
export class ActiveFilterGroupComponent {
  @Input({ required: true }) show!: boolean;
  @Input({ required: true }) label!: string;
}
