import { HttpParams } from '@angular/common/http';
import { addHttpParams, newFilter } from '@care/components/task-filter/model/task-filter';
import { CaseEntryType, CaseState, PlacementState, PlacementType } from '@core/models/general';
import { IsoLocalDateString } from '@core/utils/date';

export interface Filter<T, Id extends string> {
  values: T[];
  id: Id;
}

export enum GeneralCaseFilterChoice {
  AllRunning = 'AllRunning',
  New = 'New',
  Active = 'Active',
  InPlacement = 'InPlacement',
  Completed = 'Completed',
}

export type CaseStateFilter = Filter<CaseState, 'stateFilter'>;
export type CaseEntryTypeFilter = Filter<CaseEntryType, 'entryTypeFilter'>;
export type PlacementTypeFilter = Filter<PlacementType, 'placementTypeFilter'>;
export type PlacementStateFilter = Filter<PlacementState, 'placementStateFilter'>;

export interface DateFilter {
  from: IsoLocalDateString | null;
  to: IsoLocalDateString | null;
  id: 'dateFilter';
}

export class CaseFilter {
  filters: {
    stateFilter: CaseStateFilter;
    entryTypeFilter: CaseEntryTypeFilter;
    dateFilter: DateFilter;
    placementTypeFilter: PlacementTypeFilter;
    placementStateFilter: PlacementStateFilter;
  };

  constructor(filters: {
    stateFilter: CaseStateFilter;
    entryTypeFilter: CaseEntryTypeFilter;
    dateFilter: DateFilter;
    placementTypeFilter: PlacementTypeFilter;
    placementStateFilter: PlacementStateFilter;
  }) {
    this.filters = filters;
  }

  static empty(): CaseFilter {
    return new CaseFilter({
      stateFilter: newFilter('stateFilter'),
      entryTypeFilter: newFilter('entryTypeFilter'),
      dateFilter: { from: null, to: null, id: 'dateFilter' },
      placementTypeFilter: newFilter('placementTypeFilter'),
      placementStateFilter: newFilter('placementStateFilter'),
    });
  }

  setStateFilter(filter: CaseStateFilter): CaseFilter {
    return new CaseFilter({ ...this.filters, stateFilter: filter });
  }

  setEntryTypeFilter(entryTypeFilter: CaseEntryTypeFilter): CaseFilter {
    return new CaseFilter({ ...this.filters, entryTypeFilter: entryTypeFilter });
  }

  setDateFilter(filter: DateFilter): CaseFilter {
    return new CaseFilter({ ...this.filters, dateFilter: filter });
  }

  setPlacementTypeFilter(filter: PlacementTypeFilter): CaseFilter {
    return new CaseFilter({ ...this.filters, placementTypeFilter: filter });
  }

  setPlacementStateFilter(filter: PlacementStateFilter): CaseFilter {
    return new CaseFilter({ ...this.filters, placementStateFilter: filter });
  }

  anyFilterActive(): boolean {
    return Object.values(this.filters).some((filter: { values?: unknown[]; from?: unknown; to?: unknown }) => {
      if (filter?.values) {
        return filter.values.length > 0;
      } else {
        return filter.from || filter.to;
      }
    });
  }

  toCaseFilterDto(): HttpParams {
    let params = new HttpParams();

    const filterParamName = 'filter';

    params = addHttpParams(this.filters.stateFilter.values, `${filterParamName}.stateFilter`, params);
    params = addHttpParams(this.filters.entryTypeFilter.values, `${filterParamName}.entryTypeFilter`, params);
    if (this.filters.dateFilter.from) {
      params = params.append(`${filterParamName}.dateFromFilter`, this.filters.dateFilter.from);
    }
    if (this.filters.dateFilter.to) {
      params = params.append(`${filterParamName}.dateToFilter`, this.filters.dateFilter.to);
    }
    return params;
  }
}
