import { SingleAuditFieldDto } from '@audit/dtos/auditChange';
import { ExitState } from '@baseData/dtos/exit-state.dto';
import { ContactDto } from '@contact/dto/contact.dto';
import { CastrationType, PlacementState, PlacementType } from '@core/models/general';
import { BrandedId } from '@core/utils/brand';
import { IsoLocalDateString } from '@core/utils/date';
import { UserDto } from '@user/dto/user.dto';

import { CompactCaseAnimalDto } from './case-animal.dto';
import { CaseDto } from './case.dto';

export type PlacementId = BrandedId<'PlacementId'>;

export interface PlacementDetailDto {
  id: PlacementId;
  billingNumber: string | null;
  caseAnimal: CompactCaseAnimalDto;
  placementType: PlacementType | null;
  placementState: PlacementState | null;
  mediationHasProspect: boolean | null;
  mediationHasProspectNote: string | null;
  mediationHasProspectAuditField: SingleAuditFieldDto | null;
  mediationHasPlacementDate: boolean | null;
  mediationHasPlacementDateNote: string | null;
  mediationHasPlacementDateAuditField: SingleAuditFieldDto | null;
  mediationIsInTrial: boolean | null;
  mediationIsInTrialNote: string | null;
  mediationIsInTrialAuditField: SingleAuditFieldDto | null;
  mediationPlacementSuccessful: boolean | null;
  mediationPlacementSuccessfulNote: string | null;
  mediationPlacementSuccessfulAuditField: SingleAuditFieldDto | null;
  mediationPhonePlacementSupervision: boolean | null;
  mediationPhonePlacementSupervisionNote: string | null;
  mediationPhonePlacementSupervisionAuditField: SingleAuditFieldDto | null;
  mediationOnPremisePlacementSupervision: boolean | null;
  mediationOnPremisePlacementSupervisionNote: string | null;
  mediationOnPremisePlacementSupervisionAuditField: SingleAuditFieldDto | null;
  mediationCompleted: boolean | null;
  mediationCompletedNote: string | null;
  mediationCompletedAuditField: SingleAuditFieldDto | null;
  placementNote: string | null;
  castrationType: CastrationType | null;
  isCastrationCoveredByTrd: boolean | null;
  isVaccinatedAccordingToPassport: boolean | null;
  followUpVaccinationSecondIklsDate: IsoLocalDateString | null;
  followUpVaccinationThirdIklsDate: IsoLocalDateString | null;
  leukosisAndFivNegativeDate: IsoLocalDateString | null;
  parasiteCareDate: IsoLocalDateString | null;
  veterinaryExaminationCondition: VeterinaryExaminationCondition | null;
  illnessKinds: IllnessKind[];
  illnessNote: string | null;
  additionalNote: string | null;
  behavior: string | null;
  transferMaterials: TransferMaterial[];
  vaccinationPassHandedOver: boolean | null;
  fosterAnimalPassportHandedOver: boolean | null;
  lastUpdatedBy: UserDto | null;
}

export interface CaseGeneralPlacementDto {
  isPublishDisallowed: boolean | null;
  exitState: ExitState | null;
  exitDate: IsoLocalDateString | null;
  exitNote: string | null;
  placementType: PlacementType | null;
  placementState: PlacementState | null;
  placementNote: string | null;

  previousTakeoverContact: ContactDto | null;
  takeoverContact: ContactDto | null;
  takeoverCase: CaseDto | null;
  takeoverDate: IsoLocalDateString | null;
  takeoverInitiator: UserDto | null;
}

type TakeoverKeys = 'previousTakeoverContact' | 'takeoverContact' | 'takeoverCase' | 'takeoverDate' | 'takeoverInitiator';

export type UpdateCaseGeneralPlacementDto = Omit<CaseGeneralPlacementDto, TakeoverKeys>;

export interface UpdateWildAnimalExitStateDto {
  exitDate: IsoLocalDateString | null;
  exitNote: string | null;
}

export type UpdatePlacementDetailDto = Omit<
  PlacementDetailDto,
  'caseAnimal' | 'lastUpdatedBy' | 'billingNumber' | 'id' | MediationAuditFields
>;

export interface CompactPlacementDto {
  placementType: PlacementType | null;
  placementState: PlacementState | null;
  placementNote: string | null;
  mediationHasProspect: boolean | null;
  mediationHasProspectNote: string | null;
  mediationHasProspectAuditField: SingleAuditFieldDto | null;
  mediationHasPlacementDate: boolean | null;
  mediationHasPlacementDateNote: string | null;
  mediationHasPlacementDateAuditField: SingleAuditFieldDto | null;
  mediationIsInTrial: boolean | null;
  mediationIsInTrialNote: string | null;
  mediationIsInTrialAuditField: SingleAuditFieldDto | null;
  mediationPlacementSuccessful: boolean | null;
  mediationPlacementSuccessfulNote: string | null;
  mediationPlacementSuccessfulAuditField: SingleAuditFieldDto | null;
  mediationPhonePlacementSupervision: boolean | null;
  mediationPhonePlacementSupervisionNote: string | null;
  mediationPhonePlacementSupervisionAuditField: SingleAuditFieldDto | null;
  mediationOnPremisePlacementSupervision: boolean | null;
  mediationOnPremisePlacementSupervisionNote: string | null;
  mediationOnPremisePlacementSupervisionAuditField: SingleAuditFieldDto | null;
  mediationCompleted: boolean | null;
  mediationCompletedNote: string | null;
  mediationCompletedAuditField: SingleAuditFieldDto | null;
}

type MediationAuditFields =
  | 'mediationHasProspectAuditField'
  | 'mediationHasPlacementDateAuditField'
  | 'mediationIsInTrialAuditField'
  | 'mediationPlacementSuccessfulAuditField'
  | 'mediationPhonePlacementSupervisionAuditField'
  | 'mediationOnPremisePlacementSupervisionAuditField'
  | 'mediationCompletedAuditField';
export type UpdateCompactPlacementDto = Omit<CompactPlacementDto, MediationAuditFields>;

export enum VeterinaryExaminationCondition {
  Healthy = 'Healthy',
  IllnessFound = 'IllnessFound',
}

export enum IllnessKind {
  CatFlu = 'CatFlu',
  Fungus = 'Fungus',
  EyeInfection = 'EyeInfection',
}

export enum TransferMaterial {
  Medication = 'Medication',
  LabResults = 'LabResults',
  VaccinationDocument = 'VaccinationDocument',
  FosterAnimalPassport = 'FosterAnimalPassport',
}
