<a [routerLinkActiveOptions]="{ exact: false }" [routerLink]="navItem.path" class="nav-item" routerLinkActive="is-active">
  <ng-icon *ngIf="navItem.icon !== undefined" [name]="navItem.icon" size="20"></ng-icon>
  <div *ngIf="navItem.iconPath" class="custom-svg-icon-container">
    <img [alt]="navItem.iconAlt" [src]="navItem.iconPath" class="custom-svg-icon-container__icon" />
  </div>
  <span class="text">{{ navItem.text | translate }}</span>

  <span class="right-content">
    <ng-content></ng-content>
  </span>
</a>
