import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { BoxDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { DatePickerRangeComponent, DateRangeGroup } from '@core/components/date-picker/date-picker-range.component';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { AnimalState, CaseEntryType } from '@core/models/general';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';

import { TaskCategoryDto, TaskCategoryGroup, TaskStatus } from '../../dtos/task.dto';
import { NextStepDirective, StepDirective, StepViewComponent } from '../step-view/step-view.component';
import { ActiveFilterGroupComponent } from './active-filter-group/active-filter-group.component';
import { AnimalStateFilter, BoxFilter, CaseEntryTypeFilter, StatusFilter, TaskCategoryFilter, TaskFilter } from './model/task-filter';
import { SelectedFilterItemComponent } from './selected-filter-item/selected-filter-item.component';
import { SingleFilterListComponent } from './single-filter-list/single-filter-list.component';

@Component({
  selector: 'tgn-task-filter',
  standalone: true,
  imports: [
    MatMenuModule,
    TranslateModule,
    MatButtonModule,
    IconComponent,
    StepViewComponent,
    NextStepDirective,
    StepDirective,
    SingleFilterListComponent,
    EnumDisplayPipe,
    AsyncPipe,
    ToRadioChoicePipe,
    SelectedFilterItemComponent,
    MatDatepickerModule,
    MatInputModule,
    FormElementComponent,
    DatePickerRangeComponent,
    DatePipe,
    ActiveFilterGroupComponent,
  ],
  templateUrl: './task-filter.component.html',
  styleUrl: './task-filter.component.scss',
})
export class TaskFilterComponent implements OnInit {
  @Input({ required: true }) filter!: TaskFilter;
  @Output() onFilterChange = new EventEmitter<TaskFilter>();
  @Input() taskGroupRestriction?: TaskCategoryGroup | null = null;
  @Input() showTaskStatusFilter = false;
  entryTypeChoices: RadioChoice<CaseEntryType>[] = createEnumChoices(CaseEntryType, 'GENERAL.DOMAIN.CaseEntryType.');
  @ViewChild(MatMenuTrigger) menu!: MatMenuTrigger;

  taskCategories$!: Observable<TaskCategoryDto[]>;
  boxes$!: Observable<BoxDto[]>;

  dateRangeGroup!: FormGroup<DateRangeGroup>;
  animalStateChoices: RadioChoice<AnimalState>[] = createEnumChoices(AnimalState, 'GENERAL.DOMAIN.AnimalState.');
  stateChoices: RadioChoice<TaskStatus>[] = createEnumChoices(TaskStatus, 'GENERAL.DOMAIN.TaskStatus.');
  private destroyRef = inject(DestroyRef);

  constructor(
    private baseDataService: BaseDataService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.dateRangeGroup = this.fb.group({
      start: this.fb.control<IsoLocalDateString | null>(null),
      end: this.fb.control<IsoLocalDateString | null>(null),
    });

    this.dateRangeGroup.valueChanges.subscribe(() => {
      if (this.dateRangeGroup.invalid) {
        console.info('skipping update, invalid date range');
        return;
      }
      this.onFilterChange.emit(
        this.filter.setDateFilter({
          from: this.dateRangeGroup.value.start ?? null,
          to: this.dateRangeGroup.value.end ?? null,
          id: 'dateFilter',
        }),
      );
    });
    this.taskCategories$ = this.baseDataService.getBaseData().pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData => {
        const taskCategories = baseData.taskCategories;
        if (!this.taskGroupRestriction) {
          return taskCategories;
        }

        return taskCategories.filter(category => category.group === this.taskGroupRestriction);
      }),
    );

    this.boxes$ = this.baseDataService.getBaseData().pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData => baseData.boxes),
    );

    this.onFilterChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.menu.closeMenu();
    });
  }

  onEntryTypeFilterChange(newFilter: CaseEntryTypeFilter) {
    this.onFilterChange.emit(this.filter.setEntryTypeFilter(newFilter));
  }

  onRemoveSingleEntryTypeFilter(filter: CaseEntryType) {
    const newFilter: CaseEntryTypeFilter = {
      values: this.filter.filters.entryTypeFilter.values.filter(value => value !== filter),
      id: 'entryTypeFilter',
    };
    this.onFilterChange.emit(this.filter.setEntryTypeFilter(newFilter));
  }

  onAnimalStateFilterChange(newFilter: AnimalStateFilter) {
    this.onFilterChange.emit(this.filter.setAnimalStateFilter(newFilter));
  }

  onRemoveSingleAnimalStateFilter(filter: AnimalState) {
    const newFilter: AnimalStateFilter = {
      values: this.filter.filters.animalStateFilter.values.filter(value => value !== filter),
      id: 'animalStateFilter',
    };
    this.onFilterChange.emit(this.filter.setAnimalStateFilter(newFilter));
  }

  clearAllFilters() {
    this.dateRangeGroup.setValue({ start: null, end: null }, { emitEvent: false });
    this.onFilterChange.emit(TaskFilter.empty());
  }

  onCategoryFilterChange(filter: TaskCategoryFilter) {
    this.onFilterChange.emit(this.filter.setCategoryFilter(filter));
  }

  onRemoveSingleCategoryFilter(filter: TaskCategoryDto) {
    const newFilter: TaskCategoryFilter = {
      values: this.filter.filters.categoryFilter.values.filter(value => value !== filter),
      id: 'categoryFilter',
    };
    this.onFilterChange.emit(this.filter.setCategoryFilter(newFilter));
  }

  onRemoveSingleBoxFilter(filter: BoxDto) {
    const newFilter: BoxFilter = {
      values: this.filter.filters.boxFilter.values.filter(value => value !== filter),
      id: 'boxFilter',
    };
    this.onFilterChange.emit(this.filter.setBoxFilter(newFilter));
  }

  onBoxFilterChange($event: BoxFilter) {
    this.onFilterChange.emit(this.filter.setBoxFilter($event));
  }

  onRemoveSingleStatusFilter(filter: TaskStatus) {
    const newFilter: StatusFilter = {
      values: this.filter.filters.statusFilter.values.filter(value => value !== filter),
      id: 'statusFilter',
    };
    this.onFilterChange.emit(this.filter.setStatusFilter(newFilter));
  }

  onStateFilterChange($event: StatusFilter) {
    this.onFilterChange.emit(this.filter.setStatusFilter($event));
  }

  onRemoveDateFilter() {
    this.dateRangeGroup.setValue({ start: null, end: null }, { emitEvent: false });
    this.onFilterChange.emit(this.filter.setDateFilter({ from: null, to: null, id: 'dateFilter' }));
  }
}
