<div (keydown.tab)="$event.stopPropagation()" class="time-input-container">
  <mat-form-field appearance="outline">
    <input
      [disabled]="isDisabled"
      [formControl]="hoursControl"
      [placeholder]="'hh'"
      cdkFocusInitial="true"
      class="time-input"
      matInput
      type="text"
    />
  </mat-form-field>
  <span class="time-separator">:</span>
  <mat-form-field appearance="outline">
    <input [disabled]="isDisabled" [formControl]="minutesControl" [placeholder]="'mm'" class="time-input" matInput type="text" />
  </mat-form-field>
</div>
