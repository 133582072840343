import { A11yModule } from '@angular/cdk/a11y';
import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'tgn-time-input',
  standalone: true,
  imports: [ReactiveFormsModule, A11yModule, MatFormField, MatInput],
  templateUrl: './time-input.component.html',
  styleUrl: './time-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TimeInputComponent,
      multi: true,
    },
  ],
})
export class TimeInputComponent implements ControlValueAccessor, OnInit {
  isDisabled = false;

  hoursControl = new FormControl('', [Validators.required, Validators.min(0), Validators.max(23)]);
  minutesControl = new FormControl('', [Validators.required, Validators.min(0), Validators.max(59)]);
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onChange!: (value: string) => void;
  onTouched!: () => void;

  ngOnInit() {
    this.hoursControl.valueChanges.subscribe(() => {
      this.update();
    });
    this.minutesControl.valueChanges.subscribe(() => {
      this.update();
    });
  }

  writeValue(value: string | null): void {
    if (value === null) {
      this.minutesControl.setValue(null, { emitEvent: false });
      this.hoursControl.setValue(null, { emitEvent: false });
      return;
    }
    const [hours, minutes] = value.split(':');
    this.minutesControl.setValue(minutes, { emitEvent: false });
    this.hoursControl.setValue(hours, { emitEvent: false });
  }

  registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private update() {
    const hours = this.hoursControl.value?.padStart(2, '0') ?? '00';
    const minutes = this.minutesControl.value?.padStart(2, '0') ?? '00';

    const time = `${hours}:${minutes}`;
    this.onChange(time);
    this.onTouched();
  }
}
