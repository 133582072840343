import { HttpParams } from '@angular/common/http';
import { BoxFilter, CaseEntryTypeFilter, addHttpParams, newFilter } from '@care/components/task-filter/model/task-filter';
import { GroupDto, SpeciesDto, SubGroupDto } from '@case/dtos/base-data.dto';
import { AnimalSex, AnimalState, AnimalType, PlacementState, PlacementType } from '@core/models/general';
import { IsoLocalDateString } from '@core/utils/date';

export interface Filter<T, Id extends string> {
  values: T[];
  id: Id;
}

export enum GeneralAnimalFilterChoice {
  All = 'All',
  New = 'New',
  InCare = 'InCare',
  Placeable = 'Placeable',
  Exited = 'Exited',
}

export type AnimalStateFilter = Filter<AnimalState, 'stateFilter'>;
export type GroupFilter = Filter<GroupDto, 'groupFilter'>;
export type SubGroupFilter = Filter<SubGroupDto, 'subGroupFilter'>;
export type SpeciesFilter = Filter<SpeciesDto, 'speciesFilter'>;
export type AnimalTypeFilter = Filter<AnimalType, 'animalTypeFilter'>;
export type AnimalSexFilter = Filter<AnimalSex, 'animalSexFilter'>;
export type PlacementStateFilter = Filter<PlacementState, 'placementStateFilter'>;
export type PlacementTypeFilter = Filter<PlacementType, 'placementTypeFilter'>;

export interface DateFilter {
  from: IsoLocalDateString | null;
  to: IsoLocalDateString | null;
  id: 'dateFilter';
}

export class AnimalFilter {
  filters: {
    stateFilter: AnimalStateFilter;
    entryTypeFilter: CaseEntryTypeFilter;
    animalTypeFilter: AnimalTypeFilter;
    groupFilter: GroupFilter;
    subGroupFilter: SubGroupFilter;
    speciesFilter: SpeciesFilter;
    boxFilter: BoxFilter;
    entryDateFilter: DateFilter;
    animalSexFilter: AnimalSexFilter;
    placementStateFilter: PlacementStateFilter;
    placementTypeFilter: PlacementTypeFilter;
  };

  constructor(filters: {
    stateFilter: AnimalStateFilter;
    entryTypeFilter: CaseEntryTypeFilter;
    animalTypeFilter: AnimalTypeFilter;
    groupFilter: GroupFilter;
    subGroupFilter: SubGroupFilter;
    speciesFilter: SpeciesFilter;
    boxFilter: BoxFilter;
    entryDateFilter: DateFilter;
    animalSexFilter: AnimalSexFilter;
    placementStateFilter: PlacementStateFilter;
    placementTypeFilter: PlacementTypeFilter;
  }) {
    this.filters = filters;
  }

  static empty(): AnimalFilter {
    return new AnimalFilter({
      stateFilter: newFilter('stateFilter'),
      entryTypeFilter: newFilter('entryTypeFilter'),
      animalTypeFilter: newFilter('animalTypeFilter'),
      groupFilter: newFilter('groupFilter'),
      subGroupFilter: newFilter('subGroupFilter'),
      speciesFilter: newFilter('speciesFilter'),
      boxFilter: newFilter('boxFilter'),
      entryDateFilter: { from: null, to: null, id: 'dateFilter' },
      animalSexFilter: newFilter('animalSexFilter'),
      placementStateFilter: newFilter('placementStateFilter'),
      placementTypeFilter: newFilter('placementTypeFilter'),
    });
  }

  setStateFilter(filter: AnimalStateFilter): AnimalFilter {
    return new AnimalFilter({ ...this.filters, stateFilter: filter });
  }

  setEntryTypeFilter(entryTypeFilter: CaseEntryTypeFilter): AnimalFilter {
    return new AnimalFilter({ ...this.filters, entryTypeFilter: entryTypeFilter });
  }

  setAnimalTypeFilter(filter: AnimalTypeFilter): AnimalFilter {
    return new AnimalFilter({ ...this.filters, animalTypeFilter: filter });
  }

  setGroupFilter(filter: GroupFilter): AnimalFilter {
    return new AnimalFilter({ ...this.filters, groupFilter: filter });
  }

  setSubGroupFilter(filter: SubGroupFilter): AnimalFilter {
    return new AnimalFilter({ ...this.filters, subGroupFilter: filter });
  }

  setSpeciesFilter(filter: SpeciesFilter): AnimalFilter {
    return new AnimalFilter({ ...this.filters, speciesFilter: filter });
  }

  setBoxFilter(filter: BoxFilter): AnimalFilter {
    return new AnimalFilter({ ...this.filters, boxFilter: filter });
  }

  setEntryDateFilter(filter: DateFilter): AnimalFilter {
    return new AnimalFilter({ ...this.filters, entryDateFilter: filter });
  }

  setAnimalSexFilter(filter: AnimalSexFilter): AnimalFilter {
    return new AnimalFilter({ ...this.filters, animalSexFilter: filter });
  }

  setPlacementStateFilter(filter: PlacementStateFilter): AnimalFilter {
    return new AnimalFilter({ ...this.filters, placementStateFilter: filter });
  }

  setPlacementTypeFilter(filter: PlacementTypeFilter): AnimalFilter {
    return new AnimalFilter({ ...this.filters, placementTypeFilter: filter });
  }

  anyFilterActive(): boolean {
    return Object.values(this.filters).some((filter: { values?: unknown[]; from?: unknown; to?: unknown }) => {
      if (filter?.values) {
        return filter.values.length > 0;
      } else {
        return filter.from || filter.to;
      }
    });
  }

  toAnimalFilterDto(): HttpParams {
    let params = new HttpParams();

    const filterParamName = 'filter';

    params = addHttpParams(this.filters.entryTypeFilter.values, `${filterParamName}.entryTypeFilter`, params);
    params = addHttpParams(this.filters.stateFilter.values, `${filterParamName}.stateFilter`, params);
    params = addHttpParams(
      this.filters.boxFilter.values.map(it => it.id),
      `${filterParamName}.boxFilter`,
      params,
    );

    params = addHttpParams(this.filters.animalTypeFilter.values, `${filterParamName}.animalTypeFilter`, params);
    params = addHttpParams(
      this.filters.groupFilter.values.map(it => it.name),
      `${filterParamName}.groupFilter`,
      params,
    );

    params = addHttpParams(
      this.filters.subGroupFilter.values.map(it => it.name),
      `${filterParamName}.subGroupFilter`,
      params,
    );

    params = addHttpParams(
      this.filters.speciesFilter.values.map(it => it.name),
      `${filterParamName}.speciesFilter`,
      params,
    );

    params = addHttpParams(this.filters.animalSexFilter.values, `${filterParamName}.animalSexFilter`, params);

    params = addHttpParams(this.filters.placementStateFilter.values, `${filterParamName}.placementStateFilter`, params);

    params = addHttpParams(this.filters.placementTypeFilter.values, `${filterParamName}.placementTypeFilter`, params);

    if (this.filters.entryDateFilter.from) {
      params = params.append(`${filterParamName}.entryDateFromFilter`, this.filters.entryDateFilter.from);
    }
    if (this.filters.entryDateFilter.to) {
      params = params.append(`${filterParamName}.entryDateToFilter`, this.filters.entryDateFilter.to);
    }
    return params;
  }
}
