<h1>Interessent erfassen</h1>

@if (form) {
  <div class="buttons tgn-context-actions" *tgnContextActions>
    <button class="ml-auto" mat-flat-button (click)="close()">
      {{ (form.pristine ? 'GENERAL.BUTTON.PREVIOUS' : 'GENERAL.BUTTON.CANCEL') | translate }}
    </button>

    <button
      [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE"
      class="tgn-button"
      mat-flat-button
      (click)="save()"
      [disabled]="form.invalid || form.pristine"
    >
      {{ 'GENERAL.BUTTON.SAVE' | translate }}
    </button>
  </div>

  <div class="form-grid grid-cols-6">
    <div class="row row-span-sm-2">
      <div class="item col-span-6">
        <tgn-form-element [control]="form.controls.animals" id="animals" label="Tiere">
          <ng-template formElement let-control="control" let-id="id">
            <tgn-select [choices]="animalChoices" [control]="control" [compareIds]="true" [multiple]="true" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row row-span-sm-2">
      <div class="item col-span-3 col-span-sm-6">
        <tgn-form-element [control]="form.controls.contact" id="contact" label="Kontakt">
          <ng-template formElement let-control="control" let-id="id">
            <tgn-choose-contact [control]="form.controls.contact" [id]="id" [allowNewCreation]="true" />
          </ng-template>
        </tgn-form-element>
      </div>

      <div class="item col-span-3 col-span-sm-6">
        <tgn-form-element [control]="form.controls.state" id="state" label="Status">
          <ng-template formElement let-control="control" let-id="id">
            <tgn-select [choices]="statusChoices" [control]="control" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <h3>Abklärungen</h3>

    <div class="row row-span-sm-2">
      <div class="item col-span-6 boolean-options">
        <tgn-form-element [control]="form.controls.consentSiteSupervision" id="consentSiteSupervision">
          <ng-template formElement let-control="control" let-id="id">
            <mat-checkbox [formControl]="control" [id]="id">
              {{ 'ENTITY.Prospect.consentSiteSupervision' | translate }}
            </mat-checkbox>
          </ng-template>
        </tgn-form-element>

        <tgn-form-element [control]="form.controls.writtenAuthorizationToKeepAnimals" id="writtenAuthorizationToKeepAnimals">
          <ng-template formElement let-control="control" let-id="id">
            <mat-checkbox [formControl]="control" [id]="id">
              {{ 'ENTITY.Prospect.writtenAuthorizationToKeepAnimals' | translate }}
            </mat-checkbox>
          </ng-template>
        </tgn-form-element>

        @if (hasCat) {
          <tgn-form-element [control]="form.controls.catLockAvailable" id="catLockAvalable">
            <ng-template formElement let-control="control" let-id="id">
              <mat-checkbox [formControl]="control" [id]="id">
                {{ 'ENTITY.Prospect.catLockAvailable' | translate }}
              </mat-checkbox>
            </ng-template>
          </tgn-form-element>
        }

        @if (hasBird || hasSmallAnimalOrExotic) {
          <div class="row row-span-sm-2">
            <div class="item col-span-6">
              <tgn-form-element [control]="form.controls.imageOfAccommodationPresented" id="imageOfAccommodationPresented">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-checkbox [formControl]="control" [id]="id">
                    {{ 'ENTITY.Prospect.imageOfAccommodationPresented' | translate }}
                  </mat-checkbox>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        }
      </div>
    </div>
    <div class="row row-span-sm-2">
      <div class="item col-span-6">
        <tgn-form-element [control]="form.controls.hasAnimalsInHousehold" id="hasAnimalsInHousehold">
          <ng-template formElement let-control="control" let-id="id">
            <mat-checkbox [formControl]="control" [id]="id">
              {{ 'ENTITY.Prospect.hasAnimalsInHousehold' | translate }}
            </mat-checkbox>
          </ng-template>
        </tgn-form-element>
        <tgn-form-element
          class="mt-small"
          [control]="form.controls.hasAnimalsInHouseholdDetails"
          id="hasAnimalsInHouseholdDetails"
          [label]="'ENTITY.Prospect.hasAnimalsInHouseholdDetails' | translate"
        >
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <textarea
                matInput
                [formControl]="control"
                [id]="id"
                cdkAutosizeMinRows="5"
                cdkTextareaAutosize
                cdkAutosizeMaxRows="10"
              ></textarea>
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
    @if (hasSmallAnimalOrExotic || hasBird) {
      <div class="row row-span-sm-2">
        <div class="item col-span-6">
          <tgn-form-element
            [control]="form.controls.livingSituation"
            id="livingSituation"
            [label]="'ENTITY.Prospect.livingSituation' | translate"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  [formControl]="control"
                  [id]="id"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    }

    @if (hasDog) {
      <div class="row row-span-sm-2">
        <div class="item col-span-6">
          <tgn-form-element [control]="form.controls.amicusId" id="amicusId" [label]="'ENTITY.Prospect.amicusId' | translate">
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <input matInput [formControl]="control" [id]="id" [placeholder]="'ENTITY.Prospect.amicusId' | translate" type="text" />
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>
    }

    <h3>Unkostenbeitrag</h3>
    <div class="row row-span-sm-2">
      <div class="item col-span-3 col-span-sm-6">
        <tgn-form-element [control]="form.controls.contribution" id="contribution" [label]="'ENTITY.Prospect.contribution' | translate">
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input matInput [formControl]="control" [id]="id" placeholder="CHF" type="number" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>

      <div class="item col-span-3 col-span-sm-6">
        <tgn-form-element [control]="form.controls.donation" id="donation" [label]="'ENTITY.Prospect.donation' | translate">
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input matInput [formControl]="control" [id]="id" placeholder="CHF" type="number" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    @if (form.controls.animals.value.length > 0) {
      <h3 class="mb-large">Vertrag</h3>
    }
    <div class="row">
      <div class="item">
        @for (animal of form.controls.animals.value; track animal.id) {
          <button
            (click)="downloadContract($event, prospect, animal)"
            class="tgn-black-button mt-lage download-contract-button"
            mat-flat-button
          >
            @if (form.controls.state.value === ProspectState.ContractConcluded) {
              <span>Vertrag {{ animal.name }}</span>
            } @else {
              <span>Vorschau {{ animal.name }}</span>
            }
          </button>
        }
      </div>
    </div>
  </div>
}
