import { Component } from '@angular/core';
import { BaseDataCategoriesByGroupComponent } from '@baseData/components/base-data-categories-by-group/base-data-categories-by-group.component';
import { TaskCategoryGroup } from '@care/dtos/task.dto';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';

@Component({
  selector: 'app-base-data-tasks-page',
  standalone: true,
  imports: [BaseDataCategoriesByGroupComponent, CollapsibleComponent, NarrowPageContainerComponent],
  templateUrl: './base-data-tasks-page.component.html',
  styleUrl: './base-data-tasks-page.component.scss',
})
export class BaseDataTasksPageComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly TaskCategoryGroup = TaskCategoryGroup;
}
