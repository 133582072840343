import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ContactService } from '@contact/service/contact-service';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { ModalComponent } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';

export interface ContactDialogResult {}

interface DialogData {}

type ExportContactForm = FormGroup<{
  includeNoMailings: FormControl<boolean>;
}>;

@Component({
  selector: 'app-export-contact-dialog',
  standalone: true,
  imports: [
    CdkTextareaAutosize,
    FormElementComponent,
    MatDialogModule,
    TranslateModule,
    MatButtonModule,
    MatCheckbox,
    FormElementDirective,
    ReactiveFormsModule,
  ],
  templateUrl: './export-contact-dialog.component.html',
  styleUrl: './export-contact-dialog.component.scss',
})
export class ExportContactDialogComponent extends ModalComponent<DialogData, ContactDialogResult> {
  form: ExportContactForm;

  data: DialogData;

  constructor(
    fb: FormBuilder,
    dialogRef: MatDialogRef<ExportContactDialogComponent, ContactDialogResult>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private contactService: ContactService,
    private snackbar: SnackbarService,
    private accessService: AccessService,
  ) {
    super(dialogRef);
    this.data = data;
    this.form = createForm(fb);
    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Case);
  }

  close() {
    const value = this.form.getRawValue();
    this.contactService.downloadContactsCsv(value.includeNoMailings).subscribe({
      next: blob => {
        const url = window.URL.createObjectURL(blob.body!);
        const a = document.createElement('a');
        a.href = url;
        a.download = blob.headers.get('Content-Disposition')!.split('filename=')[1];
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        this.snackbar.showSuccessMessage('CSV Datei wurde heruntergeladen ');
        this.closeWithResult({});
      },
      error: error => {
        console.error(error);
        this.snackbar.showErrorMessage('CSV Datei konnte nicht heruntergeladen werden.');
      },
    });
  }
}

export function createForm(fb: FormBuilder): ExportContactForm {
  return fb.group(
    {
      includeNoMailings: fb.nonNullable.control<boolean>(false),
    },
    {},
  );
}
