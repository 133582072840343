import { AsyncPipe, NgClass } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { CreateRelationKindDto, UpdateRelationKindDto } from '@baseData/dtos/create-relation-kind.dto';
import { RelationKindDto, RelationKindId } from '@baseData/dtos/relation-kind.dto';
import { RelationKindService } from '@baseData/services/relation-kind.service';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SelectComponent } from '@core/components/select/select.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { defaultDebounce } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { uniqueNameValidator } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { shareReplay } from 'rxjs';

export type UpdateRelationKindForm = FormGroup<{
  active: FormControl<boolean>;
}>;

export type NewRelationKindForm = FormGroup<{
  name: FormControl<string | null>;
}>;

interface TableConfig {}

@Component({
  selector: 'tgn-base-data-relationkind',
  standalone: true,
  imports: [
    AsyncPipe,
    ConfirmationDialogDirective,
    EnumDisplayPipe,
    FormElementComponent,
    ScrollableTableComponent,
    MatTableModule,
    TypesafeMatTableModule,
    TranslateModule,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    SelectComponent,
    MatButton,
    MatError,
    FieldErrorTranslationPipe,
    NgClass,
  ],
  templateUrl: './base-data-relationkind.component.html',
  styleUrl: './base-data-relationkind.component.scss',
})
export class BaseDataRelationkindComponent implements OnInit {
  columns = ['name', 'active'];
  appRoutes = routes_config;

  newRelationKindForm!: NewRelationKindForm;
  editRelationKindFormGroups: Map<RelationKindId, UpdateRelationKindForm> = new Map<RelationKindId, UpdateRelationKindForm>();

  dataSource!: TigonDatasource<RelationKindDto, TableConfig>;
  activeInactiveChoice: RadioChoice<Boolean>[] = [
    {
      label: 'GENERAL.FORM.LABEL.Active',
      object: true,
    },
    {
      label: 'GENERAL.FORM.LABEL.Inactive',
      object: false,
    },
  ];

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private relationKindService: RelationKindService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.dataSource = new TigonDatasource<RelationKindDto, TableConfig>(
      {},
      () => {
        return this.relationKindService.getAll();
      },
      this.destroyRef,
    );

    this.dataSource.getData().subscribe(data => {
      this.editRelationKindFormGroups = new Map();
      // only allow to deactivate relationkinds without a tag
      data
        .filter((relationKind: RelationKindDto) => {
          return relationKind.tag === null;
        })
        .forEach((relationKind: RelationKindDto) => {
          const relationKindFormGroup: UpdateRelationKindForm = this.fb.group({
            active: this.fb.nonNullable.control<boolean>(relationKind.active, [Validators.required]),
          });

          this.accessService.disableBasedOnRole(relationKindFormGroup, RestrictedSection.Backoffice);
          this.editRelationKindFormGroups.set(relationKind.id, relationKindFormGroup);
          this.autoUpdateRows(relationKindFormGroup, relationKind);
        });
    });

    this.createNewRelationKindForm();
  }

  addEntry() {
    const form = this.newRelationKindForm.getRawValue();
    if (!form.name) {
      return;
    }

    const createExitStateDto: CreateRelationKindDto = {
      name: form.name,
    };

    this.relationKindService.create(createExitStateDto).subscribe({
      next: () => {
        this.newRelationKindForm.setValue({
          name: null,
        });
        this.newRelationKindForm.markAsUntouched();
        this.newRelationKindForm.markAsPristine();
        this.snackbar.showSuccessMessage(`Der Beziehungstyp: '${createExitStateDto.name}' wurde erfolgreich erstellt.`);
        this.dataSource.refresh();
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Der Beziehungstyp konnte nicht erstellt werden.');
      },
    });
  }

  private createNewRelationKindForm() {
    this.newRelationKindForm = this.fb.group({
      name: this.fb.control<string | null>(
        null,

        {
          validators: [Validators.required, Validators.minLength(1)],

          asyncValidators: [uniqueNameValidator(this.dataSource.getData().pipe(shareReplay(1)))],
          updateOn: 'change',
        },
      ),
    });

    this.accessService.disableBasedOnRole(this.newRelationKindForm, RestrictedSection.Backoffice);
  }

  private autoUpdateRows(formGroup: UpdateRelationKindForm, relationKind: RelationKindDto) {
    formGroup.valueChanges.pipe(defaultDebounce(), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        if (formGroup.invalid) {
          return;
        }

        const isActive = formGroup.getRawValue().active;

        const dto: UpdateRelationKindDto = {
          active: isActive,
        };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.relationKindService.update(relationKind.id, dto).subscribe(() => {
          this.dataSource.refresh();
        });
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Der Beziehungstyp konnte nicht aktualisiert werden.');
      },
    });
  }
}
