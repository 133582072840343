<h3 class="section-title">Tiere</h3>

@if (case$ | async; as caseDto) {
  @if (animals$ | async; as animals) {
    @if (caseDto.animalType === AnimalType.DomesticAnimal) {
      @for (animal of animals; track animal.id) {
        <tgn-single-animal-placement class="mt-medium" [case$]="case$" [animal]="animal" />
      }
    } @else {
      @if (animals.length > 0) {
        <tgn-single-wild-animal-placement class="mt-medium" [caseDto]="caseDto" [animal]="animals[0]" />
      }
    }
  }
}
