import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateSpeciesDto, UpdateSpeciesDto } from '@baseData/dtos/create-species.dto';
import { SpeciesDto, SpeciesId } from '@case/dtos/base-data.dto';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpeciesService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Inactive exitStates are included here
   */
  getAll(): Observable<SpeciesDto[]> {
    return this.http.get<SpeciesDto[]>(`${this.apiUrl}/master/species`);
  }

  /**
   * Inactive exitStates are included here
   */
  getAllDomesticAnimal(): Observable<SpeciesDto[]> {
    return this.http.get<SpeciesDto[]>(`${this.apiUrl}/master/species/domestic`);
  }

  /**
   * Inactive exitStates are included here
   */
  getAllWildAnimal(): Observable<SpeciesDto[]> {
    return this.http.get<SpeciesDto[]>(`${this.apiUrl}/master/species/wild`);
  }

  getAllActive(): Observable<SpeciesDto[]> {
    return this.http.get<SpeciesDto[]>(`${this.apiUrl}/master/species/active`);
  }

  getById(id: SpeciesId): Observable<SpeciesDto> {
    return this.http.get<SpeciesDto>(`${this.apiUrl}/master/species/${id}`);
  }

  create(dto: CreateSpeciesDto): Observable<SpeciesDto> {
    return this.http.post<SpeciesDto>(`${this.apiUrl}/master/species`, dto);
  }

  update(id: SpeciesId, dto: UpdateSpeciesDto): Observable<SpeciesDto> {
    return this.http.put<SpeciesDto>(`${this.apiUrl}/master/species/${id}`, dto);
  }
}
