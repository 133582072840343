import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TypeSafeMatCellDef, TypeSafeMatRowDef } from '@core/directives/mat-table.directive';

@NgModule({
  declarations: [],
  imports: [CommonModule, TypeSafeMatRowDef, TypeSafeMatCellDef],
  exports: [TypeSafeMatRowDef, TypeSafeMatCellDef],
})
export class TypesafeMatTableModule {}
