import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { NextStepDirective, StepDirective, StepViewComponent } from '@care/components/step-view/step-view.component';
import { ActiveFilterGroupComponent } from '@care/components/task-filter/active-filter-group/active-filter-group.component';
import { BoxFilter, CaseEntryTypeFilter } from '@care/components/task-filter/model/task-filter';
import { SelectedFilterItemComponent } from '@care/components/task-filter/selected-filter-item/selected-filter-item.component';
import { SingleFilterListComponent } from '@care/components/task-filter/single-filter-list/single-filter-list.component';
import { TaskCategoryDto } from '@care/dtos/task.dto';
import { BaseDataDto, BoxDto, GroupDto, MainGroupType, SpeciesDto, SubGroupDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { DatePickerRangeComponent, DateRangeGroup } from '@core/components/date-picker/date-picker-range.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { AnimalSex, AnimalState, AnimalType, CaseEntryType, PlacementState, PlacementType } from '@core/models/general';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { defaultDebounce } from '@core/services/base-service';
import { IsoLocalDateString } from '@core/utils/date';
import { createEnumChoices } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, combineLatest, map, shareReplay, startWith } from 'rxjs';

import {
  AnimalFilter,
  AnimalSexFilter,
  AnimalStateFilter,
  AnimalTypeFilter,
  GroupFilter,
  PlacementStateFilter,
  PlacementTypeFilter,
  SpeciesFilter,
  SubGroupFilter,
} from './model/animal-filter';

@Component({
  selector: 'tgn-animal-filter',
  standalone: true,
  imports: [
    ActiveFilterGroupComponent,
    AsyncPipe,
    DatePickerRangeComponent,
    MatMenuModule,
    MatButtonModule,
    TranslateModule,
    IconComponent,
    SelectedFilterItemComponent,
    StepViewComponent,
    StepDirective,
    NextStepDirective,
    SingleFilterListComponent,
    EnumDisplayPipe,
    DatePipe,
    ToRadioChoicePipe,
  ],
  templateUrl: './animal-filter.component.html',
  styleUrl: './animal-filter.component.scss',
})
export class AnimalFilterComponent implements OnInit {
  @Input({ required: true }) filter!: AnimalFilter;
  @Output() onFilterChange = new EventEmitter<AnimalFilter>();
  entryTypeChoices: RadioChoice<CaseEntryType>[] = createEnumChoices(CaseEntryType, 'GENERAL.DOMAIN.CaseEntryType.');
  @ViewChild(MatMenuTrigger) menu!: MatMenuTrigger;

  taskCategories$!: Observable<TaskCategoryDto[]>;
  boxes$!: Observable<BoxDto[]>;

  dateRangeGroup!: FormGroup<DateRangeGroup>;
  animalStateChoices: RadioChoice<AnimalState>[] = createEnumChoices(AnimalState, 'GENERAL.DOMAIN.AnimalState.');

  groups$!: Observable<GroupDto[]>;
  subGroups$!: Observable<SubGroupDto[]>;
  species$!: Observable<SpeciesDto[]>;
  animalTypeChoices: RadioChoice<AnimalType>[] = createEnumChoices(AnimalType, 'GENERAL.DOMAIN.AnimalType.');

  baseData$!: Observable<BaseDataDto>;

  // currently available choices based on filters selected (for example only show certain species/subGroup etc if some group / subGroup is selected)
  availableAnimalTypes$!: Observable<AnimalType[]>;
  availableGroups$!: Observable<GroupDto[]>;
  availableSubGroups$!: Observable<SubGroupDto[]>;
  availableSpecies$!: Observable<SpeciesDto[]>;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  animalSexChoices: RadioChoice<AnimalSex>[] = createEnumChoices(AnimalSex, 'GENERAL.DOMAIN.AnimalSex.');

  placementStateChoices: RadioChoice<PlacementState>[] = createEnumChoices<PlacementState>(
    PlacementState,
    'GENERAL.DOMAIN.PlacementState.',
  );

  placementTypeChoices: RadioChoice<PlacementType>[] = createEnumChoices<PlacementType>(PlacementType, 'GENERAL.DOMAIN.PlacementType.');

  private destroyRef = inject(DestroyRef);

  constructor(
    private baseDataService: BaseDataService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.baseData$ = this.baseDataService.getBaseData();

    this.getGroupsSubGroupsSpecies();

    this.setAvailableChoicesBasedOnFilter();

    this.dateRangeGroup = this.fb.group({
      start: this.fb.control<IsoLocalDateString | null>(null),
      end: this.fb.control<IsoLocalDateString | null>(null),
    });

    this.dateRangeGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
      if (this.dateRangeGroup.invalid) {
        console.info('skipping update, invalid date range');
        return;
      }
      this.onFilterChange.emit(
        this.filter.setEntryDateFilter({
          from: this.dateRangeGroup.value.start ?? null,
          to: this.dateRangeGroup.value.end ?? null,
          id: 'dateFilter',
        }),
      );
    });
    this.taskCategories$ = this.baseDataService.getBaseData().pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData => baseData.taskCategories),
    );
    this.boxes$ = this.baseDataService.getBaseData().pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData => baseData.boxes),
    );

    this.onFilterChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.menu.closeMenu();
    });
  }

  getGroupsSubGroupsSpecies() {
    this.groups$ = this.baseData$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData => [...baseData.domesticAnimal.groups, ...baseData.wildAnimal.groups].sort((a, b) => a.name.localeCompare(b.name))),
    );

    this.subGroups$ = this.baseData$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData =>
        [...baseData.domesticAnimal.subGroups, ...baseData.wildAnimal.subGroups].sort((a, b) => a.name.localeCompare(b.name)),
      ),
    );

    this.species$ = this.baseDataService.getBaseData().pipe(
      takeUntilDestroyed(this.destroyRef),
      map(baseData => [...baseData.domesticAnimal.species, ...baseData.wildAnimal.species].sort((a, b) => a.name.localeCompare(b.name))),
    );
  }

  setAvailableChoicesBasedOnFilter() {
    const filterChange$ = this.onFilterChange.pipe(startWith(this.filter), shareReplay(1));

    this.availableAnimalTypes$ = filterChange$.pipe(
      startWith(this.filter),
      map((filter: AnimalFilter) => {
        const filteredAnimalTypes = filter.filters.animalTypeFilter.values;
        return filteredAnimalTypes.length > 0 ? filteredAnimalTypes : [AnimalType.DomesticAnimal, AnimalType.WildAnimal];
      }),
      shareReplay(1),
    );

    this.availableGroups$ = combineLatest([this.groups$, this.availableAnimalTypes$]).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(([groups, availableAnimalTypes]) => {
        const filtered = groups.filter((it: GroupDto) => {
          const animalType = it.groupType === MainGroupType.DomesticAnimal ? AnimalType.DomesticAnimal : AnimalType.WildAnimal;
          return availableAnimalTypes.includes(animalType);
        });
        return filtered;
      }),
      shareReplay(1),
    );

    this.availableSubGroups$ = combineLatest([this.subGroups$, this.availableGroups$, filterChange$]).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(([subGroups, availableGroup, filter]) => {
        const filteredGroups = filter.filters.groupFilter.values.length > 0 ? filter.filters.groupFilter.values : availableGroup;
        return subGroups.filter((it: SubGroupDto) => {
          return filteredGroups.some(group => group.id === it.groupId);
        });
      }),
      shareReplay(1),
    );

    this.availableSpecies$ = combineLatest([this.species$, this.availableSubGroups$, filterChange$]).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(([species, availableSubGroups, filter]) => {
        const filteredSubGroups =
          filter.filters.subGroupFilter.values.length > 0 ? filter.filters.subGroupFilter.values : availableSubGroups;
        return species.filter((it: SpeciesDto) => filteredSubGroups.some(subGroup => subGroup.id === it.subGroupId));
      }),
      shareReplay(1),
    );
  }

  onAnimalStateFilterChange(newFilter: AnimalStateFilter) {
    this.onFilterChange.emit(this.filter.setStateFilter(newFilter));
  }

  onRemoveSingleAnimalStateFilter(filter: AnimalState) {
    const newFilter: AnimalStateFilter = {
      values: this.filter.filters.stateFilter.values.filter(value => value !== filter),
      id: 'stateFilter',
    };
    this.onFilterChange.emit(this.filter.setStateFilter(newFilter));
  }

  onEntryTypeFilterChange(newFilter: CaseEntryTypeFilter) {
    this.onFilterChange.emit(this.filter.setEntryTypeFilter(newFilter));
  }

  onRemoveSingleEntryTypeFilter(filter: CaseEntryType) {
    const newFilter: CaseEntryTypeFilter = {
      values: this.filter.filters.entryTypeFilter.values.filter(value => value !== filter),
      id: 'entryTypeFilter',
    };
    this.onFilterChange.emit(this.filter.setEntryTypeFilter(newFilter));
  }

  onAnimalTypeFilterChange($event: AnimalTypeFilter) {
    this.onFilterChange.emit(this.filter.setAnimalTypeFilter($event));
  }

  onRemoveSingleAnimalTypeFilter(filter: AnimalType) {
    const newFilter: AnimalTypeFilter = {
      values: this.filter.filters.animalTypeFilter.values.filter(value => value !== filter),
      id: 'animalTypeFilter',
    };
    this.onFilterChange.emit(this.filter.setAnimalTypeFilter(newFilter));
  }

  onRemoveSingleAnimalSexFilter(filter: AnimalSex) {
    const newFilter: AnimalSexFilter = {
      values: this.filter.filters.animalSexFilter.values.filter(value => value !== filter),
      id: 'animalSexFilter',
    };
    this.onFilterChange.emit(this.filter.setAnimalSexFilter(newFilter));
  }

  onRemoveSinglePlacementStateFilter(filter: PlacementState) {
    const newFilter: PlacementStateFilter = {
      values: this.filter.filters.placementStateFilter.values.filter(value => value !== filter),
      id: 'placementStateFilter',
    };
    this.onFilterChange.emit(this.filter.setPlacementStateFilter(newFilter));
  }

  onRemoveSinglePlacementTypeFilter(filter: string) {
    const newFilter: PlacementTypeFilter = {
      values: this.filter.filters.placementTypeFilter.values.filter(value => value !== filter),
      id: 'placementTypeFilter',
    };
    this.onFilterChange.emit(this.filter.setPlacementTypeFilter(newFilter));
  }

  onGroupFilterChange($event: GroupFilter) {
    this.onFilterChange.emit(this.filter.setGroupFilter($event));
  }

  onRemoveSingleGroupFilter(filter: GroupDto) {
    const newFilter: GroupFilter = {
      values: this.filter.filters.groupFilter.values.filter(value => value !== filter),
      id: 'groupFilter',
    };
    this.onFilterChange.emit(this.filter.setGroupFilter(newFilter));
  }

  onSubGroupFilterChange($event: SubGroupFilter) {
    this.onFilterChange.emit(this.filter.setSubGroupFilter($event));
  }

  onSpeciesFilterChange($event: SpeciesFilter) {
    this.onFilterChange.emit(this.filter.setSpeciesFilter($event));
  }

  onAnimalSexFilterChange($event: AnimalSexFilter) {
    this.onFilterChange.emit(this.filter.setAnimalSexFilter($event));
  }

  onPlacementStateFilterChange($event: PlacementStateFilter) {
    this.onFilterChange.emit(this.filter.setPlacementStateFilter($event));
  }

  onPlacementTypeFilterChange($event: PlacementTypeFilter) {
    this.onFilterChange.emit(this.filter.setPlacementTypeFilter($event));
  }

  onRemoveSingleSubGroupFilter(filter: SubGroupDto) {
    const newFilter: SubGroupFilter = {
      values: this.filter.filters.subGroupFilter.values.filter(value => value !== filter),
      id: 'subGroupFilter',
    };
    this.onFilterChange.emit(this.filter.setSubGroupFilter(newFilter));
  }

  onRemoveSingleSpeciesFilter(filter: SpeciesDto) {
    const newFilter: SpeciesFilter = {
      values: this.filter.filters.speciesFilter.values.filter(value => value !== filter),
      id: 'speciesFilter',
    };
    this.onFilterChange.emit(this.filter.setSpeciesFilter(newFilter));
  }

  onBoxFilterChange($event: BoxFilter) {
    this.onFilterChange.emit(this.filter.setBoxFilter($event));
  }

  onRemoveSingleBoxFilter(filter: BoxDto) {
    const newFilter: BoxFilter = {
      values: this.filter.filters.boxFilter.values.filter(value => value !== filter),
      id: 'boxFilter',
    };
    this.onFilterChange.emit(this.filter.setBoxFilter(newFilter));
  }

  onRemoveDateFilter() {
    this.onFilterChange.emit(this.filter.setEntryDateFilter({ from: null, to: null, id: 'dateFilter' }));
    this.dateRangeGroup.setValue({ start: null, end: null }, { emitEvent: false });
  }

  clearAllFilters() {
    this.onFilterChange.emit(AnimalFilter.empty());
    this.dateRangeGroup.setValue({ start: null, end: null }, { emitEvent: false });
  }
}
