import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnimalId } from '@animal/dtos/animal.dto';
import { PageResult } from '@core/data/tigon-datasource';
import { BaseService } from '@core/services/base-service';
import { PaginatedParams } from '@core/utils/pagination';
import { Observable, catchError, firstValueFrom, map, of } from 'rxjs';

import { FileCollectionDto } from '../dto/file-collection.dto';
import { FileTagType } from '../dto/file-tag-type';
import { FileBaseDto, FileDto, ImageDto } from '../dto/file.dto';
import { UploadFileType } from '../model/file';

export interface UploadResult<TFileDto extends FileBaseDto> {
  success: boolean;
  file?: TFileDto;
}

type ImagePaginatedParams = Omit<PaginatedParams, 'sort' | 'query'>;

export enum UploadEntityType {
  AnimalTask = 'AnimalTask',
}

export interface UploadEntity {
  id: string;
  type: UploadEntityType;
}

@Injectable({
  providedIn: 'root',
})
export class FileService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getFilesPaginated(
    fileCollection: FileCollectionDto,
    tags: FileTagType[],
    paginationParams: ImagePaginatedParams,
  ): Observable<PageResult<FileDto>> {
    const flattenedParams = {
      ...paginationParams,
      tags: tags.map(it => it.toString()).join(','),
    };
    return this.http.get<PageResult<ImageDto>>(`${this.apiUrl}/files/collection/${fileCollection.id}/files`, { params: flattenedParams });
  }

  createCollection(): Observable<FileCollectionDto> {
    return this.http.post<FileCollectionDto>(`${this.apiUrl}/files/collection`, {});
  }

  renameFile(file: FileDto, newName: string): Observable<void> {
    const body = { filename: newName };
    return this.http.patch<void>(`${this.apiUrl}/files/file/${file.id}/rename`, body);
  }

  async uploadFile(
    fileCollection: FileCollectionDto,
    files: File[],
    tags: FileTagType[],
    uploadEntity?: UploadEntity,
  ): Promise<UploadResult<FileDto>[]> {
    return this.upload(`${this.apiUrl}/files/upload/collection/${fileCollection.id}`, files, tags, uploadEntity);
  }

  async uploadAnimalProfileImage(id: AnimalId, file: File): Promise<UploadResult<FileBaseDto>> {
    const results = await this.upload(`${this.apiUrl}/files/upload/animal/${id}`, [file], undefined);
    return results[0];
  }

  async uploadUserProfileImage(file: File): Promise<UploadResult<FileBaseDto>> {
    const results = await this.upload(`${this.apiUrl}/files/upload/user/avatar`, [file], undefined);
    return results[0];
  }

  async upload<TFileDto extends FileBaseDto>(
    endpoint: string,
    files: File[],
    tags: FileTagType[] | undefined,
    uploadEntity?: UploadEntity,
  ): Promise<UploadResult<TFileDto>[]> {
    const uploads = files.map(file => {
      const formData = new FormData();
      formData.append('file', file);

      if (tags !== undefined && tags.length > 0) {
        for (const tag of tags) {
          formData.append('tags', tag);
        }
      } else if (tags !== undefined) {
        formData.append('tags', '');
      }

      if (uploadEntity !== undefined) {
        formData.append('uploadEntity.id', uploadEntity.id);
        formData.append('uploadEntity.type', uploadEntity.type);
      }

      const request = this.http.post<TFileDto>(endpoint, formData);
      return firstValueFrom(request);
    });

    const results = await Promise.allSettled(uploads);

    const uploadResults: UploadResult<TFileDto>[] = [];
    results.forEach(result => {
      if (result.status === 'fulfilled') {
        uploadResults.push({
          success: true,
          file: result.value,
        });
      } else {
        uploadResults.push({ success: false });
      }
    });

    return uploadResults;
  }

  async deleteFile(file: FileBaseDto): Promise<boolean> {
    return firstValueFrom<boolean>(
      this.http.delete(`${this.apiUrl}/files/${file.id}`).pipe(
        catchError(() => of(false)),
        map(() => true),
      ),
    );
  }

  private requestParamName(fileType: UploadFileType) {
    switch (fileType) {
      case 'image':
        return 'image';
      case 'video':
        return 'video';
      default:
        return 'file';
    }
  }
}
