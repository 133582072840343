<div *ngIf="cases$ | async as cases">
  <table #table="matTable" [dataSource]="cases" mat-table>
    <tr *matHeaderRowDef="columns" mat-header-row></tr>
    <tr
      *matRowDef="let case; table: table; columns: columns"
      [routerLink]="appRoutes.CASE_DETAIL.url(case.id)"
      class="table-row-link"
      mat-row
    ></tr>

    <ng-container matColumnDef="caseNumber">
      <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.caseNumber' | translate }}</th>
      <td *matCellDef="let case; table: table" mat-cell>{{ case.number }}</td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.title' | translate }}</th>
      <td *matCellDef="let case; table: table" mat-cell>
        <div class="max-width-column">
          <tgn-single-line-text [text]="case.title ?? '-'" />
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="entryDate">
      <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.entryDate' | translate }}</th>
      <td *matCellDef="let case; table: table" mat-cell>{{ case.entryDate | date }}</td>
    </ng-container>

    <ng-container matColumnDef="exitDate">
      <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.completeDate' | translate }}</th>
      <td *matCellDef="let case; table: table" mat-cell>{{ case.exitDate ? (case.exitDate | date) : '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="entryType">
      <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.CaseListViewDto.entryType' | translate }}</th>
      <td *matCellDef="let case; table: table" mat-cell>
        {{ 'GENERAL.DOMAIN.CaseEntryType.' + case.entryType | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="entryVia">
      <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Case.entryViaType' | translate }}</th>
      <td *matCellDef="let case; table: table" mat-cell>
        {{ 'GENERAL.DOMAIN.EntryViaType.' + case.entryVia | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.Case.state' | translate }}</th>
      <td *matCellDef="let case; table: table" mat-cell>
        {{ 'GENERAL.DOMAIN.CaseState.' + case.state | translate }}
      </td>
    </ng-container>
  </table>
</div>
