import { Pipe, PipeTransform } from '@angular/core';
import { FileDto } from '@file/dto/file.dto';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'contentTypeDisplay',
  standalone: true,
})
export class ContentTypeDisplayPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(file: FileDto): string {
    if (file.contentType === null) {
      return this.translate.instant('GENERAL.DOMAIN.ContentType.unknown');
    }
    const key = `GENERAL.DOMAIN.ContentType.${file.contentType}`;
    const translation = this.translate.instant(key);
    if (translation != key) {
      return translation;
    }
    if (file.contentType!.startsWith('image')) {
      return this.translate.instant('GENERAL.DOMAIN.ContentType.image');
    }
    if (file.contentType!.startsWith('video')) {
      return this.translate.instant('GENERAL.DOMAIN.ContentType.video');
    }
    return this.translate.instant('GENERAL.DOMAIN.ContentType.other');
  }
}
