<div (click)="openNotification()" [ngClass]="{ read: notification.read }" class="notification">
  <div class="notification-title-container">
    <div class="icon">
      @switch (notification.entityType) {
        @case (NotificationEntityType.Task) {
          <tgn-icon [iconName]="'radixClipboard'" />
        }
        @case (NotificationEntityType.Animal) {
          <tgn-icon [iconName]="'animalPawIcon'" />
        }
        @case (NotificationEntityType.Case) {
          <tgn-icon [iconName]="'radixFile'" />
        }
      }
    </div>

    <h2 class="notification-title ml-large">{{ notification.title }}</h2>

    <div class="notification-read-chip ml-large">
      @if (notification.read) {
        <mat-chip class="custom-chip">Gelesen</mat-chip>
      }
    </div>
  </div>

  <div class="notification-message">
    <span class="">
      {{ notification.date | date: 'dd.MM.yyyy' }}
    </span>
    <span class="ml-large">
      {{ notification.message }}
    </span>
  </div>
</div>
