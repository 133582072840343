import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { displayAnimalAutocomplete } from '@animal/model/animal';
import { AnimalService } from '@animal/services/animal.service';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ViewStore, ViewStoreQuery, toLoadable } from '@core/components/autocomplete/model/loadable';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { AnimalType } from '@core/models/general';
import { ModalComponent } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';

interface DialogData {
  case: CaseDetailDto;
}

type DialogResult = CreateNewAnimal | AddExistingAnimal;

type AddCaseAnimalType = 'create' | 'useExisting';

const addCaseAnimalTypes: { create: 'create'; useExisting: 'useExisting' } = {
  create: 'create',
  useExisting: 'useExisting',
};

interface AddCaseAnimalResult {
  type: AddCaseAnimalType;
}

interface CreateNewAnimal extends AddCaseAnimalResult {
  type: 'create';
  name: string;
}

interface AddExistingAnimal extends AddCaseAnimalResult {
  type: 'useExisting';
  animal: AnimalDto;
}

interface AddCaseAnimalForm {
  type: FormControl<AddCaseAnimalType>;
  animal: FormControl<AnimalDto | null>;
  name: FormControl<string>;
}

@Component({
  selector: 'app-add-case-animal-dialog',
  standalone: true,
  imports: [
    AutocompleteComponent,
    FormElementComponent,
    FormElementDirective,
    MatButtonModule,
    MatDialogActions,
    MatDialogTitle,
    TranslateModule,
    MatDialogContent,
    RadioGroupComponent,
    ReactiveFormsModule,
    MatDialogClose,
    MatFormField,
    MatInput,
  ],
  templateUrl: './add-case-animal-dialog.component.html',
  styleUrl: './add-case-animal-dialog.component.scss',
})
export class AddCaseAnimalDialogComponent extends ModalComponent<DialogData, DialogResult> {
  form?: FormGroup<AddCaseAnimalForm>;

  addChoices: RadioChoice<AddCaseAnimalType>[] = [
    { label: 'PAGE.CASE.DETAIL.ADD_CASE_ANIMAL_FORM.CREATE_NEW', object: 'create' },
    { label: 'PAGE.CASE.DETAIL.ADD_CASE_ANIMAL_FORM.ADD_EXISTING', object: 'useExisting' },
  ];

  animalViewStore: ViewStore<AnimalDto[], ViewStoreQuery> = new ViewStore<AnimalDto[], ViewStoreQuery>(
    { query: '' },
    (params: ViewStoreQuery) => {
      return this.animalService.searchAnimal(params.query).pipe(toLoadable());
    },
  );

  data: DialogData;
  protected readonly addCaseAnimalTypes = addCaseAnimalTypes;
  protected readonly displayAnimalAutocomplete = displayAnimalAutocomplete;

  constructor(
    fb: FormBuilder,
    dialogRef: MatDialogRef<AddCaseAnimalDialogComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private animalService: AnimalService,
    private snackbar: SnackbarService,
  ) {
    super(dialogRef);

    this.data = data;

    this.form = fb.group({
      type: fb.nonNullable.control<AddCaseAnimalType>('create'),
      animal: fb.control<AnimalDto | null>(null),
      name: fb.nonNullable.control<string>('', [Validators.required, Validators.minLength(1)]),
    });
  }

  getAnimalValueFn(dto: AnimalDto) {
    return dto;
  }

  setExistingAnimal(contact: AnimalDto | null) {
    this.form!.controls.animal.setValue(contact);
  }

  removeSelectedAnimal() {
    this.form!.controls.animal.setValue(null);
  }

  close() {
    const value = this.form!.value;

    if (value.type === 'create') {
      if (!value.name) {
        console.error('Name not set');
        return;
      }
      this.closeWithResult({ type: 'create', name: value.name });
    } else {
      if (!value.animal) {
        console.error('Animal or kind not set', value);
        return;
      }

      if (this.data.case.animalType !== value.animal.type) {
        if (value.animal.type === AnimalType.DomesticAnimal) {
          this.snackbar.showErrorMessage('Heimtier kann nicht einem Wildtierfall hinzugefügt werden');
        } else {
          this.snackbar.showErrorMessage('Wildtier kann nicht einem Heimtierfall hinzugefügt werden');
        }
        return;
      }
      this.closeWithResult({ type: 'useExisting', animal: value.animal });
    }
  }
}
