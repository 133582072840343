import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notIn',
  standalone: true,
})
export class NotInPipe<T> implements PipeTransform {
  transform(value: T | null | undefined, list: T[]): boolean {
    return !list.includes(value as T);
  }
}
