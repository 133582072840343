<div *tgnContextActions class="tgn-context-actions">
  <button
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="menu"
    [tgnRoleRestriction]="EXCLUDE_VETERINARIAN"
    class="tgn-button"
    mat-flat-button
  >
    {{ 'PAGE.ANIMAL.DETAIL.ACTIONS.DOWNLOAD' | translate }}
  </button>

  @if (animal$ | async; as animal) {
    @if (animal.archived) {
      <button
        (agreeEvent)="unarchive(animal)"
        [dialogTitle]="'Archivierung aufheben'"
        [dialogDescription]="'Soll dieses Tier wieder aktiv geschalten werden?'"
        appConfirmationDialog
        class="tgn-button"
        mat-flat-button
      >
        <span>{{ 'GENERAL.BUTTON.UNARCHIVE' | translate }}</span>
      </button>
    }
  }

  <mat-menu #menu="matMenu">
    <ng-template matMenuContent>
      <button (click)="downloadBoxCard()" [tgnRoleRestriction]="EXCLUDE_VETERINARIAN" mat-menu-item>
        <span>{{ 'PAGE.ANIMALS.ACTIONS.DOWNLOAD_BOX_CARD' | translate }}</span>
      </button>
      <button (click)="downloadAdmissionForm()" [tgnRoleRestriction]="EXCLUDE_VETERINARIAN" mat-menu-item>
        <span>{{ 'PAGE.ANIMALS.ACTIONS.DOWNLOAD_ADMISSION_FORM' | translate }}</span>
      </button>
    </ng-template>
  </mat-menu>
</div>

<tgn-narrow-page-container>
  @if (baseData$ | async) {
    <ng-container>
      @if (form) {
        <div class="form-grid grid-cols-6">
          <div class="row row-span-sm-2">
            <div class="item col-span-3 col-span-sm-6">
              <tgn-form-element [control]="nonEditableForm.trdId" id="trdId" [label]="'ENTITY.Animal.trdId' | translate">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <input matInput [formControl]="control" [id]="id" type="text" />
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
            <div class="item col-span-3 col-span-sm-6">
              <tgn-form-element [control]="nonEditableForm.entryDate" id="entryDate" [label]="'ENTITY.Case.entryDate' | translate">
                <ng-template formElement let-control="control" let-id="id">
                  <tgn-date-input [control]="control" [id]="id" />
                </ng-template>
              </tgn-form-element>
            </div>
          </div>

          <div class="row row-span-sm-2">
            <div class="item col-span-3 col-span-sm-6">
              <tgn-form-element [control]="form.animal.controls.name" id="name" label="Name">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <input matInput [formControl]="control" [id]="id" type="text" />
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>

            @if (form.animal.controls.type.value === AnimalType.DomesticAnimal) {
              <div class="col-span-3 form-grid grid-cols-2 col-span-sm-6">
                <div class="row row-span-sm-2">
                  <div class="item col-span-1 col-span-sm-2">
                    <tgn-form-element [control]="form.animal.controls.chipId" id="chipId" [label]="'ENTITY.Animal.chipId' | translate">
                      <ng-template formElement let-control="control" let-id="id">
                        <mat-form-field appearance="outline">
                          <input matInput [formControl]="control" [id]="id" type="text" />
                        </mat-form-field>
                      </ng-template>
                    </tgn-form-element>
                  </div>
                  <div class="item col-span-1 col-span-sm-2">
                    <tgn-form-element [control]="form.animal.controls.ringId" id="ringId" [label]="'ENTITY.Animal.ringId' | translate">
                      <ng-template formElement let-control="control" let-id="id">
                        <mat-form-field appearance="outline">
                          <input matInput [formControl]="control" [id]="id" type="text" />
                        </mat-form-field>
                      </ng-template>
                    </tgn-form-element>
                  </div>
                </div>
              </div>
            }
          </div>

          <div class="row row-span-sm-3">
            <div class="item col-span-2 col-span-sm-6">
              <tgn-form-element [control]="mainGroupTypeControl" id="mainGroup" label="Hauptgruppe">
                <ng-template formElement let-control="control" let-id="id">
                  <tgn-select [choices]="mainGroupTypeChoices" [control]="control" [id]="id"></tgn-select>
                </ng-template>
              </tgn-form-element>
            </div>
            <div class="item col-span-2 col-span-sm-6">
              <tgn-form-element [control]="form.animal.controls.group" id="group" label="Gruppe">
                <ng-template formElement let-control="control" let-id="id">
                  <tgn-select
                    [choices]="taxonomyBaseData.groups | toRadioChoice: 'name'"
                    [compareIds]="true"
                    [control]="control"
                    [id]="id"
                    [includeEmptyChoice]="true"
                    [searchable]="true"
                  ></tgn-select>
                </ng-template>
              </tgn-form-element>
            </div>
            <div class="item col-span-2 col-span-sm-6">
              <tgn-form-element [control]="form.animal.controls.subGroup" id="subGroup" label="Untergruppe">
                <ng-template formElement let-control="control" let-id="id">
                  <tgn-select
                    [choices]="taxonomyBaseData.subGroups | subGroupChoices: form.animal.value.group | toRadioChoice: 'name'"
                    [compareIds]="true"
                    [control]="control"
                    [id]="id"
                    [includeEmptyChoice]="true"
                    [searchable]="true"
                  ></tgn-select>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>

          @if (form.animal.controls.type.value === AnimalType.DomesticAnimal) {
            <div class="row row-span-sm-3">
              <div class="item col-span-2 col-span-sm-6">
                <tgn-form-element [control]="form.animal.controls.species" id="species" label="Tierart">
                  <ng-template formElement let-control="control" let-id="id">
                    <tgn-select
                      [choices]="
                        taxonomyBaseData.species
                          | addIfMissing: form.animal.controls.species.value
                          | speciesChoices: form.animal.value.group : form.animal.value.subGroup : taxonomyBaseData.subGroups
                          | toRadioChoice: 'name'
                      "
                      [compareIds]="true"
                      [control]="control"
                      [id]="id"
                      [includeEmptyChoice]="true"
                      [searchable]="true"
                    />
                  </ng-template>
                </tgn-form-element>
              </div>

              <div class="item col-span-2 col-span-sm-6">
                <tgn-form-element [control]="form.animal.controls.race" id="race" label="Rasse">
                  <ng-template formElement let-control="control" let-id="id">
                    <tgn-animal-race-input [control]="form.animal.controls.race" [id]="id" [taxonomy]="form.animal.value | cast" />
                  </ng-template>
                </tgn-form-element>
              </div>
              <div class="item col-span-2 col-span-sm-6">
                <tgn-form-element
                  [control]="form.animal.controls.raceNote"
                  id="domesticRaceNote"
                  [label]="'ENTITY.Animal.raceNote' | translate"
                >
                  <ng-template formElement let-control="control" let-id="id">
                    <mat-form-field appearance="outline">
                      <input matInput [formControl]="control" [id]="id" type="text" />
                    </mat-form-field>
                  </ng-template>
                </tgn-form-element>
              </div>
            </div>
          } @else {
            <div class="row row-span-sm-2">
              <div class="item col-span-2 col-span-sm-6">
                <tgn-form-element [control]="form.animal.controls.species" id="speciesWildAnimal" label="Tierart">
                  <ng-template formElement let-control="control" let-id="id">
                    <tgn-select
                      [choices]="
                        taxonomyBaseData.species
                          | addIfMissing: form.animal.controls.species.value
                          | speciesChoices: form.animal.value.group : form.animal.value.subGroup : taxonomyBaseData.subGroups
                          | toRadioChoice: 'name'
                      "
                      [compareIds]="true"
                      [control]="control"
                      [id]="id"
                      [searchable]="true"
                      [includeEmptyChoice]="true"
                    />
                  </ng-template>
                </tgn-form-element>
              </div>
              <div class="item col-span-2 col-span-sm-6">
                <tgn-form-element
                  [control]="form.animal.controls.raceNote"
                  id="wildAnimalRaceNote"
                  [label]="'ENTITY.Animal.raceNote' | translate"
                >
                  <ng-template formElement let-control="control" let-id="id">
                    <mat-form-field appearance="outline">
                      <input matInput [formControl]="control" [id]="id" type="text" />
                    </mat-form-field>
                  </ng-template>
                </tgn-form-element>
              </div>
            </div>

            @if (caseDetail$ | async; as caseDetail) {
              <tgn-wild-animal-statistics [caseDto]="caseDetail" [id]="'statistics'" />
            }
          }

          @if (form.animal.controls.type.value === AnimalType.DomesticAnimal) {
            <div class="row row-span-sm-2">
              <div class="item col-span-3 col-span-sm-6">
                <tgn-form-element [control]="form.animal.controls.coloring" id="colouring" label="Färbung">
                  <ng-template formElement let-control="control" let-id="id">
                    <mat-form-field appearance="outline">
                      <input matInput [formControl]="control" [id]="id" type="text" />
                    </mat-form-field>
                  </ng-template>
                </tgn-form-element>
              </div>

              <div class="item col-span-3 col-span-sm-6">
                <tgn-form-element [control]="form.animal.controls.furLength" id="furLength" label="Fell">
                  <ng-template formElement let-control="control" let-id="id">
                    <tgn-select [choices]="furLengthChoices" [control]="control" [id]="id" [includeEmptyChoice]="true" />
                  </ng-template>
                </tgn-form-element>
              </div>
            </div>

            <div class="row row-span-sm-2">
              <div class="item col-span-3 col-span-sm-6">
                <tgn-form-element [control]="form.animal.controls.sex" id="sex" label="Geschlecht">
                  <ng-template formElement let-control="control" let-id="id">
                    <tgn-select [choices]="sexChoices" [control]="control" [id]="id" [searchable]="true" />
                  </ng-template>
                </tgn-form-element>
              </div>
              <div class="item col-span-3 col-span-sm-6">
                <tgn-form-element [control]="form.animal.controls.birthdate" id="birthdate" label="Geburtsdatum">
                  <ng-template formElement let-control="control" let-id="id">
                    <tgn-date-input [control]="control" [id]="id"></tgn-date-input>
                  </ng-template>
                </tgn-form-element>
              </div>
            </div>

            @if (form.animal.controls.type.value === AnimalType.DomesticAnimal) {
              <div class="row">
                <div class="item col-span-3 col-span-sm-6">
                  <tgn-form-element [control]="form.animal.controls.guessedAge" id="guessedAge" label="Schätzung Alter">
                    <ng-template formElement let-control="control" let-id="id">
                      <mat-form-field appearance="outline">
                        <input matInput [formControl]="control" [id]="id" type="text" />
                      </mat-form-field>
                    </ng-template>
                  </tgn-form-element>
                </div>
              </div>
            }

            @if (
              form.animal.controls.group.value?.tag === GroupTag.Turtle || form.animal.controls.subGroup.value?.tag === GroupTag.Turtle
            ) {
              <div class="row">
                <div class="item col-span-3 col-span-sm-6">
                  <tgn-form-element [control]="form.animal.controls.turtleShellLength" id="turtleShellLength" label="Panzerlänge">
                    <ng-template formElement let-control="control" let-id="id">
                      <div class="input-with-unit">
                        <mat-form-field appearance="outline" class="grow-input">
                          <input
                            matInput
                            [formControl]="control"
                            [id]="id"
                            [placeholder]="'PAGE.ENTRY_CHECK.FORM.PLACEHOLDER.TURTLE_SHELL_LENGTH' | translate"
                            type="text"
                          />
                        </mat-form-field>

                        <div class="unit-select">
                          <tgn-select [choices]="lengthUnitChoices" [control]="form.animal.controls.turtleShellLengthUnit" [id]="id" />
                        </div>
                      </div>
                    </ng-template>
                  </tgn-form-element>
                </div>
              </div>
            }
          }
        </div>

        <tgn-collapsible [expanded]="false" [title]="'Fallinformationen'" class="mb-medium mt-medium">
          @if (latestCaseData$ | async; as latestCaseData) {
            <tgn-case-read-only-info [caseAnimal]="latestCaseData.caseAnimal" [case]="latestCaseData.case" />
          }
        </tgn-collapsible>

        <div class="form-grid grid-cols-6">
          <div class="row">
            <div class="item">
              <tgn-form-element [control]="form.animal.controls.behaviour" id="behavior" label="Verhalten">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <textarea
                      matInput
                      [formControl]="control"
                      [id]="id"
                      cdkAutosizeMinRows="5"
                      cdkTextareaAutosize
                      cdkAutosizeMaxRows="10"
                    ></textarea>
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>

          <div class="row">
            <div class="item">
              <tgn-form-element [control]="form.animal.controls.specials" id="specials" label="Medizinische Infos">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <textarea
                      matInput
                      [formControl]="control"
                      [id]="id"
                      cdkAutosizeMinRows="5"
                      cdkTextareaAutosize
                      cdkAutosizeMaxRows="10"
                    ></textarea>
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>

          <div class="row">
            <div class="item">
              <tgn-form-element
                [control]="form.animal.controls.feedingHabits"
                id="feedingHabits"
                [label]="'ENTITY.Animal.feedingHabits' | translate"
              >
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline">
                    <textarea
                      matInput
                      [formControl]="control"
                      [id]="id"
                      cdkAutosizeMinRows="5"
                      cdkTextareaAutosize
                      cdkAutosizeMaxRows="10"
                    ></textarea>
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>

          <div class="row">
            <div class="item col-span-6">
              <tgn-form-element
                [control]="form.animal.controls.accessories"
                [id]="'accessories'"
                [label]="'ENTITY.EntryCheck.accessories'"
                [optional]="false"
              >
                <tgn-chip-input [class]="'item-1'" [formControl]="form.animal.controls.accessories" />
              </tgn-form-element>
            </div>
          </div>
        </div>
      }
    </ng-container>
  }
</tgn-narrow-page-container>
