<div class="header">
  <tgn-avatar-image [avatar]="animal" [onUpload]="uploadAnimalAvatar" size="size-3hg" />
  <div class="header-column">
    @if (animal.archived) {
      <div>
        <mat-chip class="custom-chip">Archiviert</mat-chip>
      </div>
    }
    <h1 class="top-row">
      <div class="header-title-first-part">
        <span class="header-part">{{ animal.name }}</span>
        @if (animal.name !== null && animal.name.length > 0) {
          <span class="separator-dash">-</span>
        }
        <span class="header-part">{{ animal.race ?? '' }}</span>
        @if (animal.race !== null && animal.race.length > 0) {
          <span class="separator-dash">-</span>
        }
        <span>{{ animal.species?.name ?? '' }}</span>
        @if (animal.species !== null) {
          <span class="separator-dash">-</span>
        }
        <span class="header-part">{{ animalHeaderState?.latestCaseEntryType | enumDisplay: 'GENERAL.DOMAIN.CaseEntryType' }}</span>
        <span class="separator-dash">-</span>
      </div>
      <div class="header-title-second-part">
        <span class="header-part">ID: {{ animal.trdId }}</span>

        @if (context.case) {
          <span class="separator-dash">-</span>
          <span>{{ 'ENTITY.Case.submitReason' | translate }}: {{ context.case.submitReason.name }}</span>
        }
      </div>
    </h1>

    <div class="bottom-row">
      @if (baseData && animalHeaderState) {
        <app-animal-state-switches
          [animal]="animal"
          [placementState]="animalHeaderState.placementState"
          [baseData]="baseData"
        ></app-animal-state-switches>
        <div class="animal-links-container">
          @if (context.type === AnimalViewContext.Care) {
            <a [routerLink]="appRoutes.ANIMAL_DETAIL.url(animal.id)" class="underline-link">
              {{ 'GENERAL.ACTION.TO_ANIMAL' | translate }}
            </a>
          } @else {
            <a [routerLink]="appRoutes.CARE_ANIMAL.url(animal.id)" class="underline-link">
              {{ 'GENERAL.ACTION.TO_CARE' | translate }}
            </a>
          }
          @if (context.case) {
            <a [tgnExcludeRole]="UserRole.Veterinarian" [routerLink]="appRoutes.CASE_DETAIL.url(context.case.id)" class="underline-link">
              {{ 'GENERAL.ACTION.TO_CASE' | translate }}
            </a>
          }

          @if (caseAnimalIndex !== null) {
            @if (animal.type === AnimalType.DomesticAnimal) {
              <a [routerLink]="appRoutes.ENTRYCHECK.url(animalHeaderState.latestCaseId, caseAnimalIndex)" class="underline-link">
                {{ 'ENTITY.CaseAnimal.entryCheck' | translate }}
              </a>
            } @else {
              <a [routerLink]="appRoutes.WILD_ENTRYCHECK_EDIT.url(animalHeaderState.latestCaseId, animal.id)" class="underline-link">
                {{ 'ENTITY.CaseAnimal.entryCheck' | translate }}
              </a>
            }
          }
          <a [routerLink]="appRoutes.ANIMAL_EXITCHECK.url(animalHeaderState.latestCaseId, animal.id)" class="underline-link">
            {{ 'ENTITY.CaseAnimal.exitCheck' | translate }}
          </a>
        </div>
      }
    </div>
  </div>
</div>
