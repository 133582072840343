@if (newSpeciesDtoDtoForm) {
  <div class="overflow-container">
    <div class="endless-list">
      <div class="endless-list-header">
        <div>
          {{ 'ENTITY.SpeciesDto.name' | translate }}
        </div>
        <div>
          {{ 'ENTITY.SpeciesDto.subGroup' | translate }}
        </div>
        <div>
          {{ 'ENTITY.SpeciesDto.active' | translate }}
        </div>
      </div>

      @if (dataSource.getData() | async; as data) {
        <cdk-virtual-scroll-viewport itemSize="50" class="endless-list-viewport">
          <div *cdkVirtualFor="let speciesDto of data" class="endless-list-item">
            <div>
              <div class="max-name-column">
                <tgn-single-line-text [text]="speciesDto.name" />
              </div>
            </div>
            <div>
              @if (editSpeciesDtoDtoFormGroups.get(speciesDto.id); as group) {
                <tgn-select
                  [searchable]="true"
                  [choices]="subGroupChoices$ | async | toRadioChoice: 'name'"
                  [control]="group.controls.subGroup"
                  matInput
                />
              }
            </div>
            <div>
              @if (editSpeciesDtoDtoFormGroups.get(speciesDto.id); as group) {
                <tgn-select [choices]="activeInactiveChoice" [control]="group.controls.active" matInput />
              } @else {
                <div class="input-padding">
                  {{ (speciesDto.active ? 'GENERAL.FORM.LABEL.Active' : 'GENERAL.FORM.LABEL.Inactive') | translate }}
                </div>
              }
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      }

      <div class="create-container mt-large">
        <div>
          <tgn-form-element
            [control]="newSpeciesDtoDtoForm.controls.name"
            [hideError]="true"
            [label]="'ENTITY.SpeciesDto.name' | translate"
            id="name"
          >
            <mat-form-field appearance="outline" class="full-width">
              <input [formControl]="newSpeciesDtoDtoForm.controls.name" matInput />
            </mat-form-field>
          </tgn-form-element>
        </div>

        <div>
          <tgn-form-element
            [control]="newSpeciesDtoDtoForm.controls.subGroup"
            [hideError]="true"
            [label]="'ENTITY.SpeciesDto.subGroup' | translate"
            id="subGroup"
          >
            <tgn-select
              [searchable]="true"
              [choices]="subGroupChoices$ | async | toRadioChoice: 'name'"
              [control]="newSpeciesDtoDtoForm.controls.subGroup"
              matInput
            />
          </tgn-form-element>
        </div>

        <div></div>
      </div>
    </div>

    <mat-error
      [ngClass]="{ invisible: (newSpeciesDtoDtoForm.controls.name.value?.length ?? 0) === 0 }"
      class="errors input-padding mt-small mb-small"
    >
      {{ newSpeciesDtoDtoForm.controls.name.errors | fieldErrorTranslation | async }}
    </mat-error>

    <button [disabled]="newSpeciesDtoDtoForm.invalid" mat-flat-button class="tgn-black-button add-entry-button" (click)="addEntry()">
      {{ 'GENERAL.BUTTON.ADD_ENTRY' | translate }}
    </button>
  </div>
}
