import { HttpParams } from '@angular/common/http';
import { addHttpParams, newFilter } from '@care/components/task-filter/model/task-filter';
import { UserRole } from '@core/models/general';

export interface Filter<T, Id extends string> {
  values: T[];
  id: Id;
}

export enum NotificationTimePeriod {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Week = 'Week',
  All = 'All',
}

export type UserRoleFilter = Filter<UserRole, 'userRoleFilter'>;
export type TimePeriodFilter = Filter<NotificationTimePeriod, 'timePeriodFilter'>;
export type ReadUnreadFilter = Filter<boolean, 'readUnreadFilter'>;

export class NotificationFilter {
  filters: {
    userRoleFilter: UserRoleFilter;
    timePeriodFilter: TimePeriodFilter;
    readUnreadFilter: ReadUnreadFilter;
  };

  constructor(filters: { userRoleFilter: UserRoleFilter; timePeriodFilter: TimePeriodFilter; readUnreadFilter: ReadUnreadFilter }) {
    this.filters = filters;
  }

  static empty(): NotificationFilter {
    return new NotificationFilter({
      userRoleFilter: newFilter('userRoleFilter'),
      timePeriodFilter: newFilter('timePeriodFilter'),
      readUnreadFilter: newFilter('readUnreadFilter'),
    });
  }

  setUserRoleFilter(filter: UserRoleFilter): NotificationFilter {
    return new NotificationFilter({ ...this.filters, userRoleFilter: filter });
  }

  setTimePeriodFilter(filter: TimePeriodFilter): NotificationFilter {
    return new NotificationFilter({ ...this.filters, timePeriodFilter: filter });
  }

  setReadUnreadFilter(filter: ReadUnreadFilter): NotificationFilter {
    return new NotificationFilter({ ...this.filters, readUnreadFilter: filter });
  }

  toHttpParams(): HttpParams {
    let params = new HttpParams();

    const filterParamName = 'filter';

    params = addHttpParams(this.filters.userRoleFilter.values, `${filterParamName}.userRoleFilter`, params);

    params = addHttpParams(
      this.filters.timePeriodFilter.values.map(it => it),
      `${filterParamName}.timePeriodFilter`,
      params,
    );

    if (this.filters.readUnreadFilter.values.length === 1 && this.filters.readUnreadFilter.values[0] !== null) {
      params = addHttpParams(
        this.filters.readUnreadFilter.values.map(it => it?.toString() ?? ''),
        `${filterParamName}.readUnreadFilter`,
        params,
      );
    }

    return params;
  }
}
