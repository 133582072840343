import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ImageLightboxModalComponent,
  ImageLightboxResult,
} from '@core/components/image-display/components/image-lightbox-modal/image-lightbox-modal.component';
import { StringToDatePipe } from '@core/pipes/string-to-date.pipe';
import { ModalService } from '@core/services/modal.service';
import { ImageDto } from '@file/dto/file.dto';
import { FilePipe } from '@file/pipes/file.pipe';

@Component({
  selector: 'tgn-single-image-display',
  standalone: true,
  imports: [AsyncPipe, FilePipe, StringToDatePipe, DatePipe],
  templateUrl: './single-image-display.component.html',
  styleUrl: './single-image-display.component.scss',
})
export class SingleImageDisplayComponent {
  @Input({ required: true }) image!: ImageDto;
  @Input() showDeleteButton = true;
  @Output() onImageDeleted = new EventEmitter<ImageDto>();

  constructor(private modalService: ModalService) {}

  openLightbox(imageDto: ImageDto) {
    this.modalService
      .open(
        ImageLightboxModalComponent,
        {
          image: imageDto,
          showDeleteButton: this.showDeleteButton,
        },
        {
          panelClass: 'confirmation-dialog',
        },
      )
      .afterClosed()
      .subscribe((result: ImageLightboxResult | undefined) => {
        if (result !== undefined && result?.deleted) {
          this.onImageDeleted.next(imageDto);
        }
      });
  }
}
