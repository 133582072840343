<div class="result">
  <div class="result-title-container">
    <div class="icon">
      <!--@TODO-->
      @switch (result.type) {
        @case (SearchResultType.Animal) {
          <tgn-icon [iconName]="'animalPawIcon'" iconSize="20" />
        }
        @case (SearchResultType.Case) {
          <tgn-icon [iconName]="'radixFile'" iconSize="20" />
        }
        @case (SearchResultType.Contact) {
          <tgn-icon [iconName]="'radixPerson'" iconSize="20" />
        }
        @case (SearchResultType.Bill) {
          <tgn-icon [iconName]="'radixEnvelopeClosed'" iconSize="20" />
        }
      }
    </div>

    <div [innerHTML]="getDisplayTitle(this.result)" class="result-title-text"></div>

    <div class="result-chip">
      <mat-chip class="custom-chip">
        @switch (result.type) {
          @case (SearchResultType.Animal) {
            <span>Tier</span>
          }
          @case (SearchResultType.Case) {
            <span>Fall</span>
          }
          @case (SearchResultType.Contact) {
            <span>Person</span>
          }
          @case (SearchResultType.Bill) {
            <span>Rechnung</span>
          }
        }
      </mat-chip>
    </div>
  </div>

  <div class="result-info">
    @for (infoRow of getDisplayableInfoRow(result); track infoRow) {
      <span [innerHTML]="infoRow"></span>
      <!--@IMPROVEMENT -> do not run this method twice, use pipes also for other method calls-->
      @if ($index < getDisplayableInfoRow(result).length - 1) {
        <span><tgn-icon [iconName]="'radixDotFilled'" /></span>
      }
    }

    @if (result.type === SearchResultType.Animal && result.animalType === AnimalType.DomesticAnimal) {
      <a
        (click)="$event.stopPropagation()"
        [routerLink]="appRoutes.ANIMAL_DETAIL_PLACEMENT.url(result.id)"
        mat-flat-button
        class="tgn-black-button ml-auto"
      >
        <span>{{ 'PAGE.ANIMALS.ACTIONS.OPEN_PLACEMENT' | translate }}</span>
      </a>
    }
  </div>
</div>
