import { Component, Input, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { IconComponent } from '@core/components/icon/icon.component';
import { AvailableIcons } from '@core/models/provided-icons';
import { Observable } from 'rxjs';

@Component({
  selector: 'tgn-task-setting',
  standalone: true,
  imports: [MatMenuModule, MatButtonModule, IconComponent],
  templateUrl: './task-setting.component.html',
  styleUrl: './task-setting.component.scss',
})
export class TaskSettingComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) value!: string | null;
  @Input({ required: true }) isValid!: boolean;
  @Input({}) icon?: AvailableIcons;
  @Input({ required: true }) onClose$!: Observable<unknown>;
  @ViewChild(MatMenuTrigger) menu!: MatMenuTrigger;
}
