import { BrandedId } from '@core/utils/brand';

export type RelationKindId = BrandedId<'RelationKindId'>;

export interface RelationKindDto {
  id: RelationKindId;
  name: string;
  tag: RelationKindTag | null;
  active: boolean;
}

export type RelationKindOwner = RelationKindDto & {
  tag: RelationKindTag.Owner;
};

export type RelationKindProspect = RelationKindDto & {
  tag: RelationKindTag.Prospect;
};

export enum RelationKindTag {
  Owner = 'Owner',
  Finder = 'Finder',
  Prospect = 'Prospect',
}

export function isOwner(kind: RelationKindDto): kind is RelationKindOwner {
  return kind.tag === RelationKindTag.Owner;
}

export function isProspect(kind: RelationKindDto): kind is RelationKindProspect {
  return kind.tag === RelationKindTag.Prospect;
}
