import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult } from '@core/data/tigon-datasource';
import { BaseService } from '@core/services/base-service';
import { PaginatedParams } from '@core/utils/pagination';
import { Observable, ReplaySubject, interval, take, tap } from 'rxjs';

import { NotificationDto, NotificationId } from '../dto/notification.dto';
import { NotificationFilter } from '../model/notification-filter';

export type NotificationPaginatedParams = Omit<PaginatedParams, 'sort' | 'query'> & {
  filter: NotificationFilter;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  onNumUnread$: ReplaySubject<number> = new ReplaySubject<number>(1);

  constructor(private http: HttpClient) {
    super();
    this.getNumberOfNotifications().subscribe((numUnread: number) => {
      this.onNumUnread$.next(numUnread);
    });
    this.startFetchingUnreadCount();
  }

  markAllAsRead(): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/notification/mark-all-as-read`, {}).pipe(
      tap(() => {
        this.onNumUnread$.next(0);
      }),
    );
  }

  markAsRead(id: NotificationId): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/notification/${id}/mark-as-read`, {}).pipe(
      tap(() => {
        this.updateNumUnread();
      }),
    );
  }

  getNotificationsPaginated(params: NotificationPaginatedParams): Observable<PageResult<NotificationDto>> {
    let httpParams: HttpParams = params.filter.toHttpParams();

    const flattenedParams = {
      pageIndex: params.pageIndex.toString(),
      pageSize: params.pageSize.toString(),
    };

    httpParams = httpParams.appendAll(flattenedParams);
    return this.http.get<PageResult<NotificationDto>>(`${this.apiUrl}/notification/paginated`, { params: httpParams });
  }

  private getNumberOfNotifications(): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/notification/num-notifications`);
  }

  private startFetchingUnreadCount(): void {
    interval(100000) // 5 minutes in milliseconds
      .subscribe(() => {
        this.updateNumUnread();
      });
  }

  private updateNumUnread(): void {
    this.getNumberOfNotifications()
      .pipe(take(1))
      .subscribe((numUnread: number) => {
        this.onNumUnread$.next(numUnread);
      });
  }
}
