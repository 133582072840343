<div class="filter">
  <div class="filter-display-row">
    <button [matMenuTriggerFor]="menu" class="side-menu-button filter-button" type="button">
      <div class="filter-button-inner">
        <span class="no-filter-active">
          {{ 'COMPONENT.FILTER.TITLE' | translate }}
        </span>
        <tgn-icon [iconName]="'radixChevronDown'" />
      </div>
    </button>

    <tgn-active-filter-group [label]="'Status' | translate" [show]="filter.filters.readUnreadFilter.values.length > 0">
      @for (item of filter.filters.readUnreadFilter.values; track $index) {
        <tgn-selected-filter-item
          [label]="item.toString() | enumDisplay: 'GENERAL.DOMAIN.ReadUnreadFilter'"
          (onRemove)="onRemoveSingleReadUnreadFilterItem(item)"
        />
      }
    </tgn-active-filter-group>

    @if (filter.filters.readUnreadFilter.values.length > 0) {
      <tgn-selected-filter-item
        class="clear-all-button"
        (click)="clearAllFilters()"
        [label]="'COMPONENT.FILTER.CLEAR_FILTER' | translate"
      />
    }
  </div>

  <mat-menu #menu class="filter-menu">
    <tgn-step-view (click)="$event.stopPropagation()" *matMenuContent>
      <ng-template [tgnStepInitial]="true" tgnStep>
        <div class="menu-item-list">
          <button [tgnNextStep]="readUnreadStep" class="step-button">
            {{ 'Status' }}
          </button>
        </div>
      </ng-template>
      <ng-template #readUnreadStep="tgnStep" tgnStep>
        <tgn-single-filter-list
          (onFilterChange)="onReadUnreadFilterChange($event)"
          [filter]="filter.filters.readUnreadFilter"
          [options]="readUnreadOptions"
          [searchable]="true"
        />
      </ng-template>
    </tgn-step-view>
  </mat-menu>
</div>
