<main class="main">
  <mat-sidenav-container [hasBackdrop]="false" autosize class="sidenav-container">
    <mat-sidenav
      #settingsNav
      [disableClose]="true"
      [opened]="isSettingsActive() && isSidebarOpen()"
      class="sidenav"
      mode="over"
      position="start"
    >
      <tgn-sidebar>
        <tgn-settings-nav (onSettingsClose)="closeSettingsNav()"></tgn-settings-nav>
      </tgn-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-sidenav-container [hasBackdrop]="isSmallViewportWidth()" autosize class="sidenav-container">
        <mat-sidenav #mainNav [disableClose]="false" [opened]="isSidebarOpen()" class="sidenav" mode="side" position="start">
          <tgn-sidebar>
            <tgn-main-nav (onOpenSettingsNav)="openSettingsNav()" />
          </tgn-sidebar>
        </mat-sidenav>
        <mat-sidenav-content class="main-content">
          <tgn-header (toggleMobileMenu)="toggleMobileMenu()" [isMobileViewPort]="isSmallViewportWidth()" />
          <div class="page-container">
            <div class="page-content">
              <router-outlet></router-outlet>
            </div>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
