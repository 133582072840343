import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { StationService } from '@baseData/services/station.service';
import { BaseDataDto, CreateStationDto, StationDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { ModalComponent } from '@core/services/modal.service';
import { uniqueNameValidator } from '@core/utils/helplers';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, map } from 'rxjs';

interface DialogData {}

interface DialogResult {
  name: string;
}

type StationForm = FormGroup<{
  name: FormControl<string | null>;
}>;

@Component({
  selector: 'app-add-station-dialog',
  standalone: true,
  imports: [
    AddIfMissingPipe,
    AsyncPipe,
    CdkTextareaAutosize,
    DateInputComponent,
    FormElementComponent,
    MatDialogModule,
    TranslateModule,
    FormElementDirective,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    MatButton,
    FieldErrorTranslationPipe,
    MatError,
    RoleRestrictionDirective,
  ],
  templateUrl: './add-station-dialog.component.html',
  styleUrl: './add-station-dialog.component.scss',
})
export class AddStationDialogComponent extends ModalComponent<DialogData, DialogResult> implements OnInit {
  stationForm!: StationForm;

  allStations$!: Observable<StationDto[]>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    dialogRef: MatDialogRef<AddStationDialogComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private stationService: StationService,
    private baseDataService: BaseDataService,
    private accessService: AccessService,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.allStations$ = this.baseDataService.getBaseData().pipe(map((data: BaseDataDto) => data.stations));
    this.createForm();
  }

  save() {
    const formValues = this.stationForm.getRawValue();

    if (!formValues.name) {
      return;
    }

    const dto: CreateStationDto = {
      name: formValues.name,
    };

    this.stationService.create(dto).subscribe(() => {
      this.closeWithResult({ name: formValues.name! });
    });
  }

  private createForm() {
    this.stationForm = this.fb.group({
      name: this.fb.control<string | null>(null, {
        validators: [Validators.required],
        asyncValidators: [uniqueNameValidator(this.allStations$)],
      }),
    });

    this.accessService.disableBasedOnRole(this.stationForm, RestrictedSection.Backoffice);
  }
}
