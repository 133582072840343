import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

interface CurrencyPipeOptions {
  digits: 'code' | 'symbol';
  digitsInfo: string;
}

@Pipe({
  name: 'tgnCurrency',
  standalone: true,
})
export class FvsCurrencyPipe implements PipeTransform {
  private pipe: CurrencyPipe;
  private number: DecimalPipe;

  constructor(@Inject(LOCALE_ID) localeId: string, @Inject(DEFAULT_CURRENCY_CODE) currencyCode: string) {
    this.pipe = new CurrencyPipe(localeId, currencyCode);
    this.number = new DecimalPipe(localeId);
  }

  /**
   * digits: 'code' -> CHF 0.00
   * digits: 'symbol' -> 0.00.-
   */
  transform(
    value: number | null | undefined,
    options?: {
      withCHF?: boolean;
      withoutFractionDigits?: boolean;
      nullAsEmptyString?: boolean;
    },
  ): string {
    if ((value === null || value === undefined) && options?.nullAsEmptyString) {
      return '';
    } else if (value === null || value === undefined) {
      value = 0;
    }
    const config: CurrencyPipeOptions = {
      digits: 'symbol',
      digitsInfo: '1.2-2',
    };
    if (options?.withoutFractionDigits) {
      config.digitsInfo = '1.0-0';
    }
    if (options?.withCHF) {
      config.digits = 'code';
    }
    if (config.digits == 'symbol') {
      return this.number.transform(value, config.digitsInfo) + '.\u2013'; // EN DASH
    }

    if (value < 0) {
      return `-${this.pipe.transform(-value, undefined, config.digits, config.digitsInfo)}` ?? '';
    } else {
      return this.pipe.transform(value, undefined, config.digits, config.digitsInfo) ?? '';
    }
  }
}
