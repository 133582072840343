import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnimalHeaderStateDto } from '@animal/dtos/animal-header-state.dto';
import { StationDto } from '@case/dtos/base-data.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { CaseDto } from '@case/dtos/case.dto';
import { SearchResultDto } from '@core/components/autocomplete/dto/search-result.dto';
import { PageResult } from '@core/data/tigon-datasource';
import { BaseService } from '@core/services/base-service';
import { PaginatedParams } from '@core/utils/pagination';
import { Observable, map, of } from 'rxjs';

import { AnimalFilter, GeneralAnimalFilterChoice } from '../components/animal-filter/model/animal-filter';
import { AnimalCaseIndexDto } from '../dtos/animal-case-index.dto';
import { AnimalListViewDto } from '../dtos/animal-list-view.dto';
import { AnimalDto, AnimalId } from '../dtos/animal.dto';
import { CreateAnimalDto } from '../dtos/create-animal.dto';
import { UpdateAnimalCareDto, UpdateAnimalDto, UpdateAnimalStateDto } from '../dtos/update-animal.dto';

type AnimalPaginatedParams = Omit<PaginatedParams, 'sort'> & {
  filter: AnimalFilter;
};

@Injectable({
  providedIn: 'root',
})
export class AnimalService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  create(dto: CreateAnimalDto): Observable<AnimalDto> {
    return this.http.post<AnimalDto>(`${this.apiUrl}/animals`, dto);
  }

  searchAnimal(query: string): Observable<AnimalDto[]> {
    if (query === '') {
      return of([]);
    }

    let params: HttpParams = new HttpParams();
    params = params.set('query', query);

    return this.http.get<SearchResultDto<AnimalDto>>(`${this.apiUrl}/animals/search`, { params: params }).pipe(
      map((searchResult: SearchResultDto<AnimalDto>) => {
        return searchResult.data;
      }),
    );
  }

  getAnimalsPaginated(
    params: AnimalPaginatedParams,
    generalAnimalFilter: GeneralAnimalFilterChoice,
    stationFilter: StationDto | null,
  ): Observable<PageResult<AnimalListViewDto>> {
    let httpParams: HttpParams = params.filter.toAnimalFilterDto();

    const flattenedParams = {
      query: params.query,
      pageIndex: params.pageIndex.toString(),
      pageSize: params.pageSize.toString(),
    };

    httpParams = httpParams.appendAll(flattenedParams);
    httpParams = httpParams.append('filter.generalAnimalFilter', generalAnimalFilter.toString());
    if (stationFilter !== null) {
      httpParams = httpParams.append('filter.stationFilter', stationFilter?.id ?? '');
    }

    return this.http.get<PageResult<AnimalListViewDto>>(`${this.apiUrl}/animals/paginated`, { params: httpParams });
  }

  getArchivedAnimalsPaginated(
    params: AnimalPaginatedParams,
    generalAnimalFilter: GeneralAnimalFilterChoice,
    stationFilter: StationDto | null,
  ): Observable<PageResult<AnimalListViewDto>> {
    let httpParams: HttpParams = params.filter.toAnimalFilterDto();

    const flattenedParams = {
      query: params.query,
      pageIndex: params.pageIndex.toString(),
      pageSize: params.pageSize.toString(),
    };

    httpParams = httpParams.appendAll(flattenedParams);
    httpParams = httpParams.append('filter.generalAnimalFilter', generalAnimalFilter.toString());
    if (stationFilter !== null) {
      httpParams = httpParams.append('filter.stationFilter', stationFilter?.id ?? '');
    }

    return this.http.get<PageResult<AnimalListViewDto>>(`${this.apiUrl}/animals/archived/paginated`, { params: httpParams });
  }

  getAnimal(id: string): Observable<AnimalDto> {
    return this.http.get<AnimalDto>(`${this.apiUrl}/animals/${id}`);
  }

  update(id: AnimalId, dto: UpdateAnimalDto): Observable<AnimalDto> {
    return this.http.put<AnimalDto>(`${this.apiUrl}/animals/${id}`, dto);
  }

  updateState(id: AnimalId, dto: UpdateAnimalStateDto): Observable<AnimalDto> {
    return this.http.put<AnimalDto>(`${this.apiUrl}/animals/${id}/state`, dto);
  }

  updateAnimalCare(id: AnimalId, dto: UpdateAnimalCareDto): Observable<AnimalDto> {
    return this.http.put<AnimalDto>(`${this.apiUrl}/animals/${id}/care`, dto);
  }

  delete(id: AnimalId): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/animals/${id}`);
  }

  getLatestCase(id: AnimalId): Observable<CaseDto> {
    return this.http.get<CaseDto>(`${this.apiUrl}/animals/${id}/cases/latest`);
  }

  getLatestCaseDetail(id: AnimalId): Observable<CaseDetailDto> {
    return this.http.get<CaseDetailDto>(`${this.apiUrl}/animals/${id}/cases/latest-detail`);
  }

  getCases(id: AnimalId): Observable<AnimalCaseIndexDto[]> {
    return this.http.get<AnimalCaseIndexDto[]>(`${this.apiUrl}/animals/${id}/cases`);
  }

  archiveAnimal(id: AnimalId): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/animals/${id}/archive`, {});
  }

  unarchiveAnimal(id: AnimalId): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/animals/${id}/unarchive`, {});
  }

  getAnimalHeaderState(id: AnimalId): Observable<AnimalHeaderStateDto> {
    return this.http.get<AnimalHeaderStateDto>(`${this.apiUrl}/animals/${id}/header-state`);
  }
}
