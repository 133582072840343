@if (changes$ | async; as changes) {
  @if (changes.length === 0) {
    <div class="mt-large">
      <p>Keine Änderungen gefunden</p>
    </div>
  }

  <div class="audit-changes-container">
    @for (change of changes; track change) {
      <tgn-audit-change [change]="change" />
    }
  </div>
}

@if (hasMore) {
  <div class="mt-large">
    <button (click)="loadMore()" class="tgn-black-button" mat-flat-button>Mehr laden</button>
  </div>
}
