import { NgForOf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavItemComponent } from '@core/components/nav-item/nav-item.component';
import { routes_config } from '@core/constants';
import { RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { UserRole } from '@core/models/general';
import { NavigationItem } from '@core/models/navigation-item';
import { RoleCombination } from '@core/models/role';
import { TranslateModule } from '@ngx-translate/core';
import { CurrentUserService } from '@user/service/current-user.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'tgn-settings-nav',
  standalone: true,
  imports: [NavItemComponent, NgForOf, TranslateModule, RoleRestrictionDirective, RoleRestrictionDirective],
  templateUrl: './settings-nav.component.html',
  styleUrl: './settings-nav.component.scss',
})
export class SettingsNavComponent implements OnInit {
  @Output() onSettingsClose = new EventEmitter<void>();

  showGeneralTitle = true;
  navItemsGeneral: NavigationItem[] = [
    {
      icon: 'radixHome',
      text: 'NAVIGATION.SETTINGS_ANIMAL_SHELTER',
      path: routes_config.SETTINGS_ANIMAL_SHELTER.url(),
      roleRestriction: {
        restrictTo: [UserRole.Admin, UserRole.Office],
      },
    },
    {
      icon: 'radixPerson',
      text: 'NAVIGATION.SETTINGS_USERS',
      path: routes_config.SETTINGS_USERS.url(),
      roleRestriction: {
        restrictTo: [UserRole.Admin],
      },
    },
    {
      icon: 'radixGear',
      text: 'NAVIGATION.SETTINGS_BASE_DATA',
      path: routes_config.SETTINGS_BASE_DATA.url(),
      roleRestriction: {
        restrictTo: [UserRole.Admin, RoleCombination(UserRole.Office, UserRole.Superuser)],
      },
    },
    {
      icon: 'radixEyeOpen',
      text: 'NAVIGATION.SETTINGS_AUDIT_LOG',
      path: routes_config.SETTINGS_AUDIT_LOG.url(),
      roleRestriction: {
        restrictTo: [UserRole.Admin, UserRole.Superuser],
      },
    },
    {
      icon: 'radixArchive',
      text: 'NAVIGATION.SETTINGS_ARCHIVE',
      path: routes_config.SETTINGS_ARCHIVE.url(),
      roleRestriction: {
        exclude: [UserRole.Veterinarian],
      },
    },
  ];

  navItemsPersonal: NavigationItem[] = [
    {
      icon: 'radixPerson',
      text: 'NAVIGATION.SETTINGS_PROFILE',
      path: routes_config.SETTINGS_PROFILE.url(),
    },
  ];

  backNavItem: NavigationItem = {
    icon: 'radixChevronLeft',
    text: 'NAVIGATION.BACK',
    path: null,
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;
  protected readonly environment = environment;

  constructor(private currentUserService: CurrentUserService) {}

  ngOnInit() {
    this.currentUserService.currentUser$.subscribe(currentUser => {
      this.showGeneralTitle = this.navItemsGeneral.some(item => {
        return (
          currentUser.roles.some(role => item.roleRestriction?.restrictTo?.includes(role)) &&
          currentUser.roles.every(role => !item.roleRestriction?.exclude?.includes(role))
        );
      });
    });
  }
}
