import { AsyncPipe, NgClass } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenu } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { WildAnimalStatisticsComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics.component';
import { AnimalTitlePipe } from '@animal/pipes/animal-title.pipe';
import { CaseCloseDialogComponent } from '@case/components/case-close-dialog/case-close-dialog.component';
import { CaseGeneralAnimalsComponent } from '@case/components/case-general-animals/case-general-animals.component';
import { SubmitReasonDto } from '@case/dtos/base-data.dto';
import { CaseDetailDto, UpdateCaseDetailDto } from '@case/dtos/case-detail.dto';
import { WildAnimalStatisticsControls } from '@case/models/new-case-form';
import { CreateStmzLinkPipe } from '@case/pipes/create-stmz-link.pipe';
import { BaseDataService } from '@case/services/base-data-service';
import { CaseService } from '@case/services/case.service';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { IconComponent } from '@core/components/icon/icon.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { NumberInputComponent } from '@core/components/number-input/number-input.component';
import { RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { ContextItemDirective } from '@core/directives/context-item.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { enumValidator, nullableEnumValidator } from '@core/models/form/enum.validator';
import { animalTypeChoices, caseEntryTypeChoicesDomestic, entryViaTypeChoices } from '@core/models/form/form-helper';
import { AnimalType, CaseEntryType, CaseState, EntryViaType, PlacementState } from '@core/models/general';
import { AndRoleRestrictionPipe, RESTRICT_CASE_WRITE } from '@core/models/role';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { defaultDebounce } from '@core/services/base-service';
import { ModalService } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { IsoLocalDateString } from '@core/utils/date';
import { overwriteObjectValues } from '@core/utils/helplers';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, filter, map, startWith } from 'rxjs';

import { CaseDetailStore } from '../case-layout/case-layout.component';

interface CaseDetailForm {
  title: FormControl<string | null>;
  animalType: FormControl<AnimalType>;
  entryType: FormControl<CaseEntryType>;
  submitReason: FormControl<SubmitReasonDto>;
  noteSubmitReason: FormControl<string | null>;
  entryViaType: FormControl<EntryViaType>;
  entryDate: FormControl<IsoLocalDateString | null>;
  rescueNumber: FormControl<string | null>;
  caseCreationDate: FormControl<IsoLocalDateString>;
  completeDate: FormControl<IsoLocalDateString | null>;
  foundLocation: FormControl<string | null>;
  wildAnimalStatistics: FormGroup<WildAnimalStatisticsControls>;
  placementState: FormControl<PlacementState | null>;
}

@Component({
  selector: 'app-case-general',
  standalone: true,
  imports: [
    FormElementComponent,
    TranslateModule,
    FormElementDirective,
    ReactiveFormsModule,
    RadioGroupComponent,
    AsyncPipe,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    NumberInputComponent,
    SelectComponent,
    MatInputModule,
    MatDatepickerModule,
    DateInputComponent,
    NgClass,
    IconComponent,
    CreateStmzLinkPipe,
    NarrowPageContainerComponent,
    CaseGeneralAnimalsComponent,
    MatButton,
    MatMenu,
    ContextActionsDirective,
    WildAnimalStatisticsComponent,
    ToRadioChoicePipe,
    AddIfMissingPipe,
    RoleRestrictionDirective,
    AndRoleRestrictionPipe,
    AnimalTitlePipe,
    CollapsibleComponent,
    ContextItemDirective,
    ConfirmationDialogDirective,
  ],
  templateUrl: './case-general.component.html',
  styleUrl: './case-general.component.scss',
})
export class CaseGeneralComponent implements OnInit {
  case$: Observable<CaseDetailDto>;

  form?: FormGroup<CaseDetailForm>;

  submitReasons$!: Observable<SubmitReasonDto[]>;
  isRescue$!: Observable<boolean>;
  protected readonly caseEntryTypeChoices = caseEntryTypeChoicesDomestic;
  protected readonly entryViaTypeChoices = entryViaTypeChoices;
  protected readonly animalTypeChoices = animalTypeChoices;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly EntryViaType = EntryViaType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly CaseEntryType = CaseEntryType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly CaseState = CaseState;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RESTRICT_CASE_WRITE = RESTRICT_CASE_WRITE;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    protected caseDetailStore: CaseDetailStore,
    private baseDateService: BaseDataService,
    private caseService: CaseService,
    private snackbar: SnackbarService,
    private modalService: ModalService,
    private accessService: AccessService,
  ) {
    this.case$ = this.caseDetailStore.case$;
  }

  ngOnInit() {
    this.submitReasons$ = this.baseDateService.getBaseData().pipe(
      map(it => {
        return it.submitReasons;
      }),
    );

    this.case$.subscribe((caseDetail: CaseDetailDto) => {
      this.buildForm(caseDetail);

      this.isRescue$ = this.form!.controls.entryViaType.valueChanges.pipe(
        startWith(this.form!.controls.entryViaType.value),
        map((entryViaType: EntryViaType) => entryViaType === EntryViaType.Rescue),
      );

      this.form!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
        if (this.form!.invalid) {
          console.info('form is valid, not updating case detail');
          return;
        }
        const updateDto: UpdateCaseDetailDto = { ...this.form!.getRawValue(), state: caseDetail.state };
        this.caseService.update(caseDetail.id, updateDto).subscribe({
          next: (newCaseDto: CaseDetailDto) => {
            overwriteObjectValues(caseDetail, newCaseDto);
          },
          error: (err: unknown) => {
            console.error('could not update case detail: ', err);
            this.snackbar.showErrorMessage('PAGE.CASE.DETAIL.ERROR.COULD_NOT_UPDATE');
          },
        });
      });
    });
  }

  buildForm(caseDetailDto: CaseDetailDto) {
    const fb = this.fb;
    this.form = fb.group({
      title: this.fb.control<string | null>(caseDetailDto.title),
      animalType: fb.nonNullable.control({ value: caseDetailDto.animalType, disabled: true }),
      entryType: fb.nonNullable.control<CaseEntryType>(caseDetailDto.entryType, [nullableEnumValidator(CaseEntryType)]),
      submitReason: fb.nonNullable.control<SubmitReasonDto>(caseDetailDto.submitReason, []),
      noteSubmitReason: fb.control<string | null>(caseDetailDto.noteSubmitReason, []),
      entryViaType: fb.nonNullable.control<EntryViaType>(caseDetailDto.entryViaType, [enumValidator(EntryViaType)]),
      entryDate: fb.control<IsoLocalDateString | null>(caseDetailDto.entryDate),
      rescueNumber: fb.control<string | null>(caseDetailDto.rescueNumber, []),
      caseCreationDate: fb.nonNullable.control<IsoLocalDateString>(caseDetailDto.caseCreationDate),
      completeDate: fb.control<IsoLocalDateString | null>(caseDetailDto.completeDate),
      foundLocation: fb.control<string | null>(caseDetailDto.foundLocation, []),
      placementState: fb.control<PlacementState | null>(caseDetailDto.placementState, []),
      wildAnimalStatistics: fb.group({
        numAnimals: fb.nonNullable.control<number>(caseDetailDto.wildAnimalStatistics?.numAnimals ?? 0, [Validators.min(0)]),
        numCareStation: fb.nonNullable.control<number>(caseDetailDto.wildAnimalStatistics?.numCareStation ?? 0, [Validators.min(0)]),
        numVeterinarian: fb.nonNullable.control<number>(caseDetailDto.wildAnimalStatistics?.numVeterinarian ?? 0, [Validators.min(0)]),
        numReleased: fb.nonNullable.control<number>(caseDetailDto.wildAnimalStatistics?.numReleased ?? 0, [Validators.min(0)]),
        numDead: fb.nonNullable.control<number>(caseDetailDto.wildAnimalStatistics?.numDead ?? 0, [Validators.min(0)]),
      }),
    });

    if (caseDetailDto.archived) {
      this.form.disable();
    }

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Case);
  }

  openCloseCaseDialog(caseDto: CaseDetailDto) {
    this.modalService
      .open(
        CaseCloseDialogComponent,
        {
          case: caseDto,
        },
        {
          minWidth: '30rem',
        },
      )
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe(() => {
        caseDto.state = CaseState.Completed;
        this.caseDetailStore.loadCase(caseDto.id);
      });
  }

  unarchive(caseDto: CaseDetailDto) {
    this.caseService.unarchiveCase(caseDto.id).subscribe({
      next: () => {
        caseDto.archived = false;
        this.snackbar.showSuccessMessage('Archivierung wurde aufgehoben');
        this.caseDetailStore.loadCase(caseDto.id);
      },
      error: (err: unknown) => {
        console.error('could not unarchive case: ', err);
      },
    });
  }
}
