import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NoInfer<T> = [T][T extends any ? 0 : never];

type ModalConfig<D> = Omit<MatDialogConfig<D>, 'data'>;

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  open<D, R>(component: Type<ModalComponent<D, R>>, data?: NoInfer<D>, config?: ModalConfig<D>): MatDialogRef<ModalComponent<D, R>, R> {
    const mergedConfig = {
      data: data ?? {},
      ...config,
    };

    return this.dialog.open(component, mergedConfig);
  }
}

export abstract class ModalComponent<D, R> {
  protected constructor(private dialogRef: MatDialogRef<ModalComponent<D, R>, R>) {}

  closeWithResult(result?: R): void {
    this.dialogRef.close(result);
  }
}

export enum ModalWidth {
  Small = '400px',
  Medium = '600px',
  Large = '800px',
  ExtraLarge = '850px',
}
