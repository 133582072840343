import { DataSource } from '@angular/cdk/collections';
import { CdkTableModule } from '@angular/cdk/table';
import { AfterContentInit, Component, ContentChild, ElementRef, Input } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { WithDataAndParamsAccess } from '@core/data/tigon-datasource';

@Component({
  selector: 'tgn-scrollable-table',
  standalone: true,
  imports: [CdkTableModule],
  templateUrl: './scrollable-table.component.html',
  styleUrl: './scrollable-table.component.scss',
})
export class ScrollableTableComponent implements AfterContentInit {
  @Input({ required: false }) showTableIfEmpty = false;

  @ContentChild(MatTable) table!: MatTable<unknown>;
  @ContentChild('paginator') standardTemplate!: ElementRef;
  dataLoaded = false;
  hasItems = false;

  ngAfterContentInit() {
    if (Array.isArray(this.table.dataSource)) {
      this.hasItems = this.table.dataSource.length > 0;
      this.dataLoaded = true;
      return;
    }

    const datasource = this.table.dataSource as DataSource<unknown> & WithDataAndParamsAccess<unknown, unknown>;
    datasource.getData().subscribe((data: unknown[]) => {
      if (data.length > 0) {
        this.hasItems = true;
      } else {
        this.hasItems = false;
      }
      this.dataLoaded = true;
    });
  }
}
