import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'tgn-slide-toggle',
  standalone: true,
  imports: [NgClass, MatFormField],
  templateUrl: './slide-toggle.component.html',
  styleUrl: './slide-toggle.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SlideToggleComponent,
    },
  ],
})
export class SlideToggleComponent implements ControlValueAccessor {
  @Input() isOutlined = false;
  @Input() id: string = uuidv4();
  @Input() name: string = uuidv4();
  @Input() disabled = false;
  @Input() checked = false;

  @Input() tabIndex?: number;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  onChange = (_: boolean) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  registerOnChange(onChange: never): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: never): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: boolean): void {
    this.checked = value;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _handleClick() {
    if (this.disabled) {
      return;
    }
    this.checked = !this.checked;
    this.onChange(this.checked);
    this.onTouched();
  }
}
