import { DatePipe, UpperCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IconComponent } from '@core/components/icon/icon.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { CastPipe } from '@core/pipes/cast.pipe';
import { TranslateModule } from '@ngx-translate/core';

import { JsonString } from '../../dtos/auditChange';
import { StateValueDisplayPipe, isReferenceFieldArray } from '../../pipes/state-value-display.pipe';

export interface ReferenceField {
  id: string;
  name: string;
}

export type StateValue = string | ReferenceField | ReferenceField[] | string[] | null;

export type State = Record<string, StateValue>;

@Component({
  selector: 'tgn-audit-diff',
  standalone: true,
  imports: [IconComponent, SingleLineTextComponent, TranslateModule, UpperCasePipe, CastPipe, StateValueDisplayPipe],
  providers: [DatePipe],
  templateUrl: './audit-diff.component.html',
  styleUrl: './audit-diff.component.scss',
})
export class AuditDiffComponent implements OnInit {
  @Input({ required: true }) oldState!: JsonString | null;
  @Input({ required: true }) newState!: JsonString;
  @Input({ required: true }) translationDomainName!: string;

  oldStateObj!: State;
  newStateObj!: State;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly Object = Object;

  ngOnInit() {
    const tmpOld = this.oldState ? stateStringToObj(this.oldState) : {};
    const tmpNew = stateStringToObj(this.newState);
    const differingKeys = getDifferingKeys(tmpOld, tmpNew);
    // get old and new state but only keep the differing keys for each
    this.oldStateObj = differingKeys.reduce((acc, key) => ({ ...acc, [key]: tmpOld[key] }), {});
    this.newStateObj = differingKeys.reduce((acc, key) => ({ ...acc, [key]: tmpNew[key] }), {});

    this.transformTranslationDomainName();
  }

  // quick and dirty hack as the translation keys are not totally consistent change after refactoring @REFACTOR
  transformTranslationDomainName() {
    if (this.translationDomainName === 'DomesticAnimalEntryCheck' || this.translationDomainName === 'WildAnimalEntryCheck') {
      this.translationDomainName = 'EntryCheck';
    }
  }
}

export interface Diff {
  old: Record<string, string>;
  new: Record<string, string>;
}

function getDifferingKeys(oldState: State | null, newState: State): string[] {
  return Object.keys(newState).filter(key => {
    let oldValue: StateValue = oldState ? oldState[key] ?? null : null;
    let newStateValue: StateValue = newState[key] === undefined ? null : newState[key];

    // If the value is an empty array, we treat it as null
    if (Array.isArray(newStateValue) && newStateValue.length === 0) {
      newStateValue = null;
    }
    if (Array.isArray(oldValue) && oldValue.length === 0) {
      oldValue = null;
    }

    // If the value is a reference field, we only compare the id
    if (oldValue && typeof oldValue === 'object' && 'id' in oldValue) {
      oldValue = oldValue.id;
    }
    if (newStateValue && typeof newStateValue === 'object' && 'id' in newStateValue) {
      newStateValue = newStateValue.id;
    }

    // if the value is a reference field array, we only compare the ids
    if (isReferenceFieldArray(oldValue) && isReferenceFieldArray(newStateValue)) {
      const tmpOld = oldValue!.map(value => value.id);
      const tmpNew = newStateValue!.map(value => value.id);
      return JSON.stringify(tmpOld) !== JSON.stringify(tmpNew);
    }

    if (isReferenceFieldArray(oldValue) || isReferenceFieldArray(newStateValue)) {
      return true;
    }

    if (Array.isArray(oldValue) || Array.isArray(newStateValue)) {
      return JSON.stringify(oldValue) !== JSON.stringify(newStateValue);
    }

    return oldValue !== newStateValue;
  });
}

function stateStringToObj(state: JsonString): Record<string, string> {
  try {
    return JSON.parse(state);
  } catch (e) {
    console.error('Could not parse state', state);
  }
  return {};
}
