import { AsyncPipe, NgForOf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { WildAnimalStatisticsFormComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics-form/wild-animal-statistics-form.component';
import { WildAnimalStatisticsComponent } from '@animal/components/wild-animal-statistics/wild-animal-statistics.component';
import { RelationKindDto, RelationKindOwner, isOwner } from '@baseData/dtos/relation-kind.dto';
import { CaseDetailDto } from '@case/dtos/case-detail.dto';
import { ChooseContactComponent } from '@contact/components/choose-contact/choose-contact.component';
import { ContactDto } from '@contact/dto/contact.dto';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { InputUploadComponent } from '@core/components/input-upload/input-upload.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { NumberInputComponent } from '@core/components/number-input/number-input.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { TextareaComponent } from '@core/components/textarea/textarea.component';
import { routes_config } from '@core/constants';
import { enumValidator, nullableEnumValidator } from '@core/models/form/enum.validator';
import { animalTypeChoices, caseEntryTypeChoicesDomestic, entryViaTypeChoices } from '@core/models/form/form-helper';
import { AnimalType, CaseEntryType, EntryViaType } from '@core/models/general';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { defaultDebounce } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { isDefined } from '@core/utils/helplers';
import { FileCollectionDto } from '@file/dto/file-collection.dto';
import { FileService } from '@file/service/file.service';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, combineLatest, map, startWith, take } from 'rxjs';

import { BaseDataDto, SubmitReasonDto } from '../../dtos/base-data.dto';
import { CaseDto } from '../../dtos/case.dto';
import { CreateDomesticCaseDto, CreateWildCaseDto } from '../../dtos/create-case.dto';
import { NewCaseForm, NewCaseFormValues } from '../../models/new-case-form';
import { BaseDataService } from '../../services/base-data-service';
import { CaseService } from '../../services/case.service';

@Component({
  selector: 'app-new-case-page',
  standalone: true,
  imports: [
    TranslateModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    FormElementComponent,
    RadioGroupComponent,
    AsyncPipe,
    NumberInputComponent,
    AutocompleteComponent,
    FormElementDirective,
    InputUploadComponent,
    NarrowPageContainerComponent,
    SelectComponent,
    ToRadioChoicePipe,
    ChooseContactComponent,
    TextareaComponent,
    WildAnimalStatisticsComponent,
    WildAnimalStatisticsFormComponent,
    AddIfMissingPipe,
  ],
  templateUrl: './new-case-page.component.html',
  styleUrl: './new-case-page.component.scss',
})
export class NewCasePageComponent implements OnInit {
  form?: FormGroup<NewCaseForm>;

  submitReasons$!: Observable<SubmitReasonDto[]>;
  relationKinds$!: Observable<RelationKindDto[]>;

  destroyRef: DestroyRef = inject(DestroyRef);

  fileCollection?: FileCollectionDto;
  case?: CaseDetailDto;
  protected readonly animalTypeChoices: RadioChoice<AnimalType>[] = animalTypeChoices;
  protected readonly caseEntryTypeChoicesDomestic: RadioChoice<CaseEntryType>[] = caseEntryTypeChoicesDomestic;
  protected readonly entryViaTypeChoices: RadioChoice<EntryViaType>[] = entryViaTypeChoices;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly EntryViaType = EntryViaType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly CaseEntryType = CaseEntryType;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private caseService: CaseService,
    private baseDataService: BaseDataService,
    private snackbar: SnackbarService,
    private fileService: FileService,
    private route: ActivatedRoute,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.loadBaseData();

    this.relationKinds$.pipe(take(1)).subscribe(relationKinds => {
      const ownerKind = relationKinds.find(isOwner)!;
      this.createForm(ownerKind);
      this.setWildAnimalDefaultSubmitReason();

      // either we navigate on this site with no previous case or we navigated forward already and then back again
      this.route.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
        const id = params['id'];
        if (id !== null && id !== undefined) {
          this.caseService.getDetail(id).subscribe((caseDto: CaseDetailDto) => {
            this.case = caseDto;
            //numAnimals, contact, relationKind, wildAnimalStatistics, fileCollection
            const values = {
              animalType: caseDto.animalType,
              entryType: caseDto.entryType,
              foundLocation: caseDto.foundLocation,
              submitReason: caseDto.submitReason,
              numAnimals: caseDto.numAnimals,
              contact: caseDto.contact,
              noteSubmitReason: caseDto.noteSubmitReason,
              entryViaType: caseDto.entryViaType,
              rescueNumber: caseDto.rescueNumber,
              relationKind: caseDto.relationKind,
              fileCollection: caseDto.fileCollection,
              wildAnimalStatistics: caseDto.wildAnimalStatistics ?? this.form!.getRawValue().wildAnimalStatistics,
            };
            this.form!.setValue(values);

            if (caseDto.contact) {
              this.form!.controls.contact.disable({ emitEvent: false });
              this.form!.controls.relationKind.disable({ emitEvent: false });
            }
            if (caseDto) {
              this.form!.controls.numAnimals.disable({ emitEvent: false });
            }
            this.autoSave();
            this.autoUpdateValidators();
          });
        } else {
          this.createFileCollection();
          this.loadFormValuesFromLocalStore();
          this.autoSave();
          this.autoUpdateValidators();
        }
      });
    });
  }

  continue() {
    if (this.case) {
      this.router.navigate(routes_config.ENTRYCHECK.url(this.case.id, 0));
    } else {
      this.createCase().subscribe({
        next: (caseDto: CaseDto) => {
          this.caseService.removeLocalStorageCase();

          if (this.form!.controls.animalType.value === AnimalType.DomesticAnimal) {
            this.router.navigate(routes_config.ENTRYCHECK.url(caseDto.id, 0));
          } else {
            this.router.navigate(routes_config.WILD_ANIMAL_ENTRYCHECK.url(caseDto.id, caseDto.caseAnimals[0].animal.id));
          }
        },
        error: (err: unknown) => {
          console.error(err);
          this.snackbar.showErrorMessage('PAGE.NEW_CASE.ERROR.COULD_NOT_CREATE_CASE');
        },
      });
    }
  }

  createCase(): Observable<CaseDto> {
    const formValues: NewCaseFormValues = this.form!.getRawValue();

    const caseEntryType: CaseEntryType | null = formValues.entryType;
    const entryViaType: EntryViaType | null = formValues.entryViaType;

    let dto: CreateDomesticCaseDto | CreateWildCaseDto;

    if (formValues.animalType === AnimalType.DomesticAnimal) {
      dto = {
        type: 'DomesticAnimal',
        ...formValues,
        entryViaType: entryViaType as EntryViaType,
        entryType: formValues.animalType === AnimalType.DomesticAnimal ? caseEntryType : null,
        contactId: formValues.contact?.id ?? null,
        relationKind: formValues.relationKind,
        fileCollection: formValues.fileCollection!,
      };
    } else {
      dto = {
        type: 'WildAnimal',
        ...formValues,
        entryViaType: entryViaType as EntryViaType,
        contactId: formValues.contact?.id ?? null,
        statistics: formValues.wildAnimalStatistics,
        fileCollection: formValues.fileCollection!,
      };
    }

    return this.caseService.create(dto);
  }

  cancel() {
    this.router.navigate(routes_config.DASHBOARD.url());
  }

  private createForm(ownerKind: RelationKindOwner) {
    this.form = this.fb.group({
      animalType: this.fb.nonNullable.control(AnimalType.DomesticAnimal, [Validators.required, enumValidator(AnimalType)]),
      entryType: this.fb.control<CaseEntryType | null>(CaseEntryType.Findeltier, [
        Validators.required,
        nullableEnumValidator(CaseEntryType),
      ]),
      foundLocation: this.fb.control<string | null>(null, []),
      submitReason: this.fb.control<SubmitReasonDto | null>(null, [Validators.required]),
      noteSubmitReason: this.fb.control<string | null>(null, []),
      entryViaType: this.fb.control<EntryViaType | null>(null, [Validators.required, enumValidator(EntryViaType)]),
      rescueNumber: this.fb.control<string | null>(null, []),
      numAnimals: this.fb.nonNullable.control<number>(1, [Validators.required, Validators.min(1)]),
      contact: this.fb.control<ContactDto | null>(null, [Validators.required]),
      relationKind: this.fb.nonNullable.control<RelationKindDto | null>(ownerKind, [Validators.required]),
      wildAnimalStatistics: this.fb.nonNullable.group({
        numAnimals: this.fb.nonNullable.control<number>(0, [Validators.min(0)]),
        numCareStation: this.fb.nonNullable.control<number>(0, [Validators.min(0)]),
        numVeterinarian: this.fb.nonNullable.control<number>(0, [Validators.min(0)]),
        numReleased: this.fb.nonNullable.control<number>(0, [Validators.min(0)]),
        numDead: this.fb.nonNullable.control<number>(0, [Validators.min(0)]),
      }),
      fileCollection: this.fb.control<FileCollectionDto | null>(null),
    });

    this.accessService.disableBasedOnRole(this.form, RestrictedSection.Animal);
  }

  private createFileCollection() {
    if (this.form!.controls.fileCollection.value == null) {
      this.fileService.createCollection().subscribe((fileCollectionDto: FileCollectionDto) => {
        this.form!.controls.fileCollection.setValue(fileCollectionDto, { onlySelf: true });
      });
    }
  }

  private loadFormValuesFromLocalStore() {
    const localStorageCase: NewCaseFormValues | null = this.caseService.getLocalStorageCase();

    if (isDefined(localStorageCase)) {
      this.form!.patchValue(localStorageCase);
    }
  }

  private autoSave() {
    this.form!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), defaultDebounce()).subscribe(() => {
      const formValues: NewCaseFormValues = this.form!.getRawValue();
      if (this.case) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.caseService.updateNewCase(this.case.id, formValues).subscribe(() => {});
      } else {
        this.caseService.saveCaseToLocalStorage(formValues);
      }
    });
  }

  private autoUpdateValidators() {
    this.form!.valueChanges.pipe(
      map(() => this.form!.getRawValue()),
      startWith(this.form!.getRawValue()),
      takeUntilDestroyed(this.destroyRef),
      defaultDebounce(),
    ).subscribe(value => {
      if (!this.form) {
        console.error('Form is not defined');
        return;
      }
      // entryType required for Domestic animals
      if (value.animalType === AnimalType.DomesticAnimal) {
        this.form.controls.entryType.setValidators([Validators.required]);
      } else {
        this.form.controls.entryType.setValidators([]);
      }
      this.form.controls.entryType.updateValueAndValidity({ onlySelf: true });

      // submitReason is required for Domestic Animals

      if (value.animalType === AnimalType.DomesticAnimal) {
        this.form.controls.submitReason.setValidators([Validators.required]);
      } else {
        this.form.controls.submitReason.setValidators([]);
      }
      this.form.controls.submitReason.updateValueAndValidity({ onlySelf: true });

      // contact is required for 'Direct' EntryViaType
      if (value.entryViaType === EntryViaType.Direct) {
        this.form.controls.contact.setValidators([Validators.required]);
      } else {
        this.form.controls.contact.setValidators([]);
      }
      this.form.controls.contact.updateValueAndValidity({ onlySelf: true });

      // foundLocation is required if CaseEntryType.Findeltier or WildAnimal
      if (value.entryType === CaseEntryType.Findeltier || value.animalType === AnimalType.WildAnimal) {
        this.form.controls.foundLocation.setValidators([Validators.required]);
      } else {
        this.form.controls.foundLocation.setValidators([]);
      }
      this.form.controls.foundLocation.updateValueAndValidity({ onlySelf: true });

      this.form.updateValueAndValidity({ emitEvent: false });
    });
  }

  private loadBaseData() {
    this.submitReasons$ = this.baseDataService.getBaseData().pipe(
      map((baseData: BaseDataDto) => {
        return baseData.submitReasons;
      }),
    );

    this.relationKinds$ = this.baseDataService.getBaseData().pipe(
      map((baseData: BaseDataDto) => {
        return baseData.relationKinds;
      }),
    );
  }

  private setWildAnimalDefaultSubmitReason() {
    combineLatest([this.submitReasons$, this.form!.valueChanges])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([submitReasons]) => {
        const isWildAnimal = this.form!.controls.animalType.value === AnimalType.WildAnimal;
        const currentSubmitReason = this.form!.controls.submitReason.value;
        if (isWildAnimal && currentSubmitReason === null) {
          const submitReason = submitReasons.find(it => it.name == 'Findeltier');
          if (!submitReason) {
            return;
          }
          this.form!.controls.submitReason.setValue(submitReason);
        }
      });
  }
}
