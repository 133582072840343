import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { DateInputComponent } from '@core/components/date-input/date-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { SelectComponent } from '@core/components/select/select.component';
import { routes_config } from '@core/constants';
import { animalTypeChoices, caseEntryTypeChoicesDomestic, entryViaTypeChoices } from '@core/models/form/form-helper';
import { AnimalType, EntryViaType } from '@core/models/general';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { Iso8601String, IsoLocalDateString } from '@core/utils/date';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';

import { SubmitReasonDto } from '../../dtos/base-data.dto';
import { CompactCaseAnimalDto } from '../../dtos/case-animal.dto';
import { CaseDetailDto } from '../../dtos/case-detail.dto';
import { CaseDto } from '../../dtos/case.dto';
import { BaseDataService } from '../../services/base-data-service';

interface ReadOnlyForm {
  caseNumber: FormControl<string | null>;
  entryType: FormControl<string | null>;
  submitReason: FormControl<SubmitReasonDto | null>;
  entryViaType: FormControl<EntryViaType | null>;
  entryDate: FormControl<IsoLocalDateString | null>;
  completeDate: FormControl<Iso8601String | null>;
  stmzDate: FormControl<IsoLocalDateString | null>;
  stmzDeadline1: FormControl<IsoLocalDateString | null>;
  stmzDeadline2: FormControl<IsoLocalDateString | null>;
}

@Component({
  selector: 'tgn-case-read-only-info',
  standalone: true,
  imports: [
    DateInputComponent,
    FormElementComponent,
    FormElementDirective,
    SelectComponent,
    TranslateModule,
    ReactiveFormsModule,
    AsyncPipe,
    MatButtonModule,
    RouterLink,
    MatFormField,
    MatInput,
    AddIfMissingPipe,
    ToRadioChoicePipe,
  ],
  templateUrl: './case-read-only-info.component.html',
  styleUrl: './case-read-only-info.component.scss',
})
export class CaseReadOnlyInfoComponent implements OnInit {
  @Input({ required: true }) case!: CaseDetailDto | CaseDto;
  @Input({ required: true }) caseAnimal!: CompactCaseAnimalDto;

  form!: FormGroup<ReadOnlyForm>;
  submitReasons$!: Observable<SubmitReasonDto[]>;
  protected readonly caseEntryTypeChoices = caseEntryTypeChoicesDomestic;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;
  protected readonly animalTypeChoices = animalTypeChoices;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly routes_config = routes_config;
  protected readonly entryViaTypeChoices = entryViaTypeChoices;

  constructor(
    private formBuilder: FormBuilder,
    private baseDataService: BaseDataService,
  ) {}

  ngOnInit() {
    const fb: FormBuilder = this.formBuilder;
    const readOnlyForm: ReadOnlyForm = {
      caseNumber: fb.control({ value: this.case.caseNumber, disabled: true }),
      entryType: fb.control({ value: this.case.entryType, disabled: true }),
      submitReason: fb.control({ value: this.case.submitReason, disabled: true }),
      entryViaType: fb.control({ value: this.case.entryViaType, disabled: true }),
      entryDate: fb.control({ value: this.case.entryDate, disabled: true }),
      completeDate: fb.control({ value: this.case.completeDate, disabled: true }),
      stmzDate: fb.control({ value: this.caseAnimal.stmzDate, disabled: true }),
      stmzDeadline1: fb.control({ value: this.caseAnimal.stmzDeadline1, disabled: true }),
      stmzDeadline2: fb.control({ value: this.caseAnimal.stmzDeadline2, disabled: true }),
    };
    this.form = fb.group(readOnlyForm);

    this.submitReasons$ = this.baseDataService.getBaseData().pipe(
      map(it => {
        return it.submitReasons;
      }),
    );
  }
}
