<div class="sidebar">
  <div class="sidebar-header">
    <img [alt]="'ALT_TEXT.LOGO' | translate" [routerLink]="appRoutes.ROOT.path" class="logo" src="assets/logo.svg" />
    <span [routerLink]="appRoutes.ROOT.path" class="sidebar-title">{{ appName }}</span>

    @if (currentUser | async; as user) {
      <tgn-user-badge [user]="user" [matMenuTriggerFor]="userMenu" class="user-badge"></tgn-user-badge>
    }
  </div>
  <nav>
    <ng-content />
  </nav>
</div>

<mat-menu #userMenu="matMenu">
  @if (currentUser | async; as user) {
    <div mat-menu-item>
      {{ user | fullName }}
    </div>
  }

  <button (click)="goToProfile()" mat-menu-item>Zum Profil</button>

  @if (currentSettings) {
    <div mat-menu-item>
      <tgn-select
        [compareIds]="true"
        [includeEmptyChoice]="true"
        [placeholder]="'Station'"
        [choices]="stations | async | toRadioChoice: 'name'"
        [control]="currentSettings.controls.usedStation"
        (click)="$event.stopPropagation()"
      />
    </div>

    <div mat-menu-item>
      <tgn-select
        [placeholder]="'Team'"
        [choices]="(roles | async) ?? []"
        [control]="currentSettings.controls.usedRole"
        (click)="$event.stopPropagation()"
      />
    </div>
  }

  <button
    (agreeEvent)="logout()"
    [dialogDescription]="'Möchten Sie sich wirklich abmelden?'"
    [dialogTitle]="'Abmelden'"
    appConfirmationDialog
    mat-menu-item
  >
    Abmelden
  </button>
</mat-menu>
