import { DatePipe, NgIf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { Router, RouterLink } from '@angular/router';
import { RapportExportDialogComponent } from '@animal/components/rapport-export-dialog/rapport-export-dialog.component';
import { BaseDataDto, StationDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { FilterOption, OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { UserRole } from '@core/models/general';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { ListDisplay } from '@core/pipes/list-display.pipe';
import { StripRichTextPipe } from '@core/pipes/strip-rich-text.pipe';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CurrentUserService } from '@user/service/current-user.service';
import moment from 'moment/moment';
import { debounceTime, filter, skip, take } from 'rxjs';

import { CareAnimalsListComponent } from '../../components/care-animals-list/care-animals-list.component';
import { CreateTaskDialogComponent } from '../../components/create-task-dialog/create-task-dialog.component';
import { MoveOrCopyTaskDialogComponent } from '../../components/move-or-copy-task-dialog/move-or-copy-task-dialog.component';
import { ShowTaskDialogComponentComponent } from '../../components/show-task-dialog-component/show-task-dialog-component.component';
import { DateFilter, TaskFilter } from '../../components/task-filter/model/task-filter';
import { TaskFilterComponent } from '../../components/task-filter/task-filter.component';
import {
  BaseTaskDto,
  TaskCategoryGroup,
  TaskDto,
  TaskEntityType,
  isAnimalTask,
  isBoxTask,
  isCaseTask,
  isStationTask,
} from '../../dtos/task.dto';
import { TaskService } from '../../services/task.service';

interface TableConfig {
  query: string;
  pageIndex: number;
  pageSize: number;
  filter: TaskFilter;
  stationFilter: StationDto | null;
  entityTypeFilter: TaskEntityType[];
}

@Component({
  selector: 'app-care-tasks-page',
  standalone: true,
  imports: [
    ConfirmationDialogDirective,
    DatePipe,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTableModule,
    PaginatorComponent,
    TranslateModule,
    TypesafeMatTableModule,
    RouterLink,
    ContextActionsDirective,
    TaskFilterComponent,
    OptionButtonComponent,
    EnumDisplayPipe,
    ScrollableTableComponent,
    StripRichTextPipe,
    SingleLineTextComponent,
    ListDisplay,
    NgIf,
    FullNamePipe,
    MatTabsModule,
    CareAnimalsListComponent,
    RoleRestrictionDirective,
  ],
  templateUrl: './care-tasks-page.component.html',
  styleUrl: './care-tasks-page.component.scss',
})
export class CareTasksPageComponent implements OnInit {
  datasource: PagedTigonDatasource<TaskDto, TableConfig>;
  query = signal('');
  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  totalPages = 1;
  totalItems = 0;
  filter = TaskFilter.empty().setDateFilter(this.getTodayDateFilter());

  activeTaskStationFilter = signal<StationDto | null>(null);

  taskStationFilterOptions: FilterOption<StationDto | null>[] = [];

  protected readonly appRoutes = routes_config;
  protected readonly isAnimalTask = isAnimalTask;
  protected readonly isBoxTask = isBoxTask;
  protected readonly isStationTask = isStationTask;
  protected readonly isCaseTask = isCaseTask;

  protected readonly destroyRef = inject(DestroyRef);

  protected readonly columns = [
    'box',
    'trdId',
    'entityType',
    'species',
    'behavior',
    'status',
    'terminationDate',
    'title',
    'description',
    'category',
    'completionUser',
    'actions',
  ];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly TaskCategoryGroup = TaskCategoryGroup;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly UserRole = UserRole;

  constructor(
    private taskService: TaskService,
    private snackbar: SnackbarService,
    private modalService: ModalService,
    private router: Router,
    private baseDataService: BaseDataService,
    private currentUserService: CurrentUserService,
  ) {
    this.datasource = new PagedTigonDatasource<TaskDto, TableConfig>(
      {
        query: '',
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        filter: this.filter,
        stationFilter: this.activeTaskStationFilter(),
        // by default do not show tasks related to cases within this view
        entityTypeFilter: [TaskEntityType.Animal, TaskEntityType.Station, TaskEntityType.Box],
      },
      params => {
        return this.taskService.getTasksPaginated(
          {
            query: params.query,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            stationFilter: this.activeTaskStationFilter(),
            filter: params.filter,
            sort: [],
          },
          params.entityTypeFilter,
        );
      },
    );

    this.currentUserService.usedStation$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(usedStation => {
      if (usedStation) {
        this.activeTaskStationFilter.set(usedStation);
        this.datasource.update({ stationFilter: usedStation, pageIndex: 0 });
      }
    });

    toObservable(this.query)
      .pipe(skip(1), debounceTime(300), takeUntilDestroyed())
      .subscribe(query => {
        this.datasource.update({ query });
      });

    this.datasource.page$.pipe(takeUntilDestroyed()).subscribe(page => {
      this.totalPages = page.totalPages;
      this.totalItems = page.totalItems;
      this.pageSize = page.pageSize;
      this.pageIndex = page.pageIndex;
    });
  }

  ngOnInit() {
    this.baseDataService
      .getBaseData()
      .pipe(take(1))
      .subscribe((baseData: BaseDataDto) => {
        const filters: FilterOption<StationDto | null>[] = baseData.stations.map(station => ({
          label: station.name,
          value: station,
        }));

        this.taskStationFilterOptions = [
          {
            label: 'GENERAL.DOMAIN.TaskStationFilter.All',
            value: null,
          },
          ...filters,
        ];
      });
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.datasource.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }

  deleteTask(task: BaseTaskDto) {
    this.taskService.delete(task.id).subscribe(() => {
      this.datasource.update({});
      this.snackbar.showSuccessMessage('GENERAL.INFO.TASK_DELETED');
    });
  }

  createNewTask() {
    this.modalService
      .open(
        CreateTaskDialogComponent,
        {
          entity: null,
          entityType: null,
          taskCategoryGroups: [TaskCategoryGroup.Care],
        },
        { width: ModalWidth.ExtraLarge },
      )
      .afterClosed()
      .pipe(filter(notNullish))
      .subscribe(() => {
        this.datasource.update({});
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openTask(task: TaskDto) {
    if (isAnimalTask(task)) {
      this.router.navigate(this.appRoutes.CARE_ANIMAL.url(task.animal.id));
      return;
    }
    this.modalService
      .open(
        ShowTaskDialogComponentComponent,
        {
          task: task,
          taskCategoryGroups: [TaskCategoryGroup.Care],
        },
        { width: ModalWidth.ExtraLarge },
      )
      .afterClosed()
      .subscribe(() => {
        this.datasource.update({});
      });
  }

  updateFilter(newFilter: TaskFilter) {
    this.filter = newFilter;
    this.datasource.update({ filter: newFilter, pageIndex: 0 });
  }

  changeFilter(newFilter: StationDto | null) {
    this.activeTaskStationFilter.set(newFilter);
    this.datasource.update({ stationFilter: newFilter, pageIndex: 0 });
  }

  moveTask(task: TaskDto) {
    this.modalService
      .open(MoveOrCopyTaskDialogComponent, { task: task, mode: 'move' }, { width: ModalWidth.Large })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.snackbar.showSuccessMessage('PAGE.CARE_TASK.FEEDBACK.TASK_MOVED');
        }
        this.datasource.update({});
      });
  }

  copyTask(task: TaskDto) {
    this.modalService
      .open(MoveOrCopyTaskDialogComponent, { task: task, mode: 'copy' }, { width: ModalWidth.Large })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.snackbar.showSuccessMessage('PAGE.CARE_TASK.FEEDBACK.TASK_COPIED');
        }
        this.datasource.update({});
      });
  }

  openDownloadRapports() {
    this.modalService
      .open(RapportExportDialogComponent, {}, { width: ModalWidth.Medium })
      .afterClosed()
      .pipe(filter(notNullish))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .subscribe(() => {});
  }

  private getTodayDateFilter(): DateFilter {
    const todayLocalDateString = moment().format('yyyy-MM-DD');
    return {
      id: 'dateFilter',
      from: todayLocalDateString,
      to: todayLocalDateString,
    };
  }
}
