import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { NgIcon } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent } from '../icon/icon.component';

export interface PageEvent {
  pageIndex: number;
  previousPageIndex?: number;
  pageSize: number;
  maxPages: number;
}

@Component({
  selector: 'tgn-paginator',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    NgIcon,
    IconComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.scss',
})
export class PaginatorComponent implements OnChanges {
  @Input({ required: true }) pageSize!: number;
  @Input({ required: true }) pageIndex!: number;
  @Input({ required: true }) maxPages!: number;
  @Input({ required: true }) maxItemCount!: number;
  @Input() hidden = false;
  @Input() pageSizeChoices: number[] = [5, 10, 20, 50, 100];
  @Output() onPageEvent = new EventEmitter<PageEvent>();

  selectionFormControl = new FormControl<number>(this.pageSizeChoices[0]);

  ngOnChanges(changes: SimpleChanges): void {
    const simpleChange: SimpleChange = changes['pageSize'];
    if (simpleChange === undefined || simpleChange === null) {
      return;
    }
    const currentPageSize = simpleChange.currentValue;
    if (currentPageSize != simpleChange.previousValue) {
      this.selectionFormControl.setValue(currentPageSize);
    }

    if (simpleChange.previousValue == undefined) {
      this.setInitialPageSizeChoices(simpleChange);
    }
  }

  previous() {
    const pageEvent = this.currentPageEvent();
    pageEvent.pageIndex = Math.max(0, pageEvent.pageIndex - 1);
    this.onPageEvent.emit(pageEvent);
  }

  next() {
    const pageEvent = this.currentPageEvent();
    pageEvent.pageIndex = Math.min(this.maxPages - 1, pageEvent.pageIndex + 1);
    this.onPageEvent.emit(pageEvent);
  }

  updatePageSize(matSelectionChange: MatSelectChange) {
    const pageEvent = this.currentPageEvent();
    pageEvent.pageSize = matSelectionChange.value;
    this.onPageEvent.emit(pageEvent);
  }

  private currentPageEvent(): PageEvent {
    return {
      pageIndex: this.pageIndex,
      previousPageIndex: this.pageIndex,
      pageSize: this.pageSize,
      maxPages: this.maxPages,
    };
  }

  private setInitialPageSizeChoices(simpleChange: SimpleChange) {
    if (!this.pageSizeChoices.includes(simpleChange.currentValue)) {
      this.pageSizeChoices.push(simpleChange.currentValue);
    }

    if (this.maxItemCount > 0) {
      this.pageSizeChoices = this.pageSizeChoices.sort((a: number, b: number) => a - b);
      this.pageSizeChoices = this.pageSizeChoices.filter((pageSize: number) => pageSize < this.maxItemCount);
    }
  }
}
