import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatAnchor, MatButton } from '@angular/material/button';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { routes_config } from '@core/constants';
import { TranslateModule } from '@ngx-translate/core';
import { CurrentUserService } from '@user/service/current-user.service';

@Component({
  selector: 'app-deactivated-page',
  standalone: true,
  imports: [FormElementComponent, TranslateModule, AsyncPipe, MatButton, RouterLink, MatAnchor],
  templateUrl: './deactivated-page.component.html',
  styleUrl: './deactivated-page.component.scss',
})
export class DeactivatedPageComponent {
  currentUser$ = this.currentUserService.currentUser$;
  readonly appRoutes = routes_config;

  constructor(
    private currentUserService: CurrentUserService,
    private authService: AuthService,
    private router: Router,
  ) {}

  async logout() {
    await this.authService.logout();
    await this.router.navigateByUrl(routes_config.LOGIN.path);
  }
}
