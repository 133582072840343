import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnimalDto, AnimalId } from '@animal/dtos/animal.dto';
import { AnimalService } from '@animal/services/animal.service';
import { TaskEntityType } from '@care/dtos/task.dto';
import { BoxDto, BoxId, StationDto, StationId } from '@case/dtos/base-data.dto';
import { CaseId, CaseListViewDto } from '@case/dtos/case.dto';
import { CaseService } from '@case/services/case.service';
import { SearchResultDto } from '@core/components/autocomplete/dto/search-result.dto';
import { GlobalSearchAnimalFilter } from '@core/components/global-search/model/global-search-animal-filter';
import { GlobalSearchCaseFilter } from '@core/components/global-search/model/global-search-case-filter';
import { PageResult } from '@core/data/tigon-datasource';
import { GlobalSearchResultDto } from '@core/dto/global-search-result.dto';
import { BaseService } from '@core/services/base-service';
import { mergeHttpParams } from '@core/utils/helplers';
import { Observable, map, of } from 'rxjs';

export interface SearchResultEntity {
  id: AnimalId | CaseId | BoxId | StationId;
  name: string;
}

export interface SearchResult {
  entities: SearchResultEntity[];
}

export enum EntityType {
  Animal = 'Animal',
  Case = 'Case',
  Box = 'Box',
  Station = 'Station',
}

export interface NumGlobalSearchResults {
  numAnimalEntries: number;
  numCaseEntries: number;
  numContactEntries: number;
  numBillEntries: number;
}

export function taskEntityTypeToEntityType(taskEntityType: TaskEntityType): EntityType {
  switch (taskEntityType) {
    case TaskEntityType.Case:
      return EntityType.Case;
    case TaskEntityType.Animal:
      return EntityType.Animal;
    case TaskEntityType.Box:
      return EntityType.Box;
    case TaskEntityType.Station:
      return EntityType.Station;
    default:
      throw new Error(`Unknown task entity type: ${taskEntityType}`);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BaseService {
  constructor(
    private http: HttpClient,
    private animalService: AnimalService,
    private caseService: CaseService,
  ) {
    super();
  }

  search(query: string, entityType: EntityType): Observable<SearchResult> {
    switch (entityType) {
      case EntityType.Animal: {
        return this.animalService.searchAnimal(query).pipe(
          map((animals: AnimalDto[]) => {
            return {
              entities: animals.map(it => {
                return { id: it.id, name: it.name ?? it.trdId };
              }),
            };
          }),
        );
      }
      case EntityType.Box: {
        return this.searchBox(query).pipe(
          map((boxes: BoxDto[]) => {
            return { entities: boxes };
          }),
        );
      }
      case EntityType.Case: {
        return this.caseService.searchCases(query).pipe(
          map((cases: PageResult<CaseListViewDto>) => {
            return {
              entities: cases.items.map(it => {
                return { id: it.id, name: it.caseNumber };
              }),
            };
          }),
        );
      }
      case EntityType.Station: {
        return this.searchStation(query).pipe(
          map((stations: StationDto[]) => {
            return { entities: stations };
          }),
        );
      }
    }
  }

  globalSearch(
    query: string,
    animalFilter: GlobalSearchAnimalFilter,
    caseFilter: GlobalSearchCaseFilter,
    numEntries: NumGlobalSearchResults,
  ): Observable<GlobalSearchResultDto> {
    let params: HttpParams = mergeHttpParams(animalFilter.toAnimalFilterDto(), caseFilter.toCaseFilterDto());
    params = params.set('query', query);
    params = params.set('entryLimit.numAnimalEntries', numEntries.numAnimalEntries.toString());
    params = params.set('entryLimit.numCaseEntries', numEntries.numCaseEntries.toString());
    params = params.set('entryLimit.numContactEntries', numEntries.numContactEntries.toString());
    params = params.set('entryLimit.numBillEntries', numEntries.numBillEntries.toString());

    return this.http.get<GlobalSearchResultDto>(`${this.apiUrl}/global/search`, { params: params });
  }

  searchBox(query: string): Observable<BoxDto[]> {
    if (query === '') {
      return of([]);
    }

    let params: HttpParams = new HttpParams();
    params = params.set('query', query);

    return this.http.get<SearchResultDto<BoxDto>>(`${this.apiUrl}/master/boxes/search`, { params: params }).pipe(
      map((searchResult: SearchResultDto<BoxDto>) => {
        return searchResult.data;
      }),
    );
  }

  searchStation(query: string): Observable<StationDto[]> {
    if (query === '') {
      return of([]);
    }

    let params: HttpParams = new HttpParams();
    params = params.set('query', query);

    return this.http.get<SearchResultDto<StationDto>>(`${this.apiUrl}/master/stations/search`, { params: params }).pipe(
      map((searchResult: SearchResultDto<StationDto>) => {
        return searchResult.data;
      }),
    );
  }
}
