import { Pipe, PipeTransform } from '@angular/core';
import { ContactDto } from '@contact/dto/contact.dto';
import { notNullish } from '@core/utils/rxjs';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'contactDisplay',
  standalone: true,
})
export class ContactDisplayPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: ContactDto | null, config?: { showUnknown?: boolean; onlyName?: boolean }): string {
    if (value === null) {
      if (config?.showUnknown) {
        return this.translate.instant('GENERAL.DOMAIN.UNKNOWN');
      }
      return '';
    }
    let values = [value.firstName, value.lastName, value.company, value.zip, value.city];

    if (config?.onlyName && (value.firstName !== null || value.lastName !== null)) {
      values = [value.firstName, value.lastName];
    }

    return values
      .map(it => it?.trim())
      .filter(notNullish)
      .filter(it => it?.length > 0)
      .join(', ');
  }
}
