import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isDefined } from '@core/utils/helplers';
import { Observable, OperatorFunction, Subject, combineLatest, filter, map, pipe, scan, shareReplay, startWith, switchMap } from 'rxjs';
import { createOperatorSubscriber } from 'rxjs/internal/operators/OperatorSubscriber';
import { operate } from 'rxjs/internal/util/lift';

export type Loadable<T> = Loading<T> | Complete<T>;

export class Loading<T> {
  private static _instance: Loading<never> = new Loading();

  static instance<T>(): Loading<T> {
    return Loading._instance;
  }

  isLoading(): this is Loading<T> {
    return true;
  }

  isComplete(): this is Complete<T> {
    return false;
  }

  unwrap(): T | undefined {
    return undefined;
  }
}

export class Complete<T> {
  constructor(readonly state: T) {}

  isLoading(): this is Loading<T> {
    return false;
  }

  isComplete(): this is Complete<T> {
    return true;
  }

  unwrap(): T | undefined {
    return this.state;
  }
}

export function mapState<T, R>(project: (value: T, index: number) => R): OperatorFunction<Loadable<T>, Loadable<R>> {
  return map<Loadable<T>, Loadable<R>>((loadable: Loadable<T>, index: number) => {
    if (loadable.isComplete()) {
      return new Complete<R>(project(loadable.state, index));
    }
    return Loading.instance();
  });
}

export function unwrapState<T>(): OperatorFunction<Loadable<T>, T> {
  return operate<Loadable<T>, T>((source, subscriber) => {
    source.subscribe(createOperatorSubscriber(subscriber, value => value.isComplete() && subscriber.next(value.state)));
  });
}

export function unwrapStateOrUndefined<T>(): OperatorFunction<Loadable<T>, T | undefined> {
  return operate<Loadable<T>, T>((source, subscriber) => {
    source.subscribe(
      createOperatorSubscriber(subscriber, value => (value.isComplete() ? subscriber.next(value.state) : subscriber.next(undefined))),
    );
  });
}

export function unwrapLoading<T>(): OperatorFunction<Loadable<T>, boolean> {
  return map<Loadable<T>, boolean>(value => value.isLoading());
}

export function filterDefined<T>(): OperatorFunction<T | undefined | null, T> {
  return filter(isDefined);
}

export function asLoadable$<T>(value$: Observable<T | undefined>, loading$: Observable<boolean>): Observable<Loadable<T>> {
  return combineLatest([value$, loading$]).pipe(
    map(([value, loading]) => (loading || !value ? Loading.instance<T>() : new Complete(value))),
    startWith(Loading.instance<T>()),
  );
}

export function toLoadable<T>(): OperatorFunction<T, Loadable<T>> {
  return pipe(
    map(result => new Complete(result)),
    startWith(Loading.instance<T>()),
  );
}

export function unwrapLoadable<T>(observable: Observable<Loadable<T>>): [Observable<T>, Observable<boolean>] {
  return [observable.pipe(unwrapState()), observable.pipe(unwrapLoading())];
}

export interface ViewStoreQuery {
  query: string;
}

export class ViewStore<D, P> {
  readonly params$: Observable<P>;
  readonly data$: Observable<Loadable<D>>;
  readonly loading$: Observable<boolean>;
  private readonly paramsSubject = new Subject<Partial<P>>();

  constructor(initial: P, pageRequest: (params: P) => Observable<Loadable<D>>, destroyRef = inject(DestroyRef)) {
    this.params$ = this.paramsSubject.pipe(
      scan((original, update) => ({ ...original, ...update }), initial),
      startWith(initial),
      shareReplay(1),
      takeUntilDestroyed(destroyRef),
    );
    this.data$ = this.params$.pipe(
      switchMap(params => pageRequest(params)),
      shareReplay(1),
      takeUntilDestroyed(destroyRef),
    );
    this.loading$ = this.data$.pipe(unwrapLoading());
  }

  update(params: Partial<P> = {}) {
    this.paramsSubject.next(params);
  }
}
