<div class="scrollable-table-container">
  <div class="scrollable-table-inner">
    @if (dataLoaded) {
      @if (hasItems || showTableIfEmpty) {
        <ng-content></ng-content>
      } @else {
        <div class="no-items-container">Es wurden keine Elemente gefunden</div>
      }
    }
  </div>
</div>

@if (hasItems || showTableIfEmpty) {
  <ng-content select="[paginator]" />
}
