import { AnimalDto } from '@animal/dtos/animal.dto';
import { BoxDto, StationDto } from '@case/dtos/base-data.dto';
import { CaseDto } from '@case/dtos/case.dto';
import { CaseEntryType } from '@core/models/general';
import { BrandedId } from '@core/utils/brand';
import { Iso8601String, IsoLocalDateString } from '@core/utils/date';
import { FileCollectionDto } from '@file/dto/file-collection.dto';
import { UserDto } from '@user/dto/user.dto';

export enum TaskStatus {
  'Open' = 'Open',
  'Done' = 'Done',
}

export type TaskId = BrandedId<'TaskId'>;
type TimeString = string;
export type TaskCategoryId = BrandedId<'TaskCategoryId'>;

export enum RepetitionUnit {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export interface TaskRepetition {
  interval: number;
  unit: RepetitionUnit;
}

export enum TaskCategoryType {
  WeightCheck = 'WeightCheck',
}

export enum TaskCategoryGroup {
  Care = 'Care',
  Admin = 'Admin',
}

export interface TaskCategoryDto {
  id: TaskCategoryId;
  name: string;
  group: TaskCategoryGroup;
  type: TaskCategoryType;
  active: boolean;
}

export interface CreateTaskCategoryDto {
  name: string;
  group: TaskCategoryGroup;
}

export interface UpdateTaskCategoryDto {
  active: boolean;
}

export enum TaskEntityType {
  Case = 'Case',
  Animal = 'Animal',
  Box = 'Box',
  Station = 'Station',
}

export interface BaseTaskDto {
  id: TaskId;
  title: string;
  description: string | null;
  report: string | null;
  taskCategory: TaskCategoryDto;
  status: TaskStatus;
  terminationDate: IsoLocalDateString | null;
  terminationTime: TimeString | null;
  repetition: TaskRepetition | null;
  createdDate: Iso8601String;
  fileCollection: FileCollectionDto;
  completionUser: UserDto | null;
  completionDate: Iso8601String | null;
  creationUser: UserDto | null;

  entityType: TaskEntityType;
}

export type CreateTaskDto = Omit<BaseTaskDto, 'id' | 'createdDate' | 'report' | 'completionUser' | 'completionDate' | 'creationUser'> & {
  entityId: string | null;
  entityType: TaskEntityType | null;
};

export type UpdateTaskDto = Omit<
  BaseTaskDto,
  'createdDate' | 'fileCollection' | 'entityType' | 'completionUser' | 'completionDate' | 'creationUser'
>;

export interface AnimalTaskDto extends BaseTaskDto {
  entityType: TaskEntityType.Animal;
  animal: AnimalDto;
  animalCaseEntryType: CaseEntryType;
}

export interface CaseTaskDto extends BaseTaskDto {
  entityType: TaskEntityType.Case;
  case: CaseDto;
}

export interface BoxTaskDto extends BaseTaskDto {
  entityType: TaskEntityType.Box;
  box: BoxDto;
}

export interface StationTaskDto extends BaseTaskDto {
  entityType: TaskEntityType.Station;
  station: StationDto;
}

export type TaskDto = AnimalTaskDto | CaseTaskDto | BoxTaskDto | StationTaskDto;

export function isAnimalTask(task: TaskDto): task is AnimalTaskDto {
  return task.entityType === TaskEntityType.Animal;
}

export function isCaseTask(task: TaskDto): task is CaseTaskDto {
  return task.entityType === TaskEntityType.Case;
}

export function isBoxTask(task: TaskDto): task is BoxTaskDto {
  return task.entityType === TaskEntityType.Box;
}

export function isStationTask(task: TaskDto): task is StationTaskDto {
  return task.entityType === TaskEntityType.Station;
}

export function getTaskEntity(task: TaskDto): AnimalDto | CaseDto | BoxDto | StationDto {
  if (isAnimalTask(task)) {
    return task.animal;
  }
  if (isCaseTask(task)) {
    return task.case;
  }
  if (isBoxTask(task)) {
    return task.box;
  }
  if (isStationTask(task)) {
    return task.station;
  }
  throw new Error('Unknown task entity type');
}
