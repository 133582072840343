import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatChip } from '@angular/material/chips';
import { MatMenuItem } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@core/components/icon/icon.component';
import { routes_config } from '@core/constants';
import {
  AnimalGlobalSearchResultDto,
  BillGlobalSearchResultDto,
  CaseGlobalSearchResultDto,
  ContactGlobalSearchResultDto,
  SearchResultType,
} from '@core/dto/global-search-result.dto';
import { AnimalType } from '@core/models/general';
import { notNullish } from '@core/utils/rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const notBlankOrNullish = (value: string | null | undefined): boolean => {
  return value != null && value.length > 0;
};

export type SearchResultEntry =
  | AnimalGlobalSearchResultDto
  | CaseGlobalSearchResultDto
  | ContactGlobalSearchResultDto
  | BillGlobalSearchResultDto;

@Component({
  selector: 'tgn-global-search-result',
  standalone: true,
  imports: [MatChip, IconComponent, RouterLink, MatMenuItem, TranslateModule, MatAnchor],
  templateUrl: './global-search-result.component.html',
  styleUrl: './global-search-result.component.scss',
  providers: [DatePipe],
})
export class GlobalSearchResultComponent {
  @Input({ required: true }) result!: SearchResultEntry;
  @Input({ required: true }) query!: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly SearchResultType = SearchResultType;
  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly AnimalType = AnimalType;

  constructor(
    private translate: TranslateService,
    private datePipe: DatePipe,
  ) {}

  // @IMPROVEMENT -> refactor into pipe for memoization
  getDisplayTitle(entry: SearchResultEntry): string {
    switch (entry.type) {
      case SearchResultType.Animal:
        const name = entry.name !== null ? this.highlightMatchingText(entry.name, this.query) : 'Tier';
        const trdId = this.highlightMatchingText(entry.trdId, this.query);

        const taxonomy = [entry.group, entry.species, entry.race]
          .filter(notNullish)
          .filter(it => it?.length > 0)
          .join(', ');
        return `${name} - ${trdId} - ${taxonomy}`;
      case SearchResultType.Case:
        const title = entry.title !== null ? this.highlightMatchingText(entry.title, this.query) : 'Fall';
        const caseNumber = this.highlightMatchingText(entry.caseNumber, this.query);
        return `${title} - Nr: ${caseNumber}`;
      case SearchResultType.Contact:
        const firstName = this.highlightMatchingText(entry.firstName, this.query);
        const lastName = this.highlightMatchingText(entry.lastName, this.query);

        let highlighted = `${firstName} ${lastName}`;

        if (entry.firstName && entry.lastName) {
          const firstNameLastName = `${entry.firstName} ${entry.lastName}`;
          const lastNameFirstName = `${entry.lastName} ${entry.firstName}`;
          const firstNameLastNameHighlight = this.highlightMatchingText(firstNameLastName, this.query);
          const lastNameFirstNameHighlight = this.highlightMatchingText(lastNameFirstName, this.query);

          // if joined name is highlighted then use it always in the order 'firstName lastName'
          if (firstNameLastNameHighlight.length > firstNameLastName.length) {
            highlighted = firstNameLastNameHighlight;
          }
          if (lastNameFirstNameHighlight.length > lastNameFirstName.length) {
            highlighted = lastNameFirstNameHighlight;
          }
        }

        return highlighted;
      case SearchResultType.Bill:
        const billingNumber = this.highlightMatchingText(entry.billingNumber, this.query);
        return `Rechnung - ${billingNumber}`;
    }
  }

  // @IMPROVEMENT -> refactor into pipe for memoization
  getDisplayableInfoRow(entry: SearchResultEntry): string[] {
    switch (entry.type) {
      case SearchResultType.Animal:
        const boxName = this.highlightMatchingText(entry.boxName, this.query);
        const animalState = this.translate.instant(`GENERAL.DOMAIN.AnimalState.${entry.state}`) as string;
        return [animalState, boxName].filter(notBlankOrNullish);
      case SearchResultType.Case:
        const numAnimals = entry.caseAnimals.length;
        const numAnimalsText = numAnimals === 0 ? 'Keine Tiere' : numAnimals > 1 ? `${numAnimals} Anzahl Tiere` : '1 Tier';

        let animalNames = entry.caseAnimals
          .map(animal => this.highlightMatchingText(animal.name, this.query))
          .filter(notBlankOrNullish)
          .join(', ');

        if (animalNames.length > 0) {
          animalNames = `Tiere: ${animalNames}`;
        }

        const entryType = this.translate.instant(`GENERAL.DOMAIN.CaseEntryType.${entry.entryType}`) as string;

        const stmzNumbers = entry.caseAnimals
          .map(animal => {
            if (animal.stmzNumber == null) return null;
            return this.highlightMatchingText(animal.stmzNumber, this.query);
          })
          .filter(notNullish)
          .filter(it => it?.length > 0)
          .join(', ');

        const stmzText = stmzNumbers.length > 0 ? `STMZ: ${stmzNumbers}` : 'Keine STMZ';

        const rescueText = entry.rescueNumber ? `Rettung: ${this.highlightMatchingText(entry.rescueNumber, this.query)}` : '';

        const foundLocationText = entry.foundLocation ? `Fundort: ${this.highlightMatchingText(entry.foundLocation, this.query)}` : '';

        const state = this.translate.instant(`GENERAL.DOMAIN.CaseState.${entry.state}`) as string;
        return [state, numAnimalsText, entryType, stmzText, foundLocationText, rescueText, animalNames].filter(notBlankOrNullish);
      case SearchResultType.Contact:
        const address = [entry.address, entry.addressAddendum, entry.zip, entry.city, entry.country]
          .filter(notNullish)
          .filter(it => it?.length > 0)
          .join(', ');
        return [
          `ID: ${this.highlightMatchingText(entry.personNumber?.toString() ?? null, this.query)}`,
          this.highlightMatchingText(entry.company, this.query),
          this.highlightMatchingText(address, this.query),
        ].filter(notBlankOrNullish);
      case SearchResultType.Bill:
        return [
          `Status: ${this.translate.instant(`GENERAL.DOMAIN.BillStatus.${entry.status}`)}`,
          `Belegdatum: ${this.datePipe.transform(entry.invoiceDocumentDate, 'dd.MM.yyyy')}`,
          `Rechnungsdatum: ${entry.billingDate}`,
          `Typ: ${this.translate.instant(`GENERAL.DOMAIN.BillRangeType.${entry.billRangeType}`)}`,
          `Erstellt: ${this.datePipe.transform(entry.createdDate, 'dd.MM.yyyy')}`,
        ];
    }
  }

  private highlightMatchingText(text: string | null, query: string): string {
    if (text == null || text == '') {
      return '';
    }
    if (query.length === 0) {
      return text;
    }
    const index = text.toLowerCase().indexOf(query.toLowerCase());
    if (index === -1) {
      return text;
    }

    const pre = text.slice(0, index);
    const match = text.slice(index, index + query.length);
    const post = text.slice(index + query.length);

    return `${pre}${this.addHighlighting(match)}${post}`;
  }

  private addHighlighting(text: string | null): string {
    return `<span class="highlight">${text}</span>`;
  }
}
