import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, NgClass } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCell, MatTableModule } from '@angular/material/table';
import { CreateSpeciesDto, UpdateSpeciesDto } from '@baseData/dtos/create-species.dto';
import { SpeciesService } from '@baseData/services/species.service';
import { SpeciesDto, SpeciesId, SubGroupDto } from '@case/dtos/base-data.dto';
import { BaseDataService } from '@case/services/base-data-service';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { SelectComponent } from '@core/components/select/select.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { AnimalType } from '@core/models/general';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { defaultDebounce } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { Observable, ReplaySubject, combineLatest, map, startWith, take } from 'rxjs';

export type UpdateSpeciesDtoForm = FormGroup<{
  active: FormControl<boolean>;
  subGroup: FormControl<SubGroupDto>;
}>;

export type NewSpeciesDtoForm = FormGroup<{
  name: FormControl<string | null>;
  subGroup: FormControl<SubGroupDto | null>;
}>;

export function uniqueSpeciesNameValidator(subGroup$: Observable<SubGroupDto | null>, data$: Observable<SpeciesDto[]>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return combineLatest([subGroup$, data$]).pipe(
      map(values => {
        const [subGroup, species] = values;
        return species.filter(it => subGroup == null || it.subGroupId === subGroup.id);
      }),
      map(species => {
        return species.some(item => item.name === control.value) ? { uniqueName: true } : null;
      }),
    );
  };
}

interface TableConfig {}

@Component({
  selector: 'tgn-base-data-species',
  standalone: true,
  imports: [
    AsyncPipe,
    FieldErrorTranslationPipe,
    FormElementComponent,
    MatButton,
    MatCell,
    ScrollableTableComponent,
    MatTableModule,
    TypesafeMatTableModule,
    TranslateModule,
    MatError,
    NgClass,
    MatFormField,
    MatInput,
    SelectComponent,
    ReactiveFormsModule,
    ToRadioChoicePipe,
    SingleLineTextComponent,
    ScrollingModule,
  ],
  templateUrl: './base-data-species.component.html',
  styleUrl: './base-data-species.component.scss',
})
export class BaseDataSpeciesComponent implements OnInit {
  @Input({ required: true }) animalType!: AnimalType;

  columns = ['name', 'subGroup', 'active'];
  appRoutes = routes_config;

  newSpeciesDtoDtoForm!: NewSpeciesDtoForm;
  editSpeciesDtoDtoFormGroups: Map<SpeciesId, UpdateSpeciesDtoForm> = new Map<SpeciesId, UpdateSpeciesDtoForm>();

  subGroupChoices$: ReplaySubject<SubGroupDto[]> = new ReplaySubject<SubGroupDto[]>(1);

  dataSource!: TigonDatasource<SpeciesDto, TableConfig>;
  activeInactiveChoice: RadioChoice<Boolean>[] = [
    {
      label: 'GENERAL.FORM.LABEL.Active',
      object: true,
    },
    {
      label: 'GENERAL.FORM.LABEL.Inactive',
      object: false,
    },
  ];

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private speciesDtoService: SpeciesService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private dataService: BaseDataService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.dataSource = new TigonDatasource<SpeciesDto, TableConfig>(
      {},
      () => {
        if (this.animalType === AnimalType.DomesticAnimal) {
          return this.speciesDtoService.getAllDomesticAnimal();
        } else {
          return this.speciesDtoService.getAllWildAnimal();
        }
      },
      this.destroyRef,
    );

    this.subGroupChoices$.pipe(take(1)).subscribe(subGroups => {
      this.dataSource.getData().subscribe(species => {
        this.editSpeciesDtoDtoFormGroups = new Map();
        // only allow to deactivate submit reasons without a tag
        species
          .filter((speciesDto: SpeciesDto) => {
            return speciesDto.tag === null;
          })
          .forEach((speciesDto: SpeciesDto) => {
            const subGroup = subGroups.find(sg => sg.id === speciesDto.subGroupId)!;
            const speciesDtoFormGroup: UpdateSpeciesDtoForm = this.fb.group({
              active: this.fb.nonNullable.control<boolean>(speciesDto.active, [Validators.required]),
              subGroup: this.fb.nonNullable.control<SubGroupDto>(subGroup, [Validators.required]),
            });
            this.accessService.disableBasedOnRole(speciesDtoFormGroup, RestrictedSection.Backoffice);
            this.editSpeciesDtoDtoFormGroups.set(speciesDto.id, speciesDtoFormGroup);
            this.autoUpdateRows(speciesDtoFormGroup, speciesDto);
          });
      });
    });

    this.dataService
      .getBaseData()
      .pipe(
        map(data => {
          let subGroups;
          if (this.animalType === AnimalType.DomesticAnimal) {
            subGroups = data.domesticAnimal.subGroups;
          } else {
            subGroups = data.wildAnimal.subGroups;
          }
          return subGroups;
        }),
      )
      .subscribe(subGroups => {
        this.subGroupChoices$.next(subGroups);
      });

    this.createNewSpeciesDtoForm();
  }

  addEntry() {
    const form = this.newSpeciesDtoDtoForm.getRawValue();
    if (!form.name || !form.subGroup) {
      return;
    }

    const createSpeciesDto: CreateSpeciesDto = {
      name: form.name,
      subGroupId: form.subGroup.id,
    };

    this.speciesDtoService.create(createSpeciesDto).subscribe({
      next: () => {
        this.newSpeciesDtoDtoForm.setValue({
          name: null,
          subGroup: null,
        });
        this.newSpeciesDtoDtoForm.markAsUntouched();
        this.newSpeciesDtoDtoForm.markAsPristine();
        this.snackbar.showSuccessMessage(`Die Tierart '${createSpeciesDto.name}' wurde erfolgreich erstellt.`);
        this.dataSource.refresh();
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Die Tierart konnte nicht erstellt werden.');
      },
    });
  }

  private createNewSpeciesDtoForm() {
    this.newSpeciesDtoDtoForm = this.fb.group(
      {
        name: this.fb.control<string | null>(
          null,

          {
            validators: [Validators.required, Validators.minLength(1)],

            updateOn: 'change',
          },
        ),
        subGroup: this.fb.control<SubGroupDto | null>(null, [Validators.required]),
      },
      {},
    );

    this.accessService.disableBasedOnRole(this.newSpeciesDtoDtoForm, RestrictedSection.Backoffice);

    this.newSpeciesDtoDtoForm.addAsyncValidators([
      uniqueSpeciesNameValidator(
        this.newSpeciesDtoDtoForm.controls.subGroup.valueChanges.pipe(startWith(this.newSpeciesDtoDtoForm.controls.subGroup.value)),
        this.dataSource.getData(),
      ),
    ]);
  }

  private autoUpdateRows(formGroup: UpdateSpeciesDtoForm, speciesDto: SpeciesDto) {
    formGroup.valueChanges.pipe(defaultDebounce(), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        const formValues = formGroup.getRawValue();

        if (formGroup.invalid || !formValues.subGroup) {
          return;
        }

        const dto: UpdateSpeciesDto = {
          active: formValues.active,
          subGroupId: formValues.subGroup.id,
        };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.speciesDtoService.update(speciesDto.id, dto).subscribe(() => {
          this.dataSource.refresh();
        });
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Die Tierart konnte nicht aktualisiert werden.');
      },
    });
  }
}
