import { Iso8601String } from '@core/utils/date';
import { UserDto } from '@user/dto/user.dto';

export interface ChangeAuthor {
  name: string;
  email: string;
}

export type JsonString = string;

export enum AuditOperation {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

export interface Metadata {
  author: ChangeAuthor;
  commitDate: Iso8601String;
}

export interface AffectedObject {
  id: string;
  name: string | null;
  entityType: string;
}

export interface AuditChange {
  type: AuditOperation;
  metadata: Metadata;
  affectedObject: AffectedObject;
}

export type AuditUpdate = AuditChange & {
  type: AuditOperation.Update;
  old: string | null;
  new: string;
};

export type AuditCreate = AuditChange & {
  type: AuditOperation.Create;
  new: string;
};

export type AuditDelete = AuditChange & {
  type: AuditOperation.Delete;
};

export type AuditChangeUnion = AuditCreate | AuditUpdate | AuditDelete;

export function isAuditCreate(change: AuditChangeUnion): change is AuditCreate {
  return change.type === AuditOperation.Create;
}

export function isAuditUpdate(change: AuditChangeUnion): change is AuditUpdate {
  return change.type === AuditOperation.Update;
}

export function isAuditDelete(change: AuditChangeUnion): change is AuditDelete {
  return change.type === AuditOperation.Delete;
}

export interface SingleAuditFieldDto {
  user: UserDto;
  editDate: Iso8601String;
}
