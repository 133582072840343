import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { FullNamePipe } from '@core/pipes/full-name.pipe';
import { UserDto } from '@user/dto/user.dto';
import { InitialsPipe } from '@user/pipes/initials.pipe';

@Component({
  selector: 'tgn-user-badge',
  standalone: true,
  imports: [AvatarImageComponent, InitialsPipe, MatTooltip, FullNamePipe],
  templateUrl: './user-badge.component.html',
  styleUrl: './user-badge.component.scss',
})
export class UserBadgeComponent {
  @Input({ required: true }) user!: UserDto;
}
