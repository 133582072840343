<div *tgnContextActions class="tgn-context-actions">
  @if (contact$ | async; as contact) {
    @if (contact.archived) {
      <button
        (agreeEvent)="unarchive(contact)"
        [dialogTitle]="'Archivierung aufheben'"
        [dialogDescription]="'Soll dieser Kontakt wieder aktiv geschalten werden?'"
        appConfirmationDialog
        class="tgn-button"
        mat-flat-button
      >
        <span>{{ 'GENERAL.BUTTON.UNARCHIVE' | translate }}</span>
      </button>
    }
  }
</div>
<tgn-narrow-page-container>
  @if (form) {
    <form>
      <div class="form-grid grid-cols-12">
        <div class="row">
          <div class="item col-span-12 col-span-sm-12">
            <tgn-form-element [control]="form.controls.status" id="status" label="Status">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-select [choices]="statusChoices" [control]="control" [id]="id"></tgn-select>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-12 col-span-sm-12">
            <tgn-form-element
              [control]="form.controls.salutation"
              [id]="'salutation'"
              [label]="'ENTITY.Contact.salutation' | translate"
              class="row-span-3"
            >
              <ng-template formElement let-control="control" let-id="id">
                <div class="form-grid grid-cols-4">
                  <tgn-radio-group [choices]="salutationChoices" [control]="control" [id]="id" class="full-width" />
                </div>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-6 col-span-sm-12">
            <tgn-form-element [control]="form.controls.firstName" [id]="'firstName'" [label]="'ENTITY.Contact.firstName' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [formControl]="control"
                    [id]="id"
                    [placeholder]="'ENTITY.Contact.firstName' | translate"
                    cdkFocusInitial
                    type="text"
                  />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>

          <div class="item col-span-6 col-span-sm-12">
            <tgn-form-element [control]="form.controls.lastName" [id]="'lastName'" [label]="'ENTITY.Contact.lastName' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [placeholder]="'ENTITY.Contact.lastName' | translate" id="lastName" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-12 col-span-sm-12">
            <tgn-form-element [control]="form.controls.company" [id]="'company'" [label]="'ENTITY.Contact.company' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [formControl]="control"
                    [id]="id"
                    [placeholder]="'ENTITY.Contact.companyPlaceholder' | translate"
                    type="text"
                  />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-12 col-span-sm-12">
            <tgn-form-element
              [control]="form.controls.companyAddendum"
              [id]="'companyAddendum'"
              [label]="'ENTITY.Contact.companyAddendum' | translate"
            >
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [formControl]="control"
                    [id]="id"
                    [placeholder]="'ENTITY.Contact.companyAddendum' | translate"
                    type="text"
                  />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-12 col-span-sm-12">
            <tgn-form-element [control]="form.controls.address" [id]="'address'" [label]="'ENTITY.Contact.address' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [formControl]="control"
                    [id]="id"
                    [placeholder]="'ENTITY.Contact.addressPlaceholder' | translate"
                    type="text"
                  />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-6 col-span-sm-12">
            <tgn-form-element
              [control]="form.controls.addressAddendum"
              [id]="'address'"
              [label]="'ENTITY.Contact.addressAddendum' | translate"
            >
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
          <div class="item col-span-6 col-span-sm-12">
            <tgn-form-element [control]="form.controls.poBox" [id]="'address'" [label]="'ENTITY.Contact.poBox' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-3 col-span-sm-12">
            <tgn-form-element [control]="form.controls.zip" [id]="'zip'" [label]="'ENTITY.Contact.zip' | translate" label="Postleitzahl">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [formControl]="control"
                    [id]="id"
                    [placeholder]="'ENTITY.Contact.zipPlaceholder' | translate"
                    type="text"
                  />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
          <div class="item col-span-9 col-span-sm-12">
            <tgn-form-element [control]="form.controls.city" [id]="'city'" [label]="'ENTITY.Contact.city' | translate" label="Ort">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.city' | translate" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-12 col-span-sm-12">
            <tgn-form-element [control]="form.controls.country" [id]="'country'" [label]="'ENTITY.Contact.country' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.country' | translate" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-6 col-span-sm-12">
            <tgn-form-element [control]="form.controls.phone" [id]="'mobile'" [label]="'ENTITY.Contact.phone' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [formControl]="control"
                    [id]="id"
                    [placeholder]="'ENTITY.Contact.phonePlaceholder' | translate"
                    type="text"
                  />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
          <div class="item col-span-6 col-span-sm-12">
            <tgn-form-element [control]="form.controls.phoneWork" [id]="'email'" [label]="'ENTITY.Contact.phoneWork' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [formControl]="control"
                    [id]="id"
                    [placeholder]="'ENTITY.Contact.phonePlaceholder' | translate"
                    type="text"
                  />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-6 col-span-sm-12">
            <tgn-form-element [control]="form.controls.mobile" [id]="'mobile'" [label]="'ENTITY.Contact.mobile' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [formControl]="control"
                    [id]="id"
                    [placeholder]="'ENTITY.Contact.phonePlaceholder' | translate"
                    type="text"
                  />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
          <div class="item col-span-6 col-span-sm-12">
            <tgn-form-element [control]="form.controls.email" [id]="'email'" [label]="'ENTITY.Contact.email' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [formControl]="control"
                    [id]="id"
                    [placeholder]="'ENTITY.Contact.emailPlaceholder' | translate"
                    type="text"
                  />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row row-span-sm-3">
          <div class="item col-span-12 col-span-sm-12">
            <div class="toggles">
              <tgn-form-element [control]="form.controls.possibleDonor" class="col-span-6 row-span-3 mt-sm" id="possibleDonor">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-checkbox [formControl]="control" [id]="id">
                    {{ 'ENTITY.Contact.possibleDonor' | translate }}
                  </mat-checkbox>
                </ng-template>
              </tgn-form-element>

              <tgn-form-element [control]="form.controls.isDead" id="isDead">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-checkbox [formControl]="control" [id]="id">
                    {{ 'ENTITY.Contact.isDead' | translate }}
                  </mat-checkbox>
                </ng-template>
              </tgn-form-element>

              <tgn-form-element [control]="form.controls.noMailings" id="noMailings">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-checkbox [formControl]="control" [id]="id">
                    {{ 'ENTITY.Contact.noMailings' | translate }}
                  </mat-checkbox>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        </div>

        <div class="row row-span-sm-2">
          <div class="item col-span-12 col-span-sm-12">
            <tgn-form-element [control]="form.controls.legalRepresentationId" id="legalRep" label="Beistand">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-autocomplete
                  [viewStore]="contactViewStore"
                  [disabled]="form.controls.legalRepresentationId.disabled"
                  [displayFn]="contactDisplayFn"
                  [valueFn]="getContactValueFn"
                  [value]="contactDisplayFn(legalRepContact)"
                  [id]="id"
                  [locked]="form.controls.legalRepresentationId.value !== null"
                  (onRemoveSelected)="removeSelectedContact()"
                  (onOptionSelected)="setRelatedContact($event)"
                ></tgn-autocomplete>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row">
          <div class="item col-span-12">
            <tgn-form-element [control]="form.controls.note" id="note" [label]="'ENTITY.Contact.note' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <textarea
                    matInput
                    [formControl]="control"
                    [id]="id"
                    cdkAutosizeMinRows="5"
                    cdkTextareaAutosize
                    cdkAutosizeMaxRows="10"
                  ></textarea>
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
      </div>
    </form>
  }
</tgn-narrow-page-container>
