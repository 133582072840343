<tgn-collapsible [expanded]="true" [title]="animal | animalTitle" class="input-bg-white" variant="withBgColor">
  @if (placementForm) {
    <h3 class="subsection">{{ 'PAGE.PLACEMENT.FORM.LABEL.PLACEMENT' | translate }}</h3>
    <div class="form-grid grid-cols-6">
      <div class="row row-span-sm-2">
        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.placementType"
            [label]="'ENTITY.Placement.placementType' | translate"
            id="placementType"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select [choices]="placementTypeChoices" [control]="control" [id]="id"></tgn-select>
            </ng-template>
          </tgn-form-element>
        </div>

        <div class="item col-span-3 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.placementState"
            [label]="'ENTITY.Placement.placementState' | translate"
            id="placementState"
          >
            <ng-template formElement let-control="control" let-id="id">
              <tgn-select [choices]="placementStateChoices" [control]="control" [id]="id"></tgn-select>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <div class="row row-span-sm-2">
        <div class="item col-span-6 col-span-sm-6">
          <tgn-form-element
            [control]="placementForm.controls.placementNote"
            [label]="'ENTITY.Placement.placementNote' | translate"
            id="placementNote"
          >
            <ng-template formElement let-control="control" let-id="id">
              <mat-form-field appearance="outline">
                <textarea
                  [formControl]="control"
                  [id]="id"
                  [placeholder]="'GENERAL.FORM.PLACEHOLDER.HINTS' | translate"
                  cdkAutosizeMaxRows="10"
                  cdkAutosizeMinRows="5"
                  cdkTextareaAutosize
                  matInput
                ></textarea>
              </mat-form-field>
            </ng-template>
          </tgn-form-element>
        </div>
      </div>

      <h3 class="subsection">{{ 'PAGE.PLACEMENT.FORM.LABEL.PLACEMENT_MEDIATION' | translate }}</h3>

      <tgn-placement-mediation [placementForm]="placementForm" [placement]="placement!" />
    </div>
  }
</tgn-collapsible>
