<h1 mat-dialog-title>
  {{ 'PAGE.BASE_DATA_POSITIONS.UPDATE_BASE_DATA_POSITION_MODAL.TITLE' | translate }}
</h1>

<div class="mt-large" mat-dialog-content>
  <p>{{ 'PAGE.BASE_DATA_POSITIONS.UPDATE_BASE_DATA_POSITION_MODAL.INFO' | translate }}</p>

  <div class="form-grid grid-cols-6">
    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.validFrom"
          [hideError]="true"
          [label]="'ENTITY.BaseDataPosition.validFrom' | translate"
          id="validFrom"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-date-input [control]="control" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.validTo"
          [hideError]="true"
          [label]="'ENTITY.BaseDataPosition.validTo' | translate"
          id="validTo"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-date-input [control]="control" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>
  <button (click)="close()" [disabled]="form.invalid" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.BUTTON.SAVE' | translate }}
  </button>
</div>
