import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { routes_config } from '@core/constants';
import { UserStatus } from '@user/dto/user.dto';
import { CurrentUserService } from '@user/service/current-user.service';
import { firstValueFrom } from 'rxjs';

export function isNotDeactivated(): CanActivateFn {
  return async () => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const currentUserService = inject(CurrentUserService);

    const isLoggedIn = await authService.isLoggedIn();
    if (!isLoggedIn) {
      return true;
    }
    const user = await firstValueFrom(currentUserService.currentUser$);
    if (user.status === UserStatus.Deactivated) {
      return router.navigateByUrl(routes_config.DEACTIVATED_PAGE.path);
    }

    return true;
  };
}
