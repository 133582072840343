<div class="form-group">
  <mat-form-field [appearance]="appearance" subscriptSizing="dynamic">
    <mat-date-range-input [rangePicker]="picker">
      <input
        [autocomplete]="autocomplete"
        [disableControl]="readonly"
        [formControl]="control.controls.start"
        [placeholder]="startPlaceholder ?? ''"
        appDisableControl
        class="default-input custom-input"
        matInput
        matStartDate
      />

      <input
        [autocomplete]="autocomplete"
        [disableControl]="readonly"
        [formControl]="control.controls.end"
        [placeholder]="endPlaceholder ?? ''"
        appDisableControl
        class="default-input custom-input"
        matEndDate
        matInput
      />
    </mat-date-range-input>
    <button (click)="picker.open()" class="mat-clear-button" mat-icon-button matSuffix type="button">
      <tgn-icon [iconName]="'radixCalendar'" />
    </button>
    <mat-date-range-picker #picker (closed)="onClose()">
      @if (!hideActions) {
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>
            {{ 'GENERAL.BUTTON.CANCEL' | translate }}
          </button>
          <button mat-flat-button color="primary" matDatepickerApply (click)="onConfirm.next()">
            {{ 'GENERAL.BUTTON.APPLY' | translate }}
          </button>
        </mat-datepicker-actions>
      }
    </mat-date-range-picker>
  </mat-form-field>
</div>
