<tgn-narrow-page-container>
  <h1>Profil</h1>

  <div class="form">
    <div class="form-grid grid-cols-2">
      @if (currentUser$ | async; as user) {
        <div class="row">
          <div class="col col-span-2">
            <tgn-avatar-image
              [noRoleRestriction]="true"
              [avatar]="user"
              [onUpload]="uploadUserAvatarImage"
              size="size-3hg"
            ></tgn-avatar-image>
          </div>
        </div>

        <div class="row">
          <div class="item col-span-2">
            <tgn-form-element [control]="undefined" id="email" [label]="'ENTITY.User.email' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input [disabled]="true" matInput [value]="user.email" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
      }

      @if (form) {
        <div class="row row-span-sm-2">
          <div class="item col-span-1 col-span-sm-2">
            <tgn-form-element [control]="form.controls.firstName" id="firstName" [label]="'ENTITY.User.firstName' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" [placeholder]="'ENTITY.User.firstName' | translate" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>

          <div class="item col-span-1 col-span-sm-2">
            <tgn-form-element [control]="form.controls.lastName" id="lastName" [label]="'ENTITY.User.lastName' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" [placeholder]="'ENTITY.Contact.lastName' | translate" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        <div class="row">
          <div class="item col-span-2">
            <tgn-form-element [control]="form.controls.visa" id="visa" [label]="'ENTITY.User.visa' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <mat-form-field appearance="outline">
                  <input matInput [formControl]="control" [id]="id" [placeholder]="'ENTITY.User.visa' | translate" type="text" />
                </mat-form-field>
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
      }
    </div>
  </div>

  <div *tgnContextActions class="tgn-context-actions">
    <button (click)="save()" [disabled]="isSaving" class="tgn-button" mat-flat-button>{{ 'GENERAL.BUTTON.SAVE' | translate }}</button>
  </div>
</tgn-narrow-page-container>
