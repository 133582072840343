import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { ChipInputComponent } from '@core/components/chip-input/chip-input.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { InputUploadComponent } from '@core/components/input-upload/input-upload.component';
import { SelectComponent } from '@core/components/select/select.component';
import { TextareaComponent } from '@core/components/textarea/textarea.component';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { FileTagType } from '@file/dto/file-tag-type';
import { TranslateModule } from '@ngx-translate/core';

import { BoxDto, compareWith } from '../../dtos/base-data.dto';

export interface ParticularitiesForm {
  behavior: FormControl<string | null>;
  particularities: FormControl<string | null>;
  medications: FormControl<string | null>;
  accessories: FormControl<string[]>;
  box: FormControl<BoxDto | null>;
}

@Component({
  selector: 'app-particularities-form',
  standalone: true,
  imports: [
    FormElementComponent,
    TranslateModule,
    ReactiveFormsModule,
    ChipInputComponent,
    AsyncPipe,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    FormElementDirective,
    InputUploadComponent,
    SelectComponent,
    ToRadioChoicePipe,
    MatInput,
    CdkTextareaAutosize,
    TextareaComponent,
    AddIfMissingPipe,
  ],
  templateUrl: './particularities-form.component.html',
  styleUrl: './particularities-form.component.scss',
})
export class ParticularitiesFormComponent {
  @Input({ required: true })
  form!: FormGroup<ParticularitiesForm>;

  @Input({ required: true })
  animal!: AnimalDto;

  @Input({ required: true })
  boxes!: BoxDto[];

  protected readonly compareWith = compareWith;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly FileTagType = FileTagType;
}
