import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { AnimalRaceInputComponent } from '@animal/components/animal-race-input/animal-race-input.component';
import { AnimalDto } from '@animal/dtos/animal.dto';
import { AnimalTaxonomyBaseData, displayAnimalAutocomplete } from '@animal/model/animal';
import { SpeciesChoicesPipe } from '@animal/pipes/species-choices.pipe';
import { SubGroupChoicesPipe } from '@animal/pipes/sub-group-choices.pipe';
import { AnimalService } from '@animal/services/animal.service';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { ViewStore, ViewStoreQuery, toLoadable } from '@core/components/autocomplete/model/loadable';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { RadioChoice, RadioGroupComponent } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { FurLength, RoughAge } from '@core/models/general';
import { AddIfMissingPipe } from '@core/pipes/add-if-missing.pipe';
import { CastPipe } from '@core/pipes/cast.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { map, take } from 'rxjs';

import { GroupDto, SpeciesDto, SubGroupDto, compareWith } from '../../dtos/base-data.dto';
import { CompactCaseAnimalDto } from '../../dtos/case-animal.dto';
import { CurrentAnimalContext } from '../../pages/entry-check-page/entry-check-page.component';
import { BaseDataService } from '../../services/base-data-service';
import { CaseAnimalService } from '../../services/case-animal.service';

export interface AnimalForm {
  hasChip: FormControl<boolean | null>;
  chipId: FormControl<string | null>;
  ringId: FormControl<string | null>;
  name: FormControl<string | null>;
  group: FormControl<GroupDto | null>;
  subGroup: FormControl<SubGroupDto | null>;
  species: FormControl<SpeciesDto | null>;
  race: FormControl<string | null>;
  raceNote: FormControl<string | null>;
  coloring: FormControl<string | null>;
  furLength: FormControl<FurLength | null>;
  roughAge: FormControl<RoughAge | null>;
  guessedAge: FormControl<string | null>;
}

@Component({
  selector: 'app-animal-entry-form',
  standalone: true,
  imports: [
    TranslateModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormElementComponent,
    RadioGroupComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatAutocompleteModule,
    AutocompleteComponent,
    FormElementDirective,
    SelectComponent,
    ToRadioChoicePipe,
    AnimalRaceInputComponent,
    CastPipe,
    SpeciesChoicesPipe,
    SubGroupChoicesPipe,
    MatInput,
    AddIfMissingPipe,
  ],
  templateUrl: './animal-entry-form.component.html',
  styleUrl: './animal-entry-form.component.scss',
})
export class AnimalEntryFormComponent implements OnInit {
  @Input({ required: true }) form!: FormGroup<AnimalForm>;
  @Input({ required: true }) currentAnimalContext!: CurrentAnimalContext;

  @Output() onCaseAnimalChange: EventEmitter<CompactCaseAnimalDto> = new EventEmitter<CompactCaseAnimalDto>();

  yesNoChoices: RadioChoice<boolean>[] = [
    {
      label: 'GENERAL.FORM.LABEL.YES',
      object: true,
    },
    {
      label: 'GENERAL.FORM.LABEL.NO',
      object: false,
    },
  ];

  taxonomyBaseData: AnimalTaxonomyBaseData = {
    groups: [],
    subGroups: [],
    species: [],
  };

  animalAutocompleteStore: ViewStore<AnimalDto[], ViewStoreQuery> = new ViewStore<AnimalDto[], ViewStoreQuery>({ query: '' }, value => {
    return this.animalService.searchAnimal(value.query).pipe(
      map((data: AnimalDto[]) => {
        return data.filter((dto: AnimalDto) => {
          return !this.currentAnimalContext.caseDto.caseAnimals.some((it: CompactCaseAnimalDto) => {
            return it.animal.id == dto.id;
          });
        });
      }),
      toLoadable(),
    );
  });

  groups: GroupDto[] = [];

  protected readonly furLengthChoices: RadioChoice<FurLength>[] = Object.values(FurLength).map((furLength: FurLength) => {
    return {
      label: 'GENERAL.DOMAIN.FurLength.' + furLength,
      object: furLength,
    };
  });

  protected readonly roughAgeChoices: RadioChoice<RoughAge>[] = Object.values(RoughAge).map((roughAge: RoughAge) => {
    return {
      label: 'GENERAL.DOMAIN.RoughAge.' + roughAge,
      object: roughAge,
    };
  });

  protected readonly compareWith = compareWith;
  protected readonly displayAnimalAutocomplete = displayAnimalAutocomplete;

  constructor(
    private baseDataService: BaseDataService,
    private caseAnimalService: CaseAnimalService,
    private animalService: AnimalService,
  ) {}

  ngOnInit(): void {
    this.baseDataService
      .getTaxonomy(this.currentAnimalContext.caseAnimalDto.animal.type)
      .pipe(take(1))
      .subscribe(taxonomyBaseData => {
        this.taxonomyBaseData = taxonomyBaseData;
      });
  }

  replaceAnimal(animalDto: AnimalDto | null) {
    if (animalDto == null) {
      return;
    }

    this.form.controls.chipId.setValue(animalDto.chipId);

    const caseId = this.currentAnimalContext.caseDto.id;

    this.caseAnimalService
      .replaceAnimalInCaseAnimal(caseId, this.currentAnimalContext.caseAnimalDto.animal.id, animalDto.id)
      .subscribe((caseAnimalDto: CompactCaseAnimalDto) => {
        this.onCaseAnimalChange.emit(caseAnimalDto);
      });
  }

  removeSelectedAnimal() {
    this.caseAnimalService
      .replaceAnimalInCaseWithDefault(this.currentAnimalContext.caseDto.id, this.currentAnimalContext.caseAnimalDto.animal.id)
      .subscribe((caseAnimalDto: CompactCaseAnimalDto) => {
        this.onCaseAnimalChange.emit(caseAnimalDto);
      });
  }

  getAnimalChipAutocompleteValue(animalDto: AnimalDto | null): string | null {
    return animalDto?.chipId ?? null;
  }

  getAnimalRingAutocompleteValue(animalDto: AnimalDto | null): string | null {
    return animalDto?.ringId ?? null;
  }

  updateChipId(value: string | null) {
    this.form.controls.chipId.setValue(value);
  }

  updateRingId(value: string | null) {
    this.form.controls.ringId.setValue(value);
  }
}
