import { Component } from '@angular/core';
import { AnimalTitlePipe } from '@animal/pipes/animal-title.pipe';
import { BaseDataExitStateComponent } from '@baseData/components/base-data-exit-state/base-data-exit-state.component';
import { BaseDataRelationkindComponent } from '@baseData/components/base-data-relationkind/base-data-relationkind.component';
import { BaseDataSubmitReasonComponent } from '@baseData/components/base-data-submit-reason/base-data-submit-reason.component';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';

@Component({
  selector: 'app-base-data-case-page',
  standalone: true,
  imports: [
    BaseDataRelationkindComponent,
    AnimalTitlePipe,
    CollapsibleComponent,
    NarrowPageContainerComponent,
    BaseDataSubmitReasonComponent,
    BaseDataExitStateComponent,
  ],
  templateUrl: './base-data-case-page.component.html',
  styleUrl: './base-data-case-page.component.scss',
})
export class BaseDataCasePageComponent {}
