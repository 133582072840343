<mat-radio-group [formControl]="control" aria-label="Select an option" class="subgrid item-1 inherit-col-span" id="animalType">
  @for (choice of choices; track choice) {
    <mat-radio-button
      #button
      (click)="checkDeselectEmitter.next({ choice: choice, value: control.value })"
      [class]="gridItemClass + ' outlined'"
      [value]="choice.object"
    >
      {{ choice.label | translate }}
    </mat-radio-button>
  }
</mat-radio-group>
