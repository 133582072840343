<tgn-narrow-page-container>
  <h1>{{ 'PAGE.NEW_CASE.TITLE' | translate }}</h1>

  @if (form) {
    <div class="container">
      <form class="form-grid grid-cols-6">
        <div class="row">
          <div class="item col-span-6">
            <tgn-form-element [control]="form.controls.animalType" [id]="'animalType'" [label]="'GENERAL.FORM.LABEL.PLEASE_CHOOSE'">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-radio-group
                  [choices]="animalTypeChoices"
                  [control]="control"
                  [id]="id"
                  cdkFocusInitial
                  class="col-span-2 col-span-sm-1"
                />
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        @if (form.controls.animalType.value === AnimalType.DomesticAnimal) {
          <div class="row">
            <div class="item col-span-6">
              <tgn-form-element
                [control]="form.controls.entryType"
                [hideError]="true"
                [id]="'caseEntryType'"
                [label]="'GENERAL.FORM.LABEL.ENTRY_AS'"
                class=""
              >
                <ng-template formElement let-control="control" let-id="id">
                  <tgn-radio-group
                    [id]="id"
                    class="col-span-3 col-span-sm-1"
                    [choices]="caseEntryTypeChoicesDomestic"
                    [control]="control"
                  />
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        }

        @if (form.controls.entryType.value === CaseEntryType.Findeltier) {
          <div class="row">
            <div class="item col-span-6">
              <tgn-form-element
                [control]="form.controls.foundLocation"
                [id]="'foundLocation'"
                [label]="'ENTITY.Case.foundLocation'"
                [hideError]="true"
                class=""
              >
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline" class="">
                    <input matInput [formControl]="control" [id]="id" [placeholder]="'ENTITY.Case.foundLocation' | translate" type="text" />
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        }

        @if (form.controls.animalType.value === AnimalType.DomesticAnimal) {
          <div class="row">
            <div class="item col-span-6">
              <tgn-form-element
                [control]="form.controls.submitReason"
                [id]="'submitReason'"
                [label]="'ENTITY.Case.submitReason'"
                [hideError]="true"
                class=""
              >
                <ng-template formElement let-control="control" let-id="id">
                  <tgn-select
                    class=""
                    [control]="control"
                    [choices]="submitReasons$ | async | toRadioChoice: 'name'"
                    [compareIds]="true"
                    [placeholder]="'GENERAL.FORM.LABEL.CHOOSE_REASON' | translate"
                  />
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        }

        <div class="row row-span-1 grow-row">
          <div class="item col-span-6">
            <tgn-form-element
              [control]="form.controls.noteSubmitReason"
              [id]="'noteSubmitReason'"
              [label]="'ENTITY.Case.noteSubmitReason' | translate"
            >
              <ng-template formElement let-control="control" let-id="id">
                <tgn-textarea
                  [control]="form.controls.noteSubmitReason"
                  [id]="id"
                  [maxRowsAutosize]="10"
                  [minRowsAutosize]="5"
                  [placeholder]="'ENTITY.Case.noteSubmitReason' | translate"
                  [triggerAutosize]="true"
                />
              </ng-template>
            </tgn-form-element>
          </div>
        </div>
        <div class="row">
          <div class="item col-span-6">
            <tgn-form-element
              [control]="form.controls.entryViaType"
              [hideError]="true"
              [id]="'entryViaType'"
              [label]="'ENTITY.Case.entryViaType'"
              class=""
            >
              <ng-template formElement let-control="control" let-id="id">
                <tgn-radio-group [choices]="entryViaTypeChoices" [control]="control" [id]="id" class="col-span-2 col-span-sm-1" />
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        @if (form.controls.entryViaType.value === EntryViaType.Rescue) {
          <div class="row">
            <div class="item col-span-6">
              <tgn-form-element [control]="form.controls.rescueNumber" [id]="'rescueNumber'" [label]="'ENTITY.Case.rescueNumber'" class="">
                <ng-template formElement let-control="control" let-id="id">
                  <mat-form-field appearance="outline" class="">
                    <input matInput [formControl]="control" [placeholder]="'ENTITY.Case.rescueNumber' | translate" [id]="id" type="text" />
                  </mat-form-field>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        }

        <div class="row">
          <div class="item col-span-6">
            <tgn-form-element
              [control]="form.controls.contact"
              [hideError]="true"
              [id]="'contactId'"
              [label]="'ENTITY.Case.contactId'"
              [optional]="form.controls.entryViaType.value === EntryViaType.Rescue"
              class=""
            >
              <ng-template formElement let-control="control" let-id="id">
                <tgn-choose-contact [allowNewCreation]="true" [control]="form.controls.contact" [id]="id" class="" />
              </ng-template>
            </tgn-form-element>
          </div>
        </div>

        @if (form.controls.contact.value !== null) {
          <div class="row">
            <div class="item col-span-6">
              <tgn-form-element
                [control]="form.controls.relationKind"
                [hideError]="true"
                [id]="'contactId'"
                [label]="'ENTITY.Contact.relationKind' | translate"
                class=""
              >
                <ng-template formElement let-control="control" let-id="id">
                  <tgn-select
                    class=""
                    [control]="control"
                    [compareIds]="true"
                    [choices]="relationKinds$ | async | addIfMissing: form.controls.relationKind.value | toRadioChoice: 'name'"
                  />
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        }

        @if (form.controls.animalType.value === AnimalType.DomesticAnimal) {
          <div class="row">
            <div class="item col-span-6">
              <tgn-form-element [control]="form.controls.numAnimals" [id]="'numAnimals'" [label]="'ENTITY.Case.numAnimals'" class="  ">
                <ng-template formElement let-control="control" let-id="id">
                  <div class="number-input-container">
                    <tgn-number-input [id]="id" [formControl]="control" [minValue]="1"></tgn-number-input>
                  </div>
                </ng-template>
              </tgn-form-element>
            </div>
          </div>
        } @else {
          <tgn-wild-animal-statistics-form [form]="form.controls.wildAnimalStatistics" />
        }
      </form>

      @if (this.form!.controls.fileCollection.value !== null) {
        <tgn-input-upload [fileCollection]="this.form!.controls.fileCollection.value!" class="mt-medium mb-medium"></tgn-input-upload>
      }

      <div class="bottom-action-bar">
        <button (click)="cancel()" class="" mat-flat-button type="button">
          {{ 'GENERAL.BUTTON.CANCEL' | translate }}
        </button>
        <button (click)="continue()" [disabled]="form.invalid" class="tgn-button ml-md" mat-flat-button type="button">
          {{ 'GENERAL.BUTTON.NEXT' | translate }}
        </button>
      </div>
    </div>
  }
</tgn-narrow-page-container>
