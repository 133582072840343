import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIcon } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';
import { BreadcrumbDefinition, BreadcrumbService } from 'xng-breadcrumb';

import { IconComponent } from '../icon/icon.component';
import { SingleLineTextComponent } from '../single-line-text/single-line-text.component';

@Component({
  selector: 'tgn-breadcrumbs',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink, SingleLineTextComponent, NgIcon, IconComponent],
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss',
})
export class BreadcrumbsComponent {
  readonly breadcrumbs$: Observable<BreadcrumbDefinition[]>;

  constructor(breadcrumb: BreadcrumbService) {
    this.breadcrumbs$ = breadcrumb.breadcrumbs$.pipe(map((breadcrumbs: BreadcrumbDefinition[]) => breadcrumbs.slice(-4)));
  }

  isString(subject: unknown): subject is string {
    return typeof subject === 'string';
  }

  trackByBreadcrumb(index: number, breadcrumb: BreadcrumbDefinition): string {
    return typeof breadcrumb.label === 'string' ? breadcrumb.label : breadcrumb.alias || index.toString();
  }
}
