<button [matMenuTriggerFor]="menu" class="tgn-icon-button" mat-button>
  @if (icon) {
    <tgn-icon [iconName]="icon" />
  }
  <span class="settings-text">
    @if (!value || value === '' || !isValid) {
      {{ title }}
    } @else {
      {{ value }}
    }
  </span>
</button>
<mat-menu #menu="matMenu">
  <div (click)="$event.stopPropagation()" [disableRipple]="true" mat-menu-item>
    <ng-content></ng-content>
  </div>
</mat-menu>
