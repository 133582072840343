import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { CreatePositionCategoryDialogComponent } from '@baseData/components/create-position-category-dialog/create-position-category-dialog.component';
import { BaseDataPositionDto } from '@baseData/dtos/base-data-position.dto';
import { PositionCategoryDto } from '@baseData/dtos/position-category.dto';
import { BaseDataPositionTableComponent } from '@baseData/pages/base-data-positions-page/components/base-data-position-table/base-data-position-table.component';
import { BaseDataPositionService } from '@baseData/services/base-data-position.service';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { ModalService, ModalWidth } from '@core/services/modal.service';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, map, shareReplay, take } from 'rxjs';

@Component({
  selector: 'app-base-data-positions-page',
  standalone: true,
  imports: [
    ContextActionsDirective,
    MatButtonModule,
    MatTableModule,
    TypesafeMatTableModule,
    TranslateModule,
    BaseDataPositionTableComponent,
    CollapsibleComponent,
    FormElementComponent,
    FormElementDirective,
    MatCheckbox,
    ReactiveFormsModule,
  ],
  templateUrl: './base-data-positions-page.component.html',
  styleUrl: './base-data-positions-page.component.scss',
})
export class BaseDataPositionsPageComponent implements OnInit {
  dataSources: Map<PositionCategoryDto, TigonDatasource<BaseDataPositionDto, {}>>;

  showInvalid!: FormControl<boolean>;

  includeExpiredPositions!: FormControl<boolean>;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private baseDataPositionService: BaseDataPositionService,
    private modalService: ModalService,
    private fb: FormBuilder,
  ) {
    this.dataSources = new Map();
    this.showInvalid = this.fb.nonNullable.control<boolean>(false);
  }

  ngOnInit() {
    this.includeExpiredPositions = this.fb.nonNullable.control<boolean>(false);

    this.loadCategoriesAndPositions();

    this.includeExpiredPositions.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.dataSources.forEach(dataSource => {
        dataSource.update({});
      });
    });
  }

  loadCategoriesAndPositions() {
    this.dataSources.clear();
    this.baseDataPositionService
      .getCategories()
      .pipe(take(1))
      .subscribe((categories: PositionCategoryDto[]) => {
        categories.forEach(category => {
          const dataSource = new TigonDatasource<BaseDataPositionDto, {}>(
            {},
            () => {
              return this.fetchPositionsForCategory(category, this.includeExpiredPositions.value).pipe(shareReplay(1));
            },
            this.destroyRef,
          );

          this.dataSources.set(category, dataSource);
        });
      });
  }

  openCreatePositionCategoryModal() {
    this.modalService
      .open(CreatePositionCategoryDialogComponent, {}, { width: ModalWidth.Small })
      .afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.loadCategoriesAndPositions();
      });
  }

  fetchPositionsForCategory(category: PositionCategoryDto, includeExpired?: boolean): Observable<BaseDataPositionDto[]> {
    return this.baseDataPositionService.getAllForSettings(false, includeExpired).pipe(
      take(1),
      map(positions => {
        return positions.filter(position => position.positionCategory.id === category.id);
      }),
    );
  }
}
