import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { range } from '@core/utils/helplers';
import moment from 'moment/moment';

// date string in format 'yyyy-MM-DD'
export type IsoLocalDateString = string;

// date string in format 'DD.MM.yyyy'
export type SwissLocalDateString = string;

export type SwissMonthYearString = string;
export type YearString = string;

// full Iso8601 string with hours, minutes,...
export type Iso8601String = string;

interface LocaleData {
  firstDayOfWeek: number;
  longMonths: string[];
  shortMonths: string[];
  dates: string[];
  longDaysOfWeek: string[];
  shortDaysOfWeek: string[];
  narrowDaysOfWeek: string[];
}

const localeDeCh = 'de-ch';

export const ISO_LOCAL_DATE_FORMAT = 'YYYY-MM-DD';
export const SWISS_LOCAL_DATE_FORMAT = 'DD.MM.YYYY';
export const SWISS_MONTH_YEAR_FORMAT = 'MM.YYYY';
export const ISO_YEAR_MONTH_FORMAT = 'YYYY-MM';
export const ISO_YEAR_FORMAT = 'YYYY';

/**
 * Custom date adapter for the date picker so that any dates picked are in the
 * format 'DD.MM.yyyy'
 */
@Injectable()
export class CustomStringDateAdapter extends DateAdapter<IsoLocalDateString> {
  isoLocalDateFormat = ISO_LOCAL_DATE_FORMAT;
  swissLocalDisplayFormat = SWISS_LOCAL_DATE_FORMAT;

  private _localeData!: LocaleData;

  constructor() {
    super();
    this.setLocale(localeDeCh);
  }

  override setLocale(locale: string) {
    super.setLocale(locale);

    const momentLocaleData = moment.localeData(locale);
    this._localeData = {
      firstDayOfWeek: momentLocaleData.firstDayOfWeek(),
      longMonths: momentLocaleData.months(),
      shortMonths: momentLocaleData.monthsShort(),
      dates: range(31, (i: number) => moment({ year: 2017, month: 0, day: i + 1 }).format('D')),
      longDaysOfWeek: momentLocaleData.weekdays(),
      shortDaysOfWeek: momentLocaleData.weekdaysShort(),
      narrowDaysOfWeek: momentLocaleData.weekdaysMin(),
    };
  }

  override getYear(date: IsoLocalDateString): number {
    return this.innerParseIsoLocalDate(date).year();
  }

  override getMonth(date: IsoLocalDateString): number {
    return this.innerParseIsoLocalDate(date).month();
  }

  override getDate(date: IsoLocalDateString): number {
    return this.innerParseIsoLocalDate(date).date();
  }

  override getDayOfWeek(date: IsoLocalDateString): number {
    return moment(date, this.isoLocalDateFormat).day();
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    // Moment.js doesn't support narrow month names, so we just use short if narrow is requested.
    return style == 'long' ? this._localeData.longMonths : this._localeData.shortMonths;
  }

  getDateNames(): string[] {
    return this._localeData.dates;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style == 'long') {
      return this._localeData.longDaysOfWeek;
    }
    if (style == 'short') {
      return this._localeData.shortDaysOfWeek;
    }
    return this._localeData.narrowDaysOfWeek;
  }

  override getYearName(date: string): string {
    return moment(date, this.isoLocalDateFormat).year().toString();
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }

  override getNumDaysInMonth(date: string): number {
    return moment(date, this.isoLocalDateFormat).daysInMonth();
  }

  override clone(date: string): string {
    return date;
  }

  override createDate(year: number, month: number, date: number): string {
    return moment({ year, month, date }).format(this.isoLocalDateFormat);
  }

  override today(): string {
    return moment().format(this.isoLocalDateFormat);
  }

  // parse string in local format typed in input to iso format
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override parse(value: SwissLocalDateString, _: unknown): string | null {
    const parsed = moment(value, this.swissLocalDisplayFormat, true);
    if (!parsed.isValid()) {
      // need to return the value if not valid, otherwise validator is not triggered within
      // the material date picker
      return value;
    }
    return parsed.format(this.isoLocalDateFormat);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override format(date: IsoLocalDateString, _: unknown): SwissLocalDateString {
    return this.innerParseIsoLocalDate(date).format(this.swissLocalDisplayFormat);
  }

  override addCalendarYears(date: IsoLocalDateString, years: number): IsoLocalDateString {
    return this.innerParseIsoLocalDate(date).add(years, 'years').format(this.isoLocalDateFormat);
  }

  override addCalendarMonths(date: IsoLocalDateString, months: number): IsoLocalDateString {
    return this.innerParseIsoLocalDate(date).add(months, 'months').format(this.isoLocalDateFormat);
  }

  override addCalendarDays(date: IsoLocalDateString, days: number): IsoLocalDateString {
    return this.innerParseIsoLocalDate(date).add(days, 'days').format(this.isoLocalDateFormat);
  }

  // does not contain hours, minutes, etc!
  override toIso8601(date: IsoLocalDateString): IsoLocalDateString {
    return date;
  }

  override isDateInstance(obj: string): boolean {
    const isIsoLocalDate = moment(obj, this.isoLocalDateFormat, true).isValid();
    const isSwissLocalDate = moment(obj, this.swissLocalDisplayFormat, true).isValid();
    return isIsoLocalDate || isSwissLocalDate;
  }

  override isValid(date: string): boolean {
    return moment(date, this.isoLocalDateFormat, true).isValid() || moment(date, this.swissLocalDisplayFormat, true).isValid();
  }

  override getValidDateOrNull(str: string): string | null {
    return this.isValid(str) ? str : null;
  }

  override invalid(): string {
    return '';
  }

  private innerParseIsoLocalDate(date: IsoLocalDateString): moment.Moment {
    return moment(date, this.isoLocalDateFormat, true);
  }
}
