import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from '@core/models/general';

export interface RoleRestriction {
  restrictTo?: (UserRole | RoleCombination)[];
  exclude?: (UserRole | RoleCombination)[];
}

// AND combination of roles
export interface RoleCombination {
  roles: UserRole[];
  type: 'RoleCombination';
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RoleAccess {
  export function anyMatches(roles: UserRole[], rules: (UserRole | RoleCombination)[]): boolean {
    return rules.some(rule => matches(roles, rule));
  }

  export function matches(roles: UserRole[], rule: UserRole | RoleCombination): boolean {
    if (isRoleCombination(rule)) {
      return rule.roles.every(role => roles.includes(role));
    } else {
      return roles.some(it => it === rule);
    }
  }
}

export function isRoleCombination(rule: UserRole | RoleCombination): rule is RoleCombination {
  return (rule as RoleCombination).type === 'RoleCombination';
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function RoleCombination(...roles: UserRole[]): RoleCombination {
  return {
    roles,
    type: 'RoleCombination',
  };
}

@Pipe({
  name: 'andRoleRestriction',
  standalone: true,
})
export class AndRoleRestrictionPipe implements PipeTransform {
  transform(roleRestriction: RoleRestriction | undefined | null, other: RoleRestriction | undefined | null): RoleRestriction | null {
    if (!roleRestriction) {
      return other ?? null;
    }

    if (!other) {
      return roleRestriction ?? null;
    }

    return {
      restrictTo: [...(roleRestriction.restrictTo ?? []), ...(other.restrictTo ?? [])],
      exclude: [...(roleRestriction.exclude ?? []), ...(other.exclude ?? [])],
    };
  }
}

export const RESTRICT_CASE_WRITE: RoleRestriction = {
  restrictTo: [UserRole.Admin, UserRole.Office, UserRole.VeterinaryMedicine, UserRole.VerantwortlicheHundewesen],
};
