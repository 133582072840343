import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CreatePositionCategoryDto, PositionCategoryDto } from '@baseData/dtos/position-category.dto';
import { BaseDataPositionService } from '@baseData/services/base-data-position.service';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { EditorComponent } from '@core/components/editor/editor.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { ModalComponent } from '@core/services/modal.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';

interface DialogData {}

interface DialogResult {
  categoryDto: CreatePositionCategoryDto;
}

@Component({
  selector: 'app-create-position-category-dialog',
  standalone: true,
  imports: [
    AutocompleteComponent,
    FormElementComponent,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    EditorComponent,
    FormElementDirective,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    RoleRestrictionDirective,
  ],
  templateUrl: './create-position-category-dialog.component.html',
  styleUrl: './create-position-category-dialog.component.scss',
})
export class CreatePositionCategoryDialogComponent extends ModalComponent<DialogData, DialogResult> implements OnInit {
  nameControl!: FormControl<string | null>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(
    dialogRef: MatDialogRef<CreatePositionCategoryDialogComponent, DialogResult>,
    private baseDataPositionService: BaseDataPositionService,
    private snackbar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.nameControl = this.fb.control<string | null>(null);
  }

  close() {
    const name = this.nameControl.value?.trim();
    if (!name || name === '') {
      return;
    }

    const dto: CreatePositionCategoryDto = {
      name: name,
    };

    this.baseDataPositionService.createCategory(dto).subscribe({
      next: (categoryDto: PositionCategoryDto) => {
        this.snackbar.showSuccessMessage('PAGE.BASE_DATA_POSITIONS.FEEDBACK.POSITION_CATEGORY_CREATED');
        this.closeWithResult({ categoryDto: categoryDto });
      },
      error: () => {
        this.snackbar.showErrorMessage('PAGE.BASE_DATA_POSITIONS.FEEDBACK.ERROR_POSITION_CATEGORY_CREATE');
      },
    });
  }
}
