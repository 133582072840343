<div class="input-upload">
  @if (isLoading) {
    <tgn-loading-indicator text="COMPONENT.InputUpload.LABEL.LOADING_DATA"></tgn-loading-indicator>
  }

  <div [ngClass]="{ '-dragging': isDragging }" class="dropzone">
    <a (click)="showSelectFileDialog($event)" [tgnRoleRestriction]="GENERAL_WRITE_EXCLUDE" class="label" href="#">
      <tgn-icon [iconName]="'radixFilePlus'" />
      <span>{{ dropzoneLabel ?? 'COMPONENT.InputUpload.LABEL.UPLOAD_FILES' | translate }}</span>
    </a>
    <input (change)="onFileSelected($event)" [accept]="acceptAttribute" [disabled]="false" [multiple]="multiple" type="file" />
    @if (filesToUpload.length > 0) {
      <div class="files">
        @for (file of filesToUpload; track file) {
          <div class="file">{{ file.name }}</div>
        }
      </div>
    }
  </div>

  @if (files.length > 0) {
    <tgn-file-list-preview [files]="files" (onFileDeleted)="deleteFile($event)" />
  }
</div>
