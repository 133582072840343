<div class="content">
  <h1>Archiv</h1>

  <mat-tab-group animationDuration="0ms" class="mt-large" mat-stretch-tabs="false">
    <mat-tab label="Fälle" mat-align-tabs="start">
      <tgn-archive-cases />
    </mat-tab>
    <mat-tab label="Tiere">
      <tgn-archive-animals />
    </mat-tab>
    <mat-tab label="Kontakte">
      <tgn-archive-contacts />
    </mat-tab>
  </mat-tab-group>
</div>
