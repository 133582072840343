import { Pipe, PipeTransform } from '@angular/core';

import { CaseDetailDto } from '../dtos/case-detail.dto';

@Pipe({ standalone: true, name: 'caseTitleDisplay' })
export class CaseTitleDisplayPipe implements PipeTransform {
  transform(caseDetailDto: CaseDetailDto): string {
    const title = caseDetailDto.title ?? 'Fall';
    return `${title} - Nr: ${caseDetailDto.caseNumber}`;
  }
}
