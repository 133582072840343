import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreatePositionCategoryDto, PositionCategoryDto } from '@baseData/dtos/position-category.dto';
import { BaseService } from '@core/services/base-service';
import { IsoLocalDateString } from '@core/utils/date';
import moment from 'moment/moment';
import { Observable, map } from 'rxjs';

import {
  BaseDataPositionDto,
  BaseDataPositionId,
  CreateBaseDataPositionDto,
  ReplaceBaseDataPositionDto,
  UpdateBaseDataPositionDto,
} from '../dtos/base-data-position.dto';

@Injectable({
  providedIn: 'root',
})
export class BaseDataPositionService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getAllForSettings(excludePension: boolean, includeExpired?: boolean): Observable<BaseDataPositionDto[]> {
    return this.http.get<BaseDataPositionDto[]>(`${this.apiUrl}/master/baseDataPositions`).pipe(
      map(positions => {
        return positions
          .filter(position => {
            if (includeExpired || !position.validTo) {
              return true;
            }
            return !isPositionExpired(moment().format('yyyy-MM-DD'), position);
          })
          .filter(position => {
            return !excludePension || !position.positionCategory.isPension;
          });
      }),
    );
  }

  getValidForSelection(excludePension: boolean): Observable<BaseDataPositionDto[]> {
    const params = {
      valid: true,
    };
    return this.http.get<BaseDataPositionDto[]>(`${this.apiUrl}/master/baseDataPositions`, { params: params }).pipe(
      map(positions => {
        if (excludePension) {
          return positions.filter(position => !position.positionCategory.isPension);
        }
        return positions;
      }),
      map((positions: BaseDataPositionDto[]) => {
        return positions.filter((position: BaseDataPositionDto) => isPositionAvailableForDate(moment().format('yyyy-MM-DD'), position));
      }),
    );
  }

  update(id: BaseDataPositionId, dto: UpdateBaseDataPositionDto): Observable<BaseDataPositionDto> {
    return this.http.put<BaseDataPositionDto>(`${this.apiUrl}/master/baseDataPositions/${id}`, dto);
  }

  create(dto: CreateBaseDataPositionDto): Observable<BaseDataPositionDto> {
    return this.http.post<BaseDataPositionDto>(`${this.apiUrl}/master/baseDataPositions`, dto);
  }

  replace(dto: ReplaceBaseDataPositionDto): Observable<BaseDataPositionDto> {
    return this.http.post<BaseDataPositionDto>(`${this.apiUrl}/master/baseDataPositions/${dto.previousPositionId}/replace`, dto);
  }

  getCategories(): Observable<PositionCategoryDto[]> {
    return this.http.get<PositionCategoryDto[]>(`${this.apiUrl}/master/baseDataPositions/categories`);
  }

  createCategory(dto: CreatePositionCategoryDto): Observable<PositionCategoryDto> {
    return this.http.post<PositionCategoryDto>(`${this.apiUrl}/master/baseDataPositions/categories`, dto);
  }
}

function isPositionAvailableForDate(date: IsoLocalDateString, position: BaseDataPositionDto) {
  return !isPositionExpired(date, position) && !isPositionInFuture(date, position);
}

function isPositionExpired(date: IsoLocalDateString, baseDataPosition: BaseDataPositionDto): boolean {
  if (!baseDataPosition.validTo) {
    return false;
  }
  const validToDate = moment(baseDataPosition.validTo).local();
  return moment(date).local() > validToDate;
}

function isPositionInFuture(date: IsoLocalDateString, baseDataPosition: BaseDataPositionDto): boolean {
  if (!baseDataPosition.validFrom) {
    return false;
  }
  const validFromDate = moment(baseDataPosition.validFrom).local();
  return moment(date).local() < validFromDate;
}
