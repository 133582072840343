<h1 mat-dialog-title>
  {{ 'PAGE.ANIMALS.RapportExportDialog.TITLE' | translate }}
</h1>

<div class="mt-large" mat-dialog-content>
  <div class="form-grid grid-cols-6">
    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element [control]="form.controls.date" [hideError]="true" [label]="'GENERAL.FORM.LABEL.DATE' | translate" id="date">
          <ng-template formElement let-control="control" let-id="id">
            <tgn-date-input [control]="control" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>

    <div class="row">
      <div class="item col-span-6">
        <tgn-form-element
          [control]="form.controls.type"
          [hideError]="true"
          [label]="'PAGE.ANIMALS.RapportExportDialog.TYPE' | translate"
          id="type"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-radio-group [choices]="rapportExportChoices" [control]="control" [id]="id" class="form-grid-radio" />
          </ng-template>
        </tgn-form-element>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>
  <button (click)="create()" [disabled]="form.invalid" class="tgn-button" mat-flat-button>
    {{ 'GENERAL.BUTTON.CREATE' | translate }}
  </button>
</div>
