import { BrandedId } from '@core/utils/brand';
import { IsoLocalDateString } from '@core/utils/date';

export type VatTaxId = BrandedId<'VatTaxId'>;

export interface VatTaxDto {
  id: VatTaxId;
  value: number;
  validFrom: IsoLocalDateString;
  validTo: IsoLocalDateString;
  type: VatTaxType;
}

export enum VatTaxType {
  Normal = 'Normal',
  Reduced = 'Reduced',
  None = 'None',
}

export type CreateVatTaxDto = Omit<VatTaxDto, 'validTo' | 'id'>;

export interface VatTaxGroup {
  normal: VatTaxDto;
  reduced: VatTaxDto;
}
