<tgn-narrow-page-container>
  @if (form && animal) {
    @if (animal.type === AnimalType.DomesticAnimal) {
      <div class="container">
        <tgn-stepper
          #stepper
          (onFirstPreviousAction)="close()"
          (onLastAction)="finishExitCheck()"
          [showPreviousInFirstStep]="true"
          [firstPreviousLabel]="'GENERAL.BUTTON.PREVIOUS'"
          [lastActionLabel]="'PAGE.EXIT_CHECK.BUTTON.FINISH'"
        >
          <cdk-step [label]="'PAGE.EXIT_CHECK.STEPPER.GENERAL' | translate">
            <h2>
              {{ 'PAGE.ENTRY_CHECK.FORM.SECTION_GENERAL_FEATURES' | translate }}
            </h2>
            <form class="form-grid grid-cols-6">
              <div class="row row-span-sm-2">
                <div class="item col-span-3 col-span-sm-6">
                  <tgn-form-element [control]="form.controls.name" id="name" label="Tiernamen">
                    <ng-template formElement let-control="control" let-id="id">
                      <mat-form-field appearance="outline">
                        <input matInput [formControl]="control" [id]="id" type="text" />
                      </mat-form-field>
                    </ng-template>
                  </tgn-form-element>
                </div>
                <div class="item col-span-3 col-span-sm-6">
                  <tgn-form-element [control]="form.controls.trdId" id="trdId" [label]="'ENTITY.Animal.trdId' | translate">
                    <ng-template formElement let-control="control" let-id="id">
                      <mat-form-field appearance="outline">
                        <input matInput [formControl]="control" [id]="id" type="text" />
                      </mat-form-field>
                    </ng-template>
                  </tgn-form-element>
                </div>
              </div>

              <div class="row row-span-sm-4">
                <div class="item col-span-2 col-span-sm-6">
                  <tgn-form-element [control]="form.controls.chipId" id="chipId" [label]="'ENTITY.Animal.chipId' | translate">
                    <ng-template formElement let-control="control" let-id="id">
                      <mat-form-field appearance="outline">
                        <input matInput [formControl]="control" [id]="id" type="text" />
                      </mat-form-field>
                    </ng-template>
                  </tgn-form-element>
                </div>

                <div class="item col-span-2 col-span-sm-6">
                  <tgn-form-element [control]="form.controls.ringId" id="ringId" [label]="'ENTITY.Animal.ringId' | translate">
                    <ng-template formElement let-control="control" let-id="id">
                      <mat-form-field appearance="outline">
                        <input matInput [formControl]="control" [id]="id" type="text" />
                      </mat-form-field>
                    </ng-template>
                  </tgn-form-element>
                </div>

                <div class="item col-span-2 col-span-sm-6">
                  <tgn-form-element
                    [control]="form.controls.chipChecked"
                    id="chipChecked"
                    [label]="'ENTITY.ExitCheck.chipChecked' | translate"
                  >
                    <ng-template formElement let-control="control" let-id="id">
                      <div class="form-grid grid-cols-2 grid-cols-sm-1">
                        <tgn-radio-group class="full-width" [control]="control" [id]="id" [choices]="yesNoChoice" />
                      </div>
                    </ng-template>
                  </tgn-form-element>
                </div>
              </div>
              <div class="row">
                <div class="item col-span-6">
                  <tgn-form-element [control]="form.controls.box" id="box" [label]="'ENTITY.Animal.box' | translate">
                    <ng-template formElement let-control="control" let-id="id">
                      @if (boxes$ | async; as boxes) {
                        <tgn-select
                          [control]="control"
                          [id]="id"
                          [choices]="boxes | addIfMissing: form.controls.box.value | toRadioChoice: 'name'"
                          [compareIds]="true"
                        />
                      }
                    </ng-template>
                  </tgn-form-element>
                </div>
              </div>
            </form>
          </cdk-step>

          <cdk-step [label]="'PAGE.EXIT_CHECK.STEPPER.HEALTH' | translate">
            <h1>{{ 'PAGE.ENTRY_CHECK.TITLE_HEALTH' | translate }}</h1>
            <app-exit-check-health [form]="form" [animal]="animal" />
          </cdk-step>

          <cdk-step [label]="'PAGE.ENTRY_CHECK.STEPPER.PARTICULARITIES' | translate">
            <h2>{{ 'PAGE.ENTRY_CHECK.TITLE_PARTICULARITIES' | translate }}</h2>

            <tgn-form-element [control]="form.controls.generalNote" id="generalNote" [label]="'ENTITY.ExitCheck.generalNote' | translate">
              <ng-template formElement let-control="control" let-id="id">
                <tgn-textarea
                  [triggerAutosize]="true"
                  [control]="form.controls.generalNote"
                  [minRowsAutosize]="10"
                  [maxRowsAutosize]="15"
                  [id]="id"
                  [placeholder]="'PAGE.EXIT_CHECK.PLACEHOLDER.generalNote' | translate"
                />
              </ng-template>
            </tgn-form-element>

            <div class="mt-medium mb-medium">
              <span>Erstellt von: {{ doneBy | fullName }}</span>
            </div>
          </cdk-step>
        </tgn-stepper>
      </div>
    } @else {
      <tgn-stepper
        #stepper
        (onFirstPreviousAction)="close()"
        (onLastAction)="finishExitCheck()"
        [showPreviousInFirstStep]="true"
        [firstPreviousLabel]="'GENERAL.BUTTON.CANCEL'"
        [lastActionLabel]="'PAGE.EXIT_CHECK.BUTTON.FINISH'"
      >
        <cdk-step [label]="'PAGE.EXIT_CHECK.STEPPER.WILD_ANIMAL' | translate">
          <h2>
            {{ 'PAGE.ENTRY_CHECK.FORM.SECTION_GENERAL_FEATURES' | translate }}
          </h2>
          <div class="form-grid grid-cols-6">
            @if (wildAnimalStatistics) {
              <tgn-wild-animal-statistics-form [form]="wildAnimalStatistics" />
            }
          </div>
        </cdk-step>
      </tgn-stepper>
    }
  }
</tgn-narrow-page-container>
