@if (vatTaxForm) {
  <table #table="matTable" [dataSource]="dataSource" mat-table>
    <tr *matHeaderRowDef="columns" mat-header-row></tr>
    <tr *matRowDef="let vatTax; table: table; columns: columns" mat-row></tr>
    <tr *matFooterRowDef="columns" mat-footer-row></tr>

    <ng-container matColumnDef="value">
      <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.VatTax.value' | translate }}</th>
      <td *matCellDef="let vatTax; table: table" mat-cell>{{ vatTax.value }}%</td>
      <td *matFooterCellDef mat-footer-cell>
        <tgn-form-element [control]="vatTaxForm.controls.value" [hideError]="true" [label]="null" id="value">
          <ng-template formElement let-control="control" let-id="id">
            <mat-form-field appearance="outline">
              <input matInput [formControl]="control" [id]="id" [placeholder]="'%'" type="number" />
            </mat-form-field>
          </ng-template>
        </tgn-form-element>
      </td>
    </ng-container>

    <ng-container matColumnDef="validFrom">
      <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.VatTax.validFrom' | translate }}</th>
      <td *matCellDef="let vatTax; table: table" mat-cell>
        <div class="max-width-column">
          {{ vatTax.validFrom | date }}
        </div>
      </td>

      <td *matFooterCellDef mat-footer-cell>
        <tgn-form-element
          [control]="vatTaxForm.controls.validFrom"
          [hideError]="vatTaxForm.controls.validFrom.value === null"
          [label]="null"
          id="trdId"
        >
          <ng-template formElement let-control="control" let-id="id">
            <tgn-date-input [control]="control" />
          </ng-template>
        </tgn-form-element>
      </td>
    </ng-container>

    <ng-container matColumnDef="validTo">
      <th *matHeaderCellDef mat-header-cell>{{ 'ENTITY.VatTax.validTo' | translate }}</th>
      <td *matCellDef="let vatTax; table: table" mat-cell>
        {{ vatTax.validTo | date }}
      </td>

      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>
  </table>

  <button (click)="addEntry()" [disabled]="vatTaxForm.invalid" class="tgn-black-button mt-medium bottom-action-button" mat-flat-button>
    {{ 'PAGE.VAT_TAX.ACTIONS.ADD_ENTRY' | translate }}
  </button>
}
