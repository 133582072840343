import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisableControl]',
  standalone: true,
})
export class DisableControlDirective implements OnInit {
  private _disableControl!: boolean;
  constructor(private ngControl: NgControl) {}

  ngOnInit(): void {
    this.apply();
  }

  @Input() set disableControl(condition: boolean) {
    this._disableControl = condition;
    this.apply();
  }

  private apply() {
    const control = this.ngControl?.control;
    if (this._disableControl) {
      if (control && control.enabled) {
        control.disable();
      }
    } else {
      if (control && control.disabled) {
        control.enable();
      }
    }
  }
}
