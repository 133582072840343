import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { FormElementComponent, FormElementDirective } from '@core/components/form-element/form-element.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { ContextActionsDirective } from '@core/directives/context-actions.directive';
import { SnackbarService } from '@core/services/snackbar.service';
import { FileService } from '@file/service/file.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { UserDto } from '../../dto/user.dto';
import { CurrentUserService } from '../../service/current-user.service';

interface ProfileForm {
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  visa: FormControl<string | null>;
}

@Component({
  selector: 'app-user-profile-page',
  standalone: true,
  imports: [
    CommonModule,
    FormElementComponent,
    FormElementDirective,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatButtonModule,
    ContextActionsDirective,
    AvatarImageComponent,
    MatFormField,
    MatInput,
    NarrowPageContainerComponent,
  ],
  templateUrl: './user-profile-page.component.html',
  styleUrl: './user-profile-page.component.scss',
})
export class UserProfilePageComponent {
  form!: FormGroup<ProfileForm>;
  currentUser$: Observable<UserDto>;

  isSaving = false;

  constructor(
    fb: FormBuilder,
    private currentUserService: CurrentUserService,
    private fileService: FileService,
    private snackbar: SnackbarService,
    private translate: TranslateService,
  ) {
    this.currentUser$ = currentUserService.currentUser$;

    this.currentUser$.pipe(takeUntilDestroyed()).subscribe(user => {
      this.form = fb.group({
        firstName: fb.control(user.firstName),
        lastName: fb.control(user.lastName),
        visa: fb.control(user.visa),
      });
    });
  }

  uploadUserAvatarImage = async (file: File) => {
    return this.fileService.uploadUserProfileImage(file);
  };

  save() {
    if (!this.form.valid) {
      return;
    }

    const { firstName, lastName, visa } = this.form.getRawValue();
    this.isSaving = true;

    this.currentUserService.updateCurrentUser({ firstName, lastName, visa }).subscribe(async user => {
      this.form.patchValue({ firstName: user.firstName, lastName: user.lastName, visa: user.visa });
      this.snackbar.showSuccessMessage(this.translate.instant('PAGE.SETTINGS.PROFILE.NOTIFICATION.SAVED'));
      this.isSaving = false;
    });
  }
}
