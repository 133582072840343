import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { IconComponent } from '@core/components/icon/icon.component';
import { GENERAL_WRITE_EXCLUDE, RoleRestrictionDirective } from '@core/directives/role-restriction.directive';
import { FileBaseDto, FileDto } from '@file/dto/file.dto';
import { InputAcceptTypes, acceptedImageMimeTypes } from '@file/model/file';
import { FilePipe } from '@file/pipes/file.pipe';
import { UploadResult } from '@file/service/file.service';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';

type AvatarSize = 'size-lg' | 'size-xl' | 'size-3xl' | 'size-4xl' | 'size-3hg';

export interface AvatarHolder {
  avatarImage: FileDto | null;
}

@Component({
  standalone: true,
  selector: 'tgn-avatar-image',
  imports: [CommonModule, MatButtonModule, TranslateModule, IconComponent, FilePipe, MatFormField, MatInput, RoleRestrictionDirective],
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.scss'],
})
export class AvatarImageComponent<T extends AvatarHolder> {
  @Input({ required: true }) avatar!: T;
  @Input() size: AvatarSize = 'size-3xl';
  @Input() tooltip?: string;
  @Input() hideImageUpload = false;

  @Input() onUpload!: (file: File, holder: T) => Promise<UploadResult<FileBaseDto>>;

  @Input() noRoleRestriction = false;

  @Output() onFilesChanged = new EventEmitter<FileList | null>();
  @Output() onUploadCompleted = new EventEmitter<UploadResult<FileBaseDto>[]>();

  fileToUpload: File | null = null;
  isLoading = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly GENERAL_WRITE_EXCLUDE = GENERAL_WRITE_EXCLUDE;

  constructor(private elRef: ElementRef) {}

  get maxFileSize() {
    return environment.uploadMaxFileSize; // In bytes, must correspond to server limit.
  }

  get acceptAttribute(): InputAcceptTypes {
    return 'image/*';
  }

  showSelectFileDialog(event: MouseEvent) {
    event.preventDefault();
    const elInputFile = this.elRef.nativeElement.querySelector('input[type="file"]') as HTMLElement;
    if (elInputFile) {
      elInputFile.click();
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    this.onFilesChanged.next(input.files);
    if (files) {
      this.addFileToUpload(files[0]);
    }
  }

  async upload(file: File) {
    this.isLoading = true;
    const result = await this.onUpload(file, this.avatar);
    if (result.success) {
      this.avatar.avatarImage = result.file as FileDto;
    }
    this.onUploadCompleted.next([result]);
    this.fileToUpload = null;
    this.isLoading = false;
  }

  private validateFile(file: File) {
    if (file.size > this.maxFileSize) return false;
    return this.acceptedMimeTypes().includes(file.type);
  }

  private acceptedMimeTypes() {
    return acceptedImageMimeTypes;
  }

  private addFileToUpload(file: File) {
    if (!this.validateFile(file)) {
      return;
    }
    this.fileToUpload = file;
    this.upload(file);
  }
}
