import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@core/components/icon/icon.component';
import { routes_config } from '@core/constants';

@Component({
  selector: 'tgn-testing-placeholder',
  standalone: true,
  imports: [MatCardModule, IconComponent, MatButtonModule, RouterLink],
  templateUrl: './testing-placeholder.component.html',
  styleUrl: './testing-placeholder.component.scss',
})
export class TestingPlaceholderComponent {
  protected readonly appRoutes = routes_config;
}
