import { Component, WritableSignal, signal } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NavTabsComponent } from '@core/components/nav-tabs/nav-tabs.component';
import { RouterNavTabsComponent } from '@core/components/router-nav-tabs/router-nav-tabs.component';
import { routes_config } from '@core/constants';

const defaultTab = routes_config.SETTINGS_BASE_DATA_GENERAL.path;

@Component({
  selector: 'app-base-data-layout',
  standalone: true,
  imports: [RouterModule, NavTabsComponent, RouterNavTabsComponent],
  templateUrl: './base-data-layout.component.html',
  styleUrl: './base-data-layout.component.scss',
})
export class BaseDataLayoutComponent {
  tabs = [
    { label: 'PAGE.SETTINGS.BASE_DATA.TABS.ANIMAL', path: routes_config.SETTINGS_BASE_DATA_ANIMAL.path },
    { label: 'PAGE.SETTINGS.BASE_DATA.TABS.CASE', path: routes_config.SETTINGS_BASE_DATA_CASE.path },
    { label: 'PAGE.SETTINGS.BASE_DATA.TABS.TASKS', path: routes_config.SETTINGS_BASE_DATA_TASKS.path },
    { label: 'PAGE.SETTINGS.BASE_DATA.TABS.BOXES', path: routes_config.SETTINGS_BASE_DATA_BOXES.path },
    { label: 'PAGE.SETTINGS.BASE_DATA.TABS.VAT_TAX', path: routes_config.SETTINGS_BASE_DATA_VAT_TAX.path },
    { label: 'PAGE.SETTINGS.BASE_DATA.TABS.POSITIONS', path: routes_config.SETTINGS_BASE_DATA_POSITIONS.path },
  ];

  selectedTab: WritableSignal<string>;

  constructor(activeRoute: ActivatedRoute) {
    const selectedTab = activeRoute.snapshot.firstChild?.url[0]?.path ?? defaultTab;
    this.selectedTab = signal(selectedTab);
  }
}
