import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCell, MatTableModule } from '@angular/material/table';
import { VatTaxDto, VatTaxType } from '@baseData/dtos/vat-tax.dto';
import { VatTaxService } from '@baseData/services/vat-tax.service';
import { AvatarImageComponent } from '@core/components/avatar-image/avatar-image.component';
import { CollapsibleComponent } from '@core/components/collapsible/collapsible.component';
import { NarrowPageContainerComponent } from '@core/components/narrow-page-container/narrow-page-container.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { ConfirmationDialogDirective } from '@core/directives/confirmation-dialog.directive';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';

import { VatTaxTableComponent } from './components/vat-tax-table/vat-tax-table.component';

@Component({
  selector: 'app-base-data-vat-tax-page',
  standalone: true,
  imports: [
    CollapsibleComponent,
    TranslateModule,
    AvatarImageComponent,
    ConfirmationDialogDirective,
    EnumDisplayPipe,
    MatCell,
    MatTableModule,
    VatTaxTableComponent,
    NarrowPageContainerComponent,
  ],
  templateUrl: './base-data-vat-tax-page.component.html',
  styleUrl: './base-data-vat-tax-page.component.scss',
})
export class BaseDataVatTaxPageComponent {
  normalVatTaxDataSource: TigonDatasource<VatTaxDto, {}>;
  reducedVatTaxDataSource: TigonDatasource<VatTaxDto, {}>;
  protected readonly appRoutes = routes_config;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly VatTaxType = VatTaxType;
  private readonly destroyRef = inject(DestroyRef);

  constructor(private vatTaxService: VatTaxService) {
    this.normalVatTaxDataSource = new TigonDatasource<VatTaxDto, {}>({}, () => {
      return this.vatTaxService.getAll().pipe(
        takeUntilDestroyed(this.destroyRef),
        map(vatTaxes => {
          return vatTaxes.filter((vatTax: VatTaxDto) => {
            return vatTax.type === VatTaxType.Normal;
          });
        }),
      );
    });

    this.reducedVatTaxDataSource = new TigonDatasource<VatTaxDto, {}>({}, () => {
      return this.vatTaxService.getAll().pipe(
        takeUntilDestroyed(this.destroyRef),
        map(vatTaxes => {
          return vatTaxes.filter((vatTax: VatTaxDto) => {
            return vatTax.type === VatTaxType.Reduced;
          });
        }),
      );
    });
  }
}
