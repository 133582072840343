@if (billingRelevantInfo) {
  <app-billing-relevant-info [billingRelevantInfo]="billingRelevantInfo" [type]="'animal'" />
}
@if (datasource && currentVatTax) {
  <app-services-position-table
    [isReadOnly]="animal?.archived ?? false"
    [datasource]="datasource"
    (onAddPosition)="addPosition($event)"
    [usedVatTax]="currentVatTax"
    [positionContext]="PositionContext.CaseAnimal"
  />
}
