import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnimalId } from '@animal/dtos/animal.dto';
import { CaseId } from '@case/dtos/case.dto';
import { ContactId } from '@contact/dto/contact-list-view.dto';
import { ContactRelationId } from '@contact/dto/related-contact.dto';
import { PageResult } from '@core/data/tigon-datasource';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

import { CreateProspectDto, ProspectDto, ProspectId } from '../dto/prospect.dto';

@Injectable({
  providedIn: 'root',
})
export class CaseProspectsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getProspectsPaginated(caseId: CaseId): Observable<PageResult<ProspectDto>> {
    return this.http.get<PageResult<ProspectDto>>(`${this.apiUrl}/cases/${caseId}/prospects`);
  }

  createProspect(caseId: CaseId, contactId: ContactId, contactRelationId: ContactRelationId): Observable<ProspectDto> {
    const dto: CreateProspectDto = {
      contactId: contactId,
      contactRelationId: contactRelationId,
    };
    return this.http.post<ProspectDto>(`${this.apiUrl}/cases/${caseId}/prospects`, dto);
  }

  getProspectContract(id: ProspectId, caseId: CaseId, animalId: AnimalId): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiUrl}/prospects/${id}/contracts/animal/${animalId}/case/${caseId}`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  getProspectContractPreviewUrl(id: ProspectId, caseId: CaseId, animalId: AnimalId): string {
    return `${this.apiUrl}/prospects/${id}/contracts/animal/${animalId}/case/${caseId}/preview`;
  }

  getProspectPreviewContract(id: ProspectId, caseId: CaseId, animalId: AnimalId): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiUrl}/prospects/${id}/contracts/animal/${animalId}/case/${caseId}/preview`, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
