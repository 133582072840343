import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from '@core/models/general';

@Pipe({
  name: 'filterTeamRoles',
  standalone: true,
})
export class FilterTeamRolesPipe implements PipeTransform {
  transform(roles: UserRole[]): UserRole[] {
    return roles.filter(it => it !== UserRole.Superuser && it !== UserRole.Deactivated);
  }
}
