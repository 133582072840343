@if (showGeneralTitle) {
  <div class="nav-section">
    <h2 class="nav-section-title">
      {{ 'SIDEBAR.SECTION.GENERAL' | translate }}
    </h2>

    @for (item of navItemsGeneral; track $index) {
      <tgn-nav-item [navItem]="item" [tgnRoleRestriction]="item.roleRestriction"></tgn-nav-item>
    }
  </div>
}

<div class="nav-section">
  <h2 class="nav-section-title">
    {{ 'SIDEBAR.SECTION.PERSONAL' | translate }}
  </h2>

  @for (item of navItemsPersonal; track $index) {
    <tgn-nav-item [navItem]="item" [tgnRoleRestriction]="item.roleRestriction"></tgn-nav-item>
  }
</div>

<div class="bottom-items">
  <div [tgnRoleRestriction]="{ restrictTo: [UserRole.Admin] }" class="mb-medium">
    Umgebung: {{ 'ENVIRONMENT.' + environment.name | translate }}
  </div>

  <tgn-nav-item (click)="onSettingsClose.emit()" [navItem]="backNavItem" />
</div>
