import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'tgn-unread-notifications',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './unread-notifications.component.html',
  styleUrl: './unread-notifications.component.scss',
})
export class UnreadNotificationsComponent implements OnInit {
  numUnread = 0;

  destroyRef = inject(DestroyRef);

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    this.notificationService.onNumUnread$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: number) => {
      this.numUnread = value;
    });
  }
}
