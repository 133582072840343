import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base-service';
import { Observable } from 'rxjs';

import { SettingsDto, UpdateSettingsDto } from '../dtos/settings.dto';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getSettings(): Observable<SettingsDto> {
    return this.http.get<SettingsDto>(`${this.apiUrl}/settings`);
  }

  updateSettings(dto: UpdateSettingsDto): Observable<SettingsDto> {
    return this.http.put<SettingsDto>(`${this.apiUrl}/settings`, dto);
  }
}
