import { Pipe, PipeTransform } from '@angular/core';

export interface WithAddress {
  address: string | null;
  addressAddendum: string | null;
  zip: string | null;
  city: string | null;
}

@Pipe({ standalone: true, name: 'fullAddress' })
export class FullAddressPipe implements PipeTransform {
  transform(value: WithAddress): string {
    return [value.address, value.addressAddendum, value.zip, value.city].filter(it => it !== null).join(', ');
  }
}
