<ng-template #statisticsForm>
  <div [ngClass]="{ inline: inline }" class="item col-span-1 col-span-sm-2">
    <tgn-form-element [control]="this.form.controls.numAnimals" [id]="'numAnimals'" [label]="'ENTITY.WildAnimal.numAnimals'">
      <ng-template formElement let-control="control" let-id="id">
        <tgn-number-input [formControl]="control" [id]="id" [minValue]="0" [variantDarker]="variantDarker" />
      </ng-template>
    </tgn-form-element>
  </div>

  <div [ngClass]="{ inline: inline }" class="item col-span-1 col-span-sm-2">
    <tgn-form-element [control]="form.controls.numCareStation" [id]="'numCareStation'" [label]="'ENTITY.WildAnimal.numCareStation'">
      <ng-template formElement let-control="control" let-id="id">
        <tgn-number-input [formControl]="control" [id]="id" [minValue]="0" [variantDarker]="variantDarker" />
      </ng-template>
    </tgn-form-element>
  </div>

  <div [ngClass]="{ inline: inline }" class="item col-span-1 col-span-sm-2">
    <tgn-form-element [control]="form.controls.numVeterinarian" [id]="'numVeterinarian'" [label]="'ENTITY.WildAnimal.numVeterinarian'">
      <ng-template formElement let-control="control" let-id="id">
        <tgn-number-input [formControl]="control" [id]="id" [minValue]="0" [variantDarker]="variantDarker" />
      </ng-template>
    </tgn-form-element>
  </div>

  <div [ngClass]="{ inline: inline }" class="item col-span-1 col-span-sm-2">
    <tgn-form-element [control]="form.controls.numReleased" [id]="'numReleased'" [label]="'ENTITY.WildAnimal.numReleased'">
      <ng-template formElement let-control="control" let-id="id">
        <tgn-number-input [formControl]="control" [id]="id" [minValue]="0" [variantDarker]="variantDarker" />
      </ng-template>
    </tgn-form-element>
  </div>

  <div [ngClass]="{ inline: inline }" class="item col-span-1 col-span-sm-2">
    <tgn-form-element [control]="form.controls.numDead" [id]="'numDead'" [label]="'ENTITY.WildAnimal.numDead'">
      <ng-template formElement let-control="control" let-id="id">
        <tgn-number-input [formControl]="control" [id]="id" [minValue]="0" [variantDarker]="variantDarker" />
      </ng-template>
    </tgn-form-element>
  </div>
</ng-template>

@if (inline) {
  <ng-container [ngTemplateOutlet]="statisticsForm"></ng-container>
} @else {
  <div class="row row-span-sm-2" [ngTemplateOutlet]="statisticsForm"></div>
}
