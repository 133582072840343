<h1 mat-dialog-title>Statistikexport</h1>

<div class="mt-large" mat-dialog-content>
  <div class="form-grid grid-cols-6">
    <div class="row">
      <div class="item col-span-6 filter-item">
        @if (form.controls.filter.value === DateFilterChoice.Custom) {
          <tgn-date-input [control]="form.controls.startDate" [placeholder]="'von'" />
          <span class="separator">-</span>
          <tgn-date-input [control]="form.controls.endDate" [placeholder]="'bis'" />
        }

        @if (form.controls.filter.value === DateFilterChoice.Day) {
          <tgn-date-input [control]="form.controls.day" />
        }
        @if (form.controls.filter.value === DateFilterChoice.Month) {
          <tgn-date-input [control]="form.controls.month" [type]="DatePickerType.Month" />
        }
        @if (form.controls.filter.value === DateFilterChoice.Year) {
          <tgn-date-input [control]="form.controls.year" [type]="DatePickerType.Year" />
        }

        <div></div>
        <tgn-select [choices]="dateFilterChoices" [control]="form.controls.filter" [placeholder]="'Zeitraum'" />
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'GENERAL.BUTTON.CANCEL' | translate }}
  </button>

  <button (click)="export()" [disabled]="form.invalid || isLoading" class="tgn-button" mat-flat-button>
    @if (isLoading) {
      <mat-spinner diameter="24" />
    } @else {
      {{ 'GENERAL.BUTTON.CREATE' | translate }}
    }
  </button>
</div>
