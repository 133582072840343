<tgn-statistics-card [title]="title">
  <div>
    <span class="value-large">
      @if (value) {
        {{ value }}
      }
    </span>
    <span class="value-small">
      {{ smallText ?? '' }}
    </span>
  </div>
</tgn-statistics-card>
