import { DatePipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { OptionButtonComponent } from '@core/components/option-button/option-button.component';
import { PageEvent, PaginatorComponent } from '@core/components/paginator/paginator.component';
import { ScrollableTableComponent } from '@core/components/scrollable-table/scrollable-table.component';
import { DEFAULT_PAGE_SIZE, routes_config } from '@core/constants';
import { PagedTigonDatasource } from '@core/data/tigon-datasource';
import { TypeSafeMatCellDef, TypeSafeMatRowDef } from '@core/directives/mat-table.directive';
import { EnumDisplayPipe } from '@core/pipes/enum-display.pipe';
import { TranslateModule } from '@ngx-translate/core';

import { ContactCaseDto } from '../../../dto/contact-case.dto';
import { ContactDto } from '../../../dto/contact.dto';
import { ContactService } from '../../../service/contact-service';
import { ContactDetailStore } from '../contact-detail-layout/contact-detail-layout.component';

interface TableConfig {
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-contact-cases',
  standalone: true,
  imports: [
    MatTableModule,
    PaginatorComponent,
    TranslateModule,
    TypeSafeMatCellDef,
    DatePipe,
    FormsModule,
    MatInputModule,
    MatSortModule,
    OptionButtonComponent,
    TypeSafeMatRowDef,
    ScrollableTableComponent,
    EnumDisplayPipe,
    RouterLink,
  ],
  templateUrl: './contact-cases.component.html',
  styleUrl: './contact-cases.component.scss',
})
export class ContactCasesComponent implements OnInit {
  datasource?: PagedTigonDatasource<ContactCaseDto, TableConfig>;

  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  totalPages = 1;
  totalItems = 0;

  columns = ['caseNumber', 'title', 'caseCreationDate', 'completeDate', 'kind', 'fromToDate', 'contactStatus'];

  protected readonly appRoutes = routes_config;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private contactDetailStore: ContactDetailStore,
    private contactService: ContactService,
  ) {}

  ngOnInit() {
    const contact$ = this.contactDetailStore.contact$;

    contact$.subscribe((contact: ContactDto) => {
      this.datasource = new PagedTigonDatasource<ContactCaseDto, TableConfig>(
        {
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
        },
        params => {
          return this.contactService.getContactCasesPaginated(contact, {
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
          });
        },
        this.destroyRef,
      );
    });
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.totalPages = pageEvent.maxPages;

    this.datasource?.update({ pageSize: this.pageSize, pageIndex: this.pageIndex });
  }
}
