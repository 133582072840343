import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'enumDisplay',
  standalone: true,
})
export class EnumDisplayPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string | null | undefined, prefix: string): string {
    if (!value) {
      return '';
    }
    const key = `${prefix}.${value}`;
    return this.translate.instant(key);
  }
}
