<div>
  <tgn-global-search-filter
    (onAnimalFilterChange)="this.updateAnimalFilter($event)"
    (onCaseFilterChange)="this.updateCaseFilter($event)"
    [animalFilter]="this.animalFilter"
    [caseFilter]="caseFilter"
  />
</div>

<div class="search-container">
  <mat-form-field appearance="outline" class="search-input">
    <input
      [formControl]="searchControl"
      cdkFocusInitial="true"
      cdkTrapFocus
      cdkTrapFocusAutoCapture="true"
      matInput
      placeholder="Suchen"
      type="text"
    />
    <mat-icon matSuffix>
      @if (isLoading$ | async) {
        <div>
          <mat-spinner diameter="24" />
        </div>
      } @else {
        <tgn-icon [iconName]="'radixMagnifyingGlass'" />
      }
    </mat-icon>
  </mat-form-field>
</div>

<div class="results-list">
  @if (isLoading$ | async) {
    <mat-spinner diameter="48"></mat-spinner>
  } @else {
    @if (searchResults$ | async; as results) {
      @if (
        results.animals.entities.length === 0 &&
        results.cases.entities.length === 0 &&
        results.contacts.entities.length === 0 &&
        results.bills.entities.length === 0
      ) {
        <div class="no-results">Keine Ergebnisse gefunden</div>
      } @else {
        <div class="result-group">
          @for (animal of results.animals.entities; track animal.id) {
            <div class="result-entry" [routerLink]="appRoutes.ANIMAL_DETAIL_GENERAL.url(animal.id)">
              <tgn-global-search-result [result]="animal" [query]="searchControl.value" />
            </div>
          }
          @if (results.animals.entities.length === 0) {
            <div class="no-results">Keine Tiere gefunden</div>
          }
          @if (results.animals.hasMore) {
            <button class="load-more-button" (click)="loadMoreAnimals()" mat-flat-button>Weitere Resultate</button>
          }
        </div>

        <div class="result-group">
          @for (caseDto of results.cases.entities; track caseDto.id) {
            <div class="result-entry" [routerLink]="appRoutes.CASE_DETAIL_GENERAL.url(caseDto.id)">
              <tgn-global-search-result [result]="caseDto" [query]="searchControl.value" />
            </div>
          }
          @if (results.cases.entities.length === 0) {
            <div class="no-results">Keine Fälle gefunden</div>
          }
          @if (results.cases.hasMore) {
            <button class="load-more-button" (click)="loadMoreCases()" mat-flat-button>Weitere Resultate</button>
          }
        </div>

        <div class="result-group">
          @for (contact of results.contacts.entities; track contact.id) {
            <div class="result-entry" [routerLink]="appRoutes.CONTACT_DETAIL_GENERAL.url(contact.id)">
              <tgn-global-search-result [result]="contact" [query]="searchControl.value" />
            </div>
          }
          @if (results.contacts.entities.length === 0) {
            <div class="no-results">Keine Personen gefunden</div>
          }
          @if (results.contacts.hasMore) {
            <button class="load-more-button" (click)="loadMoreContacts()" mat-flat-button>Weitere Resultate</button>
          }
        </div>

        <div class="result-group">
          @for (bill of results.bills.entities; track bill.id) {
            <div class="result-entry" [routerLink]="appRoutes.CASE_EDIT_BILL.url(bill.caseId, bill.id)">
              <tgn-global-search-result [result]="bill" [query]="searchControl.value" />
            </div>
          }
          @if (results.contacts.entities.length === 0) {
            <div class="no-results">Keine Rechnungen gefunden</div>
          }
          @if (results.bills.hasMore) {
            <button class="load-more-button" (click)="loadMoreBills()" mat-flat-button>Weitere Resultate</button>
          }
        </div>
      }
    }
  }
</div>
