import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, NgClass } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCell, MatTableModule } from '@angular/material/table';
import { CreateRaceDto, UpdateRaceDto } from '@baseData/dtos/create-race.dto';
import { RaceService } from '@baseData/services/race.service';
import { SpeciesService } from '@baseData/services/species.service';
import { RaceDto, RaceId, SpeciesDto } from '@case/dtos/base-data.dto';
import { FormElementComponent } from '@core/components/form-element/form-element.component';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import { SelectComponent } from '@core/components/select/select.component';
import { SingleLineTextComponent } from '@core/components/single-line-text/single-line-text.component';
import { routes_config } from '@core/constants';
import { TigonDatasource } from '@core/data/tigon-datasource';
import { TypesafeMatTableModule } from '@core/modules/typesafe-mat-table/typesafe-mat-table.module';
import { FieldErrorTranslationPipe } from '@core/pipes/field-error-translation.pipe';
import { ToRadioChoicePipe } from '@core/pipes/to-radio-choice-pipe';
import { defaultDebounce } from '@core/services/base-service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { AccessService, RestrictedSection } from '@user/service/access.service';
import { ReplaySubject, combineLatest, take } from 'rxjs';

export type UpdateRaceDtoForm = FormGroup<{
  active: FormControl<boolean>;
  species: FormControl<SpeciesDto | null>;
}>;

export type NewRaceDtoForm = FormGroup<{
  name: FormControl<string | null>;
  species: FormControl<SpeciesDto | null>;
}>;

interface TableConfig {}

@Component({
  selector: 'tgn-base-data-races',
  standalone: true,
  imports: [
    AsyncPipe,
    FieldErrorTranslationPipe,
    FormElementComponent,
    MatButton,
    MatCell,
    TranslateModule,
    MatTableModule,
    TypesafeMatTableModule,
    ReactiveFormsModule,
    MatFormField,
    SelectComponent,
    MatError,
    MatInput,
    NgClass,
    ToRadioChoicePipe,
    SingleLineTextComponent,
    ScrollingModule,
  ],
  templateUrl: './base-data-races.component.html',
  styleUrl: './base-data-races.component.scss',
})
export class BaseDataRacesComponent implements OnInit {
  columns = ['name', 'species', 'active'];
  appRoutes = routes_config;

  newRaceDtoDtoForm!: NewRaceDtoForm;
  editRaceDtoDtoFormGroups: Map<RaceId, UpdateRaceDtoForm> = new Map<RaceId, UpdateRaceDtoForm>();

  domesticSpecies$: ReplaySubject<SpeciesDto[]> = new ReplaySubject<SpeciesDto[]>(1);

  dataSource!: TigonDatasource<RaceDto, TableConfig>;
  activeInactiveChoice: RadioChoice<Boolean>[] = [
    {
      label: 'GENERAL.FORM.LABEL.Active',
      object: true,
    },
    {
      label: 'GENERAL.FORM.LABEL.Inactive',
      object: false,
    },
  ];

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private raceDtoService: RaceService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private speciesService: SpeciesService,
    private accessService: AccessService,
  ) {}

  ngOnInit() {
    this.dataSource = new TigonDatasource<RaceDto, TableConfig>(
      {},
      () => {
        return this.raceDtoService.getAll();
      },
      this.destroyRef,
    );

    this.speciesService
      .getAllDomesticAnimal()
      .pipe(take(1))
      .subscribe((species: SpeciesDto[]) => {
        this.domesticSpecies$.next(species);
      });

    combineLatest([this.dataSource.getData(), this.domesticSpecies$.pipe(take(1))]).subscribe(data => {
      const [races, species] = data;
      this.editRaceDtoDtoFormGroups = new Map();
      // only allow to deactivate submit reasons without a tag
      races.forEach((raceDto: RaceDto) => {
        const speciesDto = species.find(s => s.id === raceDto.speciesId)!;
        const raceDtoFormGroup: UpdateRaceDtoForm = this.fb.group({
          active: this.fb.nonNullable.control<boolean>(raceDto.active, [Validators.required]),
          species: this.fb.control<SpeciesDto | null>(speciesDto, [Validators.required]),
        });

        this.accessService.disableBasedOnRole(raceDtoFormGroup, RestrictedSection.Backoffice);

        this.editRaceDtoDtoFormGroups.set(raceDto.id, raceDtoFormGroup);
        this.autoUpdateRows(raceDtoFormGroup, raceDto);
      });
    });

    this.createNewRaceDtoForm();
  }

  addEntry() {
    const form = this.newRaceDtoDtoForm.getRawValue();
    if (!form.name || !form.species) {
      return;
    }

    const createRaceDto: CreateRaceDto = {
      name: form.name,
      speciesId: form.species.id,
    };

    this.raceDtoService.create(createRaceDto).subscribe({
      next: () => {
        this.newRaceDtoDtoForm.setValue({
          name: null,
          species: null,
        });
        this.newRaceDtoDtoForm.markAsUntouched();
        this.newRaceDtoDtoForm.markAsPristine();
        this.snackbar.showSuccessMessage(`Die Rasse '${createRaceDto.name}' wurde erfolgreich erstellt.`);
        this.dataSource.refresh();
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Die Rasse konnte nicht erstellt werden.');
      },
    });
  }

  private createNewRaceDtoForm() {
    this.newRaceDtoDtoForm = this.fb.group(
      {
        name: this.fb.control<string | null>(
          null,

          {
            validators: [Validators.required, Validators.minLength(1)],
            updateOn: 'change',
          },
        ),
        species: this.fb.control<SpeciesDto | null>(null, [Validators.required]),
      },
      {},
    );
    this.accessService.disableBasedOnRole(this.newRaceDtoDtoForm, RestrictedSection.Backoffice);
  }

  private autoUpdateRows(formGroup: UpdateRaceDtoForm, raceDto: RaceDto) {
    formGroup.valueChanges.pipe(defaultDebounce(), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        const values = formGroup.getRawValue();
        if (formGroup.invalid || !values.species) {
          return;
        }

        const isActive = formGroup.getRawValue().active;

        const dto: UpdateRaceDto = {
          active: isActive,
          speciesId: values.species.id,
        };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.raceDtoService.update(raceDto.id, dto).subscribe(() => {
          this.dataSource.refresh();
        });
      },
      error: error => {
        console.error('error', error);
        this.snackbar.showErrorMessage('Die Rasse konnte nicht aktualisiert werden.');
      },
    });
  }
}
