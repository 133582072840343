import { Salutation, SalutationEnum } from '@contact/dto/contact.dto';
import { RadioChoice } from '@core/components/radio-group/radio-group.component';
import {
  AffectedGeneralRegion,
  AffectedLimb,
  AnimalSex,
  AnimalType,
  CaseEntryType,
  EntryViaType,
  ExtendedAffectedRegion,
  GeneralFieldChoice,
  OpenBooleanChoice,
  Parasite,
  PlacementState,
  UnitsLength,
  UnitsWeight,
  UnknownBooleanChoice,
} from '@core/models/general';

export const generalFieldChoices: RadioChoice<GeneralFieldChoice>[] = Object.values(GeneralFieldChoice).map((value: GeneralFieldChoice) => {
  return {
    label: 'GENERAL.DOMAIN.GeneralFieldChoice.' + value,
    object: value,
  };
});

export const sexChoices: RadioChoice<AnimalSex>[] = Object.values(AnimalSex).map((value: AnimalSex) => {
  return {
    label: 'GENERAL.DOMAIN.AnimalSex.' + value,
    object: value,
  };
});

export const unknownBooleanChoices: RadioChoice<UnknownBooleanChoice>[] = Object.values(UnknownBooleanChoice).map(
  (value: UnknownBooleanChoice) => {
    return {
      label: 'GENERAL.DOMAIN.UnknownBooleanChoice.' + value,
      object: value,
    };
  },
);

export const lengthUnitChoices: RadioChoice<UnitsLength>[] = Object.values(UnitsLength).map((value: UnitsLength) => {
  return {
    label: 'GENERAL.DOMAIN.UnitsLength.' + value,
    object: value,
  };
});

export const weightUnitChoices: RadioChoice<UnitsWeight>[] = Object.values(UnitsWeight).map((value: UnitsWeight) => {
  return {
    label: 'GENERAL.DOMAIN.UnitsWeight.' + value,
    object: value,
  };
});

export const parasiteChoices: RadioChoice<Parasite>[] = Object.values(Parasite).map((value: Parasite) => {
  return {
    label: 'GENERAL.DOMAIN.Parasite.' + value,
    object: value,
  };
});

export const affectedLimbChoices: RadioChoice<AffectedLimb>[] = Object.values(AffectedLimb).map((value: AffectedLimb) => {
  return {
    label: 'GENERAL.DOMAIN.AffectedLimb.' + value,
    object: value,
  };
});

export const openBooleanChoices: RadioChoice<OpenBooleanChoice>[] = Object.values(OpenBooleanChoice).map((value: OpenBooleanChoice) => {
  return {
    label: 'GENERAL.DOMAIN.OpenBooleanChoice.' + value,
    object: value,
  };
});

export const animalTypeChoices: RadioChoice<AnimalType>[] = Object.values(AnimalType).map((animalType: AnimalType) => {
  return {
    label: 'GENERAL.DOMAIN.AnimalType.' + animalType,
    object: animalType,
  };
});

export const caseEntryTypeChoicesDomestic: RadioChoice<CaseEntryType>[] = Object.values(CaseEntryType).map(
  (caseEntryType: CaseEntryType) => {
    return {
      label: 'GENERAL.DOMAIN.CaseEntryType.' + caseEntryType,
      object: caseEntryType,
    };
  },
);

export const entryViaTypeChoices: RadioChoice<EntryViaType>[] = Object.values(EntryViaType).map((entryViaType: EntryViaType) => {
  return {
    label: 'GENERAL.DOMAIN.EntryViaType.' + entryViaType,
    object: entryViaType,
  };
});

export const salutationChoices: RadioChoice<Salutation>[] = Object.values(SalutationEnum).map((salutation: Salutation) => {
  return {
    label: 'GENERAL.DOMAIN.Salutation.' + salutation,
    object: salutation,
  };
});

export const placementState: RadioChoice<PlacementState>[] = Object.values(PlacementState).map((state: PlacementState) => {
  return {
    label: 'ENTITY.PlacementState.' + state,
    object: state,
  };
});

export const affectedGeneralRegionChoices: RadioChoice<AffectedGeneralRegion>[] = Object.values(AffectedGeneralRegion).map(
  (region: AffectedGeneralRegion) => {
    return {
      label: 'GENERAL.DOMAIN.AffectedGeneralRegion.' + region,
      object: region,
    };
  },
);

export const extendedAffectedRegionChoices: RadioChoice<ExtendedAffectedRegion>[] = Object.values(ExtendedAffectedRegion).map(
  (region: ExtendedAffectedRegion) => {
    return {
      label: 'GENERAL.DOMAIN.ExtendedAffectedRegion.' + region,
      object: region,
    };
  },
);
